import React from "react";
import HelperMyAccount from "../components/HelperMyAccount";
// import MyAccount from "../components/MyAccount";
import "../styles/BuyersMyAccount.css";
import BcBuyerLayout from "../buyerComponents/BcBuyerLayout";
import BcAccountPagesContainer from "../buyerComponents/BcAccountPagesContainer";


export default function BuyersMyAccount() {
  //console.log(API_BASE_URL);
  return (
    <BcBuyerLayout>
      <BcAccountPagesContainer>
        <HelperMyAccount />
      </BcAccountPagesContainer>
    </BcBuyerLayout>

  );
}
