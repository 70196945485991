import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom/dist'
import LogisticAdminLayoutAdjustment from '../components/LogisticAdminLayoutAdjustment';
import { useState } from 'react';
import '../styles/LogisticsAdmin.css'
import { LogisticsAdminContext } from '../context/LogisticsAdminContext';
import Jutha2 from "../Images/Jutha2.png";
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField  } from '@mui/material';

function LogisticAdminThirdParty() {

    let currentStatus = "Pending"
    const [status, setStatus] = useState(['a', 'b', 'c', 'd']);

    const handleChange = (event) => {
        setStatus(event.target.value);
    };


    const params = useParams();
    const id = params.orderId;
    const {  setSelectedListItemIndex } = useContext(LogisticsAdminContext);

    useEffect(() => {
        setSelectedListItemIndex(0);
    }, [])
    const [orderData, setOrderData] = useState({
        orderId: '1234',
        orderPlaced: '2nd sept',
        deliveryDate: '8th sept',
        status: 'pending',
        skuId: '123456',
        sellerAddress: `Vaidehi Paradkar
        Raiya Kar Bhavan Nr Times Of India, Ashram Road, AHEMEDABAD, GUJARAT, INDIA - 380009`,
        buyerAddress: `Vaidehi Paradkar
        Raiya Kar Bhavan Nr Times Of India, Ashram Road, AHEMEDABAD, GUJARAT, INDIA - 380009`,
        pickup: 'by '

    }



    )

    const [product, setProduct] = useState({
        name: 'Rajasthani Jhuuttis',
        size: '3',
        color: 'Red',
        Qunatity: '1',
        length: '1',
        width: '1',
        weight: '1',
        height: '1',
        img: Jutha2

    }

    );

    // async function fetchProductData() {
    //     try {


    //         const response = await fetch(`${API_BASE_URL}/productgen/api/v1/search?`, {
    //             method: "GET",
    //             headers: {

    //             }
    //         })
    //         const data = await response.json();
    //         setProduct(data);
    //     }
    //     catch {
    //         //console.log('Error')
    //     }
    // }

    // useEffect(() => {
    //     fetchProductData();
    // }, [])
    const [dimensions, setDimensions] = useState({
        length: '',
        width: '',
      });
      const [weight, setWeight] = useState('');
      const [shipmentValue, setShipmentValue] = useState('');
      const [calculatedShipmentValue, setCalculatedShipmentValue] = useState(null);
    
      const handleCalculate = () => {
        if (dimensions.length && dimensions.width && weight) {
          // Perform calculation based on your business logic
          const calculatedValue = parseFloat(dimensions.length) * parseFloat(dimensions.width) * parseFloat(weight);
          setCalculatedShipmentValue(calculatedValue.toFixed(2)); // Set calculated shipment value with 2 decimal places
        } else {
          setCalculatedShipmentValue(null); // Reset calculated shipment value
        }
      };
    
      // const handleReset = () => {
      //   setDimensions({ length: '', width: '' });
      //   setWeight('');
      //   setShipmentValue('');
      //   setCalculatedShipmentValue(null);
      // };
      const [selectedVendor, setSelectedVendor] = useState('');
      const [showThirdPartyScreen, setShowThirdPartyScreen] = useState(false);
    
      const handleVendorSelect = (event) => {
        const vendor = event.target.value;
        setSelectedVendor(vendor);
      };
    
      const handleSubmit = () => {
        // Check if a vendor is selected before showing the "Third Party" screen
        if (selectedVendor) {
          setShowThirdPartyScreen(true);
        } else {
          alert("Please select a vendor before submitting.");
        }
      };
    return (
        <LogisticAdminLayoutAdjustment>
            <div className='logistic-orders'>
                <div className='order-head'>
                    <div className='order-column'>
                        <div className='order-column-name'>Order ID</div>
                        <div className='order-column-value'>{orderData.orderId}</div>
                    </div>
                    <div className='order-column'>
                        <div className='order-column-name'>Order Placed</div>
                        <div className='order-column-value'>{orderData.orderPlaced}</div>
                    </div>
                    <div className='order-column'>
                        <div className='order-column-name'>Delivery Date</div>
                        <div className='order-column-value'>{orderData.deliveryDate}</div>
                    </div>
                    <div className='order-column'>
                        <div className='order-column-name'>Status</div>
                        <div className='order-column-value'><div className='status-button'>{orderData.status}</div></div>
                    </div>
                </div>
                <div className='order-detail'>
                    <div className='order-product-status-detail'>


                        <div className='order-details-product-info'>
                            <img loading="lazy" className='order-details-product-image' src={Jutha2} alt=""/>
                            <div className='order-details-product-details'>
                                <div className='order-details-product-name'>{product.name}</div>
                                <div className='order-details-product-size-color-qty'>Size {product.size} Color: {product.color} Quantity:{product.Qunatity}</div>
                                <div className='order-details-product-dimensions'>L*W*H: {product.length} * {product.width} * {product.height} Weight:{product.weight}</div>
                            </div>
                        </div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <Select
                                value={currentStatus}
                                onChange={handleChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{minWidth:'350px'}}
                            >
                                {status.map((stat=>{
                                return(<MenuItem value={10}>
                                    <>{stat}</>
                                </MenuItem>)
                                }))}
                               
                            </Select>
                        </FormControl>

                    </div>
                    <div className='order-product-address-deatil'>
                        <div className='order-product-address-buyers'>
                            <div className='order-product-address-heading'>Buyer Address</div>
                            <div>Vaidehi Paradkar Raiya Kar Bhavan Nr Times Of India, Ashram Road, AHEMEDABAD, GUJARAT, INDIA - 380009</div>
                        </div>
                        <div className='order-product-address-buyers'>
                            <div className='order-product-address-heading'> PickUP details</div>
                             <div>Date: 29 Aug 2023 Time: 6.00pm</div>  
                        </div>
                        <div className='order-product-address-buyers'>
                            <div className='order-product-address-heading'> Seller address</div>
                            <div>Vaidehi Paradkar Raiya Kar Bhavan Nr Times Of India, Ashram Road, AHEMEDABAD, GUJARAT, INDIA - 380009</div>        
                        </div>
                    </div>
                    <div className='freightCalculator'>
                    <div className='freightCalculator-head'>
                        FREIGHT CALCULATOR
                      </div>
                      <div className='freightCalculator-content'>
                      <div className='freightCalculator-content-left'>
                     
        <div className='lv-form-field'>
        <div className='lv-field-name'>Pick-up Area Pincode*: </div>
        <TextField
    autoFocus
    autoComplete='off'
    variant='outlined'
    fullWidth
    type='text'
    placeholder='Enter 6 digit pickup area pincode'
    className="seller-form-field"
           />
         </div>
        <div className='lv-field-name'>Dimensions(Optional)</div>
        <div className='dimensions-container'>
          <TextField
            autoFocus
            autoComplete='off'
            variant='outlined'
            fullWidth
            type='text'
            placeholder='Length'
            className='dimension-field'
            value={dimensions.length}
            onChange={(e) => setDimensions({ ...dimensions, length: e.target.value })}
          />
          <TextField
            autoComplete='off'
            variant='outlined'
            fullWidth
            type='text'
            placeholder='Width'
            className='dimension-field'
            value={dimensions.width}
            onChange={(e) => setDimensions({ ...dimensions, width: e.target.value })}
          />
          <TextField
            autoComplete='off'
            variant='outlined'
            fullWidth
            type='text'
            placeholder='height'
            className='dimension-field'
            value={dimensions.width}
            onChange={(e) => setDimensions({ ...dimensions, width: e.target.value })}
          />
        </div>
        <div>
        <button className='Logistic-check-btn'>Check</button>
        </div>
                      </div>
                      <div className='freightCalculator-content-right'>
                      <div className='lv-field-name'>Delivery Area Pincode*</div>
          <TextField 
           autoFocus
    autoComplete='off'
    variant='outlined'
    fullWidth
    type='text'
    placeholder='Enter 6 digit pickup area pincode'
     className="seller-form-field" />
      <div className='lv-field-name'>Weight</div>
         <TextField
          autoFocus
          autoComplete='off'
          variant='outlined'
          type='text'
          placeholder='Kg'
          className='seller-form-field'
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
        />
                      </div>
                       
                      </div>
                      <div>
<div className='thirdPart-head'>THIRD PARTY (TBD)</div>
<table className='thirdPart-table-style'>
<tr>
<th>Provider</th>
<th>Price</th>
</tr>
<tr>
<td>Shiprocket</td>
<td>₹100</td>
</tr>
<tr>
<td>Shiprocket</td>
<td>₹100</td>
</tr>
<tr>
<td>Shiprocket</td>
<td>₹100</td>
</tr>
</table>
<div>
<button className='Logistic-check-btn' >RESET</button>
</div>



                     </div>
                      <div className='logistic-assignment-container'>
        <div className='logistic-assignment-head'>LOGISTIC ASSIGNMENT</div>
        <div >
        <div className='logistic-assignment-dropdown'>
        <FormControl fullWidth>
          <Select value={selectedVendor} onChange={handleVendorSelect}>
            <MenuItem value=''>Select a Vendor</MenuItem>
            <MenuItem value='vendor1'>Vendor 1</MenuItem>
            <MenuItem value='vendor2'>Vendor 2</MenuItem>
            <MenuItem value='vendor3'>Vendor 3</MenuItem>
            <MenuItem value='vendor4'>Vendor 4</MenuItem>
          </Select>
        </FormControl>
    </div>
    <div>
        <button className='local-common-btn'  onClick={handleSubmit} >Submit</button>
        </div>
       
    
        </div>
       
                     </div>
                   </div>
                  

                </div>
                
            </div>
        </LogisticAdminLayoutAdjustment>
    )
}

export default LogisticAdminThirdParty