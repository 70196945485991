import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import "../styles/AdminPlan.css"
import { API_BASE_URL } from '../App'

import {

  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Paper,
  Select,

  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import Heading from "../components/Heading";
import { useNavigate, useParams } from "react-router-dom";
import AdminLayoutAdjustment from "../components/AdminLayoutAdjustment";
import { confirmAlert, errorAlert, successAlert } from "../components/Alert";
import { AdminContext } from "../context/AdminContext";

function AdminSubscriptionPlanDetails() {

  const { accessToken } = useContext(AdminContext);


  const [rows, setRows] = useState([
    {
      description: "",
      descriptionValue: "",
      isValueView: "",
      displayorder: "",
    },
  ]);

  // Initial states
  const [open, setOpen] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [disable, setDisable] = React.useState(true);
  const [showConfirm, setShowConfirm] = React.useState(false);

  const handleAdd = () => {
    setRows([
      ...rows,
      {
        description: "",
        descriptionValue: "",
        isValueView: "",
        displayorder: rows?.length+1,
      },
    ]);
    setEdit(true);
  };


  const handleEdit = (i) => {

    setEdit(!isEdit);
  };

  const handleSave = () => {
    setEdit(!isEdit);
    setRows(rows);
    setDisable(true);
    setOpen(true);
  };

  const handleInputChange = (e, index) => {
    setDisable(false);
    const { name, value } = e.target;
    const list = [...rows];
    list[index][name] = value;
    setRows(list);
  };
  const [indexToRemove, setIndexToRemove] = useState();
  const handleConfirm = (i) => {
    setIndexToRemove(i);

    setShowConfirm(true);
    return (i);
  };


  const handleRemoveClick = () => {
    const list = [...rows];
    list.splice(indexToRemove, 1);
    setRows(list);
    setShowConfirm(false);


  };


  const handleNo = () => {
    setShowConfirm(false);
  };

  const [mySubsCurrentPlans, setMySubsCurrentPlans] = useState([]);
  // const [planFeatures, setPlanFeatures] = useState(features);
  const [allData, setAllData] = useState([]);
  const params = useParams();
  const subsid = params.subsId;
  ////console.log(subsid);
  // const subsid = 1;

  const fetchMySubsCurrentPlansData = async () => {
    const response = await fetch(`${API_BASE_URL}/subscription/api/v1/getall`, {
      method: "GET",
      headers:{
        "Authorization": `Bearer ${accessToken}`

      }
    });
    const data = await response.json();
    if (data?.status === 401) {
      errorAlert('','Session timed out. Please Log in');
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // navigate('/')
    }
    setAllData(data?.result);
    const filteredData = data.result.filter((plan) => plan.id == subsid);
    setMySubsCurrentPlans(filteredData[0]);
    ////console.log(filteredData[0])

  };

  useEffect(() => {
    fetchMySubsCurrentPlansData();
    // eslint-disable-next-line
  }, []);

  const deleteMySubsCurrentPlansData = async () => {
    const response = await fetch(`${API_BASE_URL}/subscription/api/v1/remove?planId=${subsid}`, {
      method: "Delete",
      headers:{
        "Authorization": `Bearer ${accessToken}`

      }
    });
    
    const data = await response.json();
    if (data?.status === 401) {
      errorAlert('','Session timed out. Please Log in');
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // navigate('/')
    }

    successAlert('','Plan Deleted Successfully')
    navigate('/adminPlanlist')

  };

  useEffect(() => {
    fetchMySubsCurrentPlansData();
    // eslint-disable-next-line
  }, []);



  const [planName, setPlanName] = useState();
  const [planValue, setPlanValue] = useState();
  const [planFrequency, setPlanFrequency] = useState();
  const [planDiscount, setPlanDiscount] = useState();
  const [planOrder, setPlanOrder] = useState();
  //   const [planFeatures, setPlanFeatures] = useState();

  useEffect(() => {
    setPlanName(mySubsCurrentPlans.title);
    setPlanValue(parseInt(mySubsCurrentPlans.value));
    setPlanFrequency(mySubsCurrentPlans?.isAnnual===true?"Yearly":"Monthly");
    setPlanDiscount(parseInt(mySubsCurrentPlans.annualDiscount));
    setPlanOrder(parseInt(mySubsCurrentPlans.displayOrder));
    setRows(mySubsCurrentPlans.features);

    ////console.log(rows);
    // eslint-disable-next-line
  }, [mySubsCurrentPlans]);
  const navigate = useNavigate();
  useEffect(() => {
    ////console.log(planFrequency)
  }, [planFrequency])

  const updatePlan = async () => {
    let headersList = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`
    };
    let updatedRows = rows.map((row) => {
      if (row.isValueView === "1") {
        return {
          ...row,
          isValueView: true
        };
      } else if (row.isValueView === "0") {
        return {
          ...row,
          isValueView: false
        };
      } else {
        return row;
      }
    });
   
    let bodyContent = JSON.stringify({
      "planid":subsid,
      "annualDiscount": planDiscount,
      // planFrequency:planFrequency,
      "isAnnual": planFrequency === "Yearly" ? true : false,
      "title": planName,
      "displayorder": parseInt(planOrder),
      "value": planValue,
      "features": updatedRows,
    });
    if (!planName) {
      errorAlert('', 'Plan name is missing');
      return;
    }
    
    if ( isNaN(parseInt(planValue))) {
      //console.log(planValue, typeof (planValue) , isNaN(parseInt(planValue)) )
      errorAlert('', 'Plan value is missing or not a valid integer');
      return;
    }
    
    if ( isNaN(parseInt(planDiscount))) {
      errorAlert('', 'Plan discount is missing or not a valid integer');
      return;
    }
    
    if ( isNaN(parseInt(planOrder))) {
      errorAlert('', 'Plan order is missing or not a valid integer');
      return;
    }
   
  else{
      let response = await 
    fetch (`${API_BASE_URL}/subscription/api/v1/update`,
    {
      method: "PUT",
      body: bodyContent,
      headers: headersList,
    });
    ////console.log(bodyContent);
    let data = await response.json();
    //console.log(data?.message)
    if(data?.message==="PUT Request successful."){

      //console.log(data);
      successAlert('','Plan Updated Successfully')
      navigate('/adminPlanlist')
    }
  

  };
}



  return (
    <AdminLayoutAdjustment>
      <Heading text="Subscription Plan Details" />

      <div className="admin-plan-basic-details">
        <div className="admin-plan-field">
          <div className="admin-plan-field-name">Plan Name</div>
          <TextField
            fullWidth
            variant="outlined"
            value={planName}
            onChange={(e) => setPlanName(e.target.value)}
          />
        </div>
        <div className="admin-plan-field">
          <div className="admin-plan-field-name">Plan Value</div>
          <TextField
            fullWidth
            // type="number"

            variant="outlined"
            value={planValue}
            onChange={(e) => setPlanValue(parseInt(e.target.value, 10))}
            />
        </div>
        <div className="admin-plan-field">
          <div className="admin-plan-field-name">Plan Frequency</div>
          <div className="admin-radio-field">
            <div>
              <input
                type="radio"
                name="frequency"
                id="Yearly"
                value="Yearly"
                checked={planFrequency === "Yearly"}
                onChange={(e) => setPlanFrequency(e.target.value)}
              />
              <label className="admin-radio-field-label">
                Yearly
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="frequency"
                id="Monthly"
                value="Monthly"
                checked={planFrequency === "Monthly"|| planFrequency===false}
                onChange={(e) => setPlanFrequency(e.target.value)}
              />
              <label className="admin-radio-field-label">
                Monthly
              </label>
            </div>
          </div>
        </div>
        <div className="admin-plan-field">
          <div className="admin-plan-field-name">Plan Discount</div>
          <TextField
            fullWidth
            variant="outlined"
            // type="number"

            value={planDiscount}
            onChange={(e) => setPlanDiscount(parseInt(e.target.value, 10))}
          />
        </div>
        <div className="admin-plan-field">
          <div className="admin-plan-field-name">Plan Order</div>
          <TextField
            fullWidth
            variant="outlined"
            // type="number"

            value={planOrder}
            onChange={(e) => setPlanOrder(parseInt(e.target.value, 10))}
          />
        </div>
      </div>
      <Heading text="Features" />

      <table
        class="admin-planlist-table"

      >
        <tr>
          <th>Feature Order</th>
          <th>Feature Name</th>
          <th>Feature Value</th>
          <th>Feature display</th>
          <th>Actions </th>
        </tr>
        {rows &&
          rows.map((row, i) => {
            return (
              <tr>
                {isEdit ? (
                  <>
                    <td data-th="Feature Order">
                      <TextField
                        value={row.displayorder}
                        name="displayorder"
                        // onChange={(e) => handleInputChange(e, i)}
                      />
                    </td>
                    <td data-th="Feature Name">
                      <TextField
                        value={row.description}
                        name="description"
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </td>
                    <td data-th="Feature Value">
                      <TextField
                        value={row.descriptionValue}
                        name="descriptionValue"
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </td>
                    <td data-th="Feature Display Value">
                      <Select
                        name="isValueView"
                        value={row.isValueView === true || row.isValueView === "1" ? true : false}
                        onChange={(e) => handleInputChange(e, i)}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </td>

                  </>
                ) : (
                  <>
                    <td data-th="Feature Order">{row.displayorder}</td>
                    <td data-th="Feature Name">{row.description}</td>
                    <td data-th="Feature Value">{row.descriptionValue}</td>
                    <td align="center" data-th="Feature Display Value">{row.isValueView === "1" || row.isValueView === true ? "Yes" : "No"}</td>
                    <td align="center" data-th="Actions">
                      {isEdit ? (

                        <ClearIcon onClick={() => handleConfirm(i)} />

                      ) : (
                        <DeleteOutlineIcon onClick={() => handleConfirm(i)} />

                      )}
                    </td>
                  </>
                )}

                {showConfirm && (
                  <div>
                    <Dialog
                      open={showConfirm}
                      onClose={handleNo}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      BackdropProps={{
                        style: {
                          background: "#ffffff",
                          opacity: '0.1',
                          padding: '2rem 5rem'
                        }
                      }}
                    >
                      <Paper sx={{ px: '5rem' }}>
                        <DialogTitle id="alert-dialog-title">
                          {"Confirm Delete"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Are you sure to delete
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <button
                            className="common-btn"
                            onClick={() => handleRemoveClick(i)}
                            color="primary"
                            autoFocus
                          >
                            Yes
                          </button>
                          <button
                            className="common-btn"
                            onClick={handleNo}
                            color="primary"

                          >
                            No
                          </button>
                        </DialogActions>
                      </Paper>
                    </Dialog>
                  </div>
                )}
              </tr>
            );
          })}
      </table>
      <div className="admin-plan-buttons">
        {isEdit ? (
          <div>
            <button className="common-btn" onClick={handleAdd}>
              ADD
            </button>
            {rows.length !== 0 && (
              <>
                {disable ? (
                  <button
                    className="common-btn"
                    align="right"
                    onClick={handleSave}
                  >
                    SAVE
                  </button>
                ) : (
                  <button
                    className="common-btn"
                    align="right"
                    onClick={handleSave}
                  >
                    SAVE
                  </button>
                )}
              </>
            )}
          </div>
        ) : (
          <div>
            <button className="common-btn" onClick={handleAdd}>
              ADD
            </button>
            <button className="common-btn" align="right" onClick={handleEdit}>
              EDIT
            </button>
          </div>
        )}
      </div>
      {!isEdit && <div style={{ margin: "2rem auto", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}><button style={{ fontSize: "1.5rem" }} className="common-btn" onClick={updatePlan}>Update Plan</button></div>}
          <div>
            <button type="button" className="common-button" onClick={()=>{confirmAlert('','Are you sure you want to delete this plan?',deleteMySubsCurrentPlansData)}}>Delete Plan</button>
          </div>
    </AdminLayoutAdjustment>
  );
}

export default AdminSubscriptionPlanDetails;
