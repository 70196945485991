import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../App";
import { CleaningServices } from "@mui/icons-material";

export const SellerContext = createContext();

export const SellerContextProvider = ({ children }) => {
  const [phoneNumberForOtp, setPhoneNumberForOtp] = useState("");
  const [sellerData, setSellerData] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [sellerPersonalData, setSellerPersonalData] = useState(null);
  // const [sellerUserType, setSellerUserType] = useState(3);
  const [isSellerAdmin, setIsSellerAdmin] = useState(true);
  const [selectedListItemIndex, setSelectedListItemIndex] = useState()
  const [sellerPlanDetails, setSellerPlanDetails] = useState();



  const navigate = useNavigate();

  // //console.log(process.env.REACT_APP_API_TEST);


  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setAccessToken(token);
      fetchData(token);
    }
  }, []);


  const handleAuthExpiry = (response)=>{
    if(response?.status===401){
      return true
    }else{
      return false
    }
  }

  const fetchData = async (accessToken) => {
    if(window.location.pathname==="/paymentConfirmation"){
      return;
    }
    
    try {
      const response = await fetch(
        `${API_BASE_URL}/users/api/v1/info`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      ////console.log(response);
      const data = await response.json();

      //console.log(data);
      setSellerData(data);
      if(!data?.result?.userID){
        return;
      }
      ////console.log("Before" , sellerData)
      ////console.log("After" , sellerData)
      const sellerStatus = await fetch(
        `${API_BASE_URL}/seller/api/v1/GetSellerById&GetAllSeller?SellerId=${data?.result?.typeOfUser==='SLADM'?data?.result?.userID:data?.result?.userAdminId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      ////console.log(sellerStatus);
      const status = await sellerStatus.json();
      ////console.log(status);
      setSellerPersonalData(status);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (sellerStatus?.ok) {
        ////console.log(status.result.status);

        if (
          status?.result?.status === "P" ||
          status?.result?.status === "R"
        ) {
          navigate("/sellerPersonalData");
          return data?.result?.userID
        } else if (status?.result?.status === "C") {
          navigate("/SellerCategoriesListingPage");
          return data?.result?.typeOfUser === "SLADM"?data?.result?.userAdminId:data?.result?.userID
        } else {
          ////console.log("User type not found");
        }
      } else if (!sellerStatus?.ok) {
        // ////console.log(data)
        // ////console.log(data.result?.typeOfUser)
        if (data.result?.typeOfUser === "SLUSR" || data?.result?.typeOfUser === "SLADM") {
          navigate('/SellerCategoriesListingPage');
          return data?.result?.typeOfUser === "SLADM"?data?.result?.userAdminId:data?.result?.userID
        }
        else {
          // alert('Something went wrong')
          return;
        }
      }


    } catch (error) {
      //console.error(error);
    }
  }
  const fetchDataStore = async (accessToken) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/users/api/v1/info`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      setSellerData(data);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return data;
    }
    catch (error) {
      //console.error(error);
    }
  }

  const login = async (email, password, isSellerAdmin) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: email,
      password: password,
      mobileNumber: "",
      typeOfUser: !isSellerAdmin ? 4 : 3,
      otp: "",
      isOTPBasedLogin: false,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      ////console.log(isSellerAdmin)
      const response = await fetch(
        `${API_BASE_URL}/users/api/v1/login`,
        requestOptions
      );
      const result = await response.json();
      ////console.log(result);
      // make second API call here if response is ok
      localStorage.setItem("token", result.result.accessToken);
      setAccessToken(result.result.accessToken);

      const resp = await fetchData(result.result.accessToken);
      // console.log(resp);
      const check = await fetchSellerPlan(resp);
      // console.log(check);
      // console.log(result);
      if(check){
        return result;
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const loginWithOtp = async (phone, otp, isSellerAdmin) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: "",
      password: "",
      mobileNumber: phone,
      typeOfUser: !isSellerAdmin ? 4 : 3,
      otp: otp,
      isOTPBasedLogin: true,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${API_BASE_URL}/users/api/v1/login`,
        requestOptions
      );
      const result = await response.json();
      ////console.log(result);
      // make second API call here if response is ok
      if(result?.result?.accessToken){
        console.log('aaaaa')
        localStorage.setItem("token", result.result.accessToken);
        setAccessToken(result.result.accessToken);
        const resp = await fetchData(result.result.accessToken);
        const check = await fetchSellerPlan(resp);
        console.log(check);
        console.log(result);
        if(check){
          return result;
        }
      }
    } catch (error) {
      ////console.log("error", error);
    }
  };
  const loginWithOtpForForgetPassword = async (phone, otp, isSellerAdmin) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: "",
      password: "",
      mobileNumber: phone,
      typeOfUser: !isSellerAdmin ? 4 : 3,
      otp: otp,
      isOTPBasedLogin: true,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${API_BASE_URL}/users/api/v1/login`,
        requestOptions
      );
      const result = await response.json();
      ////console.log(result);
      // make second API call here if response is ok
      if(result?.result?.accessToken){
        // console.log('aaaaa')
        localStorage.setItem("token", result.result.accessToken);
        setAccessToken(result.result.accessToken);
        // const resp = await fetchData(result.result.accessToken);
        // const check = await fetchSellerPlan(resp);
        // console.log(check);
        // console.log(result);
          return result;
      }
    } catch (error) {
      ////console.log("error", error);
    }
  };

  const redirectLogin = async (email, password) => {
    ////console.log(email, password);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: email,
      password: password,
      mobileNumber: "",
      typeOfUser: 3,
      otp: "",
      isOTPBasedLogin: false,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${API_BASE_URL}/users/api/v1/login`,
        requestOptions
      );
      const result = await response.json();
      ////console.log(result);
      // make second API call here if response is ok
      localStorage.setItem("token", result?.result?.accessToken);
      setAccessToken(result?.result?.accessToken);
      const resp = await fetchData(result.result.accessToken);
      // const check = await getSellerPlan();
      // console.log(check);
      // console.log(result);
      const check = await fetchSellerPlan(resp);

      if(check){
        return result;
      }
    } catch (error) {
      ////console.log("error", error);
    }
  };

  const logout = () => {
    setSellerData(null);
    navigate("/");
    localStorage.setItem("token", null);
  };


  const getSellerPlan = async () => {
    console.log('startr')
    try {
      if (!sellerData?.result?.userID) {
        // console.log('No data') 
        return;
      }
      // const plan = await fetch(`${API_BASE_URL}/subscription/api/v1/mapping/get/${sellerData?.result?.userID}/false/true`, {
      const plan = await fetch(`${API_BASE_URL}/subscription/api/v1/mapping/get?sellerId=${sellerData?.result?.typeOfUser==='SLADM'?sellerData?.result?.userID:sellerData?.result?.userAdminId}&onlyActivePlan=true&checkValidation=true`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      })
      const response = await plan.json();
      // const handleAuthError = handleAuthExpiry(response);
      // if (handleAuthError === true) {
      //   errorAlert('', 'Your Session has expired');
      //   logout();
      //   return;
      // }
      // console.log(response)
      // console.log('Anand')
      setSellerPlanDetails(response?.result)   
       console.log(response?.result);
      return (response);
    } catch (e) {
      // console.log("COuldnt fetch plan")
      //console.log('CArch')
    }

  }
  const fetchSellerPlan = async (id) => {
    // console.log('startr')
    try {
      if (!id) {
        // console.log('No data')
        return;
      }
      // const plan = await fetch(`${API_BASE_URL}/subscription/api/v1/mapping/get/${sellerData?.result?.userID}/false/true`, {
      const plan = await fetch(`${API_BASE_URL}/subscription/api/v1/mapping/get?sellerId=${sellerData?.result?.typeOfUser==='SLADM'?id:id}&onlyActivePlan=true&checkValidation=true`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }

      })
      const response = await plan.json();
      // console.log(response)
      // console.log('Anand')
      setSellerPlanDetails(response?.result)   

      return (response.result);
    } catch (e) {
      // console.log("couldn't fetch plan")
      //console.log('CArch')
    }

  }

  



  return (
    <SellerContext.Provider
      value={{
        sellerData,
        loginWithOtp,
        setSellerData,
        setPhoneNumberForOtp,
        phoneNumberForOtp,
        sellerPersonalData,
        redirectLogin,
        login,
        logout,
        accessToken,
        getSellerPlan,
        sellerPlanDetails,
        isSellerAdmin,
        setIsSellerAdmin,
        fetchDataStore,
        selectedListItemIndex,
        setSelectedListItemIndex,
        handleAuthExpiry,
        fetchData,
        loginWithOtpForForgetPassword

      }}
    >
      {children}
    </SellerContext.Provider>
  );
};
