import React from "react";
import "../styles/Common.css";
import { useNavigate } from "react-router-dom/dist";
import { useFilterContext } from "../context/FilterContext";

function CustomerHeading(props) {
    const navigate = useNavigate();
    const {updateFilters, newArrival, setNewArrival, fetchProductData} = useFilterContext();
  return (
    <div className="customer-heading-container">
      <div className="customer-heading-text">{props.text}</div>
      {props.viewAll&&<div className='customer-heading-view-all' style={{cursor:'pointer'}}onClick = {()=>{ window.scrollTo(0,0);navigate(`${props.nav}`); updateFilters({
    searchValue:'',
    category: '',
    // priceRange: { min: 0, max: 100 },
  } 
  )
 props.newArrival&& props.newArrival===true?setNewArrival(true):setNewArrival(false);

  fetchProductData('','','', props.newArrival);

  
  }}>View All</div>}
    </div>
  );
}

export default CustomerHeading;