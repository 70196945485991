import React from 'react'
import '../styles/CustomerLandingPage.css';
import { useNavigate  } from 'react-router-dom'

function CustomerTaleOfCraftsCard(props) {
  const navigate = useNavigate();
  return (
    <div className='cutomer-tale-craft-box'>
              <div  onClick={()=>{ navigate(`/DetailedBlogPage/${props.factId}`);   window.scrollTo(0, 0);}}>
                <img src={props.imageUrl1} className='cust-craft-image'/>
              </div>
              <div className='customer-tale-of-crafts-card-head1'>{props.blogName} </div>
              <div className='customer-tale-of-crafts-card-desc1' >{props.blogArticle} </div> 
             
    </div>
  )
}

export default CustomerTaleOfCraftsCard


{/* <div className='customer-tale-of-crafts-card' onClick={() => { navigate(`/DetailedBlogPage/${props.factId}`); window.scrollTo(0, 0); }}>
  <div className='overlay-container' style={{ backgroundImage: `url(${props.imageUrl1})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
    <div className='overlay-image'></div>
  </div>
  <div className='customer-tale-of-crafts-card-head'>{props.blogName}</div>
  <div className='customer-tale-of-crafts-card-desc'>{props.blogArticle}</div>
</div> */}


