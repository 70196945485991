import React,{useState} from "react";
import "../styles/Seller.css";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SellerCustomersCard from "../components/SellerCustomersCard";
import { sellerCustomerData } from "../SellerCustomerData";
import SellerLayout from "../components/SellerLayout";

export default function SellerCustomerList() {
  const [status, setStatus] = useState(10);

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  return (
    <SellerLayout>
      {/* <SubsNavbar />
      <SubsSubnav /> */}
            {/* <SellerNavbar2 /> */}

      <div className="page-content">
    <div>
        <div style={{ textAlign: "center", width: "10%", margin: "3rem auto" }}>
          <div className="seller-registration-head">
            <div className="seller-registration-head-text">Customers</div>
            <div className="seller-registration-head-underline"></div>
          </div>
        </div>
        <div className="seller-products-list-navbar">
          {/* <div className="seller-products-list-add-products">
                <button className="common-button" onClick={addNewProduct}>+ Add New Products</button>
            </div> */}
          <div className="seller-products-list-search-and-filter">
          {/* <button className="common-button">+ Add New Products</button> */}

            {/* <div className="seller-products-list-navbar-search-bar">
              <SearchIcon
                className="seller-products-list-navbar-search-icon"
                icon="@Icons.Filled.Search"
                fontSize="medium"
              />
              <input
                className="seller-products-list-navbar-search"
                type="string"
                placeholder="search"
              />
            </div> */}
            <div style={{display:"flex", alignItems:"center"}}>
            <div style={{fontSize:"1.2rem", marginRight:"2rem"}}>Status : </div>
            <div className="seller-products-list-category">
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth style={{ background: "white" }}>
                  {/* <InputLabel id="demo-simple-select-label">Category</InputLabel>   */}
                  <Select
                    style={{ background: "white" }}
                    //   labelId="demo-simple-select-label"
                    //   id="demo-simple-select"
                    value={status}
                    //   label="Age"
                    displayEmpty
                    onChange={handleChange}
                  >
                    <MenuItem value={10}>Customers</MenuItem>
                    <MenuItem value={20}>Approved</MenuItem>
                    <MenuItem value={30}>Not Approved</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            </div>
          </div>
        </div>
        <div className="seller-products-list-table-head">
        <div className="seller-products-list-table-product-item-small">
          </div>
          <div className="seller-products-list-table-product-item">
            Customer Name
          </div>
          <div className="seller-products-list-table-product-item">
            Email Address
          </div>
          <div className="seller-products-list-table-product-item">
            User Type
          </div>
          <div className="seller-products-list-table-product-item">
            Phone Number
          </div>
          <div className="seller-products-list-table-product-item">Actions</div>
        </div>
        <div>
          {sellerCustomerData.map((cardProps) => {
           
              return (status === 10)&&<SellerCustomersCard LiveOrder {...cardProps} />
            
            // if (status === 20) {
            //   if (cardProps.sellerProductsOrderStatus === "Approved") {
            //     return <SellerOrderCard LiveOrder {...cardProps} />;
            //   }
            // }
            // if (status === 30) {
            //   if (cardProps.sellerProductsOrderStatus === "Not Approved") {
            //     return <SellerOrderCard LiveOrder {...cardProps} />;
            //   }
          // }
            
          })}
        </div>
      </div>
    
      </div>
      {/* <SellerFooter /> */}
    </SellerLayout>
    
  );
}
