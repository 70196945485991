import React, { useState, useEffect, useRef } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { CircularProgress, TextField } from "@mui/material";
import "../styles/Address.css";
import "../styles/Common.css"
import Modal from "@mui/material/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import { useContext } from "react";
import ToastComponent from "./ToastComponent";
import { API_BASE_URL } from "../App";
import { SellerContext } from "../context/SellerContext";
import { errorAlert } from "./Alert";

const phoneRegExp = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;
const fullNameRegExp = /^[a-zA-Z][a-zA-Z']{0,}(?: [a-zA-Z'][a-zA-Z']*){0,2}$/;
const placeRegExp = /^[A-Za-z\s]{1,50}$/;
const buildingRegExp = /^\d+[A-Za-z]?\s?[/|-]?\d*[A-Za-z]?$/;

export default function SellerAddressModal(props) {

  const toastRef= useRef();


    const { sellerData, accessToken, handleAuthExpiry, logout } = useContext(SellerContext);
  // const [openModal, setOpenModal] = useState(false);
  // const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => {
    props.onClose();
  };  const [addressData, setAddressData] = useState([]);
  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    contactName: yup
    .string("Enter Your First Name")
    .matches(fullNameRegExp, "Name is invalid")
    .trim()
      .required("Name is required")
      .min(1, "Name should minimum 3 characters ")
      .max(50, "Name should not exceed 50 characters"),
      pincode: yup.string().trim()
      .required("Pincode is required")
      .matches(/^\d{6}$/, "Invalid PIN code. It should be 6 digits."),
      state: yup
      .string("Enter state")
      .required("State is required")
      .matches(placeRegExp, "Invalid State")
      .max(50, "State should be 50 characters or less"),
    city: yup
      .string("Enter city")
      .required("City is required")
      .matches(placeRegExp, "Invalid City")
      .max(50, "City should be 50 characters or less"),

    phoneNumber: yup
      .string().trim()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is required*")
      .min(10, "Phone number must consist of 10 digits")
      .max(10, "Phone number must consist of 10 digits"),
      addressLine1: yup
      .string("Enter Building Number")
      .trim() 
      .matches(buildingRegExp, "Building Number is not valid")
      .required("Building number is required"),
    addressLine2: yup
      .string("Enter Street Address")
      .trim() 
      .max(200, "Address should not exceed 200 characters")
      .required("Street Address is required"),
      country: yup
      .string("Enter country Address")
      .required("Country is required")
      .matches(placeRegExp, "Invalid Country name")
      .max(50, "Country should be 50 characters or less"),

    addressType: yup
    .string("Enter AddressType").trim()
    .required("AddressType is required")
    .max(50, "Address should be 50 characters or less"),
  });

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  const formik = useFormik({
    initialValues: {
      contactName: props.edit? props.address?.contactName:'',
      email: props.edit? props.address?.email:'',
      pincode: props.edit? props.address?.pincode:'',
      addressLine1: props.edit? props.address?.addressLine1:'',
      addressLine2: props.edit? props.address?.addressLine2:'',
      city: props.edit? props.address?.city:'',
      state: props.edit? props.address?.state:'',
      country: props.edit? props.address?.country:'',
      addressType: props.edit? props.address?.addressType:'',
      phoneNumber: props.edit? props.address?.phoneNumber:'',
      isDefault:props.edit? props.address?.isDefault:''
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      //   handleFormSubmit(values);
      //   setAddressForm(false);
      props.edit ? handleFormEdit(props.address.id, values) : handleFormSubmit(values);
      setCountry();
      setCity();
      setState();
      // resetForm({ values: "" });
      
      
    //   props.fetchAddressData();
      //   setOpenModal(false);
      props.onClose();
    },
  });
//   async function getPincode(code) {
//     await fetch(`https://api.postalpincode.in/pincode/${code}`, {
//       method: "GET",
//     })
//       .then((res) => res?.json())
//       .then((res) => {
//         setCountry(res[0]?.PostOffice[0]?.Country);
//         setState(res[0]?.PostOffice[0]?.State);
//         setCity(res[0]?.PostOffice[0]?.District);
      
//       });
//   }
//   useEffect(() => {
//     if (formik.values.pincode.length === 6) {
//       getPincode(formik.values.pincode);
//     }
//     // eslint-disable-next-line
//   }, [formik.values.pincode]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const requestBody = {
        userID: sellerData?.result?.userID,
        email: sellerData?.result?.email,
        contactName: formik.values.contactName,
        phoneNumber: formik.values.phoneNumber,
        addressLine1: formik.values.addressLine1,
        addressLine2: formik.values.addressLine2,
        city:  formik.values.city,
        pincode: formik.values.pincode,
        state:  formik.values.state,
        country:  formik.values.country,
        addressType: formik.values.addressType,
        countryCode: formik.values.countryCode,
        isDefault: formik.values.isDefault,
        IsSellerAddress: true
      };

      const response = await fetch(
        `${API_BASE_URL}/address/api/v1/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(requestBody),
        }
      );
        setLoading(false);
        const handleAuthError = handleAuthExpiry(response);
        if (handleAuthError === true) {
          setLoading(false);
          errorAlert('', 'Your Session has expired');
          logout();
          return;
        }
      if (response.ok) {
        toastRef.current.showToast("Address Added Successfully.", "success");
        props.fetchAddressData();
        handleModalClose();
      } else {
        toastRef.current.showToast("Address Addition Unsuccessfully.", "error");
      }
    } catch (error) {
      setLoading(false);
      toastRef.current.showToast("Something went wrong, Try again later.", "error");
    }
  };

  const handleFormEdit = async (addressId, values) => {
    setLoading(true);
    // event.preventDefault();
    try {
      const response = await fetch(
        `${API_BASE_URL}/address/api/v1/update`,
        {
          method: "PUT",
          body: JSON.stringify({
            userID: sellerData?.result?.userID,
            email: sellerData?.result?.email,
            contactName: formik.values.contactName,
            phoneNumber: formik.values.phoneNumber,
            addressLine1: formik.values.addressLine1,
            addressLine2: formik.values.addressLine2,
            city: formik.values.city,
            pincode: formik.values.pincode,
            state: formik.values.state,
            country: formik.values.country,
            addressType: formik.values.addressType,
            countryCode: formik.values.countryCode,
            isDefault: formik.values.isDefault,
            addressId: addressId,
          }),
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json-patch+json",
          },
        }
      );
      const data = await response.json();
      setLoading(false)
      //console.log(data);
      if (response.ok) {
        // successAlert("Address Updated sucessfully");
        toastRef.current.showToast("Address Updated Successfully.", "success");
        const updatedAddressData = { ...addressData, ...data };
        setAddressData(updatedAddressData);
        props.fetchAddressData();
        handleModalClose();
        
      } else {
        toastRef.current.showToast("Address Update Unsuccessfully.", "error");

        throw new Error("Failed to update Address");
      }
    } catch (error) {;
      setLoading(false)
      toastRef.current.showToast("Something went wrong, Try again later.", "error");
    }
  };


  return (
    <div>
      <ToastComponent ref={toastRef} timeout={2000} />

      {!props.edit && <Modal
        open={props.open}
        onClose={props.onClose}
        style={{ overflow: "scroll" }}
      >
        <div className="address-modal">
          <ClearIcon
            className="address-close-button"
            onClick={handleModalClose}
          />

          <form className="address-page-form" onSubmit={formik.handleSubmit} >
            <div className="address-page-form-title">ADD ADDRESS</div>
            <div className="address-details-fields">
              <div className="address-contact-personal-details-content">
                <div className="address-modal-title">Contact Details</div>
                <div className="address-form-field">
                  {/* <div className="field-name">Full Name: </div> */}
                  <TextField
                    autoFocus
                    autoComplete="off"
                    variant="outlined"
                    fullWidth
                    type="text"
                    id="address-fullname"
                    name="contactName"
                    className="address-field"
                    placeholder="Name* "
                    value={
                      formik.values.contactName
                    }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.contactName &&
                      Boolean(formik.errors.contactName)
                    }
                    helperText={
                      formik.touched.contactName && formik.errors.contactName
                    }
                  />
                </div>
                <div className="address-form-field">
                  {/* <div className="field-name">Contact: </div> */}
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    type="text"
                    id="address-contact"
                    name="phoneNumber"
                    className="address-field"
                    placeholder="Mobile Number*"
                    value={
                        formik.values.phoneNumber
                    }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phoneNumber &&
                      Boolean(formik.errors.phoneNumber)
                    }
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                  />
                </div>
              </div>
              {/* <Pincode /> */}
              {/* {////console.log(formik.values.fullname)} */}
              <div className="address-contact-details-content">
                <div className="address-modal-title">Address</div>
                <div className="address-form-field">
                  {/* <div className="field-name">Street Address: </div> */}
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    type="text"
                    id="address-street"
                    name="addressLine1"
                    className="address-field"
                    placeholder="Building Number *"
                    value={
                      
                         formik.values.addressLine1
                    }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.addressLine1 &&
                      Boolean(formik.errors.addressLine1)
                    }
                    helperText={
                      formik.touched.addressLine1 && formik.errors.addressLine1
                    }
                  />
                </div>
                <div className="address-form-field">
                  {/* <div className="field-name">Street Address: </div> */}
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    type="text"
                    id="address-street"
                    name="addressLine2"
                    className="address-field"
                    placeholder="Address(Street,Area) *"
                    value={
                      
                         formik.values.addressLine2
                    }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.addressLine2 &&
                      Boolean(formik.errors.addressLine2)
                    }
                    helperText={
                      formik.touched.addressLine2 && formik.errors.addressLine2
                    }
                  />
                </div>
                <div className="address-form-field">
                  {/* <div className="field-name">Pin Code: </div> */}
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    type="text"
                    id="address-pincode"
                    name="pincode"
                    className="address-field"
                    placeholder="Pin Code* "
                    value={
                      formik.values.pincode 
                    }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.pincode && Boolean(formik.errors.pincode)
                    }
                    helperText={formik.touched.pincode && formik.errors.pincode}
                  />
                  {/* <div className="address-i" id="address-i">
                  <p>i</p>
                </div> */}
                  <div   className="address-instructions"    id="address-instructions" >
                    Enter your PinCode to get Country, State and City
                  </div>
                </div>
                <div className="address-form-field">
                  {/* <div className="field-name">Country: </div> */}
                  <TextField
                    variant="outlined"
                    fullWidth
                    // disabled
                    type="text"
                    id="address-country"
                    name="country"
                    className="address-field"
                    placeholder="Country * "
                    // value={
                    //   country 
                    // }
                    value={
                      formik.values.country
                     }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.country && Boolean(formik.errors.country)
                    }
                    helperText={formik.touched.country && formik.errors.country}
                  />
                </div>
                <div className="State-city-address-form-field">
                <div className="address-form-field">
                  {/* <div className="field-name">State: </div> */}
                  <TextField
                    variant="outlined"
                    fullWidth
                    // disabled
                    type="text"
                    id="address-state"
                    name="state"
                    className="address-field-disabled"
                    placeholder="State *"
                  //   value={
                  //  state 
                  //   }
                    // disabled
                    value={
                      formik.values.state
                     }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.state && Boolean(formik.errors.state)
                    }
                    helperText={formik.touched.state && formik.errors.state}
                  />
                </div>
                <div className="address-form-field">
                  {/* <div className="field-name">City: </div> */}
                  <TextField
                    variant="outlined"
                    fullWidth
                    // disabled
                    type="text"
                    id="address-city"
                    name="city"
                    className="address-field-disabled"
                    placeholder="City *"
                    // value={ city }
                    // disabled
                    value={
                      formik.values.city
                     }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.city && Boolean(formik.errors.city)
                    }
                    helperText={formik.touched.city && formik.errors.city}
                  />
                </div>
                </div>
                {/* <Pincode /> */}
                {/* {////console.log(formik.values.pincode)} */}

                <div className="address-form-field">
                  {/* <div className="field-name">Address Type: </div> */}
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    type="text"
                    id="address-landmark"
                    name="addressType"
                    className="address-field"
                    placeholder="Address Type* "
                    value={
                     formik.values.addressType
                    }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.addressType &&
                      Boolean(formik.errors.addressType)
                    }
                    helperText={
                      formik.touched.addressType && formik.errors.addressType
                    }
                  />
                </div>
              </div>
            </div>
            <div className="address-form-btn">
              {/* <input
                type="submit"
                className="submit-button"
                value={props.edit?"Edit Address":"Add Address"}
                style={{ position: "relative", right: "5rem" }}
              /> */}

              {props.edit && !loading &&(
                <button
                  className="submit-button"
                  type="button"
                  onClick={() => {
                    formik.handleSubmit(props.addressId);
                  }}
                >
                  Edit Address
                </button>
              )}
              {props.edit && loading && <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "pink" }} /></div>}

              
              {!props.edit && !loading &&(
                <button
                  className="buyer-save-button"
                  type="button"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  Save
                </button>
              )}
              {!props.edit && loading && <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "pink" }} /></div>}

            </div>
          </form>
        </div>
      </Modal>}

      {props.edit && <Modal
        open={props.open}
        onClose={props.onClose}
        style={{ overflow: "scroll" }}
      >
        <div className="address-modal">
          <ClearIcon
            className="address-close-button"
            onClick={handleModalClose}
          />
          <form className="address-page-form" onSubmit={formik.handleSubmit}>
            <div className="address-page-form-title">EDIT ADDRESS</div>
            <div className="address-details-fields">
              <div className="address-contact-personal-details-content">
                <div className="address-modal-title">Contact Details</div>
                <div className="address-form-field">
                  {/* <div className="field-name">Full Name: </div> */}
                  <TextField
                    autoFocus
                    autoComplete="off"
                    variant="outlined"
                    fullWidth
                    type="text"
                    id="address-fullname"
                    name="contactName"
                    className="address-field"
                    placeholder="Name* "
                    value={
                      formik.values.contactName
                    }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.contactName &&
                      Boolean(formik.errors.contactName)
                    }
                    helperText={
                      formik.touched.contactName && formik.errors.contactName
                    }
                  />
                </div>
                <div className="address-form-field">
                  {/* <div className="field-name">Contact: </div> */}
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    type="text"
                    id="address-contact"
                    name="phoneNumber"
                    className="address-field"
                    placeholder="Mobile Number*"
                    value={
                        formik.values.phoneNumber
                    }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phoneNumber &&
                      Boolean(formik.errors.phoneNumber)
                    }
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                  />
                </div>
              </div>
              {/* <Pincode /> */}
              {/* {////console.log(formik.values.fullname)} */}
              <div className="address-contact-details-content">
                <div className="address-modal-title">Address</div>
                <div className="address-form-field">
                  {/* <div className="field-name">Street Address: </div> */}
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    type="text"
                    id="address-street"
                    name="addressLine1"
                    className="address-field"
                    placeholder="Building Number *"
                    value={
                        formik.values.addressLine1
                    }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.addressLine1 &&
                      Boolean(formik.errors.addressLine1)
                    }
                    helperText={
                      formik.touched.addressLine1 && formik.errors.addressLine1
                    }
                  />
                </div>
                <div className="address-form-field">
                  {/* <div className="field-name">Street Address: </div> */}
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    type="text"
                    id="address-street"
                    name="addressLine1"
                    className="address-field"
                    placeholder="Address(Street,Area) *"
                    value={
                        formik.values.addressLine2
                    }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.addressLine2 &&
                      Boolean(formik.errors.addressLine2)
                    }
                    helperText={
                      formik.touched.addressLine2 && formik.errors.addressLine2
                    }
                  />
                </div>
                <div className="address-form-field">
                  {/* <div className="field-name">Pin Code: </div> */}
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    type="text"
                    id="address-pincode"
                    name="pincode"
                    className="address-field"
                    placeholder="Pin Code* "
                    value={
                        formik.values.pincode
                    }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.pincode && Boolean(formik.errors.pincode)
                    }
                    helperText={formik.touched.pincode && formik.errors.pincode}
                  />
                  {/* <div className="address-i" id="address-i">
                  <p>i</p>
                </div> */}
                  <div
                    className="address-instructions"
                    id="address-instructions"
                  >
                    Enter your PinCode to get Country, State and City
                  </div>
                </div>
                <div className="address-form-field">
                  <TextField
                    variant="outlined"
                    fullWidth
                    // disabled
                    type="text"
                    id="address-country"
                    name="country"
                    className="address-field"
                    placeholder="Country * "
                    // value={
                    //     country
                    // }
                    // disabled
                    value={
                      formik.values.country
                  }
                  onChange={formik.handleChange}
                  error={
                    formik.touched.country &&
                    Boolean(formik.errors.country)
                  }
                  helperText={
                    formik.touched.country && formik.errors.country
                  }
                  />
                </div>
                <div className="State-city-address-form-field">
                <div className="address-form-field">
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    id="address-state"
                    name="state"
                    className="address-field-disabled"
                    placeholder="State *"
                    // value={
                    //    state
                    // }
                    // disabled
                    value={
                      formik.values.state
                  }
                  onChange={formik.handleChange}
                  error={
                    formik.touched.state &&
                    Boolean(formik.errors.state)
                  }
                  helperText={
                    formik.touched.state && formik.errors.state
                  }
                  />
                </div>
                <div className="address-form-field">
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    id="address-city"
                    name="city"
                    className="address-field-disabled"
                    placeholder="City *"
                    // value={
                    //   city
                    // }                    // disabled
                    value={
                      formik.values.city
                  }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.city &&
                      Boolean(formik.errors.city)
                    }
                    helperText={
                      formik.touched.city && formik.errors.city
                    }
                  />
                </div>
                </div>

                <div className="address-form-field">
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    type="text"
                    id="address-landmark"
                    name="addressType"
                    className="address-field"
                    placeholder="AddressType "
                    value={
                        formik.values.addressType
                    }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.addressType &&
                      Boolean(formik.errors.addressType)
                    }
                    helperText={
                      formik.touched.addressType && formik.errors.addressType
                    }
                  />
                </div>
              </div>
            </div>
            <div className="address-form-btn">
              {props.edit && (
                <button
                  className="submit-button"
                  type="button"
                  onClick={() => {
                    formik.handleSubmit(props.addressId);
                  }}
                >
                  Edit Address
                </button>
              )}
              {!props.edit && (
                <button
                  className="buyer-save-button"
                  type="button"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  Add Address
                </button>
              )}
            </div></form>
        </div>
      </Modal>}
    </div>
  );
}
