import React, { useState } from "react";
import "../styles/SellerLogin.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import Button from "@mui/joy/Button";
import { CircularProgress, IconButton, Switch, TextField } from "@mui/material";
import "../styles/Common.css";
import { SellerContext } from "../context/SellerContext";
import { useContext } from "react";
import { Alert } from "@mui/lab";
import sellerLoginImg from '../Images/logLeft.png';
import "react-toastify/dist/ReactToastify.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { alpha, styled } from "@mui/material/styles";
import {  red } from "@mui/material/colors";
import { errorAlert, successAlert } from "../components/Alert";
import CardComponent from "../components/CardComponent";
import "../styles/SellerPageLogin.css"
import "../styles/SellerCommonStyles.css"

const CustomSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: red[400],
  
      "&:hover": {
        backgroundColor: alpha(red[400], theme.palette.action.hoverOpacity),
      },
    },
  
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: red[400],
    },
  }));
  // import {  IconButton, InputAdornment } from '@material-ui/core';
  const passwordRegexp = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{6,20}$)"
  );
  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .trim()
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .matches(passwordRegexp, "Password is not valid")
      .min(8, "Password is not valid ")
      .required("Password is required"),
  });
function SellerPageLogin() {
    const {isSellerAdmin, setIsSellerAdmin} = useContext(SellerContext);
    // const [rememberMe, setRememberMe] = useState(false);
    // const [rememberMeChecked, setRememberMeChecked] = useState(false);
    const [loading, setLoading ] = useState(false);
    
    const handleisSellerAdminChange = (isSellerAdmin) => {
      setIsSellerAdmin(!isSellerAdmin);
      ////console.log(isSellerAdmin);
    };
    
  
    const navigate = useNavigate();
    // const [token, setToken] = useState(true);
    // const [success, setSuccess] = useState(false);
    const [error] = useState(false);
    // const [state, setState] = useContext(Context);
    const { login } = useContext(SellerContext);
  
    const formik = useFormik({
      initialValues: {
        email: "",
        password: "",
        checkbox: false,
      },
      validationSchema: validationSchema,
  
      onSubmit: async (values) => {
        setLoading(true);
        const result = await login(values.email.trim(), values.password, isSellerAdmin);
        if (!result?.result?.accessToken) {
          setLoading(false);
          errorAlert('','Unsuccessful Login',error)
        } else {
          successAlert('','Login Successful','success')
          setLoading(false);
  
        }
        // navigate("/SellerDashboard");
      },
    });
    // const handleSubmit = (e) => {
    //   e.preventDefault();
  
    //   if (!rememberMeChecked) {
    //     alert("Please check the 'Remember Me' checkbox.");
    //     return;
    //   }
  
    //   // Proceed with form submission logic
    //   // ...
    // };
  
    const handlePasswordChange = (event) => {
      const value = event.target.value;
      setPasswordEntered(value !== "");
    };
  
    const [showPassword, setShowPassword] = useState(false);
    const [passwordEntered, setPasswordEntered] = useState(false);
  
    const handleTogglePassword = () => {
      setShowPassword(!showPassword);
    };
  return (
    <CardComponent cardImg={<img loading="lazy" className="seller-login-img" src={sellerLoginImg} alt="" />} imageFirst={true}  >
          <div className="seller-login-left">
        <div className="seller-login-left-content">
          <div className="sellerSignIn-Layout-head">Sign In</div>

          {error && (
            <Alert severity="error" className="seller-login-page-error-toast">
              Invalid Email or Password
            </Alert>
          )}

          <form
            className="sellerSignIn-input-form-fields"
            onSubmit={formik.handleSubmit}
          >
            <div className="seller-login-form-textfields">
                <div className="seller-login-modal-name-field">
                <div className="seller-login-modal-field-name ">
                    Email Address
                  </div>
                  <div className="textField-Seller-login">
                <TextField
                  autoFocus
                  variant="outlined"
                  fullWidth
                  type="text"                 
                  name="email"
                  className="seller-login-email-textfield"
                  placeholder="Enter Email Address * "
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                </div>
                </div>
                <div className="seller-login-modal-name-field">

        <div className="seller-login-modal-field-name ">
                    Email Address
                  </div>
                  <div className="textField-Seller-login">
                <TextField
                  variant="outlined"
                  // type="text"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  fullWidth
                  className="seller-login-email-textfield"
                  placeholder="Enter Your Password * "
                  value={formik.values.password}
                  onChange={(event) => {
                    formik.handleChange(event);
                    handlePasswordChange(event);
                  }}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: passwordEntered && (
                      <IconButton onClick={handleTogglePassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
                </div>
                </div>
                </div>
                <div className="seller-login-Forget-terms">
              <span
                onClick={() => {
                  navigate("/sellerForgetPassword");
                }}
              >
                Forget Password?
              </span>
            </div>
            <label className="usertype-switch">
              Log in as Seller Admin
              <CustomSwitch defaultChecked={isSellerAdmin?true:false} onChange={()=>{handleisSellerAdminChange(isSellerAdmin)}} />
            </label>
            {!loading&&<input
              type="submit"
              className="Seller-sign-in-btn"
              value="Sign In"
            />}
            {loading && <div style={{display:"flex", width:"100%",alignItems:"center",justifyContent:"center"}}><CircularProgress style={{color:"pink" }} /></div>}
          
          </form>
          <div className="seller-login-modal-field-divider">Or</div>
          <div className="admin-login-page-button-page-terms">   
            <button
              className="Seller-sign-in-plain-btn"
              onClick={() => {
                navigate("/SellerOtpLogin");
              }}
            >
              Sign In with OTP
            </button>
          </div>
          <div className="seller-login-modal-field-signup">
                    Not a Member?{" "}
                    <Button
                      onClick={() => {
                        navigate("/SellerRegister");
                      }}
                      className="customer-login-signup-button"
                      variant="plain"
                    >
                      Create an account
                    </Button>
                  </div>
        </div>
      </div>

    </CardComponent>

  )
}

export default SellerPageLogin