import React, { useRef } from 'react';
import '../styles/CardComponent.css';
import logo from "../Images/whiteMyhrakiLogo.png"
import ToastComponent from './ToastComponent';
const CardComponent = ({ cardImg, imageFirst, children }) => {
// const toastRef = useRef()

  return (
    <div className='Card_layout'>
    <div className={`card-container-screen ${imageFirst ? 'imageFirst' : ''}`}>
               {/* <ToastComponent ref={toastRef} timeout={2000} /> */}

      <div className={`card-container-content ${imageFirst ? 'imageFirst' : ''}`}>
        {imageFirst && (
          <div className='card-content-img-first'>
            {cardImg}
          </div>
        )}
        <div className='card-container-right'>
          {children}
        </div>
        {!imageFirst && (
          <div className='card-content-img'>
         <img loading="lazy" src={logo} alt="" style={{width:"41%"}}/>
            {cardImg}
          </div>
        )}
      </div>
      <div className='Myhraki_Title'>MYHRAKI E-COMMERCE LIFESTYLE PRODUCT PRIVATE LIMITED</div>
    </div>
    </div>
  );
};

export default CardComponent;
