import React from "react";
// import { CustomerContext } from "../context/CustomerContext";
// import { errorAlert } from "../components/Alert";
import CustomerLayout from "../components/CustomerLayout";
import EmptyPage from "./EmptyPage";

function Offers() {
    // const [filteredDiscount, setFilteredDiscount] = useState();
    // const [cartData, setCartData] = useState([]);
    // const { customerData, accessToken} = useContext(CustomerContext);
    // const [discountData, setDiscountData] = useState([]);
    // const [couponCodeText, setCouponCodeText] = useState('');
    // const [expanded, setExpanded] = useState(false);
    // const fetchDiscountData = async () => {
    //     try {
    //         //console.log(customerData?.result?.userID)
    //         const response = await fetch(`${API_BASE_URL}/coupons/api/v1/GetAllCoupon`, {
    //             method:"GET",
    //             headers: {
    //                 Authorization: `Bearer ${accessToken}`,
    //             }
    //         });
    //         const data = await response.json();
    //         if (data?.result) {
    //             //console.log('data', data)
    //             setDiscountData(data?.result?.result);
    //         }
    //         else {
    //             errorAlert('', 'Error Fetching Cart data');
    //         }
    //     } catch (e) {
    //         //console.log(e)
    //     }
    // }


  return (
<CustomerLayout>
    <div>

                                    <EmptyPage desc='Updates on the way'/>
 
    </div>
    </CustomerLayout>
  )
}

export default Offers