import React from "react";
// import "../styles/SignupPage.css";
import "../styles/Common.css";
import "../styles/SellerRegistration.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { CircularProgress, IconButton, TextField } from "@mui/material";
import {  useNavigate } from "react-router-dom";
import "../styles/SellerRegistrationScreen.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { Checkbox } from "@mui/material";
import { useContext } from "react";
import { SellerContext } from "../context/SellerContext";
import ShoppingImg from "../Images/RegisterShoppingImg.png";
import CardComponent from "../components/CardComponent";
import { useState } from "react";
import { Link } from 'react-router-dom';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { API_BASE_URL } from "../App";
import ToastComponent from "../components/ToastComponent";
import { useRef } from "react";
// const phoneRegExp = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;
// const nameRegExp=/^[A-Z][a-zA-Z]{3,}\S*$/;
const passwordRegexp = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{6,20}$)"
);

const validationSchema = yup.object({
  // email: yup
  //   .string("Enter your email")
  //   .email("Enter a valid email")
  //   .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Invalid Password")
    .matches(passwordRegexp, "Password is not valid")
    .required("Password is required"),
  // number: yup
  //   .string(),
  //   .matches(phoneRegExp, "Phone number is not valid")
  //   .required("Phone number is required*")
  //   .min(10, "Phone number must consist of 10 digits")
  //   .max(10, "Phone number must consist of 10 digits"),
  // firstName: yup
  //   .string("Enter Your First Name")
  //   .required("First Name is required*"),
  // lastName: yup
  //   .string("Enter Your last Name")
  //   .required("Last Name is required*"),
  termsAndConditions: yup
    .bool("Enter terms and conditions")
    .oneOf([true], "You need to accept the terms and conditions"),
  rePassword: yup
    .string("Enter Your Password again")
    .oneOf([yup.ref("password"), null], "Passwords must match")
    // .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export default function SellerRegister() {
  // const [errorCheckboxText, setErrorCheckboxText]=useState(true)
  const { sellerData, redirectLogin } = useContext(SellerContext);
  // ////console.log(sellerData)
  const navigate = useNavigate();
  // document.getElementById('dt').max = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0];
  // const location = useLocation();
  const gotoSellerLogin = () => {
    navigate("/");
  };

  const [loading, setLoading] = useState(false);
  const toastRef = useRef();
  const [radioValue, setRadioValue] = React.useState("");
  const [radioError, setRadioError] = React.useState(false);
  const [radioHelperText, setRadioHelperText] = React.useState("");
  let day = new Date().getDate();
  //  ////console.log(day);
  let month = new Date().getMonth() + 1;
  //  ////console.log(month);

  const year = new Date().getFullYear();
  //  ////console.log(year);

  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  const maxDate = `${year}-${month}-${day}`;
  //  ////console.log(maxDate);
  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPasswordEntered(value !== "");
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [passwordEntered, setPasswordEntered] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    setRadioHelperText(" ");
    setRadioError(false);
  };

  // document.getElementById("checkBox").checked=checked;

  const formik = useFormik({
    initialValues: {
      email: sellerData?.result?.email,
      password: "",
      rePassword: "",
      number: sellerData?.result?.phone,
      firstName: sellerData?.result?.name,
      // lastName: "",
      gender: radioValue,
      termsAndConditions: false,
      date: "",
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      setLoading(true)
      // setErrorCheckboxText(true);
      // ////console.log(formik.values)
      if (radioValue === "m") {
        setRadioHelperText("");
      } else if (radioValue === "f") {
        setRadioHelperText("");
      } else if (radioValue === "o") {
        setRadioHelperText("");
      } else {
        setRadioHelperText("Please select an option.");
        setRadioError(true);
        setLoading(false);
        return;
      }

      if (radioValue) {
        const result = await postData();
        ////console.log("After posting Data", result);
        const val = await result.json();
        //console.log(val)
        if(val.result){

        
        const response = await redirectLogin(sellerData?.result?.email, formik.values.password);
        ////console.log(data);  
        if (response?.result?.accessToken) {
          toastRef.current.showToast("Login Successful", "success");
          navigate("/sellerPersonalData");
          setLoading(false);

        }
        else {
          toastRef.current.showToast("Could not register. Account already Exists", "error");
          setLoading(false);

        }
      }
      else if (val.message){
        toastRef.current.showToast("Account already exists", "error");

      }
      else{
        toastRef.current.showToast("Something went wrong. Please try later", "error");
        setLoading(false);
  
      }
    }
    },
  });
  //////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////////////////////////

  async function postData() {
    setLoading(true);
    try {

      const response = await fetch(`${API_BASE_URL}/users/api/v1/registration`, {
        method: "post",
        // mode: "no-cors",
        headers: {
          //   Accept: "application.json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          selleGuid: sellerData?.result?.guid,
          email: sellerData?.result?.email,
          password: formik.values.password,
          mobileNumber: sellerData?.result?.phone?.slice(3),
          firstName: sellerData?.result?.name,
          gender:radioValue,
          lastName: "",
          typeOfUserId: 3,
          dateOfBirth: formik.values.date,
        }),
      });
      // const data = await response.json();
      setLoading(false);
      return response;
    } catch (e) {

      setLoading(false);

      ////console.log(e);
    }
  }

  return (
    <div>
                     <ToastComponent ref={toastRef} timeout={4000} />

    <CardComponent
      cardImg={

        <img
          className="register-img-shopping"
          src={ShoppingImg}
          alt="shopping"
        />

      }

      imageFirst={false}
    >
      {/* <div className="seller-register-container"> */}
      {/* <div className="seller-register-welcome-layout">
    <div className="seller-register-welcome-layout-heading">
        Welcome to the World of Myhraki
    </div>
    <div className="seller-register-welcome-layout-desc">
        Let’s dive into MyhraKi and explore its world to grow your business
    </div>
    </div> */}
      <div className="seller-register-right-screen">
        <div className="signup-card-container">
          {/* <div className="register-page-main-logo">
        <img loading="lazy"  className='register-page-logo' src={Logo} alt="" />
    </div> */}
          <div className="seller-signup-head">Register</div>
          <form className="signup-page-form" onSubmit={formik.handleSubmit}>
            <div className="signup-row">
              <div className="signup-first">
                <TextField
                  variant="outlined"
                  fullWidth
                  disabled
                  autoFocus
                  type="text"
                  id="signup-firstName"
                  name="firstName"
                  // className="signup-firstName seller-outline"
                  placeholder="Enter Your First Name * "
                  value={formik.values.firstName}
                // onChange={formik.handleChange}
                // error={
                //   formik.touched.firstName && Boolean(formik.errors.firstName)
                // }
                // helperText={
                //   formik.touched.firstName && formik.errors.firstName
                // }
                />
                {/* <PersonIcon
                  className="signup-firstName-icon"
                  fontSize="medium "
                /> */}
              </div>
              {/* <div className="signup-last"> */}
              <div className="signup-number-field">
                <TextField
                  variant="outlined"
                  fullWidth
                  disabled
                  type="text"
                  id="signup-number"
                  name="number"
                  // className="signup-number seller-outline"
                  placeholder="Enter Your Phone Number * "
                  value={formik.values.number}
                // onChange={formik.handleChange}
                // error={formik.touched.number && Boolean(formik.errors.number)}
                // helperText={formik.touched.number && formik.errors.number}
                />
                {/* <CallIcon className="signup-number-icon" fontSize="medium " /> */}
              </div>
              {/* </div> */}
            </div>
            <div className="signup-row">
              <div className="signup-email-field">
                <TextField
                  variant="outlined"
                  fullWidth
                  disabled
                  type="text"
                  id="signup-email"
                  name="email"
                  // className="signup-email seller-outline"
                  placeholder="Enter Email Address * "
                  value={formik.values.email}
                // onChange={formik.handleChange}
                // error={formik.touched.email && Boolean(formik.errors.email)}
                // helperText={formik.touched.email && formik.errors.email}
                />
                {/* <MailOutlineIcon
                  className="signup-email-icon"
                  fontSize="medium "
                /> */}
              </div>
              <div className="signup-calendar-field">
                <input
                  variant="outlined"
                  fullWidth
                  type="date"
                  className="signup-calendar-seller seller-outline"
                  placeholder="Enter date"
                  name="date"
                  value={formik.values.date}
                  onChange={formik.handleChange}
                  min="1919-01-01"
                  max={maxDate}
                  id="dt"
                // value="DateTime.Today.ToString("yyyy-MM-dd")
                />
             
              </div>
            </div>
            <div className="signup-row">
              <div className="signup-password-field-seller">
                <TextField
                  variant="outlined"
                  fullWidth
                  autoFocus
                  type={showPassword ? "text" : "password"}
                  id="enter-password"
                  name="password"
                  //   className="login-page-email"
                  // className="signup-email seller-outline"
                  placeholder="Enter a Password * "
                  value={formik.values.password}
                  onChange={(event) => {
                    formik.handleChange(event);
                    handlePasswordChange(event);
                  }}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: passwordEntered && (
                      <IconButton onClick={handleTogglePassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
                {/* <LockOpenIcon
                  className="seller-password-icon"
                  fontSize="medium "
                /> */}
              </div>
              <div className="signup-password-field-seller">
                <TextField
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  type={showRePassword ? 'text' : 'password'}
                  id="resetPassword"
                  name="rePassword"
                  // className="signup-email seller-outline"
                  placeholder="Re-Enter your Password"
                  value={formik.values.rePassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.rePassword &&
                    Boolean(formik.errors.rePassword)
                  }
                  helperText={
                    formik.touched.rePassword && formik.errors.rePassword
                  }
                  InputProps={{
                    endAdornment: passwordEntered && (
                      <IconButton onClick={() => setShowRePassword(!showRePassword)}>
                        {showRePassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}

                />
                {/* <LockOpenIcon
                  className="seller-reset-phone-icon"
                  fontSize="medium "
                /> */}
              </div>
            </div>
            <FormControl
              className="registration-gender"
              error={radioError}
              variant="standard"
              value={radioValue}
              onChange={handleRadioChange}
            >
              {/* <FormLabel id="demo-error-radios">Gender</FormLabel> */}
              <div className="registration-gender">Gender</div>
              <RadioGroup
                // aria-labelledby="demo-error-radios"
                name="gender"
                value={radioValue}
                onChange={handleRadioChange}
                className="signup-gender-radio"
                row
                style={{
                  display: "flex",
                  justifycontent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <FormControlLabel
                  fontSize="medium "
                  style={{ marginRight: "3rem" }}
                  value="m"
                  className="signup-male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  fontSize="medium "
                  style={{ marginRight: "3rem" }}
                  value="f"
                  className="signup-female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  fontSize="medium "
                  style={{ marginRight: "3rem" }}
                  value="o"
                  className="signup-other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
              <FormHelperText
                style={{
                  fontSize: "1rem",
                  marginTop: "1rem",
                  left: "8%",
                  bottom: "-25%",
                }}
              >
                {radioHelperText}
              </FormHelperText>
            </FormControl>
            <div className="registration-terms-text">
              {/* Terms and conditions */}
              <Checkbox
                type="checkbox"
                name="termsAndConditions"
                id="signup-terms"
                className="signup-terms"
                // placeholder="Enter a desired Password"
                value={formik.values.termsAndConditions}
                onChange={formik.handleChange}
                error={
                  formik.touched.termsAndConditions &&
                  Boolean(formik.errors.termsAndConditions)
                }
                helperText={
                  formik.touched.termsAndConditions &&
                  formik.errors.termsAndConditions
                }
              />
              <div className="register-terms-text">
                I agree to the  <Link to="/termsAndCondition" className='register-privacy-link'>Terms and Conditions</Link> and  <Link to="/privacyPolicy" className='register-privacy-link'>Privacy Policy</Link> of MyhraKi.
              </div>
            </div>
            {/* {////console.log(errorCheckboxText)} */}
            {/* <div className="signup-checkbox-error"> Please agree to our terms and conditions to continue</div>  */}
            {/* {////console.log(formik.values.termsAndConditions)} */}
            <div className="seller-register-button-field">
            {!loading && <input
              type="submit"
              className="common-button-seller signup-button"
              value="Sign Up"
            />}
            {loading && <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "pink" }} /></div>}
            </div>
          </form>
          <div className="signup-signin">
            If you have already verified your OTP and registered,{" "}
            <span style={{ color: "#FF2171" }} onClick={gotoSellerLogin}>
              Sign In{" "}
            </span>
          </div>
        </div>
      </div>
    </CardComponent>
    </div>

  );
}
