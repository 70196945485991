import React, { useContext, useRef } from "react";
import "../styles/SellerForgetPassword.css";
import "../styles/Common.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { API_BASE_URL } from '../App'

import {CircularProgress, Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import sellerLogo from "../Images/MainLogo.png";
import "../styles/Common.css";
import "react-toastify/dist/ReactToastify.css";
import { SellerContext } from "../context/SellerContext";
import { useState } from "react";
import ThankYouShopping from "../Images/thankYouScreenImg.png"
import { alpha, styled } from '@mui/material/styles';
import {  red } from '@mui/material/colors';
import CardComponent from "../components/CardComponent";
import MyhrakiTextfield from "../components/MyhrakiTextfield";
import ToastComponent from "../components/ToastComponent";


const phoneRegExp = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;

const validationSchema = yup.object({
  number: yup
    .string("Enter your Phone Number")
    .trim()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone Number is required"),
});

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: red[400],
    '&:hover': {
      backgroundColor: alpha(red[400], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: red[400],
  },
}));

export default function SellerForgetPassword() {
  // const [isSellerAdmin, setIsSellerAdmin] = useState();
  const { isSellerAdmin, setIsSellerAdmin} = useContext(SellerContext)
  const [loading, setLoading ] = useState(false);
  const [invalidPhone, setInvalidPhone ] = useState(false);

  // const handleisSellerAdminChange = () => {
  //   setIsSellerAdmin(!isSellerAdmin);
  //   setSellerUserType(sellerUserType===3?4:3);
  // };
  const checkUserExist = async () => {
    try {
      const data = await fetch(
        `${API_BASE_URL}/users/api/v1/checkUser?mobNumber=${formik.values.number?.trim()}&userTypeId=${isSellerAdmin?3:4}`
      );
      const response = await data.json();
      // //console.log("Check", response)

      if (response.result === true) {
        return 'otpSent';
      }
      else if (response.result === false) {

        return 'otpNotSent';
      }

    } catch (e) {
      return false;
    }
  }
  const toastRef= useRef();

  const navigate = useNavigate();
  // const [token, setToken]=useState(true);
  const { setPhoneNumberForOtp } = useContext(SellerContext);
  const gotoLoginPage = () => {
    navigate("/");
  };
  const formik = useFormik({
    initialValues: {
      number: "",
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      setLoading(true);
      const userExists =await  checkUserExist()
      //console.log(userExists)
      setLoading(false);
      if(userExists === 'otpSent'){

        setInvalidPhone(false);
        await postForOtp();
      }
      else if(userExists === 'otpNotSent') {
        
        setInvalidPhone(true);
        
      }  else{
        setInvalidPhone(false);
        setLoading(false);

        toastRef.current.showToast("Something went wrong. Try again later", "error");

      }

    },
  });
  const verifyOTP = async (number, code) =>{
    const response = await fetch(`${API_BASE_URL}/users/api/v1/OTPVerify?mobileNumber=${formik.values.number}&userTypeId=${isSellerAdmin?3:4}&OTP=${code}
    `);
    const json = await response.json();
    //console.log(json);
    if(json.result){
      return true;
    }
    return false;
}

const userType = isSellerAdmin ? 3 : 4;
async function postForOtp() {
  setLoading(true);
  try {
    let response = await fetch(
      `${API_BASE_URL}/users/api/v1/otp?mobileNumber=${formik.values.number.trim()}&userType=${userType}`,
      {
        headers: {
       
        },

      }
    );
    const result = await response.json()
    // //console.log(result)
   
    if (result.result > 0) {
      toastRef.current.showToast("An OTP has been sent", "success");

      navigate("/sellerForgotPasswordOtpVerify");
      setPhoneNumberForOtp(formik.values.number)
      setLoading(false);
    }
    else {
      toastRef.current.showToast("Maximum otps sent. contact Admin for more", "error");
      setLoading(false);
    }
    // //console.log(response);
    ////console.log(result);
    // return response;
  } catch (e) {
    toastRef.current.showToast("Something went wrong. Try again later", "error");

    ////console.log(e);
    setLoading(false)

  }
}


  return (
    <div>
               <ToastComponent ref={toastRef} timeout={4000} />

    <CardComponent
    cardImg={<img loading="lazy" className='shopping-img' src={ThankYouShopping} alt='shopping' />}
    imageFirst={true}
  >
    {/* <div className="seller-forgot-password-container"> */}
      {/* <div className="seller-forgot-password-left">
        <div className="seller-forgot-left-content">
          <div className="ResetPassword_LeftScreen_Image">
            <img
              className="reset-password-img"
              src={ResetPasswordImg}
              alt="reset"
            />
          </div>
          <div className="ResetPassword_Content">Intresting Facts</div>
          <div className="ResetPassword_Content_Description">
            <p>
              93.5% of global internet users have purchased products online.
              Segmented campaigns to email subscribers drive a 760% increase in
              revenue.
            </p>
          </div>
        </div>
      </div> */}

      <div className="seller-forgot-password-right">
        <div className="seller-forgot-right-content">
          <div className="seller-reset-logo">
            <img loading="lazy" alt=" " src={sellerLogo} className="Myhraki-logo" />
          </div>

          <div className="seller-reset-head ">PASSWORD ASSISTANCE</div>
          <div className="seller-reset-auth-text">
          Enter the or mobile phone number associated with your account
          </div>
          {invalidPhone && <div style={{ backgroundColor: 'rgba(249, 0, 0, 0.13)', color: '#FF2121', padding: '8px 24px', width: '60%', margin: '0 auto', fontSize: '12px', borderRadius: '10px', textAlign: 'center', position: 'relative', bottom: '0px' }} >This phone number does not exist</div>}

          <form
            className="seller-reset-page-form"
            onSubmit={formik.handleSubmit}
          >
          <div className="seller-forgot-phone-field">
          <div className="textfield-head">Mobile Number*</div>
                      <MyhrakiTextfield
              variant="outlined"
              fullWidth
              //   autoFocus
              // disabled
              type="text"
              id="number" 
              name="number"
              // className="login-email"
              placeHolder="Enter your Mobile Number"
              //   value={state.phone}
              value={formik.values.number.trim()}
              onChange={formik.handleChange}
              error={formik.touched.number && Boolean(formik.errors.number)}
              helperText={formik.touched.number && formik.errors.number}
            />
            {/* <CallIcon className="seller-otp-phone-icon" fontSize="medium " /> */}


           

          </div>
            <label className="usertype-switch" style={{margin:"0 auto", minWidth:"37%",display:"flex",justifyContent:"center",alignItems:"center"
          }}>
              Log in as Seller Admin
              <CustomSwitch defaultChecked={isSellerAdmin?true:false} onChange={()=>{setIsSellerAdmin(!isSellerAdmin)}} />
            </label>
            {!loading&&<input
              type="submit"
              className="common-button-seller"
              value="Send OTP"
            />}
                        {loading && <div style={{display:"flex", width:"100%",alignItems:"center",justifyContent:"center"}}><CircularProgress style={{color:"pink" }} /></div>}
                        <div className="seller-reset-login" >
            <span onClick={gotoLoginPage}>Return to Login</span>
          </div>
          </form>
          
          {/* <div className="seller-reset-signUp">
          Don't have an account? <span onClick={gotoSignupPage}>Sign Up</span>
        </div> */}
          
          {/* <div className="seller-reset-page-terms">
            By continuing, you agree to MyhraKi's
            <span>Terms and conditions</span> and <span>privacy policy</span>
          </div> */}
        </div>

        {/* //<ToastContaner theme="coloured" className="toast-message" /> */}
      </div>
</CardComponent>
</div>
  
  );
}
