import React, { useState, useEffect } from "react";
import "../styles/Address.css";
import { API_BASE_URL } from "../App";
import { useContext } from "react";
import "../styles/AddressCard.css";
import EmptyPage from "./EmptyPage";
import { SellerContext } from "../context/SellerContext";
import SellerAddressCard from "../components/SellerAddressCard";
import Loading from "../components/Loading";
import SellerAddressModal from "../components/SellerAddressModal";
import SellerLayoutAdjustment from "../components/SellerLayoutAdjustment";
import Heading from "../components/Heading";
import { errorAlert } from "../components/Alert";


export default function SellerAddress() {
  const [addressData, setAddressData] = useState([]);
  const { sellerData, accessToken ,setSelectedListItemIndex, handleAuthExpiry ,logout} = useContext(SellerContext);
  const [addressLoading, setAddressLoading] = useState(false);
  const [loading, setLoading] = useState(false)

  const [openModal, setOpenModal] = useState(false);
  const handleModalOpen = () => {
    setOpenModal(true);
  };
  const handleModalClose = () => { setOpenModal(false); }

  useEffect(()=>{
    setSelectedListItemIndex(5);
  },[])
  const fetchAddressData = async () => {

    try {
      
      setLoading(true);
      const response = await fetch(
        
        `${API_BASE_URL}/address/api/v1/getall?userId=${sellerData?.result?.userID}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
        setAddressLoading(true);
      if (response.ok) {
        const data = await response.json();
        const handleAuthError = handleAuthExpiry(response);
        if (handleAuthError === true) {
          errorAlert('', 'Your Session has expired');
          logout();
          return;
        }
        setAddressData(data?.result);
        setLoading(false);
      } else {
        console.error("Failed to fetch address data");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching address data:", error);
    }
  };

  useEffect(() => {
    fetchAddressData();
  }, []);
  return (
    
<SellerLayoutAdjustment>
  <div className='page-content'>
    <Heading text='WAREHOUSES' />
    <div className="address-container" style={{margin:"0 auto" ,padding:"2rem" ,width:"100%", maxWidth:'1280px'}}>

      <SellerAddressModal
        open={openModal}
        onClose={handleModalClose}
        edit={false} 
        fetchAddressData={fetchAddressData}
      />

      <div className="add-address-head-container">
        
        <div className="add-address-head">
          {/* <div className="profile-page-head ">Warehouses</div> */}
          <button onClick={handleModalOpen} className="add-address-buyer">
            + ADD NEW ADDRESS
          </button>
        </div>
        <div className="addressCards" style={{display:"flex",justifyContent:"center",margin:"2rem auto"}}>
          {addressLoading && !addressData[0] && <EmptyPage desc='Updates on the way' />}
{!addressData[0]}
{loading && <div className="loading-container" style={{ minHeight: "300px", minWidth: "80%" }}>
            <Loading />

          </div>}
          {addressData?.map((address) => {
            return <SellerAddressCard {...address} fetchAddressData={fetchAddressData} />;
          })}
        </div>
      </div>
      </div>
    </div>
    </SellerLayoutAdjustment>
  );
}


