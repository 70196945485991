import React, { useState, useEffect } from 'react';
import AdminLayout from './AdminLayout';
import AdminLayout2 from './AdminLayout2';
import "../styles/AdminLayout2.css";

export default function AdminLayoutAdjustment({children}) {
  const [screenSize, setScreenSize] = useState("");

  useEffect(() => {
    function handleResize() {
     
    if (window.innerWidth <= 1024) {
        setScreenSize("medium");
      } else {
        setScreenSize("large");
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    // render different components based on `screenSize`
    <div>
      
    {screenSize === "medium" && <AdminLayout>{children}</AdminLayout>}
    {screenSize === "large" && <AdminLayout2>{children}</AdminLayout2>}
  </div>
  );  
}