import React, { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Toolbar,
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
} from "@mui/material";
import {
  Apps,
  Menu,
  AssignmentInd,
 
} from "@mui/icons-material";
import { ChatsCircle } from '@phosphor-icons/react';
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
// import CategoryIcon from "@mui/icons-material/Category";

import { AddressBook ,HandCoins} from '@phosphor-icons/react'
import Diversity3Icon from "@mui/icons-material/Diversity3";
import { makeStyles } from "@mui/styles";
import "../styles/Common.css";
import { useNavigate } from "react-router-dom";
// import Footer from "./Footer";
import { useContext } from "react";
import { SellerContext } from "../context/SellerContext";
// import { errorAlert } from "./Alert";
import ToastComponent from "./ToastComponent";

const useStyles = makeStyles((theme) => ({
  menuSliderContainer: {
    width: 350,
    background: " rgba(244, 244, 244, 1)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    border:"none",
    height: "100%",
    position:"relative"
  },
  avatar: {
    margin: "0.5rem auto",
    padding: "1rem",
    
  },
  listItem: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "1rem",
    "&:hover": {
      background: " #FF99A5 ",
      color: "white",
    },
  },
  selectedListItem: {
    background: "#ff7891",
    color: "white",
  },
}));
const listItems = [
  // {
  //   listIcon: <DashboardIcon />,
  //   listText: "Dashboard",
  //   listNav: "/SellerDashboard",
  // },
 
  {
    listIcon: <AssignmentInd />,
    listText: "Products",
    listNav: "/SellerCategoriesListingPage",
    view:true
  },
  {
    listIcon: <Apps />,
    listText: "Order",
    listNav: "/SellerOrders",
    view:true
  },
  {
    listIcon: <Diversity3Icon />,
    listText: "User",
    listNav: "/sellerManagement",
  },
  // {
  //   listIcon: <BarChartIcon />,
  //   listText: "Discount",
  //   listNav: "/SellerDiscounts",
  // },
  {
    listIcon: <ChatsCircle style={{fontSize:"25px"}} />,
    listText: "Facts",
    listNav: "/SellerFacts",
    view:true
  },
  {
    listIcon: <ManageAccountsIcon />,
    listText: "Account",
    listNav: "/SellerAccount",
  },
  {
    listIcon: <AddressBook style={{fontSize:"25px"}} />,
    // listIcon: <ManageAccountsIcon style={{fontSize:"25px"}} />,
    listText: "Warehouses",
    listNav: "/SellerAddress",
  },
  {
    listIcon: <HandCoins style={{fontSize:"25px"}} />,
    // listIcon: <ManageAccountsIcon style={{fontSize:"25px"}} />,
    listText: "Subscriptions",
    listNav: "/SubscriptionPlans",
  }
];



export default function SellerLayout1(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  // const [selectedItem, setSelectedItem] = useState(false);
  const navigate = useNavigate();
  // const handleNav=(x)=>{
  //   navigate(x)
  // }
  const toastRef = useRef();
  const { selectedListItemIndex, sellerData, setSelectedListItemIndex, logout ,sellerPersonalData} =
    useContext(SellerContext);
  const toggleSlider = () => {
    setOpen(!open);
  };

  useEffect(()=>{
    // //console.log('bkbwncwnrlnerlincven ',sellerPersonalData)
    setSelectedListItemIndex(listItems[0]);
  },[])

  const handleListItemClick = (index) => {
    if(sellerPersonalData?.result?.status!=='C'){
      setOpen(!open);
      toastRef.current.showToast(`Please complete your onboarding to access ${listItems[index].listText} and other features `, "warning");
      return;
    }

    setSelectedListItemIndex(index);
    //console.log(listItems[index].listNav);
    navigate(listItems[index].listNav);
  };

  const sideList = () => (
    <div className={classes.menuSliderContainer} >
      
      <Avatar
        className={classes.avatar}
        // src="https://i.ibb.co/rx5DFbs/avatar.png"
       
        alt="admin"
      /> 
      <Divider />
      <List>
        {/* <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between"}}> */}
        {listItems.map((listItem, index) => {
          return(<div style={{cursor:'pointer'}}>
          {(sellerData?.result?.typeOfUser==="SLADM"|| listItem?.view===true)&&<ListItem
            className={`${classes.listItem} ${
              selectedListItemIndex === index ? classes.selectedListItem : ""
            }`}
            onClick={() => {
              handleListItemClick(index);
            }}
            key={index}
            style={{ padding: "1rem auto" }}
          >
            <ListItemIcon className={classes.listItem}>
              {listItem.listIcon}
            </ListItemIcon>
            <ListItemText primary={listItem.listText} />
          </ListItem>}
          </div>
        

        )
})}
        
      </List>
      <div className={classes.listItem} style={{position:"absolute", bottom:"2rem" ,width:"99%" ,fontSize:"1.2rem",textAlign:"center", minHeight:"30px", padding:"1rem 0"}} onMouseOver={(e) => {
          e.target.style.backgroundColor = 'linear-gradient(90deg, #FA068A 0%, #FE505F 100%)';
          e.target.style.cursor="pointer"
          
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = '#f2f2f2';
        }}onClick={logout}>
          Logout
        </div>

      
    </div>
  );

  return (
    <>
                     <ToastComponent ref={toastRef} timeout={4000} />
      <nav className="seller-navbar-component">
        <AppBar position="fixed" style={{background: "white" }}>
     
          <Toolbar >
            <IconButton onClick={toggleSlider}>
              <Menu />
            </IconButton>
            <Drawer open={open} anchor="left" onClose={toggleSlider}>
              {sideList()}
            </Drawer>
          </Toolbar>
          <Avatar
            style={{
              position: "absolute",
              right: "1rem",
              color: "white",
              top: "1.3rem",
              width: "30px",
              height: "30px",
              background: "linear-gradient(90deg, #FA068A 0%, #FE505F 100%)"
            }}
            
          />
        </AppBar>
        <div className="Seller-layout" style={{margin:'60px auto 120px auto'}}>
          {props.children}
          <div style={{minHeight:'200px'}}></div>
        </div>
        {/* <Footer color="seller-footer-color" /> */}
      </nav>
    </>
  );
}
