import React from 'react'
import Button from '@mui/joy/Button';
// import Modal from '@mui/joy/Modal';
// import ModalDialog from '@mui/joy/ModalDialog';
import '../styles/CustomerLoginModal.css'
import { TextField } from '@mui/material';
import { useFormik } from "formik";
import { API_BASE_URL } from '../App'

import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { errorAlert, successAlert } from '../components/Alert';
import { CustomerContext } from '../context/CustomerContext';
import { useContext } from 'react';
import { useState } from 'react';
const phoneRegExp = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;

const validationSchema = yup.object({
      
    mobNumber: yup
    .string().trim()
    .matches(phoneRegExp, "Mobile number is not valid")
    .required("Mobile number is required*")
    .min(10, "Mobile number must consist of 10 digits")
    .max(10, "Mobile number must consist of 10 digits"),
});
function CustomerPhoneOtpLogin({ isOpen, onClose, ...props }) {
    
    const {setPhoneNumberForOtp}  = useContext(CustomerContext);
    const navigate = useNavigate();
    const [loading, setLoading ] = useState(false);
    const [tab, setTab] = useState(props.currentTab ? props.currentTab : 'password');


  
        const formik = useFormik({
            initialValues: {
                mobNumber:"",
            },
            validationSchema: validationSchema,
            onSubmit:async () => {
                await checkUserExists();
                await postOtp();
                setPhoneNumberForOtp(formik.values.mobNumber);
              },
        });
        async function checkUserExists(mobNumber) {
            try {
              const response = await fetch(
                `${API_BASE_URL}/users/api/v1/checkUser?mobNumber=${mobNumber}&userTypeId=2`
              );
              const result = await response.json();
          
              if (!result.userFound) {
                errorAlert('', 'User Found please Login');
                
              } else {
                // User not found, call postForOtp and navigate to OTP verification
                await postOtp();
                navigate('/CustomerOtpVerifyLogin');
              }
            } catch (e) {
              errorAlert('', 'Failed to check user. Try again.');
            }
          }
          
        async function postOtp() {
            setLoading(true);
            try {
              let response = await fetch(
                `${API_BASE_URL}/users/api/v1/otp?mobileNumber=${formik.values.mobNumber}&userType=2`,
                {
                  headers: {
                    // Accept: "application.json",
                    // "Content-type": "application.json",
                  },
                 
                }
              );
              const result = await response.json()
              //console.log(result)
              if(result?.message ==="Unsuccessful login - User not found.")
              {

                 errorAlert('','Failed to send OTP. Try again.')
                 navigate("/")

                //  setLoading(false);
               }
               else if(JSON.parse(result?.message)?.return===true){
                 successAlert('','OTP sent Successfully')
                 navigate("/CustomerOtpVerifyLogin")
                 setPhoneNumberForOtp(formik.values.mobNumber)
                 setLoading(false)
        
               }
              //console.log(response);
            } catch (e) {
              errorAlert('','Failed to send OTP. Try again.')
              setLoading(false)
            }}
        
  return (
    <div>
        <form className='customer-login-modal-container' onSubmit={formik.handleSubmit}>
                                <div className='customer-login-modal-field-name'>Mobile Number</div>
                                <TextField style={{ width: '390px', marginBottom: '16px' }} 
                                // error helperText={'Test'} 
                                name="mobNumber" 
                                placeholder='Enter Mobile Number'
                                value={formik.values.mobNumber}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.mobNumber && Boolean(formik.errors.mobNumber)
                                }
                                helperText={formik.touched.mobNumber && formik.errors.mobNumber}/>
                                <Button  className='customer-login-btn' fullWidth type='submit'  onClick={() => { setTab('phoneForOtpVerify') }}>Get OTP</Button>
                                <div className='customer-login-modal-field-divider'>Or</div>
                                <Button className='customer-login-sec-button' fullWidth variant='outlined' onClick={() => { setTab('phoneForOtp') }}>Login with Password </Button>
                                <div className='customer-login-modal-field-signup'>Not a Member? <Button onClick={() => { setTab('signup') }} className='customer-login-signup-button' variant='plain'>Create an account</Button></div>
                                <div className='customer-login-modal-field-terms'>By continuing, you agree that you have read and accept our Terms & Condition and Privacy Policy</div>
        </form>
    </div>
  )
}

export default CustomerPhoneOtpLogin