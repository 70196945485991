import React, { useState, useEffect } from 'react';
import SellerLayout1 from './SellerLayout1';
import SellerLayout2 from './SellerLayout2';
import "../styles/Common.css";
import "../styles/SellerLayout.css";

export default function SellerLayoutAdjustment({children}) {
  const [screenSize, setScreenSize] = useState("");

  useEffect(() => {
    function handleResize() {
     
    if (window.innerWidth <= 1024) {
        setScreenSize("medium");
      } else {
        setScreenSize("large");
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    // render different components based on `screenSize`
    <div>
      
    {screenSize === "medium" && <SellerLayout1>{children}</SellerLayout1>}
    {screenSize === "large" && <SellerLayout2>{children}</SellerLayout2>}
  </div>
  );  
}