const ReviewData=[

    {
        imageReviewCount:3,
        reviewCount:10,
        reviewerName: "Robert",
        reviewRating:"4",
        reviewDate:"22nd Aug 2022",
        reviewText:"Received same product as displayed. Best products by myhraki. Highly recommended."
    },
    {
        imageReviewCount:3,
        reviewCount:10,
        reviewerName: "Robert",
        reviewRating:"5",
        reviewDate:"2nd Aug 2022",
        reviewText:"Received same product as displayed. Best products by myhraki. Highly recommended."
    },
]

export default ReviewData;