import { Breadcrumbs } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import BcHeading from './BcHeading'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


function Breadcrumb(props) {
  return (
    <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              className="breadcrumbs"
              aria-label="breadcrumb"
              // sx={{ marginTop: "2rem", marginBottom: "2rem", }}
            >
              <Link
                underline="hover"
                color="rgba(255, 97, 85, 1)"
                style={{ fontSize: "1.8rem", fontWeight: "600", color: "#000" }}
                to="/account"
                key={"account"}
              >
                <BcHeading text="Account" />
              </Link>

              <Link
                underline="hover"
                color="inherit"
                style={{ fontSize: "1.5rem", fontWeight: "400" }}
                key={props.tab}
              >
                <BcHeading text={props.tab} />
              </Link>
            </Breadcrumbs>
  )
}

export default Breadcrumb