import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AdminContext } from "./AdminContext";

function withAdminAuth(Component) {
  return function AuthenticatedComponent(props) {
    const { adminData } = useContext(AdminContext);

    if (!adminData) {
      return <Navigate to="/" />;
    }

    return <Component {...props} />;
  };
}

export default withAdminAuth;