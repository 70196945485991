export const sellerCustomerData=[
    {
        customerId:"1",
        customerName:"Sachidananda",
        customerEmail:"anand@gmail.com",
        customerPhone:"9098989876",
        customerUserType:"customer",

    },
    {
        customerId:"2",
        customerName:"Sachidananda",
        customerEmail:"anand@gmail.com",
        customerPhone:"9098989876",
        customerUserType:"customer",

    },
    {
        customerId:"3",
        customerName:"Sachidananda",
        customerEmail:"anand@gmail.com",
        customerPhone:"9098989876",
        customerUserType:"customer",

    },
    {
        customerId:"4",
        customerName:"Sachidananda",
        customerEmail:"anand@gmail.com",
        customerPhone:"9098989876",
        customerUserType:"customer",

    },
    {
        customerId:"5",
        customerName:"Sachidananda",
        customerEmail:"anand@gmail.com",
        customerPhone:"9098989876",
        customerUserType:"customer",

    },
    {
        customerId:"6",
        customerName:"Sachidananda",
        customerEmail:"anand@gmail.com",
        customerPhone:"9098989876",
        customerUserType:"customer",

    },
    {
        customerId:"7",
        customerName:"Sachidananda",
        customerEmail:"anand@gmail.com",
        customerPhone:"9098989876",
        customerUserType:"customer",

    },
    {
        customerId:"8",
        customerName:"Sachidananda",
        customerEmail:"anand@gmail.com",
        customerPhone:"9098989876",
        customerUserType:"customer",

    },
    {
        customerId:"9",
        customerName:"Sachidananda",
        customerEmail:"anand@gmail.com",
        customerPhone:"9098989876",
        customerUserType:"customer",

    },
    {
        customerId:"10",
        customerName:"Sachidananda",
        customerEmail:"anand@gmail.com",
        customerPhone:"9098989876",
        customerUserType:"customer",

    },
    {
        customerId:"11",
        customerName:"Sachidananda",
        customerEmail:"anand@gmail.com",
        customerPhone:"9098989876",
        customerUserType:"customer",

    },
    
]