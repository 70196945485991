import React, { useState } from 'react';
import LocalVendorLayoutAdjustment from '../components/LogisticAdminLayoutAdjustment'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import "../styles/SellerFacts.css";
import "../styles/Logistics.css";
import Heading from '../components/Heading';

const facts = [
    {
        factName: "ANand",
        factDescription: "Abcd1234",
        factOrigin: "XYZ",
        factTest: "Test",
        status: "In Progress"
    },
    {
        factName: "ANand",
        factDescription: "Abcd1234",
        factOrigin: "XYZ",
        factTest: "Test",
        status: "In Progress"
    },
    {
        factName: "ANand",
        factDescription: "Abcd1234",
        factOrigin: "XYZ",
        factTest: "Test",
        status: "In Progress"
    },
    {
        factName: "ANand",
        factDescription: "Abcd1234",
        factOrigin: "XYZ",
        factTest: "Test",
        factAnand: "AANnnanad",
        status: "Out for Delivery"

    },
    {
        factName: "ANand",
        factDescription: "Abcd1234",
        factOrigin: "XYZ",
        factTest: "Test",
        factAnand: "AANnnanad",
        status: "Out for Delivery"

    },
    {
        factName: "ANand",
        factDescription: "Abcd1234",
        factOrigin: "XYZ",
        factTest: "Test",
        factAnand: "AANnnanad",
        status: "Out for Delivery"

    },
    {
        factName: "ANand",
        factDescription: "Abcd1234",
        factOrigin: "XYZ",
        factTest: "Test",
        factAnand: "AANnnanad",
        status: "Rejected"

    },
    {
        factName: "ANand",
        factDescription: "Abcd1234",
        factOrigin: "XYZ",
        factTest: "Test",
        factAnand: "AANnnanad",
        status: "New"

    },
    {
        factName: "ANand",
        factDescription: "Abcd1234",
        factOrigin: "XYZ",
        factTest: "Test",
        factAnand: "AANnnanad",
        status: "New"

    },
    {
        factName: "ANand",
        factDescription: "Abcd1234",
        factOrigin: "XYZ",
        factTest: "Test",
        factAnand: "AANnnanad",
        status: "New"

    },
    {
        factName: "ANand",
        factDescription: "Abcd1234",
        factOrigin: "XYZ",
        factTest: "Test",
        factAnand: "AANnnanad",
        status: "New"

    },
    {
        factName: "ANand",
        factDescription: "Abcd1234",
        factOrigin: "XYZ",
        factTest: "Test",
        factAnand: "AANnnanad",
        status: "New"

    },
    {
        factName: "ANand",
        factDescription: "Abcd1234",
        factOrigin: "XYZ",
        factTest: "Test",
        factAnand: "AANnnanad",
        status: "Out for Delivery"

    },
    {
        factName: "ANand",
        factDescription: "Abcd1234",
        factOrigin: "XYZ",
        factTest: "Test",
        factAnand: "AANnnanad",
        status: "Returned"

    },
]

function LocalVendorManageOrders() {
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }


    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    return (
        <LocalVendorLayoutAdjustment>
            <Heading text="Orders" />
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" fullWidth={true} className="SellerDiscount-table tabColor">
                        <Tab label={<span style={{ fontFamily: 'Barlow' }}>In Progress</span>} {...a11yProps(0)}>Anand</Tab>
                        <Tab label={<span style={{ fontFamily: 'Barlow' }}>New Orders</span>} {...a11yProps(1)} />
                        <Tab label={<span style={{ fontFamily: 'Barlow' }}>Delivered Orders</span>} {...a11yProps(2)} />
                        <Tab label={<span style={{ fontFamily: 'Barlow' }}>Returned Orders</span>} {...a11yProps(3)} />
                        <Tab label={<span style={{ fontFamily: 'Barlow' }}>Rejected Orders</span>} {...a11yProps(4)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Table status="In Progress" />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Table status="New" />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Table status="Out for Delivery" />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Table status="Returned" />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <Table status="Rejected" />
                </TabPanel>
            </Box>
        </LocalVendorLayoutAdjustment>
    )
}

export default LocalVendorManageOrders;

function Table(props) {

    async function handleOptionSelect(event) {
        // Get the selected value
        const selectedValue = event.target.value;

        try {
            // Make an API call to post the selected value
            const response = await fetch('your-api-endpoint', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ selectedValue })
            });

            if (response.ok) {
                // API call successful
                //console.log('Value posted successfully!');
            } else {
                // API call failed
                //console.log('Failed to post the value.');
            }
        } catch (error) {
            console.error('Error occurred while making the API call:', error);
        }
    }


    return (
        <table class="localVendor-tabs-table">
            <tbody>
                <tr className="localVendor-tabs-table-head-row">
                    <td >Order ID</td>
                    <td >Order Date</td>
                    <td >Order Assigned</td>
                    <td > Order Status</td>
                    <td > Status</td>
                </tr>

                {facts?.filter(fact => fact?.status === `${props.status}`).map(fact => {
                    return (
                        <tr className="localVendor-table-row">
                            <td data-th="Fact Name">{fact.factName}</td>
                            <td data-th="" style={{ whiteSpace: "nowrap", overflow: "hidden", flow: "ellipsis", maxWidth: "200px" }}>{fact.factDescription}</td>
                            {fact.status === "New" && <td data-th="Fact Origin">
                                <select className="localVendor-select-option" onChange={handleOptionSelect}>
                                    <option className="localVendor-select-option" value="Anand">Anand</option>
                                    <option className="localVendor-select-option" value="Hari">Hari</option>
                                    <option className="localVendor-select-option" value="Abhi">Abhi</option>
                                    <option className="localVendor-select-option" value="Aish">Aishwarya</option>
                                </select>
                            </td>}
                            {fact.status !== "New" && <td data-th="Fact Origin">{fact.factOrigin}</td>}
                            <td data-th="Fact Origin">{fact.factTest}</td>
                            <td data-th="Fact Origin">{fact.status}</td>


                        </tr>
                    );
                })}
            </tbody>
        </table>
        // <h1>{props.name}</h1>
    )
}






