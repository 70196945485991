import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom/dist";
import { API_BASE_URL } from '../App'


export const LogisticsAdminContext = createContext();

export const LogisticsAdminContextProvider = ({ children }) => {
    const [phoneNumberForOtp, setPhoneNumberForOtp] = useState('');
    const [accessToken, setAccessToken] = useState();
    const [logisticsAdminData, setLogisticsAdminData] = useState(null);
      const location = useLocation();


    const [selectedListItemIndex, setSelectedListItemIndex] = useState(
        location.pathname.toLowerCase() === "/orders".toLowerCase() ? 0 : 1
    )


    const navigate = useNavigate();

    //console.log(process.env.REACT_APP_API_TEST);
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setAccessToken(token);
            ////console.log(token);
            fetchData(token);
        }
    }, []);
    const fetchData = async (accessToken) => {
        try {
            const response = await fetch(
                `${API_BASE_URL}/users/api/v1/info`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            const data = await response.json();
            // //console.log(data)
            if (data?.result?.userID) {
                navigate('/orders');
                // setSelectedListItemIndex(0);
            } else {
                navigate('/');

            }
            ////console.log(data);
            setLogisticsAdminData(data);
            return (data);
        } catch (error) {
            //console.error(error);
        }
    };

    const login = async (email, password) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            email: email,
            password: password,
            mobileNumber: "",
            typeOfUser: 5,
            otp: "",
            isOTPBasedLogin: false,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        try {
            const response = await fetch(
                `${API_BASE_URL}/users/api/v1/login`,
                requestOptions
            );
            const result = await response.json();
            ////console.log(result);
            if (!response?.result?.accessToken) {
                navigate('/orders')
            }
            localStorage.setItem("token", result.result.accessToken);
            setAccessToken(result.result.accessToken)

            fetchData(result.result.accessToken);
            return (result);
        } catch (error) {
            ////console.log("error", error);
        }
    };

    const loginWithOtp = async (phone, otp) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            email: '',
            password: '',
            mobileNumber: phone,
            typeOfUser: 5,
            otp: otp,
            isOTPBasedLogin: true,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        try {
            const response = await fetch(
                //console.log(`Before , API Call made to  ${process.env.REACT_APP_API_BASE_URL}`)
                    `${API_BASE_URL}/users/api/v1/login`,
                requestOptions
            );
            const result = await response.json();
            ////console.log(result);
            if (!result.result?.accessToken) {
                return;
            }
            localStorage.setItem("token", result.result.accessToken);
            setAccessToken(result.result.accessToken)

            fetchData(result.result.accessToken);

            return (result)
        } catch (error) {
            //console.log(`After API call made to  ${process.env.REACT_APP_API_BASE_URL}`)

            ////console.log("error", error);
        }
    };

    const logout = () => {
        setLogisticsAdminData(null);
        navigate("/");
        localStorage.setItem("token", null);
    };

    return (
        <LogisticsAdminContext.Provider value={{ selectedListItemIndex, setSelectedListItemIndex, logisticsAdminData, phoneNumberForOtp, setPhoneNumberForOtp, loginWithOtp, login, logout, accessToken }}>
            {children}
        </LogisticsAdminContext.Provider>
    );
};