import { useNavigate, useParams } from "react-router-dom";

import React, { useState, useContext, useEffect } from "react";
import AdminLayout2 from "../components/AdminLayout2";
import "../styles/AdminAddGiftCard.css";
import BirthdayBox from "../Images/BirthdayBox.png";
import { API_BASE_URL } from "../App";
import { AdminContext } from "../context/AdminContext";
import AdminAddNewGiftCard from "./AdminAddNewGiftCard";
import AdminLayoutAdjustment from "../components/AdminLayoutAdjustment";
import "../styles/AdminAddGiftCard.css";
import SliderArrowLeft from "../Images/SliderArrowLeft.png";
import SliderArrowRight from "../Images/SliderArrowRight.png";
import { fabClasses } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function AdminAddGiftCard(props) {
  const navigate = useNavigate();
  const [addedgiftcard, setAddedgiftcard] = useState([]);
  const { accessToken } = useContext(AdminContext);
  const giftCardTitle = [
    { id: "1", title: "Thankyou" },
    { id: "2", title: "Birthday" },
    { id: "3", title: "Festival" },
    { id: "4", title: "Congratulations" },
  ];
 
  const fetchAdminAddedGc = async (category) => {
    // console.log(category);
    // console.log(category?.[0]?.id);
    try {
      const response = await fetch(
        `${API_BASE_URL}/coupons/api/v1/GetGiftCardImage?Category=${category}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = await response?.json();
      setAddedgiftcard((prevGiftCards) => ({
        ...prevGiftCards,
        [category]: data.result,
      }));

      // console.log("Data fetched successfully");
      // console.log(data);
    } catch (error) {
      // console.log("Catch Done", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    giftCardTitle.forEach((category, index) => {
      setTimeout(() => {
        fetchAdminAddedGc(category.id);
      }, index * 2000);
    });
  }, []);

  // const handleButtonClick = (category) => {
  //   navigate(`/AdminUploadNewGc/id:${category.id}`);
  // };
  const handleButtonClick = (category) => {
    console.log(category);
    console.log(category.title);
    console.log(category.id);
    navigate(`/AdminUploadNewGc/:${category}`, {
      state: {  id: category.id , title: category.title,},
    });
  };
  return (
    <div >
      <AdminLayoutAdjustment>
      
        <div className="admin-giftcard">Gift Card</div>
        <div className="AdminGF">
        {giftCardTitle.map((category) => (
          <div key={category.id} className="admin-giftcard-detailcontainer">
            <div className="admin-giftcard-datacontainer">
              <div className="admin-giftcard-name">{category.title}</div>
              <button
                className="admin-add-giftcard-btn"
                onClick={() => handleButtonClick(category)}
               
              >
                Add new gift card
              </button>
            </div>

            <div className="admin-added-allgiftcard">
              {addedgiftcard?.[category?.id]?.map((card) => (
                <img
                  key={card.id}
                  className="admin-added-giftcard"
                  src={card.giftcardImageUrl}
                  alt="Gift card"
                />
              ))}
            </div>
          </div>
        ))}</div>
      </AdminLayoutAdjustment>
    </div>
  );
}

export default AdminAddGiftCard;
