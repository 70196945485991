import React, { useRef, useState } from 'react';
import '../styles/CustomerImageLayout.css'
// import video from "../Images/sampleVideo.mp4"
import { CaretLeft, CaretRight,  } from '@phosphor-icons/react';
import { useEffect } from 'react';

const CustomerImageLayout = (props) => {

//    //console.log(props)
    const [thumbnails, setThumbnails] = useState(props.images)
    const [thumbnailImages, setThumbnailImages] = useState(props.imageNames)

    const [selectedIndex, setSelectedIndex] = useState(0);
    const thumbnailContainerRef = useRef(null);

    const handleThumbnailClick = (index) => {
        setSelectedIndex(index); // Set the new index
      
        const thumbnailContainer = document.querySelector('.customer-product-image-layout-thumbnails');
        if (thumbnailContainer) {
          const thumbnailDimension = window.innerWidth < 1000 ? 110 : 100; // Adjust this value based on thumbnail width
          const scrollAmount = index * thumbnailDimension;
      
          thumbnailContainer.scrollTo({
            top: window.innerWidth < 1000 ? 0 : scrollAmount,
            left: window.innerWidth < 1000 ? scrollAmount : 0,
            behavior: 'smooth', // Optional for smooth scrolling
          });
        }
      };
      
      const handlePreviousClick = () => {
        setSelectedIndex((prevIndex) => {
          const thumbnailContainer = document.querySelector('.customer-product-image-layout-thumbnails');
          const newIndex = prevIndex === 0 ? thumbnails.length - 1 : prevIndex - 1;
      
          if (thumbnailContainer) {
            const thumbnailDimension = window.innerWidth < 1000 ? 110 : 100; // Adjust this value based on thumbnail width
            const scrollAmount = newIndex * thumbnailDimension;
      
            thumbnailContainer.scrollTo({
              top: window.innerWidth < 1000 ? 0 : scrollAmount,
              left: window.innerWidth < 1000 ? scrollAmount : 0,
              behavior: 'smooth', // Optional for smooth scrolling
            });
          }
      
          return newIndex; // Return the updated index
        });
      };
      
      const handleNextClick = () => {
        setSelectedIndex((prevIndex) => {
          const thumbnailContainer = document.querySelector('.customer-product-image-layout-thumbnails');
          const newIndex = prevIndex === thumbnails.length - 1 ? 0 : prevIndex + 1;
      
          if (thumbnailContainer) {
            const thumbnailDimension = window.innerWidth < 1000 ? 110 : 100; // Adjust this value based on thumbnail width
            const scrollAmount = newIndex * thumbnailDimension;
      
            thumbnailContainer.scrollTo({
              top: window.innerWidth < 1000 ? 0 : scrollAmount,
              left: window.innerWidth < 1000 ? scrollAmount : 0,
              behavior: 'smooth', // Optional for smooth scrolling
            });
          }
      
          return newIndex; // Return the updated index
        });
      };

    useEffect(() => {
        if (thumbnailContainerRef.current) {
          const thumbnailDimension = window.innerWidth < 1000 ? 110 : 100; // Adjust this value based on thumbnail width
          const scrollAmount = selectedIndex * thumbnailDimension;
      
          thumbnailContainerRef.current.scrollTo({
            top: window.innerWidth < 1000 ? 0 : scrollAmount,
            left: window.innerWidth < 1000 ? scrollAmount : 0,
            behavior: 'smooth',
          });
        }
      }, [selectedIndex]);

      let vid = document.getElementById("selectedVideo");
      function playVid() {
        vid.play();
    }
    
    function pauseVid() {
        vid.pause();
    }

    return (
        // <div className=''>
        //     <div className='customer-product-image-layout-thumbnails-layout'>
        //         {/* <button className='customer-product-image-layout-thumbnails-layout-previous-button' onClick={handlePreviousClick}>Previous</button> */}
        //         {/* <button className='customer-product-image-layout-thumbnails-layout-left-button' onClick={handlePreviousClick}>Previous</button> */}
        //         <div className='customer-product-image-layout-thumbnails'>
        //             {thumbnails?.map((thumbnail, index) => (
        //                 <div
        //                     className={`customer-product-image-layout-thumbnail-img ${index === selectedIndex ? 'customer-product-image-layout-thumbnail-img-selected' : ''}`}
        //                     key={index}
        //                     onClick={() => handleThumbnailClick(index)}
        //                 >
        //                     {thumbnailImages?.[index]?.endsWith('.mp4') ? ( // Check if the thumbnail is a video (adjust the check based on your video file extensions)
        //                         <video
        //                             src={thumbnail}
        //                             alt={`Video ${index}`}
        //                             className='customer-product-image-layout-thumbnail-img'
                                    
        //                         />
        //                     ) : (
        //                         <img
        //                             src={thumbnail}
        //                             alt={`Image ${index}`}
        //                             className='customer-product-image-layout-thumbnail-img'
        //                         />
        //                     )}
        //                 </div>
        //             ))}
        //         </div>
        //     </div>
        //     <div className='customer-product-image-layout-selected-image-layout'>
        //         <CaretLeft size={34}style={{background:'#ffffff8a', padding:'5px', borderRadius:'100px',cursor:'pointer'}}  weight="bold" className='customer-product-image-layout-thumbnails-layout-previous-button' onClick={handlePreviousClick} />
        //         <CaretLeft size={34} style={{background:'#ffffff8a', padding:'5px', borderRadius:'100px',cursor:'pointer'}} weight="bold" className='customer-product-image-layout-thumbnails-layout-left-button' onClick={handlePreviousClick} />
        //         {thumbnailImages?.[selectedIndex]?.endsWith('.mp4') ? (
        //             <video  src={thumbnails[selectedIndex]} alt={`Selected`} controls className='customer-product-image-layout-selected-image'/>
        //         ) : (
        //             <img loading="lazy" src={thumbnails[selectedIndex]} alt={`Selected`} className='customer-product-image-layout-selected-image'/>
        //         )}
        //            <CaretRight size={34} style={{background:'#ffffff8a', padding:'5px', borderRadius:'100px',cursor:'pointer'}} weight="bold" className='customer-product-image-layout-thumbnails-layout-next-button'onClick={handleNextClick} />
        //         <CaretRight size={34} style={{background:'#ffffff8a', padding:'5px', borderRadius:'100px',cursor:'pointer'}}  weight="bold" className='customer-product-image-layout-thumbnails-layout-right-button'onClick={handleNextClick} />
        //     </div>
        // </div>
        <div className='customer-product-image-layout'>
        <div className='customer-product-image-layout-thumbnails-layout'>
            <div className='customer-product-image-layout-thumbnails'>
                {thumbnails.map((thumbnail, index) => (
                    <div
                        className={`customer-product-image-layout-thumbnail-img ${index === selectedIndex ? 'customer-product-image-layout-thumbnail-img-selected' : ''}`}
                        key={index}
                        onClick={() => handleThumbnailClick(index)}
                    >
                        {thumbnailImages?.[index]?.endsWith('.mp4') ? (
                            <video
                                src={thumbnail}
                                alt={`Video ${index}`}
                                className='customer-product-image-layout-thumbnail-img'
                            />
                        ) : (
                            <img
                                src={thumbnail}
                                alt={`Image ${index}`}
                                className='customer-product-image-layout-thumbnail-img'
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
        <div className='customer-product-image-layout-selected-image-layout'>
            {thumbnails.length > 1 && (
                <>
                    <CaretLeft
                        size={34}
                        style={{ background: '#ffffff8a', padding: '5px', borderRadius: '100px', cursor: 'pointer' }}
                        weight='bold'
                        className='customer-product-image-layout-thumbnails-layout-previous-button'
                        onClick={handlePreviousClick}
                    />
                    <CaretLeft
                        size={34}
                        style={{ background: '#ffffff8a', padding: '5px', borderRadius: '100px', cursor: 'pointer' }}
                        weight='bold'
                        className='customer-product-image-layout-thumbnails-layout-left-button'
                        onClick={handlePreviousClick}
                    />
                </>
            )}
            {thumbnailImages?.[selectedIndex]?.endsWith('.mp4') ? (
                <video src={thumbnails[selectedIndex]} alt={`Selected`} controls className='customer-product-image-layout-selected-image' />
            ) : (
                <img loading='lazy' src={thumbnails[selectedIndex]} alt={`Selected`} className='customer-product-image-layout-selected-image' />
            )}
            {thumbnails.length > 1 && (
                <>
                    <CaretRight
                        size={34}
                        style={{ background: '#ffffff8a', padding: '5px', borderRadius: '100px', cursor: 'pointer' }}
                        weight='bold'
                        className='customer-product-image-layout-thumbnails-layout-next-button'
                        onClick={handleNextClick}
                    />
                    <CaretRight
                        size={34}
                        style={{ background: '#ffffff8a', padding: '5px', borderRadius: '100px', cursor: 'pointer' }}
                        weight='bold'
                        className='customer-product-image-layout-thumbnails-layout-right-button'
                        onClick={handleNextClick}
                    />
                </>
            )}
        </div>
    </div>
    );
}

export default CustomerImageLayout;
