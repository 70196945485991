import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Dropzone from "react-dropzone";
import { CircularProgress, MenuItem, TextField } from "@mui/material";
// import Heading from "../components/Heading";
import { countries } from "countries-list";
import md5 from "crypto-js/md5";
// import SellerFooter from "../components/SellerFooter";
import "../styles/FileUploader.css";
import "../styles/AddProductBySeller.css";
import "../styles/Common.css";
import Heading from "../components/Heading";
import ImageUploderImage from "../Images/ImageUploderImage.png"
// import SellerLayout from "../components/SellerLayout";
import { SellerContext } from "../context/SellerContext";
import { v4 as uuidv4 } from "uuid";
import withSellerAuth from "../context/withSellerAuth";
import { Link, useNavigate, useParams } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Tostify from "../components/Tostify";
//import notif from "../components/Notify";
import { confirmAlert, successAlert, errorAlert } from "../components/Alert";
import { DeleteOutline } from "@mui/icons-material";
import SellerLayoutAdjustment from "../components/SellerLayoutAdjustment";
// import productIcon from "../Images/producticon.png"
// import { makeStyles } from "@mui/styles";
// import MyhrakiTextfield from "../components/MyhrakiTextfield";
import ToastComponent from "../components/ToastComponent";
import { API_BASE_URL } from "../App";
import { CaretLeft } from "@phosphor-icons/react";
import { AdminContext } from "../context/AdminContext";
import AdminLayoutAdjustment from "../components/AdminLayoutAdjustment";


function AdminEditPage() {
  const { adminData, accessToken, handleAuthExpiry, logout } = useContext(AdminContext);
//   const [sellerId, setSellerId] = useState(sellerData?.result?.typeOfUser==='SLADM'?sellerData?.result?.userID:sellerData?.result?.userAdminId);
const [sellerId, setSellerId] = useState();
//   const sellerPlan = sellerPlanDetails;
  const [category, setCategory] = useState('');
  const [title, setTitle] = useState();
  const [gender, setGender] = useState('');
  const [sellerCertUri, setSellerCertUri] = useState();
  const [description, setDescription] = useState();
  const [fact, setFact] = useState();
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState();
  const [errors, setErrors] = useState({});
  const [tab, setTab] = useState("basic");
  const [videoAllowed, setVideoAllowed] = useState(false);
  // const [commonVideos, setCommonVideos] = useState([]);
  // const [isCommonVideo, setIsCommonVideo] = useState(false);
  // const [isChecked, setIsChecked] = useState(false);
  // const [commonImages, setCommonImages] = useState([]);
  const [categoryList, setCategoryList] = useState();
  const [addressList, setAddressList] = useState();
  const [vegan, setVegan] = useState(false);
  const [handmade, setHandmade] = useState(false);
  const [makeInIndia, setMakeInIndia] = useState(false);
  const [productGST, setProductGST] = useState('');
  const toastRef = React.useRef();
  const [productData, setProductData] = useState({
    category,
    title,
    gender,
    description,
    fact,
    address,
    country,
    makeInIndia,
    vegan,
    handmade,
    productGST,
    sellerId,
    delivery: {
      cashOnDelivery: "NA",
      returnable: "NA",
      refundable: "NA",
    },
  });


  const navigate = useNavigate();
  // Function to compare arrays
  function arraysAreEqual(array1, array2) {
    if (array1?.length !== array2?.length) {
      return false;
    }

    for (let i = 0; i < array1?.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }

    return true;
  }


  const [currentProductData, setCurrentProductData] = useState(productData);
  const [currenSkus, setCurrentSkus] = useState();
  const [loading, setLoading] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);


  function isAddVideoAllowed() {
    // const elements = sellerPlanDetails?.toString().split(".");
    // if (elements?.length >= 12) {
    //   const videoElement = parseFloat(elements[8]);
    let videoElement=1
      if(videoElement>0){
        return true; 
      }
    // }
    return false;
  }


  function checkVideoAllowed() {
    if (isAddVideoAllowed()) {
      setVideoAllowed(true)
    }
    else {
      setVideoAllowed(false)
    }
  }

  useEffect(() => {
    checkVideoAllowed()
  }, [])


  const params = useParams();
  const productId = params.id;

  const handleSubmit = (e) => {
    // e.preventDefault();
    if (validate()) {
      setTab("specific");
      window.scrollTo(0, 0);

      ////console.log("Form submitted");
    }
  };

  const fetchCategories = async () => {
    try {
      let response = await fetch(
        `${API_BASE_URL}/productgen/api/v1/Category/All`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      let data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        errorAlert('', 'Your Session has expired');
        logout();
        return;
      }
      if (data?.status === 401) {
        errorAlert('', 'Session timed out. Please Log in');

        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate('/')
      }
      setCategoryList(data.result);
    } catch (error) {
      //console.error(error);
    }
  };
//   const fetchAddress = async () => {
//     try {
//       let response = await fetch(
//         // `${API_BASE_URL}/address/api/v1/${sellerData?.result?.userID}/false `,
//         `${API_BASE_URL}/address/api/v1/getAll?userId=${sellerData?.result?.AdminId}`,

//         {
//           headers: {
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );


//       let data = await response.json();
//       const handleAuthError = handleAuthExpiry(response);
//       if (handleAuthError === true) {
//         errorAlert('', 'Your Session has expired');
//         logout();
//         return;
//       }
//       setAddressList(data.result);
//     }
//     catch (error) {
//       //console.error(error);
//     }
//   };
  useEffect(() => {
    fetchCategories();
    // fetchAddress();
  }, []);

  useEffect(() => {
    const categoryIdToFind = currentProductData?.[0]?.category.split('/')[1];

    if (categoryIdToFind) {
      const filteredCategory = categoryList?.find(
        (category) => category.categoryId == categoryIdToFind
      );

      if (filteredCategory) {
        //console.log(filteredCategory.categoryName);
        setCategory(filteredCategory.categoryId);
        // Set categoryId or the desired property.
        setProductData((prevData) => ({
          ...prevData,
          gender: currentProductData?.[0]?.gender,
          category: filteredCategory.categoryId, // Set categoryId or the desired property.
        }));
      } else {
        //console.log("Category not found or filteredCategory is undefined.");
      }
    } else {
      //console.log("categoryIdToFind is undefined.");
    }
  }, [categoryList, currentProductData]);




  useEffect(() => {
    const addressIdToFind = currentProductData?.[0]?.address;

    if (addressIdToFind) {
      const filteredAddress = addressList?.find(
        (address) => address.id == addressIdToFind
      );

      if (filteredAddress) {
        //console.log(filteredAddress);
        setAddress(filteredAddress.id); // Set addressId or the desired property.
        setProductData((prevData) => ({
          ...prevData,
          address: filteredAddress.id, // Set addressId or the desired property.
        }));
      } else {
        //console.log("Address not found or filteredAddress is undefined.");
      }
    } else {
      //console.log("addressIdToFind is undefined.");
    }
  }, [addressList, currentProductData]);



  const validate = () => {
    let tempErrors = {};
    if (!category) {
      tempErrors.category = "Please choose a category";
    }
    if (
      !title ||
      !/^(?=.*\S).{1,250}$/
        .test(
          title
        )
    ) {
      tempErrors.title = "Please provide a valid Product Name";
    }
    if (!gender) {
      tempErrors.gender = "Please select applicable genders";
    }
    if (!productGST) {
      tempErrors.productGST = "Please Enter a valid GST Amount";
    } else {
      const gstAmount = Number(productGST);
    
      if (isNaN(gstAmount) || gstAmount >= 100 || gstAmount < 0) {
        tempErrors.productGST = "GST Amount should be a number less than 100";
      }
    }
    if (!description || !/^[\s\S]{1,2000}$/.test(description)|| !description?.trim().length>=1) {
      tempErrors.description = "Please provide a description";
    }
    if (!address) {
      tempErrors.address = "Please select an Address";
    }
    if (fact?.length > 0 && !/^[\s\S]{1,1000}$/.test(fact)) {
      tempErrors.fact = "Provide a fact within 1000 characters";
    }
    if (!country) {
      tempErrors.country = "Please select a Country";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };


  const fetchProductData = async () => {
    setProductLoading(true);
    try {
      let response = await fetch(
        `${API_BASE_URL}/productgen/api/v1/get?productId=${productId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      let data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        errorAlert('', 'Your Session has expired');
        logout();
        return;
      }
      if (data?.status === 401) {
        errorAlert('','Session timed out. Please Log in');
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate('/')
      }
      setCurrentProductData(data.result);
      setSellerId(data.result[0].sellerId)
      console.log(data.result[0].sellerId);
      setProductLoading(false)
    } catch (error) {
      //console.error(error);
      setProductLoading(false)

    }
  };


  useEffect(() => {
    fetchProductData();
  }, []);

  useEffect(() => {
    // ////console.log(category);
    //console.log(currentProductData)
    //console.log(currentProductData?.[0]?.category);
    //console.log(categoryList)
    //console.log(categoryList, currentProductData?.[0]?.category.split('/')[1])
    // setCategory(categoryList?.filter(category => category.categoryId == currentProductData?.[0]?.category.split('/')[1]));
    // setCategory(currentProductData?.[0]?.title);
    setTitle(currentProductData?.[0]?.title);
    setAddress(currentProductData?.[0]?.address);
    setCountry(currentProductData?.[0]?.country);
    setFact(currentProductData?.[0]?.fact);
    setGender(currentProductData?.[0]?.gender);
    setDescription(currentProductData?.[0]?.description);
    setVegan(currentProductData?.[0]?.vegan);
    setHandmade(currentProductData?.[0]?.handmade);
    setMakeInIndia(currentProductData?.[0]?.makeInIndia);
    setProductGST(currentProductData?.[0]?.productGST);
    setCurrentSkus(currentProductData?.[0]?.productSKUs?.map((sku) => ({ ...sku, newSKU: false })));
    setProductData((prevData) => ({
      ...prevData,
      gender: currentProductData?.[0]?.gender,
      // category: currentProductData?.[0].title,
      address: currentProductData?.[0]?.address,
      country: currentProductData?.[0]?.country,
      fact: currentProductData?.[0]?.fact,
      title: currentProductData?.[0]?.title,
      description: currentProductData?.[0]?.description,

    }));
  }, [currentProductData]);

  const countryList = Object.values(countries);

  const sortedCountries = countryList.sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const filteredCountries = sortedCountries.filter((country) =>
    country.name.toLowerCase().includes("a")
  );

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    setProductData((prevData) => ({
      ...prevData,
      category: event.target.value,
    }));
    setErrors((prevErrors) => {
      const { category: nameError, ...rest } = prevErrors;
      return { ...rest };
    });
  };
  const handleAddressChange = (event) => {
    setAddress(event.target.value);
    setProductData((prevData) => ({
      ...prevData,
      address: event.target.value,
    }));
    setErrors((prevErrors) => {
      const { address: nameError, ...rest } = prevErrors;
      return { ...rest };
    });
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
    setProductData((prevData) => ({
      ...prevData,
      gender: event.target.value,
    }));
    setErrors((prevErrors) => {
      const { gender: nameError, ...rest } = prevErrors;
      return { ...rest };
    });
  };

  const handleProductNameChange = (event) => {
    setTitle(event.target.value);
    setProductData((prevData) => ({
      ...prevData,
      title: event.target.value,
    }));
    setErrors((prevErrors) => {
      const { title: nameError, ...rest } = prevErrors;
      return { ...rest };
    });
  };
  const handleProductFactChange = (event) => {
    setFact(event.target.value);
    setProductData((prevData) => ({
      ...prevData,
      fact: event.target.value,
    }));
    setErrors((prevErrors) => {
      const { fact: nameError, ...rest } = prevErrors;
      return { ...rest };
    });
  };
  const handleProductDescriptionChange = (event) => {
    setDescription(event.target.value);
    setProductData((prevData) => ({
      ...prevData,
      description: event.target.value,
    }));
    setErrors((prevErrors) => {
      const { description: nameError, ...rest } = prevErrors;
      return { ...rest };
    });
  };


  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setCountry(selectedCountry);
    setProductData((prevData) => ({
      ...prevData,
      country: selectedCountry.name,
    }));
    setErrors((prevErrors) => {
      const { country: nameError, ...rest } = prevErrors;
      return { ...rest };
    });
  };

  const handleVeganChange = (event) =>{
    
    setProductData((prevData) => ({
      ...prevData,
      vegan: event.target.value,
    }));
   
  }
  const handleMakeInIndiaChange = (event) =>{
    
    setProductData((prevData) => ({
      ...prevData,
      makeInIndia: event.target.value,
    }));
   
  }
  const handleHandmadeChange = (event) =>{
    
    setProductData((prevData) => ({
      ...prevData,
      handmade: event.target.value,
    }));
  
  }
  const handleProductGSTChange = (event) =>{
    setProductGST(event.target.value);
    setProductData((prevData) => ({
      ...prevData,
      productGST: event.target.value,
    }));
    setErrors((prevErrors) => {
      const { productGST: nameError, ...rest } = prevErrors;
      return { ...rest };
    });
    console.log(productData);
  
  }



  useEffect(() => {
    ////console.log(productData);
  }, [productData, currentProductData]);

  const [formData, setFormData] = useState({});

  ////////////////////////////////

  async function SubmitProductDetails() {
    setLoading(true);
    if (!productData.fact) {
      productData.fact = "";
      setLoading(false);

    }
    if (productData?.skUs?.length <= 0) {
      errorAlert("", "Add Atleast one variant");
      setLoading(false);

      return;
    }
    try {
      const updatedProductData = {
        ...productData,
        productId: productId,
        sellerId:sellerId, 
        // address:.address
      };

      let result = await fetch(
        `${API_BASE_URL}/productgen/api/v1/update`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`,
          },
          body: JSON.stringify(updatedProductData),

        }
      );
      setLoading(false)
      const response = await result.json();
      const handleAuthError = handleAuthExpiry(result);
      if (handleAuthError === true) {
        errorAlert('', 'Your Session has expired');
        logout();
        return;
      }
      if (response?.result === true) {
        // getSellerPlan()
        navigate('/adminProductSearch')
        // errorAlert('','Product Updated Successfully');
        successAlert('', 'Product Updated Successfully')
        setLoading(false)
      }
      else {
        // errorAlert('','Something went Wrong. Please check the data and try again')
        errorAlert('', "Something went Wrong. Please check the data and try again");

        setLoading(false)

      }
    } catch (e) {
      ////console.log(e);
      // errorAlert('','Something went Wrong. Please check the data and try again')
      errorAlert('', "Something went Wrong. Please check the data and try again");
      setLoading(false)

    }
  }

  return (
    <AdminLayoutAdjustment>
      {/* <div className="product-title-card">
          <h2>Product Details</h2>
          <p>Here you expand your store with multiple products</p>
        </div> */}
      <div className="page-content">
        <Heading text="Product Details" />
        {tab === "basic" && !productLoading && !addressLoading && (
          <div>
<div onClick={() => { navigate('/adminProductSearch') }} style={{cursor:'pointer', display:'flex', alignItems:'center'}}>
              <CaretLeft /> Back</div>
            <form className="add-product-by-seller-form" >
              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">Category *</div>
                <div className="add-product-by-seller-field-textfield">
                  <TextField
                    select
                    value={category}
                    onChange={handleCategoryChange}
                    fullWidth
                    outlined
                    error={Boolean(errors.category)}
                    helperText={errors.category}
                  >
                    {categoryList?.map((category) => (
                      <MenuItem value={category.categoryId}>{category.categoryName}</MenuItem>
                    ))}
                  </TextField>
                </div>
                {/* {errors.category && (
            <div className="add-product-by-seller-field-error-text">
              Please Select a Category
            </div>
          )} */}
              </div>

              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">
                  Product Name *
                </div>
                <div className="add-product-by-seller-field-textfield">
                  <TextField
                    fullWidth
                    outlined
                    value={title}
                    onChange={handleProductNameChange}
                    error={Boolean(errors.title)}
                    helperText={errors.title}
                  />
                </div>
              </div>

              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">Gender *</div>
                <div className="add-product-by-seller-field-textfield">
                  <TextField
                    select
                    // disabled
                    value={gender ? gender : currentProductData?.[0]?.gender ? currentProductData?.[0]?.gender : ''}
                    onChange={handleGenderChange}
                    fullWidth
                    outlined
                    error={Boolean(errors.gender)}
                    helperText={errors.gender}
                  >
                    {/* <MenuItem value={gender}>{gender}</MenuItem> */}
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                    <MenuItem value={"Unisex"}>Unisex</MenuItem>
                    <MenuItem value={"Not Applicable"}>Not Applicable</MenuItem>
                  </TextField>
                </div>
                {/* {errors.gender && (
            <div className="add-product-by-seller-field-error-text">
              Please Select applicable gender
            </div>
          )} */}
              </div>

              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">Description *</div>
                <div className="add-product-by-seller-field-textfield">
                  {/* <TextField fullWidth outlined/> */}
                  <textarea
                    className={`add-product-by-seller-field-text-area ${errors.description ? "add-product-by-seller-field-error" : ""
                      }`}
                    value={description}
                    onChange={handleProductDescriptionChange}
                    error={Boolean(errors.description)}
                    helperText={errors.description}
                  />
                </div>
                {errors.description && (
                  <div className="add-product-by-seller-field-error-text-desc">
                    Please add a description
                  </div>
                )}
              </div>


              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">Fact</div>
                <div className="add-product-by-seller-field-textfield">
                  {/* <TextField fullWidth outlined/> */}
                  <textarea
                    className={`add-product-by-seller-field-text-area ${errors.fact ? "add-product-by-seller-field-error" : ""
                      }`}
                    value={fact}
                    onChange={handleProductFactChange}
                    error={Boolean(errors.fact)}
                    helperText={errors.fact}
                  />
                </div>
                {errors.fact && (
                  <div className="add-product-by-seller-field-error-text-desc">
                    Fact should be less than 300 characters.
                  </div>
                )}
              </div>

              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">Address *</div>
                <div className="add-product-by-seller-field-textfield">
                  <TextField
                    
                    value={address}
                    // onChange={handleAddressChange}
                    fullWidth
                    outlined
                    error={Boolean(errors.address)}
                    helperText={errors.address}
                  >
                   
                  </TextField>
                </div>
              </div>

              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">Country *</div>
                <div className="add-product-by-seller-field-textfield">
                  <TextField
                    // select
                    disabled
                    value={country}
                    onChange={handleCountryChange}
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors.country)}
                    helperText={errors.country}
                  >
                    <MenuItem value="">Select a country</MenuItem>
                    {filteredCountries.map((country) => (
                      <MenuItem value={country}>{country.name}</MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>

              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">Product GST</div>
                <div className="add-product-by-seller-field-textfield">
                  {/* <TextField fullWidth outlined/> */}
                  <TextField
                    fullWidth
                    value={productGST}
                    onChange={handleProductGSTChange}
                    error={Boolean(errors.productGST)}
                    helperText={errors.productGST}
                  />
                </div>
              </div>

              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">Is your product Vegan*</div>
                <div className="add-product-by-seller-field-textfield"  style={{display:'flex', gap:'50px'}}>
                <label>
        <input
          type="radio"
          name="vegan"
          value={true}
          checked={vegan===true}
          onChange={(e) => {setVegan(true); handleVeganChange(e);}}
        />{' '}
        Yes
      </label>
      <br />
      <label>
        <input
          type="radio"
          name="vegan"
          value={false}
          checked={vegan===false}
          onChange={(e) => {setVegan(false); handleVeganChange(e);}}
        />{' '}
        No
      </label>
                </div>
              </div>

              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name"> Is your product Handmade*</div>
                <div className="add-product-by-seller-field-textfield" style={{display:'flex', gap:'50px'}}>
                <label>
        <input
          type="radio"
          name="handmade"
          style={{color:'pink', background:'pink'}}
          value={true}
          checked={handmade===true}
          onChange={(e) => {setHandmade(true); handleHandmadeChange(e)}}
        />{' '}
        Yes
      </label>
      <br />
      <label>
        <input
          type="radio"
          name="handmade"
          value={false}
          checked={handmade===false}
          onChange={(e) => {setHandmade(false); handleHandmadeChange(e)}}
        />{' '}
        No
      </label>
                </div>
              </div>

              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">Is your product Made In India*</div>
                <div className="add-product-by-seller-field-textfield"  style={{display:'flex', gap:'50px'}}>
                <label>
        <input
          type="radio"
          name="madeInIndia"
          value={true}
          checked={makeInIndia===true}
          onChange={(e) => {setMakeInIndia(true); handleMakeInIndiaChange(e);}}
        />{' '}
        Yes
      </label>
      <br />
      <label>
        <input
          type="radio"
          name="madeInIndia"
          value={false}
          checked={makeInIndia===false}
          onChange={(e) => {setMakeInIndia(false); handleMakeInIndiaChange(e)}}
        />{' '}
        No
      </label>
                </div>
              </div>

              <div className="add-product-by-seller-field-button">
                <button className="common-btn" type="button" onClick={handleSubmit}>
                  Submit and Proceed
                </button>
              </div>
            </form>
          </div>
        )}
        {tab === "basic" && productLoading && <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "pink", height: "100px", width: "100px" }} /></div>}
        {tab === "specific" && (
          <div>
            <Form
              setFormData={setFormData}
              // isChecked={isChecked}
              // isCommonVideo={isCommonVideo}
              videoAllowed={videoAllowed}
              productData={productData}
              setProductData={setProductData}
              SubmitProductDetails={SubmitProductDetails}
              // commonVideos={commonVideos}
              // commonImages={commonImages}
              currentProductData={currentProductData}
              currenSkus={currenSkus}
            //   sellerData={sellerData}
              adminData={adminData}
              arraysAreEqual={arraysAreEqual}
              accessToken={accessToken}
              loading={loading}
              setLoading={setLoading}
              tab={tab}
              setTab={setTab}
              logout={logout}
              handleAuthExpiry={handleAuthExpiry}
            />
          </div>
        )}
        {/* <div>{data}</div> */}
        {/* //<ToastContaner theme="coloured" className="toast-message" /> */}
      </div>
    </AdminLayoutAdjustment>
  );
}

export default (AdminEditPage);

function Form({
  setFormData,
  formData,
  isChecked,
  isCommonVideo,
  videoAllowed,
  commonVideos,
  commonImages,
  productData,
  setProductData,
  SubmitProductDetails,
  currentProductData,
  currenSkus,
  loading, setLoading,
  adminData, arraysAreEqual, accessToken, tab, setTab, handleAuthExpiry, logout
}) {
  const [forms, setForms] = useState([]);
  const [sellerCertUri, setSellerCertUri] = useState();
  // const sellgetSellerPlanerId=1;
//   const {getSellerPlan} = useContext(SellerContext);

  function generateSkuId(sellerId) {
    const datetime = new Date().getTime().toString();
    const skuData = sellerId + datetime;
    const sku = md5(skuData).toString();
    ////console.log(sku);
    return sku;
  }

  useEffect(() => {
    ////console.log(forms);
    setFormData(forms);
    // eslint-disable-next-line
  }, [forms]);

  useEffect(() => {
    const newData = { ...productData, skUs: forms };
    setProductData(newData);
    ////console.log(productData);
    // eslint-disable-next-line
  }, [forms]);

  useEffect(() => {
    // Create a new array to store the transformed SKUs
    const transformedSKUs = currenSkus.map((sku) => {
      // Create a new imageList with the transformed format
      const transformedImageList = sku.imageList.map((filename) => {
        return {
          image: filename,
          // isNew: false,
        };
      });
      const transformedVideoList = sku.videoList.map((filename) => {
        return {
          video: filename,
          // isNew: false,
        };
      });

      // Return the updated SKU object with the transformed imageList
      return {
        ...sku,
        imageList: transformedImageList,
        videoList: transformedVideoList
      };
    });

    // Set the transformed SKUs
    setForms(transformedSKUs);
  }, [currenSkus]);

  const addNewForm = () => {
    ////console.log(currenSkus);
    if (forms.length === 0) {
      handleAddForm();
      return;
    }
    const latestForm = forms[forms.length - 1];
    const errorReasons = [];
    if (!/^[a-zA-Z,& ]{0,100}$/.test(latestForm?.color)) {
      errorReasons.push("Invalid color");
    }

    if (!/^[a-zA-Z,& ]{0,100}$/.test(latestForm?.fabric)) {
      errorReasons.push("Invalid Material");
    }

    if (!/^[1-9]\d*$/.test(latestForm?.price)) {
      errorReasons.push("Invalid price");
    }

    if (!/^[1-9]\d*$/.test(latestForm?.sellingPrice)) {
      errorReasons.push("Invalid selling price");
    }

    if (parseInt(latestForm?.sellingPrice) > parseInt(latestForm?.price)) {
      errorReasons.push("Selling price should be lesser than price");
    }

    if (!/^[1-9]\d*$/.test(latestForm?.quantity)) {
      errorReasons.push("Invalid quantity");
    }
    if (!latestForm?.imageList?.length > 0) {
      errorReasons.push(`At least one image is required.`);
    }
    if (!(latestForm?.productAttributeList[0].a_Value)) {
      errorReasons.push(
        `Integer value is required for Length property .`
      );
    }
    if (!(latestForm?.productAttributeList[1].a_Value)) {
      errorReasons.push(
        `Integer value is required for Width property .`
      );
    }
    if (!(latestForm?.productAttributeList[2].a_Value)) {
      errorReasons.push(
        `Integer value is required for Height property .`
      );
    }
    if (!(latestForm?.productAttributeList[3].a_Value)) {
      errorReasons.push(
        `Integer value is required for Weight property .`
      );
    }

    if (errorReasons.length > 0) {
      const errorMessage = `The latest form has the following errors:\n${errorReasons.join(
        "\n"
      )}`;
      errorAlert('', errorMessage, '', '', 10000, true);
    } else {
      handleAddForm();
    }
  };

  const handleAddField = (formIndex) => {
    const newForms = [...forms];
    newForms[formIndex].productAttributeList.push({ a_Key: "", a_Value: "" });
    setForms(newForms);
  };
  const [prevImages, setPrevImages] = useState([]);
  const [prevVideos, setPrevVideos] = useState([]);
  // const [prevAttributes, setPrevAttributes] = useState([]);
  const toastRef = React.useRef();

  const handlePrevImagesChange = (event, formIndex) => {
    const form = forms?.[formIndex];
    ////console.log(form);
    const updatedPrevImages = [...prevImages];
    updatedPrevImages[formIndex] = event.target.checked;

    if (event.target.checked && formIndex > 0) {
      const prevFormIndex = formIndex - 1;
      const prevImageList = forms[prevFormIndex]?.imageList;
      const prevImagesUriList = forms[prevFormIndex]?.imagesUriList;
      ////console.log(forms[prevFormIndex]?.imagesUriList);
      ////console.log(prevImageList);
      ////console.log(prevImagesUriList);
      setForms((prevForm) => {
        const updatedForm = [...prevForm];
        updatedForm[formIndex] = {
          ...prevForm[formIndex],
          imageList: prevImageList,
          imagesUriList: prevImagesUriList,
        };
        return updatedForm;
      });
    } else {
      setForms((prevForm) => {
        const updatedForm = [...prevForm];
        updatedForm[formIndex] = {
          ...updatedForm[formIndex],
          imageList: [],
          imagesUriList: [],
        };
        return updatedForm;
      });
    }

    setPrevImages(updatedPrevImages);
  };

  const handlePrevVideoChange = (event, formIndex) => {
    const form = forms?.[formIndex];
    const updatedPrevVideos = [...prevVideos];
    updatedPrevVideos[formIndex] = event.target.checked;

    if (event.target.checked && formIndex > 0) {
      const prevFormIndex = formIndex - 1;
      const prevVideoList = forms[prevFormIndex]?.videoList;
      const prevVideosUriList = forms[prevFormIndex]?.videosUriList;

      setForms((prevForm) => {
        const updatedForm = [...prevForm];
        updatedForm[formIndex] = {
          ...prevForm[formIndex],
          videoList: prevVideoList,
          videosUriList: prevVideosUriList,
        };
        return updatedForm;
      });
    } else {
      setForms((prevForm) => {
        const updatedForm = [...prevForm];
        updatedForm[formIndex] = {
          ...updatedForm[formIndex],
          videoList: [],
          videosUriList: [],
        };
        return updatedForm;
      });
    }

    setPrevVideos(updatedPrevVideos);
  };


  const handleAddForm = () => {

    setForms((prevForms) => {
      // const lastForm = prevForms[prevForms.length - 1];
      const newForm = {
        size: "",
        price: "",
        sellingPrice: "",
        quantity: "",
        newSKU: true,
        sku: generateSkuId(adminData?.result?.userID),
        productAttributeList: [
          { a_Key: "length", a_Value: "" },
          { a_Key: "width", a_Value: "" },
          { a_Key: "height", a_Value: "" },
          { a_Key: "weight", a_Value: "" },
        ],
        imageList: [],
        videoList: [],
        imagesUriList: [],
        videosUriList: []
      };
      return [...prevForms, newForm];
    });
  };


  useEffect(() => {
    if (prevImages) {
      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        updatedForms[prevForms.length - 1] = {
          ...updatedForms[prevForms.length - 1],
          imageList: updatedForms[prevForms.length - 1]?.imageList.map((image) => ({
            ...image,
            isNew: true,
          })) || [],
          imagesUriList: updatedForms[prevForms.length - 1]?.imagesUriList || [],
          isNewfiles: true,
        };
        return updatedForms;
      });
    } else {
      ////console.log("thissssss")
      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        updatedForms[prevForms.length - 1] = {
          ...updatedForms[prevForms.length - 1],
          imageList: [],
          imagesUriList: [],
        };
        return updatedForms;
      });
    }
  }, [prevImages]);

  useEffect(() => {
    if (prevVideos) {
      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        updatedForms[prevForms.length - 1] = {
          ...updatedForms[prevForms.length - 1],
          videoList: updatedForms[prevForms.length - 1]?.videoList.map((image) => ({
            ...image,
            isNew: true,
          })) || [],
          videosUriList: updatedForms[prevForms.length - 1]?.videosUriList || [],
          isNewfiles: true,
        };
        return updatedForms;
      });
    }
    if (!prevVideos) {
      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        updatedForms[prevForms.length - 1] = {
          ...updatedForms[prevForms.length - 1],
          videoList: [],
          videosUriList: [],
        };
        return updatedForms;
      });
    }
  }, [prevVideos]);


  const [uploading, setUploading] = useState(false);
  const [videoUploading, setVideoUploading] = useState(false);

  // const [thumbnailUrls, setThumbnailUrls] = useState([]);
  // const [thumbnailVideoUrls, setThumbnailVideoUrls] = useState([]);

  const handleImageDrop = (acceptedFiles, formIndex) => {
    if (acceptedFiles.length > 1) {
      errorAlert('', "Only one file can be uploaded at a time.");
      return;
    }
    if (!isChecked) {
      const updatedForms = [...forms];
      const updatedImages = updatedForms[formIndex].imageList
        ? [...updatedForms[formIndex].imageList]
        : [];
      const updatedImagesUriList = updatedForms[formIndex].imagesUriList
        ? [...updatedForms[formIndex].imagesUriList]
        : [];

      acceptedFiles.forEach(async (file) => {
        const readFileAsArrayBuffer = (file) => {
          return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = () => {
              const arrayBuffer = fileReader.result;
              resolve(new Uint8Array(arrayBuffer));
            };

            fileReader.onerror = (error) => {
              reject(error);
            };

            fileReader.readAsArrayBuffer(file.slice(0, 4)); // Read only the first 4 bytes
          });
        };
        if(updatedImages?.length===10){
          errorAlert('', "Only ten images can be uploaded.");
          return;
        }

        try {
          const uint8Array = await readFileAsArrayBuffer(file);
          //console.log(uint8Array)
          // Check if the magic number corresponds to jpg/jpeg/png
          if (
            uint8Array.length >= 2 &&
            uint8Array[0] === 0xFF &&
            uint8Array[1] === 0xD8
          ) {
            // JPG file
          } else if (
            uint8Array.length >= 4 &&
            uint8Array[0] === 0x89 &&
            uint8Array[1] === 0x50 &&
            uint8Array[2] === 0x4E &&
            uint8Array[3] === 0x47 
          ) {
            // PNG file
          } else if (
            uint8Array.length >= 2 &&
            uint8Array[0] === 0xFF &&
            uint8Array[1] === 0xD9
          ) {
            // JPEG file (ending with EOI marker)
          } else {
            // Invalid file type
            //console.log(uint8Array);
            errorAlert('',
              "Invalid file type. Please choose a valid jpg, jpeg, or png image file."
            );
            return;
          }


          // Continue with the rest of your code for size and extension validation
          if (
            !["image/jpeg", "image/png", "image/jpg"].includes(file.type)
          ) {
            errorAlert('',"File type must be jpg, jpeg, or png.");
            return;
          }

          if (file.size >= 5 * 1024 * 1024) {
            errorAlert('',"File size should be less than 5MB");
            return;
          }

          const formData = new FormData();
          const extension = file.name.split(".").pop().toLowerCase();
          const fileName = uuidv4() + "." + extension;

          formData.append("Attachment", file);
          formData.append("FileNameWithExtension", fileName);
          formData.append("FileCategory", "products");

          setUploading(true);

          fetch(`${API_BASE_URL}/blob/api/v1/uploadmedia`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Accept: "text/plain",
            },
            body: formData,
          })
            .then((response) => {
              if (!response.ok) {
                if (response.status === 401) {
                  //console.log("Unauthorized access. Redirecting to login page.");
                  logout();
                } else {
                  console.error(`Error: ${response.status} - ${response.statusText}`);
                }
                throw new Error("Network response was not ok");
              }
              return response.text();
            })
            .then((result) => {
              const imageThumbnail = URL.createObjectURL(file);
              const newImage = {
                image: fileName,
                isNew: true,
              };
              updatedImages.push(newImage);
              updatedImagesUriList.push({ thumbnailURI: imageThumbnail });
              updatedForms[formIndex].imageList = updatedImages;
              updatedForms[formIndex].imagesUriList = updatedImagesUriList;
              updatedForms[formIndex].isNewfiles = true;
              setForms(updatedForms);

              setUploading(false);
            })
            .catch((error) => {
              console.error("Error uploading image:", error);
              setUploading(false);
            });
        } catch (error) {
          console.error("Error reading file:", error);
        }
      });
    }
  };
  const handleImageCancel = (index, formIndex) => {
    ////console.log(index);
    setForms((prevForms) => {
      const updatedForms = [...prevForms];
      const form = { ...updatedForms[formIndex] };
      const updatedImages = form.imageList.filter((_, i) => i !== index);
      const updatedImagesThumbnails = form.imagesUriList.filter((_, i) => i !== index);

      // Compare imageList arrays
      if (
        currenSkus &&
        currenSkus[formIndex] &&
        arraysAreEqual(currenSkus[formIndex]?.imageList, form?.imageList)
      ) {
        // form.isNewfiles = false;
      }




      form.imageList = updatedImages;
      form.imagesUriList = updatedImagesThumbnails;
      updatedForms[formIndex] = form;

      return updatedForms;
    });
  };

  const handleVideoDrop = (acceptedFiles, formIndex) => {
    if (acceptedFiles.length > 1) {
      errorAlert('', "Only one file can be uploaded at a time.");
      return;
    }
    if (!isCommonVideo) {
      const updatedForms = [...forms];
      const updatedVideos = updatedForms[formIndex].videoList
        ? [...updatedForms[formIndex].videoList]
        : [];
      const updatedVideosUriList = updatedForms[formIndex].videosUriList
        ? [...updatedForms[formIndex].videosUriList]
        : [];

      acceptedFiles.forEach(async (file) => {
        const readFileAsArrayBuffer = (file) => {
          return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = () => {
              const arrayBuffer = fileReader.result;
              resolve(new Uint8Array(arrayBuffer));
            };

            fileReader.onerror = (error) => {
              reject(error);
            };

            fileReader.readAsArrayBuffer(file.slice(0, 4)); // Read only the first 4 bytes
          });
        };
        if(updatedVideos?.length===1){
          errorAlert('', "Only one video can be uploaded.");
          return;
        }

        try {
          const uint8Array = await readFileAsArrayBuffer(file);
            //console.log(uint8Array);
          // Check if the magic number corresponds to common video formats
          if (
            uint8Array.length >= 4 &&
            (uint8Array[0] === 0x00 && uint8Array[1] === 0x00 && uint8Array[2] === 0x00 && uint8Array[3] === 0x18) || // MP4
            (uint8Array[0] === 0x00 && uint8Array[1] === 0x00 && uint8Array[2] === 0x00 && uint8Array[3] === 0x1C) || // MP4
            (uint8Array[0] === 0x00 && uint8Array[1] === 0x00 && uint8Array[2] === 0x00 && uint8Array[3] === 0x20) || // MP4
            (uint8Array[0] === 0x00 && uint8Array[1] === 0x00 && uint8Array[2] === 0x00 && uint8Array[3] === 0x24) || // MP4
            (uint8Array[0] === 0x00 && uint8Array[1] === 0x00 && uint8Array[2] === 0x00 && uint8Array[3] === 0x28) || // MP4
            (uint8Array[0] === 0x00 && uint8Array[1] === 0x00 && uint8Array[2] === 0x00 && uint8Array[3] === 0x32) || // MP4
            (uint8Array[0] === 0x66 && uint8Array[1] === 0x74 && uint8Array[2] === 0x79 && uint8Array[3] === 0x70) || // QuickTime
            (uint8Array[0] === 0x66 && uint8Array[1] === 0x74 && uint8Array[2] === 0x79 && uint8Array[3] === 0x70) // 3GPP
          ) {
            // Continue with the rest of your code for size and extension validation
            if (file.size > 40 * 1024 * 1024) {
              errorAlert('',"File size should be less than 40MB");
              return;
            }

            const formData = new FormData();
            const extension = file.name.split(".").pop().toLowerCase();
            const fileName = uuidv4() + "." + extension;

            formData.append("Attachment", file);
            formData.append("FileNameWithExtension", fileName);
            formData.append("FileCategory", "products");

            setVideoUploading(true); // Set uploading state to true

            fetch(`${API_BASE_URL}/blob/api/v1/uploadmedia`, {
              method: "POST",
              headers: {
                Accept: "text/plain",
                Authorization: `Bearer ${accessToken}`
              },
              body: formData,
            })
              .then((response) => {
                if (!response.ok) {
                  if (response.status === 401) {
                    //console.log("Unauthorized access. Redirecting to login page.");
                    logout();
                  } else {
                    console.error(`Error: ${response.status} - ${response.statusText}`);
                  }
                  throw new Error("Network response was not ok");
                }
                return response.text();
              })
              .then((result) => {
                // Rest of the code to handle successful response
                const videoThumbnail = URL.createObjectURL(file);
                const newVideo = {
                  video: fileName,
                  isNew: true,
                };                updatedVideos.push(newVideo);
                updatedVideosUriList.push({ thumbnailURI: videoThumbnail });
                updatedForms[formIndex].videoList = updatedVideos;
                updatedForms[formIndex].videosUriList = updatedVideosUriList;
                updatedForms[formIndex].isNewfiles = true;
                setForms(updatedForms);

                setVideoUploading(false);
              })
              .catch((error) => {
                // Handle error uploading the video
                setVideoUploading(false); // Set uploading state to false
              });
          } else {
            errorAlert('',`Invalid file type: ${file.type}`);
          }
        } catch (error) {
          console.error("Error reading file:", error);
        }
      });
    }
  };

  const handleVideoCancel = (index, formIndex) => {
    setForms((prevForms) => {
      const updatedForms = [...prevForms];
      const form = { ...updatedForms[formIndex] };
      const updatedVideos = form.videoList.filter((_, i) => i !== index);
      const updatedVideosThumbnails = form.videosUriList.filter((_, i) => i !== index);

      form.videoList = updatedVideos;
      form.videosUriList = updatedVideosThumbnails;
      updatedForms[formIndex] = form;

      return updatedForms;
    });
  };

  const handleChange = (e, formIndex, fieldIndex) => {
    const { name, value } = e.target;
    const newForms = [...forms];

    if (
      name === "color" ||
      name === "fabric" ||
      name === "size" ||
      name === "price" ||
      name === "sellingPrice" ||
      name === "quantity" ||
      name === "sku"
    ) {
      newForms[formIndex][name] = value;
    } else {
      const form = newForms[formIndex];
      const newFields = [...form.productAttributeList];
      newFields[fieldIndex][name] = value;
      newForms[formIndex].productAttributeList = newFields;
    }

    setForms(newForms);
  };


  const deleteSku = async (formIndex) => {
    confirmAlert('', 'Are you sure you want to delete this SKU?', () => { deleteSkuHandler(formIndex) });
  }
  const deleteSkuHandler = async (formIndex) => {
    ////console.log("Before filter:", forms);

    if (forms?.[formIndex]?.sku) {
      const matchedSku = currenSkus?.find((currenSku) => currenSku?.sku === forms[formIndex]?.sku);

      if (matchedSku) {
        const deleteThisSku = await fetch(`${API_BASE_URL}/productgen/api/v1/delete/${forms?.[formIndex]?.sku}`, {
          method: "DELETE",
          headers: {
            AUthorization: `Bearer ${accessToken}`
          },
        })
        const response = await deleteThisSku.json();
        
        const handleAuthError = handleAuthExpiry(deleteThisSku);
        if (handleAuthError === true) {
          errorAlert('', 'Your Session has expired');
          logout();
          return;
        }
        if(response?.result){
        //   getSellerPlan()
        successAlert('', 'Variant Deleted');
        }
        ////console.log(response);
      } else {
        ////console.log("No matching SKU found in currenSkus");
      }
    }

    const updatedForms = forms.filter((_, index) => index !== formIndex);


    setForms(updatedForms);
    window.scrollTo(0, 0);
  };
  // const classes = useStyles();


  return (
    <div>
      <ToastComponent ref={toastRef} timeout={4000} />

      <form>
      <div  onClick={() => setTab('basic')} style={{cursor:'pointer', display:'flex', alignItems:'center'}}>
              <CaretLeft /> Back to basic details</div>
                      {forms?.map((form, formIndex) => (

          <div key={formIndex} className="add-product-by-seller-form">
            <div className="seller-product-sku-delete-icon-container"><DeleteOutline onClick={() => { deleteSku(formIndex) }} className="seller-product-sku-delete-icon" style={{ width: "2rem", height: "2rem" }} /></div>
            <div className="add-product-by-seller-field">
              <div className="add-product-by-seller-field-name">Color</div>
              <div className="add-product-by-seller-field-textfield">
                <TextField
                  fullWidth
                  type="text"
                  name="color"
                  placeholder="Color"
                  value={form.color}
                  onChange={(e) => {
                    handleChange(e, formIndex);
                    // formik.setFieldValue("color", e.target.value);
                  }}
                  error={!/^[a-zA-Z,& ]{0,100}$/.test(form.color)}
                />
              </div>
            </div>
            <div className="add-product-by-seller-field">
              <div className="add-product-by-seller-field-name">Material</div>
              <div className="add-product-by-seller-field-textfield">
                <TextField
                  fullWidth
                  type="text"
                  name="fabric"
                  placeholder="Fabric"
                  value={form.fabric}
                  onChange={(e) => {
                    handleChange(e, formIndex);
                    // formik.setFieldValue("fabric", e.target.value);
                  }}

                  error={!/^[a-zA-Z,& ]{0,100}$/.test(form.fabric)}
                />
              </div>
            </div>
            <div className="add-product-by-seller-field-single-line-inputs">
              <div className="add-product-by-seller-field-single-line-input-field">
                <div className="add-product-by-seller-field-name">Size</div>
                <div className="add-product-by-seller-field-single-line-input-field-textfield">
                  <TextField
                    // fullWidth
                    outlined
                    type="text"
                    name="size"
                    value={form.size}
                    onChange={(e) => handleChange(e, formIndex)}
                  />
                </div>
              </div>
              <div className="add-product-by-seller-field-single-line-input-field">
                <div className="add-product-by-seller-field-name">Price  (₹) *</div>
                <div className="add-product-by-seller-field-single-line-input-field-textfield">
                  <TextField
                    // fullWidth
                    outlined
                    type="text"
                    name="price"
                    value={form.price}
                    onChange={(e) => {
                      handleChange(e, formIndex);
                      // formik.setFieldValue("price", e.target.value);
                    }}
                    error={!/^[1-9]\d*$/.test(form.price)}
                  // error={Boolean(priceError)}
                  />
                </div>
              </div>
              <div className="add-product-by-seller-field-single-line-input-field">
                <div className="add-product-by-seller-field-name">
                  Selling Price  (₹)*
                </div>
                <div className="add-product-by-seller-field-single-line-input-field-textfield">
                  <TextField
                    // fullWidth
                    outlined
                    type="text"
                    name="sellingPrice"
                    value={form.sellingPrice}
                    // error={Boolean(quantityError)}
                    onChange={(e) => {
                      handleChange(e, formIndex);
                      // formik.setFieldValue("sellingPrice", e.target.value);
                    }}
                    error={!/^[1-9]\d*$/.test(form.sellingPrice)}
                  />
                </div>
              </div>
              <div className="add-product-by-seller-field-single-line-input-field">
                <div className="add-product-by-seller-field-name">Quantity*</div>
                <div className="add-product-by-seller-field-single-line-input-field-textfield">
                  <TextField
                    // fullWidth
                    outlined
                    type="text"
                    name="quantity"
                    value={form.quantity}
                    // error={Boolean(quantityError)}
                    onChange={(e) => {
                      handleChange(e, formIndex);
                      // formik.setFieldValue("quantity", e.target.value);
                    }}
                    error={!/^[1-9]\d*$/.test(form.quantity)}
                  />
                </div>
              </div>
            </div>

            <div className="add-product-by-seller-field-single-line-inputs">
              <div className="add-product-by-seller-field-single-line-input-field">
                <div className="add-product-by-seller-field-name">Discount</div>
                <div className="add-product-by-seller-field-single-line-input-field-textfield">
                  <TextField
                    // fullWidth
                    outlined
                    type="text"
                    name="discount"
                    value={Math.floor(
                      ((form.price - form.sellingPrice) / form.price) * 100
                    )}
                    onChange={(e) => handleChange(e, formIndex)}
                    disabled
                  />
                </div>
              </div>

              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">SKU</div>
                <div className="add-product-by-seller-field-textfield">
                  <TextField
                    fullWidth
                    outlined
                    type="text"
                    name="sku"
                    value={form.sku}
                    onChange={(e) => handleChange(e, formIndex)}
                    // error={Boolean(quantityError)}
                    disabled
                  />
                </div>
              </div>
            </div>

            {formIndex > 0 && formIndex >= currenSkus?.length && (
              <div className="add-product-by-seller-field-add-img-checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={prevImages[formIndex]}
                    onChange={(event) => handlePrevImagesChange(event, formIndex)}
                    style={{ width: "1.4rem", height: "1.4rem" }}
                  />
                  Import images from the previous variant
                </label>
              </div>
            )}
            {formIndex === 0 && (
              <div className="add-product-image-field">
                <div className="add-product-by-seller-image-field">
                  <Dropzone
                    className="seller-drag-image-field"
                    onDrop={(acceptedFiles) =>
                      handleImageDrop(acceptedFiles, formIndex)
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input
                          className="seller-drag-image-field"
                          {...getInputProps({
                            accept: "image/png, image/jpeg, image/jpg",
                          })}
                        />
                        <p className="add-product-image-add-text">
                          <img loading="lazy" src={ImageUploderImage} alt=""  />
                          <p>Drop your images here or click to Browse</p>
                          {!uploading && <button
                            type="button"
                            className="common-btn"
                            style={{ position: "relative", top: "50%" }}
                          >
                            Upload Images
                          </button>}

                          {uploading ? (
                            <CircularProgress animation="border" role="status" style={{ color: 'pink' }}>
                              <span className="sr-only">Loading...</span>
                            </CircularProgress>
                          ) : (
                            <p></p>
                          )}
                        </p>
                      </div>
                    )}
                  </Dropzone>
                  <ul className="seller-products-mediaList">
                    {!prevImages[formIndex] &&
                      form?.imageList?.map((image, index) => (
                        <li key={index}>
                          <img
                            src={form?.imagesUriList?.[index]?.thumbnailURI}
                            alt="thumb"
                            onClick={() => {
                              window.open(form?.imagesUriList?.[index]?.uri);
                            }}
                          // style={{ maxWidth: "100px" }}
                          />
                          <button
                            className="cancel-button"
                            type="button"
                            onClick={() => handleImageCancel(index, formIndex)}
                          >
                            Cancel
                          </button>
                        </li>
                      ))}
                    {/* {!prevImages[formIndex] &&
    thumbnailUrls?.[formIndex]?.slice(form?.imagesUriList?.length)?.map((thumbnail, index) => (
      <li key={index + form?.imagesUriList?.length}>
        <img
          src={thumbnail}
          alt="thumb"
          style={{ maxWidth: "100px" }}
        />
        <button type="button" onClick={() => handleImageCancel(index + form?.imagesUriList?.length, formIndex)}>
          Cancel
        </button>
      </li>
    ))} */}
                  </ul>
                </div>
                <div className="add-product-by-seller-common-img-rules">
                  <div>
                    NOTE: You have to add one image at the least so that your product can be displayed.
                    You can add up to  10 images.
                    Image size cannot exceed 5MB.
                  </div>
                </div>
              </div>
            )}
            {formIndex !== 0 && (
              <div className="add-product-image-field">
                <div className="add-product-by-seller-image-field">
                  {!prevImages?.[formIndex] && (
                    <Dropzone
                      className="seller-drag-image-field"
                      onDrop={(acceptedFiles) =>
                        handleImageDrop(acceptedFiles, formIndex)
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input
                            className="seller-drag-image-field"
                            {...getInputProps({
                              accept: "image/png, image/jpeg, image/jpg",
                            })}
                          />
                          <p className="add-product-image-add-text">
                            <img loading="lazy" src={ImageUploderImage} alt=""  />
                            <p>Drop your images here or click to Browse</p>
                            {!uploading && <button
                              type="button"
                              className="common-btn"
                              style={{ position: "relative", top: "50%" }}
                            >
                              Upload Images
                            </button>}

                            {uploading ? (
                              <CircularProgress animation="border" role="status" style={{ color: 'pink' }}>
                                <span className="sr-only">Loading...</span>
                              </CircularProgress>
                            ) : (
                              <p></p>
                            )}
                          </p>
                        </div>
                      )}
                    </Dropzone>
                  )}
                  <ul className="seller-products-mediaList">
                    {form?.imageList?.map((image, index) => (
                      <li key={index}>
                        <img
                          src={form?.imagesUriList?.[index]?.thumbnailURI}
                          alt="thumb"
                          onClick={() => {
                            window.open(form?.imagesUriList?.[index]?.uri);
                          }}
                        // style={{ maxWidth: "100px" }}
                        />
                        <button
                          className="cancel-button"
                          type="button"
                          onClick={() => handleImageCancel(index, formIndex)}
                        >
                          Cancel
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="add-product-by-seller-common-img-rules">
                  <div>
                    NOTE: You have to add one image at the least so that your product can be displayed.
                    You can add up to  10 images.
                    Image size cannot exceed 5MB.
                  </div>
                </div>
              </div>
            )}

            {formIndex > 0 && videoAllowed && (
              <div className="add-product-by-seller-field-add-img-checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={prevVideos[formIndex]}
                    onChange={(event) => handlePrevVideoChange(event, formIndex)}
                    style={{ width: "1.4rem", height: "1.4rem" }}
                    onClick={() => {
                      window.open(sellerCertUri);
                    }}
                  />
                  Import videos from the previous variant
                </label>
              </div>
            )}
           
            {formIndex === 0 && videoAllowed && (
              <div className="add-product-image-field">
                <div className="add-product-by-seller-image-field">
                  <Dropzone
                    className="seller-drag-image-field"
                    onDrop={(acceptedFiles) => handleVideoDrop(acceptedFiles, formIndex)}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input
                          className="seller-drag-image-field"
                          {...getInputProps({
                            accept: "video/mp4, video/quicktime, video/3gpp",
                          })}
                          onClick={() => {
                            window.open(sellerCertUri);
                          }}
                        />
                        <p className="add-product-image-add-text">
                          <img loading="lazy" src={ImageUploderImage} alt="" />
                          <p>Drop your videos here or click to Browse</p>
                          {!videoUploading && <button
                            type="button"
                            className="common-btn"
                            style={{ position: "relative", top: "50%" }}
                          >
                            Upload Videos
                          </button>}

                          {videoUploading ? (
                            <CircularProgress animation="border" role="status" style={{ color: 'pink' }}>
                              <span className="sr-only">Loading...</span>
                            </CircularProgress>
                          ) : (
                            <p></p>
                          )}
                        </p>
                      </div>
                    )}
                  </Dropzone>
                  <ul className="seller-products-mediaList">
                    {!prevVideos[formIndex] &&
                      form?.videoList?.map((video, index) => (
                        <li key={index}>
                          <video controls style={{ maxWidth: "100px" }}>
                            <source src={form?.videosUriList?.[index]?.thumbnailURI} type="video/mp4" />
                          </video>
                          <button
                            className="cancel-button"
                            type="button"
                            onClick={() => handleVideoCancel(index, formIndex)}
                          >
                            Cancel
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="add-product-by-seller-common-img-rules">
                  <div>
                    NOTE: You have to add one video at least so that your product can be displayed.
                    You can add only one video.
                    Video size cannot exceed 40MB.
                  </div>
                </div>
              </div>
            )}

            {formIndex !== 0 && videoAllowed && (
              <div className="add-product-image-field">
                <div className="add-product-by-seller-image-field">
                  {!prevVideos?.[formIndex] && (
                    <Dropzone
                      className="seller-drag-image-field"
                      onDrop={(acceptedFiles) =>
                        handleVideoDrop(acceptedFiles, formIndex)
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input
                            className="seller-drag-image-field"
                            {...getInputProps({
                              accept: "video/mp4, video/quicktime, video/3gpp",
                            })}
                          />
                          <p className="add-product-image-add-text">
                            <img loading="lazy" src={ImageUploderImage} alt="" />
                            <p>Drop your videos here or click to Browse</p>
                            {!videoUploading && <button
                              type="button"
                              className="common-btn"
                              style={{ position: "relative", top: "50%" }}
                            >
                              Upload Videos
                            </button>}

                            {videoUploading ? (
                              <CircularProgress animation="border" role="status" style={{ color: 'pink' }}>
                                <span className="sr-only">Loading...</span>
                              </CircularProgress>
                            ) : (
                              <p></p>
                            )}
                          </p>
                        </div>
                      )}
                    </Dropzone>
                  )}
                  <ul className="seller-products-mediaList">
                    {form?.videoList?.map((video, index) => (
                      <li key={index}>
                        <video controls style={{ maxWidth: "100px" }}>
                          <source
                            src={form?.videosUriList?.[index]?.thumbnailURI}
                            type="video/mp4"
                          />
                        </video>
                        <button
                          className="cancel-button"
                          type="button"
                          onClick={() => handleVideoCancel(index, formIndex)}
                        >
                          Cancel
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="add-product-by-seller-common-img-rules">
                  <div>
                    NOTE: You have to add one video at least so that your product can be displayed.
                    You can add only one video.
                    Video size cannot exceed 40MB.
                  </div>
                </div>
              </div>
            )}

            <p className="add-product-by-seller-field">
              You can add multiple values and properties
            </p>
            {form?.productAttributeList?.map((field, fieldIndex) => (
              <div key={fieldIndex}>
                <div className="add-product-key-value-field">
                <div className="add-product-by-seller-field-textfield">
                    {fieldIndex>3&&<TextField
                      fullWidth
                      type="text"
                      name="a_Key"
                      // onFocus={(event)=>fieldIndex<4&& event.target.blur()}
                      placeholder={
                        field.a_Key === "length" ? 'Length in Centimeters ' :
                          field.a_Key === "width" ? 'Width in Centimeters' :
                            field.a_Key === "height" ? 'Height in Centimeters' :
                              field.a_Key === "weight" ? 'Weight in KiloGrams' : field.a_Key
                      }
                      // onFocus={document.activeElement.blur()}
                      // disabled={field.a_Key === "length" || field.a_Key === "width" || field.a_Key === "height" || field.a_Key === "weight"}
                      value={fieldIndex < 4 ? null : field.a_Key}
                      onChange={(e) => {
                        if (!(field.a_Key === "length" || field.a_Key === "width" || field.a_Key === "height" || field.a_Key === "weight")) {
                          handleChange(e, formIndex, fieldIndex);
                        } else {
                          e.target.value = '';

                        }
                      }}
                    />}
                     {fieldIndex<=3&&<div style={{ width:'220px',  padding:'12px', border:'1px solid #aaa'}}
                  >{
                    field.a_Key === "length" ? 'Length in Centimeters ' :
                      field.a_Key === "width" ? 'Width in Centimeters' :
                        field.a_Key === "height" ? 'Height in Centimeters' :
                          field.a_Key === "weight" ? 'Weight in KiloGrams' : field.a_Key
                  }</div>}
                  </div>
                  
                  <div className="add-product-by-seller-field-textfield">
                    <TextField
                      fullWidth
                      type={`${fieldIndex > 3 ? 'text' : 'number'}`}
                      name="a_Value"
                      placeholder="Value"
                      value={field.a_Value}
                      inputProps={{
                        min: 0,
                        onWheel: (e) => e.target.blur(),
                        onInput: (event) => {
                          if (parseFloat(event.target.value) < 0) {
                            event.target.value = 0;
                          }
                          if(event.target.value.startsWith('.')){
                            event.target.value = `0${event.target.value}`
                          }
                        },
                      }}
                      onChange={(e) => handleChange(e, formIndex, fieldIndex)}
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className="add-product-by-seller-field">
              <button
                onClick={() => handleAddField(formIndex)}
                type="button"
                className="common-button"
              >
                Add new field
              </button>
            </div>


          </div>
        ))}
        <div className="products-page-button-container">
          <button className="common-btn" type="button" onClick={addNewForm}>
            Add new Variant
          </button>
          {!loading && <button
            type="button"
            className="common-btn"
            onClick={() => {
              let hasError = false;
              let errorIndex = -1;
              const errorReasons = [];

              for (let i = 0; i < forms?.length; i++) {
                const form = forms[i];

                if (!/^[a-zA-Z,& ]{0,100}$/.test(form?.color)) {
                  errorReasons.push(`Invalid color. 
                
                `);
                }

                if (!/^[a-zA-Z,& ]{0,100}$/.test(form?.fabric)) {
                  errorReasons.push(`Invalid Material .
                
                `);
                }

                if (!/^[1-9]\d*$/.test(form?.price)) {
                  errorReasons.push(`Invalid price .
                `);
                }

                if (!/^[1-9]\d*$/.test(form?.sellingPrice)) {
                  errorReasons.push(`Invalid selling price
                `);
                }

                if (parseInt(form?.sellingPrice) > parseInt(form?.price)) {
                  errorReasons.push(
                    `Selling price should be lesser than price 
                  `
                  );
                }

                if (!/^[1-9]\d*$/.test(form?.quantity)) {
                  errorReasons.push(`Invalid quantity  
                `);
                }

                if (!form?.imageList?.length > 0) {
                  errorReasons.push(
                    `At least one image is required 
                  `
                  );
                }

                if (!(form?.productAttributeList[0].a_Value)) {
                  errorReasons.push(
                    `Integer value is required for Length property 
                  `
                  );
                }
                if (!(form?.productAttributeList[1].a_Value)) {
                  errorReasons.push(
                    `Integer value is required for Width property\
                  `
                  );
                }
                if (!(form?.productAttributeList[2].a_Value)) {
                  errorReasons.push(
                    `Integer value is required for Height property
                  `
                  );
                }
                if (!(form?.productAttributeList[3].a_Value)) {
                  errorReasons.push(
                    `Integer value is required for Weight property 
                  `
                  );
                }

                if (errorReasons.length > 0) {
                  hasError = true;
                  errorIndex = i;
                  break; // Terminate the loop when an error is found
                }
              }

              if (hasError) {
                const errorMessage = `Form at Position ${errorIndex + 1} has the following errors:\n${errorReasons.map(reason => `• ${reason}`).join('\n')}`;

                errorAlert('', errorMessage, '', '', 10000, true);
              } else {
                SubmitProductDetails();
              }
            }}
          >
            Submit Product
          </button>}
          {loading && <CircularProgress style={{ color: "pink" }} />}
        </div>
      </form>
    </div>
  );
}
