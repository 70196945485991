import React from 'react'
import  { useState } from 'react'
import { useFormik } from 'formik'
import { API_BASE_URL } from '../App'
import * as yup from "yup";
import "../styles/ManageProfile.css"
import "../styles/CustomerProfilePage.css"
// import AdminLayoutAdjustment from "../components/AdminLayoutAdjustment";
import { useContext } from 'react';
import { errorAlert, successAlert } from '../components/Alert';
// import { CaretRight } from '@phosphor-icons/react'
import {  PhoneCall ,Envelope ,WhatsappLogo} from '@phosphor-icons/react'
import Divider from '@mui/material/Divider'; 
import { CustomerContext } from '../context/CustomerContext';
import CustomerContactEmail from '../pages/CustomerContactEmail';

function BuyersHelp() {
    const [userData, setUserData] = useState({
        // "userID": "123456789",
        // "email": "anand@gmail.com",
        // "salt": "string",
        // // "password": "string",
        // "mobileNumber": "9110685998",
        // "firstName": "Sachidananda",
        // "lastName": "MG",
        // "typeOfUser": "BYR",
        // "dateOfBirth": "2023-04-10T10:21:03.726Z"
    })

    // const [edit, setEdit] = useState(false);
    const { accessToken } = useContext(CustomerContext);

    const phoneRegExp = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;

    const [loading,setLoading] = useState(false)
    const validationSchema = yup.object({
        email: yup
        .string("Enter your email")
        .email("Enter a valid email")
        // .trim()
        .required("Email is required"),
        mobileNumber: yup
        .string().trim()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Phone number is required*")
        .min(10, "Phone number must consist of 10 digits")
        .max(10, "Phone number must consist of 10 digits"),
      firstName: yup
        .string("Enter Your First Name")
        .required("First Name is required"),
      lastName: yup
        .string("Enter Your last Name")
        .required("Last Name is required"),
        });

    const formik = useFormik({
        initialValues: {
            email: userData?.email,
            mobileNumber: userData?.mobileNumber,
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            typeOfUser: userData?.typeOfUser,
            dateOfBirth: userData?.dateOfBirth
        },
        validationSchema: validationSchema,
        onSubmit: async () => {
            //console.log(('submitted'))
            await postEditData();
            // setEdit(false);
        },
    });

    async function postEditData(){
        setLoading(true);
        const response = await fetch(`${API_BASE_URL}/users/api/v1/update` , {
            method:"POST",
            headers:{
                Authorization: `Bearer ${accessToken}`
            },
            body:JSON.stringify({
                email: formik.values.email,
                mobileNumber: formik.values.mobileNumber,
                firstName: formik.values.firstName,
                lastName: formik.values.lastName,
                typeOfUser: formik.values.typeOfUser,
                dateOfBirth: formik.values.dateOfBirth
            })

        }

        )
        const data = await response.json();
        setLoading(false);
        // //console.log(data);
        if(data.Result===true){
            successAlert('', 'Data Updated Successfully');
            // setLoading(false);
        }
        else{
            errorAlert('', 'Something went wrong Please try again' )
            // setLoading(false);
        }
    }
  return (
    <>
    {/* <CustomerNavbar /> */}
    <div className='help-container'>
    <div className='profile-page-head'>Contact Us</div>
    <div className='contact-methods'>
      <div className='contact-methods-field'>
          <div className='contact-methods-field-set'>
           <div> Contact us through Phone Number</div>
           <div style={{color: "rgba(119, 119, 119, 1)",fontSize:"14px", letterSpacing:"0.02em"}}>Mon - Sat, 9 am to 7 pm</div>
            </div>
          <div className='phone-container'><div className='logo-icon-field'> <PhoneCall style={{ width:'24px', height:'24px'}}/></div><div  className='logo-name-field'>1800 203 1217</div></div>
      </div>
      <Divider />
      <div className='contact-methods-field'>
      <div className='contact-methods-field-set'>
           <div> Contact us through Email</div>
          <div style={{color: "rgba(119, 119, 119, 1)",fontSize:"14px", letterSpacing:"0.02em"}}>Average Response Time - 24hrs</div> </div>
          <div className='phone-container'> 
          <div className='logo-icon-field'><Envelope style={{ width:'24px', height:'24px'}}/></div>
          <div  className='logo-name-field'>hello@myhraki.com</div>
          </div>
      </div>
      <Divider />
      <div className='contact-methods-field'>
      <div className='contact-methods-field-set'>
            <div>Contact us through Whatsapp </div>
          <div style={{color: "rgba(119, 119, 119, 1)",fontSize:"14px", letterSpacing:"0.02em"}}>Average Response Time - 24hrs</div> 
          </div>
          
          <div className='phone-container-whatsapp' > 
          <div className='logo-icon-field'><WhatsappLogo style={{ width:'24px', height:'24px'}}/></div>
         <div className='logo-name-field'>Whatsapp</div> 
          </div>
      </div>     
       <Divider />

    </div>

    <div className='contact-seperator'>Or </div>
    {/* <div className='contact-deatils'>
    <form className="contact-details-form" onSubmit={formik.handleSubmit}  >
<div className="contact-details-field">
  <div className="contact-field-name"> Name </div>
  <div className='contact-us-name-field'>
  <TextField
    autoComplete="off"
    variant="outlined"
       type="text" 
       placeholder="First Name"
    name="First name"
    className="contact-first-name"
    value={formik.values.firstName}
    onChange={formik.handleChange}
    error={
      formik.touched.firstName &&
      Boolean(formik.errors.firstName)
    }
    helperText={
      formik.touched.firstName && formik.errors.firstName
    }
  />
 
  </div>
  </div>
<div className="contact-details-field">
  <div className="contact-field-name">Email </div>
  <div className="manage-form-field-textfield">
  <TextField
    autoComplete="off"
    variant="outlined"
    //   fullWidth
    type="email"
    name="email"
    className="email-field"
    placeholder="Enter email "
    value={formik.values.email}
    onChange={formik.handleChange}
    error={
      formik.touched.email && Boolean(formik.errors.email)
    }
    helperText={formik.touched.email && formik.errors.email}
  />
  </div>
</div>
<div className="contact-details-field">
  <div className="contact-field-name">
    {" "}
    Query
  </div>
  <div className="manage-form-field-textarea">
  <textarea
    multiLine
    autoComplete="off"
    variant="outlined"
      fullWidth
      // type="text"
    name="instructions"
    className="contact-textarea"
    placeholder="Type your query here ...... "
    value={formik.values.instructions}
    onChange={formik.handleChange}
    error={
      formik.touched.instructions &&
      Boolean(formik.errors.instructions)
    }
    helperText={
      formik.touched.instructions &&
      formik.errors.instructions
    }
  />
  </div>
</div>
<div className="contact-button-field">
<input
  type="submit"
  value="Submit"
  className="byr-submit-btn "
  onClick={formik.handleSubmit}
/>
</div>
</form>
    </div> */}
    <CustomerContactEmail />
       </div>
       </>
  )
}

export default BuyersHelp