import React from "react";
import "../styles/AdminForgetPassword.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { CircularProgress, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { API_BASE_URL } from '../App'

import sellerLogo from "../Images/logo.png";
import "../styles/Common.css";
// import { Snackbar } from '@mui/material';
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
// import { AdminContext } from "../context/AdminContext";
// import adminleftlogo from "../Images/adminleft.png"
// import buyerForgotImg from "../Images/buyerForgotImg.png"
// import CallIcon from "@mui/icons-material/Call";
import { errorAlert, successAlert } from "../components/Alert";
import { useState } from "react";
import { Link } from 'react-router-dom';
import BuyerCard from "../components/BuyerCard";
import "../styles/BuyerForgot.css"
import { CustomerContext } from "../context/CustomerContext";
const phoneRegExp = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;

const validationSchema = yup.object({
  number: yup
    .string("Enter your Phone Number")
    .trim()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone Number is required"),
});

export default function BuyerForgetPassword() {
  const navigate = useNavigate();
  // const [token, setToken]=useState(true);
  const [loading, setLoading] = useState(false);
  const { setPhoneNumberForOtp } = useContext(CustomerContext);
  // const [loginInProgress, setLoginInProgress] = useState(false)
  const gotoLoginPage = () => {
    navigate("/login");
  };

  const formik = useFormik({
    initialValues: {
      number: "",
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      setLoading(true)
      const result = await postForOtp();
      //console.log(result);
      if (result.ok) {
        //notify('An OTP has been sent to your Number. Please check.' );
        successAlert('', 'OTP sent successfully')
        setLoading(false);
        setPhoneNumberForOtp(formik.values.number.trim());
        navigate("/buyerForgetPasswordOtpVerify");
      } else {
        errorAlert('', 'Error sending OTP')
        setLoading(false);

        //notify("Please check your number before requesting OTP.");
      }
    },
  });
  async function postForOtp() {
    setLoading(true)
    try {
      let result = await fetch(
        `${API_BASE_URL}/users/api/v1/otp?mobileNumber=${formik.values.number.trim()}&userType=${1}`,
        {
          headers: {
            // Accept: "application.json",
            // "Content-type": "application.json",
          },
        }
      );
      ////console.log(result)
      setLoading(false)
      //console.log(result);

      return result;
    } catch (e) {
        setLoading(false)

      //console.log(e);
    }
  }

  // const //notify = () =>
  // toast("An Email has been sent to your Email ID. Please check.");
  return (
    <BuyerCard
    // cardImg={<img loading="lazy" className="BuyerForgotImg" src={buyerForgotImg} alt="" />}
    // imageFirst={true}
  >
      <div className="buyer-login-right">
        <div className="seller-reset-logo">
          <img loading="lazy" alt=" " src={sellerLogo} className="Buyer-logo" />
        </div>

        <div className="buyer-login-head">
        Forgot Password
          </div>
        <div className="buyers-subtext">Enter Phone Number</div>
        {/* <div className="buyer-otp-verify-note">Enter your Password. </div>      */}
        <form className="buyer-reset-page-form" onSubmit={formik.handleSubmit}>
          <div className="buyer-reset-page-email-field">
            <TextField
              variant="outlined"
              fullWidth
              // autoFocus
              type="text"
              name="number"
              //   className="login-page-email"
              className="byr-forgot-password-phone"
              // placeholder="Enter phone number"
              value={formik.values.number.trim()}
              onChange={formik.handleChange}
              error={formik.touched.number && Boolean(formik.errors.number)}
              helperText={formik.touched.number && formik.errors.number}
            />
            {/* <CallIcon
              className="byr-forgot-phone-icon"
              fontSize="medium "
            /> */}
          </div>
          {!loading && <input type="submit" className="byr-common-button" value="Send OTP" />}
          {loading &&
            <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "pink" }} /></div>}

        </form>
        <div className="buyer-reset-login">
          <span onClick={gotoLoginPage}>Return to Login</span>
        </div>
        {/* <div className="seller-reset-signUp">
          Don't have an account? <span onClick={gotoSignupPage}>Sign Up</span>
        </div> */}
      <div className="byr-terms-condition ">
            By continuing, you agree to MyhraKi's
            <Link to="/termsAndCondition" className='register-privacy-link'>Terms and Conditions</Link> and  <Link to="/privacyPolicy" className='register-privacy-link'>Privacy Policy</Link>
          </div>

      </div>
   
    </BuyerCard>
  );
}
