import React, { useEffect, useState } from "react";
import "../styles/Footer.css";
// import LockOpenIcon from "@mui/icons-material/LockOpen";
// import { TextField } from "@mui/material";
// import { useFormik } from "formik";
// import * as yup from "yup";
// import logo from "../logo.png";
import mandala from "../Images/Mandala.png";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import { minHeight } from "@mui/system";
// footer props=
// cls name =prop.backgroundColor

export default function AdminFooter() {
  const [isLogo, setIsLogo] = useState(true);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 1024) {
        setIsLogo(false);
      } else {
        setIsLogo(true);
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div
      className="footer"
      // style={{ backgroundColor: "rgba(144, 222, 224, 0.56)" }}
    >
      <div
        style={{
          backgroundColor: "white",
          minHeight: "10rem",
          borderBottom: "1px solid black",
        }}
      ></div>
      <div
        className="footer-bottom"
        style={{ position: "relative", zIndex: "100" }}
      >
        {isLogo && (
          <img
            src={mandala}
            alt="mandala"
            style={{
              width: "15rem",
              height: "15rem",
              position: "absolute",
              top: "-17rem",
              left: "45%",
              zIndex: "100",
              // marginTop:"200px"
            }}
          />
        )}
        <div className="footer-logo-name">Myhraki</div>
        <div className="footer-conditions">
          <div className="footer-privacy">Privacy policy</div>
          <div className="footer-privacy">
            2022, MyhraKi.com, All Rights Reserved
          </div>
          <div className="footer-privacy">Terms and Conditions</div>
        </div>
      </div>
    </div>
  );
}
