import React from 'react'
import "../styles/Blog.css"
import { useNavigate } from "react-router-dom";


function BlogCardComponent({ title, description, date ,img ,id}) {
  const navigate = useNavigate();

  return (
    
    <div className='bloag-card-container' onClick={()=>{navigate(`/DetailedBlogPage/${id}`);  window.scrollTo(0, 0);}}>
        <img loading="lazy" src={img} alt="" className='blog-card-img'/>
        <div className='blog-card-Title'>{title}</div>
        <div className='blog-card-description'>{description}</div>
      <p style={{color:"rgba(0, 0, 0, 0.53)",fontSize:"12px"}}>{date}</p>
      </div>

      
  )
}

export default BlogCardComponent