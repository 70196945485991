import "../styles/Blog.css";
import React, { useState, useEffect } from "react";
import BlogBanner from "../Images/BlogBanner.png";
import { useNavigate, useParams } from "react-router-dom";
import "../styles/Blog.css";
import CustomerLayout from "../components/CustomerLayout";
import Stack from "@mui/material/Stack";
import BlogCardComponent from "../components/BlogCardComponent";
import { API_BASE_URL } from "../App";
import { Pagination } from "@mui/material";
import { Box, Skeleton } from "@mui/material";

function Blog() {
  const navigate = useNavigate();
  const params = useParams();
  const [activeButton, setActiveButton] = useState("Indian Handicrafts");
  const [blogData, setBlogData] = useState([]);
  const [filteredArtData, setFilteredArtData] = useState([]);
  const [filteredArtisanData, setFilteredArtisanData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [activeData, setActiveData] = useState([]);
  const limit = 12;

  async function fetchBlogData() {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/facts/api/v1/GetAllCFacts?PageNumber=1&PageSize=100`,
        {
          method: "GET",
          headers: {},
        }
      );
      const data = await response.json();
      const activeData = data?.result?.filter(
        (blog) => blog?.status === "Active"
      );
      console.log(activeData);

      const artData = activeData.filter((item) => item.category === "Art");

      setFilteredArtData(artData);

      const artisanData = activeData.filter(
        (item) => item.category === "Artisan"
      );
      setFilteredArtisanData(artisanData);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching blog data:", error);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchBlogData();
  }, []);

  const handleCategoryChange = (category) => {
    setActiveButton(category);
    setPage(1);
    const categoryString1 = category === "Art";
    const categoryString2 = category === "Artisan"
    const filteredData1 = activeData.filter((item) => item.category === categoryString1);
    const filteredData2 = activeData.filter((item) => item.category === categoryString2);
    if (category === "Art") {
      setFilteredArtData(filteredData1);
    }
    else if (category === "Artisan") {
      setFilteredArtisanData(filteredData2); 
    }
    else{
      console.log("Not the specified category");
    }
  };
  
  
  const handleCardClick = (uniqueId) => {
    navigate(`/DetailedBlogPage/${uniqueId}`);
  };

  const handleBlogChange = (event, value) => {
    setPage(value);
    window.scrollTo(0,0);
  };
  console.log(filteredArtData);
  console.log("Filtered Blog Data Length:", filteredArtData.length);
  const startIndex = (page - 1) * limit;
const endIndex = page * limit;
const paginatedData = filteredArtData.slice(startIndex, endIndex);

  return (
    <div>
      <CustomerLayout>
        <div className="Blog-container">
          <img
            loading="lazy"
            src={BlogBanner}
            alt=""
            className="blog-banner-img"
          />
          <div className="Category-buttons-blog-field">
            <button
              className={`Category-buttons-blog ${
                activeButton === "Indian Handicrafts" ? "active" : ""
              }`}
              onClick={() => handleCategoryChange("Indian Handicrafts")}
            >
              Indian Handicrafts
            </button>
            <button
              className={`Category-buttons-blog-plain ${
                activeButton === "Indian Artisans" ? "active" : ""
              }`}
              onClick={() => handleCategoryChange("Indian Artisans")}
            >
              Indian Artisans
            </button>
          </div>
          <div className="blog-facts-container">
            {loading ? (
              <div style={{ maxWidth: "1280px", margin: "2rem auto" }}>
                <Skeleton
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", lg: "row", gap: "2rem" },
                  }}
                  variant="rectangular"
                  width="75%"
                  height={500}
                  minWidth={300}
                />
              </div>
            ) : activeButton === "Indian Handicrafts" ? (
              filteredArtData?.slice((page - 1) * limit, page * limit).map((blog) => (
                  <BlogCardComponent
                    key={blog.id}
                    img={blog.imageUrl2}
                    title={blog.blogName}
                    description={blog.blogArticle}
                    date={blog.date}
                    id={blog.factId}
                  />
                ))
            ) : (
              filteredArtisanData
                ?.slice((page - 1) * limit, page * limit)
                .map((blog) => (
                  <BlogCardComponent
                    key={blog.id}
                    img={blog.imageUrl1}
                    title={blog.blogName}
                    description={blog.blogArticle}
                    date={blog.date}
                    id={blog.factId}
                  />
                ))
            )}
          </div>
          {activeButton === "Indian Handicrafts" &&
            filteredArtData.length > limit && (
              <div>
                <Stack
                  spacing={2}
                  className="blog-pagination"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "1rem auto",
                    alignItems: "center",

                  }}
                >
                  <Pagination
                    count={Math.ceil(filteredArtData.length / limit)}
                    page={page}
                    variant="outlined"
                    onChange={handleBlogChange}
                    className="pagination-blog"
                    
                
                    
                  />
                </Stack>
              </div>
            )}
          {activeButton === "Indian Artisans" &&
            filteredArtisanData.length > limit && (
              <div>
                <Stack
                  spacing={2}
                  className="blog-pagination"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "1rem auto",
                    alignItems: "center",
                    
                  }}
                >
                  <Pagination
                    count={Math.ceil(filteredArtisanData.length / limit)}
                    page={page}
                    variant="outlined"
                    onChange={handleBlogChange}
                    className="pagination-blog"
                    
                  />
                </Stack>
              </div>
            )}
        </div>
      </CustomerLayout>
    </div>
  );
}

export default Blog;
