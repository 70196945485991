import React, { useContext } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom/dist';
import CustomerLayout from '../components/CustomerLayout';
import { Heart, Truck, ShareNetwork, Star, Copy } from '@phosphor-icons/react'
// import Star from "@mui/icons-material/Star";
// import SizeIcon from "../components/SizeIcon";
import Quantity from "../components/Quantity";
import { API_BASE_URL } from "../App";

import { CircularProgress, Skeleton, Stack, TextField } from '@mui/material';
import MakeInIndia from "../Images/MakeInIndia.png"
import HandMade from "../Images/hand-made.png"
import Vegan from "../Images/Vegan.png"
import "../styles/CustomerProductDetail.css"
// import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
// import Jutha2 from "../Images/Jutha2.png";
// import img1 from "../Images/TaleOfImages.png"
// import img2 from "../Images/TaleOfImages2.png"
// import img3 from "../Images/TaleOfImages3.png"
// import Wishlist from './Wishlist';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Divider } from '@mui/joy';
import SingleProductCustomerReviews from './SingleProductCustomerReviews';
// import CustomImageGallery from '../components/CustomImageGallery';
import CustomerImageLayout from '../components/CustomerImageLayout';
// import productData from '../ProductData';
// import video from "../Images/sampleVideo.mp4"
// import SkuCard from '../components/SkuCard';
import { addToWishlist, deleteFromWishlist } from '../utils/addToWishlist';
import { CustomerContext } from '../context/CustomerContext';
import { errorAlert, successAlert } from '../components/Alert';
import CustomerLoginModal from '../components/CustomerLoginModal';
import useMediaQuery from '@mui/material/useMediaQuery';
import ToastComponent from '../components/ToastComponent';
import { useRef } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CustomerHeading from '../components/CustomerHeading';
import '../styles/CustomerLandingPage.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderArrowLeft from "../Images/SliderArrowLeft.png"
import SliderArrowRight from "../Images/SliderArrowRight.png"
import CustomerProductCard from '../components/CustomerProductCard';
import AdminLayoutAdjustment from '../components/AdminLayoutAdjustment';
import { AdminContext } from '../context/AdminContext';
// import MyhrakiTextfield from '../components/MyhrakiTextfield';
import { CaretLeft } from "@phosphor-icons/react";

// import Jutha2 from "../Images/Jutha2.png"
function AdminProductPreview({ productId, ...props }) {
  const params = useParams();
  const id = params.productId;
  const { customerData, accessToken, handleAuthExpiry, logout } = useContext(AdminContext);
  // const navigate = useNavigate();
  const [images, setImages] = useState();
  const [imageNames, setImageNames] = useState();
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const [productDataTest, setproductDataTest] = useState();
  const [loading, setLoading] = useState(false);
  // const [incLoading, setIncLoading] = useState(false);
  const [decLoading, setDecLoading] = useState(false);
  const [deliverLoading, setDeliverLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  // let [review, setReview] = useState(false);
  // const [forceRerender, setForceRerender] = useState(false);
  const toastRef = useRef();
  const [tempCartData, setTempCartData] = useState();
  const [cartData, setCartData] = useState([]);
  const [newLoading, setNewLoading] = useState(false);
  const[NewArrivalData ,setNewArrivalData]=useState([]);

  const navigate = useNavigate();




  const fetchproductDataTest = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${API_BASE_URL}/productgen/api/v1/get?productId=${id}`
      );
      if (response.ok) {
        const data = await response.json();
        setproductDataTest(data?.result[0]);
// fetchNewArrivalData(data?.result[0])
        //console.log('ProductData', data?.result[0])
        //console.log( ...data?.result[0]?.productSKUs[selectedProductIndex]?.imageList, ...data?.result[0]?.productSKUs[selectedProductIndex]?.videoList)
        // setImages(data.result[0]?.productSKUs[0]?.imagesUriList[0?.uri])
        const thumbnails2 = [...data?.result[0]?.productSKUs[selectedProductIndex]?.imagesUriList, ...data?.result[0]?.productSKUs[selectedProductIndex]?.videosUriList]
        const thumbnails2ImageNames = [...data?.result[0]?.productSKUs[selectedProductIndex]?.imageList, ...data?.result[0]?.productSKUs[selectedProductIndex]?.videoList]
        //console.log(thumbnails2, "Thumbnail2")
        //console.log(thumbnails2ImageNames, "Thumbnail2")
        const returnedImages = thumbnails2?.map((thumbnail) => {
          return thumbnail?.uri
        })

        const returnedImageNames = thumbnails2ImageNames?.map((image) => {
          return image
        })
        //console.log(returnedImages);
        //console.log(returnedImageNames);
        //console.log('Before setting images:', images);
        setImages(returnedImages);
        setImageNames(returnedImageNames);
        //console.log('After setting images:', images);
        //console.log('After setting images:', imageNames);
        setLoading(false);
        // fetchNewArrivalData();


      } else {
        setLoading(false);
        throw new Error("Failed to fetch product data");

      }
    } catch (error) {
      console.error("Error fetching product data:", error);
      setLoading(false);

    }
  };




  useEffect(() => {
    window.scrollTo(0, 0);
    fetchproductDataTest();
    // fetchReview();
  }, [])

  useEffect(() => {
    const fetchProductDataAndImages = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${API_BASE_URL}/productgen/api/v1/get?productId=${id}`
        );
        if (response.ok) {
          const data = await response.json();
          setproductDataTest(data.result[0]);

          const thumbnails2 = [
            ...data.result[0]?.productSKUs[selectedProductIndex]?.imagesUriList,
            ...data.result[0]?.productSKUs[selectedProductIndex]?.videosUriList
          ];

          const returnedImages = thumbnails2?.map((thumbnail) => thumbnail.uri);
          setImages(returnedImages);
          setLoading(false);
        } else {
          throw new Error("Failed to fetch product data");
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
        setLoading(false);
      }
    };

    fetchProductDataAndImages(); // Fetch product data and images when id changes
  }, [id]);


  // const [tab, setTab] = useState('Description');
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [pincode, setPincode] = useState();
  const [available, setAvailable] = useState();
  const [tabValue, setTabValue] = useState('Description');

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };





  const handleSkuChange = async (sku, index) => {
    setSelectedProductIndex(index);
    // await fetchproductDataTest();
  }

  useEffect(() => {
    // //console.log("Selected" , selectedProductIndex)
    if (productDataTest?.productSKUs[0]?.sku) {

      const thumb = [
        ...productDataTest?.productSKUs?.[selectedProductIndex]?.imagesUriList,
        ...productDataTest?.productSKUs?.[selectedProductIndex]?.videosUriList
      ];

      const resultImages = thumb?.map((thumbnail) => thumbnail?.uri);
      // //console.log(resultImages)
      setImages(resultImages);
    }
  }, [selectedProductIndex])
  const [loginModalOpen, setLoginModalOpen] = React.useState(false);

  const checkDeliverable = async () => {
    setDeliverLoading(true)
    try {


      const response = await fetch(`${API_BASE_URL}/logistic/api/v1/${pincode}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });
      const data = await response.json();
      setDeliverLoading(false)

      if (data?.result?.success === true) {
        setAvailable(true);
      } else {
        setAvailable(false);
      }
    } catch {
      setDeliverLoading(false)

      setAvailable();
    }
  }

  const [isFavorite, setIsFavorite] = useState(false);

 




  const copyToClipboard = () => {
    const currentURL = window.location.href;
    navigator.clipboard.writeText(currentURL)
    successAlert('',
      'Copied to clipboard',
    );
  };
  const customTabStyles = {
    color: '#FE3C72',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    textTransform: 'uppercase',
    padding: "0px 0px 0px",
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: '#FE3C72',
        fontWeight: 800,
      },
    },
  });
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <img loading="lazy" src={SliderArrowRight} alt="prevArrow" {...props} style={{ width: "30px", height: "30px" }} />
  );
  
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <img loading="lazy" src={SliderArrowLeft} alt="nextArrow" {...props} style={{ width: "30px", height: "30px" }} />
  );
  var settingsForProductCard = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
            }
        },
        {
            breakpoint: 390,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,

            }
        },

        {
            breakpoint: 1054,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
            }
        },
        {
            breakpoint: 1104,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
            }
        },


    ],
    nextArrow: <SlickArrowLeft />,
    prevArrow: <SlickArrowRight />
};





  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div>

      <AdminLayoutAdjustment>
        {/* <ToastComponent ref={toastRef} timeout={4000} /> */}
        {/* <CustomerLoginModal isOpen={loginModalOpen} onClose={() => {
          setLoginModalOpen(false);
        }} /> */}
        {/* <div onClick={()=>{navigate('/adminProductSearch')}}>Back</div> */}
        <div  onClick={() => {navigate('/adminProductSearch')}} style={{cursor:'pointer', display:'flex', alignItems:'center'}}>
              <CaretLeft /> Back to Search Page</div>
        {!productDataTest?.title ? (
          <div className='Product-details-container' style={{ marginTop: '2rem' }}>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'space-between' }, gap: '5rem', width: { xs: '90%' } }}>

              <Stack spacing={1}>
                {/* For variant="text", adjust the height via font-size */}
                <Box spacing={1} sx={{ display: 'flex', gap: '2rem' }}>
                  <Stack spacing={2} sx={{ display: { xs: 'none', md: 'block' } }}>

                    <Skeleton variant="rectangular" width={60} height={60} />
                    <Skeleton variant="rectangular" width={60} height={60} />
                    <Skeleton variant="rectangular" width={60} height={60} />
                    <Skeleton variant="rectangular" width={60} height={60} />
                  </Stack>
                  <Stack spacing={1}>
                    <Skeleton variant="rectangular" width={300} height={300} />
                  </Stack>
                </Box>
              </Stack>
              <Skeleton
                variant="rectangular"
                sx={{
                  width: {
                    xs: '90%', // default value for xs and sm
                    md: '700px', // value for md and larger
                  },
                  height: '700px',
                }}
              />
            </Box>
          </div>
        ) : (
          <div className='Product-details-container'>


            <div className="customer-image-gallery">
              {images?.length > 0 && <CustomerImageLayout key={images.join(',')} images={images} imageNames={imageNames} />}
            </div>


            <div className='Product-details-right' >
              <div className='Product-details-container-right'>
                <div className='Product-details-title-field'>
                  <div className='Product-details-title'> {productDataTest?.title}</div>
                  <div className='product-favourite-share-icons'>
                    {!isFavorite ? (
                      <Heart size={24} color="#000"
                        // onClick={handleHeartClick} 
                        // onClick={handleHeartClick} style={{ cursor: "pointer" }} 
                        />
                    ) : (
                      <Heart size={24} weight="fill" color="#FD2A80" style={{ cursor: "pointer" }} />
                    )}                <Copy style={{ width: "24", height: "24", cursor: "pointer" }} onClick={copyToClipboard} />
                  </div>
                </div>
                <div className='category-single-product'>{productDataTest?.category.split('/')[0]}</div>
                <div>
                  <div className="single-prices">
                    <div className="single-price">{`₹ ${productDataTest?.productSKUs?.[selectedProductIndex]?.sellingPrice ? parseFloat(productDataTest?.productSKUs?.[selectedProductIndex]?.sellingPrice) + 100 : 'N/A'}`}</div>
                    <div className="single-actual-price">{`₹ ${parseFloat(productDataTest?.productSKUs?.[selectedProductIndex]?.price) + 100}`}</div>
                    <div className="single-discount">
                      {
                        productDataTest?.productSKUs?.[selectedProductIndex]?.sellingPrice != null &&
                          productDataTest?.productSKUs?.[selectedProductIndex]?.price != null ? (
                          `${Math.floor((
                            (
                              (
                                parseFloat(productDataTest?.productSKUs?.[selectedProductIndex]?.price) + 100
                              ) - (
                                parseFloat(productDataTest?.productSKUs?.[selectedProductIndex]?.sellingPrice) + 100
                              )
                            ) / (parseFloat(productDataTest?.productSKUs?.[selectedProductIndex]?.price) + 100)
                          ) * 100)
                          }% OFF`
                        )
                          : 'N/A'
                      }
                    </div>
                    <div className='inclusive-tag-lines'>Inclusive of all taxes</div>

                  </div>
                </div>
                <div className='reviews-single-product'> <Star size={20} fill="green" className="green-star" weight='fill' />{productDataTest?.avgRating} | <span>Reviews</span></div>
                <Divider />
                <div className='sku-cards'>


                  {productDataTest?.productSKUs.map((sku, index) => {
                    // //console.log(sku);
                    return (
                      // <div >{sku.sellingPrice}</div>
                      // <SkuCard handleClick={()=>{handleSkuChange(sku.sku, index)}} index={index} color={sku.color} img={sku.imagesUriList[0]?.uri} size={sku.size} />
                      <div className={`sku-card ${index === selectedProductIndex ? 'selected-sku-card' : ''}`} onClick={() => { handleSkuChange(sku.sku, index) }}>
                        {/* <img loading="lazy" className='props.img' */}
                        <div style={{ width: '58px', height: '44px' }}>

                          <img loading="lazy" className='sku-card-img' src={sku.imagesUriList[0]?.uri} alt="" />
                        </div>
                        <div>

                          <div className='sku-card-color'> Color : {sku.color ? sku.color : 'custom'}</div>
                          <div className='sku-card-size'> Size : {sku.size ? sku.size : 'Custom'}</div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <Box className='Quantity-button-field' >

                  <Quantity sku={productDataTest?.productSKUs[selectedProductIndex]}
                    selectedQuantity={selectedQuantity}
                    setSelectedQuantity={(newQuantity) => {
                      // Ensure the new quantity is within the range [1, Infinity]
                      const clampedQuantity = Math.max(1, newQuantity);
                      setSelectedQuantity(clampedQuantity);
                    }} />
                  {!cartLoading &&
                    <button  
                      className='byr-common-button'>ADD TO CART</button>
                  }
                  {cartLoading && <div style={{ display: "flex", width: "100%", marginLeft: "120px" }}><CircularProgress style={{ color: "pink" }} /></div>}
                </Box>
                <Divider />

                <div className='product-Pincode-tracking-field'>
                  {/* <div className='icon-shipping-tag'><Truck style={{ marginRight: '6px', width: '24px', height: '24px' }} />  Free Shipping on orders above ₹750/-</div> */}
                  <div style={{ display: 'flex', flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-start", gap: "8px" }} className='product-details-pincode-field'>
                    <TextField className='product-Pincode-tracking-textfield' placeholder='Enter Pincode here' value={pincode} onChange={(e) => { setPincode(e.target.value) }} />
                    {!deliverLoading && <div className='check-link' style={{ cursor: "pointer", color: "#ff2d55" }} onClick={checkDeliverable}><u>Check</u></div>}
                    {deliverLoading && <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "pink" }} /></div>}
                  </div>
                  {available === true && <div style={{ color: 'green', fontSize: '10px' }}>Deliverable to this location</div>}
                  {available === false && <div style={{ color: 'red', fontSize: '10px' }}>Not Deliverable to this location</div>}
                </div>

              </div>

              <ThemeProvider theme={theme}>
                <div className='Faq-head-nav' style={{display:'block', marginLeft:'24px'}}>
                  <div className='Product-details-container-right-tabs-head'>

                    <Box
                      sx={{
                        flexGrow: 1,
                        // maxWidth: { xs: 320, sm: 480 },
                        bgcolor: 'background.paper',
                      }}
                    >
                      <Tabs
                        value={tabValue}
                        onChange={handleChangeTab}
                        fullwidth
                        scrollButtons={false}
                        aria-label="visible arrows tabs example"
                        // sx={customTabStylesScroll}
                        
                        sx={{
                          [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.1 },
                            width: 500,
                          },
                        }}
                      >
                        <Tab label="Description" value="Description" style={customTabStyles} />
                        <Tab label="Review" value="Review" style={customTabStyles} />
                        <Tab label="Specification" value="Specification" style={customTabStyles} />
                      </Tabs>
                    </Box>
                  </div>
                  <div className='Product-details-container-right-tabs-content'>
                    {tabValue === 'Description' && (
                      <div>
                        {/* <div className='Product-details-container-right-tabs-description'>
                          {productDataTest?.fact}
                        </div> */}
                        <div className='Product-details-container-right-tabs-description'>
                          {productDataTest?.description}
                        </div>
                      </div>
                    )}
                    {tabValue === 'Review' && (
                      <div>
                        {/* <SingleProductCustomerReviews productId={productDataTest?.productId} UserId={productDataTest?.sellerId} Approval='' accessToken={accessToken} /> */}
                      </div>
                    )}
                    {tabValue === 'Specification' && (
                      <div className='Product-details-container-right-tabs-Specifications'>
                        {productDataTest?.productSKUs?.[selectedProductIndex]?.fabric && <div className='fabric-desc'><span style={{color:'gray', marginRight:'12px', minWidth:'250px'}}>Fabric of the product:</span> {productDataTest?.productSKUs?.[selectedProductIndex]?.fabric}</div>}
                        {productDataTest?.productSKUs?.[selectedProductIndex]?.color && <div className='fabric-desc'><span style={{color:'gray', marginRight:'12px', minWidth:'250px'}}>Color of the product:</span> {productDataTest?.productSKUs?.[selectedProductIndex]?.color}</div>}
                        {productDataTest?.productSKUs?.[selectedProductIndex]?.productAttributeList?.map((item, index) => {
                          return(<div key={index} className='fabric-desc'>
                                                         <span style={{color:'gray', marginRight:'12px', minWidth:'250px'}}>{item?.a_Key}</span>: {item?.a_Value} {item?.a_Key==="length"||item?.a_Key==="height"||item?.a_Key==="width"?' Centimeters':item?.a_Key==="weight"?' KiloGrams':''}

                          </div>)
                        })}
                      </div>
                    )}

                  </div>
                </div></ThemeProvider>
              <div className='Product-details-media-images' style={{flexWrap:'wrap'}}>
                <div className='Product-details-media-images-container'><img loading="lazy" src={MakeInIndia} alt="" className='media-image' /></div>
                <div className='Product-details-media-images-container'><img loading="lazy" src={HandMade} alt="" className='media-imag2' /></div>
                <div className='Product-details-media-images-container'><img loading="lazy" src={Vegan} alt="" className='media-image2' /></div>
              </div>
            </div>
           
          </div>)}
          
          {/* <CustomerHeading text='New Arrivals '   /> */}
            {/* <div className='customer-landing-page-section-container'>
            <Slider {...settingsForProductCard}>
                    {newLoading ? (
                        [1, 2, 3, 4]?.map((i) => (
                            <div key={i}>
                                <CustomerWishlistCardShimmer

                                />
                            </div>
                        ))
                    ) : (
                        NewArrivalData?.map((product) => (
                            <div key={product.productId}>
                                <CustomerProductCard
                                    {...product}
                                    onClick={() => {
                                        navigate(`/productDetail/${product.productId}`);
                                        window.scrollTo(0, 0);
                                    }}
                                />
                            </div>
                        ))
                    )
                    }
                </Slider>
            </div> */}
      </AdminLayoutAdjustment>

    </div>

  )
}

export default AdminProductPreview

const CustomerWishlistCardShimmer = () => {
  return (
      <div className='customer-product-card' style={{ border: '1px solid rgba(217,217,217,1)', borderRadius: '8px', padding: '1rem' }}>
          <div className='customer-product-card-img'>
              <Skeleton variant="rectangular" width='100%' height={200} />
              <div className='customer-product-card-fav'>
                  {/* <HighlightOffIcon size={32} weight="fill" color="#f70808" /> */}
              </div>
          </div>
          <Skeleton variant="text" className='customer-product-card-title' sx={{ fontSize: '1.25rem' }} width='80%' height={25} />
          <Skeleton variant="text" className='customer-product-card-category' sx={{ fontSize: '1rem' }} width='50%' height={20} />
          <Skeleton variant="text" className='customer-product-card-price' sx={{ fontSize: '1.25rem' }} width='30%' height={25} />
          <Skeleton variant="text" className='customer-product-card-review' sx={{ fontSize: '1rem' }} width='80%' height={20} />
          <Skeleton variant="text" className='customer-product-card-quantity' sx={{ fontSize: '1rem' }} width='50%' height={20} />
          <Skeleton variant="rectangular" className='customer-product-card-button' width='100%' height={40} />
      </div>
  );
}