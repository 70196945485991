import React, { useState} from "react";
import "../styles/SingleProduct.css";
import Star from "@mui/icons-material/Star";
import "../styles/Common.css";
import Modal from "@mui/material/Modal";
import Rating from "@mui/material/Rating";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Footer from "./Footer";
import SingleProductImageCarousel from "../components/Single";
import SizeIcon from "../components/SizeIcon";
import productData from "../ProductData";
import reviewData from "../ReviewData";
import logo from "../Images/logo.png";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import SingleProductReview from "../components/SingleProductReview";
import Quantity from "../components/Quantity";
import PrdouctFilterCard from "../components/PrdouctFilterCard";
import Header from "../components/Header";


export default function SingleProduct() {
  const params = useParams();
  const id = params.productId;
  ////console.log(id);
  function rateCount(num, total) {
    return (num / total) * 100;
  }
  // const [color,setColor]=useState("")
  //Modal for size chart.
  const [openModal, setOpenModal] = useState(false);
  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);

  let [review, setReview] = useState(false);
  let [outOfStock] = useState(false);
  // let [count, setCount] = useState(0);



  const formik = useFormik({
    initialValues: {
      starRating: 0,
      feedback: "",
      doc: "",
    },
    onSubmit: (values, { resetForm }) => {
      submitReview();
      resetForm({ values: "" });
    },
  });
  const submitReview = () => {
    ////console.log(formik.values);
  };
  return (
    <div>
    <div className="single-product-container">
      <Header />
      <div className="page-content">
      {/* <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        className="breadcrumbs"
        style={{ marginLeft: "11rem" }}
        aria-label="breadcrumb"
      >
        <Link underline="hover" color="inherit">
          Womens Fashion
        </Link>
        <Link underline="hover" color="inherit">
          TOPS
        </Link>
      </Breadcrumbs> */}

      {!review && (
        <div className="single-product-layout">
          <div className="single-product-image-layout">
            <SingleProductImageCarousel id={id} />
          </div>
          <div className="single-product-description-layout">
            <div className="single-prod-name">
              {productData[id].productName}
            </div>
            <div className="single-product-stars">
              <div className="single-product-star-indicator">
                <Star fontSize="lg" />
                <Star fontSize="lg" />
                <Star fontSize="lg" />
                <Star fontSize="lg" />
                <Star fontSize="lg" />
              </div>
              <div className="single-product-star-number">{`(${productData[id].productStarRating}/5)`}</div>
              <div className="single-product-review-count">{`(${productData[id].productReviewCount} Reviews)`}</div>
            </div>
            <div className="single-prices">
              <div className="single-price">{`Rs ${productData[id].productSellingPrice}`}</div>
              <div className="single-actual-price">{`Rs ${productData[id].productActualPrice}`}</div>
              <div className="single-discount">{`${productData[id].productDiscount} % OFF`}</div>
              {outOfStock && <div className="single-stock">OUT OF STOCK</div>}
            </div>
            <div className="single-product-taxes-text">
              Inclusive of all taxes
            </div>

            <div className="single-product-item-header">
              <div>Size</div>
              <div onClick={handleModalOpen} className="single-item-size-chart">
                Size Chart &gt;{" "}
              </div>
              <Modal open={openModal} onClose={handleModalClose}>
                <div className="single-product-size-modal">
                  <img loading="lazy" alt="" src={"https://picsum.photos/id/1015/250/150/"} />
                </div>
              </Modal>
            </div>
            <div className="single-sizes">
              <SizeIcon size="XS" />
              <SizeIcon size="S" />
              <SizeIcon size="M" />
              <SizeIcon size="L" />
              <SizeIcon size="XL" />
              <SizeIcon size="XXL" />
            </div>
            <div style={{ display: "flex" }}>
              <button className="common-button">Add to cart</button>
              <button
                className="common-button"
                style={{
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FavoriteBorder sx={{ fontSize: 30 }} /> Wishlist
              </button>
            </div>
            <div className="single-product-item-header">COLOURS</div>
            <div className="colors-select">
              <div className="colors-select-red"></div>
              <div className="colors-select-green"></div>
              <div className="colors-select-blue"></div>
              <div className="colors-select-yellow"></div>
            </div>
            {/* <div className="single-product-item-header">QUANTITY</div>
            <div>
              <button className="operation-button" onClick={incrementCount}>
                +
              </button>

              <div
                className="selected-number"
                style={{ display: "inline-block" }}
              >
                {count}
              </div>

              <button className="operation-button" onClick={decrementCount}>
                -
              </button>
            </div> */}
                    <div className="single-product-item-header">QUANTITY</div>

            <Quantity />
            <div className="single-product-item-header"> DESCRIPTION</div>
            <div className="single-product-description-points">
              <ul>
                {productData[id].productDescription.map(function (item) {
                  return <li>{item}</li>;
                })}
              </ul>
            </div>
            <div className="single-product-item-header">RATINGS & REVIEWS</div>
            <div className="single-product-item-review-by-stars">
              <div className="single-item-five-star">
                <div>5</div>
                <Star />
                <div className="single-review-field">
                  <div
                    className="single-review-field-filled"
                    style={{
                      width: `${rateCount(
                        productData[id].productRating[0].five,
                        productData[id].totalRatings
                      )}%`,
                    }}
                  ></div>
                </div>
                <div>{productData[id].productRating[0].five}</div>
              </div>
            </div>
            <div className="single-product-item-review-by-stars">
              <div className="single-item-five-star">
                <div>4</div>
                <Star />
                <div className="single-review-field">
                  <div
                    className="single-review-field-filled"
                    style={{
                      width: `${rateCount(
                        productData[id].productRating[0].four,
                        productData[id].totalRatings
                      )}%`,
                    }}
                  ></div>
                </div>
                <div>{productData[id].productRating[0].four}</div>
              </div>
            </div>
            <div className="single-product-item-review-by-stars">
              <div className="single-item-five-star">
                <div>3</div>
                <Star />
                <div className="single-review-field">
                  <div
                    className="single-review-field-filled"
                    style={{
                      width: `${rateCount(
                        productData[id].productRating[0].three,
                        productData[id].totalRatings
                      )}%`,
                    }}
                  ></div>
                </div>
                <div>{productData[id].productRating[0].three}</div>
              </div>
            </div>
            <div className="single-product-item-review-by-stars">
              <div className="single-item-five-star">
                <div>2</div>
                <Star />
                <div className="single-review-field">
                  <div
                    className="single-review-field-filled"
                    style={{
                      width: `${rateCount(
                        productData[id].productRating[0].two,
                        productData[id].totalRatings
                      )}%`,
                    }}
                  ></div>
                </div>
                <div>{productData[id].productRating[0].two}</div>
              </div>
            </div>
            <div className="single-product-item-review-by-stars">
              <div className="single-item-five-star">
                <div>1</div>
                <Star />
                <div className="single-review-field">
                  <div
                    className="single-review-field-filled"
                    style={{
                      width: `${rateCount(
                        productData[id].productRating[0].one,
                        productData[id].totalRatings
                      )}%`,
                    }}
                  ></div>
                </div>
                <div>{productData[id].productRating[0].one}</div>
              </div>
            </div>
            <button
              className="common-button"
              onClick={() => {
                setReview(true);
                // setCount(0);
                window.scrollTo(0, 0);
              }}
              style={{ backgroundColor: "white", alignSelf: "flex-start" }}
            >
              Write a Review
            </button>
            <div className="single-product-review-image-layout">
        <div className="single-product-review-images-heading">
          CUSTOMER REVIEW IMAGES (3)
        </div>
        <div className="single-product-review-images">
          <img loading="lazy" src={logo} alt="" />
          <img loading="lazy" src={logo} alt="" />
          <img loading="lazy" src={logo} alt="" />
        </div>
      </div>
      <div className="single-product-review-desc-heading">
          CUSTOMER REVIEWS (15)
        </div>
            {reviewData.slice(0, 3).map((cardProps) => {
              return <SingleProductReview {...cardProps} />;
            })}
          </div>
        </div>
      )}
      {review && (
        <div className="single-page-submit-review">
          <div className="single-review-desc">
            <div className="single-review-desc-img">
              <img loading="lazy" src={productData[id].productImage} alt="" />
            </div>
            <div className="single-review-desc-desc">
              <div className="single-prod-name">
                {productData[id].productName}
              </div>
              <div className="single-product-stars">
                <div className="single-product-star-indicator">
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                  <Star color="white" />
                </div>
                <div className="single-product-star-number">{`(${productData[id].productStarRating}/5)`}</div>
                <div className="single-product-review-count">{`(${productData[id].productReviewCount} Reviews)`}</div>
              </div>
              <div className="single-prices">
                <div className="single-price">{`Rs ${productData[id].productSellingPrice}`}</div>
                <div className="single-actual-price">{`Rs ${productData[id].productActualPrice}`}</div>
                <div className="single-discount">{`${productData[id].productDiscount} % OFF`}</div>
                {outOfStock && <div className="single-stock">OUT OF STOCK</div>}
              </div>
              <div className="single-product-item-header"> DESCRIPTION</div>
              <div className="single-product-description-points">
                <ul>
                  {productData[id].productDescription.map(function (item) {
                    return <li>{item}</li>;
                  })}
                </ul>
              </div>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            
            <div className="single-rate-and-review">
              <div className="single-review-rate">
                <div style={{ fontSize: "2rem" }}>RATING PRODUCT</div>
                <div className="rating-box">
                  <div className="star-rating-text"> Star Rating</div>
                  <Rating
                    max={5}
                    precision={0.5}
                    name="starRating"
                    value={formik.values.starRating}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className="single-review-review">
                <textarea
                  className="single-review-textarea"
                  placeholder="Would you like"
                  // type="text"
                  name="feedback"
                  value={formik.values.feedback}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="single-upload-files">
              <input
                className="submit-upload-files"
                type="file"
                name="doc"
                value={formik.values.doc}
                onChange={formik.handleChange}
              />
            </div>
            </div>
            

            <input
              type="submit"
              className="common-button"
              onClick={() => {
                formik.handleSubmit();
                window.scrollTo(0, 0);
                setReview(false);
              }}
              value="Submit Review"
            />
          </form>
        </div>
      )}
      {!review && (
        <div>
          <div className="related-styling">
            <div className="related-styling-text">RELATED ITEMS</div>
            <div className="related-styling-underline"> </div>
          </div>
          <div className="product-image-cards">
            {/* <RelatedItemsCard id="0" />
            <RelatedItemsCard id="1" />
            <RelatedItemsCard id="2" />
            <RelatedItemsCard id="3" /> */}

            {productData.slice(0,4).map((cardProps)=>{
              return <PrdouctFilterCard id={`${cardProps.productId}`} />
            })}
          </div>
        </div>
      )}
      </div>
      <Footer />
    </div>
    </div>);
}