import { useState, useContext } from "react";
import "../styles/AdminLogin.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { CircularProgress, IconButton, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import logo from "../Images/MainLogo.png";
// import AdminLoginImg from '../Images/adminotpverifyimg.png'
import "../styles/Common.css";
import { Alert } from "@mui/lab";
import { errorAlert, successAlert } from "../components/Alert";
// import { AdminContext } from "../context/AdminContext";
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import BuyerLoginImg from "../Images/BuyerLoginImg.png";
import "../styles/BuyerLogin.css"
import { Link } from 'react-router-dom';
import BuyerCard from "../components/BuyerCard";
import { CustomerContext } from "../context/CustomerContext";
//import notif from "../components/Notify";
const passwordRegexp = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{6,20}$)"
);
const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    // .trim()
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .matches(passwordRegexp, "Password is not valid")
    .min(8, "Password should be minimum 8 characters")
    .required("Password is required"),
});

export default function LoginPage() {
  const navigate = useNavigate();

  // const [loginInProgress, setLoginInProgress] = useState(false)
  const [loading, setLoading ] = useState(false);
  const [error] = useState(false);

  const { login } = useContext(CustomerContext);
 
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const result = await login(values.email, values.password);
      if (!result?.result?.accessToken) {
        setLoading(false);
        errorAlert('Wrong Password','Unsuccessful Login',error)
      } else {
        successAlert('','Login Successful','success')
        setLoading(false);

      }
    },
  });
  // const [passwordShown, setPasswordShown] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordEntered, setPasswordEntered] = useState(false);
    
    const handleTogglePassword = () => {
      setShowPassword(!showPassword);
    };
    
    const handlePasswordChange = (event) => {
      const value = event.target.value;
      setPasswordEntered(value !== '');
    };
  return (
    <BuyerCard
    cardImg={<img loading="lazy" className="BuyerLoginImg" src={BuyerLoginImg} alt="" />}
    imageFirst={true}
  >
    <div className="buyer-login-right">
        <div className="admin-login-page-card">
          {error && (
            <Alert severity="error" className="admin-login-page-error-toast">
              Invalid Email or Password
            </Alert>
          )}
       <div className="logo-field">
        <img loading="lazy" src={logo} alt='' className="Buyer-logo"/>
       </div>
          <div className="buyer-login-head">
            Sign In
          </div>
          <form
            className="buyer-login-page-form"
            onSubmit={formik.handleSubmit}
          >
            <div className="buyer-login-page-email-field">
              <TextField
                autoFocus
                variant="outlined"
                fullWidth
                type="text"
                id="login-page-email"
                name="email"
                className="buyer-login-email"
                placeholder="Enter Your Email"
                value={formik.values.email.trim()}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            <MailOutlineIcon
                  className="buyer-login-email-icon"
                  fontSize="medium "
                />
            </div>

            <div className="buyer-login-page-password-field">
              <TextField
                variant="outlined"
                 type={showPassword ? 'text' : 'password'}
                id="login-page-password"
                name="password"
                fullWidth
                className="buyer-login-password"
                placeholder="Enter Your Password"
                value={formik.values.password}
                onChange={(event) => {
                  formik.handleChange(event);
                  handlePasswordChange(event);
                }}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: passwordEntered && (
                  
                      <IconButton onClick={handleTogglePassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
               
                  ),
                }}
              />
              <LockOpenIcon
                className="buyer-login-password-icon"
                fontSize="medium "
              />
            </div>
        
            {/* <label className="buyer-login-page-checkbox">
              <input
                type="checkbox"
                style={{
                  fontSize: "5em",
                  marginRight: "10px",
                  transform: "scale(1.5)",
                }}
                name="checkbox"
                value="value"
              />
              Remember me?
            </label> */}

           {!loading&& <input
              type="submit"
              className="byr-common-button "
              value="Sign In"
            />}
            {loading&&
       <div style={{display:"flex", width:"100%",alignItems:"center",justifyContent:"center"}}><CircularProgress style={{color:"pink" }} /></div>}

          </form>
          <div className="admin-login-page-forgot seller-login-page-forgot">
            <span
              onClick={() => {
                navigate("/BuyerForgetPassword");
              }}
            >
              Forget Password?
            </span>
          </div>
          {/* <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <hr style={{ width: "45%", height: " 0px", alignSelf: "center" }} />
            <p style={{ fontSize: "1.5rem", alignSelf: "center", color: "#FF7891" }}>or</p>
            <hr style={{ width: "45%", height: " 0px", alignSelf: "center" }} />
          </div> */}
          <div className="sign-in-with-otp-accept-terms-field">
          <button
             className="byr-common-button "
            onClick={() => {
              navigate("/otpLogin");
            }}
          >
            Sign In with OTP
          </button>
          
          {/* <button
             className="byr-common-button "
            onClick={() => {
              navigate("/register");
            }}
          >
            Register
          </button> */}
          <div className="signup-signin-byr" >
          New User?,{""}
          <span
            onClick={() => {
              navigate("/register");
            }}
            style={{color:"#FF6155",textDecoration:"underline"}}
          >
            Sign Up
          </span>
          </div>

          <div className="byr-terms-condition ">
            By continuing, you agree to MyhraKi's
            <Link to="/termsAndCondition" className='register-privacy-link'>Terms and Conditions</Link> and  <Link to="/privacyPolicy" className='register-privacy-link'>Privacy Policy</Link>
          </div>
          </div>
        </div>
      </div>
    </BuyerCard>
  );
}
