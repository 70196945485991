const orderInfo = {
    shippingInfo: {
      name: "Aishwarya",
      email: "aishwaryakrishna81@gmail.com",
      shippingAddress: "Indira nagar Kar Bhavan Nr Times Of India, Ashram Road, AHEMEDABAD, GUJARAT, INDIA - 380009",
      billingAddress: "Indira nagar Kar Bhavan Nr Times Of India, Ashram Road, AHEMEDABAD, GUJARAT, INDIA - 380009",
      contact: "8989283782"
    },
    paymentInfo: {
      name: "Aishwarya",
      paymentMethod: "Ph*** Pay",
      orderSummary: [
        { text: "Subtotal", amount: "₹ 12,500" },
        { text: "Total Discount", amount: "₹ 2,500" },
        { text: "Estimated Delivery", amount: "Tomorrow" },
        { text: "Total", amount: "₹ 10,000" }
      ]
    },
    orderDetails: {
        orderID: "989091",
        orderDate: "Aug 20 2023"
      },
    shippingInfo: {
        name: "Anand",
        email: "anand123@gmail.com",
        shippingAddress: "Indira nagar Kar Bhavan Nr Times Of India, Ashram Road, AHEMEDABAD, GUJARAT, INDIA - 380009",
        billingAddress: "Indira nagar Kar Bhavan Nr Times Of India, Ashram Road, AHEMEDABAD, GUJARAT, INDIA - 380009",
        contact: "8989283782"
      },
      paymentInfo: {
        name: "Anand",
        paymentMethod: "Ph*** Pay",
        orderSummary: [
          { text: "Subtotal", amount: "₹ 12,500" },
          { text: "Total Discount", amount: "₹ 2,500" },
          { text: "Estimated Delivery", amount: "Tomorrow" },
          { text: "Total", amount: "₹ 10,000" }
        ]
      },
      shippingInfo: {
        name: "Hari",
        email: "hari123@gmail.com",
        shippingAddress: "Indira nagar Kar Bhavan Nr Times Of India, Ashram Road, AHEMEDABAD, GUJARAT, INDIA - 380009",
        billingAddress: "Indira nagar Kar Bhavan Nr Times Of India, Ashram Road, AHEMEDABAD, GUJARAT, INDIA - 380009",
        contact: "8989283782"
      },
      paymentInfo: {
        name: "Hari",
        paymentMethod: "Ph*** Pay",
        orderSummary: [
          { text: "Subtotal", amount: "₹ 12,500" },
          { text: "Total Discount", amount: "₹ 2,500" },
          { text: "Estimated Delivery", amount: "Tomorrow" },
          { text: "Total", amount: "₹ 10,000" }
        ]
      }
  };
export default orderInfo;  