import React, { useContext, useRef, useState } from "react";

import { API_BASE_URL } from '../App'
import "../styles/Common.css"
import "../styles/AddressCard.css";
import ToastComponent from "./ToastComponent";
import { Trash } from "@phosphor-icons/react";
import { SellerContext } from "../context/SellerContext";
import SellerAddressModal from "./SellerAddressModal";
import { errorAlert } from "./Alert";



export default function SellerAddressCard(props ) {
  const { sellerData, accessToken, handleAuthExpiry, logout } = useContext(SellerContext);
  const [addressData, setAddressData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultLoading, setDefaultLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const toastRef = useRef();
  const handleModalOpen = () => {
    setOpenModal(true);
  };
  const handleModalClose = () => {setOpenModal(false);}

  const handleDelete = async (id) => {
   setLoading(true)
    try {
      const response = await fetch(`${API_BASE_URL}/address/api/v1/${id}?userId=${sellerData?.result?.userID}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      setLoading(false);
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        setLoading(false);
        errorAlert('', 'Your Session has expired');
        logout();
        return;
      }
      if (response.ok) {
        toastRef.current.showToast("Address deleted Successfully", "success");
        props.fetchAddressData();
      } else {
        setLoading(false)
        toastRef.current.showToast("Could not delete address", "error");
      }
    } catch (error) {
      setLoading(false)
      toastRef.current.showToast("Could not delete address", "error");
    }
  };

  const handleDefaultAddressChange = async (props)=>{
    setDefaultLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/address/api/v1/update`,
        {
          method: "PUT",
          body: JSON.stringify({
            ...props,
            AddressId: props.id,
            userID: sellerData?.result?.userID,
            
            isDefault: true,
      contactName: props.contactName,
      phoneNumber: props.phoneNumber,
      addressLine1: props.addressLine1,
      addressLine2: props.addressLine2,
      city: props.city,
      pincode: props.pincode,
      state: props.state,
      country: props.country,
      addressType: props.addressType,
      countryCode: props.countryCode,
    
          }),
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json-patch+json",
          },
        }
      );
      const data = await response.json();
      setDefaultLoading(false)
      if (response.ok) {
        toastRef.current.showToast("Default Address Changed", "success");

        const updatedAddressData = { ...addressData, ...data };
        setAddressData(updatedAddressData);
        props.fetchAddressData();
        handleModalClose();
      } else {
        toastRef.current.showToast("Could not change  Default Address", "error");
      }
    } catch (error) {
      setDefaultLoading(false)

      toastRef.current.showToast("Could not change  Default Address", "error");
      console.error("Error updating Address:", error);
    }
  }
  return (
    <>
          <ToastComponent ref={toastRef} timeout={4000} />
  <div className="address-card-content-fields">
  {props.isDefault ? (
  <div className="isDefault">Default address</div>
) : (
  defaultLoading !== true ? (
    <div className="isDefault" onClick={() => handleDefaultAddressChange(props)}>
    </div>
  ) : (
    <div style={{ display: "flex", width: "10%", alignItems: "center", justifyContent: "center", minHeight:'0px' }}>
      {/* <CircularProgress style={{ color: "pink" }} /> */}
    </div>
  )
)}
 <div className={`address-card-container`}>
  <div className="name-and-icons">
    <div className="address-name-type-field">
      {!props.isAdd && <div className="name">{props.contactName}</div>}
      {!props.isAdd && (
        <div className="address-type-icon">
          {!props.isAdd && <div className="addressType"> {props.addressType}</div>}
        </div>
      )}
     
    </div>
    <div>  <Trash style={{width:"24px",height:"24px"}} onClick={() => handleDelete(props.id)} /></div>
  </div>
  {!props.isAdd && <div className="address">{props.addressLine1}</div>}
  <div style={{ display: "flex", gap: "1rem" }}>
    {!props.isAdd && <div className="address-place-field"> {props.state}</div>}
    {!props.isAdd && <div className="address-place-field"> {props.city}</div>}
    {!props.isAdd && <div className="address-place-field"> {props.pincode}</div>}
  </div>
  {!props.isAdd && <div className="address-place-field"> {props.country}</div>}
  {!props.isAdd && <div className="contact">Contact: <span className="address-place-field">{props.phoneNumber}</span></div>}
  {!props.isAdd && (
    <div className="address-edit-delete-icons">
      {/* <button className="address-edit-icon" onClick={handleModalOpen}>Edit</button> */}
      {!props.isDefault && props.addressType !== "Default" && (
        <button className="address-edit-icon" onClick={() => { handleDefaultAddressChange(props) }}>
          Set as Default
        </button>
      )}
    </div>
    
  )}
</div>
    <SellerAddressModal
          open={openModal}
          onClose={handleModalClose}
          edit={true} 
          address={props}
          fetchAddressData={props.fetchAddressData}
        />
 </div>

</>
  );
 
}
