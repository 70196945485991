import {  Divider, LinearProgress } from "@mui/material";
import React, { useState } from "react";
// import Card from "./Card";
import "../styles/TrackDetails.css";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CachedIcon from "@mui/icons-material/Cached";
import HomeIcon from "@mui/icons-material/Home";
// import BuyersLayout from "./BuyersLayout";
// import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import { Link } from "react-router-dom";
// import DoneIcon from "@mui/icons-material/Done";

export default function TrackDetails(props) {
  const [orderStatus] = useState(props.state);
  return (
     
    <div className="order-tracker">
      {/* <div className="order-tracking-title">Track Order</div> */}
      <div className="track-details-heads">Order ID : 12345678</div>
      <div className="track-details-heads">Order Date : Mar 19 2023</div>
      <div className="delivery-details" >
        {orderStatus==="Dispatched"&&<div className="blue">
        <span className="black"><LocalShippingIcon sx={{  fontSize: "1.5rem", paddingRight:"1rem" }} />
           Estimated Delivery on :</span>  Mar 19 2023
        </div>}
        {orderStatus==="OrderProcessing"&&<div className="blue">
        <span className="black"><LocalShippingIcon sx={{  fontSize: "1.5rem", paddingRight:"1rem" }} />
           Estimated Delivery on :</span>  Mar 19 2023
        </div>}
        {orderStatus==="Delivered"&&<div className="blue">
        <span className="black"><LocalShippingIcon sx={{  fontSize: "1.5rem", paddingRight:"1rem" }} />
          Delivered on :</span>  Mar 19 2023
        </div>}
        {orderStatus==="Returned"&&<div className="blue">
        <span className="black"><LocalShippingIcon sx={{ fontSize: "1.5rem" , position:"relative", top:"-1rem", paddingRight:"1rem"}} />
           Delivered on :</span> Mar 19 2023<br />
          Returned on : Mar 19 2023
        </div>}
        <div className="order-tracking-status">
          Order Status:
          <span className="order-status-highlight"> {orderStatus}</span>
        </div>
        <div className="order-tracking-status">
          Status: <TaskAltIcon sx={{ color: "rgba(248, 152, 128, 1)" }} /> Paid
        </div>
      </div> 
      <Divider />
      <div style={{ width: "10%", margin: "0 auto" }}>Complete</div>
      {orderStatus==="Dispatched"&&<div className="order-percent-completed">
        <LinearProgress
        className="order-perecentage"
          variant="determinate"
          value={60}
          style={{
            width: " 192px",
            margin: "0 auto",
            color: "#FC2975;",
            display: "flex",
          }}
          sx={{ color: "rgba(248, 152, 128, 1)" }}
        />
        <span className="order-percent-completed-text">65%</span>
      </div>}
      {orderStatus==="Delivered"&&<div className="order-percent-completed">
        <LinearProgress
          variant="determinate"
          value={100}
          style={{
            width: "20%",
            margin: "0 auto",
            color: "rgba(248, 152, 128, 1)",
            display: "flex",
          }}
          sx={{ color: "rgba(248, 152, 128, 1)" }}
        />
        <span className="order-percent-completed-text">100%</span>
      </div>}

      <div class="stepper-wrapper" style={{ width: "80%", fontSize: "1rem" }}>
        <div id="step-1" class="stepper-item">
          <div class="step-counter default">
            <TaskAltIcon sx={{ color: "white" }} />
          </div>
          <div class="step-name">Order Confirmed</div>
        </div>
        <div id="step-2" class="stepper-item completed">
          <div class="step-counter">
            <CachedIcon sx={{ color: "white" }} />{" "}
          </div>
          <div class="step-name">Order Processing</div>
        </div>
        <div id="step-3" class="stepper-item completed">
          <div class="step-counter">
            {" "}
            <LocalShippingIcon sx={{ color: "white" }} />
          </div>
          <div class="step-name">Order Dispatched</div>
        </div>
        {orderStatus === "Dispatched" && (
          <div id="step-4" class="stepper-item ">
            <div class="step-counter">
              <HomeIcon sx={{ color: "black" }} />
            </div>
            <div class="step-name">Order Delivered</div>
          </div>
        )}
        {orderStatus === "Dispatched" && (
          <div id="step-4" class="stepper-item ">
            <div class="step-counter">
              <HomeIcon sx={{ color: "black" }} />
            </div>
            <div class="step-name">Order Completed</div>
          </div>
        )}
        {orderStatus === "Delivered" && (
          <div id="step-4" class="stepper-item completed">
            <div class="step-counter">
              <HomeIcon sx={{ color: "white" }} />
            </div>
            <div class="step-name">Order Delivered</div>
          </div>
        )}
        {orderStatus === "Delivered" && (
          <div id="step-4" class="stepper-item completed">
            <div class="step-counter">
              <HomeIcon sx={{ color: "white" }} />
            </div>
            <div class="step-name">Order Completed</div>
          </div>
        )}
      </div>
      {/* <div onClick={props.goToDefautView}>Go Back </div> */}
    </div>
 
  );
}
