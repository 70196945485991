import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import MyhrakiLogo from "../Images/MainLogoFinal.png";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { API_BASE_URL } from '../App'

import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { UserCircle, Truck, AddressBook, PhoneCall, SignOut, ChatsCircle } from '@phosphor-icons/react';
import { ShoppingCart } from '@phosphor-icons/react';
import { Heart } from '@phosphor-icons/react';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { pink } from '@mui/material/colors';
import { Button, TextField } from '@mui/material';
import { CustomerContext } from '../context/CustomerContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom/dist';
import "../styles/CustomerNavbar.css"
import { useEffect } from 'react';
import { useState } from 'react';
import { SignIn } from '@phosphor-icons/react';
import CustomerCategoryBar from './CustomerCategoryBar';
// import "../styles/BuyersNavbar.css"


function CustomerNavbar(props) {
    // //console.log('From Navbar main', props)
    return (
        <div>
            <PrimarySearchAppBar {...props} />
            <CustomerCategoryBar />

        </div>
    )
}

export default CustomerNavbar



const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(2),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const typographyStyles = {
    display: { xs: 'none', sm: 'block' },
    color: '#FFF',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    textTransform: 'uppercase'
};

function PrimarySearchAppBar(props) {
    const { customerData, accessToken, logout,currentProfileTab, setCurrentProfileTab } = useContext(CustomerContext);
    const navigate = useNavigate();

    // //console.log('From Navbar sub', props)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const handleLogoClick = () => {
        navigate("/")
    }
    const handleBlogClick =() =>{
        navigate("/Blog")
        //console.log('Navigating to Blog');
    }
    const handleMyOrderClick = () => {
        setCurrentProfileTab('orders')
        handleMobileMenuClose();
        //console.log(currentProfileTab)
        navigate("/profile")

    }
    const handleMyAddressClick = () => {
        setCurrentProfileTab('address')
        handleMobileMenuClose();

        navigate("/profile")

    }
    const handleContactClick = () => {
        setCurrentProfileTab('help')
        handleMobileMenuClose();

        navigate("/profile")

    }
    const handleFaqClick = () => {
        setCurrentProfileTab('faq')
        handleMobileMenuClose();

        navigate("/profile")

    }
    const handleWishlistClick = () => {
        setCurrentProfileTab('wishlist')
        handleMobileMenuClose();

        navigate("/profile")

    }
    const handleMyProfileClick = () => {
        setCurrentProfileTab('account')
        handleMobileMenuClose();

        navigate("/profile")
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
            {/* <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (

        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleMyProfileClick}>
                <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                    <UserCircle />
                </IconButton>
                <p>My Profile</p>
            </MenuItem>
            <MenuItem onClick={handleMyAddressClick}>
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                >
                    {/* <Badge badgeContent={17} color="error"> */}
                    <AddressBook />
                    {/* </Badge> */}
                </IconButton>
                <p>My Address</p>
            </MenuItem>
            <MenuItem onClick={handleMyOrderClick}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <Truck />
                </IconButton>
                <p>My Orders</p>
            </MenuItem>
            <MenuItem onClick={handleContactClick}>
                <IconButton
                    aria-label="show 4 new mails"
                    size="large"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <PhoneCall />
                </IconButton>
                <p>Contact Us</p>
            </MenuItem>
            <MenuItem >

                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    {/* <Badge badgeContent={4} color="success"> */}
                    <Heart />
                    {/* </Badge> */}

                </IconButton>
                <p>My Wishlist</p>    
            </MenuItem>
            <MenuItem onClick={handleFaqClick}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <ChatsCircle />
                </IconButton>
                <p>FAQ</p>
            </MenuItem>
           {!customerData?.result?.accessToken&& <MenuItem onClick={()=>{logout()}}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <SignOut />
                </IconButton>
                <p>Logout</p>
            </MenuItem>}
           {customerData?.result?.accessToken&& <MenuItem onClick={props.openLoginModal}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <SignIn />
                </IconButton>
                <p>Login/ Register</p>
            </MenuItem>}
        </Menu>
    );





    const [searchValue, setSearchValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    let debounceTimer = null;

    useEffect(() => {
        const fetchSuggestions = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/productgen/api/v1/search?Text=${searchValue}&PageNumber=0&PageSize=10`);
                const data = await response.json();
                //console.log(data);
                const filteredData = data.result

                setSuggestions(filteredData);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        };

        clearTimeout(debounceTimer);

        if (searchValue) {
            debounceTimer = setTimeout(() => {
                fetchSuggestions();
            }, 500);
        } else {
            setSuggestions([]);
        }

        return () => clearTimeout(debounceTimer);
    }, [searchValue]);

    const handleSearchChange = (event) => {
        const { value } = event.target;
        setSearchValue(value);
    };

    const handleSuggestionClick = (id) => {
        setSuggestions([]);
        navigate(`/productDetail/${id}`)
    }

    return (
        <Box sx={{ flexGrow: 1 }} >
            <AppBar position="fixed" style={{ background: 'linear-gradient(90deg, #FD2583 0%, #FE585B 100%)', boxShadow: 'none', display: 'flex', justifyContent: 'space-between', width: '100%' }} >
                <Toolbar sx={{ width: { md: '90%', lg: '90%' }, margin: { xs: 'none', md: '0 auto' } }}
                    style={{ background: 'linear-gradient(90deg, #FD2583 0%, #FE585B 100%)', maxWidth: '1280px', boxShadow: 'none', display: 'flex' }}>

                    {/* <Typography
                        variant="h4"
                        noWrap
                        component="h4"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                        MYHRAKI
                    </Typography> */}
                    <Box sx={{ display: { xs: 'block', md: 'none' } }} style={{ marginRight: '10px' }}>
                        M
                    </Box>

                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        {<img loading="lazy" src={MyhrakiLogo} alt="" onClick={handleLogoClick} className='CustomNavBar' />}
                    </Box>


                    <Box style={{ display: 'flex', gap: '1rem' }} sx={{ marginLeft: { sm: 'none', md: '3rem' } }}>
                        <Typography variant="h6" noWrap component="div" sx={typographyStyles}>
                            TRENDING NOW
                        </Typography>
                        <Typography variant="h6" noWrap component="div" sx={typographyStyles} onClick={handleBlogClick}>
                            BLOG
                        </Typography>
                        <Typography variant="h6" noWrap component="div" sx={typographyStyles}>
                            OFFERS
                        </Typography>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />

                    <div className="buyers-searchbar">

                        <Search style={{ minWidth: '230px', height: "32px", backgroundColor: 'white', color: 'black', position: 'relative', borderRadius: "8px", display: "flex", alignItems: "center" }} className='buyers-search-field'>
                            <SearchIconWrapper>
                                {/* <SearchIcon /> */}
                                <MagnifyingGlass style={{ color: 'rgba(235, 77, 128, 1)' }} />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                inputProps={{ 'aria-label': 'search' }}
                                value={searchValue}
                                onChange={handleSearchChange}
                            />
                        </Search>

                        {suggestions?.length > 0 && (
                            <ul className="buyers-search-suggestion-list">
                                {suggestions?.map((suggestion, index) => (
                                    <li className="buyers-search-suggestion" key={index} onClick={() => { handleSuggestionClick(suggestion?.productId) }}>
                                        {searchValue} in {suggestion?.title}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <Box sx={{ flexGrow: 1 }} />

                    {!customerData?.result?.userID && <Button variant="text" sx={{ display: { xs: 'none', md: 'flex' } }} style={{ color: 'white', fontSize: "16px", fontWeight: "600", letterSpacing: "0.03em" }} className='Login-button-navbar' onClick={props.openLoginModal}>LOGIN/SIGNUP</Button>}
                    {customerData?.result?.userID && <Button variant="text" sx={{ display: { xs: 'none', md: 'flex' } }} style={{ color: 'white', fontSize: "16px", fontWeight: "600", letterSpacing: "0.03em" }} onClick={logout}>LOGOUT</Button>}
                    <Box sx={{ flexGrow: 1 }} />

                    <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: '16px', width: "110px" }}>
                        <IconButton
                            size="large"
                            aria-label="show 17 new notifications"
                            color="inherit"
                        >
                            <ShoppingCart size={32} onClick={() => { navigate('/cart') }} />
                            {/* </Badge> */}
                        </IconButton>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            color="inherit"
                        >
                            <UserCircle size={32} onClick={() => { navigate('/profile') }} />
                        </IconButton>


                        {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                            <Badge badgeContent={4} color="error">
                                <Heart size={32} />
                            </Badge>
                        </IconButton> */}
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show 17 new notifications"
                            color="inherit"
                        >
                            {/* <Badge badgeContent={17} color="error"> */}
                            <ShoppingCart size={28} onClick={() => { navigate('/cart') }} />
                            {/* </Badge> */}
                        </IconButton>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"

                        >
                            <UserCircle size={28} color='#fff' />
                        </IconButton>

                        {/* <MoreIcon /> */}

                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}

        </Box>
    );
}







