import React from "react";
import "../styles/Wishlist.css";
import Card from "../components/Card";
import { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "@mui/material";
import { API_BASE_URL } from '../App'
import { useContext } from "react";
import BcBuyerLayout from "../buyerComponents/BcBuyerLayout";
import { CustomerContext } from "../context/CustomerContext";

export default function BuyerReviewsRatings() {
    const [reviewsData, setReviewsData] = useState([]); 
  const { accessToken} = useContext(CustomerContext);
  const [productId, setProductId] = useState(""); 
//   const[approval,setApproval]=useState("");
// const [userId, setUserId] = useState(""); 
  // // return fetch("https://api.myhraki.com/api/v1/Favorites?buyeremail=kharavela.jain%40outlook.com", {

  const fetchReviews = async () => {
    try {
      const userId = "userId"; 
      const approval = true;
  
      const response = await fetch(
        `${API_BASE_URL}/feedback/api/v1/Get?ProductId=${productId}&UserId=${userId}&Approval=${approval}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`, 
          },
        }
      );
  
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
  
      const data = await response.json();
      //console.log("Fetched reviews:", data);
  
      // Process the fetched reviews here (e.g., update state, display on the screen, etc.)
      // Wait for a short delay before making the next request
      await new Promise((resolve) => setTimeout(resolve, 500)); 
    //   fetchReviews();
    } catch (error) {
      console.error("Unable to fetch reviews:", error.message);
    }
  };
useEffect(()=>{
    fetchReviews();
  },[])
                      

  return (
    <BcBuyerLayout>
      <div className='bc-buyer-layout-container'>
      <Breadcrumbs>

      <Link >
                My Account
              </Link>

              <Link
                underline="hover"
                color="inherit"
                style={{ fontSize: "1.5rem", fontWeight: "400" }}
              >
                My Reviwes and Ratings
              </Link>
            </Breadcrumbs>
            {reviewsData &&
        reviewsData?.response?.map((cardProps) => {
          return  <Card
          reviewsRatingCard
          {...cardProps}
          key={cardProps.Id}
          productId={productId}
          setProductId={setProductId}
        />;
        })}
        </div>
    </BcBuyerLayout>
  );
}
