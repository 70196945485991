import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Typography,
} from "@mui/material";
import {
  Apps,
  Menu,
  ContactMail,
  AssignmentInd,
  Home,
} from "@mui/icons-material";
import VerifiedIcon from "@mui/icons-material/Verified";
import  "../styles/Logistics.css";
import mainlogo from "../Images/MainLogo.png";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BarChartIcon from "@mui/icons-material/BarChart";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CategoryIcon from "@mui/icons-material/Category";
import { makeStyles } from "@mui/styles";
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import { useContext } from "react";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { LocalVendorContext } from "../context/LocalVendorContext";
import { LogisticsAdminContext } from "../context/LogisticsAdminContext";

const useStyles = makeStyles((theme) => ({
  menuSliderContainer: {
    width: 350,
    background: "white",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    height: "100%",
    position:"relative"
  },
  avatar: {
    margin: "0.5rem auto",
    padding: "1rem",
    
  },
  listItem: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "1rem",
    "&:hover": {
      background: " #FF99A5 ",
      color: "white",
    },
  },
  selectedListItem: {
    background: "#FD2A80",
    color: "white",
  },
}));
const listItems = [
  {
    listIcon: <ShoppingBagOutlinedIcon />,
    listText: "ORDERS",
    listNav: "/orders",
  },

  {
    listIcon: <DashboardIcon />,
    listText: "RETURN AND REPLACE",
    listNav: "/return",
  },
  {
    listIcon: <DoNotDisturbAltIcon />,
    listText: "CANCEL ORDERS",
    listNav: "/cancel",
  },
];



export default function LogisticAdminLayout1(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const navigate = useNavigate();
  // const handleNav=(x)=>{
  //   navigate(x)
  // }
  const { selectedListItemIndex, setSelectedListItemIndex, logout } =
    useContext(LogisticsAdminContext);
  const toggleSlider = () => {
    setOpen(!open);
  };

  useEffect(()=>{
    setSelectedListItemIndex(listItems[0]);
  },[])

  const handleListItemClick = (index) => {
    setSelectedListItemIndex(index);
    //console.log(listItems[index].listNav);
    navigate(listItems[index].listNav);
  };

  const sideList = () => (
    <div className={classes.menuSliderContainer} >
      
      <Avatar
        className={classes.avatar}
        // src="https://i.ibb.co/rx5DFbs/avatar.png"
        alt="admin"
      /> 
      <Divider />
      <List>
        {/* <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between"}}> */}
        {listItems.map((listItem, index) => (
          <ListItem
            className={`${classes.listItem} ${
              selectedListItemIndex === index ? classes.selectedListItem : ""
            }`}
            onClick={() => {
              handleListItemClick(index);
            }}
            key={index}
            style={{ padding: "1rem auto" }}
          >
            <ListItemIcon className={classes.listItem}>
              {listItem.listIcon}
            </ListItemIcon>
            <ListItemText primary={listItem.listText} />
          </ListItem>
        ))}
        
      </List>
      <div className={classes.listItem} style={{position:"absolute", bottom:"2rem" ,width:"99%" ,fontSize:"1.2rem",textAlign:"center", minHeight:"30px", padding:"1rem 0"}} onMouseOver={(e) => {
          e.target.style.backgroundColor = '#FD2A80';
          e.target.style.cursor="pointer"
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = '#f2f2f2';
        }}onClick={logout}>
          Logout
        </div>

      
    </div>
  );

  return (
    <>
      <nav className="logistic-navbar-component">
        <AppBar position="fixed" style={{background: "white" }}>
     
          <Toolbar >
            <IconButton onClick={toggleSlider}>
              <Menu />
            </IconButton>
            <Drawer open={open} anchor="left" onClose={toggleSlider}>
              {sideList()}
            </Drawer>
          </Toolbar>
          <Avatar
            style={{
              position: "absolute",
              right: "1rem",
              color: "black",
              top: "1.3rem",
              width: "30px",
              height: "30px",
            }}
            
          />
        </AppBar>
        <div className="logistic-orders">{props.children}</div>
        {/* <Footer color="seller-footer-color" /> */}
      </nav>
    </>
  );
}
