import React from 'react';
import '../styles/ReturnRefundPolicy.css'; // Import your styles or use inline styles
import CustNavbar from './CustNavbar';
import CustomerFooter from './CustomerFooter';

const ReturnRefundPolicy = () => {
  return (
    <>
    <CustNavbar />
    <div className="return-refund-policy">
           <h2>Return and Refund Policy for Buyers</h2>
      <p>
        Thank you for choosing to shop at MyhraKi, our e-commerce boutique platform. We want to ensure that you have a satisfactory shopping experience, and to that end, we have established this Return and Refund Policy for buyers. This policy outlines the terms and conditions for returns and refunds on purchases made through MyhraKi. By making a purchase on our platform, you acknowledge and agree to this policy.
      </p>

      <h3>1. Returns</h3>
      <p>
        <strong>1.1. Eligibility:</strong> As a buyer, you have the right to return eligible products within 5 days from the date of delivery. To be eligible for a return, the product must be unused, in its original packaging, and in the same condition as when you received it.
      </p>

      <p>
        <strong>1.2. Non-eligible items:</strong> The following items are not eligible for returns:
        <ul>
          <li>Perishable goods, such as food items or flowers</li>
          <li>Personalized or customized products</li>
          <li>Intimate or sanitary goods, such as undergarments or cosmetics (for hygiene reasons)</li>
          <li>Gift cards or vouchers</li>
        </ul>
      </p>

      <p>
        <strong>1.3. Return Process:</strong> To initiate a return, please contact our customer support team at 1800 203 1217. Provide them with your order details, including the order number and the reason for the return. Our team will guide you through the return process and provide you with the necessary instructions.
      </p>

      <p>
        <strong>1.4. Return Shipping:</strong> The cost of return shipping is the responsibility of the buyer unless the return is due to an error on our part or a defective product. We recommend using a trackable shipping method to ensure the safe and timely return of the product.
      </p>

      <p>
        <strong>1.5. Refunds:</strong> Once we receive and inspect the returned product, we will notify you of the status of your refund. If the return is approved, we will initiate a refund to your original payment method within 5-7 working days. Please note that the refund amount may be subject to deductions for return shipping costs or restocking fees, if applicable.
      </p>

      <h3>2. Refunds</h3>
      <p>
        <strong>2.1. Faulty or Damaged Products:</strong> 
        If you receive a faulty or damaged product, please contact our customer support team within 5 days of delivery. Provide them with the necessary details and any supporting evidence, such as photographs. We will assess the issue and, if eligible, arrange for a replacement or refund.      </p>

      <p>
        <strong>2.2. Lost Packages:</strong> 
        In the event that your package is lost during transit and the tracking information does not indicate delivery, please reach out to our customer support team. We will work with the shipping carrier to investigate the issue. If the package is confirmed lost, we will either send a replacement or provide a refund.       </p>

      <p>
        <strong>2.3. Refund Processing:</strong> Refunds will be processed back to the original payment method used for the purchase. The time it takes for the refund to reflect in your account may vary depending on your payment provider. 
      </p>

    <h3>Contact Us </h3>
       <p>If you have any questions or concerns regarding our Return and Refund Policy, please contact us at 1800 203 1217. Our customer support team will be happy to assist you and provide further clarification.</p>
      <p>
      Please note that this Return and Refund Policy is subject to change without prior notice. It is your responsibility to review this policy periodically for any updates or modifications.      </p>
  <h3>Additional Note</h3>
  
  <p> Verification of Product Condition Upon Delivery In addition to our standard shipping procedures, we require customers to provide video proof of the product upon delivery. This step ensures that any potential defects or damages can be promptly addressed. Please have your camera ready to record the unboxing process and inspect the product upon receipt. If you notice any issues or discrepancies, kindly reach out to our customer support team with the video evidence for further assistance. Thank you for your cooperation in ensuring a smooth and satisfactory shopping experience with Myhraki.</p>
    </div>
    <CustomerFooter />
    </>
  );
};

export default ReturnRefundPolicy;
