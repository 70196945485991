import React from "react";
import  "../styles/Common.css"
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from '@mui/icons-material/Logout';
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import MailIcon from "@mui/icons-material/Mail";
// import AdminFooter from "./AdminFooter";
import mainlogo from "../Images/MainLogo.png";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { ChatsCircle } from '@phosphor-icons/react';
import { Avatar } from "@mui/material";
import {
  Apps,
  AssignmentInd,
  
} from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { green } from "@mui/material/colors";
import { useContext } from "react";

import { AddressBook ,HandCoins} from '@phosphor-icons/react'
import { SellerContext } from "../context/SellerContext";
import ToastComponent from "./ToastComponent";
import { useRef } from "react";
// import { useEffect } from "react";
// import Home from "../Images/DashboardIcon.png"
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: "rgba(244, 244, 244, 1) !important",
  border:"none",
// color: "rgba(0, 0, 0, 0.6)",
color:green,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  backgroundColor: "rgba(244, 244, 244, 1) !important",

  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
 


  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // marginLeft: drawerWidth,
    width: `calc(100% - ${0}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const listItems = [
  // {
  //   listIcon: <DashboardIcon />,
  //   listText: "Dashboard",
  //   listNav: "/SellerDashboard",
  // },
 
  {
    listIcon: <AssignmentInd />,
    listText: "Products",
    listNav: "/SellerCategoriesListingPage",
    view:true
  },
  {
    listIcon: <Apps />,
    listText: "Order",
    listNav: "/SellerOrders",
    view:true
  },
  {
    listIcon: <Diversity3Icon />,
    listText: "User",
    listNav: "/sellerManagement",
  },
  // {
  //   listIcon: <BarChartIcon />,
  //   listText: "Discount",
  //   listNav: "/SellerDiscounts",
  // },
  {
    listIcon: <ChatsCircle style={{fontSize:"25px"}} />,
    listText: "Facts",
    listNav: "/SellerFacts",
    view:true
  },
  {
    listIcon: <ManageAccountsIcon />,
    listText: "Account",
    listNav: "/SellerAccount",
  },
  {
    listIcon: <AddressBook style={{fontSize:"25px"}} />,
    // listIcon: <ManageAccountsIcon style={{fontSize:"25px"}} />,
    listText: "Warehouses",
    listNav: "/SellerAddress",
  },
  {
    listIcon: <HandCoins style={{fontSize:"25px"}} />,
    // listIcon: <ManageAccountsIcon style={{fontSize:"25px"}} />,
    listText: "Subscriptions",
    listNav: "/SubscriptionPlans",
  }
];

export default function SellerLayout2({ children }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const toastRef = useRef();



  const { selectedListItemIndex,sellerData, setSelectedListItemIndex, logout, sellerPersonalData } =
    useContext(SellerContext);
// useEffect(()=>{
//   //console.log(selectedListItemIndex)
// },[selectedListItemIndex])

    const handleListItemClick = (index) => {
      if(sellerPersonalData?.result?.status!=='C'){
        // setOpen(!open);
        toastRef.current.showToast(`Please complete your onboarding to access ${listItems[index].listText} and other features `, "warning");
        return;
      }
      setSelectedListItemIndex(index);
      //console.log(listItems[index].listNav);
      navigate(listItems[index].listNav);
    };

  return (<>
                       <ToastComponent ref={toastRef} timeout={4000} />

    <Box sx={{ display: "flex" }} className="seller-navbar-sidebar">
      <CssBaseline />
     
      <AppBar position="fixed" open={open} elevation={0} style={{background:"red !important"}}>
        <Toolbar style={{background:"red !important"}}> 
     
          <IconButton
            color="black"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 0,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            color="black"
            aria-label="open drawer"
            onClick={handleDrawerClose}
                        edge="start"
            sx={{
              marginRight: 0,
              ...(!open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <img loading="lazy" src={mainlogo} alt="" className="Myhraki-logo-nav-bar"/> 

        </Toolbar>
        
       <Avatar style={{position:"absolute", right:"1rem", color:"white", top:"1.3rem",width:"30px",height:"30px", background: "linear-gradient(90deg, #FA068A 0%, #FE505F 100%)"}} />
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} color="red">
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              open&&<ChevronLeftIcon 
            />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
       
        <List>
     
          {listItems.map((listItem, index) => {
            return(<div>
            {(sellerData?.result?.typeOfUser==="SLADM"|| listItem?.view===true)&&<ListItem onClick={() => {handleListItemClick(index) }} key={index} disablePadding sx={{ display: "block", backgroundColor:selectedListItemIndex===index?"#ff7891":"#f2f2f2", borderRadius:"0px", minHeight:"3rem" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {listItem.listIcon}
                </ListItemIcon>
                <ListItemText primary={listItem.listText} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>}
            </div>)
          }
          )}
        </List>
        <div style={{ display: "flex",  alignItems: "center",   justifyContent: "center",    position: "absolute",    bottom: "2rem",    width: "99%",    fontSize: "16px",    textAlign: "center", minHeight: "30px", padding: "1rem 0" }}
  onMouseOver={(e) => {
    e.target.style.backgroundColor = "#FE3C72";
    e.target.style.color = "white";
    e.target.style.borderRadius ="0px 8px 8px 0px"
    e.target.style.cursor = "pointer";
  }}
  onMouseLeave={(e) => {
    e.target.style.backgroundColor = "#f2f2f2";
    e.target.style.color = "rgba(0, 0, 0, 0.7)";
  }}
  onClick={logout}
>
  <LogoutIcon style={{ marginRight: "0.5rem" }} />
  Logout
</div>
        
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, }}>
        <DrawerHeader />
        <div className="Seller-layout">
          {children}
          <div style={{minHeight:'200px'}}></div>

          </div>
        {/* <Footer color="seller-footer-color" /> */}
      </Box>
    </Box>
    </>

  );
}
