import { useEffect } from 'react';

export default function useNavigationTracking() {
    const handleRouteChange = () => {
      const currentPage = window.location.pathname;
      localStorage.setItem('currentPage', currentPage);
    };
  
    useEffect(() => {
      handleRouteChange(); 
    }, [window.location.pathname]);
  } 
  
