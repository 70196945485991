import React, { useRef } from "react";
import "../styles/OtpVerify.css";
import OtpInput from "react-otp-input";
import { useState, useEffect } from "react";  
import Logo from "../Images/MainLogo.png";
import "../styles/Common.css";
import { API_BASE_URL } from '../App'

import "../styles/SellerOtpVerify.css";
import { SellerContext } from "../context/SellerContext";
import { useContext } from "react";
import ThankYouShopping from "../Images/thankYouScreenImg.png"
import CardComponent from "../components/CardComponent";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import ToastComponent from "../components/ToastComponent";
export default function SellerOtpVerifyLogin() {

  const { isSellerAdmin, setPhoneNumberForOtp, phoneNumberForOtp, loginWithOtp } = useContext(SellerContext);
  const [loading, setLoading] = useState(false);
  const [resendOtpMessage, setResendOtpMessage] = useState(false);
  const [invalidOTP, setInvalidOTP] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const toastRef = useRef();
  const [timeLeft, setTimeLeft] = useState(180);
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    setEnabled(false);
    const timer = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    if (timeLeft === 0) {
      clearTimeout(timer);
      setEnabled(true);
    }
    return () => clearTimeout(timer);
  }, [timeLeft]);

  //   useEffect(()=>{
  // if (timeLeft===0){
  //   setEnabled(true)
  // }
  // else{
  //   setEnabled(false)
  // }
  //   },[timeLeft])

  // const [otp] = useState("560068");
  async function verifyOtp() {
    setInvalidOTP(false);
    setLoading(true);
    const response = await loginWithOtp(phoneNumberForOtp.trim()
    , code, isSellerAdmin);
    setResendOtpMessage(false);

    if (response?.result?.accessToken) {
      setInvalidOTP(false);
      setCode();
      setLoading(false);
      // toastRef.current.showToast("Login successful", "success");
      // navigate('/SellerCategoriesListingPage')
    } else if (response?.message) {
      setInvalidOTP(true);
      // toastRef.current.showToast("Invalid OTP", "error");
      setLoading(false);
    } else {
      setLoading(false);
      setInvalidOTP(false);

      toastRef.current.showToast("Something went wrong. Please try later", "error");
    }
  }

  const resendOtp = async () => {
    setResendLoading(true);

    try {
      let response = await fetch(
        `${API_BASE_URL}/users/api/v1/otp?mobileNumber=${phoneNumberForOtp.trim()}&userType=${isSellerAdmin ? 3 : 4}`,
        {
          headers: {
            // Accept: "application.json",
            // "Content-type": "application.json",
          },

        }
      );
      const result = await response.json();
      //console.log(result);

      if (result?.result > 0) {

        setInvalidOTP(false);
        setResendOtpMessage(true);
        setPhoneNumberForOtp(phoneNumberForOtp.trim());
        setCode();
        setTimeLeft(180);
        setResendLoading(false);

      } else {
        setResendLoading(false);
        toastRef.current.showToast("Maximum otps sent. contact Admin for more", "error");

      }

    } catch (e) {
      setInvalidOTP(false);
      setLoading(false);
      toastRef.current.showToast("Something went wrong. Try again later", "error");
      setResendLoading(false);
    }
  };

  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  // const [resend, setResend] = useState(false);
  const navigate = useNavigate();
  const handleChange = (code) => setCode(code);
  const gotoLoginPage = () => {
    navigate("/");
  };


  return (
    <div>
               <ToastComponent ref={toastRef} timeout={4000} />

      <CardComponent
        cardImg={<img loading="lazy" className='reset-password-left-img' src={ThankYouShopping} alt='shopping' />}
        imageFirst={true}
      >
        <div className="seller-otp-vrify-rightScreen">
          <div className="seller-otp-verify-right-content">
            <div className="OtpVerification-Myhraki-logo">
              <img loading="lazy" src={Logo} alt="title" className="Myhraki-logo" />
            </div>
            <div className="OtpVerification-title">We sent you an OTP</div>
            {invalidOTP && <div style={{ backgroundColor: 'lightpink', color: 'maroon', padding: '8px 24px 8px 24px', width: '30%', margin: '0 auto', fontSize: '12px', borderRadius: '10px', textAlign: 'center', position: 'relative', bottom: '15px' }} >Invalid OTP</div>}
            {resendOtpMessage && <div style={{ backgroundColor: 'rgba(2, 159, 74, 1)', color: 'white', padding: '8px 24px 8px 24px', width: '30%', margin: '0 auto', fontSize: '12px', borderRadius: '10px', textAlign: 'center', position: 'relative', bottom: '15px' }} > OTP has been Resent</div>}

            <div className="otp-numbers">
              <OtpInput
                value={code}
                onChange={handleChange}
                numInputs={4}
                separator={<span style={{ width: "8px" }}></span>}
                isInputNum={true}
                shouldAutoFocus={true}
                className="otp-verify-otp-verify"
                inputStyle={{
                  border: "1px solid #000",
                  // borderRight: "none",
                  // borderLeft: "none",
                  // borderTop: "none",
                  width: "2rem",
                  height: "40px",
                  padding: "20px , 0px",
                  fontSize: "16px",
                  color: "#000",
                  borderRadius: "8px",
                  //   fontWeight: "400",
                  // caretColor: "blue"
                }}
                focusStyle={{
                  border: "2px solid #029F4A",
                  // borderRight: "none",
                  // borderLeft: "none",
                  // borderTop: "none",
                  outline: "none",
                }}
              />
            </div>
            {error && (
              <div className="otp-verify-error">Please enter a valid OTP</div>
            )}

            {!loading && <button className="OtpVerify-button-seller" onClick={verifyOtp}>
              Verify
            </button>}
            {loading && <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "pink" }} /></div>}
            <div className="otp-verify-resend-otp">
              <div>
                <span>Didn't receive the OTP?</span>
              </div>
              {!enabled && (
                <div
                  className="otp-verify-resend-otp-button"
                  style={{ color: "black" }}
                >
                  Resend
                </div>
              )}

              {enabled && !resendLoading && (
                <div className="otp-verify-resend-otp-button" onClick={resendOtp}>
                  Resend OTP
                </div>
              )}
              {enabled && resendLoading && <div style={{ display: "flex", width: "10%", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "pink" }} /></div>}


              <div>

                {timeLeft !== 0 && <div>{timeLeft}</div>}
              </div>
            </div>
            <div className="seller-reset-login" >
              <span onClick={gotoLoginPage}>Return to Login</span>
            </div>

          </div>
        </div>
      </CardComponent>
    </div>

  );
}
