const productData = [
  {
    productId:0,
    title: "Miss Chase Womens Apparel",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/",
    imagesUriList:[{
      thumbnailURI: 'https://picsum.photos/id/1018/1000/600/',
      uri: 'https://picsum.photos/id/1018/250/150/',
      },
      {
        thumbnailURI: 'https://picsum.photos/id/1015/1000/600/',
        uri: 'https://picsum.photos/id/1015/250/150/',
      },
      {
        thumbnailURI: 'https://picsum.photos/id/1019/1000/600/',
        uri: 'https://picsum.photos/id/1019/250/150/',
      },
      {
        thumbnailURI: 'https://picsum.photos/id/1015/1000/600/',
        uri: 'https://picsum.photos/id/1015/250/150/',
      },
      {
        thumbnailURI: 'https://picsum.photos/id/1019/1000/600/',
        uri: 'https://picsum.photos/id/1019/250/150/',
      },]
  },
  {
    productId:1,
    title: "Miss Chase Women Apparel",
    productStarRating: "3",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],
    productRatingOverall:3.5,
    totalRatings:1000,
    productRating: [
      {
        five: 600,
        four: 330,
        three: 60,
        two: 60,
        one: 30,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
   {
    productId:2,

    title: "Miss Chase Women Apparel",
    productStarRating: "4",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:3,
    title: "Miss Chase Women Apparel",
    productStarRating: "5",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:4,

    title: "Miss Chase Women Apparel",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:5,

    title: "Miss Chase Women Apparel",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:6,

    title: "Miss Chase Women Apparel",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:7,

    title: "Miss Chase Women Apparel",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:8,

    title: "Miss Chase Women Apparel",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:9,

    title: "Miss Chase Women Apparel",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:10,

    title: "Miss Chase Women Apparel",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:11,

    title: "Miss Chase Women Apparel",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:8,

    title: "ID=8",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:9,

    title: "ID=9",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:10,

    title: "ID=11",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:11,

    title: "ID=11",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:12,

    title: "ID=8",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:13,

    title: "ID=9",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:14,

    title: "ID=11",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:15,

    title: "ID=11",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:16,

    title: "ID=8",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:17,

    title: "ID=9",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:18,

    title: "ID=11",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:19,

    title: "ID=11",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:20,

    title: "ID=8",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:21,

    title: "ID=9",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:22,

    title: "ID=11",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:23,

    title: "ID=11",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:24,

    title: "ID=8",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:25,

    title: "ID=9",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:26,

    title: "ID=11",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
  {
    productId:27,

    title: "ID=11",
    productStarRating: "4.8",
    productReviewCount: "109",
    productSellingPrice: "2100",
    productActualPrice: "3000",
    productDiscount: "30",
    description: [
      "Care Instructions: Hand Wash Only",
      "Fit Type: Regular Fit",
      "Fabric: 100% Polyester | Care Label/ Instructions: Hand wash with warm water, wash dark colors separately, do not bleach, dip dry away from direct heat, warm iron only.",
      "Fit: Relaxed Fit | Length: Maxi | Pattern: Floral | Knit/ Woven: Woven | Hemline: Straight | Lining: No | Style: Maxi | Neck Type: Round Neck | Sleeves: 3/4 Sleeves.",
      "Occasion: Casual wear, day wear, daily wear.",
      "The model is 5'9' and she is wearing size S.",
      "There might be slight color variation between the actual product and image shown on the screen due to photoshoot lighting.",
    ],    
    productRatingOverall:3.5,
    totalRatings:500,
    productRating: [
      {
        five: 300,
        four: 165,
        three: 30,
        two: 30,
        one: 15,
      },
    ],
    imageList:"https://picsum.photos/id/1015/250/150/"
  },
];

export default productData;
