import React, { useContext, useEffect, useState } from 'react'
import AdminLayoutAdjustment from '../components/AdminLayoutAdjustment'
import { AdminContext } from '../context/AdminContext';
import "../styles/Common.css"
import { successAlert } from '../components/Alert';
import { API_BASE_URL } from '../App'

function ReviewApproval() {
    // const [unapprovedReviews, setUnapprovedReviews] = useState([]);
    const [reviewsData, setReviewsData] = useState([]);
  const { accessToken } = useContext(AdminContext);
  const fetchUnapprovedReviews = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/feedback/api/v1/Get?Approval=${false}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
  
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
  
      const data = await response.json();
      setReviewsData(data.result);
    } catch (error) {
      console.error("Unable to fetch unapproved reviews:", error.message);
    }
  };
  
  useEffect(() => {
    fetchUnapprovedReviews();
  }, []);
  const handleApprove = async (reviewId) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/feedback/api/v1/update?reviewId=${reviewId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json-patch+json",
          },
          body: JSON.stringify({ approval: true }),
        }
      );
  
      if (response.ok) {
        
        localStorage.setItem(`review_${reviewId}_approved`, "true");
        setReviewsData((prevData) =>
          prevData.map((review) =>
            review.id === reviewId ? { ...review, approved: true } : review
          )
        );
        
        successAlert("", "Review Approved Successfully");
        fetchUnapprovedReviews();
      } else {
        const errorText = await response.text();
        throw new Error(errorText || "Failed to approve review");
      }
    } catch (error) {
      console.error("Failed to Approve review:", error.message);
    }
  };
  
   
      const handleReject = async (id) => {
        try {
            const response = await fetch(
              `${API_BASE_URL}/feedback/api/v1/${id}`,
              {
                method: "DELETE",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );
        
            if (response.ok) {
                // Call fetchUnapprovedReviews again after successful approval
                await fetchUnapprovedReviews();
                successAlert('', "Review Deleted Successfully");
              } else {
                const errorText = await response.text();
                throw new Error(errorText || "Failed to approve review");
              }
            } catch (error) {
              console.error("Error deleting review:", error);
            }
      };

  return (
<AdminLayoutAdjustment>
<div className="SellerDiscount-table">
<table className="admin-categories-table">
      <thead className="seller-discount-thead">
          <tr>
            <th>Product ID</th>
            <th>User ID</th>
            <th>Rating</th>
            <th>Content</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {reviewsData.map((review) => (
            <tr key={review.id}>
              <td data-th="productId">{review.productId}</td>
              <td  data-th="userId">{review.userId}</td>
              <td  data-th="rating">{review.rating}</td>
              <td  data-th="content">{review.content}</td>
              <td  data-th="date">{review.date}</td>
              <td data-th="status">
              <div className="fact-approve-buttons">
              <div className='approve-tick'>
              <button className="common-btn" onClick={() => handleApprove(review.id)}>Approve</button>
            
              {review.approved || localStorage.getItem(`review_${review.id}_approved`) === "true" ? (
             < span className="green-tick">✔️</span>
              ) : null}
              </div>
                <button className="Reject-button-facts" onClick={() => handleReject(review.id)}>Reject</button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
</AdminLayoutAdjustment>
  )
}

export default ReviewApproval