import React, { useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import CustomerLayout from "../components/CustomerLayout";
import CustomerHeading from "../components/CustomerHeading";
import ThankyouGiftCard from "../Images/Thankyou.png";
import Festival from "../Images/Fesitival.png";
import congratsGiftCard from "../Images/Congratulation.png";
import HappybirthdayCard from "../Images/HappyBirthday.png";
import Logo from "../Images/MyhrakiLogoC.png";
import "../styles/CustomerGiftCard.css";

const CustomerGiftCard = () => {
  const navigate = useNavigate();

  const handleImageClick = (id) => {
    console.log(id);
    navigate(`/giftCardDetail/${id}`);
  };

  const giftCardList = [
    {
      idx: 1,
      image: ThankyouGiftCard,
      description: "Thank You",
    },
    {
      idx: 2,
      image: HappybirthdayCard,
      description: "Happy Birthday!!",
    },
    {
      idx: 3,
      image: Festival,
      description: "Happy Fest",
    },
    {
      idx: 4,
      image: congratsGiftCard,
      description: "Congrats",
    },
  ];

  return (
    <CustomerLayout>
      <CustomerHeading />
      <div className="gc-mainCardContainer">
        <div className="gc-Heading-container">
          <img src={Logo} className="Logo-image" />
          <span className="Logo-text">Pay Gift Card</span>
        </div>
        <div className="gc-Text-container">
          Gift your loved once with special message
        </div> 
        <div className="cardcontainer1">
          {giftCardList?.map((card) => {
            console.log(card);
            console.log(card.idx, "CardID");
            return (
              <img
                key={card.idx}
                src={card.image}
                alt="Thank you gift card"
                id="Card-container2"
                image={card.image}
                description={card.description}
                onClick={() => handleImageClick(card.idx)}
              />
            );
          })}
        </div>
      </div>
    </CustomerLayout>
  );
};

export default CustomerGiftCard;

// useEffect(() => {
//   const fetchImageData = async () => {
//     try {
//       const response = await fetch(`https://dev-api.myhraki.com/web/coupons/api/v1/GetGiftCardImage?Category=1`);
//       // const response = await fetch(`https://dev-api.myhraki.com/web/coupons/api/v1/GetGiftCardImage?Category=${id}`);
//       const data = await response.json();
//       const imageArray = data.map(id => id.giftcardImageUrl);
//       setImage(imageArray);
//     } catch (error) {
//       console.error('Error fetching images:', error);
//     }
//   };
//   fetchImageData();
// }, []);

{
  /* <div id='Card-container1'>
            <Link to='./giftCardDetail2' ><img src={HappybirthdayCard} alt="Happy Birthday gift card" id='Card-container2' onClick={handleImageClick} /></Link> 
            </div>
            <div id='Card-container1'>
                <Link to='./giftCardDetail3'><img src={Festival} alt="Festival gift card" id='Card-container2' onClick={handleImageClick}/></Link>
            </div>
            <div id='Card-container1'>
                <Link to='./giftCardDetail4'><img src={congratsGiftCard} alt="Congrats gift card" id='Card-container2' onClick={handleImageClick}/></Link>
            </div> */
}
