import React, { useEffect, useState } from "react";
import "../styles/InviteSeller2.css";
import { nanoid } from "nanoid";
import { TextField } from "@mui/material";
// import { useFormik } from "formik";
// import * as yup from "yup";
// import data from "../Data";
// import data from "../dummyData.json";
// import Edit from "@mui/icons-material/Edit";
// import DeleteTwoTone from "@mui/icons-material/DeleteTwoTone";
import { Delete, EditTwoTone, SaveTwoTone } from "@mui/icons-material";
import { Copy} from '@phosphor-icons/react'
import ClearIcon from "@mui/icons-material/Clear";
// import { AuthContext } from "../Context/AuthStore";
// import { useAdminAuth } from "../Context/adminAuth";
// import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import Heading from "../components/Heading";
import AdminLayoutAdjustment from "../components/AdminLayoutAdjustment";
import { AdminContext } from "../context/AdminContext";
import { useContext } from "react";
import { API_BASE_URL } from "../App";
import { CircularProgress } from "@mui/material";
import { errorAlert, successAlert } from "../components/Alert";
import { useNavigate } from "react-router-dom";
import ToastComponent from "../components/ToastComponent";

  function InviteSeller2() {


    const navigate = useNavigate();
    const [getLinkLayout, setGetLinkLayout] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [linkResult, setLinkResult] = useState(null);
  const [error, setError] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  // const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const toastRef = React.useRef();
  const [addFormData, setAddFormData] = useState({
    invite_sellerName: "",
    invite_sellerPhoneNumber: "",
    invite_sellerEmail: "",
  });
  // ////console.log(contacts);
  const [editFormData, setEditFormData] = useState({
    invite_sellerName: "",
    invite_sellerPhoneNumber: "",
    invite_sellerEmail: "",
  });


  const {accessToken, handleAuthExpiry, logout } = useContext(AdminContext);
  

  useEffect(()=>{

  },[contacts])

  const [editContactId, setEditContactId] = useState(null);
   const formik = useFormik({
    initialValues: {
      mobile: '',
    },
    onSubmit: values => {
      handleGetLink(formik.values)
    },
  });

  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = (event.target.value).trim();

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    setAddFormData(newFormData); 
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    if (
      addFormData.invite_sellerName.match(fullNameRegExp) &&
      addFormData.invite_sellerPhoneNumber.match(phoneRegExp) &&
      addFormData.invite_sellerEmail.match(emailRegExp)
    ) {
      const newContact = {
        id: nanoid(),
        invite_sellerName: addFormData.invite_sellerName,
        invite_sellerPhoneNumber: addFormData.invite_sellerPhoneNumber,
        invite_sellerEmail: addFormData.invite_sellerEmail,
      };

      setAddFormData({
        invite_sellerName: "",
        invite_sellerPhoneNumber: "",
        invite_sellerEmail: "",
      });

      document.getElementById("invite_sellerName").value = "";
      document.getElementById("invite_sellerEmail").value = "";
      document.getElementById("invite_sellerPhoneNumber").value = "";

      const newContacts = [...contacts, newContact];
      setContacts(newContacts);
      setNameError(false);
      setEmailError(false);
      setPhoneError(false);
    } else {
      !addFormData.invite_sellerName.match(fullNameRegExp) &&
        setNameError(true);
        !addFormData.invite_sellerEmail.match(emailRegExp)&&
        setEmailError(true);
        !addFormData.invite_sellerPhoneNumber.match(phoneRegExp) &&
        setPhoneError(true);
    }
  };


  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };

  const handleEditClick = (event, contact) => {
    event.preventDefault();
    // event.stopImmediatePropagation()
    setEditContactId(contact.id);

    const formValues = {
      invite_sellerName: contact.invite_sellerName,
      invite_sellerPhoneNumber: contact.invite_sellerPhoneNumber,
      invite_sellerEmail: contact.invite_sellerEmail,
    };

    setEditFormData(formValues);
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const editedContact = {
      id: editContactId,
      invite_sellerName: editFormData.invite_sellerName,
      invite_sellerPhoneNumber: editFormData.invite_sellerPhoneNumber,
      invite_sellerEmail: editFormData.invite_sellerEmail,
    };

    const newContacts = [...contacts];

    const index = contacts.findIndex((contact) => contact.id === editContactId);

    newContacts[index] = editedContact;
    ////console.log(typeof(newContacts[index].invite_sellerName))
    if(fullNameRegExp.test(newContacts[index].invite_sellerName) && phoneRegExp.test(newContacts[index].invite_sellerPhoneNumber) && emailRegExp.test(newContacts[index].invite_sellerEmail)){
      setContacts(newContacts);
      setEditContactId(null);
}
    else{
      errorAlert('',"Please check the data before Submitting");
      toastRef.current.showToast("Password Update failed", "error");
    }
      
  };

  const handleCancelClick = () => {
    setEditContactId(null);
  };

  const handleDeleteClick = (contactId) => {
    const newContacts = [...contacts];

    const index = contacts.findIndex((contact) => contact.id === contactId);

    newContacts.splice(index, 1);

    setContacts(newContacts);
  };

  const phoneRegExp = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;
  const fullNameRegExp = /^(?!.\s{2})(?!.[.'-]{2})[A-Z][A-Za-z' ]{0,48}[A-Za-z]$/;
  const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

  // ////console.log(contacts);




  async function sendInvites(i) {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/seller/api/v1/Sellerinvatation`,
        {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            "Name": i.invite_sellerName,
            "Phone": `+91${i.invite_sellerPhoneNumber}`,
            "Email": i.invite_sellerEmail,
          }),
        }
      );
      const data = await response.json(); 
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        errorAlert('', 'Your Session has expired');
        logout();
        return;
      } 
      setLoading(false);
      if(data?.status===401){
        // errorAlert('Session timed out. Please Log in');
        toastRef.current.showToast("Session timed out. Please Log in", "error");
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate('/')
      } 
      ////console.log( data);
  
      // Delay the execution of the function by 1 second
      await new Promise(resolve => setTimeout(resolve, 1000));
        return data;
    } catch (e) {
      ////console.log(e);
      setLoading(false);
    }
  }
  
  const postInvites = async () => {
    let unsentRequests = false;
    const processedContacts = []; // Create a new array to store the processed contacts
    for (let i = 0; i < contacts.length; i++) {
      const data = await sendInvites(contacts[i]);
      ////console.log(data.result)
      if (data.result === true) {
        setContacts(contacts.filter((contact) => {
          return contact.id !== contacts[i].id;
        }));
         // Add the processed contact to the new array
      } else {
        unsentRequests = true;
        processedContacts.push(contacts[i]);
        ////console.log("Invite sending failed for contact:", contacts[i]);
      }
    }
  
    setContacts(processedContacts); // Update the state with the new array
    if (unsentRequests) {
      alert("Some requests couldn't be sent. Please check.");
    }
  };
  
  const handleGetLink = (e) => {
    setPhoneNumber(e.target.value);
  };
  const handleCopyLink = () => {
    // You can use the Clipboard API or a library like clipboard.js to copy the link to the clipboard
    // Here's a simple example using the Clipboard API
    navigator.clipboard.writeText(linkResult);
    successAlert('Link copied to clipboard!');
  };

  const GetLink = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/seller/api/v1/GetLink?phoneNumber=${encodeURIComponent(formik.values.mobile)}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          "Authorization": `Bearer ${accessToken}`,
        },
      });
  
      if (response.ok) {
        const resultData = await response.json();
        const resultLink = resultData.result.result;
        var parts = resultData?.result?.result?.split('/'); 
        var extractedValue = parts[parts.length - 1];
        await postRejectData(extractedValue);
        setLinkResult(resultLink);
        setError(null);
      } else {
        const errorData = await response.json();
        setLinkResult(null);
        setError(errorData.message || 'An error occurred.');
      }
    } catch (error) {
      setLinkResult(null);
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  

  async function postRejectData(id) {
    setLoading(true);
    try {
      await fetch(
        `${API_BASE_URL}/seller/api/v1/RejectBySeller`,
        {
          method: "put",

          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            sellerId: id,
          }),
        }
      );
      setLoading(false);
      // navigate("/sellerInviteReject");

    } catch (e) {
      //console.log(e);
      setLoading(false);

    }
  }



  return (
    <AdminLayoutAdjustment>
            <ToastComponent ref={toastRef} timeout={4000} />
            <Heading text="Invite Sellers" />
      <div className="invite-seller-container">
      
        {/* <div className="get-link-invite" onClick={() => setGetLinkLayout(!getLinkLayout)}>Get Link</div> */}
       
        {getLinkLayout && (
        <form className="invite-form-card"  onSubmit={formik.handleSubmit}>
           <div className="invite-form-card-input-field">
            <TextField
              autoFocus
              autoComplete="off"
              variant="outlined"
              fullWidth
              type="text"
              id="invite_sellerName"
              name="mobile"
              className="invite-seller-card-seller-field"
              placeholder="Enter Mobile Number"
              // onChange={handleGetLink}
              onChange={formik.handleChange}
              value={formik.values.mobile}
            />
            {!loading &&<button className="common-button invite-seller-button" onClick={GetLink}>
            Get Link
          </button>}
{loading&&    
   <div style={{display:"flex", width:"100%",alignItems:"center",justifyContent:"center"}}>
    <CircularProgress style={{color:"pink" }} /></div>
}</div>
{linkResult && (
        <div className="InviteSellerLink-container">
          <div>{linkResult}</div>
          <Copy size={50}  onClick={handleCopyLink}/>
          
        </div>
      )}
      {error && <div style={{ color: '#d32f2f' }}>{error}</div>}
        </form>
      )}
        <form className="invite-form-card" onSubmit={handleAddFormSubmit}>
          <div className="invite-form-card-field">
            <div className="invite-form-card-field-name">Seller Name* :</div>
            <div className="invite-form-card-input-field">
            <TextField
              autoFocus
              autoComplete="off"
              variant="outlined"
              fullWidth
              type="text"
              id="invite_sellerName"
              name="invite_sellerName"
              className="invite-seller-card-seller-field"
              placeholder="Enter Full Name"
              onChange={handleAddFormChange}
            />
          {nameError && (
            <div className="invite-seller-error-message">Name is invalid.</div>
          )}
                      </div>

          </div>
          
          <div className="invite-form-card-field">
            <div className="invite-form-card-field-name">
             Phone Number* :
            </div>
            <div className="invite-form-card-input-field">

            <TextField
              autoComplete="off"
              variant="outlined"
              fullWidth
              type="text"
              id="invite_sellerPhoneNumber"
              name="invite_sellerPhoneNumber"
              className="invite-seller-card-seller-field"
              placeholder="Enter Phone Number "
              onChange={handleAddFormChange}
            />
          {phoneError && (
            <div className="invite-seller-error-message">
              Phone Number is invalid.
            </div>
          )}
                      </div>

          </div>

          <div className="invite-form-card-field">
            <div className="invite-form-card-field-name">Seller Email Id* :</div>
            <div className="invite-form-card-input-field">
            <TextField
              autoComplete="off"
              variant="outlined"
              fullWidth
              type="text"
              id="invite_sellerEmail"
              name="invite_sellerEmail"
              className="invite-seller-card-seller-field"
              placeholder="Enter Email Id"
              onChange={handleAddFormChange}
            />
          {emailError && (
            <div className="invite-seller-error-message">
              Email is invalid.
            </div>
          )}
            </div>
          </div>
          <div className="invite-get-button-field">
          <button type="submit" className="common-button invite-seller-button">
            Add New
          </button>
          <div className="get-link-invite" onClick={() => setGetLinkLayout(!getLinkLayout)}>Get Link</div>
          </div>
        </form>

        {contacts.length>0&&<form>
          <table class="admin-categories-table">
            <thead >
              <tr>
              {/* <th className="invite-table-head">#</th> */}
                <th >Name</th>
                <th > Phone Number</th>
                <th > Email Id</th>
                <th >Actions</th>
              </tr>
            </thead>
            <tbody >
              {contacts&& contacts.map((contact) => (
                <>
                  {editContactId === contact.id ? (
                    <EditableRow
                      editFormData={editFormData}
                      handleEditFormChange={handleEditFormChange}
                      handleCancelClick={handleCancelClick}
                      handleEditFormSubmit={handleEditFormSubmit}
                    />
                  ) : (
                    <ReadOnlyRow
                      contact={contact}
                      handleEditClick={handleEditClick}
                      handleDeleteClick={handleDeleteClick}
                    />
                  )}
                </>
              ))}
            </tbody>
          </table>
        </form>}
        {contacts.length>0&&<div className="inviteSeller-Invite-Button-Field">
          {!loading &&<button className="common-button inviteSeller" onClick={postInvites}>
            Invite
          </button>}
{loading&&<div>Sending...</div>}
        </div>}
       
       
      </div>
    </AdminLayoutAdjustment>
  );
}

export default InviteSeller2


const ReadOnlyRow = ({ contact, handleEditClick, handleDeleteClick }) => {
  return (
    <tr >
      <td data-th="Seller Name">{contact.invite_sellerName}</td>
      <td data-th="Phone Number">{contact.invite_sellerPhoneNumber}</td>
      <td data-th="Email Id">{contact.invite_sellerEmail}</td>
      <td data-th="Action">
        <EditTwoTone
          type="button"
          onClick={(event) => handleEditClick(event, contact)}
        />

        <Delete type="button" onClick={() => handleDeleteClick(contact.id)} />
      </td>
    </tr>
  );
};

const EditableRow = ({
  editFormData,
  handleEditFormChange,
  handleCancelClick,
  handleEditFormSubmit,
}) => {
  return (
    <tr >
      <td >
        <TextField
          type="text"
          required="required"
          placeholder="Enter a name..."
          name="invite_sellerName"
          value={editFormData.invite_sellerName}
          onChange={handleEditFormChange}
       />
      </td>

      <td >
        <TextField
          type="text"
          required="required"
          placeholder="Enter a phone number..."
          name="invite_sellerPhoneNumber"
          value={editFormData.invite_sellerPhoneNumber}
          onChange={handleEditFormChange}
        />
      </td>
      <td >
        <TextField
          type="email"
          required="required"
          placeholder="Enter an email..."
          name="invite_sellerEmail"
          value={editFormData.invite_sellerEmail}
          onChange={handleEditFormChange}
        />
      </td>
      <td className="invite-table-cell">
        <SaveTwoTone type="button" onClick={handleEditFormSubmit} />

        <ClearIcon type="button" onClick={handleCancelClick} />
      </td>
    </tr>
  );
};
