import React from 'react'
import '../styles/CustomerGiftCard.css'
import CustomerGiftCardDetail from './CustomerGiftCardDetails'
import congrats1 from '../Images/congrats1.png'
import festival1 from '../Images/festival1.png' 
import festival2 from '../Images/festival2.png'

function CustomerGiftCardDetail4() {
  const images=[congrats1,festival1,festival2]
  return (
    <div>
      <CustomerGiftCardDetail description=": Congratulations" images={images}/>
    </div>
  )
}

export default CustomerGiftCardDetail4
