import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from '../App'

export const LocalVendorContext = createContext();

export const LocalVendorContextProvider = ({ children }) => {
    const [phoneNumberForOtp, setPhoneNumberForOtp] = useState("");
    const [localVendorData, setLocalVendorData] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    //   const [sellerPersonalData, setSellerPersonalData] = useState(null);
    // const [sellerUserType, setSellerUserType] = useState(3);
    const [isLocalVendorAdmin, setIsLocalVendorAdmin] = useState(false);
    const [selectedListItemIndex, setSelectedListItemIndex] = useState(1)
    //   const [sellerPlanDetails, setSellerPlanDetails] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            setAccessToken(token);
            fetchData(token);
        }
    }, []);

    const fetchData = async (accessToken) => {
        try {
            const response = await fetch(
                `${API_BASE_URL}/users/api/v1/info`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            //console.log(response);
            const data = await response.json();

            //console.log(data);
            setLocalVendorData(data);
            if(data?.result?.userID){
                navigate('/localVendorOrder')
            }



        } catch (error) {
        }
    }

    const login = async (email, password, isSellerAdmin) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            email: email,
            password: password,
            mobileNumber: "",
            typeOfUser: !isSellerAdmin ? 6 : 5,
            otp: "",
            isOTPBasedLogin: false,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        try {
            ////console.log(isSellerAdmin)
            const response = await fetch(
                `${API_BASE_URL}/users/api/v1/login`,
                requestOptions
            );
            const result = await response.json();
            ////console.log(result);
            // make second API call here if response is ok
            localStorage.setItem("token", result.result.accessToken);
            setAccessToken(result.result.accessToken);

            fetchData(result.result.accessToken);
            return result;
        } catch (error) {
            ////console.log("error", error);
        }
    };

    const loginWithOtp = async (phone, otp, isLocalVendorAdmin) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            email: "",
            password: "",
            mobileNumber: phone,
            typeOfUser: !isLocalVendorAdmin ? 6 : 5,
            otp: otp,
            isOTPBasedLogin: true,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        try {
            const response = await fetch(
                `${API_BASE_URL}/users/api/v1/login`,
                requestOptions
            );
            const result = await response.json();
            ////console.log(result);
            // make second API call here if response is ok
            localStorage.setItem("token", result.result.accessToken);
            setAccessToken(result.result.accessToken);

            fetchData(result.result.accessToken);
            return (result);
        } catch (error) {
            ////console.log("error", error);
        }
    };

  

    const logout = () => {
        setLocalVendorData(null);
        navigate("/");
        localStorage.setItem("token", null);
    };




    return (
        <LocalVendorContext.Provider
            value={{
                localVendorData,
                loginWithOtp,
                setLocalVendorData,
                setPhoneNumberForOtp,
                phoneNumberForOtp,
                login,
                logout,
                accessToken,
                isLocalVendorAdmin,
                setIsLocalVendorAdmin,
                selectedListItemIndex, 
                setSelectedListItemIndex
            }}
        >
            {children}
        </LocalVendorContext.Provider>
    );
};
