import React from "react";
import "../styles/Wishlist.css";
import { API_BASE_URL } from '../App'

import { useEffect, useState } from "react";
import BcBuyerLayout from "../buyerComponents/BcBuyerLayout";
import Breadcrumb from "../buyerComponents/Breadcrumb";
import BcAccountPagesContainer from "../buyerComponents/BcAccountPagesContainer";
import BcWishlistCard from "../buyerComponents/BcWishlistCard";
import useNavigationTracking from "../components/NavigationTracking";
import { useContext } from "react";
import { addToWishlist } from "../utils/addToWishlist";
import { successAlert } from "../components/Alert";
import { CustomerContext } from "../context/CustomerContext";


export default function Wishlist() {
  useNavigationTracking();
  const{ customerData,accessToken} = useContext(CustomerContext);

  const [wishlistData, setWishlistData] = useState([]);
  
  const fetchWishlistData = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/favorites/api/v1/list?userId=${customerData?.result?.userID}}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      //console.log('Fetched wishlist data:', data);
      if (data?.result) {
        setWishlistData(data.result);
        
      }
    } catch (error) {
      console.error('Error fetching wishlist data:', error);
    }
  };

  useEffect(() => {
    fetchWishlistData();
        // eslint-disable-next-line
  }, []);           
  const handleAddToWishlist = async (item) => {
    try {
      const response = await addToWishlist(item, customerData, accessToken);
      setWishlistData(response.updatedWishlistData);
    } catch (error) {
      console.error('Error adding item to wishlist:', error);
    }
  };

  const handleDeleteItem = async (ID) => {
    try {
      const response = await fetch(`${API_BASE_URL}/favorites/api/v1/${ID}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (response.ok) {      
        successAlert('','Item successfully deleted');
        fetchWishlistData();
      } else {
        console.error('Error deleting item:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  return (
    <BcBuyerLayout>
      <BcAccountPagesContainer>

       <Breadcrumb tab='Wishlist' />
        {wishlistData &&
          wishlistData?.map((cardProps) => {
            return <BcWishlistCard wishlistCard {...cardProps} onAddToWishlist={handleAddToWishlist} deleteClick={(ID)=>{handleDeleteItem(ID)}}/>;
          })}
                </BcAccountPagesContainer>
 
    </BcBuyerLayout>
  );
}
