import React from 'react'
import CustNavbar from '../components/CustNavbar'
import CustomerFooter from '../components/CustomerFooter'

function BuyerShippingPolicy() {
  return (
    <>
    <CustNavbar />
    <div className='terms-condition-content'>
    <h2>Shipping Policy - Myhraki</h2>
    <p>
      Thank you for choosing to shop with Myhraki! We value your patronage and strive to ensure your shopping experience is smooth and enjoyable.
      Please take a moment to review our Shipping Policy to understand important information about the shipping process and policies for your orders.
    </p>

    <h3>Order Processing:</h3>
    <p>
    We endeavor to process orders promptly to ensure timely delivery to our customers. Typically, order processing takes 1 to 3 business days. During peak seasons or unforeseen circumstances, processing times may be slightly longer. Orders are processed on business days, excluding weekends and public holidays.    </p>
    <h3>Shipping Methods and Estimated Delivery Times:</h3>
    <p>We provide a range of shipping methods tailored to suit your preferences. When you proceed to checkout, you'll have the opportunity to choose from the available shipping options. The estimated delivery date typically falls within 7 to 10 days from the date of purchase, depending on your selected shipping method and location. Kindly be aware that these estimations are approximate, and actual delivery times may fluctuate due to circumstances beyond our influence.</p>
    <h3>Shipping Costs:</h3>
    <p>Shipping costs are calculated based on the total weight of your order, shipping destination, and the chosen shipping method. You'll see applicable shipping costs during checkout, allowing you to review and confirm your order.</p>
    <h3>Tracking Orders:</h3>
    <p>Once your order is shipped, we'll provide you with a tracking number and instructions on how to track your package. You can use the tracking number to monitor the progress of your shipment on the shipping carrier's website. Please allow some time for the tracking information to be updated after the order has been shipped.</p>
    <h3>Address Accuracy:</h3>
    <p>It's essential to provide an accurate and complete shipping address during checkout. We cannot be held responsible for any delays or delivery issues arising from incorrect or incomplete addresses. If you need to make changes to your shipping address after placing an order, please contact our customer support promptly.</p>
    <h3>Lost or Damaged Packages:</h3>
    <p>While we take great care in packaging and shipping orders, we cannot assume liability for any lost, stolen, or damaged packages during transit. In the unfortunate event of a lost, stolen, or damaged package, please contact the shipping carrier directly to file a claim. We'll assist you to the best of our ability in resolving the issue.</p>
    
    <h3>Order Status and Communication:</h3>
    <p>
      We'll provide regular updates on your order's status through email or your Myhraki account. If you have any questions or concerns regarding your order or shipping, please don't hesitate to reach out to our dedicated customer support team. We're here to assist you.
    </p>

    <p>
      By placing an order on our platform, you acknowledge and agree to comply with the terms and conditions stated in this Shipping Policy.
    </p>

    <p>
      Thank you for shopping with Myhraki!
    </p>
  </div>
  <CustomerFooter />
  </>

  )
}

export default BuyerShippingPolicy