import React, { useState, useEffect, useContext, useRef } from "react";
import CustomerLayout from "../components/CustomerLayout";
import CustomerHeading from "../components/CustomerHeading";
// import "../styles/CustomerGiftCard.css";
import Rectangle from "../Images/Rectangle.png";
import date from "../Images/date.png";
import Logo from "../Images/MyhrakiLogoC.png";
import { Star } from "@phosphor-icons/react/dist/ssr";
import "../styles/CustomerGiftCardDetail.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CustomerContext } from "../context/CustomerContext";
import { API_BASE_URL } from "../App";
import { Diversity1 } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import ThankyouGiftCard from "../Images/Thankyou.png";
import Festival from "../Images/Fesitival.png";
import congratsGiftCard from "../Images/Congratulation.png";
import HappybirthdayCard from "../Images/HappyBirthday.png";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomerLoginModal from "../components/CustomerLoginModal";
import { Divide } from "@phosphor-icons/react";
import { Divider } from "@mui/joy";
import { CircularProgress, TextField } from "@mui/material";
import GCBanner from "../Images/GCBanner.png";
import GiftCard from "../Images/Gift Card New Option 1.jpg";

function CustomerGiftCardDetail(props) {
  const { customerData, accessToken } = useContext(CustomerContext);
  // const gcminamount = new RegExp(
  //   "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{6,20}$)"
  // );
  const [loading, setLoading] = useState(false)
  const validationSchemaMailCard = yup.object({
    amount: yup
      .number()
      .min(100, "Amount must be greater than 100")
      .max(100000,"Amount must be less than 100000")
      .required("Amount is required"),
    receptanceName: yup
      .string()
      .trim()
      .matches(/^[A-Za-z- ]+$/, "Name must contain only alphabetic characters")
      .required("Name is required"),
    receptanceEmail: yup
      .string()
      .trim()
      .matches(/.*@.*/, "Email must contain @")
      .email("Invalid email format"),
    receptancePhone: yup
      .string()
      .trim()
      .matches(/^\d{10}$/, "Phone number must be 10 digits")
      .required("Number is required"),
    from: yup
      .string()
      .trim()
      .matches(/^[A-Za-z- ]+$/, "Name must contain only alphabetic characters")
      .required("From is required"),
    message: yup
      .string()
      .trim()
      .max(1000, "Message must not exceed 100 characters")
      .required("Message is required"),
    deliveryDate: yup.date().required("Date is required"),
  });

  const formikMailCard = useFormik({
    initialValues: {
      amount: "",
      receptancePhone: "",
      receptanceName: "",
      receptanceEmail: "",
      from: "",
      message: "",
      deliveryDate: "",
    },
    validationSchema: validationSchemaMailCard,
    onSubmit: async (values) => {
      if (!customerData?.result?.userID) {
        setLoginModalOpen(true);
        return;
      }
      pay(values);
      // console.log(values);
      // console.log(formikMailCard);
    },
  });

  /* ------------------PHYSICAL CARD VALIDATION ----------------------------------*/

  // const validationSchemaPhysical = yup.object({
  //   amount: yup.number().required("amount is required"),
  //   receptanceName: yup.string().trim().required("Name is required"),
  //   receptanceEmail: yup.string().trim().required("Name is required"),
  //   receptancePhone: yup.number().required("Name is required"),
  //   address: yup.string().trim().required("Address is required"),
  //   from: yup.string().trim().required("From is required"),
  //   message: yup.string().trim().required("message is required"),
  //   deliveryDate: yup.date().required("Date is required"),
  // });

  // const formikPhysical = useFormik({
  //   initialValues: {
  //     amount: "",
  //     receptanceName: "",
  //     receptanceEmail: "",
  //     receptanceAddress: "",
  //     from: "",
  //     message: "",
  //     deliveryDate: "",
  //   },
  //   validationSchema: validationSchemaPhysical,
  //   onSubmit: async (values) => {
  //     if (!customerData?.result?.userID) {
  //       setLoginModalOpen(true);
  //       return;
  //     }
  //     // console.log(values);
  //     // console.log(formikPhysical)
  //   },
  // });

  /* -----------------------------------------------------------------------------------*/

  // console.log(props);
  // const params = useParams();
  // const gc = params.id;
  // console.log(gc);

  const [giftCardOptions, setGiftCardOptions] = useState([]);

  const giftCardList = [
    {
      idx: 1,
      image: ThankyouGiftCard,
      description: "Thank You",
    },
    {
      idx: 2,
      image: HappybirthdayCard,
      description: "Happy Birthday!!",
    },
    {
      idx: 3,
      image: Festival,
      description: "Happy Festival",
    },
    {
      idx: 4,
      image: congratsGiftCard,
      description: "Congratualtions!!!!",
    },
  ];

  // const filteredGiftCardList = giftCardList?.filter((card) => card.idx == gc);

  const [buttonActive, setButtonActive] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [loginModalOpen, setLoginModalOpen] = React.useState(false);

  /*---------------------- TWO SHOWS DIFFERENT TABLE WHEN UPON SELECTION---------*/
  // const [showTable1, setshowTable1] = useState(false);  !!  TO SHOW TABLE1 WHEN BUTTON IS CLICKED
  // const [showTable2, setshowTable2] = useState(false);  !!  TO SHOW TABLE1 WHEN BUTTON IS CLICKED
  // const handleClick1 = () => {
  //   setshowTable1(true);
  //   setshowTable2(false);
  //   setButtonActive(1);
  // };

  // const handleClick2 = () => {
  //   setshowTable1(false);
  //   setshowTable2(true);
  //   setButtonActive(2);
  // };
  /*-------------------------------------------*/

  // const [selectedImage, setSelectedImage] = useState(`${params.image}`);
  const [selectedGiftCardImage, setSelectedGiftCardImage] = useState(GiftCard);

  // const handleClick = (image) => {
  //   // console.log(image);
  //   setSelectedImage(image);
  // };

  const [message, setMessage] = useState(
    "Your personal message will be displayed here"
  );

  const [amount, setAmount] = useState("0");
  const handleInputChange1 = (event) => {
    setMessage(event.target.value);
  };
  const handleInputChange2 = (event) => {
    setAmount(event.target.value);
    event.preventDefault();
  };

  // const [formData, setFormData] = useState({
  //   userid: "",
  //   userName: "",
  //   userPhoneNumber: "",
  //   userEmailid: "",
  //   amount: "",
  //   receptacename: "",
  //   receptaceEmail: "",
  //   imageName: "",
  //   message: "",
  //   deliveryDate: "",
  // });
  // const handleChange = (event) => {
  //   setFormData({ ...formData, [event.target.name]: event.target.value });
  // };

  const pay = async (values) => {
    console.log("Pay Called");
    console.log(values);
setLoading(true)
    try {
      
      const response = await fetch(
        `${API_BASE_URL}/coupons/api/v1/createVoucherByUser`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application / json - patch + json",
          },
          body: JSON.stringify({
            userid: customerData?.result?.userID,
            userName: customerData?.result?.firstName,
            userPhoneNumber: customerData?.result?.mobileNumber,
            userEmailid: customerData?.result?.email,
            amount: parseFloat(values?.amount),
            receptacename: values?.receptanceName,
            receptaceEmail: values?.receptanceEmail,
            receptacePhone: `${values?.receptancePhone}`,
            imageName: selectedGiftCardImage,
            message: values?.message,
            deliveryDate: values?.deliveryDate,
            paymentGetewayName: "PhonePe",
          }),
        }
      );
      setLoading(false)
      const data = await response.json();
      const urlData = JSON.parse(data?.result);
      const urlPath = JSON.parse(urlData?.result);
      const url = urlPath.data.instrumentResponse.redirectInfo.url;
        window.location.href = url;
    } catch {
      setLoading(false)

    }
  };

  //  ---------------- TO FETCH GC BASED ON CATEGORY ---------------------------------
  // const fetchGiftCardOptions = async () => {
  //   try {
  //     const response = await fetch(
  //       `${API_BASE_URL}/coupons/api/v1/GetGiftCardImage?Category=${gc}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       }
  //     );
  //     const data = await response?.json();
  //     // console.log(data);
  //     setGiftCardOptions(data?.result);
  //   } catch {}
  // };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   fetchGiftCardOptions();
  //   setSelectedImage(filteredGiftCardList?.[0]?.image);
  // }, []);

  const today = new Date();
  // const [value, setValue] = useState(0);

  // const handleWheel = (e) => {
  //   e.preventDefault(); // Prevent the default scroll action
  //   const newValue = e.deltaY > 0 ? value - 1 : value + 1; // Increment or decrement the value
  //   setValue(newValue);
  // };

  return (
    <CustomerLayout>
      <CustomerLoginModal
        isOpen={loginModalOpen}
        onClose={() => {
          setLoginModalOpen(false);
        }}
      />
      <CustomerHeading />
      <div className="GiftCardLayout">

          <div className="gc-heading">GIFT CARD</div>
          <div className="gc-main-container">
            <div className="gc-box-container">
              <img src={GiftCard} className="Box-image" loading="lazy"/>
              <tr className="gc-tr1">
                <td className="gc-td1">
                  <div className="gc-pay"><span style={{color:'rgba(254, 82, 136, 1)', fontSize:'20px', fontWeight:'600', fontStyle:'bold', lineHeight:'0.2px', marginRight:'5px'}}>Myhraki</span>Pay Gift Card</div>
                </td>
                <td className="gc-td2">₹{amount}</td>
              </tr>
              <div className="Box-message">{message}</div>
            </div>
            <div className="eGift-card">
              <div className="My-text1">Myhraki Pay eGift Card</div>
              <div className="My-text2">By Myhraki</div>

              {/* <div className="Cardrating" style={{marginBottom:'20px'}}>
                  <Star className="cardStar" weight="fill" size={16} style={{paddingTop:'3px'}} color="green" />
                  <div className="cardReview">4.4 | 20 Reviews</div>
                </div> */}

              <Divider className="gc-divider" />

              {/* <div className="My-text3">1. Select a style for your Gift Card</div>
                <div className="My-text4">
                  Design:<div className="gc-span">{filteredGiftCardList[0].description}</div>
                </div>
                <div className="cardImage">
                  {giftCardOptions?.map((giftCard, index) => (
                    <img
                      key={giftCard?.id}
                      src={giftCard?.giftcardImageUrl}
                      alt={`Image ${index + 1}`}
                      className="Gc-Image"
                      onClick={() => {
                        handleClick(giftCard?.giftcardImageUrl);
                        setSelectedGiftCardImage(giftCard?.imageName);
                      }}
                    />
                  ))}
                </div> */}
              <Divider className="gc-divider" />
              {/* -------------------- BUTTONS FOR  EMAIL AND PHYSICAL CARD --------------------- */}
              {/* <div id="feilds">
                  <label className="product-delivery">Delivery</label>
                  <div className="clickButton">
                    <button
                      className={`buttonClick1 ${buttonActive === 1 ? "active" : ""}`}
                      type="button"
                      onClick={handleClick1}
                    >
                      Email & Message
                    </button>
                    <button
                      className={`buttonClick2 ${buttonActive === 2 ? "active" : ""}`}
                      onClick={handleClick2}
                      type="button"
                    >
                      Physical Card
                    </button>
                  </div>
                </div> */}
              {/* ------------------------------------EMAIL---------------------------------------------- */}
              <div className="MailCard-table">
                <form
                  onSubmit={formikMailCard.handleSubmit}
                  className="table-detail"
                >
                  <div className="gc-div-input">
                    <label className="gc-label">Amount</label>
                    <div className="gc-img-input">
                      <img src={Rectangle} className="gc-icon" />
                      <input
                        type="text"
                        name="amount"
                        value={formikMailCard.values.amount}
                        onChange={formikMailCard.handleChange}
                        className="gc-withimage-input"
                        onInput={handleInputChange2}
                        inputMode="numeric"
                      />
                    </div>
                  </div>
                  {formikMailCard.touched.amount && (
                    <div style={{ color: "red" }} id="gc-error">
                      {formikMailCard?.errors?.amount}
                    </div>
                  )}

                  <div className="gc-div-input">
                    <label className="gc-label">To</label>
                    <div className="gc-to-grid">
                      <div className="gc-toinput">
                        <input
                          type="text"
                          name="receptanceName"
                          className="gc-e-input"
                          id="gc-input-field"
                          placeholder="Enter recipient Name"
                          value={formikMailCard.values.receptanceName}
                          onChange={formikMailCard.handleChange}
                        />
                        {formikMailCard.touched.receptanceName && (
                          <div style={{ color: "red" }} id="gc-error">
                            {formikMailCard?.errors?.receptanceName}
                          </div>
                        )}
                      </div>
                      <div className="gc-toinput">
                        <input
                          type="email"
                          name="receptanceEmail"
                          id="gc-input-field"
                          className="gc-e-input"
                          placeholder="Enter recipient e-mail address (Optional) "
                          value={formikMailCard.values.receptanceEmail}
                          onChange={formikMailCard.handleChange}
                        />
                        {formikMailCard.touched.receptanceEmail && (
                          <div style={{ color: "red" }} id="gc-error">
                            {formikMailCard?.errors?.receptanceEmail}
                          </div>
                        )}
                      </div>
                      <div className="gc-toinput">
                        <input
                          type="text"
                          name="receptancePhone"
                          className="gc-e-input"
                          id="gc-input-field"
                          placeholder="Enter recipient phone number  "
                          value={formikMailCard.values.receptancePhone}
                          onChange={formikMailCard.handleChange}
                        />
                        {formikMailCard.touched.receptancePhone && (
                          <div style={{ color: "red" }} id="gc-error">
                            {formikMailCard?.errors?.receptancePhone}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="gc-div-input">
                    <label className="gc-label">From</label>
                    <div className="fromInputMain">
                      <input
                        type="text"
                        name="from"
                        className="gc-e-inputfrom"
                        id="gc-input-field"
                        placeholder="Your name"
                        value={formikMailCard.values.from}
                        onChange={formikMailCard.handleChange}
                      />
                    </div>
                  </div>
                  {formikMailCard.touched.from && (
                    <div style={{ color: "red" }} id="gc-error">
                      {formikMailCard?.errors?.from}
                    </div>
                  )}

                  <div className="gc-div-input">
                    <label className="e-messagelabel">Message</label>
                    <textarea
                      className="gc-msginput-field"
                      id="gc-input-field"
                      type="text"
                      name="message"
                      placeholder="Hope you enjoy this Myhraki Gift Pay Card!"
                      value={formikMailCard.values.message}
                      onChange={formikMailCard.handleChange}
                      onInput={handleInputChange1}
                      rows={4}
                    />
                  </div>
                  {formikMailCard.touched.message && (
                    <div style={{ color: "red" }} id="gc-error">
                      {formikMailCard?.errors?.message}
                    </div>
                  )}

                  <div className="gc-div-input">
                    <label className="gc-date-label">Delivery Date</label>
                    <div className="gc-img-input">
                      <img src={date} className="gc-icon" />
                      <DatePicker
                        calendarClassName="custom-calendar"
                        calendarContainer={document.querySelector(
                          ".date-picker"
                        )}
                        className="gc-withimage-dateinput"
                        selected={startDate}
                        name="deliveryDate"
                        onSelect={(date) => {
                          setStartDate(date);
                          formikMailCard.setFieldValue("deliveryDate", date);
                        }}
                        showPopperArrow={false}
                        minDate={today}
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                  {formikMailCard.touched.deliveryDate && (
                    <div style={{ color: "red" }} id="gc-error">
                      {formikMailCard?.errors?.deliveryDate}
                    </div>
                  )}

                  <div className="buttonCard">
                   {loading?<CircularProgress style={{color:'pink'}} />: <button className="cardBuy" type="submit">
                      BUY NOW
                    </button>}
                  </div>
                </form>
              </div>

              {/*------------------------------------------- PHYSICAL CARD ----------------------------------------*/}
              {/* <div>
                  {showTable2 && (
                    <form
                      onSubmit={formikPhysical.handleSubmit}
                      className="table-detail"
                    >
                      <div id="feilds">
                        <label className="amount">Amount</label>
                        <div id="div-input">
                          <img src={Rectangle} className="rectangle" />
                          <input
                            type="number"
                            name="amount"
                            value={formikPhysical.values.amount}
                            onChange={formikPhysical.handleChange}
                            className="input1"
                            onInput={handleInputChange2}
                          />
                        </div>
                      </div>
                      {formikPhysical.touched.amount && (
                        <div style={{ color: "red" }}>
                          {formikMailCard?.errors?.amount}
                        </div>
                      )}

                      <div id="table-detail">
                        <div id="feilds1">
                          <label className="p-tolabel">To</label>
                          <input
                            type="text"
                            name="receptanceName"
                            className="p-toinput"
                            placeholder="Receptance name"
                            value={formikPhysical.values.receptanceName}
                            onChange={formikPhysical.handleChange}
                          />
                        </div>
                        {formikPhysical.touched.receptanceName && (
                          <div style={{ color: "red" }}>
                            {formikPhysical?.errors?.receptanceName}
                          </div>
                        )}
                        <div id="feilds1">
                          <label className="p-addresslabel">Address</label>
                          <input
                            type="text"
                            name="receptanceAddress"
                            className="p-addressinput"
                            placeholder="Receptance address"
                            value={formikPhysical.values.receptanceAddress}
                            onChange={formikPhysical.handleChange}
                          />
                        </div>
                        {formikPhysical.touched.receptanceAddress && (
                          <div style={{ color: "red" }}>
                            {formikPhysical?.errors?.receptanceAddress}
                          </div>
                        )}
                        <div id="feilds1">
                          <label className="p-fromlabel">From</label>
                          <input
                            type="text"
                            name="from"
                            className="p-frominput"
                            placeholder="Your name"
                            value={formikPhysical.values.from}
                            onChange={formikPhysical.handleChange}
                          />
                        </div>
                        {formikPhysical.touched.from && (
                          <div style={{ color: "red" }}>
                            {formikPhysical?.errors?.from}
                          </div>
                        )}
                        <div id="feilds1">
                          <label className="p-messagelabel">Message</label>
                          <input
                            type="text"
                            name="message"
                            className="p-messageinput"
                            onInput={handleInputChange1}
                            placeholder="Hope you enjoy this Myhraki Gift Pay Card!"
                            value={formikPhysical.values.message}
                            onChange={formikPhysical.handleChange}
                          />
                        </div>
                        {formikPhysical.touched.message && (
                          <div style={{ color: "red" }}>
                            {formikPhysical?.errors?.message}
                          </div>
                        )}
                        <div id="feilds1">
                          <label className="p-datelabel">Delivery Date</label>

                          <div id="dateimg2">
                            <img src={date} className="date2" />
                            {buttonActive === 2 && (
                              <DatePicker
                                calendarClassName="pcustom-calendar"
                                calendarContainer={document.querySelector(
                                  ".date-picker"
                                )}
                                selected={startDate}
                                onSelect={(date) => {
                                  setStartDate(date);
                                  formikPhysical.setFieldValue("deliveryDate", date);
                                }}
                                showPopperArrow={false}
                                className="p-dateinput"
                              />
                            )}
                          </div>
                          {formikPhysical.touched.deliveryDate && (
                            <div style={{ color: "red" }}>
                              {formikPhysical?.errors.deliveryDate}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="buttonCard">
                        <button className="cardBuy" type="submit">
                          BUY NOW PHYSICAL
                        </button>
                      </div>
                    </form>
                  )}
                  </div>*/}
              {/* -------------------------------------------------------------------------------- */}
            </div>
          </div>
      </div>
    </CustomerLayout>
  );
}

export default CustomerGiftCardDetail;
