import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import "../styles/AdminPlan.css";
import { API_BASE_URL } from '../App'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, 
  MenuItem,
  Paper,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import Heading from "../components/Heading";
import { useNavigate } from "react-router-dom";
import AdminLayoutAdjustment from "../components/AdminLayoutAdjustment";
import { AdminContext } from "../context/AdminContext";
import { errorAlert, successAlert } from "../components/Alert";

function AdminAddPlan() {
 
  const { accessToken} = useContext(AdminContext);
  const [rows, setRows] = useState([
    {
      description: "",
      value: "",
      isValueView: "",
      displayorder: 1,
    },
  ]);

  const [open, setOpen] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [disable, setDisable] = React.useState(true);
  const [showConfirm, setShowConfirm] = React.useState(false);


  const handleAdd = () => {
    setRows([
      ...rows,
      {
        description: "",
        value: "",
        isValueView: "",
        displayorder: rows?.length+1,
      },
    ]);
    setEdit(true);
  };

  const handleEdit = (i) => {
    setEdit(!isEdit);
  };

  const handleSave = () => {
    setEdit(!isEdit);
    setRows(rows);
    setDisable(true);
    setOpen(true);
  };

  const handleInputChange = (e, index) => {
    setDisable(false);
    const { name, value } = e.target;
    const list = [...rows];
    list[index][name] = value;
    setRows(list);
  };
  const [indexToRemove, setIndexToRemove] = useState();
  const handleConfirm = (i) => {
    setIndexToRemove(i);

    setShowConfirm(true);
    return i;
  };

  const handleRemoveClick = () => {
    const list = [...rows];
    list.splice(indexToRemove, 1);
    setRows(list);
    setShowConfirm(false);
  };

  const handleNo = () => {
    setShowConfirm(false);
  };

  const [planName, setPlanName] = useState();
  const [planValue, setPlanValue] = useState();
  const [planFrequency, setPlanFrequency] = useState("Monthly");
  const [planDiscount, setPlanDiscount] = useState();
  const [planOrder, setPlanOrder] = useState();
  // const [planId, setPlanId] = useState();

  const navigate = useNavigate();
  useEffect(() => {
    //console.log(planFrequency);
  }, [planFrequency]);

  async function addPlan() {
    if (planValue > 0) {
      const planId = await createPayment(planName, planValue, planFrequency);
      await addPlanDetails(planId);
    } else {
      await addPlanDetails("nil");
    }
  }

  const createPayment = async (planName, amount, frequency) => {
    let headersList = {
      "Authorization":`Bearer ${accessToken}`
    };
    let formData = new FormData();
    formData.append("period", frequency ==="Yearly"? "yearly" : "monthly");
    formData.append("interval", "2");
    formData.append("item.name", planName);
    formData.append("item.amount", amount);
    formData.append("item.currency", "INR");
    formData.append("item.description", "Plan");
   
    let response = await fetch(
      `${API_BASE_URL}/payments/api/v1/CreatePlanForSubscription`,
      {
        method: "POST",
        body: formData,
        headers: headersList,
      }
    );

    const result = await response.json();
    //console.log(result);
    ////console.log(result.result.id);
    return result.result.id;
  };


  const addPlanDetails = async (planId) => {
    let headersList = {
      "Content-Type": "application/json",
      "Authorization":`Bearer ${accessToken}`
    };

    let bodyContent = JSON.stringify({
      annualDiscount: planDiscount,
      isAnnual: planFrequency === "Yearly" ? true : false,
      title: planName,
      displayorder: parseInt(planOrder),
      value: planValue,
      features: rows,
      razorpayPlanId: planId,
    });
    if (!planName) {
      errorAlert('', 'Plan name is missing');
      return;
    }
    
    if ( isNaN(parseInt(planValue))) {
      //console.log(planValue, typeof (planValue) , isNaN(parseInt(planValue)) )
      errorAlert('', 'Plan value is missing or not a valid integer');
      return;
    }
    
    if ( isNaN(parseInt(planDiscount))) {
      errorAlert('', 'Plan discount is missing or not a valid integer');
      return;
    }
    
    if ( isNaN(parseInt(planOrder))) {
      errorAlert('', 'Plan order is missing or not a valid integer');
      return;
    }
    else{
    let response = await fetch(
      `${API_BASE_URL}/subscription/api/v1/create`,
      {
        method: "post",
        body: bodyContent,
        headers: headersList,
      }
    );
    //console.log(bodyContent);
    let data = await response.json();
    ////console.log(data);
    successAlert('','Plan created Successfully!');
    navigate("/adminPlanlist");
  };
}

  return (
    <AdminLayoutAdjustment>
      <Heading text="Subscription Plan Details" />

      <div className="admin-plan-basic-details">
        <div className="admin-plan-field">
          <div className="admin-plan-field-name">Plan Name</div>
          <TextField
            fullWidth
            variant="outlined"

            value={planName}
            onChange={(e) => setPlanName(e.target.value)}
          />
        </div>
        <div className="admin-plan-field">
          <div className="admin-plan-field-name">Plan Value</div>
          <TextField
            fullWidth
            variant="outlined"
            // type="number"
            value={planValue}
            onChange={(e) => setPlanValue(e.target.value)}
          />
        </div>
        <div className="admin-plan-field">
          <div className="admin-plan-field-name">Plan Frequency</div>
          <div className="admin-radio-field">
            <div>
              <input
                type="radio"
                name="frequency"
                id="Yearly"
                value="Yearly"
                checked={planFrequency === "Yearly"}
                onChange={(e) => setPlanFrequency(e.target.value)}
              />
              <label className="admin-radio-field-label">Yearly</label>
            </div>
            <div>
              <input
                type="radio"
                name="frequency"
                id="Monthly"
                value="Monthly"
                checked={planFrequency === "Monthly"}
                onChange={(e) => setPlanFrequency(e.target.value)}
              />
              <label className="admin-radio-field-label">Monthly</label>
            </div>
          </div>
        </div>
        <div className="admin-plan-field">
          <div className="admin-plan-field-name">Plan Discount</div>
          <TextField
            fullWidth
            variant="outlined"
            // type="number"

            value={planDiscount}
            onChange={(e) => setPlanDiscount(e.target.value)}
          />
        </div>
        <div className="admin-plan-field">
          <div className="admin-plan-field-name">Plan Order</div>
          <TextField
            fullWidth
            variant="outlined"
            // type="number"

            value={planOrder}
            onChange={(e) => setPlanOrder(e.target.value)}
          />
        </div>
      </div>
      <Heading text="Features" />

      <table
        class="admin-planlist-table"
      >
        <tr>
          <th>Feature Order</th>
          <th>Feature Name</th>
          <th>Feature Value</th>
          <th>Feature display</th>
          <th>Actions </th>
        </tr>
        {rows &&
          rows.map((row, i) => {
            return (
              <tr>
                {isEdit ? (
                  <>
                    <td data-th="Feature Order">
                      <TextField
                        value={row.displayorder}
                        name="displayorder"
                        // onChange={(e) => handleInputChange(e, i)}
                      />
                    </td>
                    <td data-th="Feature Name">
                      <TextField
                        value={row.description}
                        name="description"
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </td>
                    <td data-th="Feature Value">
                      <TextField
                        value={row.value}
                        name="value"
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </td>
                    <td data-th="Feature Display Value">
                      <TextField
                      select
                        // style={{ width: "100px" }}
                        name="isValueView"
                        value={row.isValueView === true ? true : false}
                        onChange={(e) => handleInputChange(e, i)}

                      >
                        {/* <MenuItem value={row.isValueView}>{row.isValueView}</MenuItem> */}
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </TextField>
                    </td>
                  
                  </>
                ) : (
                  <>
                    <td data-th="Feature Order">{row.displayorder}</td>
                    <td data-th="Feature Name">{row.description}</td>
                    <td data-th="Feature Value">{row.value}</td>
                    <td align="center" data-th="Feature Display Value">
                      {row.isValueView === true ? "Yes" : "No"}
                    </td>
                    <td align="center" data-th="Action">
                      {isEdit ? (
                        <ClearIcon onClick={() => handleConfirm(i)} />
                      ) : (
                        <DeleteOutlineIcon onClick={() => handleConfirm(i)} />
                      )}
                    </td>
                  </>
                )}

                {showConfirm && (
                  <div>
                    <Dialog
                      open={showConfirm}
                      onClose={handleNo}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      BackdropProps={{
                        style: {
                          background: "#ffffff",
                          opacity: "0.1",
                          padding: "2rem 5rem",
                        },
                      }}
                    >
                      <Paper sx={{ px: "5rem" }}>
                        <DialogTitle id="alert-dialog-title">
                          {"Confirm Delete"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Are you sure to delete
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <button
                            className="common-btn"
                            onClick={() => handleRemoveClick(i)}
                            color="primary"
                            autoFocus
                          >
                            Yes
                          </button>
                          <button
                            className="common-btn"
                            onClick={handleNo}
                            color="primary"
                          >
                            No
                          </button>
                        </DialogActions>
                      </Paper>
                    </Dialog>
                  </div>
                )}
              </tr>
            );
          })}
      </table>
      <div className="admin-plan-buttons">
        {isEdit ? (
          <div>
            <button className="common-btn" onClick={handleAdd}>
              {/* <AddBoxIcon onClick={handleAdd} /> */}
              ADD
            </button>
            {rows.length !== 0 && (
              <>
                {disable ? (
                  <button
                    className="common-btn"
                    align="right"
                    onClick={handleSave}
                  >
                    SAVE
                  </button>
                ) : (
                  <button
                    className="common-btn"
                    align="right"
                    onClick={handleSave}
                  >
                    SAVE
                  </button>
                )}
              </>
            )}
          </div>
        ) : (
          <div>
            <button className="common-btn" onClick={handleAdd}>
              {/* <AddBoxIcon onClick={handleAdd} /> */}
              ADD
            </button>
            <button className="common-btn" align="right" onClick={handleEdit}>
              {/* <CreateIcon /> */}
              EDIT
            </button>
          </div>
        )}
      </div>
      {!isEdit && (
        <div
          style={{
            margin: "2rem auto",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            style={{ fontSize: "1.5rem" }}
            className="common-btn"
            onClick={addPlan}
          >
            Add Plan
          </button>
        </div>
      )}
    </AdminLayoutAdjustment>
  );
}

export default AdminAddPlan;
