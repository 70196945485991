import { TextField } from "@mui/material";
import React, { useContext } from "react";
import Heading from "../components/Heading";
// import SellerLayout from "../components/SellerLayout";
import "../styles/SellerManagementPage.css";
import "../styles/Common.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import { useEffect } from "react";
import {
  Cancel,
  CheckCircle,
  Delete,
  Edit,
} from "@mui/icons-material";
import { SellerContext } from "../context/SellerContext";
//import notif from "../components/Notify";
// import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert} from "../components/Alert";
import SellerLayoutAdjustment from "../components/SellerLayoutAdjustment";
import ToastComponent from "../components/ToastComponent";
import { API_BASE_URL } from '../App'
import Loading from "../components/Loading";


export default function SellerManagementPage() {
  // const firstNameRegExp=/^[A-Z][a-zA-Z]{3,}\S*$/;



  const passwordRegexp = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%^&*_(\\)\\+\\-\\=\\[\\]\\\\\\{\\}\\|:;\";'<>?,./`~])(?=.{6,20}$)"
  );
  const phoneRegExp = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;
  // const phoneRegExp = /^[0-9]{10}$/;
  const fullNameRegExp = /^(?!.*\\s{2})(?!.*[.'-]{2})[A-Za-z][A-Za-z' ]{0,48}[A-Za-z]$/;
  const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;


  const [staffAllowed, setStaffAllowed] = useState();
  const {sellerData, accessToken, sellerPlanDetails, handleAuthExpiry, logout, getSellerPlan} = useContext(SellerContext)
  const toastRef = React.useRef();
  const [loading, setLoading] = useState(false);

  
  // //console.log(sellerPlanDetails);
  function isAddStaffAllowed(sellerPlanDetails) {
    const elements = sellerPlanDetails?.toString().split(".");
    if (elements?.length >= 12) {
      const staffElement = parseFloat(elements[9]);
      if(staffElement>0){
        return true;
      }
    }
    return false;
  }

  function checkAddStaffAllowed() {
    if(isAddStaffAllowed(sellerPlanDetails)){
      //console.log(sellerPlanDetails)
      setStaffAllowed(true)
    }
    else{
      setStaffAllowed(false)
    }
  }

  useEffect(()=>{
    checkAddStaffAllowed()
  },[sellerPlanDetails])


  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .trim()
      .matches(emailRegExp,"Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(8, "Invalid Password")
      .matches(passwordRegexp,"Enter a valid password")
      .required("Password is required"),
    mobileNumber: yup
      .string()
      .matches(phoneRegExp, "Enter a valid number")
      .required("Phone Number is required")
      .trim()
      .min(10, "Phone mobile number must consist of 10 digits")
      .max(10, "Phone mobile number must consist of 10 digits"),
    firstName: yup
    .string()
    .required(" Name is Required")
    .min(1, " Name should be 1 chars atleast")
    .max(50, "Full Name should not exceed 50 characters")
    .matches(fullNameRegExp, "Invalid Full Name"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      firstName: "",
      mobileNumber: "",
      typeOfUser: "SLUSR",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
   setLoading(true);
      if(!staffAllowed){
        // showAlert('','Cannot add users in your existing Plan. Please upgrade')
        toastRef.current.showToast("Cannot add users in your existing Plan. Please upgrade", "error");
        setLoading(false);
        return;
      }
      const userExists = await checkUserExist(formik.values.mobileNumber.trim());
      const userExistEmail = await checkUserExistEmail(formik.values.email);
      if (userExists === false && userExistEmail ===false) {
        const response= await postData();
      if(response.result){
        setLoading(false);
        resetForm({ values: "" });
        fetchSellerUsers();

        getSellerPlan();
        toastRef.current.showToast("User successfully Created", "success");
      }
      else{
        setLoading(false);
        toastRef.current.showToast("An error Occured. Please check the data and try again", "error");
      }
      } else {
        setLoading(false);
        toastRef.current.showToast("User Already exists", "error");
      }
      
    },
  });

  const checkUserExist = async (mobNumber) => {
    try {
      const data = await fetch(
        `${API_BASE_URL}/users/api/v1/checkUser?mobNumber=${mobNumber}&userTypeId=4`
      );
      const response = await data.json();
      // //console.log("Check", response)

      if (response?.result === true) {
        return true;
      } else if (response?.result === false) {
        return false;
      }
    } catch (e) {
      return false;
    }
  };
  const checkUserExistEmail = async (email) => {
    try {
      const data = await fetch(
        `${API_BASE_URL}/users/api/v1/checkUser?email=${email}&userTypeId=4`
      );
      const response = await data.json();
      // //console.log("Check", response)

      if (response.result === true) {
        return true;
      } else if (response.result === false) {
        return false;
      }
    } catch (e) {
      return false;
    }
  };


  async function postData() {
    if(staffAllowed===true){
    try {
      let result = await fetch(`${API_BASE_URL}/users/api/v1/registration`, {
        method: "post",
        // mode: "no-cors",
        headers: {
          //   Accept: "application.json",
          "Content-type": "application/json",
          "Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          firstName: formik.values.firstName.trim(),
          email: formik.values.email.trim(),
          mobileNumber: formik.values.mobileNumber.trim(),
          password: formik.values.password,
          typeOfUserId: 4,
          dateOfBirth:"",
          adminId:sellerData?.result?.userID,
          lastName:"",
        }),
      });
      //console.log(sellerPlanDetails);
      //console.log(staffAllowed)
      const data= await result.json();
      const handleAuthError = handleAuthExpiry(result);
      if (handleAuthError === true) {
        // errorAlert('', 'Your Session has expired');
        toastRef.current.showToast("Your Session has expired.", "error");
        logout();
        return;
      }

      //console.log(data);
      return data
    } catch (e) {
      //console.log(e);
    }
  }else{
    toastRef.current.showToast("Cannot add users in your existing Plan. Please upgrade", "error");
  }
  }

  const [sellerUsers, setSellerUsers] = useState();
  const [editMode, setEditMode] = useState(false);
  const [editedUser, setEditedUser] = useState({});

  const fetchSellerUsers = async () => {
    setLoading(true);
    const response = await fetch(`${API_BASE_URL}/users/api/v1/UsersAdmin/${sellerData?.result?.userID}`,{
      headers:{
        "Authorization": `Bearer ${accessToken}`
      }
    });
    const data = await response.json();
    const handleAuthError = handleAuthExpiry(response);
    if (handleAuthError === true) {
      setLoading(false);
      toastRef.current.showToast("Your Session has expired.", "error");
      logout();
      return;
    }
    setSellerUsers(data.result);
    setLoading(false);
  };

  const deleteSeller = async (userId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/users/api/v1/${userId}`, {
        method: "DELETE",
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      });
     
      const data= await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired.", "error");
        logout();
        return;
      }
      // console.log(response?.result)
      if(data?.result===true){
        fetchSellerUsers();
        getSellerPlan();
      }
    
      if(data.result){
        //notify('User Deleted Successfully');
      }
    } catch (error) {
      console.error("Error deleting seller:", error);
      toastRef.current.showToast("An error occurred. Please try again.", "error");

    }
  };
  

  const saveUserChanges = async (userId) => {
    if (
      !fullNameRegExp.test(editedUser.firstName.trim()) ||
      !emailRegExp.test(editedUser.email.trim()) ||
      !phoneRegExp.test(editedUser.mobileNumber.trim())
    ) {
        // alert("Name, Email and Phone Number are mandatory. Please check before submitting.");
        toastRef.current.showToast("Name, Email and Phone Number are mandatory. Please check before submitting.", "error");
        return;
      }
      const userExists = await checkUserExist(editedUser.mobileNumber.trim());
      const userExistEmail = await checkUserExistEmail(editedUser.email);
      if (userExists === false || userExistEmail ===false) {
        const response= await postData();
      if(response?.result){
        fetchSellerUsers();
        getSellerPlan();
        toastRef.current.showToast("User successfully Created", "success");
      }
      else{
        toastRef.current.showToast("An error Occured. Please check the data and try again", "error");
      }
      }

    setSellerUsers(
      sellerUsers.map((user) => (user.userID === userId ? editedUser : user))
    );
    const response= await fetch(`${API_BASE_URL}/users/api/v1/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json-patch+json",
        "Authorization": `Bearer ${accessToken}`

      },
      body: JSON.stringify({
        "userID": editedUser.userID,
        "email": editedUser.email.trim(),
        "mobileNumber": editedUser.mobileNumber.trim(),
        "firstName": editedUser.firstName.trim(),
        "lastName": "",
        "dateOfBirth": ""

      }),
    });
    setEditMode(false);
    setEditedUser({});
    const data = await response.json();
    if (data.result){
      //notify('User Edit Successful');
      toastRef.current.showToast("User Edit Successful", "success");
    }
    else{
      //notify('An error Occured. Please try again');
      toastRef.current.showToast("An error Occured. Please try again", "error");
      fetchSellerUsers();
    }
  };

  // const handleEditClick = (user) => {
  //   setEditMode(true);
  //   setEditedUser(user);
  //   //console.log("EditedUser ", editedUser, user)
  // };

  const handleCancelClick = () => {
    setEditMode(false);
    setEditedUser({});
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedUser({ ...editedUser, [name]: value });
  };

  useEffect(() => {
    fetchSellerUsers();
  }, []);

  return (
    <>
              <ToastComponent ref={toastRef} timeout={4000} />

    <SellerLayoutAdjustment>

      <div className="page-content">
      <Heading text="Add Sellers" />
      <form className="add-seller-user-form" onSubmit={formik.handleSubmit}>
        <div className="add-seller-user-form-field">
          <div className="add-seller-user-form-field-firstName">Seller Name*</div>
          <div className="add-seller-user-form-field-textfield">
            <TextField
              variant="outlined"
              fullWidth
              autoFocus
              type="text"
              name="firstName"
              //   className="login-page-email"
              //   className="signup-firstName"
              placeholder="Enter the seller's Name "
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </div>
        </div>
        <div className="add-seller-user-form-field">
          <div className="add-seller-user-form-field-firstName">Seller Email*</div>
          <div className="add-seller-user-form-field-textfield">
            <TextField
              variant="outlined"
              fullWidth
              type="text"
              //   id="signup-email"
              name="email"
              //   className="login-page-email"
              //   className="signup-email"
              placeholder="Enter Email Address"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </div>
        </div>
        <div className="add-seller-user-form-field">
          <div className="add-seller-user-form-field-firstName">Seller Phone*</div>
          <div className="add-seller-user-form-field-textfield">
            <TextField
              variant="outlined"
              fullWidth
              type="text"
              //   id="signup-mobileNumber"
              name="mobileNumber"
              //   className="login-page-email"
              //   className="signup-mobileNumber"
              placeholder="Enter Your Phone Number"
              value={formik.values.mobileNumber}
              onChange={formik.handleChange}
              error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
              helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
            />
          </div>
        </div>
        <div className="add-seller-user-form-field">
          <div className="add-seller-user-form-field-firstName">Seller Password*</div>
          <div className="add-seller-user-form-field-textfield">
            <TextField
              variant="outlined"
              fullWidth
              type="text"
              //    id="signup-password"
              name="password"
              //   className="login-page-email"
              //    className="signup-password"
              placeholder="Enter a desired Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </div>
        </div>
        <div className="add-seller-user-form-buttons">
          
          <input type="submit" value=" + Add Seller User" className="common-btn" />
        </div>
      </form>
      
      {/* <h3 text="Seller Users List"> </h3> */}
<div className="seller-added-list">
      <table className="admin-categories-table">
        <tbody>
          <tr>
            <th>Seller Name</th>
            <th>Seller Email</th>
            <th>Seller Number</th>
            <th>Actions</th>
          </tr>
          {loading && <div className="loading-container" style={{ minHeight: "300px", minWidth: "80%" }}>
            <Loading />

          </div>}
          {sellerUsers?.map((user) => (
            <tr key={user.userID}>
              <td data-th="Seller Name">
                {editMode && editedUser.userID === user.userID ? (
                  <TextField
  type="text"                    name="firstName"
                    value={editedUser.firstName}
                    onChange={handleInputChange}
                    required
                  />
                ) : (
                  user.firstName
                )}
              </td>
              <td data-th="Seller Email">
                {editMode && editedUser.userID === user.userID ? (
                  <TextField
  type="text"                    name="email"
                    value={editedUser.email}
                    onChange={handleInputChange}
                  />
                ) : (
                  user.email
                )}
              </td>
              <td data-th="Seller Number">
                {editMode && editedUser.userID === user.userID ? (
                  <TextField
  type="text"                    name="mobileNumber"
                    value={editedUser.mobileNumber}
                    onChange={handleInputChange}
                  />
                ) : (
                  user.mobileNumber
                )}
              </td>
              <td data-th="Actions">
                {editMode && editedUser.userID === user.userID ? (
                  <>
                    <CheckCircle
                      onClick={() => saveUserChanges(user.userID)}
                      style={{ marginRight: "1rem", fontSize: "2.0rem" }}
                    />
                    <Cancel
                      onClick={handleCancelClick}
                      style={{ marginRight: "1rem", fontSize: "2.0rem" }}
                    />
                  </>
                ) : (
                  <>
                    {/* <Edit onClick={() => handleEditClick(user)} style={{marginRight:"1rem", fontSize:"2.0rem"}}/> */}
                    <Delete onClick={() =>{confirmAlert('', 'Are you sure you want to delete this user?', () =>{ deleteSeller(user.userID)})}} style={{marginRight:"1rem", fontSize:"2.0rem"}}/>
                    {/* // onClick={() => { confirmAlert('', 'Are you sure you want to delete this category?', () => { DeleteCategory(category.categoryId) }) }} */}
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table></div>
      {/* //<ToastContaner theme="coloured" className="toast-message" /> */}

</div>
    </SellerLayoutAdjustment>
    </>
  );
}
