import React, { useState, useEffect } from 'react';


import LogisticAdminLayout1 from './LogisticAdminLayout1';
import LogisticsAdminLayout2 from './LogisticAdminLayout2';

export default function LogisticAdminLayoutAdjustment({children}) {
  const [screenSize, setScreenSize] = useState("");

  useEffect(() => {
    function handleResize() {
     
    if (window.innerWidth <= 1024) {
        setScreenSize("medium");
      } else {
        setScreenSize("large");
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    // render different components based on `screenSize`
    <div>
      
    {screenSize === "medium" && <LogisticAdminLayout1>{children}</LogisticAdminLayout1>}
    {screenSize === "large" && <LogisticsAdminLayout2>{children}</LogisticsAdminLayout2>}
  </div>
  );  
}