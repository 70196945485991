import React from 'react'
import "../styles/BcBuyerLayout.css"
import BottomNavbar from './BcBottomNavbar'
import { useEffect } from 'react';
import { useState } from 'react';

function BcBuyerLayout(props) {
    //console.log(`Sana is asking ${process.env.REACT_APP_API_BASE_URL}`)
    const [isLargeScreen, setIsLargeScreen] = useState(window.screen.width > 767);
    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth > 767);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="bc-buyers-layout" style={{ backgroundColor: 'rgb(255, 255, 255)', width:"747px" }}>
            <div style={{ width:'100%'}}>

                {/* <BcBuyerNavbar /> */}
            </div>
            {props.children}
            {/* <BcBuyerFooter /> */}
            {/* <Footer color="admin-footer-color"/> */}

            {!isLargeScreen && 
            <BottomNavbar />
            }

        </div>
    )
}

export default BcBuyerLayout



