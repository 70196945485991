import React from 'react'
import "../styles/EmptyPage.css"
import empty from "../Images/emptyPage.png"
import { useNavigate } from 'react-router-dom';

function EmptyPage(props) {
    const navigate = useNavigate(); 
    const startShopping=()=>{
      navigate('/');
    }
  return (

    <div className='empty-page-container'>
       <img loading="lazy" src={empty} alt='' />
       <div className='empty-page-content'>{props.desc}</div>
       
      <button className='start-shopping-btn' onClick={startShopping}>Start shopping</button>

    </div>

  )
}

export default EmptyPage 