import React, { createContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API_BASE_URL } from '../App'


export const AdminContext = createContext();

export const AdminContextProvider = ({ children }) => {
  const [phoneNumberForOtp,setPhoneNumberForOtp] = useState('');
  const [accessToken,setAccessToken] = useState();
  const [adminData, setAdminData] = useState(null);
  const location = useLocation();
  

  const [selectedListItemIndex, setSelectedListItemIndex] = useState
    (location.pathname.toLowerCase()==="/AdminDashboard".toLowerCase()?0:location.pathname.toLowerCase()==="/AdminFacts".toLowerCase()?1:location.pathname.toLowerCase()==="/AdminDiscount".toLowerCase()?2:location.pathname.toLowerCase()==="/inviteSeller2".toLowerCase()?3:location.pathname.toLowerCase()==="/adminPlanlist".toLowerCase()?4:location.pathname.toLowerCase()==="/manageUsers".toLowerCase()?5:location.pathname.toLowerCase()==="/adminInvite".toLowerCase()?6:location.pathname.toLowerCase()==="/adminApproval".toLowerCase()?7:location.pathname.toLowerCase()==="/adminAddCategory".toLowerCase()?8:location.pathname.toLowerCase()==="/trackOrder".toLowerCase()?9:0)
    
  
const navigate = useNavigate();


const handleAuthExpiry = (response)=>{
  if(response?.status===401){
    return true
  }else{
    return false
  }
}
//console.log(process.env.REACT_APP_API_TEST);
useEffect(() => {
  const token = localStorage.getItem('token');
  if (token) {
    setAccessToken(token);
    ////console.log(token);
    fetchData(token);
  }
}, []);
 const fetchData = async (accessToken) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/users/api/v1/info`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
        // //console.log(data)
      if(data?.result?.userID){
        navigate('/AdminFacts');
        setSelectedListItemIndex(0);
      }else{
        navigate('/');

      }
      ////console.log(data);
      setAdminData(data);
      return(data);
    } catch (error) {
      //console.error(error);
    }
  };

  const login = async (email, password) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: email,
      password: password,
      mobileNumber: "",
      typeOfUser: 1,
      otp: "",
      isOTPBasedLogin: false,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${API_BASE_URL}/users/api/v1/login`,
        requestOptions
      );
      const result = await response.json();
      ////console.log(result);
      if(!response?.result?.accessToken){
        
      }
      localStorage.setItem("token", result.result.accessToken);
      setAccessToken(result.result.accessToken)
      
      fetchData(result.result.accessToken);
      return(result);
    } catch (error) {
          ////console.log("error", error);
    }
  };

  const loginWithOtp = async (phone, otp) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: '',
      password: '',
      mobileNumber: phone,
      typeOfUser: 1,
      otp: otp,
      isOTPBasedLogin: true,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        //console.log(`Before , API Call made to  ${process.env.REACT_APP_API_BASE_URL}`)
        `${API_BASE_URL}/users/api/v1/login`,
        requestOptions
      );
      const result = await response.json();
      ////console.log(result);
        if(!result.result?.accessToken){
          return;
        }
      localStorage.setItem("token", result.result.accessToken);
      setAccessToken(result.result.accessToken)

      fetchData(result.result.accessToken);
      
      return(result)
    } catch (error) {
      //console.log(`After API call made to  ${process.env.REACT_APP_API_BASE_URL}`)

      ////console.log("error", error);
    }
  };

  const loginWithOtpForForgetPassword = async (phone, otp, isSellerAdmin) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: "",
      password: "",
      mobileNumber: phone,
      typeOfUser: 1,
      otp: otp,
      isOTPBasedLogin: true,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${API_BASE_URL}/users/api/v1/login`,
        requestOptions
      );
      const result = await response.json();
      ////console.log(result);
      // make second API call here if response is ok
      if(result?.result?.accessToken){
        // console.log('aaaaa')
        localStorage.setItem("token", result.result.accessToken);
        setAccessToken(result.result.accessToken);
        // const resp = await fetchData(result.result.accessToken);
        // const check = await fetchSellerPlan(resp);
        // console.log(check);
        // console.log(result);
          return result;
      }
    } catch (error) {
      ////console.log("error", error);
    }
  };

  const fetchDataStore = async (accessToken) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/users/api/v1/info`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      setAdminData(data);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return data;
    }
    catch (error) {
      //console.error(error);
    }
  }

  const logout = () => {
    setAdminData(null);
    navigate("/");
    localStorage.setItem("token", null);
  };

  return (
    <AdminContext.Provider value={{fetchDataStore, loginWithOtpForForgetPassword,handleAuthExpiry, adminData,phoneNumberForOtp,setPhoneNumberForOtp,loginWithOtp, login, logout, accessToken , selectedListItemIndex, setSelectedListItemIndex}}>
      {children}
    </AdminContext.Provider>
  );
};