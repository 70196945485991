import React from 'react'
import OtpInput from "react-otp-input";
import { useState, useEffect ,useContext} from "react";
import Button from '@mui/joy/Button';
import '../styles/CustomerLoginModal.css'
import "../styles/OtpVerify.css";
import { API_BASE_URL } from '../App'

import "../styles/Common.css";
import "../styles/SellerOtpVerify.css";
import { useFormik } from "formik";
// import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { errorAlert, successAlert } from "../components/Alert";
import { CustomerContext } from '../context/CustomerContext';
import { CircularProgress } from "@mui/material";


const phoneRegExp = /^(?!\s)([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}(?<!\s)$/;

function CustomerOtpVerifyLogin() {
    const [code, setCode] = useState("");
    // const navigate = useNavigate();
    const [timeLeft, setTimeLeft] = useState(180);
    const [loading, setLoading] = useState(false);
    const { customerData  ,setPhoneNumberForOtp ,loginWithOtp} = useContext(CustomerContext);
    const [enabled, setEnabled] = useState(false);
    const [error] = useState(false);

    const validationSchema = yup.object({
      
        mobileNumber: yup
        .string().trim()
        .matches(phoneRegExp, "Mobile number is not valid")
        .required("Mobile number is required*")
        .min(10, "Mobile number must consist of 10 digits")
        .max(10, "Mobile number must consist of 10 digits"),
    });
    const formik = useFormik({
        initialValues: {
            mobileNumber:"",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            // await checkUserExists(values.mobileNumber);
            // await postForOtp();
            setPhoneNumberForOtp(formik.values.number);
            verifyOtp();
        },
    });

    useEffect(() => {
        setEnabled(false);
        const timer = setTimeout(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000);
        if (timeLeft === 0) {
          clearTimeout(timer);
          setEnabled(true);
        }
        return () => clearTimeout(timer);
      }, [timeLeft]);
    
      
  async function verifyOtp() {
    setLoading(true);
    const response = await loginWithOtp(setPhoneNumberForOtp, code);
  
    if (!response?.result?.accessToken) {
      errorAlert('', 'Wrong OTP. Unsuccessful Login');
      setLoading(false);
    } else {
      setLoading(false);
      successAlert('', 'Login Successful', 'success');
    }
  }
  
      const resendOtp = async () => {
        try {
          let result = await fetch(
            `${API_BASE_URL}/user/api/v1/GenerateOTP?uniqueID=${customerData?.result?.mobileNumber}&mobileNumber=%2B91${`${customerData?.result?.mobileNumber}`.slice(3)}`,
            {
              headers: {
                // Accept: "application.json",
                // "Content-type": "application.json",
              },
             
            }
          );
          if(result?.ok){
            successAlert('','OTP has been resent.')
          }
          else{
            errorAlert('','Error Resending OTP.')
            return;
          }
        } catch (e) {
          errorAlert('','Error Resending OTP.')
          ////console.log(e);
          return;
        }
        setTimeLeft(180);
      };
      const handleChange = (code) => setCode(code);




  return (
    <div className='customer-signup-modal-container'>
        <form className='customer-login-modal-container'>
        <div className='customer-login-modal-login'>WE SENT YOU AN OTP</div>
        <p>Please enter the 4 digits number to verify your</p>
        <div className="otp-numbers" >
             <OtpInput
               value={code}
               onChange={handleChange}
               numInputs={4}
               separator={<span style={{ width: "8px" }}></span>}
               isInputNum={true}
               shouldAutoFocus={true}
               className="otp-verify-otp-verify-byr"
               inputStyle={{
                 border: "1px solid rgba(0, 0, 0, 1)",
                 width: "54px",
                 height: "61px",
                 fontSize: "16px",
                 color: "#000",
                 marginTop:"30%",
                 //   fontWeight: "400",
                 // caretColor: "blue"
               }}
               focusStyle={{
                border: "1px solid rgba(0, 0, 0, 1)",
                // outline: "none",
               }}
             />
           </div>
           {error && (
          <div className="otp-verify-error">Please enter a valid OTP</div>
            )}
            <div className="otp-verify-resend-otp">
          <div>
            <span>Didn't receive the OTP?</span>
          </div>
          {!enabled && (
            <div
              className="otp-verify-resend-otp-button"
              style={{ color: "black" }}
            >
              Resend
            </div>
          )}
          {enabled && (
            <div className="otp-verify-resend-otp-button" onClick={resendOtp}>
              Resend OTP
            </div>
          )}

          <div>
            {timeLeft !== 0 && <div>{timeLeft}</div>}
          </div>
        </div>
           {!loading&&
           <Button  className='customer-login-btn' fullWidth type='submit' onClick={verifyOtp}>CONTINUE</Button>}
           {loading && <div style={{display:"flex", width:"100%",alignItems:"center",justifyContent:"center"}}><CircularProgress style={{color:"pink" }} /></div>}

           </form>

    </div>
  )
}

export default CustomerOtpVerifyLogin