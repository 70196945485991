import React from "react";
import "../styles/AdminForgetPassword.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { API_BASE_URL } from '../App'

import { CircularProgress, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import MailOutlineIcon from "@mui/icons-material/MailOutline";
import sellerLogo from "../Images/logo.png";
import "../styles/Common.css";
// import { Snackbar } from '@mui/material';
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { AdminContext } from "../context/AdminContext";
import adminleftlogo from "../Images/adminleft.png"
import CallIcon from "@mui/icons-material/Call";
import { errorAlert, successAlert } from "../components/Alert";
import { useState } from "react";
const phoneRegExp = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;

const validationSchema = yup.object({
  number: yup
    .string("Enter your Phone Number")
    .trim()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone Number is required"),
});

export default function AdminForgetPassword() {
  const navigate = useNavigate();
  // const [token, setToken]=useState(true);
  const [loading, setLoading] = useState(false);
  const { setPhoneNumberForOtp } = useContext(AdminContext);
  // const [loginInProgress, setLoginInProgress] = useState(false)
  const gotoLoginPage = () => {
    navigate("/");
  };

  const formik = useFormik({
    initialValues: {
      number: "",
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      setLoading(true)
      const result = await postForOtp();
      //console.log(result);
      if (result.ok) {
        successAlert('', 'OTP sent successfully')
        setLoading(false);
        setPhoneNumberForOtp(formik.values.number.trim());
        navigate("/adminForgotPasswordOtpVerify");
      } else {
        errorAlert('', 'Error sending OTP')
        setLoading(false);
      }
    },
  });
  async function postForOtp() {
    setLoading(true)
    try {
      let result = await fetch(
        `${API_BASE_URL}/users/api/v1/otp?mobileNumber=${formik.values.number.trim()
        }&userType=${1}`,
        {
          headers: {
            // Accept: "application.json",
            // "Content-type": "application.json",
          },
        }
      );
      ////console.log(result)
      setLoading(false)
      //console.log(result);

      return result;
    } catch (e) {
      //console.log(e);
    }
  }

  // const //notify = () =>
  // toast("An Email has been sent to your Email ID. Please check.");
  return (
    <div className="admin-forget-password">
      <div className='admin-forgot-password-right'>
        <div className="seller-forgot-left-content">
          <div className='ResetPassword_LeftScreen_Image'>
            <img loading="lazy" className="admin-left-img" src={adminleftlogo} alt="reset" />
          </div>

        </div>
      </div>
      <div className='admin-forgot-password-left'>

        {/* <div className="OtpVerification-container-right-content"> */}
        <div className="seller-reset-logo">
          <img loading="lazy" alt=" " src={sellerLogo} className="seller-reset-logo" />
        </div>

        <div className="seller-reset-head ">Reset Password</div>

        <div className="admin-reset-text">
          Enter the number associated with your account and we’ll send an OTP to
          Reset your Password.
        </div>
        <form className="seller-reset-page-form" onSubmit={formik.handleSubmit}>
          <div className="admin-reset-page-email-field">
            <TextField
              variant="outlined"
              fullWidth
              autoFocus
              type="text"
              name="number"
              //   className="login-page-email"
              className="admin-login-email"
              placeholder="Enter phone number"
              value={formik.values.number.trim()}
              onChange={formik.handleChange}
              error={formik.touched.number && Boolean(formik.errors.number)}
              helperText={formik.touched.number && formik.errors.number}
            />
            <CallIcon
              className="otp-phone-icon"
              fontSize="medium "
            />
          </div>
          {!loading && <input type="submit" className="submit-button" value="Send OTP" />}
          {loading &&
            <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "pink" }} /></div>}

        </form>
        <div className="seller-reset-login">
          <span onClick={gotoLoginPage}>Return to Login</span>
        </div>
        {/* <div className="seller-reset-signUp">
          Don't have an account? <span onClick={gotoSignupPage}>Sign Up</span>
        </div> */}
        <div className="seller-reset-page-terms">
          By continuing, you agree to MyhraKi's
          <span>Terms and conditions</span> and <span>privacy policy</span>
        </div>
        {/* </div> */}

        {/* //<ToastContaner theme="coloured" className="toast-message" /> */}
        {/* <Tostify toast="admin-toastify-toast" /> */}
      </div>
    </div>
  );
}
