import React from 'react';
import '../styles/ComingSoon.css';
import whiteMyhraki from "../Images/thankYouScreenImg.png"
const ComingSoon = () => {
  return (
    <div className="coming-soon-card">
    <div className="coming-soon-container">
        <img loading="lazy" src={whiteMyhraki} alt="" style={{width:"50%"}} />
      <h1 className="title" style={{fontFamily:"Bodoni"}}>Coming Soon!!</h1>
      <p className="description">We're working on something exciting!</p>
      
    </div>
    </div>
  );
};

export default ComingSoon;
