import { Divider } from "@mui/material";
import React,{useState} from "react";
import "../styles/ViewOrder.css";
import logo from "../Images/logo.png";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
// import CachedIcon from "@mui/icons-material/Cached";
// import HomeIcon from '@mui/icons-material/Home';
// import DoneIcon from '@mui/icons-material/Done';
import TrackDetails from "../components/TrackDetails";
import Navbar from "../components/Navbar";


export default function OrderTracking(props) {
    const [tracker, setTracker]= useState(false);
    const [orderStatus]= useState("Delivered");

    function goToTrackOrder(){
        setTracker(true);
    }
  return (
    <div>
      <Navbar />
       <div className="orders-filter">
        
      </div> 
      <div className="view-order-align">
      <div className="my-orders-heading">Order Details</div>
    {!tracker&&(<div className="order-details-container">
      <div className="order-id-and-buttons">
        <div>Order ID : 12345678</div>
        <div className="order-details-buttons">
          <div className="order-get-invoice">Get Invoice</div>
          {orderStatus==="Dispatched"&&<button className="order-details-button" onClick={goToTrackOrder}>Track Order</button>}
          {orderStatus==="Dispatched"&&<button className="order-details-button">Cancel Order</button>}
          {orderStatus==="Delivered"&&<button className="order-details-button" onClick={goToTrackOrder}>Track Order</button>}
          {orderStatus==="Delivered"&&<button className="order-details-button disabled">Cancel Order</button>}
          {orderStatus==="Returned"&&<button className="order-details-button disabled" >Track Order</button>}
          {orderStatus==="Returned"&&<button className="order-details-button disabled">Cancel Order</button>}
        </div>
      </div>
      <div className="order-date">Order Date : Aug 19 2022</div>
      <div className="delivery-details" style={{marginLeft:"1rem"}}>
        {orderStatus==="Dispatched"&&<div className="blue">
        <span className="black"><LocalShippingIcon sx={{  fontSize: "1.5rem", paddingRight:"1rem" }} />
           Estimated Delivery on :</span>  August 26, 2022
        </div>}
        {orderStatus==="Delivered"&&<div className="blue">
        <span className="black"><LocalShippingIcon sx={{  fontSize: "1.5rem", paddingRight:"1rem" }} />
          Delivered on :</span>  August 26, 2022
        </div>}
        {orderStatus==="Returned"&&<div className="blue">
        <span className="black"><LocalShippingIcon sx={{ fontSize: "1.5rem" , position:"relative", top:"-1rem", paddingRight:"1rem"}} />
           Delivered on :</span> August 26, 2022<br />
          Returned on : August 30,2022
        </div>}
        <div>
          Order Status:
          <span className="order-status-highlight"> {orderStatus}</span>
        </div>
        <div>
          Status: <TaskAltIcon sx={{ color: "rgba(248, 152, 128, 1)" }} /> Paid
        </div>
      </div>
      <Divider />
      <div className="orders-heading">Items in My Orders</div>
      <div className="order-product">
      <img loading="lazy" alt=" "  src={logo} className="order-product-image" style={{width:"150px"}}/>
        <div className="order-product-desc">
          <div className="order-product-name">Miss Chase Womens Apparel</div>
          <div className="order-product-color">Color:Black</div>
          <div className="order-product-size">Size: M</div>
        </div>
        <div className="order-price-and-quantity">
          <div className="order-price">Rs:1500</div>
          <div className="order-quantity">Quantity:2</div>
        </div>
      </div>
      <Divider style={{ width: "80%", margin: "0 auto" }} />
      <div className="order-product">
        <img loading="lazy" alt=" "  src={logo} className="order-product-image" style={{width:"150px"}}/>
        <div className="order-product-desc">
          <div className="order-product-name">Miss Chase Womens Apparel</div>
          <div className="order-product-color">Color:Black</div>
          <div className="order-product-size">Size: M</div>
        </div>
        <div className="order-price-and-quantity">
          <div className="order-price">Rs:1500</div>
          <div className="order-quantity">Quantity:2</div>
        </div>
      </div>
      <Divider style={{ width: "80%", margin: "0 auto" }} />
      <div className="order-shipping-details">
        <div className="shipping-info">
            <div className="shipping-info-header">Shipping & Billing Info</div>
          <div className="shipping-info-field">
            <div className="shipping-info-field-name">Name :</div>
            <div className="shipping-info-field-value"> Rahul Mehta</div>
          </div>
          <div className="shipping-info-field">
            <div className="shipping-info-field-name">Email:</div>
            <div className="shipping-info-field-value"> abc@gmail.com</div>
          </div>
          <div className="shipping-info-field">
            <div className="shipping-info-field-name">Shipping Address:</div>
            <div className="shipping-info-field-value"> Raiya Kar Bhavan Nr Times Of India, Ashram Road, AHEMEDABAD, GUJARAT, INDIA - 380009</div>
          </div>
          <div className="shipping-info-field">
            <div className="shipping-info-field-name">Billing-address:</div>
            <div className="shipping-info-field-value">Raiya Kar Bhavan Nr Times Of India, Ashram Road, AHEMEDABAD, GUJARAT, INDIA - 380009 </div>
          </div>
          <div className="shipping-info-field">
            <div className="shipping-info-field-name">Contact:</div>
            <div className="shipping-info-field-value">99999999999</div>
          </div>
        </div>
        <div className="payment-info">
        <div className="shipping-info-header">Payment</div>
        <div className="payment-info-field">
            <div>Rahul Mehta</div>
            <div>RazorPay **********56</div>
            <Divider />
            <div className="cart-order-summary-text">
            Order Summary <span className="items-count">(4 Items)</span>
          </div>
          <div className="cart-checkout-row">
            <div className="cart-checkout-amount-text">Subtotal</div>
            <div className="cart-checkout-amount">₹ 12,500</div>
          </div>
          <div className="cart-checkout-row">
            <div className="cart-checkout-amount-text">Total Discount</div>
            <div className="cart-checkout-amount">₹ 2,500</div>
          </div>
          <div className="cart-checkout-row">
            <div className="cart-checkout-amount-text">Estimated Delivery</div>
            <div className="cart-checkout-amount">Tomorrow</div>
          </div>
          <Divider className="divider" />
          <div className="cart-checkout-row-dark">
            <div className="cart-checkout-amount-text">Total</div>
            <div className="cart-checkout-amount">₹ 10,000</div>
          </div>
            </div>
        </div>
      </div>
    </div>)}
    </div>
    {tracker&&<div>
        <TrackDetails state={orderStatus} goToDefaultView={props.goToDefautView}/>
        </div>}
    </div>
  );
}