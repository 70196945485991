import React, { createContext, useState, useContext, useEffect } from "react";
import { API_BASE_URL } from "../App";

const FilterContext = createContext();

export const useFilterContext = () => {
  return useContext(FilterContext);
};

export const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState({
    searchValue: "",
    category: "",
    priceRange: 0,
  });
  const pageSize = 20;
  const [pageNumber, setPageNumber] = useState(0);
  const [newArrival, setNewArrival] = useState(false);

  const [products, setProducts] = useState([]);
  const [suggestionLoading, setLSuggestionLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateFilters = (newFilters) => {
    console.log("filters updated");
    console.log(newFilters);
    console.log({ ...filters, ...newFilters });
    setPageNumber(0);
    setFilters({ ...filters, ...newFilters });
  };
  // const searchValue='red'

  useEffect(() => {
    // console.log(filters, "HEREEEEEE");
    const fetchData = async () => {
      setLSuggestionLoading(true);
      try {
        const response = await fetch(
          `${API_BASE_URL}/productgen/api/v1/search?Text=${
            filters?.searchValue
          }&searchForTitle=true&PageNumber=${0}&PageSize=${10}`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        console.log("this", data);
        setProducts(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLSuggestionLoading(false);
      }
    };

    if (filters.searchValue.length > 2) {
      fetchData();
    }
  }, [filters]);

  async function fetchProductData(value, category, price, newArrivalTrue) {
    console.log("Calling");
    console.log(category);
    console.log(newArrival)
    setLoading(true);

    try {
      const response = await fetch(
        `${API_BASE_URL}/productgen/api/v1/search?PageNumber=${
          (pageNumber === 0 ? 0 : pageNumber - 1) * pageSize
        }&PageSize=${pageNumber === 0 ? pageSize : pageNumber * pageSize}${
          value?.length > 0 ? `&Text=${value}` : ""
        }${category ? `&Category=${category}` : ""}${
          price >= 1 ? `&CostRange1=${1}&CostRange2=${price - 101}` : ""
        }${newArrivalTrue ? `&NewArrival=true` : ""}`,
        {
          method: "GET",
          headers: {},
        }
      );

      const data = await response.json();
      setProductList(data?.result?.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    }
  }



  // async function fetchProductData() {
  //   // console.log('Calling')
  //   setLoading(true);
  //   console.log(filters);

  //   try {
  //     const response = await fetch(
  //       `${API_BASE_URL}/productgen/api/v1/search?PageNumber=${
  //         (pageNumber === 0 ? 0 : pageNumber - 1) * pageSize
  //       }&PageSize=${pageNumber === 0 ? pageSize : pageNumber * pageSize}${
  //         filters?.searchValue.length > 0 ? `&Text=${filters.searchValue}` : ""
  //       }${filters?.category ? `&Category=${filters.category}` : ""}${
  //         filters?.priceRange >= 1
  //           ? `&CostRange1=${1}&CostRange2=${filters.priceRange - 101}`
  //           : ""
  //       }`,
  //       {
  //         method: "GET",
  //         headers: {},
  //       }
  //     );

  //     const data = await response.json();
  //     // console.log(data);
  //     // console.log(data?.result);
  //     // console.log(data?.result?.result);
  //     setProductList(data?.result?.result);
  //     // console.log(productList);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.log("Error", error);
  //   }
  // }

  // useEffect(() => {
  //   fetchProductData(filters.searchValue, filters.category, filters.priceRange);
  // }, [pageNumber]);

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.location.pathname === "/products"
    ) {
      // console.log(filters)
      // console.log('prods',products)

      fetchProductData(
        filters.searchValue,
        filters.category,
        filters.priceRange, false
      );
    }
  }, [ pageNumber]);

  const contextValue = {
    filters,
    setFilters,
    products,
    setProducts,
    loading,
    error,
    updateFilters,
    productList,
    setProductList,
    pageNumber,
    setPageNumber,
    fetchProductData,
    newArrival,
    setNewArrival
  };

  return (
    <FilterContext.Provider value={contextValue}>
      {children}
    </FilterContext.Provider>
  );
};
