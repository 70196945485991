import React from 'react'
import "../styles/AboutUsFooter.css"
import CustNavbar from '../components/CustNavbar';
import CustomerFooter from '../components/CustomerFooter';
function CustomerCancellationPolicy() {
    return (
        <>
        <CustNavbar />
        <div className="myhraki-description-cancelPolicy">
     
        <h1>MyhraKi Buyers Cancellation Policy </h1>
     
        <p>Cancellation Period: Buyers can cancel their order after any time placing it.</p>
     
        <p>Cancellation Procedure: Buyers can initiate cancellation by accessing their order history on the Myhraki platform and selecting the option to cancel the order. Alternatively, they can contact customer support for assistance with the cancellation process. </p>
     
        <p>Refund Policy: Upon successful cancellation within the stipulated time frame, buyers will receive a full refund to their original payment method. </p>
     
        <p>Exceptions: The cancellation policy may not apply to certain products or services that are explicitly labeled as non-refundable or subject to different cancellation terms. Buyers are advised to review the product description and terms before making a purchase.</p>
     
        <p>Communication: Buyers will receive confirmation of their cancellation via email or through the Myhraki platform's messaging system. </p>
     
        <p>Policy Updates: Myhraki reserves the right to modify or update the cancellation policy at any time. Any changes will be communicated to buyers through the platform's notifications or via email. </p>
     
        <p>Contact Information: For inquiries or assistance regarding cancellations, buyers can reach out to Myhraki's customer support team through the provided contact channels on the platform. </p>
       </div>
       <CustomerFooter />
        </>
      )
}

export default CustomerCancellationPolicy
