import React, { useState } from "react";
import "../styles/Subnav.css";
import { useNavigate } from "react-router-dom";

export default function Subnav() {
  const [category, setCategory] = useState("home");
  const navigate = useNavigate();

  const filterHome = () => {
    ////console.log(category);
    setCategory("home");
    navigate("/productFilter");
  };

  return (
    <div className="subnav">
      <input type="checkbox" id="subnav-check" />

      <div className="subnav-btn">
        <label for="subnav-check">
          <span></span>
          <span></span>
          <span></span>
        </label>
      </div>

      <div className="subnav-links">
        <a href="#" onClick={filterHome}>
          Home
        </a>
        <a href="#" onClick={filterHome}>
          Men
        </a>
        <a href="#" onClick={filterHome}>
          Women
        </a>
        <a href="#" onClick={filterHome}>
          Kids
        </a>
        <a href="#" onClick={filterHome}>
          Home and Kitchen
        </a>
        <a href="#" onClick={filterHome}>
          Others
        </a>
      </div>
    </div>
  );
}
