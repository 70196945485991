import React, { useContext, useRef } from "react";
import "../styles/AdminOtpVerify.css";
import "../styles/SellerForgotPasswordOtp.css";
import OtpInput from "react-otp-input";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../Images/MainLogo.png";
import { API_BASE_URL } from '../App'

import ThankYouShopping from "../Images/ResetPasswordImg.png"
import "../styles/Common.css";
// import { AdminContext } from "../context/AdminContext";
import { useFormik } from "formik";
import * as yup from "yup";
import { CircularProgress } from "@mui/material";
import { SellerContext } from "../context/SellerContext";
// import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import notif from "../components/Notify";
import CardComponent from "../components/CardComponent";
import MyhrakiTextfield from "../components/MyhrakiTextfield";
import ToastComponent from "../components/ToastComponent";


// import { replace, useFormik } from "formik";
// import * as yup from "yup";
// import AppContext from "../Context/app-context";
// import Countdown from "react-countdown";

export default function SellerForgotPasswordOtpVerify() {
  const [loading, setLoading ] = useState(false);
  const [resendOtpMessage, setResendOtpMessage] = useState(false);
  const [invalidOTP, setInvalidOTP] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const toastRef = useRef();
  const {accessToken, loginWithOtpForForgetPassword} = useContext(SellerContext)


  const passwordRegexp = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{6,20}$)"
  );
  const validationSchema = yup.object({
    password: yup
      .string("Enter your password")
      .matches(passwordRegexp, "Password is not valid")
      .min(8, "Password should contain 8 characters")
      .required("Password is required"),
    rePassword: yup
      .string("Enter Your Password again")
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .min(8, "Password should contain 8 characters")
      .required("Password is required"),
  });

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      password: "",
      rePassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      setInvalidOTP(false);
      setLoading(true);
      const response = await loginWithOtpForForgetPassword(phoneNumberForOtp?.trim(), code, isSellerAdmin);
      setResendOtpMessage(false);
      if (response?.result?.accessToken) {
        setInvalidOTP(false);
        setCode();
        setLoading(false);
        // toastRef.current.showToast("Login successful", "success");
        await postResetData(response?.result?.accessToken);
        // navigate('/SellerCategoriesListingPage')
      } else if (response?.message) {
        setInvalidOTP(true);
        // toastRef.current.showToast("Invalid OTP", "error");
        setLoading(false);
      } else {
        setLoading(false);
        setInvalidOTP(false);
  
        toastRef.current.showToast("Something went wrong. Please try later", "error");
      }
    },
  });

  // const {sellerRegistered} = useContext(AppContext)

  const {setPhoneNumberForOtp, phoneNumberForOtp, isSellerAdmin, loginWithOtp, fetchDataStore } = useContext(SellerContext);
  const [timeLeft, setTimeLeft] = useState(180);
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    setEnabled(false);
    const timer = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    if (timeLeft === 0) {
      clearTimeout(timer);
      setEnabled(true);
    }
    return () => clearTimeout(timer);
  }, [timeLeft]);



  async function postResetData(token) {
    const userId = await fetchDataStore(token);
    try {
      //console.log(sellerData)
      const response = await fetch(`${API_BASE_URL}/users/api/v1/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization:`Bearer ${token}`
        },
        body: JSON.stringify({
          userID:userId?.result?.userID,
          password: formik.values.password,
        }),
      });
  
      if (response.ok) {
        toastRef.current.showToast("Password Update successful", "success");
        navigate('/SellerCategoriesListingPage')
        return true;
      } else {
        toastRef.current.showToast("Password Could not be updated", "error");
        return false;
      }
    } catch (error) {
      toastRef.current.showToast("Something went wrong. Please try later", "error");
      return false;
    }
  
  }


  const gotoLoginPage = () => {
    navigate("/");
  };
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const handleChange = (code) => setCode(code);


  const resendOtp = async () => {
    setResendLoading(true);

    try {
      let response = await fetch(
        `${API_BASE_URL}/users/api/v1/otp?mobileNumber=${phoneNumberForOtp?.trim()}&userType=${isSellerAdmin ? 3 : 4}`,
        {
          headers: {
            // Accept: "application.json",
            // "Content-type": "application.json",
          },

        }
      );
      const result = await response.json();
      //console.log(result);

      if (result?.result > 0) {

        setInvalidOTP(false);
        setResendOtpMessage(true);
        setPhoneNumberForOtp(phoneNumberForOtp?.trim());
        setCode();
        setTimeLeft(180);
        setResendLoading(false);

      } else {
        setResendLoading(false);
        toastRef.current.showToast("Maximum otps sent. contact Admin for more", "error");

      }

    } catch (e) {
      setInvalidOTP(false);
      setLoading(false);
      toastRef.current.showToast("Something went wrong. Try again later", "error");
      setResendLoading(false);
    }
  };


  return (
    <div>
               <ToastComponent ref={toastRef} timeout={4000} />

    <CardComponent
    cardImg={<img loading="lazy" className='shopping-img-forgot' src={ThankYouShopping} alt='shopping' />}
    imageFirst={true}
  >
    {/* <div className="seller-otp-Container"> */}
      <div className="seller-otp-verify-left">
        <form className="SellerOtpVerification-container-right-content" onSubmit={formik.handleSubmit}>
          <div className="OtpVerification-Myhraki-logo">
            <img loading="lazy" src={logo} alt="title" className="Myhraki-logo" />
          </div>
          <div className="SelllerOtpVerification-title">OTP Verification</div>

          {invalidOTP && <div style={{ backgroundColor: 'lightpink', color: 'maroon', padding: '8px 24px 8px 24px', width: '60%', margin: '0 auto', fontSize: '12px', borderRadius: '10px', textAlign: 'center', position: 'relative', bottom: '15px' }} >Invalid OTP</div>}
            {resendOtpMessage && <div style={{ backgroundColor: 'rgba(2, 159, 74, 1)', color: 'white', padding: '8px 24px 8px 24px', width: '30%', margin: '0 auto', fontSize: '12px', borderRadius: '10px', textAlign: 'center', position: 'relative', bottom: '15px' }} > OTP has been Resent</div>}

          <div className="otp-numbers">
            <OtpInput
              value={code}
              onChange={handleChange}
              numInputs={4}
              separator={<span style={{ width: "8px" }}></span>}
              isInputNum={true}
              shouldAutoFocus={true}
              className="otp-verify-otp-verify"
              inputStyle={{
                border: "2px solid black",
                borderRight: "none",
                borderLeft: "none",
                borderTop: "none",
                width: "2rem",
                height: "2rem",
                fontSize: "1.5rem",
                color: "#000",
              }}
              focusStyle={{
                // border: "1px solid #CFD3DB",
                borderRight: "none",
                borderLeft: "none",
                borderTop: "none",
                outline: "none",
              }}
            />
          </div>
          {error && (
            <div className="otp-verify-error">Please enter a valid OTP</div>
          )}
          {/* <div className="admin-reset-page-phone-field"> */}
            <MyhrakiTextfield
              variant="outlined"
              fullWidth
              autoFocus
              autoComplete="off"
              type="text"
              name="password"
              placeHolder="Enter your Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            {/* <LockIcon className="admin-reset-phone-icon" fontSize="medium " /> */}
          {/* </div> */}
          {/* <div className="admin-reset-page-phone-field"> */}
            <MyhrakiTextfield
              variant="outlined"
              fullWidth
              //   autoFocus
              autoComplete="off"
              type="text"
              // id="rePassword"
              name="rePassword"
              // className="login-email"
              placeHolder="Re-Enter your Password"
              value={formik.values.rePassword}
              onChange={formik.handleChange}
              error={
                formik.touched.rePassword && Boolean(formik.errors.rePassword)
              }
              helperText={formik.touched.rePassword && formik.errors.rePassword}
            />
            {/* <LockIcon className="admin-reset-phone-icon" fontSize="medium " /> */}
          {/* </div> */}
          <div className="otp-verify-resend-otp">
            <div>
              <span>Didn't receive the OTP?</span>
            </div>
            {!enabled && (
              <div
                className="otp-verify-resend-otp-button"
                style={{ color: " #FF5569;" }}
              >
                Resend
              </div>
            )}
           
           {enabled && !resendLoading && (
                <div className="otp-verify-resend-otp-button" onClick={resendOtp}>
                  Resend OTP
                </div>
              )}
              {enabled && resendLoading && <div style={{ display: "flex", width: "10%", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "pink" }} /></div>}



            <div>
            
              {timeLeft !== 0 && <div>{timeLeft}</div>}
            </div>
          </div>
          {!loading &&<input type="submit"className="common-button-seller" value="Verify" />}
          {loading && <div style={{display:"flex", width:"100%",alignItems:"center",justifyContent:"center"}}><CircularProgress style={{color:"pink" }} /></div>}

          {/* <div className="otp-verify-signUp">New User? <span onClick={gotoSignupPage}>Sign Up</span></div> */}
          <div className="seller-reset-login">
            <span   onClick={gotoLoginPage}>Return to Login</span>
          </div>
          </form>
         
      
      </div>
    
      {/* //<ToastContaner theme="coloured" className="toast-message" /> */}

    </CardComponent>
    </div>

  );
}
