import React, { useEffect } from 'react'
import '../styles/CustomerProfilePage.css'
import "../styles/Contact.css"
import { useState } from 'react'
import { useFormik } from 'formik'
import { CircularProgress, TextField } from '@mui/material';
import * as yup from "yup";
import "../styles/ManageProfile.css"
import { useContext } from 'react';
import { errorAlert, successAlert } from '../components/Alert';
import { CustomerContext } from '../context/CustomerContext'
import VerifiedIcon from '@mui/icons-material/Verified';
import CustomerLoginModal from './CustomerLoginModal'
import { API_BASE_URL } from '../App'
import ToastComponent from './ToastComponent'
const fullNameRegExp =/^(?!.*\\s{2})(?!.*[.'-]{2})[A-Za-z][A-Za-z' ]{0,48}[A-Za-z]$/;
const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
function Profile() {
    const [edit, setEdit] = useState(false);
    const { accessToken, customerData , fetchData} = useContext(CustomerContext);
    const [profileTab, setProfileTab] = useState();
    const [loginModalOpen, setLoginModalOpen] = React.useState(false);
    const toastRef = React.useRef();
    const phoneRegExp = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;
    const [loading, setLoading] = useState(false)
    const [isEmailVerified, setIsEmailVerified] = useState(false);
const [isPhoneVerified, setIsPhoneVerified] = useState(false);

    const validationSchema = yup.object({
        email: yup
            .string("Enter your email")
            .email("Enter a valid email")
            .matches(emailRegExp, "Email is not valid")
            .trim()
            .required("Email is required"),
        mobileNumber: yup
            .string().trim()
            .matches(phoneRegExp, "Phone number is not valid")
            .required("Phone number is required*")
            .min(10, "Phone number must consist of 10 digits")
            .max(10, "Phone number must consist of 10 digits"),
        firstName: yup
            .string("Enter Your First Name")
            .required("First Name is required")
            .matches(fullNameRegExp, "Name is invalid")
            .trim()
            .min(1, "Name should be min 3 characters")
            .max(50, "Name not exceed 50 characters"),
        lastName: yup
            .string("Enter Your last Name")
            .required("Last Name is required"),
    });

    const formik = useFormik({
        initialValues: {
            email: customerData?.result?.email,
            mobileNumber: customerData?.result?.mobileNumber,
            firstName: customerData?.result?.firstName,
            lastName: customerData?.result?.lastName,
            typeOfUser: 2,
            dateOfBirth: customerData?.result?.dateOfBirth
        },
        validationSchema: validationSchema,
        onSubmit: async () => {
            console.log(('submitted'))
            await postEditData();
        },
    });

    async function postEditData() {
        setLoading(true);
        const response = await fetch(`${API_BASE_URL}/users/api/v1/update
        `, {
            method: "put",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json-patch+json'
            },

            body: JSON.stringify(
                {
                    userID: customerData?.result?.userID,
                    email: formik.values.email,
                    // mobileNumber: formik.values.mobileNumber,
                    firstName: formik.values.firstName,
                    dateOfBirth: formik.values.dateOfBirth,
                    // "password": "abcD123@4"

                }

            )

        }
        )
        const data = await response.json();
        // setLoading(false);
        // console.log(data);
        if (data.result === true) {
            // successAlert('', 'Data Updated Successfully');
            setLoading(false);
        }
        else {
            // errorAlert('', 'Something went wrong Please try again')
            setLoading(false);
        }
    }
    async function postEditNameData() {
      if (!isEmailVerified || !isPhoneVerified) {
          toastRef.current.showToast('Please complete verification before submitting.', "error");
          return;
      }
  
      console.log(formik.values.email.match(emailRegExp));
      console.log(formik.values.mobileNumber.match(phoneRegExp));
      
      if(formik.values.email.match(emailRegExp) && formik.values.mobileNumber.match(phoneRegExp)) {
          try {
              const response = await fetch(`${API_BASE_URL}/users/api/v1/update`, {
                  method: "PUT",
                  headers: {
                      Authorization: `Bearer ${accessToken}`,
                      'Content-Type': 'application/json-patch+json'
                  },
                  body: JSON.stringify({
                      userID: customerData?.result?.userID,
                      firstName: formik.values.firstName,
                  }),
              });
  
              const data = await response.json();
  
              if (data?.result === true) {
                  toastRef.current.showToast("Data Updated Successfully", "success");
                  fetchData(accessToken);
              } else {
                  toastRef.current.showToast('Something went wrong. Please try again.', "error");
              }
          } catch (error) {
              toastRef.current.showToast('Error occurred while updating data.', "error");
          } finally {
              setLoading(false);
          }
      } else {
          if (!formik.values.email.match(emailRegExp) || !formik.values.mobileNumber.match(phoneRegExp)) {
              toastRef.current.showToast('Please enter a valid Email ID and Phone number', "error");
          }
      }
  }
  
    const checkMobileExist = async (mobNumber) => {
        const data = await fetch(
          `${API_BASE_URL}/users/api/v1/checkUser?mobNumber=${mobNumber}&userTypeId=2`
        );
        const response = await data.json();
        console.log("Check" , response)
        if (response.result === true) {
          toastRef.current.showToast( 'PhoneNumber exists.', "error");
          return false;
        }
        
        return true;
      };
      const checkEmailExist = async (email) => {
        console.log(email)
        const data = await fetch(
          `${API_BASE_URL}/users/api/v1/checkUser?email=${email}&userTypeId=2`
        );
        const response = await data.json();
        console.log("Check" , response.result)
        if (response.result === true) {
          toastRef.current.showToast( 'Email already exists.', "error");
          return false;
        }
        return true;

     
      };

      async function otpForEmailChange() {
        try {
          let response = await fetch(`${API_BASE_URL}/users/api/v1/otp?email=${formik.values.email}&userType=${2}`,
            {
              headers: {
                // Accept: "application.json",
                // "Content-type": "application.json",
              },
            }
          );
          const result = await response.json();
         if(result?.result>0){
          successAlert("", "OTP Sent");
          return true;
         }
          
        } catch (e) {
            toastRef?.current?.showToast(
              "", "Failed to send OTP. Try again.",
             "error"
           );
          setLoading(false);
        }
      }
      async function otpForPhoneChange() {
        try {
          let response = await fetch(`${API_BASE_URL}/users/api/v1/otp?mobileNumber=${formik.values.mobileNumber}&userType=${2}`,
            {
              headers: {
                // Accept: "application.json",
                // "Content-type": "application.json",
              },
            }
          );
          const result = await response.json();
          console.log(result);
          console.log("Message", result.message);
          console.log("Message", result.result);
         if(result?.result>0){
          successAlert("", "OTP Sent");
          return true;
         
         }
          
        } catch (e) {
          errorAlert("", "Failed to send OTP. Try again.");
          setLoading(false);
        }
      }


      async function handleEmailEditChange() {
        if (!formik.values.email.match(emailRegExp)) {
            toastRef.current.showToast("Email Invalid", "error");
            return;
        }
        const check = await checkEmailExist(formik.values.email);
        if (check === true) {
            const response = await otpForEmailChange();
            if (response === true) {
                setIsEmailVerified(false); // Reset verification status
                setProfileTab('emailForVerificationChange');
                setLoginModalOpen(true);
            } else {
                toastRef?.current?.showToast('Could not send OTP', "error");
            }
        } else {
            toastRef?.current?.showToast('Email Already Exists.', "error");
        }
    }
    
    async function handlePhoneEditChange() {
        if (!formik.values.mobileNumber.match(phoneRegExp)) {
            toastRef.current.showToast("Phone Number Invalid", "error");
            return;
        }
        const check = await checkMobileExist(formik.values.mobileNumber);
        if (check === true) {
            const response = await otpForPhoneChange();
            if (response === true) {
                setIsPhoneVerified(false); // Reset verification status
                setProfileTab('phoneNumberForVerificationChange');
                setLoginModalOpen(true);
            } else {
                toastRef?.current?.showToast('Could not send OTP', "error");
            }
        } else {
            toastRef?.current?.showToast('Phone Number Already Exists.', "error");
        }
    }
    
    useEffect(()=>{
        fetchData(accessToken);
    },[])
  
  return (
    <div>
      <ToastComponent ref={toastRef} timeout={4000} />
     <CustomerLoginModal currentTab={profileTab} isOpen={loginModalOpen} onClose={() => setLoginModalOpen(false)}  emailForVerification={formik.values.email} phoneForVerification={formik.values.mobileNumber} editLayout={edit} setEditLayout={setEdit}/>     
                            <div className='customer-details-container'>
                                <div className='profile-page-head'>My Account</div>
                                <div className='personal-details-head'>
                                    <div className='personal-details-head-sub'>Personal Info</div>
                                    <span className='personal-details-head-sub-span'>Update your personal details here</span>
                                </div>
                               
                                <form className="manage-profile-form" onSubmit={formik.handleSubmit}>

                                    <div className="manage-form-field">
                                        <div className="manage-form-field-name">First Name</div>
                                        <div className="manage-form-field-textfield">
                                            <TextField
                                                fullwidth
                                                disabled={edit === true ? false : true}
                                                name="firstName"
                                                value={formik.values.firstName}
                                                onChange={formik.handleChange}
                                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                                helperText={formik.touched.firstName && formik.errors.firstName}
                                            />
                                        </div>
                                    </div>
                                    <div className="manage-form-field">
                                        <div className="manage-form-field-name">Email</div>
                                        <div className="manage-form-field-textfield" style={{ position: 'relative' }}>
                                            <TextField
                                                fullwidth
                                                disabled={edit === true ? false : true}

                                                name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                error={formik.touched.email && Boolean(formik.errors.email)}
                                                helperText={formik.touched.email && formik.errors.email}
                                            />
                                            {edit && formik.values.email !== customerData?.result?.email && <div type='button' className='Eamil-verify-link' style={{ position: 'absolute', width: '190px', right: '30px', color:"#FD2A80" ,cursor:"pointer" ,fontSize:"15px",textDecoration:"underline"}} onClick={handleEmailEditChange}>Verify</div>}
                                            {edit && formik.values.email === customerData?.result?.email && <div className='Eamil-verifed-link' style={{ position: 'absolute', width: '215px', right: '30px',color:"#FD2A80",display:"flex",alignItems:"center" ,cursor:"pointer" ,gap:"4px" ,fontSize:"15px"}} >
                                                <VerifiedIcon style={{width:"16px",height:"16px"}}/>Verified
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="manage-form-field">
                                        <div className="manage-form-field-name">Mobile Number</div>
                                        <div className="manage-form-field-textfield" style={{ position: 'relative' }}
                                        >
                                            <TextField
                                                fullwidth={edit === true ? false : true}
                                                disabled={edit === true ? false : true}
                                                name="mobileNumber"
                                                value={formik.values.mobileNumber}
                                                onChange={formik.handleChange} h                                    
                                                error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                                                helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
                                            />
                                            {edit && formik.values.mobileNumber !== customerData?.result?.mobileNumber && <div type='button' className='Eamil-verify-link' style={{ position: 'absolute', width: '190px', right: '30px',color:"#FD2A80" ,cursor:"pointer" ,fontSize:"15px", textDecoration:"underline"}}
                                                onClick={handlePhoneEditChange}>Verify</div>}
                                            {edit && formik.values.mobileNumber === customerData?.result?.mobileNumber && <div className='Eamil-verifed-link' style={{ position: 'absolute', width: '215px', right: '30px',color:"#FD2A80",display:"flex",alignItems:"center",cursor:"pointer" ,gap:"4px" ,fontSize:"15px"}}
                                            > <VerifiedIcon style={{width:"16px"}}/>Verified</div>}
                                        </div>

                                    </div>
                                    
                                    <div style={{ alignSelf: "flex-end",display:"flex",justifyContent:"flex-end",margin:"1rem auto",width:"90%" }}>
                                        {!edit && <button type="submit" onClick={() => { setEdit(true) }} className="byr-submit-btn ">Edit Details</button>}
                                        {edit && !loading && <input type="submit" className="byr-submit-btn " value="Submit"onClick={postEditNameData} />}
                                        {edit && loading && <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "pink" }} /></div>}
                                    </div>
                                </form>
                            </div>
                        
    </div>
  )
}
export default Profile