import React from "react";
import "../styles/SellerInviteReject.css";
import ThankYouShopping from "../Images/thankYouScreenImg.png"
import CardComponent from "../components/CardComponent";
import "../styles/Common.css";

export default function SellerInviteReject() {
  return (
    <CardComponent
    cardImg={<img loading="lazy" className='shopping-img' src={ThankYouShopping} alt='shopping' />}
    imageFirst={true}
  >
    <div className='SellerThankYouScreen-RightScreen-content'>
      {/* <div className='SellerThankYouScreen-RightScreen-title'><img loading="lazy" className="Myhraki-logo" src={MainLogo} alt=""/></div> */}
      <div className='SellerThankYouScreen-RightScreen-desc'>
        {/* <img loading="lazy" src={sad} alt="" className="sad-logo" /> */}
       <div className="RejectScreenDescription"> Thank You! "Hope to see you soon in our MyhraKi family."</div>
        {/* <p style={{marginTop:"20%" ,fontFamily:"Barlow" , fontSize:"1.1rem", color:"rgba(112, 112, 112, 0.72)",display:"flex",justifyContent:"center",textAlign:"center"}}>Thank You for your time.Hope to see you join the MyhraKi family soon.</p> */}
      </div>
    </div>
  </CardComponent>
   
  );
}
