import React, { useEffect } from "react";
import CustomerLayout from "../components/CustomerLayout";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { errorAlert } from "../components/Alert";
import { API_BASE_URL } from "../App";
import PaymentFailure from "../components/PaymentFailure";
import PaymentSuccess from "../components/PaymentSuccess";
import { CustomerContext } from "../context/CustomerContext";
import { useContext } from "react";

const CustomerPaymentConfirmationPage = () => {
  const [waiting, setWaiting] = useState(true);
  // const [transactionId, setTransactionId] = useState();
  const { handleAuthExpiry, logout } = useContext(CustomerContext);
  const [user, setUser] = useState();
  // const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [userData, setUserData] = useState();

  const fetchData = async (accessToken) => {
    try {
      const response = await fetch(`${API_BASE_URL}/users/api/v1/info`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        logout();
        return;
      }
      if (data?.result?.userID) {
        setUser(true);
        setUserData(data);
      } else {
        // setNotSure(true);
        setUser(false);
      }

      return data;
    } catch (error) {}
  };

  useEffect(() => {
    setTimeout(() => {
      fetchData(localStorage?.getItem("token"));
    }, 2000);
  }, []);
  useEffect(() => {
    if (user === true) {
      confirm();
    }
  }, [user]);

  const confirm = async () => {
    setTimeout(async () => {
      const result = await checkPaymentStatusByUserId();
      if (!result) {
        setWaiting("error");
        return;
      }
      if (result) {
        const check = await checkPaymentStatusByTransactionId(result);
        if (check === true) {
          setWaiting(false);
        } else {
          //console.log('failed at transaction ');
          return;
        }
      } else {
        // alert('failed at User id');

        return;
      }
    }, 5000);
  };

  const checkPaymentStatusByUserId = async () => {
    try {
      const data = await fetch(
        `${API_BASE_URL}/payments/api/v1/paymentStatusByUserId?UserId=${userData?.result?.userID}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage?.getItem("token")}`,
          },
        }
      );
      const response = await data.json();
      //console.log('ressssssss',response)
      // setTransactionId(response.result.orderId)
      // await new Promise((resolve) => setTimeout(resolve, 1000));

      if (response.result.paymentStatus === "COMPLETED") {
        return response.result.orderId;
      } else if (!response.result) {
        return false;
      }
      return false;
    } catch (e) {
      //console.log(e)
    }
  };

  const checkPaymentStatusByTransactionId = async (transactionId) => {
    try {
      const data = await fetch(
        `${API_BASE_URL}/payments/api/v1/paymentStatusByUserId?PaymentOrderId=${transactionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage?.getItem("token")}`,
          },
        }
      );
      const response = await data.json();
      if (response?.result[0]?.status === "Completed") {
        return true;
      }
      return false;
    } catch (e) {
      //console.log(e);
    }
  };

  return (
    <CustomerLayout>
      {waiting && waiting !== "error" && (
        <div
          style={{ width: "400px", padding: "2rem", margin: "75px auto 0px" }}
        >
          <div
            style={{
              display: "flex",
              height: "100px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress
              style={{ color: "#FD2A80", height: "100px", width: "100px" }}
            />
          </div>
          :
          <div
            style={{
              color: "#000",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "28px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            Please Wait
          </div>
          <div
            style={{
              color: "rgba(0, 0, 0, 0.50)",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "19px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            Your Payment is in progress{" "}
          </div>
        </div>
      )}
      {!waiting && (
        <div>
          <PaymentSuccess />
        </div>
      )}
      {waiting === "error" && (
        <div>
          {" "}
          <PaymentFailure />
        </div>
      )}
    </CustomerLayout>
  );
};

export default CustomerPaymentConfirmationPage;
