import React, { useContext, useEffect, useRef, useState } from "react";
import "../styles/faq.css";
import MyhrakiLogo from "../Images/Myhraki_Logo0213.png";
import {
  CaretLeft,
  CaretRight,
  MagnifyingGlass,
  UserCircle,
  Wallet,
} from "@phosphor-icons/react";
import { Heart, ShoppingCart } from "@phosphor-icons/react";
import "../styles/CustomerNavbar.css";
import {
  Truck,
  AddressBook,
  PhoneCall,
  SignOut,
  ChatsCircle,
} from "@phosphor-icons/react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import CustomerLoginModal from "./CustomerLoginModal";
import { SignIn } from "@phosphor-icons/react";
import { CustomerContext } from "../context/CustomerContext";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../App";
import { useFilterContext } from "../context/FilterContext";

const CustNavbar = () => {
  const [loginModalOpen, setLoginModalOpen] = React.useState(false);

  return (
    <div>
      <Navbar openLoginModal={() => setLoginModalOpen(true)} />
      <SecondarySearch />
      {/* <TestComponent /> */}
      <SecondaryCategoryBar />
      <CustomerLoginModal
        isOpen={loginModalOpen}
        onClose={() => setLoginModalOpen(false)}
      />
    </div>
  );
};

export default CustNavbar;

function Navbar(props) {
  const {
    customerData,
    accessToken,
    logout, cart,
    setCurrentProfileTab,
  } = useContext(CustomerContext);
  const { fetchProductData } = useFilterContext();
  const navigate = useNavigate();
  const handleLogoClick = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  useEffect(() => {
    setInnerWidth(window.innerWidth);
    //console.log(innerWidth)
  }, [window.innerWidth]);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleMyOrderClick = () => {
    setCurrentProfileTab("orders");
    handleMobileMenuClose();
    //console.log(currentProfileTab)
    navigate("/profile");
    window.scrollTo(0, 0);
  };
  const handleMyAddressClick = () => {
    setCurrentProfileTab("address");
    handleMobileMenuClose();

    navigate("/profile");
    window.scrollTo(0, 0);
  };
  const handleContactClick = () => {
    setCurrentProfileTab("help");
    handleMobileMenuClose();

    navigate("/profile");
    window.scrollTo(0, 0);
  };
  const handleFaqClick = () => {
    setCurrentProfileTab("faq");
    handleMobileMenuClose();

    navigate("/profile");
    window.scrollTo(0, 0);
  };
  const handleWishlistClick = () => {
    setCurrentProfileTab("wishlist");
    handleMobileMenuClose();
    window.scrollTo(0, 0);
    navigate("/profile");
  };
  const handleWalletClick = () => {
    setCurrentProfileTab("wallet");
    handleMobileMenuClose();
    window.scrollTo(0, 0);
    navigate("/profile");
  };
  const handleMyProfileClick = () => {
    setCurrentProfileTab("account");
    handleMobileMenuClose();
    window.scrollTo(0, 0);
    navigate("/profile");
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      // id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
      {/* <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {customerData?.result?.userID && (
        <MenuItem onClick={handleMyProfileClick}>
          <IconButton
            size="large"
            aria-label="show 4 new mails"
            color="inherit"
          >
            <UserCircle />
          </IconButton>
          <p>My Profile</p>
        </MenuItem>
      )}
      {customerData?.result?.userID && (
        <MenuItem onClick={handleMyAddressClick}>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            {/* <Badge badgeContent={17} color="error"> */}
            <AddressBook />
            {/* </Badge> */}
          </IconButton>
          <p>My Address</p>
        </MenuItem>
      )}
      {customerData?.result?.userID && (
        <MenuItem onClick={handleMyOrderClick}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <Truck />
          </IconButton>
          <p>My Orders</p>
        </MenuItem>
      )}
      {customerData?.result?.userID && (
        <MenuItem onClick={handleWishlistClick}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            {/* <Badge badgeContent={4} color="success"> */}
            <Heart />
            {/* </Badge> */}
          </IconButton>
          <p>My Wishlist</p>
        </MenuItem>
      )}
      {customerData?.result?.userID && (
        <MenuItem onClick={handleContactClick}>
          <IconButton
            aria-label="show 4 new mails"
            size="large"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <PhoneCall />
          </IconButton>
          <p>Contact Us</p>
        </MenuItem>
      )}

      {customerData?.result?.userID && (
        <MenuItem onClick={handleWalletClick}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            {/* <Badge badgeContent={4} color="success"> */}
            <Wallet />
            {/* </Badge> */}
          </IconButton>
          <p>Wallet</p>
        </MenuItem>
      )}

      {customerData?.result?.userID && (
        <MenuItem onClick={handleFaqClick}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <ChatsCircle />
          </IconButton>
          <p>FAQ</p>
        </MenuItem>
      )}
      {customerData?.result?.userID && (
        <MenuItem
          onClick={() => {
            logout();
          }}
        >
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <SignOut />
          </IconButton>
          <p>Logout</p>
        </MenuItem>
      )}
      {!customerData?.result?.userID && (
        <MenuItem onClick={() => {
          handleMenuClose();
          props.openLoginModal()
        }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <SignIn />
          </IconButton>
          <p>Login/ Register</p>
        </MenuItem>
      )}
    </Menu>
  );
  const handleBlogClick = () => {
    navigate("/Blog");
    window.scrollTo(0, 0);
    //console.log('Navigating to Blog');
  };
  const handleTendingNowClick = () => {
    updateFilters({
      searchValue: "",
      category: "",
      priceRange: 0,
    });
    fetchProductData('', '', '', false);
    navigate("/products");
    window.scrollTo(0, 0);
  };
  const handleOffersClick = () => {
    navigate("/Offers");
    window.scrollTo(0, 0);
  };
  //   //console.log(customerData);
  const {
    updateFilters,
    products,
    setProducts,
    searchValue,
    filters,
    setFilters,
  } = useFilterContext();

  const [searchText, setSearchText] = useState("");

  // const [searchValue, setSearchValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  let debounceTimer = null;

  const handleSearchChange = (event) => {
    const { value } = event.target;
    // setFilters({ ...filters, searchValue: value });
    console.log(value);
    updateFilters({ searchValue: value, category: "" });
    setSearchText(value);
  };

  const [loading, setLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);

  const [cartData, setCartData] = useState([]);
  const [addressData, setAddressData] = useState([]);

  const { handleAuthExpiry, setCart } = useContext(CustomerContext);
  const [currentTotalAmount, setCurrentTotalAmount] = useState();



  const suggestionsListRef = useRef(null);

    const handleClickOutside = (event) => {
      if (window.innerWidth <= 700) {
        return;
      }
      if (
        suggestionsListRef.current && 
        !suggestionsListRef.current.contains(event.target) 
      ) {
        console.log(suggestionsListRef.current);
  
        setProducts();
      }
        };
  

  useEffect(() => {

  
    if (window.innerWidth > 700) {
      document.addEventListener("mousedown", handleClickOutside);
    }
  
    return () => {
      if (window.innerWidth < 700) {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };
  }, []);

  useEffect(() => {
    setSuggestions(products?.result?.result?.result || []);
  }, [products]);

  return (
    <div className="nav">
      <div className="nav-container">
        <div className="icon-container">
          <img
            src={MyhrakiLogo}
            alt=""
            className="nav-img"
            onClick={handleLogoClick}
          />
        </div>
        <div className="nav-links">
          <div className="nav-link" onClick={handleTendingNowClick}>
            Trending Now
          </div>
          <div className="nav-link" onClick={handleBlogClick}>
            Blog
          </div>
          <div className="nav-link" onClick={handleOffersClick}>
            Offers
          </div>
        </div>
        <div className="nav-search">
          <MagnifyingGlass size={18} color="#666" />
          <input
            type="text"
            className="nav-search-bar"
            value={searchText}
            onChange={handleSearchChange}
            // label="Search"
            placeholder="Search"
          />
          {products?.result?.result?.result?.length > 0 && (
            <ul className="buyers-search-suggestion-list" >
              <div className="buyers-search-suggestion-list-container" ref={suggestionsListRef}>
                {products?.result?.result?.result?.map((suggestion, index) => (
                  <li
                    className={`buyers-search-suggestion`}
                    key={index}
                    onClick={() => {
                      navigate("/products");
                      setProducts();
                      setSuggestions();
                      setSearchText(suggestion?.title);
                      fetchProductData(suggestion?.title, '', '', false);
                      updateFilters({ priceRange: '', category: "", searchValue: "" });
                      console.log(filters);
                    }}

                  >
                    <span style={{ color: "gray" }}>
                      {/* {filters.searchValue} in {` `} */}
                    </span>
                    {suggestion?.title}
                  </li>
                ))}
              </div>
            </ul>
          )}
           {products?.result?.result?.result?.length > 0 &&<div style={{cursor:'pointer'}} onClick={()=>{
          setSearchText('');
          updateFilters({ priceRange: 0, category: "", searchValue: "" });
          setProducts();
          setSuggestions();
        }}>Clear</div>}
        </div>

        {!customerData?.result?.userID && (
          <button className="nav-btn" onClick={props.openLoginModal}>
            Login/Signup
          </button>
        )}
        {customerData?.result?.userID && <button className="nav-btn"></button>}
        <div className="nav-icons">
          {innerWidth > 1000 && (
            <Heart
              className="nav-icon"
              color="#fff"
              onClick={() => {
                setCurrentProfileTab("wishlist");
                navigate("/profile");

                window.scrollTo(0, 0);
              }}
            />
          )}
          {/* <Heart
              className="nav-icon"
              color="#fff"
              onClick={() => {
                // setCurrentProfileTab("wishlist");
                navigate("/profile");
                window.scrollTo(0, 0);
              }}
            /> */}
          {/* <IconButton color="inherit"> */}
          {/* <Badge
            color="error"
            onClick={() => {
              navigate("/cart");
            }}
          >
            <ShoppingCart
              className="nav-icon"
              color="#fff"
              onClick={() => {
                navigate("/cart");
                window.scrollTo(0, 0);
              }}
            />
            badgeContent={''} color="secondary"
          </Badge> */}
          <IconButton  onClick={() => {
                  navigate("/cart");
                  window.scrollTo(0, 0);
                }}>
            {/* {cart>0?<Badge badgeContent={cart} color="secondary">
              <ShoppingCart
                className="nav-icon"
                color="#fff"
                onClick={() => {
                  navigate("/cart");
                  window.scrollTo(0, 0);
                }}
              />
            </Badge>: */}
            <Badge
              badgeContent={cart}
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: "#fff", // White background
                  color: "rgb(235, 77, 128)",        // Inherit text color
                },
              }}
            >
              <ShoppingCart
                className="nav-icon"
                style={{ color: "#fff" }} // Optional: You can style the icon as well
               
              />
            </Badge>
          </IconButton>
          {/* </IconButton> */}

          {innerWidth > 1000 && (
            <UserCircle
              className="nav-icon"
              color="#fff"
              onClick={() => {
                navigate("/profile");
                window.scrollTo(0, 0);
              }}
            />
          )}
          {innerWidth <= 1000 && (
            <UserCircle
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              size={28}
              color="#fff"
            />
          )}
        </div>
        {renderMobileMenu}
        {renderMenu}
      </div>
    </div>
  );
}

function SecondarySearch() {
  // const { searchText, setSearchText } = useContext(CustomerContext);
  // const [searchValue, setSearchValue] = useState(searchText);
  const [suggestions, setSuggestions] = useState([]);
  let debounceTimer = null;
  const [searchText, setSearchText] = useState("");
  const suggestionsListRef2 = useRef(null);
 
  


  const handleSearchChange = (event) => {
    const { value } = event.target;
    // setFilters({ ...filters, searchValue: value });
    console.log(value);
    updateFilters({ searchValue: value, category: "" });
    setSearchText(value);
  };
  const {
    updateFilters,
    products,
    setProducts,
    searchValue,
    filters,
    setFilters,
    fetchProductData,
  } = useFilterContext();

  const navigate = useNavigate();
  return (
    <div className="secondary-search-cont">
      <div className="secondary-search-bar">
        <MagnifyingGlass size={18} color="#666" />
        <input
          type="text"
          className="nav-search-bar"
          value={searchText}
          onChange={handleSearchChange}
          // label="Search"
          placeholder="Search"
        />

        {products?.result?.result?.result?.length > 0 &&<div onClick={()=>{
          setSearchText('');
          updateFilters({ priceRange: 0, category: "", searchValue: "" });
          setProducts();
          setSuggestions();
        }}>Clear</div>}
        {products?.result?.result?.result?.length > 0 && (
          <ul className="buyers-search-suggestion-list buyers-search-suggestion-list-container" ref={suggestionsListRef2}>
            <div className="buyers-search-suggestion-list-container" >

              {products?.result?.result?.result?.map((suggestion, index) => (
                <li
                  className="buyers-search-suggestion"
                  key={suggestion?.productId}

                  onClick={() => {
                    navigate("/products");
                    setProducts();
                    setSuggestions();
                    setSearchText(suggestion?.title);
                    fetchProductData(suggestion?.title, "", "", false);
                    updateFilters({ priceRange: 0, category: "", searchValue: "" });
                  }}
                >
                  <span style={{ color: "gray" }}>
                    {/* {filters.searchValue} in {` `}{" "} */}
                  </span>
                  {suggestion?.title}
                </li>
              ))}
            </div>
          </ul>
        )}
      </div>
    </div>
  );
}
{
  /*----------------------------------------SECONDARY NAV BAR----------------------------------------------------------------- */
}

// function SecondaryCategoryBar() {
//   const { accessToken, setSelectedCategory } = useContext(CustomerContext);
//   const { updateFilters } = useFilterContext();
//   const [categories, setCategories] = useState([]);
//   const navigate = useNavigate();
//   const secnavbar = useRef(null);
//   const [selectedIndex, setSelectedIndex] = useState(0);
//   const [slidePosition, setSlidePosition] = useState(0);
//   const categoryWidth = 100; // Width of each category block
//   const visibleCategories = 4; // Number of visible categories

//   useEffect(() => {
//     const fetchCategories = async () => {
//       try {
//         const response = await fetch(
//           `${API_BASE_URL}/productgen/api/v1/category/All`,
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${accessToken}`,
//             },
//           }
//         );

//         if (response.ok) {
//           const data = await response.json();
//           setCategories(data);
//           //console.log("Fetched successfully");
//         } else {
//           console.error("Failed to fetch categories:", response.status);
//         }
//       } catch (error) {
//         console.error("Error fetching categories:", error);
//       }
//     };

//     fetchCategories();
//   }, []);

//   const handleLeftClick = () => {
//     const newPosition = Math.max(
//       0,
//       slidePosition - categoryWidth * visibleCategories
//     );
//     setSlidePosition(newPosition);
//     secnavbar.current.scrollBy({
//       left: -categoryWidth * visibleCategories,
//       behavior: "smooth",
//     });
//   };

//   const handleRightClick = () => {
//     const totalWidth = (categories?.result?.length || 0) * categoryWidth;
//     const newPosition = Math.min(
//       slidePosition + categoryWidth * visibleCategories,
//       totalWidth - categoryWidth * visibleCategories
//     );
//     setSlidePosition(newPosition);
//     secnavbar.current.scrollBy({
//       left: categoryWidth * visibleCategories,
//       behavior: "smooth",
//     });
//   };
//   const totalWidth = (categories?.result?.length || 0) * categoryWidth;
//   return (
//     <div
//       className="secondary-category-bar"
//       style={{ overflowX: "auto", position: "fixed" }}
//     >
//       <div className="secondary-category-bar-all">
//         <div>
//           <CaretLeft
//             style={{
//               color: slidePosition === 0 ? "#ccc" : "#EB4D80",
//             }}
//             // className="CaretLeftArrowNav1"
//             onClick={handleLeftClick}
//           />
//         </div>
//         <div
//           ref={secnavbar}
//           className="secondary-category-cont"
//           style={{ display: "flex", marginRight: "10px" }}
//         >
//           {categories?.result?.map((category, index) => (
//             <div
//               key={category?.id}
//               className="category-block"
//               onClick={() => {
//                 setSelectedCategory(category?.categoryId);
//                 updateFilters({
//                   searchValue: "",
//                   category: category?.categoryId,
//                   priceRange: { min: 0, max: 100 },
//                 });
//                 navigate("/products");
//                 window.scrollTo(0, 0);
//               }}
//               style={{
//                 marginRight: "10px",
//                 flex: "0 0 auto",
//                 width: `${categoryWidth}px`,
//               }}
//             >
//               {category.categoryName}
//             </div>
//           ))}
//         </div>
//         <div
//           style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
//         >
//           <CaretRight
//             style={{
//               transform: "translateY(-50%)",
//               color:
//                 slidePosition >= totalWidth - categoryWidth * visibleCategories
//                   ? "#ccc"
//                   : "#EB4D80",
//             }}
//             // className="CaretArrowSecNav2"
//             onClick={handleRightClick}
//           />
//         </div>
//       </div>
//     </div>
//   );
// }

function SecondaryCategoryBar() {
  const {
    accessToken,
    setSelectedCategory,
    searchText,
    setSearchText,
    currentProfileTab,
    setCurrentProfileTab,
  } = useContext(CustomerContext);
  const { filters, updateFilters, fetchProductData } = useFilterContext();
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const secnavbar = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [slidePosition, setSlidePosition] = useState(0);
  const categoryWidth = 100; // Width of each category block
  const visibleCategories = 4; // Number of visible categories
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 700); // Initial check for desktop view

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/productgen/api/v1/category/All`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCategories(data);
        } else {
          console.error("Failed to fetch categories:", response.status);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [accessToken]);

  const handleLeftClick = () => {
    // const newPosition = Math.max(
    //   0,
    //   slidePosition - categoryWidth * visibleCategories
    // );
    // setSlidePosition(newPosition);
    // secnavbar.current.scrollBy({
    //   left: -categoryWidth * visibleCategories,
    //   behavior: "smooth",
    // });
    selectedIndex > 0 && setSelectedIndex(selectedIndex - 1);
  };

  const handleRightClick = () => {
    // const totalWidth = (categories?.result?.length || 0) * categoryWidth;
    // const newPosition = Math.min(
    //   slidePosition + categoryWidth * visibleCategories,
    //   totalWidth - categoryWidth * visibleCategories
    // );
    // setSlidePosition(newPosition);
    // secnavbar.current.scrollBy({
    //   left: categoryWidth * visibleCategories,
    //   behavior: "smooth",
    // });
    selectedIndex < categories?.result?.length - 4 &&
      setSelectedIndex(selectedIndex + 1);
  };

  // Update the isDesktop state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 700);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const totalWidth = (categories?.result?.length || 0) * categoryWidth;
  return (
    <>
      {isDesktop ? (<div className="secondary-category-bar">
        <div className="secondary-category-cont">
          <CaretLeft
            size={26}
            style={{
              background: "#ffffff8a",
              color: selectedIndex === 0 ? "#ccc" : "#EB4D80",
              padding: "5px",
              borderRadius: "0px",
              cursor: "pointer",
              borderRight: "1px solid #EB4D80"
            }}
            weight="bold"
            onClick={handleLeftClick}
            className="ArrowNav"
          />

          {categories?.result
            ?.slice(selectedIndex, selectedIndex + 4)
            .map((category) => {
              return (
                <>
                  <div
                    key={category?.id}
                    className="category-block"
                    onClick={() => {
                      setSelectedCategory(category?.categoryId);
                      updateFilters({
                        searchValue: "",
                        category: category?.categoryId,
                        // priceRange: { min: 0, max: 100 },
                        priceRange: filters.priceRange
                      });

                      fetchProductData("", category?.categoryId, "", false);
                      navigate("/products");
                      window.scrollTo(0, 0);
                    }}
                  >
                    {" "}
                    {category.categoryName}

                  </div>
                </>
              );
            })}
          <CaretRight
            size={26}
            style={{
              background: "#ffffff8a",
              padding: "5px",
              borderRadius: "100px",
              cursor: "pointer",
              color:
                selectedIndex >= categories?.result?.length - visibleCategories
                  ? "#ccc"
                  : "#EB4D80",

            }}
            weight="bold"
            onClick={handleRightClick}
            className="ArrowNav"
          />
        </div>
      </div>) : (
        <div className="secondary-category-bar">
          <div className="secondary-category-cont">
            {categories?.result
              // ?.slice(selectedIndex, selectedIndex + 4)
              ?.map((category) => {
                return (
                  <>
                    <div
                      key={category?.id}
                      className="category-block"
                      onClick={() => {
                        setSelectedCategory(category?.categoryId);
                        updateFilters({
                          searchValue: "",
                          category: category?.categoryId,
                          // priceRange: { min: 0, max: 100 },
                        });
                        fetchProductData("", category?.categoryId, "", false);
                        navigate("/products");
                        window.scrollTo(0, 0);
                      }}
                    >
                      {" "}
                      {category.categoryName}
                    </div>
                  </>
                );
              })}

          </div>
        </div>
      )}
    </>

  );
}



