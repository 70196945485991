import React from 'react'
import UC from '../Images/UC.png'
import '../styles/UnderConstruction.css'
import CustomerLayout from '../components/CustomerLayout'

function UnderConstruction() {
  return (
    <CustomerLayout>
    <div className='UC'>
        <div className='UC-text1'>Website is <span className='UC-text2'>Under Construction</span> </div>
        <div >
         <img src={UC} alt="Under Construction" className='UC-Image' />
        </div>
    </div>
    </CustomerLayout>
  )
}

export default UnderConstruction
