import React, { useEffect, useRef } from 'react'
import "../styles/PrivacyPolicy.css"
export default function PrivacyPolicy(){
  const termsRef = useRef(null);

  useEffect(() => {
    termsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

    return ( 
<div className="privacy-policy-content">
  <h2  ref={termsRef}>Privacy Policy</h2>

  <h3>Information We Collect</h3>
  <ul className='privacy-policy-ul'>
    <li className='privacy-policy-li'>Name</li>
    <li>Email address</li>
    <li>Phone number</li>
    <li>Business name and address</li>
    <li>Tax ID number</li>
    <li>Payment and bank account information</li>
    <li>Shipping and order information</li>
  </ul>

  <h3>Use of Information</h3>
  <p>
    We use the personal information we collect to:
  </p>
  <ul >
    <li>Facilitate your use of our platform and services</li>
    <li>Process your payments and complete transactions</li>
    <li>Provide customer support</li>
    <li>Communicate with you about our services, updates, and promotions</li>
    <li>Verify your identity and prevent fraud</li>
    <li>Comply with legal and regulatory requirements</li>
  </ul>

  <h3>Information Sharing</h3>
  <p>
    We do not share your personal information with third parties for marketing purposes. We may share your information with third-party service providers who help us operate our platform and provide services to you, such as payment processors, shipping providers, and customer support providers. We may also share your information if required by law or if we believe it is necessary to protect our legal rights, property, or safety, or the rights, property, or safety of others.
  </p>

  <h3>Data Security</h3>
  <p>
    We take data security seriously and use industry-standard security measures to protect your personal information from unauthorized access, disclosure, and misuse. We also require our service providers to implement appropriate security measures to protect the personal information we share with them.
  </p>

  <h3>Your Rights</h3>
  <p>
    You have the right to access and correct your personal information. You can do this by logging into your account and updating your profile information. You may also request to delete your account and personal information by contacting us at <a href="mailto:privacy@myhraki.com">privacy@myhraki.com</a>. However, please note that we may retain certain information for legal or business purposes, such as to prevent fraud or resolve disputes.
  </p>

  <h3>Changes to this Privacy Policy</h3>
  <p>
    We may update this privacy policy from time to time to reflect changes in our business practices or legal requirements. We will notify you of any material changes by posting a notice on our platform or by sending you an email. Your continued use of our platform after such notice constitutes your acceptance of the updated privacy policy.
  </p>

  <h3>Contact Us</h3>
  <p>
    If you have any questions or concerns about this privacy policy or our use of your personal information, please contact us at <a href="mailto:Hello@myhraki.com">Hello@myhraki.com</a>.
  </p>
</div>)
}