import React from "react";
import "../styles/OtpPage.css";
import "../styles/Common.css";
import "../styles/SellerOtpConfirmationPage.css";
// import { useFormik } from "formik";
// import * as yup from "yup";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SellerContext } from "../context/SellerContext";
// import { useState } from "react";
import { useContext } from "react";
// import { useEffect } from "react";
import { useFormik } from "formik";

import ResetPasswordImg from "../Images/ResetPasswordImg.png"
// import * as yup from "yup";
import logo from "../Images/MainLogo.png";
import CardComponent from "../components/CardComponent";
// import { errorAlert, successAlert } from "../components/Alert";
import { useState } from "react";
import MyhrakiTextfield from "../components/MyhrakiTextfield";
import { useRef } from "react";
import ToastComponent from "../components/ToastComponent";
import { API_BASE_URL } from "../App";


const recipient = "hello@myhraki.com"; // Replace with the email address of the recipient
const subject = "Request here to change phone number"; // Replace with the subject you want to pre-fill
const gmailUrl = `https://mail.google.com/mail/?view=cm&to=${recipient}&su=${encodeURIComponent(subject)}`;

export default function SellerOtpConfirmationPage() {
    const {sellerData} = useContext(SellerContext);

    const [loading, setLoading] = useState(false);
    const toastRef= useRef();
   

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      number:sellerData?.result?.phone,
    },
    // validationSchema: validationSchema,
    onSubmit: async() => {
      await postData();

    },
  });


  async function postData() {
    setLoading(true)
    try {
      let result = await fetch(
        `${API_BASE_URL}/seller/api/v1/GenerateOTP?uniqueID=${sellerData?.result?.guid}&phoneNumber=%2B91${`${sellerData?.result?.phone}`.slice(3)}`,
        {
          headers: {
     
          },
         
        }
      );
      ////console.log(result);
      setLoading(false);
     
      if(result?.ok){
        toastRef.current.showToast("An OTP has been sent to your number", "success");
        navigate("/sellerOtpVerify");
      }
      else{
        toastRef.current.showToast("Could not send OTP.", "error");
        setLoading(false);

        return;
      }
    } catch (e) {
      toastRef.current.showToast("Something went wrong. Please try later", "error");
      setLoading(false);

      ////console.log(e);
      return;
    }
  }
  return (
    <div>
                     <ToastComponent ref={toastRef} timeout={4000} />

    <CardComponent
    cardImg={<img loading="lazy" className="reset-password-img" src={ResetPasswordImg} alt="reset" />
  }
    imageFirst={true}
  >
    <div className='ResetPassword_RightScreen'>
    <div className='ResetPassword_Content_img'> <img loading="lazy" src={logo} className="Myhraki-logo" alt='title' /> </div>
    <div className="ResetPassword_Content_Title">Sign In With One Time Password</div>
    <div className='ResetPassword_Content_TextField'>
    <form className="seller-otp-confirmation-page-form" onSubmit={formik.handleSubmit}>
    <div className="otp-page-email-field">
      <div style={{color:" #000" ,fontSize:"14px" ,opacity:" 0.7"}}>Enter your Phone Number</div>
      <MyhrakiTextfield
        variant="outlined"
        fullWidth
        // autoFocus
        disabled
        type="text"
        // id="number"
        name="number"
        className={`seller-otp-confirmation-num-field custom-background`}
        placeHolder="Enter your Phone Number"
        value={sellerData?.result?.phone}
        // onChange={formik.handleChange}
        // error={formik.touched.result.phone && Boolean(formik.errors.result.phone)}
        // helperText={formik.touched.result.phone && formik.errors.result.phone}
      />
      {/* <CallIcon className="seller-otp-confirmation-email-icon" fontSize="medium " /> */}
    </div>
    <div className="ResetPassword_Button_Field">
    {!loading&&<input type="submit" className="common-button-seller" value="Send OTP" />}</div>
    {loading && <div style={{display:"flex", width:"100%",alignItems:"center",justifyContent:"center"}}><CircularProgress style={{color:"pink" }} /></div>}

  </form>
  <div className="seller-otp-login-content-desc">
          We need to send a verification code to Authenticate your number.
        </div>
  {/* <div  className="seller-otp-login-content-desc"> Not able to Sign In?</div> */}
        <a href={gmailUrl} className="requet-to-change-phone-number">Get Help</a>
        
        {/* <div className="admin-login-page-terms">
            By continuing, you agree to MyhraKi's
            <span> Terms and conditions</span> and <span>privacy policy</span>
          </div> */}
       
  </div>
    </div>
    </CardComponent>
    </div>

  )
}