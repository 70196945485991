import { Divider } from "@mui/material";
import React, { useState } from "react";
import "../styles/ViewOrder.css";
import TrackDetails from "../components/TrackDetails";
import orderInfo from "../OrderDetailsData"
import jutha from "../Images/Jutha.png"
import "../styles/Common.css"
import CustomerLayout from "../components/CustomerLayout";

export default function ViewOrder() {
  const [tracker] = useState(false);
  const [orderStatus] = useState("Delivered");


  return (
    <CustomerLayout>
      
      <div className="view-order-align">
        <div className="my-orders-heading">Order Details</div>
        {!tracker && (
          <div className="order-details-container">
            <div className="order-id-and-buttons">
              <div>Order ID:{orderInfo.orderDetails.orderID}</div>
              <button className="CustomerPlain-common-btn">+ Get Invoice</button>
             
            </div>
            <div className="order-date">Order Date:{orderInfo.orderDetails.orderDate}</div>
            <div className="order-shipping-details">
              <div className="shipping-info">
                <div className="shipping-info-header">Shipping & Billing Info</div>
                <div className="shipping-info-field">
                  <div className="shipping-info-field-value">{orderInfo.shippingInfo.name}</div>
                </div>
                <div className="shipping-info-field">
                  <div className="shipping-info-field-value">
                  {orderInfo.shippingInfo.billingAddress}
                  </div>
                </div>
               
              </div>
              <div className="payment-info">
                <div className="shipping-info-header">Payment</div>
                <div className="payment-info-field">
                  <div>{orderInfo.paymentInfo.paymentMethod}</div>
                  </div>
                  </div>
                  <div>
                  <div className="shipping-info-header">
                    Order Summary 
                  </div>
                  
                  <div className="cart-checkout-row">
                    <div className="cart-checkout-amount-text">Subtotal</div>
                    <div className="cart-checkout-amount">₹ 12,500</div>
                  </div>
                  <div className="cart-checkout-row">
                    <div className="cart-checkout-amount-text">Total Discount</div>
                    <div className="cart-checkout-amount">₹ 2,500</div>
                  </div>
                  <div className="cart-checkout-row">
                    <div className="cart-checkout-amount-text">Estimated Delivery</div>
                    <div className="cart-checkout-amount">Tomorrow</div>
                  </div>
                  <Divider className="divider" />
                  <div className="cart-checkout-row-dark">
                    <div className="cart-checkout-amount-text">Total</div>
                    <div className="cart-checkout-amount">₹ 10,000</div>
                  </div>
              </div>
            </div>
            <div className="order-shipping-products">
          
          <div className="order-date" style={{color: "rgba(2, 159, 74, 1)",fontSize:"16px"}}>Order Date:{orderInfo.orderDetails.orderDate}</div>
            <div className="order-product">
              <img loading="lazy" alt=" " src={jutha} className="order-product-image" />
              <div className="order-product-desc">
                <div className="order-product-name">Miss Chase Womens Apparel</div>
                <div className="order-product-size">Size: M</div>
                {/* <div className="order-product-color">Color: Black</div>
             
                <div className="order-price">Rs: 1500</div>
                <div className="order-quantity">Quantity: 2</div> */}
                <div style={{display:"flex",gap:"16px"}}>
              <button className="BuyAgainButton">Buy again</button>
              <button  className="Return-PlainButton">Return</button>
             </div>
            
            </div>
        
            </div>
            <Divider style={{ width: "100%", margin: "0 auto" }} />
            <div className="order-product">
              <img loading="lazy" alt=" " src={jutha} className="order-product-image" />
              <div className="order-product-desc">
                <div className="order-product-name">Miss Chase Womens Apparel</div>
                <div className="order-product-size">Size: M</div>
                {/* <div className="order-product-color">Color: Black</div>
             
                <div className="order-price">Rs: 1500</div>
                <div className="order-quantity">Quantity: 2</div> */}
                <div style={{display:"flex",gap:"16px"}}>
              <button className="BuyAgainButton">Buy again</button>
              <button  className="Return-PlainButton">Return</button>
             </div>
            
            </div>
        
            </div>

           </div>
          </div>
        )}
      </div>
      {tracker && (
        <div className="track-order-details-page">
          <TrackDetails state={orderStatus} />
        </div>
      )}
    </CustomerLayout>

  );
}
