import { useState, useContext } from "react";
import "../styles/AdminLogin.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { CircularProgress, IconButton, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import logo from "../Images/MainLogo.png";
import AdminLoginImg from '../Images/adminotpverifyimg.png'
import "../styles/Common.css";
import { Alert } from "@mui/lab";
import { errorAlert, successAlert } from "../components/Alert";
import { AdminContext } from "../context/AdminContext";
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Visibility, VisibilityOff } from "@mui/icons-material";
//import notif from "../components/Notify";
const passwordRegexp = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{6,20}$)"
);
const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    // .trim()
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .matches(passwordRegexp, "Password is not valid")
    .min(8, "Password should be minimum 8 characters")
    .required("Password is required"),
});

export default function AdminLogin() {
  const navigate = useNavigate();

  // const [loginInProgress, setLoginInProgress] = useState(false)
  const [loading, setLoading ] = useState(false);
  const [error] = useState(false);

  const { login } = useContext(AdminContext);
 
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const result = await login(values.email, values.password);
      if (!result?.result?.accessToken) {
        setLoading(false);
        errorAlert('Wrong Password','Unsuccessful Login',error)
      } else {
        successAlert('','Login Successful','success')
        setLoading(false);

      }
    },
  });
  const [passwordShown, setPasswordShown] = useState(false);
  // const togglePassword = () => {
  //   setPasswordShown(!passwordShown);
  // };




    const [showPassword, setShowPassword] = useState(false);
    const [passwordEntered, setPasswordEntered] = useState(false);
    
    const handleTogglePassword = () => {
      setShowPassword(!showPassword);
    };
    
    const handlePasswordChange = (event) => {
      const value = event.target.value;
      setPasswordEntered(value !== '');
    };
  return (
    <div className="admin-login-page-container">
      <div className="admin-login-page-left">
        <div className="admin-login-page-card">
          {error && (
            <Alert severity="error" className="admin-login-page-error-toast">
              Invalid Email or Password
            </Alert>
          )}

          <div className="admin-login-head">
            Sign In
          </div>
          <form
            className="admin-login-page-form"
            onSubmit={formik.handleSubmit}
          >
           <div className="admin-login-page-email-field">
              <TextField
                autoFocus
                variant="outlined"
                fullWidth
                type="text"
              
                id="login-page-email"
                name="email"
                className="admin-login-email"
                placeholder="Enter Email Address * "
                value={formik.values.email.trim()}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <MailOutlineIcon
                className="admin-login-password-icon"
                fontSize="medium "
              />
            </div>

            <div className="admin-login-page-password-field">
              <TextField
                variant="outlined"
                 type={showPassword ? 'text' : 'password'}
                id="login-page-password"
                name="password"
                fullWidth
                className="admin-login-password"
                placeholder="Enter Your Password * "
                value={formik.values.password}
                onChange={(event) => {
                  formik.handleChange(event);
                  handlePasswordChange(event);
                }}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: passwordEntered && (
                  
                      <IconButton onClick={handleTogglePassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
               
                  ),
                }}
              />
              <LockOpenIcon
                className="admin-login-password-icon"
                fontSize="medium "
              />
            </div>
        
            <label className="admin-login-page-checkbox seller-login-page-checkbox">
              <input
                type="checkbox"
                style={{
                  fontSize: "5em",
                  marginRight: "10px",
                  transform: "scale(1.5)",
                }}
                name="checkbox"
                value="value"
              />
              Remember me?
            </label>

           {!loading&& <input
              type="submit"
              className="common-button"
              value="Sign In"
            />}
            {loading&&
       <div style={{display:"flex", width:"100%",alignItems:"center",justifyContent:"center"}}><CircularProgress style={{color:"pink" }} /></div>}

          </form>
          <div className="admin-login-page-forgot seller-login-page-forgot">
            <span
              onClick={() => {
                navigate("/AdminForgetPassword");
              }}
            >
              Forget Password?
            </span>
          </div>
          {/* <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <hr style={{ width: "45%", height: " 0px", alignSelf: "center" }} />
            <p style={{ fontSize: "1.5rem", alignSelf: "center", color: "#FF7891" }}>or</p>
            <hr style={{ width: "45%", height: " 0px", alignSelf: "center" }} />
          </div> */}
          <div className="sign-in-with-otp-accept-terms-field">
          <button
             className="common-button"
            onClick={() => {
              navigate("/AdminOtpLogin");
            }}
          >
            Sign In with OTP
          </button>

          <div className="admin-login-page-terms">
            By continuing, you agree to MyhraKi's
            <span> Terms and conditions</span> and <span>privacy policy</span>
          </div>
          </div>
        </div>
      </div>
      <div className="admin-login-page-right">
        <div className="admin-login-page-right-content" >
      <div className=".admin-login-right-img">
          <img loading="lazy" alt=" " className="Myhraki-logo" src={logo} />
        </div>
        <div className="admin-login-right-img-field" style={{marginbottom:"30px"}} >
          <img loading="lazy" alt=" " className="admin-login-right-img" src={AdminLoginImg} />
        </div>
        </div>
      </div>
      {/* //<ToastContaner theme="coloured" className="toast-message" /> */}

    </div>
  );
}
