import Swal from 'sweetalert2';
import "../styles/Alert.css";

export const showAlert = (title, text, icon, styles) => {
  // const styleTag = document.createElement('style');
  // styleTag.innerHTML = `
  //   .swal2-confirm:focus {
  //     outline: none !important;
  //     box-shadow: none !important;
  //   }
  //   .swal2-icon.swal2-info {
  //     border-color: pink !important;
  // }
  // `;
  // document.head.appendChild(styleTag);

  Swal.fire({
    title: title,
    text: text,
    icon: icon || 'info',
    confirmButtonText: 'OK',
    customClass: {
      content: styles || '',
      popup: '',
    },
    didOpen: () => {
      const popup = Swal.getPopup();
      const confirmButton = Swal.getConfirmButton();
      popup.style.backgroundColor = '#f2f2f2';
      // popup.style.zIndex= 100000;
      confirmButton.style.backgroundColor = "#ff7891";
      confirmButton.style.border="none";

   
    },
  });
};


export const successAlert = (alertTitle, alertText, alertIcon, alertStyles) => {
  const swalOptions = {
    title: alertTitle,
    text: alertText,
    icon: 'success',
    customClass: {
      // content: alertStyles || '',
      // popup: '',
    },
    didOpen: () => {
      const popup = Swal.getPopup();
      const confirmButton = Swal.getConfirmButton();

      // popup.style.backgroundColor = '#f2f2f2';
      confirmButton.style.display = "none";

    },
  };

  const swal = Swal.fire(swalOptions);

  setTimeout(() => {
    swal.close();
  }, 2000);
};
export const errorAlert = (alertTitle, alertText, alertIcon, alertStyles,lastingTime, button) => {
  const swalOptions = {
    title: alertTitle,
    text: alertText,
    icon: 'error',
    customClass: {
      // content: alertStyles || '',
      // popup: '',
    },
    didOpen: () => {
      const popup = Swal.getPopup();
      const confirmButton = Swal.getConfirmButton();

      // popup.style.backgroundColor = '#f2f2f2';
      confirmButton.style.display =button===true? "inline-block":"none";
      confirmButton.style.backgroundColor =button===true? "#ff7891":"#fff";

    },
  };

  const swal = Swal.fire(swalOptions, lastingTime);

  setTimeout(() => {
    swal.close();
  }, lastingTime?lastingTime:2000);
};

export const confirmAlert = (title, text, onConfirm) => {
  Swal.fire({
    title: title,
    text: text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#ff7891',
    cancelButtonColor: '#888',
    confirmButtonText: 'Confirm'
  }).then((result) => {
    if (result.isConfirmed) {
      if (typeof onConfirm === 'function') {
        onConfirm();
      }
      Swal.fire(
        '',
        ' Successful.',
        'success'
      );
    }
  });
};
export const okAlert = (title, text, onConfirm) => {
  Swal.fire({
    title: title,
    html: text,
    icon: 'warning',
    showCancelButton: false,
    confirmButtonColor: '#ff7891',
    cancelButtonColor: '#888',
    confirmButtonText: 'OK'
  }).then((result) => {
    if (result.isConfirmed) {
      if (typeof onConfirm === 'function') {
        onConfirm();
      }
    }
  });
};



