import React, { useState, useEffect, useContext } from "react";
import "../styles/SellerRegistration.css";
import "../styles/admin.css";
import "../styles/Common.css";
import Heading from "../components/Heading";
import { API_BASE_URL } from '../App'

import { useNavigate } from "react-router-dom/dist";
import AdminLayoutAdjustment from "../components/AdminLayoutAdjustment";
import { AdminContext } from "../context/AdminContext";
// import withAdminAuth from "../context/withAdminAuth";
import { errorAlert } from "../components/Alert";

function AdminApproval() {
  const navigate = useNavigate();
  const { accessToken } = useContext(AdminContext);
  const [requestFailed, setRequestFailed] = useState(false);

  useEffect(() => {
    if (accessToken) {
      fetchAdminApprovalData();
    }
  }, [accessToken]);

  const openCardDetails = (id) => {
    ////console.log(id);
    navigate(`/adminApprovalDetailVerification/${id}`);
  };
  const [adminApprovalData, setAdminApprovalData] = useState([]);

  const fetchAdminApprovalData = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/seller/api/v1/GetSellerById&GetAllSeller`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 401) {
        throw new Error("Unauthorized");
      }
      const data = await response.json();
      if(data?.status===401){
        errorAlert('','Session timed out. Please Log in');
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate('/')
      }
      setAdminApprovalData(data.result);
      ////console.log(data);
      setRequestFailed(false);
    } catch (error) {
      //console.error(error);
      setRequestFailed(true);
      navigate("/"); // use navigate function instead of push method
    }
  };

  useEffect(() => {
    if (requestFailed) {
      navigate("/"); // use navigate function instead of push method
    }
  }, [requestFailed, navigate]);

  // useEffect(() => {
  //   fetchAdminApprovalData();
  // }, []);


  const filteredData = adminApprovalData?.filter((props) => {
    return (
      props.companyName &&
      props.companyAddress &&
      props.sellerName &&
      props.sellerMobileNumber &&
      props.emailId &&
      props.aadharNumber &&
      props.panNumber &&
      props.accountNumber &&
      props.accountHolderName &&
      props.bankIFSC &&
      props.branchCode &&
      props.status &&
      props.dateOfSubmition &&
      props.typeOfAccount &&
      props.sellerPlanName
    );
  });
  
  // Sort the filteredData array by dateOfSubmition in ascending order
  adminApprovalData?.sort((a, b) => {
    return new Date(a.dateOfSubmition) - new Date(b.dateOfSubmition);
  });

  return (
    <AdminLayoutAdjustment>
      <Heading text="Admin Approval" />
      <div className="layout-container"> 
      <table class="admin-approval-table">
        <tbody >
          <tr >
            <th style={{borderRadius:"10px 0px 0px 10px" ,}}>Company Name</th>
            <th style={{}}>Requested Date</th>
            <th style={{}}>Seller Name</th>
            <th style={{borderRadius:"0px 10px 10px 0px" }}>Status</th>
          </tr>
          {adminApprovalData.map((props) => {
            ////console.log("data", adminApprovalData[0]);
          
              ////console.log(props.sellerName);
              return (
                <tr onClick={() => openCardDetails(props.sellerId)} >
                  <td data-th="Company Name">{props.companyName}</td>
                  <td data-th="Requested Date">
                    {props.dateOfSubmition.substr(0, 10)}
                  </td>
                  <td data-th="Subscription Plan">{props.sellerName}</td>
                  <td data-th="Status">
                    <div className="admin-approval-status">
                      {props.status === "P" && (
                        <div className="status-indicator-scheduled"> </div>
                      )}
                      {props.status === "C" && (
                        <div className="status-indicator-active"> </div>
                      )}
                      {props.status === "R" && (
                        <div className="status-indicator-expired"> </div>
                      )}
                      <div>{props.status}</div>
                    </div>
                  </td>
                </tr>
              );
            
          })}
        </tbody>
      </table>
      </div>
      {/* <button onClick={()=>{navigate('/inviteSeller2')}}  className="common-btn">Invite Seller</button> */}
    </AdminLayoutAdjustment>
  );
}

export default (AdminApproval);
