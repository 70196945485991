import { Button, Checkbox } from "@mui/material";
import React from "react";
import "../styles/CustomerLandingPage.css";
import { Star } from "@phosphor-icons/react";
import { Heart } from "@phosphor-icons/react";
import { addToWishlist, deleteFromWishlist } from "../utils/addToWishlist";
import { useNavigate } from "react-router-dom";
import { CustomerContext } from "../context/CustomerContext";
import { useContext } from "react";
import { useState } from "react";
import ToastComponent from "./ToastComponent";
import { useRef } from "react";
import CustomerLoginModal from "./CustomerLoginModal";

function CustomerProductCard(props) {
  const [isFavorite, setIsFavorite] = useState(false);
  const toastRef = useRef();
  const navigate = useNavigate();
  const { customerData, accessToken } = useContext(CustomerContext);
  const [loginModalOpen, setLoginModalOpen] = React.useState(false);

  // const handleHeartClick = async (props) => {
  //     console.log('click fav')

  //     setIsFavorite(!isFavorite);
  //     const added = await addToWishlist(props, customerData, accessToken);
  //     if (added !=false){
  //       toastRef.current.showToast("Item added to wishlist", "success");
  //       toastRef.current.showToast2('Item added to wishlist', 'view wishlist', 'success');
  //     }
  //    };
  const handleHeartClick = async (props) => {
    //console.log('click fav')

    if (!customerData?.result?.userID) {
      setLoginModalOpen(true);
      return;
    }

    setIsFavorite(!isFavorite);
    const added = await addToWishlist(props, customerData, accessToken);
    if (added !== false) {
      // Toast or other actions after successful addition
      // toastRef.current.showToast2('Item added to wishlist', 'view wishlist', 'success');
    }
  };

  const handleHeartDeleteClick = async (id) => {
    //console.log('click fav')
    setIsFavorite(!isFavorite);
    deleteFromWishlist(accessToken, id);
  };

  const checkboxStyle = {
    // Define the default style for the Checkbox
    width: "24px",
    height: "32px",
    color: "",
    // Use a ternary operator to set the background color to red when it's a favorite
    backgroundColor: isFavorite ? "red" : "transparent",
  };

  return (
    <>
      <ToastComponent ref={toastRef} timeout={4000} />
      <CustomerLoginModal
        isOpen={loginModalOpen}
        onClose={() => {
          setLoginModalOpen(false);
        }}
      />
      <div className="customer-product-card">
        <div className="customer-product-card-img">
          <div>
            <img
              loading="lazy"
              className="Product_card_img_layout"
              src={
                props.productSKUs?.[0]?.imagesUriList?.[0]?.uri || props.image
              }
              alt=""
              onClick={() => {
                navigate(`/productDetail/${props.productId}`);
              }}
            />
          </div>
          <div className="customer-product-card-fav">
            {!isFavorite ? (
              <Heart
                size={32}
                color="rgba(255, 189, 112, 0.6)"
                onClick={() => {
                  handleHeartClick(props);
                }}
              />
            ) : (
              <Heart
                size={32}
                weight="fill"
                color="#FD2A80"
                onClick={() => {
                  handleHeartDeleteClick(props.ID);
                }}
              />
            )}
          </div>
        </div>
        <div
          onClick={() => {
            navigate(`/productDetail/${props.productId}`);
          }}
        >
          <div className="customer-product-card-title">{props.title}</div>
          <div className="customer-product-card-category">
            {props.category ? props.category.split("/")[0] : ""}
          </div>
          {/* <div className='customer-product-card-price'>₹{props.productSKUs?.[0]?.sellingPrice?parseFloat(props.productSKUs?.[0]?.sellingPrice) + 100:props.cost+100}/-</div> */}

          <div className="customer-product-card-price">
            ₹
            {props.productSKUs?.[0]?.sellingPrice
              ? (
                  parseFloat(props.productSKUs?.[0]?.sellingPrice) + 100
                ).toFixed(0)
              : (parseFloat(props.cost) + 100).toFixed(0)}
            /-
          </div>

          <div className="customer-product-card-review">
            <Star size={15} fill="green" weight="fill" /> | {props.avgRating}{" "}
            Reviews
          </div>
          <div className="customer-product-card-quantity">
          {props.productSKUs?.[0]?.quantity >= 0 ? props.productSKUs[0].quantity + ' products in stock' : '0 products in stock '}
          </div>
          <Button
            onClick={() => {
              navigate(`/productDetail/${props.productId}`);
            }}
            contained
            fullWidth
            className="customer-product-card-button"
          >
            {props.wishlistCard ? "Add to Cart" : "View Product"}
          </Button>
        </div>
      </div>
    </>
  );
}

export default CustomerProductCard;
