import React, { useState} from 'react'
import AdminLayoutAdjustment from '../components/AdminLayoutAdjustment'
import { CircularProgress, TextField } from '@mui/material'
import "../styles/TrackOrder.css"
import { API_BASE_URL } from '../App'
import { errorAlert } from '../components/Alert'

function TrackOrder() {
    const [searching, setSearching] = useState(false);
    const [loading, setLoading] = useState(false);
    const [orderId, setOrderId] = useState('');
    const [orderReferenceIdDetails, setOrderReferenceIdDetails] = useState();
    const [orderData, setOrderData] = useState(null);
    // const { accessToken } = useContext(AdminContext);
    const getOrderReferenceIdDetails = async () => {
        setOrderData(null);
        setLoading(true)
        setSearching(true);
        try {
            let response = await fetch(`${API_BASE_URL}/order/api/v1/orderDetailsById?orderid=${orderId}`, {
                method: "GET",
                headers: {
                    // Authentication:`Bearer ${accessToken}`,
                }
            },
            );
            const data = await response.json();
            //console.log(data);
            if (data.result?.success === true) {
                setOrderReferenceIdDetails(data.result?.result)
                setSearching(false);
                setLoading(false)
                // const orderDetails = await getOrderData();

            } else if (data.result?.success === false) {
                errorAlert('', 'Order does not exist. Please check the Order ID')
                setSearching(false);
                setLoading(false)
                return;
            }
            else {
                errorAlert('', 'Something went Wrong.')
                setSearching(false);
                setLoading(false)
            }
        }
        catch {
            setSearching(false);
            setLoading(false)
            errorAlert('', 'Something went Wrong.')
        }
    }
    // const getOrderData = async () => {
    //     try {
    //         const response = await fetch(`${API_BASE_URL}/order/api/v1/items?orderId=${orderId}&orderRefNumber=${orderReferenceIdDetails?.id}&buyermail=${orderReferenceIdDetails?.email}&buyermobile=${orderReferenceIdDetails?.contact}`, {
    //             method: "GET",
    //             headers: {
    //                 // Authentication:`Bearer ${accessToken}`,
    //             }
    //         }
    //         )
    //         const data = await response.json();
    //         setOrderData(data.result);
    //         //console.log(data);
    //         return data;

    //     } catch {

    //     }
    // }
    return (
        <AdminLayoutAdjustment>
            <div className="track-order" >
                <div className="track-order-searchbar">
                    <TextField fullwidth outlined placeholder='Enter the Order ID' onChange={(e) => { setOrderId(e.target.value) }} />
                </div>
                <div className="track-order-search-button">
                    {!searching && <button className="common-button" onClick={getOrderReferenceIdDetails}>Search</button>}
                    {searching &&
                        <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "pink" }} /></div>}

                </div>
            </div>
            {loading && <div className="order-track-details" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "pink" }} /></div>}

            {!loading && orderData && <div className="order-track-details">
                <div className="order-details-id">
                    Order Id: {orderReferenceIdDetails.id}
                </div>
                <div className="order-details-personal-details">
                    <p className="order-details-personal-details-field">Name : {orderReferenceIdDetails.name}</p>
                    <p className="order-details-personal-details-field">Email : {orderReferenceIdDetails.email}</p>
                    <p className="order-details-personal-details-field">Contact : {orderReferenceIdDetails.contact}</p>
                </div>

                <div className="order-details-details">
                    {orderData?.map((item) => {

                        return (
                            <div className="order-details-details-card">
                                <div className="order-details-card-img">
                                    {/* Image File:  {item.imageFile} */}
                                    <img loading="lazy" src="https://picsum.photos/100" alt="A" />
                                </div>
                                <div>
                                    <div>
                                        Item Cost:  {item.itemCost}
                                    </div>
                                    
                                    <div >
                                        Quantity:  {item.quantity}
                                    </div>
                                    <div>
                                        Item Total: {item.itemCost * item.quantity}
                                    </div>
                                </div>
                                
                            </div>
                        )

                    })
                    }
                </div>

            </div>}

        </AdminLayoutAdjustment>
    )
}

export default TrackOrder