import React from 'react'
import "../styles/PaymentSuccess.css"
import {XCircle} from '@phosphor-icons/react'

function PaymentFailure({type}) {
  return (
    <div className='Payment-display-container'>
        <XCircle style={{ width: "152px", height: "152px",color: "rgba(255, 0, 0, 0.6)"}} />
        <div className='Payment-success-title'>Payment Failed.</div>
       {type!=="seller" &&<p style={{color:"rgba(0, 0, 0, 0.5)",fontSize:"19px"}}>Your recent payment hasn't been reflected in our system. Please contact our support team for assistance. Call 1800 203 1217 for more.</p>}
        {type==="seller"&&<p style={{color:"rgba(0, 0, 0, 0.5)",fontSize:"19px"}}>Your recent payment hasn't been reflected in our system. Please contact our support team for assistance. call 1800 203 1217 for more.</p>}
    </div>

  )
}

export default PaymentFailure