import React from 'react'
import '../styles/CustomerLandingPage.css';
import { Divider } from '@mui/material';


function CustomerMarquee() {
  return (
    <div className='customer-marquee'>
        <h3 className='h3-customer-marquee'>MyhraKi</h3>
        <div className='CustomerMarqueeDesc'>India's 1st E-commerce Boutique Marketplace</div>
    </div>
  )
}

export default CustomerMarquee