import React from "react";
import logo from "../Images/HdLogo.png";
import "../styles/CustomerFooter.css";
// import { Button, TextField } from '@mui/material';
import {
  FacebookLogo,
  InstagramLogo,
  Envelope,
  PinterestLogo,
} from "@phosphor-icons/react";
// import { Email } from '@mui/icons-material';
import { Phone, WhatsappLogo } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { useFilterContext } from "../context/FilterContext";
import { Divider } from "@mui/material";

function CustomerFooter() {
  const navigate = useNavigate();
  const { fetchProductData } = useFilterContext();
  const {
    updateFilters,
    products,
    setProducts,
    searchValue,
    filters,
    setFilters,
  } = useFilterContext();
  const handleInstagramClick = () => {
    window.scrollTo(0, 0);
  };
  const handleNavigateToTerms = () => {
    window.scrollTo(0, 0);
    navigate("/termsAndCondition");
  };
  const handleNavigateToPrivacy = () => {
    window.scrollTo(0, 0);
    navigate("/buyerPrivacyPolicy");
  };
  const handleNavigateToShipping = () => {
    window.scrollTo(0, 0);
    navigate("/RetunRefund");
  };
  const handleNavigateShippingPolicy = () => {
    window.scrollTo(0, 0);
    navigate("/BuyerShippingPolicy");
  };
  const handleNavigateCancellationPolicy = () => {
    window.scrollTo(0, 0);
    navigate("/CustomerCancellationPolicy");
  };
  const handleTendingNowClick = () => {
    updateFilters({
      searchValue: "",
      category: "",
      priceRange: 0,
    });
    fetchProductData();
    navigate("/products");
    window.scrollTo(0, 0);
  };
  return (
    <div className="customer-footer">
      <div className="customer-footer-content">
        <img
          loading="lazy"
          src={logo}
          alt="logo"
          className="customer-footer-logo"
        />
        <div className="customer-footer-lists">
          <ul className="customer-footer-list">
            <li className="customer-footer-list-item-name"></li>
            <li
              className="customer-footer-list-item"
              onClick={() => {
                navigate("/aboutUs");
                window.scrollTo(0, 0);
              }}
            >
              About us
            </li>
            {/* <Divider className="cutomer-footer-divider" /> */}
            <li
              className="customer-footer-list-item"
              onClick={handleTendingNowClick}
            >
              Trending Now
            </li>
            {/* <Divider className="cutomer-footer-divider" /> */}
            <li
              className="customer-footer-list-item"
              onClick={() => {
                navigate("/blog");
                window.scrollTo(0, 0);
              }}
            >
              Blog
            </li>
            {/* <Divider className="cutomer-footer-divider" /> */}
            {/* <li className='customer-footer-list-item'></li>
          <li className='customer-footer-list-item'></li> */}
          </ul>
          <ul className="customer-footer-list">
            <li className="customer-footer-list-item-name"></li>
            <li
              className="customer-footer-list-item"
              onClick={handleNavigateToPrivacy}
            >
              Privacy Policy
            </li>
            {/* <Divider className="cutomer-footer-divider" /> */}
            <li
              className="customer-footer-list-item"
              onClick={handleNavigateToShipping}
            >
              Return and Refund Policy{" "}
            </li>
            {/* <Divider className="cutomer-footer-divider" /> */}
            <li
              className="customer-footer-list-item"
              onClick={handleNavigateToTerms}
            >
              Terms and Conditions
            </li>
            {/* <Divider className="cutomer-footer-divider" /> */}
            <li
              className="customer-footer-list-item"
              onClick={handleNavigateShippingPolicy}
            >
              Shipping Policy
            </li>
            <li
              className="customer-footer-list-item"
              onClick={handleNavigateCancellationPolicy}
            >
              Cancellation Policy
            </li>
          </ul>
          <ul className="customer-footer-list">
            <li className="customer-footer-list-item-name"></li>
            <li className="customer-footer-list-item">
              {" "}
              hello@myhraki.com
              <a
                className="icon-a"
                href="https://mail.google.com/mail/?view=cm&to=hello@myhraki.com"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleInstagramClick}
              >
                <Envelope
                  className="customer-footer-icon"
                  style={{ margin: "0" }}
                />
              </a>
            </li>{" "}
            <li className="customer-footer-list-item">
              1800 203 1217{" "}
              <Phone className="customer-footer-icon" style={{ margin: "0" }} />
            </li>
            <li className="customer-footer-list-item">
              {" "}
              Chat with us on WhatsApp
              <a
  target="_blank"
  className="icon-a"
  href="https://wa.me/918050797094"
  style={{ textDecoration: "none" }}
>
  <WhatsappLogo
    className="customer-footer-icon"
    style={{ margin: "0" }}
  />
</a>

              {/* <a
                                className='icon-a'
                                href='https://web.whatsapp.com/'
                                target='_blank'
                                rel='noopener noreferrer'
                                onClick={handleInstagramClick}
                            >
                                <WhatsappLogo className='customer-footer-icon' style={{ margin: '0' }} />
 
                    </a> */}
            </li>{" "}
          </ul>
        </div>

        <div className="customer-footer-contact-us">
          {/* <div className='customer-footer-contact-us-email'>
                        <div className="bold">Follow latest updates & New Launches</div>
                        <div className="stylish">Sign Up for MyhraKi Emails</div>
                        <div className='customer-footer-email-field'>
                                <MyhrakiTextfield value={email} onChange={(e)=>{setEmail(e.target.value)}} placeHolder='Enter Email' error={true} helperText={'This is an error'} />
                            <button contained style={{ color: 'white', background: '#FD2A80',border:"none",padding:"6px 30px 6px 30px",fontSize:"16px", width:"144px",height:"49px",borderRadius:"8px" }} >Subscribe</button>
                        </div>
                    </div> */}
        </div>
        
      </div>
      <div className="customer-footer-contact-us-social">
      <div className="customer-footer-copyrights1">
          MYHRAKI E-COMMERCE LIFESTYLE PRODUCT PRIVATE LIMITED
        </div>
        <div className="customer-footer-icons">
          <div className="customer-footer-icons-all">
          <a
            className="icon-a"
            href="https://www.instagram.com/myhraki_official"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleInstagramClick}
          >
            <InstagramLogo className="customer-footer-icon" />
          </a>
          <a
            className="icon-a"
            href="https://www.facebook.com/myhrakiofficial"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleInstagramClick}
          >
            <FacebookLogo className="customer-footer-icon" />
          </a>
          <a
            className="icon-a"
            href="https://in.pinterest.com/0bys9vpl3dpuuw8v64mvge6myap25s/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleInstagramClick}
          >
            <PinterestLogo className="customer-footer-icon" />
          </a>
          </div>
          
          <div className="customer-footer-copyrights2">
          Copyrights © Myhraki. All rights Reserved
          </div>
        </div>
       
      </div>
    </div>
  );
}

export default CustomerFooter;
