import React, { useContext, useEffect, useState } from "react";
import SellerSubscriptionSelectionCard from "./SellerSubscriptionSelectionCard";
import "../styles/SellerSubscriptionSelection.css";
import { SellerContext } from "../context/SellerContext";
import { API_BASE_URL } from "../App";
import Loading from "./Loading";

export default function SellerSubscriptionSelection({ planName,setSellerSubscriptionData,sellerSubscriptionData,subsPlan,selectedPlan, setSelectedPlan, setSubsPlan }) {
  // const [sellerSubscriptionData, setSellerSubscriptionData] = useState([]);
  console.log(selectedPlan?.title)
  const [subscriptionPlan, setSubscriptionPlan] = useState(selectedPlan?.title);
 const[loading,setLoading]=useState(false);
  const { accessToken } = useContext(SellerContext)

  const handleSubscriptionSelection = (planName, planId, plan) => {
    setSubscriptionPlan(planName);
    setSubsPlan(planName);
    setSelectedPlan(plan);
  };

  useEffect(()=>{
    setSubscriptionPlan(selectedPlan?.title);
  },[selectedPlan])

  // setSubscriptionPlan(selectedPlan)


  // const sortedSellerSubscriptionData = sellerSubscriptionData?.sort((a, b) => {
  //   return parseInt(a.planOrder) - parseInt(b.planOrder);
  // });
  // ////console.log(sortedSellerSubscriptionData)

  // const fetchSubscriptionData = async () => {
  //   const response = await fetch(`${API_BASE_URL}/subscription/api/v1/getall`, {
  //     // mode: "no-cors",
  //     headers: {
  //       // 'Accept': 'text/plain',
  //       // 'Content-Type': 'text/plain',
  //       Authorization: `Bearer ${accessToken}`
  //     },
  //   });
  //   const data = await response.json();
  //   setSellerSubscriptionData(data);
  //   ////console.log(data);
  // };

  // useEffect(() => {
  //   fetchSubscriptionData();
  // }, []);

  // useEffect(() => {
  //   ////console.log(subscriptionPlan);
  // }, [subscriptionPlan]);

  return (
    <div className="seller-subscription-selection-cards">
      {loading && <div className="loading-container" style={{ minHeight: "300px", minWidth: "80%" }}> <Loading /> </div>}
      {sellerSubscriptionData?.result && sellerSubscriptionData?.result.map((plan) => {
        return (
          <SellerSubscriptionSelectionCard
            key={plan.id}
            id={plan.id}
            onClick={() => {
              handleSubscriptionSelection(plan.title, plan.razorpayPlanId, plan);
              setSelectedPlan(plan);
            }}
            planTitle={selectedPlan?.title}
            selected={subscriptionPlan === plan.title}
            {...plan}
            planName={planName}
          />
        );
      })}
    </div>
  );
}
