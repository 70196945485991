const LogisticOrderData =[
    {
image:"https://media.istockphoto.com/id/1259705254/photo/stylish-fashionable-blonde-woman-with-smoky-eye-makeup-in-jeans-white-t-shirt-and-black.jpg?s=612x612&w=0&k=20&c=jsfMFnIiaVZdxPg933bLGn1J3RVqqzFhycWeY0ZBJ18=",
title:"Women Jeans Pant",
color:"Black",
size:"S",
quantity:"2",
price:"1800",
    },
]
export default LogisticOrderData