import React from "react";
import "../styles/GiftCardPurchaseDetails.css";
import Birthday from "../Images/birthday2.png";
function GiftCardPurchaseDetails({ data }) {
  console.log(data);
  const dateTimeString = data.createdDate;
  const date = new Date(dateTimeString).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });
  return (
    <div>
      <div className="GC-purchase">
        <div className="GC-Image">
          <img src={data.giftCardImageName} className="GC-Image" />
        </div>
        <div className="all-purchase-detail">
        <div className="purchase-detail1">
          <div>Gift Card</div>
          <div>Order No.{data.id}</div>
          <div className="DeliveryStatus">{data.emailStatus
==="Notify"?"Delivered":"Pending"}</div>
        </div>
        <div className="purchase-detail2">
          <div>ORDER Placed</div>
          <div>{date}</div>
          <div></div>
        </div>
        <div className="purchase-detail3">
          <div>Total</div>
          <div>₹{data.amount}</div>
          <div></div>
        </div>
        <div className="purchase-detail4">
          <div>Ship To</div>
          <div>{data.receptanceName}</div>
          <div></div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default GiftCardPurchaseDetails;
