import React, { useRef } from "react";
import "../styles/OtpVerify.css";
import OtpInput from "react-otp-input";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../Images/MainLogo.png";
import "../styles/Common.css";
import "../styles/SellerOtpVerify.css";
import { SellerContext } from "../context/SellerContext";
import { useContext } from "react";
import ShoppingImg from "../Images/RegisterShoppingImg.png";
import CardComponent from "../components/CardComponent";
// import { errorAlert, successAlert } from "../components/Alert";
import { CircularProgress } from "@mui/material";
import ToastComponent from "../components/ToastComponent";
import { API_BASE_URL } from "../App";

export default function SellerOtpVerify() {
  // const {sellerRegistered} = useContext(AppContext)

  const { sellerData } = useContext(SellerContext);
  const [loading, setLoading] = useState(false);
  const [resendOtpMessage, setResendOtpMessage] = useState(false);
  const [invalidOTP, setInvalidOTP] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const toastRef = useRef();
  const [timeLeft, setTimeLeft] = useState(180);
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    setEnabled(false);
    const timer = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    if (timeLeft === 0) {
      clearTimeout(timer);
      // update state when countdown is complete
      setEnabled(true);
    }
    return () => clearTimeout(timer);
  }, [timeLeft]);



  async function verifyOtp() {
    setLoading(true);
    setInvalidOTP(false);
    setResendOtpMessage(false);
    if(code.length!=6){
      setInvalidOTP(true); 
      setLoading(false);
      return;
    }
    try {
      const response = await fetch(`${API_BASE_URL}/seller/api/v1/ActivateSeller?uniqueID=${sellerData?.result?.guid}&OTP=${code}`, {
        method: 'PUT',
        headers: {
          // 'accept': 'text/plain'
        }
      });

      if (!response) {
        setLoading(false);
        setInvalidOTP(false);
        setResendOtpMessage(false);
        toastRef.current.showToast("Something went wrong. Please try later", "error");

      }

      const data = await response.json();
      setLoading(false);
      setInvalidOTP(false);
      setResendOtpMessage(false);
      if (data.result && typeof data.result === 'boolean' && data.result === true) {

        toastRef.current.showToast("OTP verified Successfully", "success");
        navigate('/sellerRegister');
      }
      else {
        setInvalidOTP(true);
        // toastRef.current.showToast("Invalid OTP", "error");
      }
      ////console.log(data); // Do something with the response data
    } catch (error) {
      setLoading(false);
      setInvalidOTP(false);
      setResendOtpMessage(false);
      toastRef.current.showToast("Something went wrong. Please try later", "error");
      //console.error('There was a problem with the fetch operation:', error);
    }
  }

  const resendOtp = async () => {
    setResendLoading(true);
    try {
      let result = await fetch(
        `${API_BASE_URL}/seller/api/v1/GenerateOTP?uniqueID=${sellerData?.result?.guid}&phoneNumber=%2B91${`${sellerData?.result?.phone}`.slice(3)}`,
        {
          headers: {          },

        }
      );
      setResendLoading(false);


      if (result?.ok) {
        setResendOtpMessage(true);
        setInvalidOTP(false);
        // toastRef.current.showToast("An OTP has been sent to your number", "success");
      }
      else {
        toastRef.current.showToast("Could not send OTP.", "error");
        return;
      }
    } catch (e) {
      toastRef.current.showToast("Something went wrong. Please try later", "error");
      ////console.log(e);
      setResendLoading(false);

      return;
    }
    setTimeLeft(180);
  };


  const navigate = useNavigate();

  const [code, setCode] = useState("");
  const [error,setError] = useState(false);
  // const [resend, setResend] = useState(false);
  const handleChange = (code) => setCode(code);

  // const gotoLoginPage = () => {
  //   navigate("/");
  // };


  return (
    <div>
                     <ToastComponent ref={toastRef} timeout={4000} />

    <CardComponent
      cardImg={<img loading="lazy" className="register-img-shopping" src={ShoppingImg} alt="shopping" />}
      imageFirst={true}
    >
      <div className="seller-otp-vrify-container">
        <div className="seller-otp-vrify-leftScreen">
          {/* <div className="seller-otp-verify-left-content"> 
        <div className="seller-otp-login-leftScreen-content"> 
        <div><img loading="lazy" src={Star}  alt="" className="tri-img" /></div>
        <div className="seller-otp-login-title"> Did You Know?</div>
       <div className="seller-otp-login-left-desc">93.5% of global internet users have purchased products online. Segmented campaigns to email subscribers drive a 760% increase in revenue.</div>
    </div>
      </div> */}
        </div>
        {/* <div className="seller-otp-vrify-rightScreen"> */}
        {/* <div className="seller-otp-verify-right-content">  */}
        <div className="OtpVerification-Myhraki-logo">
          <img loading="lazy" src={Logo} alt="title" className="Myhraki-logo" />
        </div>
        <div className="OtpVerification-title">We have sent you an OTP</div>
        {invalidOTP && <div style={{ backgroundColor: 'lightpink', color: 'maroon', padding: '8px 24px 8px 24px', width: '30%', margin: '0 auto', fontSize: '12px', borderRadius: '10px', textAlign: 'center', position: 'relative', bottom: '15px' }} >Invalid OTP</div>}
            {resendOtpMessage && <div style={{ backgroundColor: 'rgba(2, 159, 74, 1)', color: 'white', padding: '8px 24px 8px 24px', width: '30%', margin: '0 auto', fontSize: '12px', borderRadius: '10px', textAlign: 'center', position: 'relative', bottom: '15px' }} > OTP has been Resent</div>}

        <div className="otp-numbers">
          <OtpInput
            value={code}
            onChange={handleChange}
            numInputs={6}
            separator={<span style={{ width: "8px" }}></span>}
            isInputNum={true}
            shouldAutoFocus={true}
            className="otp-verify-otp-verify"
            inputStyle={{
              border: "1px solid #000",
              // borderRight: "none",
              // borderLeft: "none",
              // borderTop: "none",
              width: "35px",
              height: "35px",
              fontSize: "16px",
              padding: "8px 0px",
              color: "#000",
              borderRadius: "8px"

              //   fontWeight: "400",
              // caretColor: "blue"
            }}
            focusStyle={{
              border: "2px solid #029F4A",
              padding: "8px 0px",
              borderRadius: "8px",
              // borderRight: "none",
              // borderLeft: "none",
              // borderTop: "none",
              outline: "none",
            }}
          />
        </div>

        {!loading && <button className="OtpVerify-button-seller" onClick={verifyOtp}>
          Verify
        </button>}
        {loading && <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "pink" }} /></div>}

        <div className="otp-verify-resend-otp">
          <div>
            <span>Didn't receive the OTP?</span>
          </div>
          {!enabled && (
            <div
              className="otp-verify-resend-otp-button"
              style={{ color: "black" }}
            >
              Resend
            </div>
          )}
                    {enabled && !resendLoading && (
                <div className="otp-verify-resend-otp-button" onClick={resendOtp}>
                  Resend OTP
                </div>
              )}
              {enabled && resendLoading && <div style={{ display: "flex", width: "10%", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "pink" }} /></div>}




          <div>
            {/* <Timer style={{ color: "black" }} initMinute={0} initSeconds={20} /> */}
            {/* <Countdown zeroPadTime={0} date={Date.now() + 18000}>
    <Completionist />
  </Countdown> */}
            {timeLeft !== 0 && <div>{timeLeft}</div>}
          </div>
        </div>

        <div className="seller-otp"> </div>
        {/* <div className="seller-reset-login" >
            <span onClick={gotoLoginPage}>Return to Login</span>
          </div> */}
        {/* <div className="otp-verify-signUp">New User? <span onClick={gotoSignupPage}>Sign Up</span></div> */}
        {/* <div className="admin-login-page-terms">
            By continuing, you agree to MyhraKi's
            <span> Terms and conditions</span> and <span>privacy policy</span>
          </div> */}
      </div>
      {/* </div> */}
      {/* </div> */}
    </CardComponent>
    </div>

  );
}
