import React, { useEffect, useRef } from 'react'
import "../styles/TermsAndCondition.css"
export default function TermsAndConditionSeller(){
  const termsRef = useRef(null);

  useEffect(() => {
    termsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);
    return ( 
        <div className='terms-condition-content'>
  <h2  ref={termsRef}>Terms & Conditions for Sellers on Myhraki</h2>
  <ul className='terms-condition-ul'>
    <li  className='terms-condition-li' >
      <h3>Introduction</h3>
      <p>These Terms & Conditions ("Terms") govern the relationship between Myhraki and its registered sellers ("Sellers") and set out the terms under which Sellers may use the Myhraki platform to sell products.</p>
    </li>
    <li  className='terms-condition-li'>
      <h3>Account Creation</h3>
      <p>To become a Seller on Myhraki, you must create an account and provide accurate and complete information about your business, including your business name, contact information, and payment information. You must also agree to these Terms and any additional policies and guidelines published by Myhraki from time to time.</p>
    </li>
    <li  className='terms-condition-li'>
      <h3>Product Listings</h3>
      <p>You may only list products for sale on Myhraki that are in compliance with all applicable laws and regulations. You must provide accurate and complete information about each product, including its price, description, and any applicable taxes or shipping fees.</p>
    </li>
    <li  className='terms-condition-li'>
      <h3>Order Fulfillment</h3>
      <p>You are responsible for fulfilling all orders that you receive through Myhraki in a timely and professional manner. You must provide accurate and complete information about shipping and delivery times and must notify customers promptly of any delays or issues with their orders.</p>
    </li>
    <li  className='terms-condition-li'>
      <h3>Payments and Fees</h3>
      <p>Myhraki will handle all customer payments on your behalf and will deduct a commission from the total sale price before remitting payment to you. You agree to pay all fees and charges associated with your use of the platform, including any transaction fees or other charges imposed by payment processors. </p>
    </li>
    <li  className='terms-condition-li'>
      <h3>Intellectual Property</h3>
      <p>You warrant that all products and content that you list on Myhraki do not infringe any third-party intellectual property rights. You grant Myhraki a non-exclusive, worldwide, royalty-free license to use, reproduce, and display your products and content on the Myhraki platform for the purpose of promoting and facilitating sales.</p>
    </li>
    <li  className='terms-condition-li'>
      <h3>Prohibited Activities</h3>
      <p>You agree not to engage in any activity that violates these Terms or any applicable law or regulation, including but not limited to:</p>
      <ul>
        <li>Listing products that are illegal or infringe third-party rights.</li>
        <li>Using the Myhraki platform to engage in fraudulent or deceptive practices.</li>
        <li>Interfering with the proper functioning of the Myhraki platform or its technical infrastructure.</li>
        <li>Impersonating another person or entity.</li>
      </ul>
    </li>
    <li  className='terms-condition-li'>
      <h3>Termination</h3>
      <p>Myhraki may terminate your account and/or these Terms at any time, with or without cause. You may also terminate these Terms by closing your account and ceasing to use the Myhraki platform. Upon termination, Myhraki will remit any outstanding payments to you, subject to any deductions or withholdings required by law.</p>
    </li>
    <li  className='terms-condition-li'>
      <h3>Disclaimers and Limitations of Liability</h3>
      <p>
      MYHRAKI PROVIDES THE PLATFORM "AS IS" AND DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. MYHRAKI SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE PLATFORM, WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE.         </p>
  </li>
  <li  className='terms-condition-li'>
      <h3>Governing Law and Jurisdiction </h3>
      <p>
      These Terms shall be governed by and construed in accordance with the laws of government, without regard to its conflicts of law principles. Any dispute arising out of or in connection with these Terms shall be resolved by the courts of jurisdiction. 
 </p>  </li>
   <li  className='terms-condition-li'>
  <h3>Amendments</h3>
  <p>Myhraki may amend these Terms from time to time by posting updated terms on its website. Your continued use of the Myhraki platform after the effective date of any amended Terms constitutes your acceptance of such amended Terms. 
By using the Myhraki platform, you agree to these Terms and any additional policies or guidelines published by Myhraki from time to time. 
</p></li>
   </ul>
   </div>
    )}