import React from 'react'
import "../styles/Footer.css"
import mandala from "../Images/Mandala.png"
import FacebookIcon from '../Images/facebook.png';
import PinterestIcon from '../Images/pintrest.png';
import InstagramIcon from '../Images/instagram.png';
import RuPay from '../Images/RuPay.png';
import Gpay from '../Images/Gpay.png';
import Visa from "../Images/visa.png";
import NetBanking from "../Images/NetBanking.png"
import footerHelpIcon from "../Images/HelpIconFooter.png";
import { Link } from 'react-router-dom';
import "../components/PrivacyPolicy"
export default function Footer(props) {
  return (
    <div className={`footer-container ${props.color}`}>
        <div className="footer-content">
            {/* <img loading="lazy" src={mandala}  alt="design" className="footer-image" /> */}
            {/* <div className="footer-social-and-payment">
                <div className="footer-icons">
                    <div className="footer-icons-subtext">Follow</div>
                    <div>
                    <a href="https://www.facebook.com/profile.php?id=100090521603572" target="_blank" rel="noopener noreferrer">
                    <img loading="lazy" className="footer-social-icon" src={FacebookIcon} alt="Facebook" />
                    </a>
                  <a href="https://www.instagram.com/myhraki_official" target="_blank" rel="noopener noreferrer">
                  <img loading="lazy" className="footer-social-icon" src={InstagramIcon} alt="Instagram" />
                   </a>
                  <a href="https://pin.it/2olQaCm" target="_blank" rel="noopener noreferrer">
                  <img loading="lazy" className="footer-social-icon" src={PinterestIcon} alt="Pinterset" />
                  </a>
                    </div>
                </div>
                <div className="footer-icons">
                    <div className="footer-icons-subtext">Supported Payment systems</div>
                    <div>
                        <img loading="lazy" className="footer-payment-icon-visa" src={Visa} alt="" />
                        <img loading="lazy" className="footer-payment-icon-gpay" src={Gpay} alt=""/>
                        <img loading="lazy" className="footer-payment-icon-cash" src={RuPay} alt=""/>
                        <img loading="lazy" className="footer-payment-icon-cash" src={NetBanking} alt="" />
                  
                    </div>
                </div>

            </div>
            <div className="footer-terms">
            <Link to="/privacyPolicy" className='footer-links'>Privacy Policy</Link>
                <div  className='footer-links'>2023, MyhraKi.com, All Rights Reserved</div>
             <Link to="/termsAndCondition"  className='footer-links'>Terms and Conditions</Link>
            </div> */}


            
            <div className='footer-descriptions'>
            {/* <div class="footer-part1-content">
    <ul class="footer-column" >
      <li>
      <span>Help and Contact</span></li>
      <li>Track your Order</li>
      <li>Delivery Information</li>
      <li>Find the right size</li>
      <li>Subscribe to our Newsletter</li>
    </ul>
    <ul class="footer-column">
      <li> <span>About Gift Cards and Vouchers</span></li>
      <li>Buy Gift Cards</li>
      <li>About Gift Cards and Vouchers</li>
      <li>Corporate Gift Cards</li>
      <li>Subscribe the Newsletter</li>
    </ul>
    <ul class="footer-column">
      <li><span>About Us</span></li>
      <li>Visit our Corporate Site</li>
      <li>Terms and Condition</li>
      <li>Privacy Policy</li>
      <li>Newsroom</li>
    </ul>
 
            </div> */}
            <div class="footer-links-part2">
            <div className='footer-part2-content'>
    {/* <ul class="footer-column">
      <li><span>Our Partners</span></li>
      <div className="footer-icons">
                   
                    <div>
                        <img loading="lazy" className="footer-payment-icon-cash" src={RuPay} alt=""/>
                        <img loading="lazy" className="footer-payment-icon-cash" src={NetBanking} alt="" />
                  
                    </div>
                </div>
    </ul> */}
    <ul class="footer-column">
      <li><span>Our Payment Systems</span></li>
      <div className="footer-icons">
                    
                    <div>
                        {/* <img loading="lazy" className="footer-payment-icon-visa" src={Visa} alt="" /> */}
                        <img loading="lazy" className="footer-payment-icon-gpay" src={Gpay} alt=""/>
                        {/* <img loading="lazy" className="footer-payment-icon-cash" src={RuPay} alt=""/> */}
                        <img loading="lazy" className="footer-payment-icon-cash" src={NetBanking} alt="" />
                  
                    </div>
                </div>
    </ul>
    <ul class="footer-column">
      <li><span>Our Promises</span></li>
      <li>Free Delivery*</li>
      <li>100 days Return Policy</li>
      <li>Free Returns</li>
    </ul>
    <ul class="footer-column">
      <li><span>Our Services</span></li>
      <li>Connected Retail</li>
      <li>Marketing Services</li>
      <li>Learn More</li>
    </ul>
            </div>
            </div>
              <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <hr style={{ width: "100%", height: " 2px", alignSelf: "center",background:"white"}} />
        </div>
            <div className='footer-bottom-content'>
           
            <div className="footer-terms">
                <div>
            <Link to="/privacyPolicy" className='footer-links'>Privacy Policy</Link>
            </div>
                <div  className='footer-links'>2023, MyhraKi.com, All Rights Reserved</div>
                <div>
             <Link to="/termsAndCondition"  className='footer-links'>Terms and Conditions</Link>
             </div>
            </div>
            <div className="footer-social-and-payment">
                <div className="footer-icons">
                    <div className="footer-icons-subtext">You can also find us on</div>
                    <div>
                    <a href="https://www.facebook.com/profile.php?id=100090521603572" target="_blank" rel="noopener noreferrer">
                    <img loading="lazy" className="footer-social-icon" src={FacebookIcon} alt="Facebook" />
                    </a>
                  <a href="https://www.instagram.com/myhraki_official" target="_blank" rel="noopener noreferrer">
                  <img loading="lazy" className="footer-social-icon" src={InstagramIcon} alt="Instagram" />
                   </a>
                  <a href="https://pin.it/2olQaCm" target="_blank" rel="noopener noreferrer">
                  <img loading="lazy" className="footer-social-icon" src={PinterestIcon} alt="Pinterset" />
                  </a>
                    </div>
                </div>
              

            </div>
            </div>

            </div>
        </div>
    </div>
  )
}
