import React, { useContext, useEffect, useState } from 'react'
import "../styles/AdminFaq.css"
import "../styles/Common.css"
import AdminLayoutAdjustment from '../components/AdminLayoutAdjustment'
import Heading from '../components/Heading';
import { TextField } from '@mui/material';
import Modal from "@mui/joy/Modal";
import { API_BASE_URL } from '../App'

import { useFormik } from "formik";
import * as yup from "yup";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import { errorAlert, successAlert } from '../components/Alert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { AdminContext } from '../context/AdminContext';

function AdminFaq() {
  const [open, setOpen] = useState(false);
  const { accessToken} = useContext(AdminContext);
  const [selectedCategory, setSelectedCategory] = useState();

  const [categories, setCategories] = useState([]);
  const[selectedCategoryId,setSelectedCategoryId]=useState();

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };
  

  const validationSchema = yup.object().shape({
    categoryName: yup .string()
    .max(50, 'categoryName should not exceed 50 characters')
    .required('categoryName is required'),
  });
  const formik = useFormik({
    initialValues: {
      categoryName:"",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleAddCategory(formik.values);
    },
  });

  const fetchData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/faq/api/v1/getCategory`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setCategories(data);
      } else {
        
      }
    } catch (error) {
    
    }
  };
 
  
  const handleCategoryChange = async (event) => {
    const selectedCategoryName = event.target.value;
    const selectedCategory = categories?.result?.find(category => category.name === selectedCategoryName);

    if (selectedCategory) {
      setSelectedCategoryId(selectedCategory.id);
      setSelectedCategory(selectedCategoryName);
      
      try {
        const response = await fetchFaqData(selectedCategory.id);
        if (response.ok) {
          const data = await response.json();
          setFaqDetailData(data.result);
        } else {
          
        }
      } catch (error) {
        
      }
    } else {
      setSelectedCategoryId(null);
      setSelectedCategory(null);
      setFaqDetailData([]);
    }
  };
    
    const handleAddCategory = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/faq/api/v1/addCategory?categoryName=${formik.values.categoryName}`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            categoryName: formik.values.categoryName,
          }),
        });
    
        if (response.ok) {
          successAlert('', 'Category added successfully');
          closeModal();
          fetchData();
        } else {
          errorAlert('', 'Failed to add the category');
        
        }
      } catch (error) {

      }
    };
    const [formData, setFormData] = useState({
      faqCategoryId: selectedCategoryId,
      question: formik.values.question,
      answer: formik.values.answer,
    });
    
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
    };
 
    const [faqDetailData, setFaqDetailData] = useState([]);
    const handleAddSubmit = async () => {
      try {
      
        if (!selectedCategoryId || selectedCategoryId === 'Category') {
      
          return;
        }
    
        const response = await fetch(`${API_BASE_URL}/faq/api/v1/add`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json-patch+json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            faqCategoryId: selectedCategoryId, 
            question: formData.question,
            answer: formData.answer,
          }),
        });
    
        if (response.ok) {
          successAlert('', 'Successfully added question and answer');
          setFormData({
            faqCategoryId: selectedCategoryId,
            question: '',
            answer: '',
          });
         
        fetchFaqData();
          
        } else {
          errorAlert('', 'Failed to add question and answer');
        
        }
      } catch (error) {

      }
    };
    
    const handleDeleteFaq = async (id) => {
      try {
        console.log('Deleting FAQ item with ID:', id);
        const response = await fetch(`${API_BASE_URL}/faq/api/v1/deleteFaq/${id}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
    
        if (response.ok) {
          const responseData = await response.json();
    
          if (responseData.result === true) {
           
            const updatedFaqDetailData = faqDetailData.filter((faq) => faq.id !== id);
            setFaqDetailData(updatedFaqDetailData);
    
            successAlert('', 'FAQ item deleted successfully');
          } else {
            errorAlert('', 'Failed to delete FAQ item');
          }
        } else {
          errorAlert('', 'Failed to delete FAQ item');
        }
      } catch (error) {
        // console.error('An error occurred while deleting the FAQ item:', error);
        errorAlert('', 'An error occurred while deleting the FAQ item');
      }
    };
    
    const fetchFaqData = async (id) => {
      try {
        const response = await fetch(`${API_BASE_URL}/faq/api/v1/${id}` ,{
          method:"GET",
          headers:{
            Authorization: `Bearer ${accessToken}`,
          }
        });
        if (response.ok) {
          const data = await response.json();
          setFaqDetailData(data.result);
        } else {
          
        }
      } catch (error) {
       
      }
    };
    
  
    useEffect(() => {
      fetchData();
      fetchFaqData(selectedCategoryId); 
    }, [accessToken,selectedCategoryId]);
  return (
    <AdminLayoutAdjustment>
    <Heading text="FAQ" />
    <div className='Admin-faq-container'>
        <div className='Admin-faq-buttons'>
        <select
      className='addCategory-btn'
      value={selectedCategory}
      onChange={handleCategoryChange}
    >
      <option value='Category'>Category</option>
      {categories?.result?.map((category) => (
        <option key={category.id} value={category.name}>
          {category.name}
        </option>
      ))}
    </select>
            <button className='submit-button' onClick={openModal}>+ Add New</button>
        </div>

        <div className='question-ans-textarea-fields'>
      <textarea
        className='question-ans-textarea'
        placeholder='Enter Question'
        name='question'
        value={formData.question}
        onChange={handleInputChange}
      ></textarea>
      <textarea
        className='question-ans-textarea'
        placeholder='Enter Answer here....'
        name='answer'
        value={formData.answer}
        onChange={handleInputChange}
      ></textarea>
      <div className='submit-button-add-category'>
      <button className='submit-button' onClick={handleAddSubmit}>
        Submit
      </button>
      </div>  
      
    </div>
        <div className='added-question-ans-fields'>
        {faqDetailData?.map((faq, index) => (
          <Accordion key={index} sx={{ marginTop: '20px', boxShadow: 'none', width: '73%', borderRadius: '8px 8px 8px 8px !important', border: '0.5px solid rgba(0, 0, 0, 0.20)', padding: '8px 8px'}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`faq-${index}-content`} id={`faq-${index}-header`}>
             <div style={{display:"flex",justifyContent:"space-between" ,width:"100%"}}> <div className='faq-question'>{faq.question}</div>
         <DeleteOutlineOutlinedIcon onClick={() => handleDeleteFaq(faq.id)}/></div>
            </AccordionSummary>
            <AccordionDetails>
              <div className='faq-answer'>{faq.answer}</div>
            </AccordionDetails>
          </Accordion>
        ))}
        </div>
    </div>
    <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={open}
            onClose={() => setOpen(false)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Sheet
              variant="outlined"
              sx={{
                width: "50%",
                maxWidth: "600px",
                borderRadius: "md",
                p: 3,
                boxShadow: "lg",
              }}
            >
              <ModalClose
                variant="outlined"
                sx={{
                  top: "calc(-1/4 * var(--IconButton-size))",
                  right: "calc(-1/4 * var(--IconButton-size))",
                  boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                  borderRadius: "50%",
                  bgcolor: "background.body",
                }}
              />
              <Heading text="Add Category" />
              <form className='faq-add-category' onSubmit={formik.handleSubmit}>
  <div className='faq-add-category-name'>
    <div>category name :</div>
    <div>
      <TextField
        name='categoryName'
        value={formik.values.categoryName}
        onChange={formik.handleChange}
        error={formik.touched.categoryName && Boolean(formik.errors.categoryName)}
        helperText={formik.touched.categoryName && formik.errors.categoryName}
      />
    </div>
  </div>
  <button className='submit-button' type='submit'>
    Submit
  </button>
</form>
           
            </Sheet>
          </Modal>
    </AdminLayoutAdjustment>
  )
}

export default AdminFaq