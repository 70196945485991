import React from 'react'
import AdminLayoutAdjustment from '../components/AdminLayoutAdjustment'
import Heading from '../components/Heading'
import "../styles/InvitedSeller.css";
import { Copy,Trash} from '@phosphor-icons/react'
import SearchBarComponent from "../components/SearchBarComponent"

function InviteSellerLink() {
  return (
    <AdminLayoutAdjustment>
        
        <Heading text="Invited Seller List" />
        <div className='Invited-seller-container'> 
        <table class="admin-approval-table">
        <tbody >
        <tr>
         
            <th>NAME</th>
            <th>EMAIL ID</th>
            <th>PHONE NO.</th>
            <th></th>
        </tr>
        <tr>
            
            <td>kjjhwgdv</td>
            <td>kjsjhqsu@gmail.com</td>
            <td>9898989898</td>
            <td ><Copy size={24}/>   <Trash size={24}/></td>
           
        </tr>
        </tbody>
        </table>
        </div>
    </AdminLayoutAdjustment>
  )
}

export default InviteSellerLink