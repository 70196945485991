import React, { useState } from 'react';
import LocalVendorLayoutAdjustment from '../components/LogisticAdminLayoutAdjustment';
import LogisticOrderData from "../LogisticOrderData";
import "../styles/LocalVendorCommon.css";
import "../styles/LocalVendorOrder.css";
import { FormControl, MenuItem, Select, TextField } from '@mui/material';

function LocalVendorOrder() {
  const [dimensions, setDimensions] = useState({
    length: '',
    width: '',
  });
  const [weight, setWeight] = useState('');
  const [shipmentValue, setShipmentValue] = useState('');
  const [calculatedShipmentValue, setCalculatedShipmentValue] = useState(null);

  const handleCalculate = () => {
    if (dimensions.length && dimensions.width && weight) {
      // Perform calculation based on your business logic
      const calculatedValue = parseFloat(dimensions.length) * parseFloat(dimensions.width) * parseFloat(weight);
      setCalculatedShipmentValue(calculatedValue.toFixed(2)); // Set calculated shipment value with 2 decimal places
    } else {
      setCalculatedShipmentValue(null); // Reset calculated shipment value
    }
  };

  const handleReset = () => {
    setDimensions({ length: '', width: '' });
    setWeight('');
    setShipmentValue('');
    setCalculatedShipmentValue(null);
  };
  return (
    <LocalVendorLayoutAdjustment>

    <div className='Order-details-container'>
      {LogisticOrderData.map((order, index) => (
        <div key={index} className='order-card'>
          <img loading="lazy" src={order.image} alt={order.title} className='lv-order-image'/>
          <div className='order-deatils-localVendors'>
          <p>Title: {order.title}</p>
          <p>Color: {order.color}</p>
          <p>Size: {order.size}</p>
          <p>Quantity: {order.quantity}</p>
          <p>Price: {order.price}</p>
          </div>
          <button className='lv-approve-btn'>Approve</button>
          <button  className='lv-reject-btn'>Reject </button>
        </div>
      ))}
    </div>
    <div className='freight-calculator-container'>
    <div className='freight-calculator'>
        
        <div className='pickup-area'>
        <h2>Freight Calculator</h2>
        <div className='lv-form-field'>
        <div className='lv-field-name'>Pick-up Area Pincode*: </div>
        <TextField
    autoFocus
    autoComplete='off'
    variant='outlined'
    fullWidth
    type='text'
    placeholder='Enter 6 digit pickup area pincode'
    className="seller-form-field"
           />
         </div>
         <div className='lv-field-name'>Delivery Area Pincode*</div>
          <TextField 
           autoFocus
    autoComplete='off'
    variant='outlined'
    fullWidth
    type='text'
    placeholder='Enter 6 digit pickup area pincode'
     className="seller-form-field" />
        <div className='lv-field-name'>Dimensions(Optional)</div>
        <div className='dimensions-container'>
          <TextField
            autoFocus
            autoComplete='off'
            variant='outlined'
            fullWidth
            type='text'
            placeholder='Length'
            className='dimension-field'
            value={dimensions.length}
            onChange={(e) => setDimensions({ ...dimensions, length: e.target.value })}
          />
          <TextField
            autoComplete='off'
            variant='outlined'
            fullWidth
            type='text'
            placeholder='Width'
            className='dimension-field'
            value={dimensions.width}
            onChange={(e) => setDimensions({ ...dimensions, width: e.target.value })}
          />
        </div>
         <div className='lv-field-name'>Weight</div>
         <TextField
          autoFocus
          autoComplete='off'
          variant='outlined'
          type='text'
          placeholder='Kg'
          className='seller-form-field'
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
        />
          <div className='shipment-value'>
         <div className='lv-field-name'>Shipment Value</div>
         <TextField
            autoFocus
            autoComplete='off'
            variant='outlined'
            type='text'
            placeholder='2000'
            className='seller-form-field'
            value={calculatedShipmentValue !== null ? calculatedShipmentValue : shipmentValue}
            onChange={(e) => setShipmentValue(e.target.value)}
          />
          <button className='lv-common-btn' onClick={handleCalculate}>Calculate</button>
        <button className='lv-common-btn' onClick={handleReset}>Reset</button>
        </div>
       
        </div>
        <div className='lv-thirdpart-table'> 
        <h2>Third party(TBD)</h2>
          <table className='styled-table'>
          <thead>
            <tr>
              <th className='th-freight-calculator'>Provider</th>
              <th className='th-freight-calculator'>Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ship rocket</td>
              <td>Rs.100</td>
            </tr>
            <tr>
              <td>Ship rocket</td>
              <td>Rs.100</td>
            </tr>
            <tr>
              <td>Ship rocket</td>
              <td>Rs.100</td>
            </tr>
          </tbody>
        </table>
        </div>
    
      
     </div>
      
      {/* Logistic Assignment */}
      <div className='logistic-assignment'>
        <h2>Logistic Assignment</h2>
        <div className='local-vendor'>
        <div className='lv-field-name'>LocalVendor</div>
        <div className='lv-field-dropdown'>
          <Select>
          <FormControl fullWidth>
      <MenuItem value='vendor1'>Vendor 1</MenuItem>
      <MenuItem value='vendor2'>Vendor 2</MenuItem>
      <MenuItem value='vendor3'>Vendor 3</MenuItem>
      <MenuItem value='vendor4'>Vendor 4</MenuItem>
      </FormControl>
    </Select>
    </div>
        </div>
        <button className='lv-approve-btn'>Submit</button>
      </div>
      </div>
    </LocalVendorLayoutAdjustment>
  );
}

export default LocalVendorOrder;
