import React from 'react'
import "../styles/buyerPrivacyPloicy.css"
import CustomerLayout from './CustomerLayout'

function BuyerPrivacyPolicy() {
  return (
    <CustomerLayout>
    <div className='Buyer_Privacy_policy'>
      <h1>Privacy Policy</h1>
    <h2>Hello MyhraKi Buyers</h2>
    <p>
      Thank you for choosing MyhraKi for your handmade product shopping! Your trust is important to us, and we are committed to ensuring the privacy and security of your personal information. This Privacy Policy is here to provide you with transparency on how we collect, use, and protect your data.
    </p>

    <h3>1. Information We Collect:</h3>
    <p>
      a. <strong>Personal Information:</strong>
      <ul>
        <li>Full Name</li>
        <li>Address (Billing and Shipping)</li>
        <li>Email Address</li>
        <li>Phone Number</li>
        <li>Payment Information (Credit/Debit Card details)</li>
      </ul>
    </p>

    <p>
      b. <strong>User-Generated Content:</strong>
      <ul>
        <li>Reviews and Ratings</li>
        <li>Product preferences and wishlists</li>
      </ul>
    </p>

    <p>
      c. <strong>Technical Information:</strong>
      <ul>
        <li>IP Address</li>
        <li>Browser Information</li>
        <li>Device Information</li>
      </ul>
    </p>

    <h3>2. How We Use Your Information:</h3>
    <p>
      a. <strong>Order Processing:</strong>
      <ul>
        <li>Fulfilling and delivering your orders</li>
        <li>Sending order confirmations and updates</li>
      </ul>
    </p>

    <p>
      b. <strong>Customer Support:</strong>
      <ul>
        <li>Responding to inquiries</li>
        <li>Resolving issues and providing assistance</li>
      </ul>
    </p>

    <p>
      c. <strong>Personalization:</strong>
      <ul>
        <li>Tailoring product recommendations and promotions based on your preferences</li>
      </ul>
    </p>

    <p>
      d. <strong>Communication:</strong>
      <ul>
        <li>Sending promotional emails and newsletters (you can opt-out anytime)</li>
        <li>Important updates related to your account or our services</li>
      </ul>
    </p>

    <p>
      e. <strong>Analytics:</strong>
      <ul>
        <li>Improving our website and services</li>
        <li>Analyzing user behavior for a better shopping experience</li>
      </ul>
    </p>

    <h3>3. Your Choices:</h3>
    <p>
      a. <strong>Opt-Out:</strong> You can opt-out of receiving promotional emails at any time by using the unsubscribe link provided in the emails.
    </p>

    <p>
      b. <strong>Account Information:</strong> You can review and update your account information by logging into your MyhraKi account.
    </p>

    <h3>4. Information Sharing:</h3>
    <p>
      a. <strong>Third-Party Service Providers:</strong> We share necessary information with payment processors, shipping companies, and other service providers to fulfill your orders.
    </p>

    <p>
      b. <strong>Legal Compliance:</strong> We comply with legal obligations and respond to lawful requests.
    </p>

    <h3>5. Security Measures:</h3>
    <p>Your security is our priority. We use industry-standard measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.</p>

    <h3>6. Contact Us:</h3>
    <p>If you have any questions, concerns, or requests regarding your privacy, please reach out to us:</p>
    <ul>
      <li>Phone: 1800 203 1217</li>
      <li>Email: hello@myhraki.com</li>
    </ul>

    <h3>7. Updates to the Privacy Policy:</h3>
    <p>We may update this Privacy Policy, and any changes will be effective immediately upon posting on our website.</p>

    <p>Thank you for being a part of the MyhraKi family!</p>
  </div>
  </CustomerLayout>
  )
}

export default BuyerPrivacyPolicy