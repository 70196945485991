import React from "react";
import "../styles/SellerCategoryList.css"


// export default function SellerProductCard(props) {
//   return (
//     <div className="seller-products-card" >
//       <div className="seller-category-card-img">
//         {/* <img loading="lazy" src={props.img} alt="" /> */}
//        <img loading="lazy" alt="" src={`https://picsum.photos/id/${10}/200`} />
//       </div>
//       <div className="seller-category-card-name">{props.title}</div>
//       <div className="seller-category-card-name">{props.data.title}</div>
//       <div className="seller-category-card-name">{props.data.price}</div>
//       <div className="seller-category-card-name">{props.data.sku}</div>
//       {/* <div className='seller-category-card-name'>Cat </div> */}
//     </div>
//   );
// }


export default function SellerProductCard(props){
  return(
    <div className="seller-product-card" onClick={props.onClick}>
      <div className="seller-product-image">
        <img loading="lazy" src={props.imgUrl} alt="" style={{width:"100%", height:"200px"}} />
      </div>
      <div className="seller-product-name">{props.title}</div>

    </div>
  )
}