import React from "react";
// import Header from "../components/Header";
// import HelperMyAccount from "../components/HelperMyAccount";
import "../styles/BuyersMyAccount.css";
import Address from "./Address";
import BcBuyerLayout from "../buyerComponents/BcBuyerLayout";
import BcAccountPagesContainer from "../buyerComponents/BcAccountPagesContainer";

export default function AddressPage() {
  return (
    <BcBuyerLayout>
      <BcAccountPagesContainer>
        <Address />
      </BcAccountPagesContainer>
    </BcBuyerLayout>
  );
}
