import React from "react";
import "../styles/Seller.css";
import Edit from "@mui/icons-material/Edit";
import DeleteTwoTone from "@mui/icons-material/DeleteTwoTone";
// import Modal from "@mui/material/Modal";
// import sellerProductsData from "../SellerProductsData";
// import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";


export default function SellerCustomersCard(props) {

    // const navigate = useNavigate();



//     const openCardDetails=(sellerProductsId)=>{
// ////console.log(sellerProductsId);
// navigate (`/orderApproval/${sellerProductsId}`)
//     }
  return (
    <div>
      <div
        className="seller-products-card"
        // onClick={()=>openCardDetails(props.sellerProductsId)}
      >
        
<div className="seller-products-list-table-product-item-small" >
          <input type="checkbox" />
        </div>
       
<div className="seller-products-list-table-product-item" >
    <div style={{display:"flex", justifyContent:"space-evenly"}}>
<Avatar />
          {props.customerName}
    </div>
        </div>
        <div className="seller-products-list-table-product-item" >
          {props.customerEmail}
        </div>
        <div className="seller-products-list-table-product-item">
          {props.customerUserType}
        </div>
        <div className="seller-products-list-table-product-item">
          {props.customerPhone}
        </div>
        
        
        
        <div className="seller-products-list-table-product-item">
          <Edit className="icon" />
          <DeleteTwoTone className="icon" />
        </div>
      </div>
     
      
      
    </div>
  );
}


