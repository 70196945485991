import React from 'react'
import BuyersNavbar from './BuyersNavbar'
import "../styles/BuyerLayout.css"
import Footer from './Footer'

export default function BuyersLayout(props) {
  return (
    <div>
      <BuyersNavbar />
      <div className="buyers-layout">
      {props.children}
      </div>
        <Footer color="admin-footer-color"/>
    </div>
  )
}
