import React, { useContext, useEffect, useState } from 'react';
import '../styles/faq.css';
// import { CaretUp, CaretDown } from '@phosphor-icons/react'
// import Contact from './Contact';
// import CustomerContactEmail from './CustomerContactEmail';
import { CustomerContext } from '../context/CustomerContext';
import { Accordion, AccordionDetails, AccordionSummary,  Skeleton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { API_BASE_URL } from "../App";

import EmptyPage from './EmptyPage';

function CustomerFaq(props) {
  // const [tab, setTab] = useState( props.currentTab ? props.currentTab : "General");
  const { accessToken } = useContext(CustomerContext);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [faqDetailData, setFaqDetailData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [faqDetailDataLoading, setFaqDetailDataLoading] = useState(false);
  const handleCategoryClick = async (category) => {
    setSelectedCategory(category);
    await fetchFAQData(category.id);

  };


  const faqCategory = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/faq/api/v1/getCategory`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });
setLoading(false);
      if (response.ok) {
        const data = await response.json();
        setCategories(data.result);
        setSelectedCategory(data.result[0]);
        fetchFAQData(data.result[0].id)
      } else {
        console.error('Failed to fetch FAQ categories');
      }
    } catch (error) {
      setLoading(false);
      console.error('An error occurred while fetching FAQ categories:', error);
    }
  };

  const fetchFAQData = async (categoryId) => {
    setFaqDetailData([]);
    setFaqDetailDataLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/faq/api/v1/${categoryId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setFaqDetailDataLoading(false);

      if (response.ok) {
        const data = await response.json();
        setFaqDetailData(data.result);
        //console.log(data)
      } else {
        console.error('Failed to fetch FAQ data');
      }
    } catch (error) {
      setFaqDetailDataLoading(false);
      console.error('An error occurred while fetching FAQ data:', error);
    }
  };

  useEffect(() => {
    faqCategory();
  }, []);





  return (
    !categories[0] ?
      <FaqShimmer /> :
      <div className='faq-container'>
        <div className='faq-category-list'>

          {categories?.map((category) => {
            return (
              <div
                key={category.id} // You should add a unique key to each category item
                className={`faq-category-item ${selectedCategory === category ? 'selected' : ''}`}
                onClick={() => handleCategoryClick(category)}
              >
                {category.name}
              </div>
            )
          })}
        </div>
        <div className='faq-question-ans-container' >
          {!faqDetailData[0] && !faqDetailDataLoading && <div>
            <EmptyPage desc='updates on the way'/>
          </div>}
          {!faqDetailData[0] && faqDetailDataLoading && <div>
            <FaqAccordion />
            <FaqAccordion />
            <FaqAccordion />
            <FaqAccordion />
          </div>}
          {!faqDetailDataLoading && faqDetailData?.map((faq) => (
            <Accordion key={faq.id} sx={{
              marginTop: '24px', boxShadow: 'none', width: '95%' ,borderRadius: '8px',
              border: '0.5px solid rgba(0, 0, 0, 0.20)', padding: '16px 8px'
            }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${faq.id}-content`}
                id={`${faq.id}-header`}
              >
                <div className='faq-question'>{faq.question}</div>
              </AccordionSummary>
              <AccordionDetails sx={{width: '95%' }}>
                {/* <div className='faq-answer'> */}
                  {faq.answer}
                {/* </div> */}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
  );

}


export default CustomerFaq;

const FaqShimmer = () => {
  return (
    <div className='faq-container'>

      <div className='faq-category-list'>

        <Skeleton className='faq-category-item' variant="text" />
        <Skeleton className='faq-category-item' variant="text" />
        <Skeleton className='faq-category-item' variant="text" />
        <Skeleton className='faq-category-item' variant="text" />
        <Skeleton className='faq-category-item' variant="text" />
        <Skeleton className='faq-category-item' variant="text" />
        <Skeleton className='faq-category-item' variant="text" />
        <Skeleton className='faq-category-item' variant="text" />
        <Skeleton className='faq-category-item' variant="text" />
        <Skeleton className='faq-category-item' variant="text" />
        <Skeleton className='faq-category-item' variant="text" />
        <Skeleton className='faq-category-item' variant="text" />
      </div>
      <FaqAccordion />
      <FaqAccordion />
      <FaqAccordion />
      <FaqAccordion />
    </div>

  )
}

const FaqAccordion = () => {
  return (
    <div style={{border:'1px solid rgba(217,217,217,1', borderRadius:'8px', padding:'1rem', width:'80%', margin:'1rem 0'}}>
  
    {/* <Skeleton variant="circular" width={40} height={40} /> */}
    <Skeleton variant="text" sx={{ fontSize: '1rem' }} width='60%' height={30} />
    <Skeleton variant="text" sx={{ fontSize: '1rem' }} width='90%' height={180} />
    
</div>
  )
}