import React from 'react'
import "../styles/PaymentSuccess.css"
import {CheckCircle} from '@phosphor-icons/react'
import CustomerLayout from './CustomerLayout'

function PaymentSuccess({type}) {
  return (
    <div className='Payment-display-container'>
        <CheckCircle style={{ width: "152px", height: "152px",color: "rgba(2, 159, 74, 1)"}} />
        <div className='Payment-success-title'>Payment Successful</div>
       {type!=="seller" &&<p style={{color:"rgba(0, 0, 0, 0.5)",fontSize:"19px"}}>Thank you for your purchase, continue shopping</p>}
        {type==="seller"&&<p style={{color:"rgba(0, 0, 0, 0.5)",fontSize:"19px"}}>Thank you for purchasing Subscription. Please wait Until the Admin Approves your Profile.</p>}
    </div>

  )
}

export default PaymentSuccess