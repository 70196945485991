import React from 'react'
import "../styles/AboutUsFooter.css"
import CustNavbar from './CustNavbar';
import CustomerFooter from './CustomerFooter';

function AboutUsFooter() {
  return (
    <>
    <CustNavbar />
    <div className="myhraki-description">
    <h1>A hassle-free and enjoyable shopping journey with Myhraki</h1>

    <p>
      At Myhraki, we understand the importance of offering a diverse and carefully curated selection of products to cater to the varied tastes and preferences of our customers. Our team of experienced curators works tirelessly to handpick products from trusted and innovative brands, ensuring that each item meets our high standards of quality, design, and functionality. This commitment to excellence is reflected in the carefully curated collections available on our platform, spanning fashion, beauty, home décor, and more.
    </p>

    <p>
      Our user-friendly interface is designed with the customer in mind, providing an intuitive and seamless navigation experience. Whether you are searching for the latest fashion trends, exploring beauty products, or enhancing your living space with stylish home décor, Myhraki makes it easy for you to find exactly what you're looking for. The website's layout and features are crafted to enhance the overall shopping experience, allowing customers to effortlessly browse through our extensive catalog.
    </p>

    <p>
      One of the distinguishing features of Myhraki is our dedication to customer satisfaction. We prioritize the needs and concerns of our customers, offering prompt and reliable customer service support. Our team is readily available to address any questions, provide assistance, and ensure that every customer has a positive experience with Myhraki. We believe that exceptional customer service is a fundamental aspect of building trust and loyalty, and we strive to exceed expectations in this regard.
    </p>

    <p>
      To further enhance the convenience for our customers, Myhraki offers a variety of payment options, including secure payment gateways. We prioritize the security of our customers' personal and financial information, ensuring a safe and secure transaction process. Additionally, our transparent and straightforward returns policies give customers peace of mind, allowing them to shop confidently, knowing that they can easily return items if needed.
    </p>

    <p>
      At Myhraki, we are not just an ecommerce platform; we are a destination for those seeking a personalized and enjoyable shopping experience. Our commitment to quality, customer satisfaction, and innovation sets us apart in the world of online retail. We invite you to explore Myhraki and discover the perfect products that complement your lifestyle, reflect your individuality, and make every purchase a delightful experience. Thank you for choosing Myhraki, where your satisfaction is our priority.
    </p>
    <p>
 MyhraKi Office Address : 224, 7th Cross Rd, Indira Nagar II Stage, 1st Stage, Indiranagar, Bengaluru, Karnataka 560038
    </p>
  </div>
  <CustomerFooter />
  </>
);
}

export default AboutUsFooter