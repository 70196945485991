import React, { useRef } from "react";
import "../styles/SellerOtpLogin.css";
import "../styles/Common.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { CircularProgress, Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import sellerLogo from "../Images/MainLogo.png";
// import CallIcon from "@mui/icons-material/Call";
import ResetPasswordImg from "../Images/ResetPasswordImg.png"
// import { ToastContainer } from 'react-toastify';
// import { Context } from "../Context/Store";
import { useContext } from "react";
import { SellerContext } from "../context/SellerContext";
import CardComponent from "../components/CardComponent";
import { alpha, styled } from '@mui/material/styles';
import { red } from '@mui/material/colors';
// import { errorAlert, successAlert } from "../components/Alert";
import { useState } from "react";
import MyhrakiTextfield from "../components/MyhrakiTextfield";
import ToastComponent from "../components/ToastComponent";
import { API_BASE_URL } from "../App";


const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: red[400],

    "&:hover": {
      backgroundColor: alpha(red[400], theme.palette.action.hoverOpacity),
    },
  },

  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: red[400],
  },
}));


const phoneRegExp = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;
const validationSchema = yup.object({
  number: yup
    .string()
    .trim()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required")
    .min(10, "Phone number must consist of 10 digits")
    .max(10, "Phone number must consist of 10 digits"),
});



export default function SellerOtpLogin() {
  const navigate = useNavigate();

  const { isSellerAdmin, setIsSellerAdmin } = useContext(SellerContext);
  const [loading, setLoading] = useState(false);
  const [invalidPhone, setInvalidPhone ] = useState(false);
  const handleisSellerAdminChange = (isSellerAdmin) => {
    setIsSellerAdmin(!isSellerAdmin);
    ////console.log(isSellerAdmin);
  };

  const toastRef = useRef();
  const { setPhoneNumberForOtp } = useContext(SellerContext)
  const checkUserExist = async (mobNumber) => {
    try {
      const data = await fetch(
        `${API_BASE_URL}/users/api/v1/checkUser?mobNumber=${mobNumber.trim()}&userTypeId=${isSellerAdmin?3:4}`
      );
      const response = await data.json();
      // //console.log("Check", response)

      if (response.result === true) {
        return 'otpSent';
      }
      else if (response.result === false) {

        return 'otpNotSent';
      }

    } catch (e) {
      return false;
    }
  }
  const formik = useFormik({
    initialValues: {
      number: "",
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      setLoading(true);
      const userExists =await  checkUserExist(formik.values.number.trim())
      //console.log(userExists)
      setLoading(false);
      if(userExists === 'otpSent'){

        setInvalidPhone(false);
        await postForOtp();
      }
      else if(userExists === 'otpNotSent') {
        
        setInvalidPhone(true);
        
      }  else{
        setInvalidPhone(false);
        setLoading(false);

        toastRef.current.showToast("Something went wrong. Try again later", "error");

      }

    },
  });
  const userType = isSellerAdmin ? 3 : 4;
  async function postForOtp() {
    setLoading(true);
    try {
      let response = await fetch(
        `${API_BASE_URL}/users/api/v1/otp?mobileNumber=${formik.values.number.trim()}&userType=${userType}`,
        {
          headers: {
         
          },

        }
      );
      const result = await response.json()
      // //console.log(result)
     
      if (result.result > 0) {
        toastRef.current.showToast("An OTP has been sent", "success");

        navigate("/SellerOtpVerifyLogin");
        setPhoneNumberForOtp(formik.values.number)
        setLoading(false);
      }
      else {
        toastRef.current.showToast("Maximum otps sent. contact Admin for more", "error");
        setLoading(false);
      }
      // //console.log(response);
      ////console.log(result);
      // return response;
    } catch (e) {
      toastRef.current.showToast("Something went wrong. Try again later", "error");

      ////console.log(e);
      setLoading(false)

    }
  }


  const gotoLoginPage = () => {
    navigate("/");
  };


  return (
    <div>
               <ToastComponent ref={toastRef} timeout={4000} />

    <CardComponent
      cardImg={<img loading="lazy" className="reset-password-img" src={ResetPasswordImg} alt="reset" />}
      imageFirst={true}
    >
      {/* <div className="seller-otp-login-container"> */}
      {/* <div className="seller-otp-login-leftScreen">
        <div className="seller-otp-login-leftScreen-content"> 
        <div><img loading="lazy" src={Star}  alt="" className="tri-img" /></div>
        <div className="seller-otp-login-title"> Did You Know?</div>
      
       <div className="seller-otp-login-left-desc">93.5% of global internet users have purchased products online. Segmented campaigns to email subscribers drive a 760% increase in revenue.</div>
    </div>            
    
    </div> */}
      <div className='seller-otp-login-rightScreen'>
        <div className="seller-otp-login-right-content">
          <div className="seller-otp-leftScreen-image">
            <img loading="lazy" src={sellerLogo} alt="" className="Myhraki-logo" />
          </div>
          <div className='seller-otp-login-content-title'>Sign In with One Time Password</div>
          {invalidPhone && <div style={{ backgroundColor: 'rgba(249, 0, 0, 0.13)', color: '#FF2121', padding: '8px 24px', width: '60%', margin: '0 auto', fontSize: '12px', borderRadius: '10px', textAlign: 'center', position: 'relative', bottom: '0px' }} >This phone number does not exist</div>}

          <form className="sellerSignIn-input-form-fields" onSubmit={formik.handleSubmit}>
          <div className="seller-Otplogin-textfield">
            <div className="textfield-head">Enter Phone Number *</div>
              <MyhrakiTextfield
                variant="outlined"
                fullWidth
                //   autoFocus
                // disabled
                type="text"
                // id="number"
                name="number"
                // className="login-email"
                placeHolder="Enter your Phone Number"
                //   value={state.phone}
                value={formik.values.number.trim()}
                onChange={formik.handleChange}
                error={formik.touched.number && Boolean(formik.errors.number)}
                helperText={formik.touched.number && formik.errors.number}
              />
              {/* <CallIcon className="seller-otp-phone-icon" fontSize="medium " /> */}
            </div>
            <label className="usertype-switch">
              Log in as Seller Admin
              <CustomSwitch defaultChecked={isSellerAdmin ? true : false} onChange={() => { handleisSellerAdminChange(isSellerAdmin) }} />
            </label>
            {!loading && <input
              type="submit"
              className="common-button-seller"
              value="Send OTP"

            />}
            {loading && <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "pink" }} /></div>}

            {/* <label style={{color: "#FF5569" ,    display: "flex",justifyContent: "center",alignItems: "center"}}>
            {/* <label style={{color: "#FF5569" ,    display: "flex",justifyContent: "center",alignItems: "center"}}>
              <input
                type="checkbox"
                checked={isSellerAdmin}
                onChange={handleisSellerAdminChange}
                style={{  fontSize: "5em",
                marginRight: "15px",
                transform: "scale(1.3)",
                color:" #FF5569",
                marginRight: "15px",
                marginTop: "1.1%"
              }}
              />
              I'm a seller Admin User
            </label> */}

          </form>
          {/* <div  className="seller-otp-login-content-desc"> Not able to Sign In?</div> */}
          {/* <a href={gmailUrl} className="requet-to-change-phone-number">Get Help</a> */}
          {/* <div className="seller-otp-login-content-desc">
            We need to send a verification code to Authenticate your number.
          </div> */}
          <div className="seller-reset-login">
            <span onClick={gotoLoginPage}>Return to Login</span>
          </div>
          {/* <div className="admin-login-page-terms">
            By continuing, you agree to MyhraKi's
            <span> Terms and conditions</span> and <span>privacy policy</span>
          </div> */}

        </div>
      </div>

      {/* //<ToastContaner theme="coloured" className="toast-message" /> */}
    </CardComponent>
    </div>

  );
}
