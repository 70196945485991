import React, { useRef } from 'react'
import CustomerNavbar from './CustomerNavbar'
import CustomerCategoryBar from './CustomerCategoryBar'
import CustomerFooter from './CustomerFooter';
import CustomerLoginModal from './CustomerLoginModal';
import ToastComponent from './ToastComponent';
import CustNavbar from './CustNavbar';
import '../styles/Common.css'

function CustomerLayout(props) {
    const [loginModalOpen, setLoginModalOpen] = React.useState(false);

    return (
        <>
            <CustNavbar openLoginModal={() => setLoginModalOpen(true)} />
            <div className='customer-layout-children-cont' >

            {props.children} 
            </div>
            <CustomerFooter />
            <CustomerLoginModal isOpen={loginModalOpen} onClose={() => setLoginModalOpen(false)}  />
        </>
    )
}

export default CustomerLayout