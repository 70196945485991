import React, { useContext, useEffect, useState } from "react";
import "../styles/Seller.css";
import "../styles/Common.css";
import "../styles/SellerLiveOrders.css";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Heading from "../components/Heading";
import SellerLayoutAdjustment from "../components/SellerLayoutAdjustment";
// import SearchBar from "../components/SearchBarComponent";
import { useNavigate } from "react-router-dom";
import { SellerContext } from "../context/SellerContext";
import { API_BASE_URL } from "../App";
import { errorAlert } from "../components/Alert";
import Loading from "../components/Loading";

export default function SellerLiveOrders() {
  const {sellerData, accessToken, handleAuthExpiry, logout} = useContext(SellerContext)
  const [status, setStatus] = useState("All");
  const [statusFilter, setStatusFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    status !== "All " && setStatusFilter(true);
    status === "All" && setStatusFilter(false);
  }, [status]);

  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  // const navigate = useNavigate();

  //   const [layout, setLayout] = useState('cards');

  //   function openSellerProductDetails() {
  //     setLayout('selectedProductDetails')
  //   }

  // const arr=[];

  const [sellerOrdersData, setSellerOrdersData] = useState([]);
  const fetchOrdersData = async () => {
    setLoading(true);
    try{
    const response = await fetch(`${API_BASE_URL}/seller/api/v1/GetOrderBySellerid?SellerId=${sellerData?.result?.typeOfUser==='SLADM'?sellerData?.result?.userID:sellerData?.result?.userAdminId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,

      },
    });
    const data = await response.json();
    setLoading(false);
    const handleAuthError = handleAuthExpiry(response);
    if(handleAuthError===true){
      setLoading(false);
      errorAlert('', 'Your Session has expired');
      logout();
      return;
    }
    setSellerOrdersData(data);
    setLoading(false);
    //console.log(data);
  }catch{
    setLoading(false);
    errorAlert('','Could not fetch Orders data. Please try')
  }
}

  useEffect(() => {
    fetchOrdersData();
  }, []);

  const handleItemClick =(itemId)=>{
    navigate('/SellerOrderDetails/'+itemId)
  }

  return (
    <SellerLayoutAdjustment>
      
      <div className="page-content"> 
      <Heading text="Orders" />

      {/* <button onClick={()=>{navigate('/SellerOrdersDeatils')}} >Test</button> */}
      <div className="layout-container"> 
      <div className="seller-products-list-navbar">
       
        <div className="seller-products-list-search-and-filter">
         
          <Box
            // className="seller-products-list-category" 
            style={{ justifyContent:"flex-end" ,gap:"10px",alignItems:"center" ,marginLeft:"40px"}}
            sx={{display:{xs:'block', md:'flex'}}}
          >
            <div style={{ fontSize: "18px" }}>Status : </div>
            <div>
            <Box sx={{ minWidth: 250 }}>
              <FormControl fullWidth style={{ background: "white" }}>
                <Select
                  style={{ background: "white" }}

                  value={status}
                
                  displayEmpty
                  onChange={handleChange}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"Completed"}>Completed</MenuItem>
                  <MenuItem value={"pending"}>Pending</MenuItem>
                  <MenuItem value={"Return"}>Return</MenuItem>
                  <MenuItem value={"Replace"}>Replace</MenuItem>
                  <MenuItem value={"Rejected"}>Rejected</MenuItem>
                  <MenuItem value={"Canceled"}>Canceled</MenuItem>
                </Select>
              </FormControl>
            </Box></div>
          </Box>
        </div>
      </div>

      {!loading&&<table class="seller-orders-table">
        <tbody>
          <tr>
            <th>Order Number</th>
            <th> Amount</th>
            <th>Order Status</th>
            <th>Shipment Status</th>
          </tr>
           
          {statusFilter &&
            sellerOrdersData?.result?.filter((i) => i.orderStatus === status)
              .map((order) => {
                return (
                  <tr onClick={()=>{handleItemClick(order.itemNumber)}}>
                  <td data-th="Order Number">{order.itemNumber}</td>
                  
                  <td data-th="Total Amount">{order.totalCost}</td>
                  <td data-th="Order Status">{order.orderStatus}</td>
                  <td data-th="Shipment Status">{order.shipmentStatus}</td>
                </tr>
                );
              })}

          {!statusFilter &&
            sellerOrdersData?.result?.map((order) => {
              return (
                <tr onClick={()=>{handleItemClick(order.itemNumber)}}>
                  <td data-th="Order Number">{order.itemNumber}</td>
                  {/* <td data-th="Order Details">
                    {order.skuid}
                  </td> */}
                  <td data-th="Total Amount">{order.totalCost}</td>
                  <td data-th="Order Status">{order.orderStatus}</td>
                  <td data-th=" Shipment Status">{order.shipmentStatus}</td>
                </tr>
              );
            })}
        </tbody>
      </table>}
      {loading && <div className="loading-container" style={{ minHeight: "300px", width:'90%', maxWidth:'400px', margin:'0 auto' }}>
            <Loading />

          </div>}
      </div>
      </div>
    </SellerLayoutAdjustment>
  );
}





// import React, { useState } from "react";
// import SellerLayout from "../components/SellerLayout";
// import ComingSoon from "../components/ComingSoon";
// import withSellerAuth from "../context/withSellerAuth";
// import SellerLayoutAdjustment from "../components/SellerLayoutAdjustment";
// function SellerLiveOrders() {
// return (
// <SellerLayoutAdjustment>
// <ComingSoon/>
// </SellerLayoutAdjustment>
// );
// }

// export default withSellerAuth(SellerLiveOrders);