import React, { useRef, useState } from "react";
import "../styles/SellerLogin.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { CircularProgress, IconButton, Switch, TextField } from "@mui/material";
import "../styles/Common.css";
// import { Alert } from "@mui/lab";
import { SellerContext } from "../context/SellerContext";
import sellerLoginImg from '../Images/logLeft.png';
import { useContext } from "react";
import "react-toastify/dist/ReactToastify.css";
// import Tostify from "../components/Tostify";
//import notif from "../components/Notify";
// import CardComponent from "../components/CardComponent";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { alpha, styled } from "@mui/material/styles";
// import { pink } from '@mui/material/colors';
import "../styles/SellerInviteReject.css";
// import ThankYouShopping from "../Images/thankYouScreenImg.png"
import CardComponent from "../components/CardComponent";
import "../styles/Common.css";
// import sad from "../Images/RejectScrrenCat.png"
// import MainLogo from "../Images/ThankYouImage.png"
import {  red } from "@mui/material/colors";
// import { ToastContainer } from "react-toastify";
import ToastComponent from "../components/ToastComponent";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: red[400],

    "&:hover": {
      backgroundColor: alpha(red[400], theme.palette.action.hoverOpacity),
    },
  },

  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: red[400],
  },
}));
// import {  IconButton, InputAdornment } from '@material-ui/core';
const passwordRegexp = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{6,20}$)"
);
const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .trim()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    // .matches(passwordRegexp, "Password is not valid")
    // .min(8, "Password is not valid ")
    .required("Password is required"),
});

export default function SellerLogin() {
  // const [adminUser, setAdminUser]=useState('')
  // const adminAuth = useAdminAuth();

  // const handleLogin=()=>{
  //     adminAuth.login()
  //     navigate("/inviteSeller2");

  // }
  // const location= useLocation();
  // const redirectPath = location.state?.path || "/"
  const {isSellerAdmin, setIsSellerAdmin} = useContext(SellerContext);
  // const [rememberMe, setRememberMe] = useState(false);
  // const [rememberMeChecked, setRememberMeChecked] = useState(false);
  const [loading, setLoading ] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  
  const handleisSellerAdminChange = (isSellerAdmin) => {
    setIsSellerAdmin(!isSellerAdmin);
    ////console.log(isSellerAdmin);
  };
  

  const navigate = useNavigate();
  // const [token, setToken] = useState(true);
  // const [success, setSuccess] = useState(false);
  // const [error,setError] = useState(false);
  // const [state, setState] = useContext(Context);

  const handleNavigateToTerms = () => {
      navigate('/TermsAndConditionSeller');
  };
  const handleNavigateToShipping = () =>{
    navigate('/ShippingPolicy')
  }
  const handleNavigateToRefund = () =>{
    navigate('/RefundPolicy')
  }
  const { login } = useContext(SellerContext);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      checkbox: false,
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      setLoading(true);
      const result = await login(values.email.trim(), values.password, isSellerAdmin);
      setLoading(false);
      console.log (result)
      if (result?.result?.accessToken) {
        // successAlert('','Login Successful','success')
        setInvalidPassword(false);

        toastRef.current.showToast("Login Successfully", "success");
        setLoading(false);

      }
     
     else if (!result?.result?.accessToken) {
        setLoading(false);
        setInvalidPassword(true);
        // errorAlert('','Unsuccessful Login',error)
      } 
      else{
        setInvalidPassword(false);
        toastRef.current.showToast("Something went wrong", "error");

      }
      // navigate("/SellerDashboard");
    },
  });


  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPasswordEntered(value !== "");
  };

  const [showPassword, setShowPassword] = useState(false);
  const [passwordEntered, setPasswordEntered] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const toastRef = useRef();

  return (
    <div>
         <ToastComponent ref={toastRef} timeout={4000} />

    <CardComponent
      cardImg={<img loading="lazy" className="seller-login-img" src={sellerLoginImg} alt="" />}
      imageFirst={true}
    >

      <div className="seller-login-left">
        {/* <div className='sellerSignIn-Myhraki-Logo'>
      <img loading="lazy" src={logo} alt='logo' className="myhraki-logo"/>
    <div className='sellerSignIn-Myhraki-Logo'>
    </div> */}
        <div className="seller-login-left-content">
          <div className="sellerSignIn-Layout-head">Sign In</div>

          {invalidPassword && <div style={{ backgroundColor: 'rgba(249, 0, 0, 0.13)', color: '#FF2121', padding: '8px 24px', width: '60%', margin: '0 auto', fontSize: '12px', borderRadius: '10px', textAlign: 'center', position: 'relative', bottom: '0px' }} >Invalid Email or Password</div>}


          <form
            className="sellerSignIn-input-form-fields"
            onSubmit={formik.handleSubmit}
          >
            <div className="seller-login-textfield">
                <div className="textfield-head">Enter Email Address *</div>
                <TextField
                  autoFocus
                  variant="outlined"
                  fullWidth
                  type="text"                 
                  name="email"
                  placeHolder="Enter Email Address "
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <div className="textfield-head">Enter Your Password * </div>
                <TextField
                  variant="outlined"
                  // type="text"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  fullWidth
                  placeHolder="Enter Your Password "
                  value={formik.values.password}
                  onChange={(event) => {
                    formik.handleChange(event);
                    handlePasswordChange(event);
                  }}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: passwordEntered && (
                      <IconButton onClick={handleTogglePassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
            </div>

            <label className="usertype-switch">
              Log in as Seller Admin
              <CustomSwitch defaultChecked={isSellerAdmin?true:false} onChange={()=>{handleisSellerAdminChange(isSellerAdmin)}} />
            </label>
            {/* <label className="seller-login-page-checkbox">
              <input
                type="checkbox"
                style={{
                  fontSize: "3em",
                  marginRight: "15px",
                  transform: "scale(1.2)",
                  color: " #FF5569"
                }}
                // checked={rememberMe}
                // checked={rememberMeChecked}
                // onChange={() => setRememberMeChecked(!rememberMeChecked)}
                // onChange={(event) => setRememberMe(event.target.checked)}
                name="checkbox"
                // value={formik.values.checkbox}
                // // onChange={formik.handleChange}
                //   error={
                //     formik.touched.checkbox && Boolean(formik.errors.checkbox)
                //   }
                //   helperText={formik.touched.checkbox && formik.errors.checkbox}
              />
              Remember me?
            </label> */}

            {!loading&&<input
              type="submit"
              className="common-button-seller"
              value="Sign In"
            />}
            {loading && <div style={{display:"flex", width:"100%",alignItems:"center",justifyContent:"center"}}><CircularProgress style={{color:"pink" }} /></div>}
            <div className="sellerSignIn-Forget-terms">
              <span
                onClick={() => {
                  navigate("/sellerForgetPassword");
                }}
              >
                Forget Password?
              </span>
            </div>
          </form>
          {/* <div style={{ display: "flex", flexDirection: "row", width: "100%" ,marginBottom: "-45px"}}>
          <hr style={{ width: "45%", height: " 0px", alignSelf: "center" }} />
          <p style={{ fontSize: "1rem", alignSelf: "center" ,color:"gray"}}>or</p>
          <hr style={{ width: "45%", height: " 0px", alignSelf: "center" }} />
        </div> */}

          {/* <div className="seller-login-page-social"> */}
          {/* <GoogleIcon className="seller-login-page-google" fontSize="large" /> */}
          {/* <PhoneAndroidOutlined
              className="seller-login-page-otp"
              fontSize="large"
              onClick={() => {
                navigate("/AdminOtpLogin");
              }}
            /> */}
          {/* </div> */}
          <div className="admin-login-page-button-page-terms">
          
            <button
              className="Plain-common-button-seller"
              onClick={() => {
                navigate("/SellerOtpLogin");
              }}
            >
              Sign In with OTP
            </button>
            {/* <div className="admin-login-page-terms">
              By continuing, you agree to MyhraKi's
              <span> Terms and conditions</span> and <span>privacy policy</span>
            </div> */}
          </div>
          <div className="admin-login-page-terms">
              By continuing, you agree to MyhraKi's
              <span onClick={handleNavigateToTerms}> Terms and conditions</span>, <span onClick={handleNavigateToShipping}>Shipping  policy</span> and <span onClick={handleNavigateToRefund}>Return and Refund policy</span>
            </div>
        </div>
      </div>
      {/* <div className="seller-login-right">
        <div className="seller-login-right-content" >  
<img loading="lazy" className="seller-login-img"  src={sellerLoginImg} alt="" />
        </div>
        
      </div> */}
      {/* //<ToastContaner theme="coloured" className="toast-message" /> */}
    </CardComponent>
    </div>
    
  );
}
