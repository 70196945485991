import React, { useContext, useEffect, useState } from 'react';
import '../styles/CustomerLandingPage.css';
import { CustomerContext } from '../context/CustomerContext';
import { useNavigate } from 'react-router-dom';
// import { FilterContext } from '../context/FilterContext';
import { API_BASE_URL } from '../App';

function CustomerCategoryBar() {
  // const {filterCategory, setFilterCategory} = useContext(FilterContext)

  const { accessToken,  setSelectedCategory } = useContext(CustomerContext);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/productgen/api/v1/category/All`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${accessToken}`,
          },
         
        });

        if (response.ok) {
          const data = await response.json();
          setCategories(data); 
          //console.log("Fetched successfully");
        } else {
          console.error("Failed to fetch categories:", response.status);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories(); 
  }, [accessToken]);
  return (
    <div className='customer-category-bar'>
      <div className='category-container'>
        {categories?.result?.map((category, index) => (
          <div key={index} className='category-item' onClick={()=> { setSelectedCategory(category?.categoryId);  navigate('/products')}}>
            {/* {filterCategory} */}
            {category.categoryName}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CustomerCategoryBar;
