import React, { useContext } from "react";
import "../styles/AdminOtpVerify.css";
import OtpInput from "react-otp-input";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../Images/MainLogo.png";
import { API_BASE_URL } from '../App'

import AdminOtpImg from "../Images/adminotpverifyimg.png";
import "../styles/Common.css";
import { AdminContext } from "../context/AdminContext";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import notif from "../components/Notify";
// import { replace, useFormik } from "formik";
// import * as yup from "yup";
// import AppContext from "../Context/app-context";
// import Countdown from "react-countdown";
import { errorAlert, successAlert } from "../components/Alert";
import { CircularProgress } from "@mui/material";
export default function AdminOtpVerify() {
  // const {sellerRegistered} = useContext(AppContext)
  const { phoneNumberForOtp, loginWithOtp } = useContext(AdminContext);
  // const [loginInProgress, setLoginInProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const userType = 1;
  const [timeLeft, setTimeLeft] = useState(180);
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    setEnabled(false);
    const timer = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    if (timeLeft === 0) {
      clearTimeout(timer);
      // update state when countdown is complete
      setEnabled(true);
    }
    return () => clearTimeout(timer);
  }, [timeLeft]);

  //   useEffect(()=>{
  // if (timeLeft===0){
  //   setEnabled(true)
  // }
  // else{
  //   setEnabled(false)
  // }
  //   },[timeLeft])

  async function verifyOtp() {
    setLoading(true);
    const response = await loginWithOtp(phoneNumberForOtp, code);
  
    if (!response?.result?.accessToken) {
      errorAlert('', 'Wrong OTP. Unsuccessful Login');
      setLoading(false);
    } else {
      setLoading(false);
      successAlert('', 'Login Successful', 'success');
    }
  }
  





  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  // const [resend, setResend] = useState(false);
  const resendOtp = async () => {
    try {
      let result = await fetch(
          `${API_BASE_URL}/users/api/v1/otp?mobileNumber=${phoneNumberForOtp?.trim()}&userType=${userType}`,
          {
          headers: {
            // Accept: "application.json",
            // "Content-type": "application.json",
          },
         
        }
      );
      //console.log("Message", result.message);
      if(result?.message === 'Maximum attempts! try next day'){

        errorAlert('','Maximum attempts reached. Try after 24 hours');
        setLoading(false);
        return;
      }
      if(result?.ok){
        successAlert('','OTP has been resent.')
      }
      else{
        errorAlert('','Error Resending OTP.')
        return;
      }
    } catch (e) {
      errorAlert('','Error Resending OTP.')
      //console.log(e);
      return;
    }
    setTimeLeft(180);
  };

  const handleChange = (code) => setCode(code);


  const navigate = useNavigate();

  const gotoLoginPage=()=>{
    navigate("/");
  }

  return (
    <div className="admin-otp-Container">
      <div className="admin-otp-verify-left">
        <div className="OtpVerification-container-right-content-admin">
          <div className="OtpVerification-Myhraki-logo">
            <img loading="lazy" src={logo} alt="title" className="Myhraki-logo" />
          </div>
          <div className="OtpVerification-title">OTP Verification</div> <div className="admin-otp-verify-note"><p>Please enter 4 Digit Verification Code sent to your Mobile Number</p></div>
          <div className="otp-numbers">
            <OtpInput
              value={code}
              onChange={handleChange}
              numInputs={4}
              separator={<span style={{ width: "8px" }}></span>}
              isInputNum={true}
              shouldAutoFocus={true}
              className="otp-verify-otp-verify"
              inputStyle={{
                border: "2px solid black",
                borderRight: "none",
                borderLeft: "none",
                borderTop: "none",
                width: "2rem",
                height: "2rem",
                fontSize: "1.5rem",
                color: "#000",
                marginTop:"30%"

                //   fontWeight: "400",
                // caretColor: "blue"
              }}
              focusStyle={{
                // border: "1px solid #CFD3DB",
                borderRight: "none",
                borderLeft: "none",
                borderTop: "none",
                outline: "none",
              }}
            />
          </div>
          {error && (
            <div className="otp-verify-error">Please enter a valid OTP</div>
          )}
         {!loading && <button className="common-button" onClick={verifyOtp}>
            Verify
          </button>}
          {loading && <div style={{display:"flex", width:"100%",alignItems:"center",justifyContent:"center"}}><CircularProgress style={{color:"pink" }} /></div>}

          <div className="otp-verify-resend">
            <div>
              <span style={{color: "rgba(112, 112, 112, 0.72)"}}>Didn't receive the OTP?</span>
            </div>
            {!enabled && (
              <div
                className="otp-verify-resend-otp-button"
                style={{ color: "#FF5569" }}
              >
                Resend
              </div>
            )}
            {enabled && (
              <div className="otp-verify-resend-otp-button" onClick={resendOtp}>
                Resend OTP
              </div>
            )}

            <div>
              {/* <Timer style={{ color: "black" }} initMinute={0} initSeconds={20} /> */}
              {/* <Countdown zeroPadTime={0} date={Date.now() + 18000}>
    <Completionist />
  </Countdown> */}
              {timeLeft !== 0 && <div>{timeLeft}</div>}
            </div>
          </div>
         
          {/* <div className="otp-verify-signUp">New User? <span onClick={gotoSignupPage}>Sign Up</span></div> */}
          <div className="seller-reset-login">
          <span onClick={gotoLoginPage}>Return to Login</span>
        </div>
          <div className="admin-login-page-terms">
          By continuing, you agree to MyhraKi's
            <span> Terms and conditions</span> and <span>privacy policy</span>
          </div>
        </div>
      </div>
      <div className="admin-otp-verify-right">
        <div className="OtpVerification-container-left-content">
          <div className="OtpVerification-container-left-img">
            <img loading="lazy" src={AdminOtpImg} alt=" " />
          </div>
        </div>
      </div>
      {/* //<ToastContaner theme="coloured" className="toast-message" /> */}

    </div>
  );
}
