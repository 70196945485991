import React from 'react'
import '../styles/BcBuyerLayout.css'

const headingStyles = {
    color: 'rgb(43, 52, 69)',
    width: '100%',
    textAlign: 'center',
    margin: '2rem auto',
    fontSize: '26px'
}

function BcHeading(props) {
    return (
        <div className="bc-buyer-layout-container">
            <div style={headingStyles}>{props.text}</div>
        </div>
    )
}

export default BcHeading
