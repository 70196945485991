import React, { useState } from "react";
import "../styles/Seller.css";
import Edit from "@mui/icons-material/Edit";
import "../styles/SellerDiscount.css"
import { TextField } from "@mui/material";
import Select from "@mui/material/Select";
import ClearIcon from "@mui/icons-material/Clear";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DeleteTwoTone from "@mui/icons-material/DeleteTwoTone";
import { AdminContext } from "../context/AdminContext";
import { useContext } from "react";
import { Modal } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import Modal from "@mui/material/Modal";
// import sellerProductsData from "../SellerProductsData";
// import { useNavigate } from "react-router-dom";
// import { Avatar } from "@mui/material";
 
export default function AdminDiscountCard(props) {
  const { accessToken, sellerData } = useContext(AdminContext);
  const [discountCoupons, setDiscountCoupons] = useState();
  const [adminDiscountData, setAdminDiscountData] = useState([]);
 
  //     const openCardDetails=(sellerProductsId)=>{
  // //console.log(sellerProductsId);
  // navigate (`/orderApproval/${sellerProductsId}`)
  //     }
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  let month = currentDate.getMonth() + 1;
  let day = currentDate.getDate();
 
  if (month < 10) {
    month = `0${month}`;
  }
  if (day < 10) {
    day = `0${day}`;
  }
 
  const maxDate = `${year}-${month}-${day}`;
  const minDate = `${year}-${month}-${day}`;
  const [selectedStartDate, setSelectedStartDate] = useState(minDate);
 
  async function fetchCoupons() {
    try {
      const response = await fetch(
        "https://dev-api.myhraki.com/web/coupons/api/v1/GetAllCoupon",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
 
      if (response.ok) {
        const coupons = await response.json();
        setAdminDiscountData(coupons.result);
        return coupons.result;
      } else {
        console.error("Unable to fetch coupons:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }
 
  const [openModal, setOpenModal] = useState(false);
  const [editedCoupon, setEditedCoupon] = useState({});
 
  const handleRowClick = () => {
    setOpenModal(true);
    setEditedCoupon({
      id: props.id,
      couponName: props.couponName,
      couponCode: props.couponCode,
      product: props.product,
      message: props.message,
      offerType: props.offerType,
      amount: props.amount,
      status: props.status,
      couponStartDateTime: props.couponStartDateTime,
      couponExpiryDateTime: props.couponExpiryDateTime,
      minAmountCart: props.minAmountCart,
      maximumUse: props.maximumUse,
    });
  };
 
 
  const handleModalClose = () => {
    setOpenModal(false);
  };
 
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        "https://dev-api.myhraki.com/web/coupons/api/v1/UpdateCoupon",
        {
          method: "PUT",
          body: JSON.stringify({
            id: editedCoupon.id,
            couponName: editedCoupon.couponName,
            couponCode: editedCoupon.couponCode,
            offerType: editedCoupon.offerType,
            amount: editedCoupon.amount,
            couponStartDateTime: editedCoupon.couponStartDateTime,
            couponExpiryDateTime: editedCoupon.couponExpiryDateTime,
            product: editedCoupon.product,
            minAmountCart: editedCoupon.minAmountCart,
            message: editedCoupon.message,
            maximumUse: editedCoupon.maximumUse,
          }),
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      console.log(data);
      if (data?.result === true) {
        //  fetchCoupons();
        handleModalClose();
        props.setUpdated(true);
        // setSellerDiscountData(coupons.result)
      } else {
        throw new Error("Failed to update coupon");
      }
    } catch (error) {
      console.error("Error updating coupon:", error);
    }
  };
 
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "couponStartDateTime") {
      setSelectedStartDate(value);
    }
    setEditedCoupon((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
 
  const handleDeleteCoupon = async (id) => {
    try {
      const response = await fetch(
        `https://dev-api.myhraki.com/web/coupons/api/v1/DeleteCoupon?CouponId=${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            accept: "text/plain",
          },
        }
      );
      const data = await response.json();
      if (data?.result === true) {
        await fetchCoupons();
        props.setDeleted(true);
      } else {
        throw new Error("Failed to update coupon");
      }
    } catch (error) {
      console.error("Error deleting coupon:", error);
    }
  };
 
  const handleDelete = (event, id) => {
    event.stopPropagation();
    handleDeleteCoupon(id);
  };
 
  return (
    <>
      <div onClick={handleRowClick} className="modal-trigger">
        <table className="SellerDiscount-table">
          <tbody>
            <tr>
              <td data-th="Coupon Name">{props.couponName}</td>
              <td data-th="Coupon Code">
                <div
                  style={{
                    width: "166px",
                    background: "rgba(255, 97, 85, 0.2)",
                    borderRadius: "5px",
                    padding: "5px",
                  }}
                >
                  {props.couponCode}
                </div>
              </td>
              <td data-th="Amount">{props.amount}</td>
              <td data-th="Status">
                <div className="seller-discount-status">
                  {props.status === "pending" && (
                    <div className="status-indicator-pending"> </div>
                  )}
                  {props.status === "Active" && (
                    <div className="status-indicator-active"> </div>
                  )}
                  {props.status === "Expired" && (
                    <div className="status-indicator-expired"> </div>
                  )}
                  {props.status === "Scheduled" && (
                    <div className="status-indicator-scheduled "> </div>
                  )}
                  {props.status}
                </div>
                <div></div>
              </td>
              <td data-th="Action">
                {props.action}
                <DeleteTwoTone className="icon" onClick={(event) => handleDelete(event, props.id)} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {openModal && editedCoupon.status === "Scheduled" && (
        <Modal open={openModal} onClose={handleModalClose}>
          <div className="coupon-modal" id="couponModal">
            <ClearIcon
              className="address-close-button"
              onClick={handleModalClose}
            />
            <div className="seller-modal-head-text">Add New Coupon</div>
            <form className="address-page-form" onSubmit={handleFormSubmit} >
              <div className="address-form-field">
                <div className="field-name">Coupon Name: </div>
                <TextField
                  autoFocus
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  type="text"
                  id="address-fullname"
                  name="couponName"
                  className="address-field"
                  placeholder=" "
                  value={editedCoupon.couponName || ""}
                  onChange={handleInputChange}
                // value={formik.values.couponName}
                // onChange={formik.handleChange}
                // error={
                //   formik.touched.couponName && Boolean(formik.errors.couponName)
                // }
                // helperText={formik.touched.couponName && formik.errors.couponName}
                />
              </div>
              <div className="address-form-field">
                <div className="field-name">Coupon Code: </div>
                <TextField
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  type="text"
                  id="address-street"
                  name="couponCode"
                  className="address-field"
                  placeholder=" "
                  value={editedCoupon.couponCode || ""}
                  onChange={handleInputChange}
                // value={formik.values.couponCode}
                // onChange={formik.handleChange}
                // error={formik.touched.couponCode && Boolean(formik.errors.couponCode)}
                // helperText={formik.touched.couponCode && formik.errors.couponCode}
                />
              </div>
              <div className="discount-form-field">
                <div className="discount-field-name1">Offer Type: </div>
                <div className="offer-type">
                  <FormControl>
                    <Select
                      value={editedCoupon.offerType || ""}
                      onChange={handleInputChange}
                      name="offerType"
                      placeholder=" "
                      className="offer-dropdown"
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem value="">
                        <em>Select Flat or Percentage</em>
                      </MenuItem>
                      <MenuItem value="Flat">Flat</MenuItem>
                      <MenuItem value="Percentage">Percentage</MenuItem>
                    </Select>
                    {/* <FormHelperText>{formik.touched.offerType && formik.errors.offerType}</FormHelperText> */}
                  </FormControl>
                </div>
              </div>
              <div className="address-form-field">
                <div className="field-name">Amount: </div>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="text"
                  id="address-country"
                  name="amount"
                  className="address-field"
                  placeholder=" "
                  value={editedCoupon.amount || ""}
                  onChange={handleInputChange}
                // value={formik.values.amount}
                // // disabled
                // onChange={formik.handleChange}
                />
              </div>
              <div className="address-form-field-date">
                <div className="field-name">Start Date : </div>
                <div style={{ width: "90%", display: "flex" }}>
                  <div className="facts-date-field">
                    <TextField
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      type="date"
                      id="address-pincode"
                      name="couponStartDateTime"
                      className="address-field"
                      placeholder=" "
                      min={minDate}
                      value={selectedStartDate}
                      onChange={handleInputChange}
                      inputProps={{
                        // Disable dates prior to the current date
                        min: minDate,
                      }}
                    />
                    <CalendarMonthIcon
                      className="facts-date-icon-seller"
                      fontSize="medium "
                    />
                  </div>
                </div>
              </div>
              <div className="address-form-field-date">
                <div className="field-name">Expiry Date : </div>
                <div style={{ width: "90%", display: "flex" }}>
                  <div className="facts-date-field">
                    <TextField
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      type="date"
                      id="address-pincode"
                      name="couponExpiryDateTime"
                      className="address-field"
                      placeholder=" "
                      min={selectedStartDate}  // Set the min attribute based on selectedStartDate
                      value={editedCoupon.couponExpiryDateTime || ""}
                      onChange={handleInputChange}
                      inputProps={{
                        // Disable dates prior to the current date
                        min: selectedStartDate,
                      }}
                    />
                    <CalendarMonthIcon
                      className="facts-date-icon-seller"
                      fontSize="medium "
                    />
                  </div>
                </div>
              </div>
              <div className="address-form-field">
                <div className="field-name">Products: </div>
                <TextField
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  type="text"
                  id="address-street"
                  name="product"
                  className="address-field"
                  placeholder=" "
                  value={editedCoupon.product || ""}
                  onChange={handleInputChange}
                // value={formik.values.couponCode}
                // onChange={formik.handleChange}
                // error={formik.touched.couponCode && Boolean(formik.errors.couponCode)}
                // helperText={formik.touched.couponCode && formik.errors.couponCode}
                />
              </div>
              <div className="address-form-field">
                <div className="field-name">Minimum Purchase Amount: </div>
                <TextField
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  type="text"
                  id="address-contact"
                  name="minAmountCart"
                  className="address-field"
                  placeholder=" "
                  value={editedCoupon.minAmountCart || ""}
                  onChange={handleInputChange}
                // value={formik.values.minAmountCart}
                // onChange={formik.handleChange}
                // error={formik.touched.minAmountCart && Boolean(formik.errors.minAmountCart)}
                // helperText={formik.touched.minAmountCart && formik.errors.minAmountCart}
                />
              </div>
              <div className="discount-form-field">
                <div className="discount-field-name">Message to be Displayed: </div>
                <textarea
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  type="text"
                  id="address-contact"
                  name="message"
                  className="discount-message-field"
                  placeholder=" "
                  value={editedCoupon.message || ""}
                  onChange={handleInputChange}
                // value={formik.values.couponCode}
                // onChange={formik.handleChange}
                // error={formik.touched.couponCode && Boolean(formik.errors.couponCode)}
                // helperText={formik.touched.couponCode && formik.errors.couponCode}
                />
              </div>
              <div className="address-form-field">
                <div className="field-name">Number Available </div>
                <TextField
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  type="text"
                  id="address-contact"
                  name="maximumUse"
                  className="address-field"
                  placeholder=" "
                  value={editedCoupon.maximumUse || ""}
                  onChange={handleInputChange}
                // value={formik.values.maximumUse}
                // onChange={formik.handleChange}
                // error={formik.touched.maximumUse && Boolean(formik.errors.maximumUse)}
                // helperText={formik.touched.maximumUse && formik.errors.maximumUse}
                />
              </div>
              <input
                type="submit"
                className="common-button"
                value="Save"
                style={{ position: "relative", right: "5rem" }}
              />
            </form>
          </div>
 
        </Modal>
      )}
    </>
 
  );
}