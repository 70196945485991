import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { SellerContext } from "./SellerContext";

function withSellerAuth(Component) {
  return function AuthenticatedComponent(props) {
    const { sellerData } = useContext(SellerContext);

    if (!sellerData) {
      return <Navigate to="/" />;
    }

    return <Component {...props} />;
  };
}

export default withSellerAuth;