import React, {  useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import "../styles/CustomerContactEmail.css";
import { CircularProgress } from "@mui/material";
import "../styles/Common.css"
import * as yup from "yup";
import { CustomerContext } from "../context/CustomerContext";
import { useContext } from "react";
import { errorAlert, successAlert } from "../components/Alert";
import { API_BASE_URL } from "../App";
import ToastComponent from "../components/ToastComponent";
const fullNameRegExp = /^[a-zA-Z][a-zA-Z]{0,}(?: [a-zA-Z][a-zA-Z]*){0,2}$/;
const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

function CustomerContactEmail() {
  const {  accessToken } = useContext(CustomerContext);
  const [loading, setLoading] = useState(false);
  const toastRef = React.useRef();

    const validationSchema = yup.object({
      name: yup
          .string("Enter fullname")
          .matches(fullNameRegExp, "Full Name is invalid")
          .required("Full name is required")
          .min(1, "Full Name should be 3 characters atleast")
          .max(50, "Full Name should not exceed  50 characters "),

        email: yup
          .string("Enter your Email")
          .matches(emailRegExp, "Email is invalid")
          .email("Enter a valid Email")
          .required("Email is required"),
          query: yup
          .string("What can we help you with")
          .trim()
          .required("Query is required")
          .max(300, "Query should not exceed 300 characters")
          .min(1, "Query should be 3 chars atleast"),
      });
      const formik = useFormik({
        initialValues: {
          name: "",
          email: "",
          query: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
          try {
            await fetchContactData();
            resetForm({ values: "" });
            toastRef.current.showToast("Query sent successfully", "success");
          } catch (error) {
            console.error(error);
            toastRef.current.showToast("An error occurred while sending the query. Please try again.", "error");
          }
        },
      });
    
      const [email, setEmail] = useState(false);
      const[contactData , setContactData]=useState("");
      const fetchContactData = async () => {
              
        try {
          setLoading(true);
          const response = await fetch(`${API_BASE_URL}/faq/api/v1/contactUs/add`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json-patch+json',
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              "name": formik.values.name,
              "email": formik.values.email,
              "query": formik.values.query
            })
          });
          const data = await response.json();
          setLoading(false)
          setContactData(data);
          if (response.ok) {
            toastRef.current.showToast("Query sent successfully", "success");
            // successAlert('',"Query sent successfully");
            
          } else {
            setLoading(false)
            toastRef.current.showToast("An error occurred while sending the query. Please try again.", "error");
            // errorAlert('',"An error occurred while sending the query. Please try again.");
          }
        } catch (error) {
          setLoading(false);
          console.error(error);
          toastRef.current.showToast("An error occurred while sending the query. Please try again.", "error");

        }
      };
    
  return (
    <>
    <ToastComponent ref={toastRef} timeout={4000} />
    <div className="contact-email-container">
        <div className="contact-email-head">ASK A QUESTION</div>
    <form
      className="contact-email-section-form"
      onSubmit={formik.handleSubmit}
    >
      <div className="contact-email-form-field ">
        <div>Name</div>      
        <TextField
          // autoFocus
          autoComplete="off"
          variant="outlined"
          //   fullWidth
type="text"                    
          name="name"
          placeholder="Enter your name"
          className="email-field"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={
            formik.touched.name &&
            Boolean(formik.errors.name)
          }
          helperText={
            formik.touched.name && formik.errors.name
          }
        />
      </div>
      <div className="contact-email-form-field ">
        <div>Email</div>
        <TextField
          autoComplete="off"
          variant="outlined"
          //   fullWidth
          type="email"
          name="email"
          className="email-field email"
          placeholder="Enter your email "
          value={formik.values.email}
          onChange={formik.handleChange}
          error={
            formik.touched.email && Boolean(formik.errors.email)
          }
          helperText={formik.touched.email && formik.errors.email}
        />
      </div>
      <div className="contact-email-form-field ">
        <div>Query</div>
      <div className="error-message-query-field">
      <textarea
            autoComplete="off"
            variant="outlined"
            name="query"
            className={`email-field-textarea ${formik.touched.query && Boolean(formik.errors.query) ? 'error' : ''}`}
            placeholder="Enter your message"
            value={formik.values.query}
            onChange={formik.handleChange}
            error={formik.touched.query && Boolean(formik.errors.query)}
          />
          {formik.touched.query && formik.errors.query && (
            <div className="error-message-query">{formik.errors.query}</div>
          )}
      </div>
      </div>
      <div className="contact-button-field">
     {!loading &&<input
        type="submit"
        value="Ask Question"
        className="contact-button-byr"
        // onClick={formik.handleSubmit}
      />}
          {loading && <div style={{ display: "flex", width: "20%", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "pink" }} /></div>}

      </div>
    </form>
  </div>
  </>
  )
}

export default CustomerContactEmail