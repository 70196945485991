import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Heading from "../components/Heading";
import SellerProductCard from "../components/SellerProductsCard";
import { useContext } from "react";
import { SellerContext } from "../context/SellerContext";
import { errorAlert } from "../components/Alert";
import withSellerAuth from "../context/withSellerAuth";
import SellerLayoutAdjustment from "../components/SellerLayoutAdjustment";
import { useState } from "react";
import { CaretLeft } from '@phosphor-icons/react'
import {  CircularProgress, IconButton } from "@mui/material";
import "../styles/SellerCategoryList.css"
import { useEffect } from "react";
import { API_BASE_URL } from "../App";
import { CleaningServices } from "@mui/icons-material";
import Loading from "../components/Loading";

function SellerProductsPage(props) {

  const {getSellerPlan,  accessToken, sellerData} = useContext(SellerContext)
  const { category } = useParams();
  // const { state } = useLocation();
  const [addLoading, setAddLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleNavigateBack = () => {
    navigate('/sellerCategoriesListingPage');
  };

  // const products = state.products;
  ////console.log(products);
  ////console.log(category);

  // const productsOfSelectedCategory = products.filter(
  //   (product) => product?.category?.trim() === category
  // );
  const [productsOfSelectedCategory, setProductsOfSelectedCategory] = useState([]);


  ////console.log(productsOfSelectedCategory);

  async function fetchProductsOfSelectedCategory(){
    setLoading(true);
    try{

    
    const response = await fetch(`${API_BASE_URL}/productgen/api/v1/search?Category=${category}&SellerId=${sellerData?.result?.typeOfUser==='SLADM'?sellerData?.result?.userID:sellerData?.result?.userAdminId}&PageNumber=${0}&PageSize=${50}`,{
    headers:{
      Authorization:`Bearer ${accessToken}`
    },
  })
  const data = await response.json();
  //console.log(data);
  setLoading(false);

  setProductsOfSelectedCategory(data?.result);
  }catch{
    setLoading(false);

  }
}

  useEffect(()=>{
    fetchProductsOfSelectedCategory();
  },[])

  const navigate = useNavigate();


  function isAddProductAllowed(sellerPlanDetails) {
    ////console.log(sellerPlanDetails)
    const elements = sellerPlanDetails?.toString()?.split(".");
    if (elements?.length >= 12) {
      const videoElement = parseFloat(elements[1]);
      if(videoElement>0){
        return true;
      } else{
        return 'expired'
      }   
     }
    return false;
  }


  async function addNewProduct() {
    setAddLoading(true);
    const sellerPlanDetailsres = await getSellerPlan();
    const sellerPlanDetails= await sellerPlanDetailsres?.result
    if(isAddProductAllowed(sellerPlanDetails)===true){
      navigate("/addProductBySeller2");
      setAddLoading(false);

    }
    else if(isAddProductAllowed(sellerPlanDetails)==='expired'){
      setAddLoading(false);

      errorAlert('','Cannot add more products in your existing subscription plan.')
    }
    else{
      setAddLoading(false);

      errorAlert('','Something went wrong. Try again.')

    }
  }

  const displayProductDetails = (product) => {
    ////console.log(product.id);
    navigate(`/SellerProductDetails2/${product?.productId}`);
  };

  return (
    <div>
      <SellerLayoutAdjustment>
      <div className="page-content">
      <Heading text="Products" />
          <div className="seller-products-list-navbar">
            {/* <div className="seller-products-list-add-products">
                <button className="common-button" onClick={addNewProduct}>+ Add New Products</button>
            </div> */}
            <div className="seller-products-list-search-and-filter">
              <div className="seller-category-list-back-nav-field">

              <IconButton onClick={handleNavigateBack}>
           <CaretLeft />
          </IconButton>
              {!addLoading&&<button className="common-button" onClick={addNewProduct}>
                + Add New Products
              </button>}
              {addLoading&&<div style={{width:'200px'}}><CircularProgress style={{color:'pink',  margin:'0 auto 0 50px'}} /></div>}
              </div>
{/* 
              <div className="seller-products-list-navbar-search-bar">
                <SearchIcon
                  className="seller-products-list-navbar-search-icon"
                  // icon="@Icons.Filled.Search" 
                  fontSize="medium"
                />
                <input
                  className="seller-products-list-navbar-search"
                  type="string"
                  placeholder="search"
                />
              </div> */}
            </div>
          </div>
          {loading && <div className="loading-container" style={{ minHeight: "300px", width:'90%', maxWidth:'400px', margin:'0 auto' }}>
            <Loading />

          </div>}

          {!loading&& <div className="seller-product-cards">
            {productsOfSelectedCategory?.result?.map((product) => (
              <SellerProductCard
              imgUrl = {product?.productSKUs[0]?.imagesUriList[0]?.thumbnailURI}
                key={product?.productId}
                title={product?.title}
                data={product}
                onClick={()=>{displayProductDetails(product)}}
              />
            ))}
          </div>}
        </div>
      </SellerLayoutAdjustment>
    </div>
  );
}


export default withSellerAuth(SellerProductsPage);