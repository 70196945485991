import { TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import {  useNavigate, useParams } from "react-router-dom";
import Heading from "../components/Heading";
import AdminLayoutAdjustment from "../components/AdminLayoutAdjustment";
import { useContext } from "react";
import { API_BASE_URL } from '../App'

import { AdminContext } from "../context/AdminContext";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import withAdminAuth from "../context/withAdminAuth";
import { errorAlert } from "../components/Alert";
//import notif from "../components/Notify";

 function AdminApprovalDetailVerification() {
  const params = useParams();
  const uniqueId = params.id;

  const navigate = useNavigate();

  // ////console.log(uniqueId);
  const { accessToken,handleAuthExpiry, logout } = useContext(AdminContext);

  const [sellerCertUri, setSellerCertUri] = useState();
  const [
    adminApprovaldetailVerificationData,
    setAdminApprovaldetailVerificationData,
  ] = useState([]);
  const [props, setProps] = useState([])

  const [messageField, setMessageField] = useState(false);
  const [message, setMessage] = useState("");
  const [edit, setEdit] = useState(false);
  const [sellerStatus, setSellerStatus] = useState("");

  // const [loaded, setLoaded] = useState(false);

  const fetchAdminApprovaldetailVerificationData = async () => {
    const response = await fetch(
      `${API_BASE_URL}/seller/api/v1/GetSellerById&GetAllSeller?SellerId=${uniqueId}`,
      {
        // mode: "no-cors",
        headers: {
          Authorization: `Bearer ${accessToken}`,

          // 'Accept': 'text/plain',
          // 'Content-Type': 'text/plain',
        },
      }
    );
    const data = await response.json();
    if(data?.status===401){
      errorAlert('','Session timed out. Please Log in');
      await new Promise((resolve) => setTimeout(resolve, 1000));
      navigate('/')
    }
    ////console.log(data);
    setAdminApprovaldetailVerificationData(data.result);
    setProps(data?.result)
    setSellerStatus(data.result?.status);
    getCertificate();

    // getCertificate()
    ////console.log(adminApprovaldetailVerificationData);
  };

  //   useEffect(() => {
  //     fetchAdminApprovaldetailVerificationData();
  // // eslint-disable-next-line
  //   }, []);
  useEffect(() => {
    if (accessToken) {
      fetchAdminApprovaldetailVerificationData();
    }
  }, [accessToken]);

  useEffect(() => {
    if (adminApprovaldetailVerificationData) {
      getCertificate();
    }
  }, [adminApprovaldetailVerificationData]);

  async function getCertificate() {
    try {
      if (
        !adminApprovaldetailVerificationData ||
        !adminApprovaldetailVerificationData.referenceNumber
      ) {
        ////console.log("Missing reference number");
        return;
      }

      const data = await fetch(
        `${API_BASE_URL}/blob/api/v1/getfile`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-type": "application/json-patch+json",
          },
          body: JSON.stringify({
            fileNameWithExtension: [
              `${adminApprovaldetailVerificationData.referenceNumber}`,
            ],
            fileCategory: "Msme",
          }),
        }
      );

      const response = await data.json();
      ////console.log(response);
      setSellerCertUri(response?.result[0]?.uri);
    } catch (e) {
      ////console.log(e);
    }
  }

  const updateRejectMesssage = async () => {
    setMessageField(true);
  };

  async function rejectSeller() {
    if(message && /.*[a-zA-Z].*/.test(message)){
      await postRejectData(message);
    }
    else{
      alert('Message is required so that seller knows what he is missing')
    }

  }

  async function postApproveData() {
    if(  !(props.companyName &&
      props.companyAddress &&
      props.sellerName &&
      props.sellerMobileNumber &&
      props.emailId &&
      props.aadharNumber &&
      props.panNumber &&
      props.accountNumber &&
      props.accountHolderName &&
      props.bankIFSC &&
      props.branchCode &&
      props.status &&
      props.dateOfSubmition &&
      props.typeOfAccount &&
      props.sellerPlanName)){
        alert('Complete Seller Details not Available');
        return;
      }
    try {
      const data = await fetch(
        `${API_BASE_URL}/seller/api/v1/ConfirmByAdmin`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            // Accept: "application.json",
            "Content-type": "application/json-patch+json",
          },
          body: JSON.stringify({
            sellerId: adminApprovaldetailVerificationData?.sellerId,
          }),
        }
      );
      setSellerStatus("C");

      const response = await data.json();
      ////console.log(response);
      fetchAdminApprovaldetailVerificationData();
    } catch (e) {
      ////console.log(e);
    }
  }

  async function postRejectData(message) {
    if(  !(props.companyName &&
      props.companyAddress &&
      props.sellerName &&
      props.sellerMobileNumber &&
      props.emailId &&
      props.aadharNumber &&
      props.panNumber &&
      props.accountNumber &&
      props.accountHolderName &&
      props.bankIFSC &&
      props.branchCode &&
      props.status &&
      props.dateOfSubmition &&
      props.typeOfAccount &&
      props.sellerPlanName)){
        alert('Complete Seller Details not Available');
        return;
      }
    try {
      const response= await fetch(
        `${API_BASE_URL}/seller/api/v1/RejectByAdmin?SellerId=${adminApprovaldetailVerificationData?.sellerId}&comment=${message}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            // Accept: "application.json",
            "Content-type": "application/json-patch+json",
          },
         
        }
      );
      const data= await response.json();
      if(!data.result){
        setMessageField(false);
        setMessage(false);
        setSellerStatus("R");
        fetchAdminApprovaldetailVerificationData();
//notify('Seller has been Rejected');
      }
      else{
        //notify('An Error occured. Seller could\'nt be rejected');
        setMessageField(false);

      }
      
    } catch (e) {
      ////console.log(e);
    }
  }

  const editMode=()=>{
if(edit===true){
  return true;
}
else{
  return false;
}
  }

  const updateData = async ()=>{
    try {
      // ////console.log(state);
      const data = await fetch(
        `${API_BASE_URL}/seller/api/v1/ModifyDetails`,
        {
          method: "PUT",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            // id: state.guid,
            aadharNumber: props?.aadharNumber,
            panNumber: props.panNumber,
            gstNumber: props.gstNumber,
            companyName:props.companyName.trim(),
            companyAddress: props.companyAddress,
            accountNumber: props.accountNumber.trim(),
            bankIFSC: props.bankIFSC.trim(),
            accountHolderName: props.accountHolderName.trim(),
            branchCode:props.branchCode.trim(),
            typeOfAccount: props.typeOfAccount,
            msmeReferenceNumber: props.msme,
            sellerPlanName: props.sellerPlanName,
            sellerId: uniqueId,
          }),
        }
      );
      ////console.log(data);
      const response = await data.json();
      const handleAuthError = handleAuthExpiry(data);
      if (handleAuthError === true) {
        errorAlert('', 'Your Session has expired');
        logout();
        return;
      }
      ////console.log(response);
    } catch (e) {
      ////console.log(e);
      alert('',' Update Failed')
    }
  }

  return (
    <AdminLayoutAdjustment>
      <Heading text="Seller Approval" />
      <div className="admin-approval-details-list">
        <div className="admin-approval-details-category-card">
          <div className="admin-approval-details-category-card-name">
            Seller Personal Details
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Seller Name
            </div>
            <TextField
              className="admin-approval-details-textfield"
              disabled={edit===true?false:true}
              value={props?.sellerName}
              onChange={(e) => {
                setProps({ ...props, sellerName: e.target.value });
              }}
            />
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Seller Email
            </div>
            <TextField
              className="admin-approval-details-textfield"
              disabled={edit===true?false:true}
              value={props?.emailId}
              onChange={(e) => {
                setProps({ ...props, emailId: e.target.value });
              }} />
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Seller Phone Number
            </div>
            <TextField
              className="admin-approval-details-textfield"
              disabled={edit===true?false:true}
              value={props?.sellerMobileNumber}
              onChange={(e) => {
                setProps({ ...props, sellerMobileNumber: e.target.value });
              }} />
          </div>
        </div>

        <div className="admin-approval-details-category-card">
          <div className="admin-approval-details-category-card-name">
            Aadhar Details
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Aadhar Number
            </div>
            <TextField
              className="admin-approval-details-textfield"
              disabled
              value={props?.aadharNumber}
              onChange={(e) => {
                setProps({ ...props, aadharNumber: e.target.value });
              }}/>
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Aadhar Holder Name 
            </div>
            <TextField
              className="admin-approval-details-textfield"
              disabled
              value={(adminApprovaldetailVerificationData?.aadhar_HolderName && JSON.parse(adminApprovaldetailVerificationData?.aadhar_HolderName)?.data?.full_name) || ''}

            />
          </div>
        </div>

        <div className="admin-approval-details-category-card">
          <div className="admin-approval-details-category-card-name">
            Pan and GST Details
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">PAN</div>
            <TextField
              className="admin-approval-details-textfield"
              disabled              value={props?.panNumber}
              // onChange={(e) => {
              //   setProps({ ...props, panNumber: e.target.value });
              // }} 
              />
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">PAN Holder Name</div>
            <TextField
              className="admin-approval-details-textfield"
              disabled
              
              value={(adminApprovaldetailVerificationData?.pan_HolderName && JSON.parse(adminApprovaldetailVerificationData?.pan_HolderName)?.data?.full_name) || ''}
              />
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">GST</div>
            <TextField
              className="admin-approval-details-textfield"
              disabled={edit===true?false:true}
              value={props?.gstNumber}
              // onChange={(e) => {
              //   setProps({ ...props, gstNumber: e.target.value });
              // }}
              />
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">GST Holder Name</div>
            <TextField
              className="admin-approval-details-textfield"
              disabled
              value={(adminApprovaldetailVerificationData?.gsT_HolderName && JSON.parse(adminApprovaldetailVerificationData?.gsT_HolderName)?.data?.legal_name) || ''}

            />
          </div>
        </div>

        <div className="admin-approval-details-category-card">
          <div className="admin-approval-details-category-card-name">
            Bank Account Details
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Bank Name
            </div>
            <TextField
              className="admin-approval-details-textfield"
              disabled={edit===true?false:true}
              value={props?.branchCode}
              onChange={(e) => {
                setProps({ ...props, branchCode: e.target.value });
              }}/>
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Bank Account Number
            </div>
            <TextField
              className="admin-approval-details-textfield"
              disabled={edit===true?false:true}
              value={props?.accountNumber}
              onChange={(e) => {
                setProps({ ...props, accountNumber: e.target.value });
              }}/>
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Account Holder Name
            </div>
            <TextField
              className="admin-approval-details-textfield"
              disabled={edit===true?false:true}
              value={props?.accountHolderName}
              onChange={(e) => {
                setProps({ ...props, accountHolderName: e.target.value });
              }}/>
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Bank IFSC
            </div>
            <TextField
              className="admin-approval-details-textfield"
              disabled={edit===true?false:true}
              value={props?.bankIFSC}
              onChange={(e) => {
                setProps({ ...props, bankIFSC: e.target.value });
              }} />
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Account Type
            </div>
            <TextField
              className="admin-approval-details-textfield"
              disabled={edit===true?false:true}
              value={props?.typeOfAccount}
              onChange={(e) => {
                setProps({ ...props, typeOfAccount: e.target.value });
              }}/>
          </div>
        </div>

        <div className="admin-approval-details-category-card">
          <div className="admin-approval-details-category-card-name">
            MSME Details
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              MSME Image
            </div>

            <img
              src={sellerCertUri}
              className="admin-approval-cert-image"
              alt="msme"
              onClick={() => {
                window.open(sellerCertUri);
              }}
            />
            {/* <TextField
                    className="admin-approval-details-textfield"
                    disabled
                    value={adminApprovaldetailVerificationData?.referenceNumber}
                  /> */}
                  
          </div>
        </div>
        <div className="admin-approval-details-category-card">
          <div className="admin-approval-details-category-card-name">
            Subscription Plan
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Plan Name
            </div>

            <TextField
                    className="admin-approval-details-textfield"
                    disabled
                    value={adminApprovaldetailVerificationData?.sellerPlanName}
                  />
          </div>

          <div>
            {!edit&&<button className='common-button' onClick={()=>{setEdit(true)}}>Edit Details</button>}
            {edit&&<button className='common-button' onClick={()=>{setEdit(false); updateData();}}>Save  Changes</button>}
          </div>
          {adminApprovaldetailVerificationData?.status === "P" && (
          <div className="admin-approval-pending">
            <div>Do you want to Approve this Seller ?</div>
            <button
              className="common-button"
              onClick={() => {
                postApproveData();
              }}
            >
              Approve
            </button>
            <button
              className="common-button reject-button"
              onClick={() => {
                // setConfirmUser("R");
                updateRejectMesssage();
                // postRejectData();
              }}
            >
              Reject
            </button>
          </div>
        )}

        
        {adminApprovaldetailVerificationData?.status === "C" && (
          <div className="admin-approval-updated-status">
            You have Approved this Seller!
          </div>
        )}
        {adminApprovaldetailVerificationData?.status === "R" && (
          <div className="admin-approval-updated-status">
            You have Rejected this Seller!
          </div>
        )}
        {messageField && (
          <div>
            <div className="admin-reject-message">
              {/* <TextField fullWidth outlined/> */}
              <textarea
                className="admin-approval-details-text-area"
                value={message}
                placeholder="Enter the reason/s for which you are rejecting this seller"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
              <button
                className="common-button"
                onClick={rejectSeller}
              >Reject This Seller</button>
            </div>
          </div>
        )}
        </div>
      </div>
    </AdminLayoutAdjustment>
  );
}

export default withAdminAuth(AdminApprovalDetailVerification);
