import React, { useState, useEffect } from "react";
import "../styles/SellerDashboard.css"
import "../styles/Seller.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
// import Chart from 'chart.js/auto';
import { Chart, ArcElement } from "chart.js";
import DashboardData from "../DashboardData";
import { Bar, Pie } from "react-chartjs-2";
import sellerProductsData from "../SellerProductsData";
import SellerDashboardTopProductsCard from "../components/SellerDashboardTopProductsCard";
import { CategoryScale } from "chart.js";
import { registerables } from "chart.js";
import withSellerAuth from "../context/withSellerAuth";
import SellerLayout from "../components/SellerLayout";
import Heading from "../components/Heading";
import dashboardWelcome from "../Images/dashboardWelcome.png";
import ellipse from "../Images/Ellipse .png";
import visitors from "../Images/visitorsIcon.png";
import order from "../Images/NewOrdersIcon.png";
import TotalProducts from "../Images/TotalProducts.png";
import InDelivery from "../Images/InDelivery.png";
import  profilePic from "../Images/ProductHolder.png"
import SellerLayoutAdjustment from "../components/SellerLayoutAdjustment";
import { useNavigate } from "react-router-dom";
import { errorAlert } from "../components/Alert";
import { SellerContext } from "../context/SellerContext";
import { useContext } from "react";
Chart.register(...registerables);
Chart.register(CategoryScale);

function SellerDashboard() {
  const { accessToken , sellerData} = useContext(SellerContext);
  const navigate = useNavigate();
  const data = [
    // { profilePic: <img loading="lazy" src={profilePic} alt="profile pic" />, name: "Rahul", value: 500 },
    // { profilePic: <img loading="lazy" src={profilePic} alt="profile pic" />, name: "John", value: 400 },
    // { profilePic: <img loading="lazy" src={profilePic} alt="profile pic" />, name: "Sarah", value: 300 },
    
  ];
  const ratings = [
    { name: "Excelent", count: 0, stars: "⭐⭐⭐⭐⭐", barClass: "bar-5" },
    { name: "Good", count: 0, stars: "⭐⭐⭐⭐", barClass: "bar-4" },
    { name: "Average", count: 0, stars: "⭐⭐⭐", barClass: "bar-3" },
    { name: "Below Average", count: 0, stars: "⭐⭐", barClass: "bar-2" }
  ];
  var products = [
    {
      name: "Product1",
      // image:  <img loading="lazy" src={profilePic} alt="profile pic" />,
      sale: 230,
      availability: true
    },
    {
      name: "Product2",
      // image: <img loading="lazy" src={profilePic} alt="profile pic" />,
      sale: 240,
      availability: true
    },
    {
      name: "Product3",
      // image:  <img loading="lazy" src={profilePic} alt="profile pic" />,
      sale: 230,
      availability: false
    },
    {
      name: "Product4",
      // image:  <img loading="lazy" src={profilePic} alt="profile pic" />,
      sale: 230,
      availability: true
    },

  ];
  Chart.register(ArcElement);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        // display: true,
        // text: 'Chart.js Bar Chart',
      },
    },
  };

  const [sellerDashboardData, setSellerDashboardData] = useState([]);

  // const fetchDashboardData = async (sellerId) => {
  //   const response = await fetch(`${API_BASE_URL}/products/api/v1/get?sellerId=${sellerId}`, {
  //     // mode: "no-cors",
  //     headers: {
  //       // 'Accept': 'text/plain',
  //       "Authorization": `Bearer ${accessToken}`,
  //       // 'Content-Type': 'text/plain',
  //       // "Access-Control-Allow-Origin": "*",
  //     },
  //   });
  //   const data = await response.json();
  //   if(data?.status===401){
  //     errorAlert('Session timed out. Please Log in');
  //     await new Promise((resolve) => setTimeout(resolve, 1000));
  //     navigate('/')
  //   }
  //   setSellerDashboardData(data);
  //   ////console.log(data);
  // };

  // useEffect(() => {
  //   fetchDashboardData();
  // }, []);

  const visitorData = 0
// sellerDashboardData.slice(0,4).map((i)=>{
//  return i.name.length;
// })

const newOrdersData = 0
// sellerDashboardData.slice(3,7).map((i)=>{
//  return i.name.length;
// })

const readyToShipData = 0
// sellerDashboardData.slice(5,9).map((i)=>{
//  return i.name.length;
// })

const inDeliveryData = 0
// sellerDashboardData.slice(2,7).map((i)=>{
//  return i.name.length;
// })

const valuesForPieChartData = 0
// sellerDashboardData.slice(0,5).map((i)=>{
//  return i.name.length;
// })
  

  ////console.log(visitorData);

  const barChartData = {
    labels: ["Nov 22", "Dec 22", "Jan 23", "Feb 23"],
    datasets: [
      {
        label: "Visitors",
        data: visitorData,
        backgroundColor: ["rgba(255, 253, 216, 1)"],
        borderColor: ["rgba(255, 253, 216, 1)"],
        borderWidth: 1,
      },
      {
        label: "New Orders",
        data: newOrdersData,
        backgroundColor: ["rgba(212, 240, 240, 1)"],
        borderColor: ["rgba(212, 240, 240, 0.3)"],
        borderWidth: 1,
      },
      {
        label: "Ready to ship",
        data: readyToShipData,
        backgroundColor: ["rgba(255, 239, 243, 1)"],
        borderColor: ["rgba(255, 239, 243, 1)"],
        borderWidth: 1,
      },
      {
        label: "In Delivery",
        data: inDeliveryData,
        backgroundColor: ["rgba(239, 255, 233, 1)"],
        borderColor: ["rgba(239, 255, 233, 1)"],
        borderWidth: 1,
      },
    ],
  };
  const pieChartData = {
    labels: [
      "Clothing",
      "Kitchenware",
      "Accessories",
      "Cosmetics",
      "Home Decore",
    ],
    datasets: [
      {
        label: "Visitors",
        data: valuesForPieChartData,
        backgroundColor: [
          " #3BDDF3",
          "#AAEFF9",
          "#31A5CA",
          "#D0F9FF",
          "#7DF7C3",
        ],
        borderColor: [" #3BDDF3", "#AAEFF9", "#31A5CA", "#D0F9FF", "#7DF7C3"],
        borderWidth: 1,
      },
    ],
  };



  return (
    <SellerLayoutAdjustment>
 <Heading text="Dashboard" />
 <>
      <div className='admin-dashboard-welcome'>
    <div className='seller-dashboard-status'>
    <img loading="lazy" src={dashboardWelcome} alt="" className='welcome-img' />
    <div className='welcome-desc'>
    <div className="dashboard-section-head">Welcome to MyhraKi!</div>
    <div className="dashboard-section-para">
      Activities you need to monitor to maintain buyer satisfaction
    </div>
    </div>
    </div>
    <div className='seller-dashboard-status-help'>
      <div className='admin-dashboard-status-help-img'> <img loading="lazy" src={ellipse} alt=""  className='ellipse-img'/></div>
      <div className='admin-dashboard-status-help-title'>Need Help?</div>
      <div className='admin-dashboard-status-help-desc'>Customer support is here to solve all your doubts reach out on 9876543210</div>
    </div>
    </div>
    <div className="seller-activities-cards">
    <div className="dashboard-section-head">Important Today</div>
    <div className="dashboard-section-para">
      Activities you need to monitor to maintain buyer satisfaction
    </div>
   
    <div className='admin-dashboard-activities-icon'>
    {/* <div className="seller-activities-card visitors-card">
      <VisibilityIcon className="seller-activities-icon" />
      <div className="seller-activities-card-data">
        <div className="seller-activities-card-data-field">{0}</div>
        <div className="seller-activities-card-data-field small-text">
          New Visitors
        </div>
      </div>
    </div> */}

   
    <div className="seller-activities-card new-orders-card">
      <ShoppingBagIcon className="seller-activities-icon" />
      <div className="seller-activities-card-data">
        <div className="seller-activities-card-data-field">{0}</div>
        <div className="seller-activities-card-data-field small-text">
          New Orders
        </div>
      </div>
    </div>
   
   
    <div className="seller-activities-card ready-to-ship-card">
      <LocalShippingIcon className="seller-activities-icon" />
      <div className="seller-activities-card-data">
        <div className="seller-activities-card-data-field">{0}</div>
        <div className="seller-activities-card-data-field small-text">
          Ready to ship
        </div>
      </div>
    </div>
    
   
    <div className="seller-activities-card in-delivery-card">
      <DeliveryDiningIcon className="seller-activities-icon" />
      <div className="seller-activities-card-data">
      <div className="seller-activities-card-data-field">{0}</div>
        <div className="seller-activities-card-data-field small-text">
          In Delivery
        </div>
      </div>
    </div>
    </div>
  </div>

      <div className="seller-dashboard-card-layout-chart">
        <div className="seller-barChart">
          <Bar options={options} data={barChartData} height="125"/>
        </div>
        <div className="seller-pieChart">
          <Pie options={options} data={pieChartData} />
        </div>
      </div>

     {/* <div className="seller-dashboard-card-layout">
   <div className="seller-dashboard-table-field" style={{width:"100%"}}>
    <div className="seller-dashboard-table-title">Top Selling Products</div>
<div className="seller-dashboard-table-content">
<table className="SellerDiscount-table">
<thead >
    <tr >
      <th>No</th>
      <th >Product Name</th>
      <th >Sale</th>
      <th >Product Availability</th>
    </tr>
  </thead>
  <tbody >
    {products.map((product, index) => (
      <tr key={index} >
        <td data-th="No" >{index + 1}</td>
        <td data-th="Name">
          <img loading="lazy" src={product.image} alt={product.name} />
          <span>{product.name}</span>
        </td>
        <td data-th="sale">{product.sale}</td>
        <td data-th="Action">
          <button className="seller-dashboard-button">{product.availability ? 'In Stock' : 'Out of Stock'}</button>
        </td>
      </tr>
    ))}
  </tbody>
</table>

</div>
    
   </div>
   </div> */}
   </>
    </SellerLayoutAdjustment>
  );
}

export default (SellerDashboard)