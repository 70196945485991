import React, { useEffect, useState } from "react";
import "../styles/Contact.css";
import CallIcon from "@mui/icons-material/Call";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import * as yup from "yup";
import { TextField } from "@mui/material";
// import { useNavigate } from "react-router-dom";
import Chatbot from "../components/ChatBot";
import BcBuyerLayout from "../buyerComponents/BcBuyerLayout";
import Breadcrumb from "../buyerComponents/Breadcrumb";
import useNavigationTracking from "../components/NavigationTracking";
import { useFormik } from "formik";

const validationSchema = yup.object({
  fullname: yup
    .string("Enter fullname")
    .required("Full name is required")
    .min(3, "Full Name should be 3 chars atleast"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  instructions: yup
    .string("What can we help you with")
    .required("This field is required")
    .min(3, "Full Name should be 3 chars atleast"),
});
export default function Contact() {

  useNavigationTracking();

  const formik = useFormik({
    initialValues: {
      fullname: "",
      email: "",
      instructions: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setEmail(false);
      resetForm({ values: "" });
    },
  });

  const [email, setEmail] = useState(false);
  const [chat, setChat] = useState(false);

  function setEmailContact() {
    setEmail(true);
  }
  // const navigate = useNavigate()
  const[contactData , setContactData]=useState("");
  const fetchContactData = async () => {
    const response = await fetch("https://jsonplaceholder.typicode.com/users", {
      // mode: "no-cors",
      headers: {
        // 'Accept': 'text/plain',
        // 'Content-Type': 'text/plain',
        "Access-Control-Allow-Origin": "*",
      },
    });
    const data = await response.json();
    setContactData(data);
  };

  useEffect(() => {
    fetchContactData();
  }, []);  
  const recipient = "hello@myhraki.com"; 
const subject = "Fill out your quries and we’ll get back to you in 24 hours"; 
const gmailUrl = `https://mail.google.com/mail/?view=cm&to=${recipient}&su=${encodeURIComponent(subject)}`;

  return (
    <BcBuyerLayout>
    <div className="buyers-my-account ">
      <div className="buyers-my-account-container">
        <div className="page-content">
          {/* <MyAccount /> */}
          {/* <HelperMyAccount /> */}
          <Breadcrumb tab='Contact Us' />
            {!email && !chat && (
              <div className="contact-container">
                <div className="contact-email">
                  <div className="contact-card">
                  <MailOutlineIcon className="contact-icon" />
                    <div className="contact-title">Email Us</div>
                    <div className="contact-text">
                      Fill out our form and we'll get back to you in 24 hours
                    </div>
                    {/* <button
                      className="contact-button"
                      onClick={setEmailContact}
                    >
                      Get Started
                    </button> */}
                    <a href={gmailUrl} className="get-help-gmail">Get Help</a>
                  </div>
                 
                </div>
                <div className="contact-phone">
                  <div className="contact-card">
                  <CallIcon className="contact-icon" />
                    <div className="contact-title">Contact Us</div>
                    <div className="contact-text-number">1800 203 1217</div>
                  </div>
                  
                </div>
                {/* <div className="contact-bot">
                  <div className="contact-card">
                  <ChatBubbleOutlineIcon className="contact-icon" />
                    <div className="contact-title">Chat Live</div>
                    <div className="contact-text">
                      We're available Mon 9:00am - Sat 7:00pm
                    </div>
                    <button
                      className="contact-button"
                      onClick={() => {
                        setChat(true);
                      }}
                    >
                      Chat Now
                    </button>
                  </div>
                  
                </div> */}
              </div>
            )}
            {email && (
              <div className="contact-email-section">
                <div className="Contact-Fields-head">
                <div > Contact Us </div>
                <div>|</div>
                <div style={{fontSize:"15px",color: "rgba(112, 112, 112, 0.72)"}}>email us </div> 
                </div>
                <MailOutlineIcon className="contact-email-section-icon" />
                <form
                  className="contact-email-section-form"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="email-section-form-field">
                    <div className="contact-field-name">Full Name </div>
                    <TextField
                      // autoFocus
                      autoComplete="off"
                      variant="outlined"
                      //   fullWidth
    type="text"                      id="email-section-fullname"
                      name="fullname"
                      className="email-field"
                      placeholder=" "
                      value={formik.values.fullname}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.fullname &&
                        Boolean(formik.errors.fullname)
                      }
                      helperText={
                        formik.touched.fullname && formik.errors.fullname
                      }
                    />
                  </div>
                  <div className="email-section-form-field">
                    <div className="contact-field-name">Email </div>
                    <TextField
                      autoComplete="off"
                      variant="outlined"
                      //   fullWidth
                      type="email"
                      id="email-section-email"
                      name="email"
                      className="email-field"
                      placeholder=" "
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </div>
                  <div className="email-section-form-field">
                    <div className="contact-field-name">
                      {" "}
                      What can we help you with ?
                    </div>
                    <textarea
                      multiLine
                      autoComplete="off"
                      variant="outlined"
                      //   fullWidth
                        // type="text"
                      id="email-section-instructions"
                      name="instructions"
                      className="email-field"
                      placeholder=" "
                      value={formik.values.instructions}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.instructions &&
                        Boolean(formik.errors.instructions)
                      }
                      helperText={
                        formik.touched.instructions &&
                        formik.errors.instructions
                      }
                    />
                  </div>
                  <div className="contact-button-field">
                  <input
                    type="submit"
                    value="Submit"
                    className="contact-button"
                    onClick={formik.handleSubmit}
                  />
                  </div>
                </form>
              </div>
            )}
            {chat && (
              <div className="contact-email-section">
                <div className="Contact-Fields-head">
                <div > Contact Us </div>
                <div>|</div>
                <div style={{fontSize:"15px",color: "rgba(112, 112, 112, 0.72)"}}>email us </div> 
               
                </div>
             <div>
              <Chatbot />
            <span style={{ color: "rgba(255, 97, 85, 1)", textDecoration: "underline", display:"flex",justifyContent:"center",alignItems:"center",marginTop:"2%" ,marginBottom:"2%"}}className="custom-button">
            Back
            </span>
            </div>
           </div>
            )}
          </div>
        </div>
      </div>
    </BcBuyerLayout>
  );
}
