import React, { useState, useEffect, useRef } from "react";
import "../styles/SellerSignupOtpVerify.css";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../Images/MainLogo.png";
import "../styles/Common.css";
import "../styles/SellerAcceptInvite.css";
import { useContext } from "react";
import { SellerContext } from "../context/SellerContext";
import inviteAcceptImg from "../Images/thankYouScreenImg.png";
import CardComponent from "../components/CardComponent";
import { CircularProgress } from "@mui/material";
import ToastComponent from "../components/ToastComponent";
import { API_BASE_URL } from "../App";

export default function SellerAcceptInvite() {
  const navigate = useNavigate();
  const { sellerData, setSellerData } = useContext(SellerContext);
  const params = useParams();
  const invitedSellerUniqueId = params.id;
  const [loading, setLoading] = useState(false);
  const [errorUser, setErrorUser] = useState(false);
  const [invitedSellerData, setInvitedSellerData] = useState();
  const toastRef= useRef();

  

  useEffect(() => {
    getSellerStatus();
  }, []);


  const getSellerStatus = async () => {
    setLoading(true);
    try{

    
    const response = await fetch(
      `${API_BASE_URL}/seller/api/v1/GetSellerInfo?uniqueID=${invitedSellerUniqueId}`,
      {
        method: "GET",

        headers: {
          // 'Accept': 'application/json',
          // "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    setLoading(false);
      console.log(data);
    if(data?.message==="Check error"){
      navigate('/')
      return;
    }
    //console.log("AAAA", data);
    if(data?.result ==="You are rejected this link connect to admin"){
      navigate('/sellerRejectInvite');
      return;
    }
    if(data?.message === "Link has been expired"){
      navigate('/')
      return;
    }
    if (data?.result?.status === "A") {

      setSellerData(data);
      
      navigate("/sellerRegister");
    } else if(data?.result?.status === "P") {
      setInvitedSellerData(data);
      setSellerData(data);
    }
    else{
      setErrorUser(true);
      toastRef.current.showToast("Something went wrong. Try again later", "error");
      return;
    }
  }catch{
    setErrorUser(true);
    setLoading(false);
    toastRef.current.showToast("Something went wrong. Try again later", "error");
  }
}

  const fetchInvitedSellerData = async () => {
    console.log('fetch call')
    try{

    
    setLoading(true);
    const response = await fetch(
      `${API_BASE_URL}/seller/api/v1/GetSellerInfo?uniqueID=${invitedSellerUniqueId}`,
      {
              headers: {
        },
      }
    );
    setLoading(false);
    const data = await response.json();
    if(data?.message==="Check error"){
      navigate('/')
      return;
    }
    //console.log("AAAA", data);
    if(data?.result ==="You are rejected this link connect to admin"){
      navigate('/sellerRejectInvite');
      return;
    }
    if(data?.message === "Link has been expired"){
      navigate('/')
      return;
    }
    if (data?.result?.status === "A") {

      setSellerData(data);
      
      navigate("/sellerRegister");
    } else if(data?.result?.status === "P") {
      setInvitedSellerData(data);
      setSellerData(data);
    }
    else{
      setLoading(false);

      toastRef.current.showToast("Something went wrong. Try again later", "error");
      return;
    }
   
  }catch{
    setLoading(false);

    toastRef.current.showToast("Something went wrong. Try again later", "error");

  }
}

  useEffect(() => {
   sellerData?.result?.name && fetchInvitedSellerData();
    // eslint-disable-next-line
  }, []);


  async function postAcceptData() {
   await fetchInvitedSellerData();
   if(sellerData?.result?.phone){

     navigate("/sellerOtpConfirmation");
   }
  }

  async function postRejectData() {
    setLoading(true);
    try {
      await fetch(
        `${API_BASE_URL}/seller/api/v1/RejectBySeller`,
        {
          method: "put",

          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            sellerId: invitedSellerUniqueId,
          }),
        }
      );
      setLoading(false);
      navigate("/sellerInviteReject");

    } catch (e) {
      //console.log(e);
      setLoading(false);

    }
  }

  return (
    <div>
                     <ToastComponent ref={toastRef} timeout={4000} />

      <CardComponent
        cardImg={
          <img loading="lazy" className="inviteAcceptImg" src={inviteAcceptImg} alt="" />
        }
        imageFirst={true}
      >
        <div className="seller-accept-head">
          Welcome To
          <img loading="lazy" src={logo} className="Myhraki-logo" alt="" />
        </div>
        {!loading && !errorUser && <div className="seller-accept-buttons">
          <button
            className="common-button reject-button"
            onClick={async() => {
              await postRejectData();
            }}
          >
            Decline
          </button>
          <button
            className="common-button accept-button"
            onClick={() => {
              postAcceptData();
              //console.log(invitedSellerUniqueId);
              //console.log(sellerData);
            }}
          >
            Accept
          </button>
        </div>}
        {!loading && errorUser &&<div>
        <div style={{ backgroundColor: 'lightpink', color: 'maroon', padding: '8px 24px 8px 24px', width: '80%', margin: '0 auto', fontSize: '12px', borderRadius: '10px', textAlign: 'center', position: 'relative', bottom: '15px' }} > There was an error fetching your data</div>
          <div>If you have already registered, <span onClick={()=>{navigate('/')}} style={{textDecoration:'undereline', color:'#fe468a', cursor:'pointer', fontSize:'18px'}}>Login</span></div>
          </div>}
        {loading && <div style={{display:"flex", width:"100%",alignItems:"center",justifyContent:"center"}}><CircularProgress style={{color:"pink" }} /></div>}

        <div className="seller-accept-text">
          Inviting you to join the family of MyhraKi and explore the magnificent
          features
        </div>
      </CardComponent>
    </div>
  ); 
}
