import React from "react";
import "../styles/SellerNavbar.css";
import sellerLogo from "../Images/logo.png";
// import SearchIcon from "@mui/icons-material/Search";
// import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Avatar,Menu } from '@mui/material';
import {  MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import cart from "../cart.png";
// import SubsSubnav from "./SubsSubnav";

export default function SubsNavbar() {
  const navigate = useNavigate();

  function gotoAccount() {
    // ////console.log("BBB")
    navigate("/account");
  }
  // function gotoOrders() {
  //   // ////console.log("BBB")
  //   navigate("/myOrders");
  // }
  // function gotoWishlist() {
  //   // ////console.log("BBB")
  //   navigate("/wishlist");
  // }
  function gotoProfile() {
    // ////console.log("BBB")
    navigate("/home");
  }
  // function gotoCart() {
  //   // ////console.log("BBB")
  //   navigate("/cart");
  // }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="seller-navbar-container">
      <div className="seller-navbar-left">
        <img loading="lazy" className="seller-navbar-logo" src={sellerLogo} alt="logo" />
      </div>
      <div className="seller-navbar-right">
        <div className="seller-subnav">
          {/* <div className="seller-subnav"> */}
            <input type="checkbox" id="seller-subnav-check" />

            <div className="seller-subnav-btn">
              <label for="seller-subnav-check">
                <span></span>
                <span></span>
                <span></span>
              </label>
            </div>

            <div className="seller-subnav-links" style={{ marginLeft: "3rem" }}>
              <a href="/sellerDashboard">Dashboards</a>
              <a href="/sellerProductsList">Products</a>
              <a href="/sellerCustomers">Customers</a>
              <a href="/sellerOrders">Orders</a>
              <a href="/sellerDiscounts">Discounts</a>
              <a href="/sellerFacts">Facts</a>
            </div>
          {/* </div> */}
          {/* <Divider sx={{ mt: 2 }} /> */}
        </div>
        <div className="seller-navbar-icons">
          {/* <FavoriteBorderIcon icon="@Icons.Filled.FavoriteBorder" className="navbar-wishlist" fontSize='large' onClick={gotoWishlist}/>
        <img loading="lazy" alt=" "  src={cart} className="navbar-cart" onClick={gotoCart}/> */}
          {/* <AccountCircleIcon icon="@Icons.Outlined.AccountCircle" className="navbar-profile"  /> */}
          <div
            title="Account settings"
            style={{ display: "inline-block" }}
            onClick={handleClick}
            size="medium"
            // sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <AccountCircleIcon
              icon="@Icons.Outlined.AccountCircle"
              className="seller-navbar-profile"
              fontSize="large"
            />
          </div>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 5.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: -20,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-80%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem className="menu-item" onClick={gotoProfile}>
              <Avatar /> Profile
            </MenuItem>
            <MenuItem className="menu-item" onClick={gotoAccount}>
              <Avatar /> My account
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
}
