import { Divider } from "@mui/material";
import React from "react";
import "../styles/SellerSubscriptionSelection.css"

export default function SellerSubscriptionSelectionCard(props) {
    // //console.log(props.planFeatures)
    // const sortedPlanFeatures = props.planFeatures.sort((a, b) => {
    //     return parseInt(a.featureOrder) - parseInt(b.featureOrder);
    //   });
  return (
    <div className={`seller-subscription-select-card ${props.selected?"selected-subscription-card":""}`} onClick={props.onClick}>
      <div className="seller-subscription-select-card-head">
        <div className="title-subscription">{props.title}</div>
        {props?.planName===props.title&&<div style={{position:'absolute', bottom:'280px', left:'25%', fontSize:'28px', color:'#FA068A'}}>Current Plan</div>}
        {/* <Heading text={props.title} /> */}
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column" ,marginBottom:"28px"}}>
        <div className="seller-subscription-select-card-plan-details">
        <div className="seller-subscription-select-card-plan-price">
  {props.value > 0 ? (
    <span className="price-with-rupee">{props.value}</span>
  ) : (
    "Free"
  )}
</div>          {!props.isAnnual&&<div className="seller-subscription-select-card-plan-frequency">
          </div>}
          {props.isAnnual&& props.value!==0 &&<div className="seller-subscription-select-card-plan-frequency">
          per month
          </div>}
         
        </div>        <hr style={{ width: "229px", height: "0px", flexShrink: 0, borderWidth: "0.5px", borderColor: "#000", opacity: 0.3 ,position: "relative",bottom:"8px" }} />
        <div className="seller-subscription-select-card-plan-annualPrice">
  { props?.value>0&&
    <span className="price-with-rupee-annual">₹ {props.value * 12} Yearly</span>
  }
  { props.value<=0&&
    <span className="price-with-rupee-annual">No Commitments</span>
  }
</div>
          </div>
      </div>
      <Divider className="subs-card-divider"/>
      <div className="seller-subscription-select-card-body">

        {props.features.filter((feature=>!(feature.descriptionValue.toLowerCase()==="no"||((feature.descriptionValue.toLowerCase()==="0")&&(feature.isValueView==="0")))  )).map((feature)=>{
        return (<div className="seller-subscription-select-card-feature">
          •
          <div>{`${feature.isValueView==="0"?"":feature.isValueView===false?"":feature.descriptionValue==="Yes"?"":feature.descriptionValue==="0"?"":feature.descriptionValue}  ${feature.description} ` }</div>
        </div>)
       
        })}
     
      </div>
    </div>
  );
}
