import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';

function SingleProductImageCarousel({ imagesUriList }) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  try {
    const imageItems = imagesUriList?.map((image ,index) => ({
      original: image.uri,
      thumbnail: image.thumbnailURI,
      // thumbnailClass: index === selectedImageIndex ? 'selected-thumbnail' : '',
      onClick: () => {
        setSelectedImageIndex(index);
      },

    }));
   
    return (
      <div >
        {imageItems ? (
          <ImageGallery items={imageItems}   thumbnailPosition='left'     />
        ) : (
          <div>Loading...</div>
        )}
      </div>
      
    );
  } catch (error) {
    console.error("Error rendering image carousel:", error);
    return <div>Error rendering image carousel</div>;
  }
}

export default SingleProductImageCarousel;
