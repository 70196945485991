import React, { useContext } from "react";
import "../styles/AdminAddCategory.css";
import AdminLayoutAdjustment from "../components/AdminLayoutAdjustment";
import Heading from "../components/Heading";
import { CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import { API_BASE_URL } from '../App'

import { useEffect } from "react";
import { CheckCircle, DeleteOutline, EditOutlined } from "@mui/icons-material";
import { AdminContext } from "../context/AdminContext";
import { useNavigate } from "react-router-dom";
import { confirmAlert, successAlert } from "../components/Alert";
import withAdminAuth from "../context/withAdminAuth";
import { errorAlert } from "../components/Alert";
import { v4 as uuidv4 } from "uuid";
// import Button from '@mui/joy/Button';
// import FormControl from '@mui/joy/FormControl';
// import FormLabel from '@mui/joy/FormLabel';
// import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
// import Stack from '@mui/joy/Stack';
// import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
// import CustomSearchBar from "../components/CustomSearchBar";

function AdminAddCategory() {
  
  const { accessToken } = useContext(AdminContext);
  const [requestFailed, setRequestFailed] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categoryGst, setCategoryGst] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [imageName, setImageName] = useState('')
  const [selectedCategory, setSelectedCategory] = useState();
  const[categories,setCategories]=useState();
  const navigate = useNavigate();

  const categoryNameRegExp = /^[a-zA-Z,&'\- ']{1,50}$/;
  const fetchCategories = async () => {
    try {
      let response = await fetch(
        `${API_BASE_URL}/productgen/api/v1/category/All`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      let data = await response.json();
      if (data?.status === 401) {
        errorAlert('','Session timed out. Please Log in');
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate('/')
      }
      setCategories(data.result);
      setRequestFailed(false);
    } catch (error) {
      //console.error(error);
      setRequestFailed(true);
      if (error.title === "Unauthorized") {
        navigate("/");
      }
    }
  };

  useEffect(() => {
    if (accessToken) {
      fetchCategories();
    }
  }, [accessToken]);

  useEffect(() => {
    if (requestFailed) {
      navigate("/");
    }
  }, [requestFailed, navigate]);



  async function DeleteCategory(categoryId) {

    ////console.log(categoryId);
    setCategories(
      categories.filter((category) => category.categoryId !== categoryId)
    );

    fetch(`${API_BASE_URL}/productgen/api/v1/category/remove/${categoryId}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${accessToken}`,

      },
      method: "delete",
    });
    setTimeout(fetchCategories, 1000);



  }


  const [filteredData, setFilteredData] = useState(categories);

  useEffect(()=>{
    setFilteredData(categories)
  },[categories])

  // const handleFilter = (filteredItems) => {
  //   setFilteredData(filteredItems);
  // };



  async function handleAddCategorySubmit() {

    if (categoryNameRegExp.test(categoryName)) {
      try {
        let result = await fetch(`${API_BASE_URL}/productgen/api/v1/category/Create`, {
          method: "post",
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${accessToken}`,

          },
          body: JSON.stringify({
            "categoryName": categoryName.trim(),
            "categoryWiseGST": categoryGst.trim(),
            "imageNameWithExtension": imageName,

          }),
        });
        ////console.log(result);
        ////console.log( categoryName);
        const newCategory = await result.json();
        if (newCategory?.result === true) {

          setCategories([...categories, newCategory]);
          setCategoryName("");
          setCategoryGst("");
          setTimeout(fetchCategories, 200);
          setImageName(null);
          successAlert('', 'Category Added Successfully')
        }
        else {
          errorAlert('', 'Could not add category. Please try again.')
        }
      } catch (e) {
        ////console.log(e);
        errorAlert('', 'Could not add category. Please try again.')

      }
    } else {
      errorAlert('', "Give a valid Category Name. Category Name can be 50 characters long and cannot contain special characters except '&', ',' and '-' ", "warning");
    }
  }

  async function editCategory(categoryId) {
    
    let result = await fetch(`${API_BASE_URL}/productgen/api/v1/category/Update`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${accessToken}`,

      },
      method: "PUT",
      body: JSON.stringify({

        "categoryId": categoryId,
        "categoryName": categoryName?.trim(),
        "categoryWiseGST": categoryGst?.trim(),
        "imageNameWithExtension": imageName

      })
    });
    const data = await result.json();
    //console.log(data)
    if(data?.result===true){
      fetchCategories();
      setCategoryName(null);
      setCategoryGst(null);
      setImageName(null);
      setOpen(false);
      successAlert('','Category Updated successfully');
    }
    else{
      setOpen(false);
      errorAlert('','Category Update Failed. Please try again');
    }

  }

  const [open, setOpen] = useState(false);

  return (
    <AdminLayoutAdjustment>
      <Heading text="Categories" />
      {/* <button onClick={()=>{navigate('/trackOrder')}} >Track</button> */}
      <form
        className="admin-category-addition-field"
        onSubmit={handleAddCategorySubmit}
      >
        <div>
          <TextField
            fullWidth
            value={categoryName}
            placeholder="Category Name"
            onChange={(e) => {
              setCategoryName(e.target.value);
            }}
          />
        </div>
        <div>
          <TextField
            fullWidth
            value={categoryGst}
            placeholder="Category Gst"
            onChange={(e) => {
              setCategoryGst(e.target.value);
            }}
          />
        </div>
        <div>

          <label className="choose-file-btn">
            <input
              type="file"
              // accept=".jpg,.jpeg,.png"
              onChange={async (event) => {
                setImageLoading(true);

                const uploadedImage = event.target.files[0];
                const maxSizeInBytes = 10 * 1024 * 1024;
                if (uploadedImage.size > maxSizeInBytes) {
                  errorAlert('',"File size exceeds 1 MB limit.");
                  setImageLoading(false);

                  return;
                }

                // Check if file type is jpg/jpeg/png
                const validTypes = ["image/jpeg", "image/png", "image/jpg"];
                if (!validTypes.includes(uploadedImage.type)) {
                  errorAlert('',"File type must be jpg, jpeg, or png.");
                  setImageLoading(false);

                  return;
                }
                const fileExtension = uploadedImage.name
                  .split(".")
                  .pop(); // Extract file extension
                const imageName = `${uuidv4()}.${fileExtension}`; // Generate image name with uuid and file extension

                const formData = new FormData();
                formData.append("ContainerName", "ecomdata");
                formData.append(
                  "FileNameWithExtension",
                  imageName
                );
                formData.append("FileCategory", "Category");
                formData.append(
                  "Attachment",
                  uploadedImage,
                  uploadedImage.name
                );

                try {
                  const response = await fetch(
                    `${API_BASE_URL}/blob/api/v1/uploadmediaWithContainer`,
                    {
                      method: "POST",
                      headers: {
                        Authorization: `Bearer ${accessToken}`,
                      },
                      body: formData,
                    }
                  );

                  if (!response.ok) {
                    errorAlert('', 'Something went WrongLocation. Could not upload Image');
                  }

                  // Set image variable as the uploaded image name
                  setImageName(imageName)
                  // You can also perform other actions after successful upload
                  setImageLoading(false);

                } catch (error) {
                  
                  setImageLoading(false);

                }
              }}
              name="image"
            />
            Choose File
          </label>

          {imageLoading && (
            <CircularProgress className="file-submitted" />
          )}
          {!imageLoading && imageName !== '' && <CheckCircle className="file-submitted" />}
        </div>
        <button
          type="button"
          onClick={handleAddCategorySubmit}
          className="common-btn"
        >
          Add
        </button>
      </form>
      <table class="admin-categories-table">
        <tbody>
          <tr>
            <th>Category Name </th>
            <th>Category GST </th>
            <th> Category Image</th>
            <th> Actions</th>
          </tr>


          {filteredData?.map((category) => {
            return (
              <tr>
                <td
                  data-th="Category Name"
                  className="admin-category-list-name"
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                    <div style={{ fontSize: "1.5rem", textAlign: "center", width: "100%" }}>
                      {category.categoryName}
                    </div>
                   
                  </div>
                </td>
                <td
                  data-th="Category Name"
                  className="admin-category-list-name"
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                    <div style={{ fontSize: "1.5rem", textAlign: "center", width: "100%" }}>
                      {category.categoryWiseGst}
                    </div>
                   
                  </div>
                </td>
                <td>

                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img loading="lazy" src={category.blobImageURL} alt={category.categoryId} style={{ maxWidth: "100px" }} />
                  </div>
                </td>
                {/* <td data-th="No of Products">{category.categoryItems}</td> */}
                <td data-th="Actions">
                  <EditOutlined onClick={() => {
                    setSelectedCategory(category.categoryId)
                    setOpen(true)
                  }} />
                  <DeleteOutline onClick={() => { confirmAlert('', 'Are you sure you want to delete this category?', () => { DeleteCategory(category.categoryId) }) }} />
                </td>
              </tr>
            );
          })}

        </tbody>
      </table>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          s
        >
          <Typography id="basic-modal-dialog-title" component="h2">
            Edit Category.
          </Typography>
          <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
            Please fill in valid category name and Image before submitting.
          </Typography>



          <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
            <TextField
              fullWidth
              value={categoryName}
              placeholder="Category Name"
              onChange={(e) => {
                setCategoryName(e.target.value);
              }}
            />
            <TextField
              fullWidth
              value={categoryGst}
              placeholder="Category Gst"
              onChange={(e) => {
                setCategoryGst(e.target.value);
              }}
            />


            <label className="choose-file-btn">
              <input
                type="file"
                // accept=".jpg,.jpeg,.png"
                onChange={async (event) => {
                  setImageLoading(true);

                  const uploadedImage = event.target.files[0];
                  const maxSizeInBytes = 10 * 1024 * 1024;
                  if (uploadedImage.size > maxSizeInBytes) {
                    errorAlert('',"File size exceeds 1 MB limit.");
                    setImageLoading(false);

                    return;
                  }

                  // Check if file type is jpg/jpeg/png
                  const validTypes = ["image/jpeg", "image/png", "image/jpg"];
                  if (!validTypes.includes(uploadedImage.type)) {
                    errorAlert('',"File type must be jpg, jpeg, or png.");
                    setImageLoading(false);

                    return;
                  }
                  const fileExtension = uploadedImage.name
                    .split(".")
                    .pop(); // Extract file extension
                  const imageName = `${uuidv4()}.${fileExtension}`; // Generate image name with uuid and file extension

                  const formData = new FormData();
                  formData.append("ContainerName", "ecomdata");
                  formData.append(
                    "FileNameWithExtension",
                    imageName
                  );
                  formData.append("FileCategory", "Category");
                  formData.append(
                    "Attachment",
                    uploadedImage,
                    uploadedImage.name
                  );

                  try {
                    const response = await fetch(
                      `${API_BASE_URL}/blob/api/v1/uploadmediaWithContainer`,
                      {
                        method: "POST",
                        headers: {
                          Authorization: `Bearer ${accessToken}`,
                        },
                        body: formData,
                      }
                    );

                    if (!response.ok) {
                      errorAlert('', 'Something went WrongLocation. Could not upload Image');
                    }

                    // Set image variable as the uploaded image name
                    setImageName(imageName)
                    // You can also perform other actions after successful upload
                    setImageLoading(false);

                  } catch (error) {
                    ////console.log(error);
                    setImageLoading(false);

                    // Handle error if upload fails
                  }
                }}
                name="image"
              />
              Choose File
            </label>

            {imageLoading && (
              <CircularProgress className="file-submitted" />
            )}
            {!imageLoading && imageName !== '' && <CheckCircle className="file-submitted" />}

          </div>

          <div>

            <button type="button" className="common-button" onClick={() => {
              editCategory(selectedCategory)
            }}>Edit Category</button>
            <button type="button" className="common-button" onClick={() => {
              setOpen(false);
            }}>Cancel </button>
          </div>

        </ModalDialog>
      </Modal>
  {/* <CustomSearchBar data={categories} onFilter={handleFilter}   keysToFilter={['categoryName']}/> */}
  </AdminLayoutAdjustment>
  );
}
export default withAdminAuth(AdminAddCategory);

