import React, { useState, useEffect } from 'react'
import Heading from "../components/Heading";
import "../styles/ManageProfile.css"
import "../styles/SellerAccountStyle.css"
import { useContext } from 'react';
import { errorAlert } from '../components/Alert';
import SellerLayoutAdjustment from '../components/SellerLayoutAdjustment'
import { SellerContext } from '../context/SellerContext';
import { useNavigate, useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import withSellerAuth from '../context/withSellerAuth';
import { API_BASE_URL } from '../App';
import Loading from '../components/Loading';

function SellerAccount() {
  const params = useParams();
  const uniqueId = params.id;
  const navigate = useNavigate();
  // //console.log(uniqueId);
  const { accessToken, sellerData, setSelectedListItemIndex, logout } = useContext(SellerContext);
  const [sellerCertUri, setSellerCertUri] = useState();
  const [loading, setLoading] = useState(false);
  const [
    adminApprovaldetailVerificationData,
    setAdminApprovaldetailVerificationData,
  ] = useState([]);

  // const [messageField, setMessageField] = useState(false);
  // const [message, setMessage] = useState("");

  const [sellerStatus, setSellerStatus] = useState("");

  // const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setSelectedListItemIndex(4);
  }, [])

  const fetchAdminApprovaldetailVerificationData = async () => {
    try {

      setLoading(true);
      const response = await fetch(
        `${API_BASE_URL}/seller/api/v1/GetSellerById&GetAllSeller?SellerId=${sellerData?.result?.userID}`,
        {
          // mode: "no-cors",
          headers: {
            Authorization: `Bearer ${accessToken}`,

            // 'Accept': 'text/plain',
            // 'Content-Type': 'text/plain',
          },
        }
      );
      const data = await response.json();
      if (response?.status === 401) {
        logout();
        errorAlert('', 'Session timed out. Please Log in');
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate('/')
        setLoading(false);
      }
      ////console.log(data);
      setAdminApprovaldetailVerificationData(data?.result);
      setSellerStatus(data?.result?.status);
      getCertificate();
      setLoading(false);

      // getCertificate()
      ////console.log(adminApprovaldetailVerificationData);
    } catch {
      setLoading(false);

    }
  }

  //   useEffect(() => {
  //     fetchAdminApprovaldetailVerificationData();
  // // eslint-disable-next-line
  //   }, []);
  useEffect(() => {
    if (accessToken) {
      fetchAdminApprovaldetailVerificationData();
    }
  }, [accessToken]);

  useEffect(() => {
    if (adminApprovaldetailVerificationData) {
      getCertificate();
    }
  }, [adminApprovaldetailVerificationData]);

  async function getCertificate() {
    try {
      if (
        !adminApprovaldetailVerificationData ||
        !adminApprovaldetailVerificationData?.referenceNumber
      ) {
        ////console.log("Missing reference number");
        return;
      }

      const data = await fetch(
        `${API_BASE_URL}/blob/api/v1/getfile`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-type": "application/json-patch+json",
          },
          body: JSON.stringify({
            fileNameWithExtension: [
              `${adminApprovaldetailVerificationData.referenceNumber}`,
            ],
            fileCategory: "Msme",
          }),
        }
      );

      const response = await data.json();
      ////console.log(response);
      setSellerCertUri(response?.result[0]?.uri);
    } catch (e) {
      ////console.log(e);
    }
  }


  return (
    <SellerLayoutAdjustment>
      <div className='page-content'>

      <Heading text="MY ACCOUNT" />
      {loading && adminApprovaldetailVerificationData && <div className="loading-container" style={{ minHeight: "300px", minWidth: "80%" }}>
        <Loading />

      </div>}

      {!loading && <div className="admin-approval-details-list">
        <div className="admin-approval-details-category-card">
          <div className="admin-approval-details-category-card-name">
            Seller Personal Details
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Seller Name
            </div>
            <TextField
              className="admin-approval-details-textfield"
              disabled
              value={adminApprovaldetailVerificationData?.sellerName}
            />
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Seller Email
            </div>
            <TextField
              className="admin-approval-details-textfield"
              disabled
              value={adminApprovaldetailVerificationData?.emailId}
            />
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Seller Phone Number
            </div>
            <TextField
              className="admin-approval-details-textfield"
              disabled
              value={adminApprovaldetailVerificationData?.sellerMobileNumber}
            />
          </div>
        </div>

        <div className="admin-approval-details-category-card">
          <div className="admin-approval-details-category-card-name">
            Aadhar Details
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Aadhar Number
            </div>
            <TextField
              className="admin-approval-details-textfield"
              disabled
              value={adminApprovaldetailVerificationData?.aadharNumber}
            />
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Aadhar Holder Name
            </div>
            <TextField
              className="admin-approval-details-textfield"
              disabled
              value={(adminApprovaldetailVerificationData?.aadhar_HolderName && JSON.parse(adminApprovaldetailVerificationData?.aadhar_HolderName)?.data?.full_name) || ''}

            />
          </div>
        </div>

        <div className="admin-approval-details-category-card">
          <div className="admin-approval-details-category-card-name">
            Pan and GST Details
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">PAN</div>
            <TextField
              className="admin-approval-details-textfield"
              disabled
              value={adminApprovaldetailVerificationData?.panNumber}
            />
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">PAN Holder Name</div>
            <TextField
              className="admin-approval-details-textfield"
              disabled

              value={(adminApprovaldetailVerificationData?.pan_HolderName && JSON.parse(adminApprovaldetailVerificationData?.pan_HolderName)?.data?.full_name) || ''}
            />
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">GST</div>
            <TextField
              className="admin-approval-details-textfield"
              disabled
              value={adminApprovaldetailVerificationData?.gstNumber}
            />
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">GST Holder Name</div>
            <TextField
              className="admin-approval-details-textfield"
              disabled
              value={(adminApprovaldetailVerificationData?.gsT_HolderName && JSON.parse(adminApprovaldetailVerificationData?.gsT_HolderName)?.data?.legal_name) || ''}

            />
          </div>
        </div>

        <div className="admin-approval-details-category-card">
          <div className="admin-approval-details-category-card-name">
            Bank Account Details
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Bank Name
            </div>
            <TextField
              className="admin-approval-details-textfield"
              disabled
              value={adminApprovaldetailVerificationData?.branchCode}
            />
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Bank Account Number
            </div>
            <TextField
              className="admin-approval-details-textfield"
              disabled
              value={adminApprovaldetailVerificationData?.accountNumber}
            />
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Account Holder Name
            </div>
            <TextField
              className="admin-approval-details-textfield"
              disabled
              value={adminApprovaldetailVerificationData?.accountHolderName}
            />
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Bank IFSC
            </div>
            <TextField
              className="admin-approval-details-textfield"
              disabled
              value={adminApprovaldetailVerificationData?.bankIFSC}
            />
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Account Type
            </div>
            <TextField
              className="admin-approval-details-textfield"
              disabled
              value={adminApprovaldetailVerificationData?.typeOfAccount}
            />
          </div>
        </div>

        <div className="admin-approval-details-category-card">
          <div className="admin-approval-details-category-card-name">
            MSME Details
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              MSME Image
            </div>

            <img
              src={sellerCertUri}
              className="admin-approval-cert-image"
              alt="msme"
              onClick={() => {
                window.open(sellerCertUri);
              }}
            />
            {/* <TextField
                    className="admin-approval-details-textfield"
                    disabled
                    value={adminApprovaldetailVerificationData?.referenceNumber}
                  /> */}
          </div>
        </div>
        <div className="admin-approval-details-category-card">
          <div className="admin-approval-details-category-card-name">
            Subscription Plan
          </div>
          <div className="admin-approval-details-card-field">
            <div className="admin-approval-details-card-field-name">
              Plan Name
            </div>

            <TextField
              className="admin-approval-details-textfield"
              disabled
              value={adminApprovaldetailVerificationData?.sellerPlanName}
            />
          </div>
        </div>
      </div>}
      </div>

    </SellerLayoutAdjustment>

  )
}

export default withSellerAuth(SellerAccount);