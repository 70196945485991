import { API_BASE_URL } from '../App'
import { errorAlert, successAlert } from "../components/Alert";

const addToCart = async (props, buyerData, accessToken) => {
    //console.log('From Cart Function ' , props)
    try {
      // Make your API call here to add the item to the cart
      const response = await fetch(`${API_BASE_URL}/cart/api/v1/new`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
           Authorization: `Bearer ${accessToken}`,
},
        body: JSON.stringify({
            
                "BuyerID": buyerData?.result?.userID,
                "Products": [
                  {
                    "ItemId": 0,
                    "ImageFilename": props.productSKUs[0].imagesUriList[0].thumbnailURI,
                    "Title": props.title,
                    "Description": props.desc,
                    "SKU": props.productSKUs[0].sku,
                    "Qty": 1,
                    "UnitCost": 200,
                    "CategoryId": "12kninibib"
                  }
                ]
              }

        )
      });
      
      if (response.ok) {
        const responseData = await response.json();
        successAlert('', 'Added to Cart')
        //console.log(responseData);
        return responseData; // You can return the response data if needed
      } else {
          errorAlert('', 'Could not add to Cart')
        throw new Error('Failed to add item to cart');

      }
    } catch (error) {
        errorAlert('', 'Could not add to Cart')

      console.error('Error adding item to cart:', error);
      throw error;
    }
  };
  
  export  {addToCart}