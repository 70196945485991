import React, { useEffect, useState } from 'react'
import AdminLayoutAdjustment from '../components/AdminLayoutAdjustment'
import "../styles/AdminAddCategory.css";
import Heading from "../components/Heading";
import { API_BASE_URL } from '../App';

function AdminContactUs() {
    const[contactUsData ,setContactUsData]=useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const pageSize = 10;
    async function FetchContactUs() {
        try {


            const response = await fetch(`${API_BASE_URL}/faq/api/v1/contactUs/getAll?PageNumber=${pageNumber}&PageSize=${pageSize}&IncludeCount=true`, {
                method: "GET",
                headers: {

                }
            })
            const data = await response.json();
            setContactUsData(data);
            console.log(data)
        }
        catch {
        }
    }
    useEffect(() => {
        FetchContactUs();
    }, [])

  return (
    <AdminLayoutAdjustment>
          <Heading text="Contact Us Queries" />
       

        
           <table class="admin-categories-table">
        <tbody>
          <tr>
            <th>Name </th>
            <th>Email </th>
            <th>Query
                
            </th>
          
          </tr>
          {contactUsData?.result?.map(contact =>{
            return(
                <tr>
                <td
                  data-th="Category Name"
                  className="admin-category-list-name"
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                    <div style={{ fontSize: "18px", textAlign: "center", width: "100%" }}>
                    {contact.name}
                    </div>
                   
                  </div>
                </td>
                <td
                  data-th="Category Name"
                  className="admin-category-list-name"
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                    <div style={{ fontSize: "18px", textAlign: "center", width: "100%" }}>
                    {contact?.email}
                    </div>
                   
                  </div>
                </td>
                <td>

                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div style={{ fontSize: "18px", textAlign: "center", width: "100%" }}>
                    {contact?.query}
                    </div>
                  </div>
                </td>
              
               
              </tr>
            )
          })}

        </tbody>
      </table>
    

    </AdminLayoutAdjustment>
  )
}

export default AdminContactUs