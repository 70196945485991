import { Delete, EditOutlined } from "@mui/icons-material";
import { Avatar, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import AdminLayoutAdjustment from "../components/AdminLayoutAdjustment";
import Heading from "../components/Heading";
import { errorAlert } from "../components/Alert";
import {  successAlert } from "../components/Alert";
import "../styles/AdminManageUsers.css";
import { Modal } from "@mui/material";
import { AdminContext } from "../context/AdminContext";
import ModalDialog from '@mui/joy/ModalDialog';
import { API_BASE_URL } from "../App";

import Typography from '@mui/joy/Typography';
// import ClearIcon from "@mui/icons-material/Clear";
import { useContext } from "react";
import withAdminAuth from "../context/withAdminAuth";
function AdminManageUsers() {
  const { accessToken } = useContext(AdminContext);
  const [typeOfUser, setTypeOfUser] = useState("All");
const[selectedUser,setSelectedUser]=useState();
 const [firstName, setFirstName] = useState(''); 
 const [email, setEmail] = useState(''); 
 const [mobileNumber, setMobileNumber] = useState(''); 
 const [lastName, setLastName] = useState(''); 
 const[dateOfBirth,setDateOfBirth]= useState('');
  const [open, setOpen] = useState(false);
    // const [userName, setUserName] = useState("");
  const handleTypeOfUserChange = (event) => {
    setTypeOfUser(event.target.value);
  };

  const [statusFilter, setStatusFilter] = useState(false);
  useEffect(() => {
    typeOfUser !== "All " && setStatusFilter(true);
    typeOfUser === "All" && setStatusFilter(false);
  }, [typeOfUser]);

  //console.log(accessToken);
  const [adminUsersData, setAdminUsersData] = useState([]);
  ////console.log(accessToken);
  const fetchAdminUsersData = async () => {
    const response = await fetch(`${API_BASE_URL}/users/api/v1/list`, {
      // mode: "no-cors",
      method:"POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json-patch+json",
      
      },
      body:JSON.stringify({
        // "firstName": value.name,
        // "email":  adminUsersData.email,
        // "mobileNumbe":adminUsersData.mobileNumbe,
      })
     
     
    });
    
    const data = await response.json();
    setAdminUsersData(data);
    ////console.log(data);
    const users = data.users;
  //console.log("Users:", users);

  };

  useEffect(() => {
    fetchAdminUsersData();
  }, []);

  async function DeleteUser(userID) {
    const shouldDelete = window.confirm("Are you sure you want to delete this user?");
    if (!shouldDelete) {
      return;
    }
    
    setAdminUsersData((prevData) => ({
      ...prevData,
      result: prevData.result.filter((user) => user.userID !== userID)
    }));
    
    try {
      await fetch(`${API_BASE_URL}/users/api/v1/${userID}`, {
        method: "delete",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    
      //console.log(adminUsersData); 
      successAlert('', 'User Deleted successfully');
    } catch (error) {
      console.error("Failed to delete user:", error);
    }
  }
  

  const filteredUsers = adminUsersData?.result?.filter((user) => {
    if (typeOfUser === "All") {
      return true;
    } else {
    
      if (typeOfUser === "Admin" && user.typeOfUser === "SUADM") {
        return true;
      } else if (typeOfUser === "Seller" && user.typeOfUser === "SLADM") {
        return true;
      } else if (typeOfUser === "Buyer" && user.typeOfUser === "BYR") {
        return true;
      }
      return false; 
    }
  });

  
  
  // const [searchQuery, setSearchQuery] = useState("");
// const handleSearchQueryChange = (event) => {
//   setSearchQuery(event.target.value);
// };

// const filteredStateArray = adminUsersData?.filter((item) =>
//   item?.name.toLowerCase().includes(searchQuery.toLowerCase())
// );
// const filteredStateArray=adminUsersData
////console.log(filteredStateArray);
// const [openModal, setOpenModal] = useState(false);
// const [editedUser, setEditedUser] = useState({});

// const handleRowClick = () => {
//   setOpenModal(true);
//   setEditedUser({
   
//   });
// };
// const handleModalClose = () => {
//   setOpenModal(false);
// };
async function editUser(userID) {
  try {
    let result = await fetch(`${API_BASE_URL}/users/api/v1/update`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
      },
      method: "PUT",
      body: JSON.stringify({
        "userID": userID,
        "firstName": firstName?.trim(),
        "email": email?.trim(),
        "mobileNumber": mobileNumber?.trim(),
        "lastName": lastName?.trim(),
        "dateOfBirth": dateOfBirth,
      })
    });

    if (!result.ok) {
      throw new Error("Failed to update user");
    }

    // Update state variables with new data
    setFirstName(firstName?.trim());
    setEmail(email?.trim());
    setMobileNumber(mobileNumber?.trim());
    setLastName(lastName?.trim());
    setDateOfBirth(dateOfBirth);

    // Optionally, you can fetch updated data again from the backend
    // to ensure that the state variables are in sync with the server.

    fetchAdminUsersData(); // Uncomment this if needed
    setOpen(false);
    successAlert('', 'User Updated successfully');
  } catch (error) {
    console.error("Error occurred:", error);
    setOpen(false);
    errorAlert('', 'User Update Failed. Please try again');
  }
}

  return (
    <AdminLayoutAdjustment>
      <Heading text="Manage Users" />
      <div className="manage-user-filter">
        <div className="manage-users-dropdown">
          <Select
            style={{ width: "100%" }}
            value={typeOfUser}
            onChange={handleTypeOfUserChange}
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"Buyer"}>Buyer</MenuItem>
            <MenuItem value={"Seller"}>Seller</MenuItem>
            <MenuItem value={"Admin"}>Admin</MenuItem>
          </Select>
        </div>
        {/* <div className="manage-users-filter-searchbar">
          <TextField fullWidth className="manage-users-textfield" value={searchQuery} onChange={handleSearchQueryChange}/>
          <Search className="manage-user-search-icon" />
        </div> */}
      </div>
 <div  className="modal-trigger">
     <table className="admin-manage-users-table">
  <tbody>
    <tr>
      <th>Name</th>
      <th>Email</th>
      <th>User Type</th>
      <th>Actions</th>
    </tr>

    {filteredUsers?.map((user) => (
      <tr key={user.userID}>
        <td data-th="Name">
          <div className="manage-users-name-section">
            <Avatar />
            {user.firstName}
          </div>
        </td>
        <td data-th="Email">{user.email}</td>
        <td data-th="Type of User">{user.typeOfUser}</td>
        <td data-th="Actions">
           <EditOutlined onClick={() => {
                    setSelectedUser(user.userID)
                    setFirstName(user.firstName)
                    setOpen(true)
                  }} />
          <Delete onClick={() => DeleteUser(user.userID)} />
        </td>
      </tr>
    ))}
  </tbody>
</table>
  <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          s
        >
          <Typography id="basic-modal-dialog-title" component="h2">
            Edit User.
          </Typography>
          <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
            Please fill in valid user details before submitting.
          </Typography>
            <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
            <TextField
              fullWidth
              value={firstName}
              placeholder="First Name"
              onChange={(e) => {
                 setFirstName(e.target.value);
              }}
            />
             <TextField
              fullWidth
              value={email}
              placeholder="Email"
              onChange={(e) => {
                 setEmail(e.target.value);
              }}
            />
             <TextField
              fullWidth
              value={mobileNumber}
              placeholder="mobileNumber"
              onChange={(e) => {
                 setMobileNumber(e.target.value);
              }}
            />
            <button type="button" className="common-button" onClick={() => {
              editUser(selectedUser)
            }}>Edit User</button>
            <button type="button" className="common-button" onClick={() => {
              setOpen(false);
            }}>Cancel </button>
            </div>
          </ModalDialog>
          </Modal>
</div>
 
    </AdminLayoutAdjustment>
  );
}
export default withAdminAuth(AdminManageUsers);

