import React, { useState, useEffect } from "react";
// import Breadcrumbs from "@mui/material/Breadcrumbs";
// import Link from "@mui/material/Link";
// import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import * as yup from "yup";
// import { useFormik } from "formik";
import { Skeleton } from "@mui/material";
import "../styles/Address.css";
// import Modal from "@mui/material/Modal";
// import ClearIcon from "@mui/icons-material/Clear";
import AddressCard from "../components/AddressCard";
// import { BuyerContext } from "../context/BuyerContext";
import { API_BASE_URL } from "../App";

import { useContext } from "react";
// import BcHeading from "../buyerComponents/BcHeading";
// import Breadcrumb from "../buyerComponents/Breadcrumb";
import { CustomerContext } from "../context/CustomerContext";
// import { successAlert } from "../components/Alert";
// import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@mui/material';
import CustomerAddressModal from "../components/CustomerAddressModal";
import "../styles/AddressCard.css";
import EmptyPage from "./EmptyPage";

// import AddressData from "../AddressData";
// const phoneRegExp = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;
// const fullNameRegExp = /^[A-Z][a-zA-Z]{3,}(?: [A-Z][a-zA-Z]*){0,2}$/;

export default function Address() {
  const [addressData, setAddressData] = useState([]);
  const { customerData, accessToken, handleAuthExpiry , logout } = useContext(CustomerContext);
  // const [updated, setUpdated] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);


  const [openModal, setOpenModal] = useState(false);
  const handleModalOpen = () => {
    setOpenModal(true);
  };
  const handleModalClose = () => { setOpenModal(false); }


  const fetchAddressData = async () => {
    setAddressLoading(true);

    try {
      const response = await fetch(
        
        `${API_BASE_URL}/address/api/v1/getall?userId=${customerData?.result?.userID}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
        // setAddressLoading(false);
        const data = await response.json()
        const handleAuthError = handleAuthExpiry(response);
        if (handleAuthError === true) {
          logout();
          return;
        
      }
      setAddressLoading(false);
        // const data = await response.json();
        setAddressData(data?.result);

        console.log("Address Data:", data?.result);
      if(!data?.result) {
        console.error("Failed to fetch address data");
      }
    } catch (error) {
      console.error("Error fetching address data:", error);
    }
  };

  useEffect(() => {
    fetchAddressData();
  }, []);

  return (
    <div className="address-container">
      <CustomerAddressModal
        open={openModal}
        onClose={handleModalClose}
        edit={false} // Pass the mode to the modal component
        fetchAddressData={fetchAddressData}
      />
      <div className="add-address-head-container">
        <div className="add-address-head">
          <div className="profile-page-head ">Saved Address</div>
          <button onClick={handleModalOpen} className="add-address-buyer">
            {" "}
             ADD NEW ADDRESS
          </button>
        </div>
        <div className="addressCards">
          {!addressLoading && !addressData[0] && <EmptyPage desc='Updates on the way' />}
{addressLoading && !addressData[0]&&<AddressShimmer />}
          {addressData?.map((address) => {
            //console.log(address)
            return <AddressCard {...address} fetchAddressData={fetchAddressData} />;
          })}
          {/* <AddressShimmer /> */}
        </div>
      </div>
    </div>
  );
}
const AddressShimmer = () => {
  return (
    <div className="addressCards">

      <AddressShimmerCard />
      <AddressShimmerCard />
      <AddressShimmerCard />
    </div>
  )
}
const AddressShimmerCard = () => {
  return (
    <div className='address-card-content-fields' style={{border:'1px solid rgba(217,217,217,1)', borderRadius:'8px', padding:'1rem'}}>
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={110} height={20}/>

      {/* <Skeleton variant="circular" width={40} height={40} /> */}
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width='20%' height={80} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width='80%' height={30} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width='30%' height={40} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width='30%' height={40} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width='30%' height={40} />
      
    </div>
  )
}
