import React from "react";
import "../styles/Footer.css";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import logo from "../Images/logo.png";
import mandala from "../Images/Mandala.png";
import FacebookIcon from "@mui/icons-material/Facebook";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

export default function Footer() {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      // navigate('/home');
      ////console.log("AAAAAAAAAAA");
    },
  });

  return (
    <div className="footer">
      <div className="footer-top">
        <div className="footer-top-left">
          <div className="footer-social-follow">FOLLOW</div>
          <div className="footer-social-icons">
            <FacebookIcon />
            <LockOpenIcon />
            <LockOpenIcon />
          </div>
          <div className="footer-services">SERVICES</div>
          <div className="footer-links">
            <div className="footer-links-col-1">
              <div className="footer-link-text">Return Policy</div>
              <div className="footer-link-text">Return Policy</div>
              <div className="footer-link-text">Return Policy</div>
              <div className="footer-link-text">Return Policy</div>
              <div className="footer-link-text">Return Policy</div>
            </div>
            <div className="footer-links-col-1">
              <div className="footer-link-text">Return Policy</div>
              <div className="footer-link-text">Return Policy</div>
              <div className="footer-link-text">Return Policy</div>
              <div className="footer-link-text">Return Policy</div>
              <div className="footer-link-text">Return Policy</div>
            </div>
            <div className="footer-links-col-1">
              <div className="footer-link-text">Return Policy</div>
              <div className="footer-link-text">Return Policy</div>
              <div className="footer-link-text">Return Policy</div>
              <div className="footer-link-text">Return Policy</div>
              <div className="footer-link-text">Return Policy</div>
            </div>
          </div>
        </div>
        <div className="footer-top-right">
          <div className="footer-options">
            <div className="footer-option">New Arrivals</div>
            <div className="footer-option">Discounts</div>
            <div className="footer-option">Contact Us</div>
          </div>
          <div className="footer-subscribe-text">
            Subscribe to get the latest news on sales, new releases and more...
          </div>
          <form className="footer-subscribe-form">
            <TextField
              variant="outlined"
              fullWidth
              type="text"
              id="footer-email"
              name="email"
              //   className="login-page-email"
              className="footer-email"
              placeholder="Enter Email Address"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            {/* <LockOpenIcon className="footer-email-icon " fontSize="medium " /> */}

            <input
              type="submit"
              className="footer-subscribe-button"
              value="Subscribe"
              onSubmit={formik.handleSubmit}
            />
          </form>
          <div className="footer-payment-text">Supported Payment Systems</div>
          <div className="footer-payment-images">
            <img loading="lazy" className="footer-payment-cash" src={logo} alt="aaaa" />
            {/* <img loading="lazy" alt=" " className="footer-payment-visa" src={logo} /> */}
            <img loading="lazy" alt=" " className="footer-payment-gateway" src={logo} />
            {/* <img loading="lazy" alt=" " className="footer-payment-paypal" src={logo} /> */}
            <img loading="lazy" alt=" " className="footer-payment-gpay" src={logo} />
            <img loading="lazy" alt=" " className="footer-payment-discover" src={logo} />
          </div>
        </div>
        <img loading="lazy" className="footer-mandala" src={mandala} alt="mandala" />
        {/* <img loading="lazy" alt=" "  className="footer-logo" src={logo} alt="aaaa" /> */}
      </div>
      <div className="footer-bottom">
        <div className="footer-logo-name">Myhraki</div>
        <div className="footer-conditions">
          <div className="footer-privacy">Privacy policy</div>
          <div className="footer-privacy">
            2022, MyhraKi.com, All Rights Reserved
          </div>
          <div className="footer-privacy">Terms and Conditions</div>
        </div>
      </div>
    </div>
  );
}
