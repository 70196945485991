import React, { useState } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import "../styles/BcBuyerLayout.css"
import { useNavigate } from 'react-router-dom';

const BottomNavbar = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      style={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        backgroundColor: '#f8f8f8',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
      }}
    >
      <BottomNavigationAction label="Home" icon={<HomeIcon />} onClick = {()=>{navigate('/')}}/>
      <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
      <BottomNavigationAction label="Cart" icon={<ShoppingCartIcon />} onClick = {()=>{navigate('/cart2')}}/>
      <BottomNavigationAction label="Profile" icon={<AccountCircleIcon />} onClick = {()=>{navigate('/account')}}/>
    </BottomNavigation>
  );
};

export default BottomNavbar;