import React from "react";
import "../styles/ResetPassword.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import logo from '../Images/logo.png';
import "../styles/Common.css";


const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

export default function ResetPassword() {
  const navigate = useNavigate();
  // const [token, setToken]=useState(true);

  const gotoLoginPage=()=>{
    navigate("/login");
  }
  const gotoSignupPage=()=>{
    navigate("/signup");
  }
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
       postData();
      navigate("/");
    },
  });
  async function postData() {
    try {
      let result = await fetch(
        "https://webhook.site/b2622e3f-5a2f-4b98-8759-7b721b73e9bc",
        {
          method: "post",
          mode: "no-cors",
          headers: {
            Accept: "application.json",
            "Content-type": "application.json",
          },
          body: JSON.stringify({
            typeOfUser:"B",
            email: formik.values.email,
          }),
        }
      );
      ////console.log(result);
    } catch (e) {
      // setToken(false)
      ////console.log(e);
    }
  }
  return (
    <div className="reset-screen-container">
      <div className="reset-top-left-rect"></div>
      <div className="reset-top-right-rect"></div>
      <div className="reset-circle"></div>
      <div className="reset-btm-left"></div>
      <div className="reset-btm-left-bottom"></div>
      <div className="reset-btm-left-top"></div>
      

      <div className="reset-card">
      <div className="reset-logo">
        <img loading="lazy" alt=" "  src={logo}  className="reset-logo" />
      </div>
        <div className="reset-head">Reset Password</div>
        
        <div className="reset-auth-text">
          Enter the email associated with your account and we’ll send an email
          with instructions to Reset your Password.
        </div>
        <form className="reset-page-form" onSubmit={formik.handleSubmit}>
          <div className="reset-page-email-field">
            <TextField
              variant="outlined"
              fullWidth
              autoFocus
              type="text"
              id="email"
              name="email"
              //   className="login-page-email"
              className="reset-email"
              placeholder="Enter Email Address"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <MailOutlineIcon className="reset-email-icon" fontSize="medium " />
          </div>
          <input type="submit" className="submit-button sign-in-button" value="Send Instructions" />
        </form>
        <div className="reset-login">
          <span onClick={gotoLoginPage}>Return to Login</span>
        </div>
        <div className="reset-signUp">
          Don't have an account? <span onClick={gotoSignupPage}>Sign Up</span>
        </div>
        <div className="reset-page-terms">
            By continuing, you agree to MyhraKi's
            <span>Terms and conditions</span> and <span>privacy policy</span>
          </div>
      </div>
    </div>
  );
}
