import React from 'react'
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { useState } from 'react';
import { TextField } from '@mui/material';

export default function BcModalTestPage() {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <button onClick={handleOpen}>Open Login Modal</button>
            <BasicModal open={open} onClose={handleClose} />
        </div>
    );
}


function BasicModal({ open, onClose }) {
    
    return (
        <React.Fragment>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={onClose}
                sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        width: 300,
                        borderRadius: 'md',
                        p: 2,
                        boxShadow: 'lg',
                        display:'flex',
                        flexDirection:'column',

                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.surface',
                        }}
                    />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="#999999"
                        fontWeight="lg"
                        fontSize={32}
                        textAlign={'center'}
                        mb={4}
                    >
                        Login
                    </Typography>
                    <TextField placeholder='Email ID/ Mobile' fullWidth size="small" error helperText="Incorrect entry." sx={{marginBottom:'1rem'}}/>
                    <TextField placeholder='Password' fullWidth size="small" error helperText="Incorrect entry." />
                    <button className='action-button' style={{alignSelf:'center'}}>Sign In</button>

                </Sheet>
            </Modal>
        </React.Fragment>
    );
}
