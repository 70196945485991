import React from "react";
import "../styles/Footer.css";
import mandala from "../mandala.png";


export default function SellerFooter() {
  return ( 
    <div
      className="footer"
      style={{ backgroundColor: "rgba(255, 97, 85, 0.1)", width:"100%" }}
    >
        <div style={{ backgroundColor: "white", minHeight:"10rem",borderBottom:"0.3 px solid black" }}></div>
      <div
        className="footer-bottom"
        style={{ position: "relative", zIndex: "100", }}
      >
        <img loading="lazy" 
          src={mandala}
          alt="mandala"
          style={{
            maxWidth: "10rem",
            maxHeight: "10rem",
            position: "absolute",
            top:"-15rem",
            left: "45%",
            zIndex: "100",
            // marginTop:"200px"
          }}
        />
        <div className="footer-logo-name">Myhraki</div>
        <div className="footer-conditions">
          <div className="footer-privacy">Privacy policy</div>
          <div className="footer-privacy">
            2022, MyhraKi.com, All Rights Reserved
          </div>
          <div className="footer-privacy">Terms and Conditions</div>
        </div>
      </div>
    </div>
  );
}
