import React, { useContext, useEffect } from 'react'
import { CircularProgress } from '@mui/material'
import { useState } from 'react';
import { errorAlert } from '../components/Alert';
import { API_BASE_URL } from '../App'
import { SellerContext } from '../context/SellerContext';
import SellerLayoutAdjustment from '../components/SellerLayoutAdjustment';
import PaymentSuccess from '../components/PaymentSuccess';
import PaymentFailure from '../components/PaymentFailure';
import { useNavigate } from 'react-router-dom';



const SellerPaymentConfirmationPage = () => {
    const [waiting, setWaiting] = useState(true);
    // const [transactionId, setTransactionId] = useState();
    const { accessToken,handleAuthExpiry,fetchData, logout } = useContext(SellerContext);
    const [user, setUser] = useState();
    // const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [userData, setUserData ] = useState();
    const [sellerPersonalData, setSellerPersonalData] = useState();
    const navigate = useNavigate();

    const fetchSellerData = async (accessToken) => {
        try {
            const response = await fetch(
                `${API_BASE_URL}/users/api/v1/info`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            const data = await response.json();
            const handleAuthError = handleAuthExpiry(response);
            if (handleAuthError === true) {
              errorAlert('', 'Your Session has expired');
              logout();
              return;
            }
            if (data?.result?.userID) {
                setUser(true);
                setUserData(data)
                
            }
            else {
                // setNotSure(true);
                setUser(false);
            }

            return data;
        } catch (error) {


        }
    };

    useEffect(() => {
        setTimeout(() => {

            fetchSellerData(localStorage?.getItem('token'));
        }, 2000
        )
    }, []);
    useEffect(() => {
        if (user === true) {

            confirm()
        }
    }, [user]);


const getSellerPersonalData= async()=>{


    const sellerStatus = await fetch(
        `${API_BASE_URL}/seller/api/v1/GetSellerById&GetAllSeller?SellerId=${userData?.result?.userID}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const handleAuthError = handleAuthExpiry(sellerStatus);
      if(handleAuthError===true){
        errorAlert('', 'Your Session has expired');
        logout();
        return;
      }
      ////console.log(sellerStatus);
      const status = await sellerStatus.json();
      setSellerPersonalData(status?.result)
      return(status?.result);
      

    }
    const confirm = async () => {
        setTimeout(async () => {
            const result = await checkPaymentStatusByUserId();
            if (result===true) {
                
                const check = await fetchSellerData(accessToken);
                if (check?.result?.userID) {
                    //console.log(check)
                    const  persData= await getSellerPersonalData()
                    //console.log(persData);
                    if(persData?.sellerPlanName){
                        // successAlert('','Payment Success')
                        setWaiting(false);
                        setTimeout(()=>{
                            navigate('/sellerPersonalData')
                            fetchData(accessToken)
                        }, 3000)
                    }
                }
               
            } else {
                setWaiting('notify');
                return;
            }

        }
            , 7000)
    }

    const checkPaymentStatusByUserId = async () => {
        try {
            const data = await fetch(`${API_BASE_URL}/payments/api/v1/GetAllPaymentDetailsByUserId?UserId=${userData?.result?.userID}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage?.getItem('token')}`
                    }
                });
            const response = await data.json();
            const handleAuthError = handleAuthExpiry(data);
            if(handleAuthError===true){
              errorAlert('', 'Your Session has expired');
              logout();
              return;
            }
            const diff= Date.now() - new Date(`${response?.result[response?.result?.length-1]?.paymentCreatedDate}`).getTime()
            //console.log('diff', diff)
            if (diff<=420000) {
                return true
            }
            return false;
        } catch (e) {
            //console.log(e)
        }
    }

  



    return (
        <SellerLayoutAdjustment>
            {
                waiting ===true && <div style={{ width: '400px', padding: '2rem', margin: '0 auto' }}>
                    <div style={{ display: "flex", height: '100px', alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "#FD2A80", height: '100px', width: "100px" }} /></div>:
                    <div style={{
                        color: '#000',
                        textAlign: 'center',
                        fontFamily: 'Poppins',
                        fontSize: '28px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal'
                    }}>Please Wait
                    </div>
                    <div style={{
                        color: 'rgba(0, 0, 0, 0.50)',
                        textAlign: 'center',
                        fontFamily: 'Poppins',
                        fontSize: '19px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal'
                    }}>
                        Your Payment is in progress                  </div>
                </div>
            }
            {
                !waiting &&<div style={{marginTop:'60px'}}>

                <PaymentSuccess type='seller'/>
                </div>
            }
            {
                waiting==='notify' &&<PaymentFailure type="seller" />
            }


        </SellerLayoutAdjustment >
    )
}

export default SellerPaymentConfirmationPage