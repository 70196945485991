import React from 'react'
import '../styles/Common.css'


const MyhrakiTextfield = (props) => {
  
    return (
        <div className='myhraki-textfield' >

       
        <input type='text'
            value={props.value}
            onChange={props.onChange}
            name={props.name}
            placeHolder={props.placeHolder}
            // error={props.error}
            // error={true}
            // helperText={props.helperText}
            helperText='this is wrong'
            className={`custom-input ${props.className}`}
            InputProps={props.InputProps}
        />
        {props.error && <div className='custom-input-error'>{props.helperText}</div>}
         </div>
    )
}

export default MyhrakiTextfield
