import React, { useState, useEffect, useContext } from 'react';
import Breadcrumb from './Breadcrumb';
import { API_BASE_URL } from '../App'

import BcAccountPagesContainer from './BcAccountPagesContainer';
import BcBuyerLayout from './BcBuyerLayout';
import { errorAlert, successAlert } from '../components/Alert';
import BcWishlistCard from './BcWishlistCard';
import { CustomerContext } from '../context/CustomerContext';



const WishlistPage = () => {
  const {  accessToken } = useContext(CustomerContext);
  const [wishlistData, setWishlistData] = useState([]);
  // const[wishlist,setWishlist]=useState("");

  const fetchWishlistData = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/favorites/api/v1/list?userId=445F00BA-3745-11EE-A495-6045BD72BD8E`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      //console.log('Fetched wishlist data:', data);
      if (data?.result) {
        setWishlistData(data.result);
      } else {
        errorAlert('', 'Error Fetching Wishlist data');
      }
    } catch (error) {
      console.error('Error fetching wishlist data:', error);
      errorAlert('', 'Error Fetching Wishlist data');
    }
  };

  useEffect(() => {
    fetchWishlistData();
  }, []);

  const handleDelete = async (ID) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/favorites/api/v1/${ID}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        setWishlistData((prevWishlist) =>
          prevWishlist?.filter((item) => item.ID !== ID)
        );

        successAlert('', 'Item deleted successfully');
        //console.log('Updated wishlistData:', wishlistData);
      } else {
        console.error('Error deleting item from wishlist');
      }
    } catch (error) {
      console.error('Error deleting item from wishlist:', error);
    }
  };
 
 

  const addToWishlist = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/favorites/api/v1/add`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            "ID":"",
    "userId": '445F00BA-3745-11EE-A495-6045BD72BD8E',
    "_sku": 'props._sku',
    "title": 'props.title',
    "description": 'props.description',
    "unitCost": 'props.unitCost',
    "imageFilename": 'props.imageFilename',
          }),
        }
      );

      if (response.ok) {
        successAlert('', 'Item Added successfully');
        fetchWishlistData(); 
      } else {
        const errorData = await response.json();
        console.error('Error adding item to wishlist:', errorData);
      }
    } catch (error) {
      console.error('Error adding item to wishlist:', error);
    }
  };

  const handleCheckboxChange = (itemProps) => {
    if (itemProps.isChecked) {
    
      addToWishlist();
    } else {
      errorAlert('', 'Error Fetching Wishlist data');
    }
  };


  return (
    <BcBuyerLayout>
      <BcAccountPagesContainer>
        <Breadcrumb tab="Wishlist" />
        {wishlistData &&
          wishlistData.map((cardProps) => (
            <BcWishlistCard
              wishlistCard
              {...cardProps}
              handleDelete={() => handleDelete(cardProps.ID)}
              handleCheckboxChange={(isChecked) =>
                handleCheckboxChange(cardProps.ID, isChecked, cardProps)
              }
            />
            
          ))}
      </BcAccountPagesContainer>
    </BcBuyerLayout>
  );
};

export default WishlistPage;
