import React from "react";
import AdminLayout2 from "../components/AdminLayout2";
import "../styles/AdminAddNewGiftCard.css";
import addCard from "../Images/addCard.png";
import BirthdayBox from "../Images/BirthdayBox.png";
import { useState } from "react";
import { API_BASE_URL } from "../App";
import { useFormik } from "formik";
import { useContext , useEffect } from "react";
import { AdminContext } from "../context/AdminContext";
import * as yup from "yup";
import { useLocation, useParams } from "react-router-dom";
import { IdleTimerConsumer } from "react-idle-timer";
import { Category } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";


function AdminAddNewGiftCard(props) {
  const location = useLocation();
  const { title, id } = location.state;
  // const params = useParams();
  // const id1=params.id;
  // console.log(id1);
  // const title1=useParams().title;
  const {accessToken}= useContext(AdminContext)
  const [addedgiftcard, setAddedgiftcard]=useState([])
  const handleFileChange = async (e) => {
      const uploadedImage = e.target.files[0];
      const maxSizeInBytes = 5 * 1024 * 1024; 
      if (uploadedImage.size > maxSizeInBytes) {
        console.error("File size exceeds 5 MB limit.");
        return;
      }

      const validTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (!validTypes.includes(uploadedImage.type)) {
      console.error("File type must be jpg, jpeg, or png.");
      return;
    }

    const fileExtension = uploadedImage.name.split(".").pop();
    const ImageNameWithExtension = `${uuidv4()}.${fileExtension}`;

    const formData = new FormData();
    formData.append("Image", uploadedImage);
    formData.append("ImageNameWithExtension", ImageNameWithExtension);
    formData.append("Category", id);
  
    try {
      const response = await fetch(`${API_BASE_URL}/coupons/api/v1/CreateGiftCardImage`, {
        method: "POST",
        headers :{
        Authorization: `Bearer ${accessToken}`,
      
        },
        body: formData,
      
    });
    
    if (!response.ok) {
      throw new Error("Failed to upload image");
    }

    console.log("Image uploaded successfully");
    fetchAdminAddedGc(id);
}
catch(e){
}

}
const fetchAdminAddedGc = async (category) => {
  console.log(category);
  try {
    const response = await fetch(
      `${API_BASE_URL}/coupons/api/v1/GetGiftCardImage?Category=${category}`,
      {
        method: "GET",
        headers: {
          Authorization: ` Bearer ${accessToken}`,
          
        },
      }
    );

    const data = await response?.json();
    setAddedgiftcard((prevGiftCards) => ({
      ...prevGiftCards,
      [category]: data.result,
    }));
    
    console.log("Data fetched successfully");
    console.log(data);
  } catch (error) {
    console.log("Catch Done", error);
  }
};
useEffect(() => {
  window.scrollTo(0, 0);
  fetchAdminAddedGc(id);
  console.log(id);
}, [id]);
const handleFileUpload = async () => {
  const fileInput = document.getElementById("file-input");
  if (fileInput) {
    fileInput.click(); 
  }
};
  return (
    <div>
      <AdminLayout2>
        <div className="admin-new-giftcard">Gift Card</div>
        <div className="admin-new-giftcard-detailcontainer">
          <div className="admin-new-giftcard-name">{title}</div>
          <div className="admin-added-allnewgiftcard">
            <label className="admin-add-new-giftcard-box" onClick={handleFileUpload}>
              <img src={addCard} className="admin-add-new-card" alt="Add Card" />
              <input
                id="file-input"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              /> </label>
              {/* <>{addedgiftcard[id]?.map((card) => (
                  <img
                    key={card.id}
                    className="admin-added-old-giftcard"
                    src={card.giftcardImageUrl}
                    alt="Gift card"
                  />
                ))}</> */}
            
            <div className="updatedGiftCard">{addedgiftcard[id] &&
                addedgiftcard[id].map((card) => (
                  <img
                    key={card.id}
                    className="admin-added-old-giftcard"
                    src={card.giftcardImageUrl}
                    alt="Gift card"
                  />
                ))}</div>
            
        </div>
        </div>
      </AdminLayout2>
    </div>
  );
  

}
export default AdminAddNewGiftCard;
