import React from 'react';
import "../styles/Loading.css"
import { CircularProgress } from '@mui/material';

const Loading = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-content">
        {/* <h2>Loading...</h2>
         */}
         <CircularProgress style={{color:"pink", minHeight:"100px", width:"100px", }} />
         {/* <div>Loading your content. Please Wait.</div> */}
      </div>
    </div>
  );
};

export default Loading;