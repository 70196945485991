import React, { useContext, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
// import SellerFooter from "./SellerFooter";
import logo from "../Images/MainLogo.png";
import "../styles/SellerLayout.css";
import "../styles/Common.css";
import {  NavLink, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import { SellerContext } from "../context/SellerContext";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import { useLocation } from "react-router-dom";

export default function SellerLayout({ children }) {
  return (
    <div className="seller-layout" style={{background: " #F4F4F4"
  }}>
      <ResponsiveAppBar />
      <div className="page-content">
        <div style={{ paddingTop: "4rem" }}>{children}</div>
      </div>                                
      <Footer color="seller-footer-color" />
    </div>
  );
}

function ResponsiveAppBar() {
  const { sellerPersonalData, sellerData, logout } = useContext(SellerContext);
  // const { selectedListItemIndex, setSelectedListItemIndex } =
  //   useContext(SellerContext);

  const location = useLocation();
  const currentPath = location.pathname;

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (sellerPersonalData?.result?.status === "C") {
      ////console.log(sellerPersonalData?.result?.status);
      return true;
    } else {
      ////console.log("errrrrr", sellerPersonalData?.result?.status);
      setOpen(true);
      return false;
    }
  };

  useEffect(() => {
    document
      .getElementsByClassName("seller-dashboard-link")[0]
      .classList.remove("seller-navbar-link-selected");
    const element = document.getElementsByClassName(
      "seller-management-link"
    )[0];

    if (element) {
      element.classList.remove("seller-navbar-link-selected");
    }
      document
      .getElementsByClassName("seller-orders-link")[0]
      .classList.remove("seller-navbar-link-selected");
    document
      .getElementsByClassName("seller-facts-link")[0]
      .classList.remove("seller-navbar-link-selected");
    document
      .getElementsByClassName("seller-categories-link")[0]
      .classList.remove("seller-navbar-link-selected");
    document
      .getElementsByClassName("seller-discounts-link")[0]
      .classList.remove("seller-navbar-link-selected");

      if (currentPath === "/SellerDashboard") {
        const elements = document.getElementsByClassName("seller-dashboard-link");
        if (elements.length > 0) {
          elements[0].classList.add("seller-navbar-link-selected");
        }
      }
      
      if (currentPath === "/SellerCategoriesListingPage") {
        const elements = document.getElementsByClassName("seller-categories-link");
        if (elements.length > 0) {
          elements[0].classList.add("seller-navbar-link-selected");
        }
      }
    
     
      if (currentPath === "/SellerOrders") {
        const elements = document.getElementsByClassName("seller-orders-link");
        if (elements.length > 0) {
          elements[0].classList.add("seller-navbar-link-selected");
        }
      }
      if (currentPath === "/SellerFacts") {
        const elements = document.getElementsByClassName("seller-facts-link");
        if (elements.length > 0) {
          elements[0].classList.add("seller-navbar-link-selected");
        }
      }
      if (currentPath === "/SellerDiscounts") {
        const elements = document.getElementsByClassName("seller-discounts-link");
        if (elements.length > 0) {
          elements[0].classList.add("seller-navbar-link-selected");
        }
      }
    if (currentPath === "/sellerManagement") {
      const elements = document.getElementsByClassName("seller-management-link");
      if (elements.length > 0) {
        elements[0].classList.add("seller-navbar-link-selected");
      }
    }
  }, [currentPath]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate();

  return (
    <AppBar
      position="static"
      style={{
        background: "#FFFFFF",
        position: "fixed",
        zIndex: "100",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img loading="lazy" src={logo} alt="logo" className="Myhraki-logo-nav-bar" />

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem
                onClick={() => {
                  if (sellerPersonalData?.result?.status === "C") {
                    ////console.log(sellerPersonalData?.result?.status);

                    navigate("/sellerDashboard");
                    handleCloseNavMenu();
                  } else {
                    ////console.log(sellerPersonalData?.result?.status);
                    setOpen(true);
                  }
                }}
              >
                <Typography textAlign="center">Dashboard</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  if (sellerPersonalData?.result?.status === "C") {
                    ////console.log(sellerPersonalData?.result?.status);

                    navigate("/SellerCategoriesListingPage");
                    handleCloseNavMenu();
                  } else {
                    ////console.log(sellerPersonalData?.result?.status);
                    setOpen(true);
                  }
                }}
              >
                <Typography textAlign="center">Products</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  if (sellerPersonalData?.result?.status === "C") {
                    ////console.log(sellerPersonalData?.result?.status);

                    navigate("/sellerOrders");
                    handleCloseNavMenu();
                  } else {
                    ////console.log(sellerPersonalData?.result?.status);
                    setOpen(true);
                  }
                }}
              >
                <Typography textAlign="center">Orders</Typography>
              </MenuItem>
              {sellerData?.result?.typeOfUser === "SLADM" && (
                <MenuItem
                  onClick={() => {
                    if (sellerPersonalData?.result?.status === "C") {
                      ////console.log(sellerPersonalData?.result?.status);

                      navigate("/sellerManagement");
                      handleCloseNavMenu();
                    } else {
                      ////console.log(sellerPersonalData?.result?.status);
                      setOpen(true);
                    }
                  }}
                >
                  <Typography textAlign="center">Users</Typography>
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  if (sellerPersonalData?.result?.status === "C") {
                    ////console.log(sellerPersonalData?.result?.status);

                    navigate("/sellerDiscounts");
                    handleCloseNavMenu();
                  } else {
                    ////console.log(sellerPersonalData?.result?.status);
                    setOpen(true);
                  }
                }}
              >
                <Typography textAlign="center">Discounts</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  if (sellerPersonalData?.result?.status === "C") {
                    ////console.log(sellerPersonalData?.result?.status);

                    navigate("/sellerFacts");
                    handleCloseNavMenu();
                  } else {
                    ////console.log(sellerPersonalData?.result?.status);
                    setOpen(true);
                  }
                }}
              >
                <Typography textAlign="center">Facts</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "block" },
              width: "90%",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "70%",
                justifyContent: "space-around",
              }}
            >
              <NavLink
                className="seller-navbar-link seller-dashboard-link"
                to="/SellerDashboard"
                onClick={(event) => {
                  if (!handleClick()) {
                    event.preventDefault();
                  }
                }}
              >
                Dashboard
              </NavLink>
              <NavLink
                className="seller-navbar-link seller-categories-link"
                to="/SellerCategoriesListingPage"
                onClick={(event) => {
                  if (!handleClick()) {
                    event.preventDefault();
                  }
                }}
              >
                Products
              </NavLink>
              <NavLink
                className="seller-navbar-link seller-orders-link"
                to="/SellerOrders"
                onClick={(event) => {
                  if (!handleClick()) {
                    event.preventDefault();
                  }
                }}
              >
                Orders
              </NavLink>
              {sellerData?.result?.typeOfUser === "SLADM" && (
                <NavLink
                  className="seller-navbar-link seller-management-link"
                  to="/sellerManagement"
                  onClick={(event) => {
                    if (!handleClick()) {
                      event.preventDefault();
                    }
                  }}
                >
                  Users
                </NavLink>
              )}
              <NavLink
                className="seller-navbar-link seller-discounts-link"
                to="/SellerDiscounts"
                onClick={(event) => {
                  if (!handleClick()) {
                    event.preventDefault();
                  }
                }}
              >
                Discounts
              </NavLink>
              <NavLink
                className="seller-navbar-link seller-facts-link"
                to="/SellerFacts"
                onClick={(event) => {
                  if (!handleClick()) {
                    event.preventDefault();
                  }
                }}
              >
                Facts
              </NavLink>
            </div>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={sellerPersonalData?.result?.sellerName}
                  src="/static/images/avatar/2.jpg"
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                onClick={() => {
                  logout();
                }}
              >
                <Typography textAlign="center">Log Out</Typography>
              </MenuItem>
              {/* ))} */}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 300,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "background.body",
            }}
          />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            Access Denied
          </Typography>
          <Typography id="modal-desc" textColor="text.tertiary">
            Please complete filling your details and wait for the Admin to
            Approve your account.
          </Typography>
        </Sheet>
      </Modal>
    </AppBar>
  );
}
