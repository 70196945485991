import React from 'react'
import SNF from '../Images/SNF.png'
import '../styles/SearchNotFound.css'
import CustomerLayout from '../components/CustomerLayout'
import { Navigate, useNavigate } from 'react-router-dom'

function SearchNotMatch() {
    const navigate=useNavigate();
    const handleClick=()=>{
        navigate("/");
    }
  return (
    
    <div className='SearchNotMatch'>
        <div className='SNF-maintext'>
            <div className='SNF-text1'>Oops!!<span className='SNF-text2'>Product Not Found</span> </div>
            <div className='SNF-btn'><button className='SNF-button' onClick={handleClick}>Back to home</button></div>
        </div>
        <div >
         <img src={SNF} alt="Sorry, No Match" className='SNF-Image' />
        </div>
      
    </div> 
     
  )
}

export default SearchNotMatch
