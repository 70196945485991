import { Button, Checkbox } from '@mui/material'
import React from 'react'
import '../styles/CustomerLandingPage.css';
import { Star } from '@phosphor-icons/react';
import image from "../Images/Jutha2.png"
import { Heart } from '@phosphor-icons/react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { HeartBreak } from '@phosphor-icons/react';
import { HeartStraight, Heartbeat } from '@phosphor-icons/react';
import { addToWishlist, deleteFromWishlist } from '../utils/addToWishlist';
import { useNavigate } from 'react-router-dom'
import { CustomerContext } from '../context/CustomerContext';
import { useContext } from 'react';
import { useState } from 'react';
import { Trash } from '@phosphor-icons/react';

function CustomerWishlistCard(props) {
    const [isFavorite, setIsFavorite] = useState(false);

    const navigate = useNavigate();
    const {customerData, accessToken} = useContext(CustomerContext)
    // const handleHeartClick = () => {
    //     //console.log('click fav')
    //     setIsFavorite(!isFavorite);
    //     addToWishlist(props, customerData, accessToken); 
    //    };
    const handleHeartDeleteClick = async (id) => {
        //console.log('click fav')
        // setIsFavorite(!isFavorite);
        const deleted = await deleteFromWishlist(accessToken, id)
        //console.log(deleted)
        if(deleted === true){
            props.filterWishlistData(id);
        }
       };

       const checkboxStyle = {
        // Define the default style for the Checkbox
        width: '24px',
        height: '32px',
        color: '',
        // Use a ternary operator to set the background color to red when it's a favorite
        backgroundColor: isFavorite ? 'red' : 'transparent',
      };

    return (
        <div className="Customer_wishlist_card_container" >
            {/* <Checkbox className='customer-product-card-fav' icon={<HeartBreak />} checkedIcon={<Heart />} /> */}
            <div className='customer-product-card-img'>
                <img loading="lazy"  src={props.imageFilename} alt="img" style={{maxWidth:'100%'}} className='Product_card_img_layout'/>
                <div className='customer-product-card-fav' >
      {/* {isFavorite ? (
        <Heart size={32} color="#f70808"  onClick={handleHeartClick} />
      ) : (
        <Heart size={32}   weight="fill"  color="#f70808"  onClick={()=>{handleHeartDeleteClick(props.ID)}} />
      )} */}

      <HighlightOffIcon   size={32}   weight="fill"  color="#f70808"  onClick={()=>{handleHeartDeleteClick(props.ID)}} />
    </div>
            </div>
            <div  onClick={()=>{navigate(`/productDetail/${props.productId}`)}}>
            <div className='customer-product-card-title'>{props.title}</div>
            <div className='customer-product-card-category'>{props.category}</div>
            <div className='customer-product-card-price'>₹{props.unitCost}/-</div>
            <div className='customer-product-card-review'><Star /> {props.category} | {props.category} Reviews</div>
            {/* <div className='customer-product-card-quantity'>{props.category} products in stock</div> */}
            <Button onClick={()=>{navigate(`/productDetail/${props.productId}`)}} contained fullWidth className='customer-product-card-button' >{props.wishlistCard?'Add to Cart':'View Product'}</Button>
            </div>
        </div>

    )
}

export default CustomerWishlistCard