import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Dropzone from "react-dropzone";
// import SellerNavbar2 from "../components/SellerNavbar2";
import { CircularProgress, FormControlLabel, MenuItem,  Radio,  RadioGroup,  TextField } from "@mui/material";
// import Heading from "../components/Heading";
import { countries } from "countries-list";
import md5 from "crypto-js/md5";
// import SellerFooter from "../components/SellerFooter";
import "../styles/FileUploader.css";
import "../styles/AddProductBySeller.css";
import Heading from "../components/Heading";
// import SellerLayout from "../components/SellerLayout";
import { SellerContext } from "../context/SellerContext";
import { v4 as uuidv4 } from "uuid";
// import withSellerAuth from "../context/withSellerAuth";
import { useFormik } from "formik";
import * as yup from "yup";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Tostify from "../components/Tostify";
import ImageUploderImage from "../Images/ImageUploderImage.png"
//import notif from "../components/Notify";
import { confirmAlert, successAlert, errorAlert } from "../components/Alert";
import { Link, useNavigate } from "react-router-dom";
import { DeleteOutline } from "@mui/icons-material";
import SellerLayoutAdjustment from "../components/SellerLayoutAdjustment";
import ToastComponent from "../components/ToastComponent";
import { API_BASE_URL } from "../App";
import { CaretLeft } from "@phosphor-icons/react";


function AddProductBySeller2() {
  const { sellerData, accessToken, sellerPlanDetails, handleAuthExpiry, logout, getSellerPlan } = useContext(SellerContext);
  const navigate = useNavigate();
  const [sellerId, setSellerId] = useState(sellerData?.result?.typeOfUser==='SLADM'?sellerData?.result?.userID:sellerData?.result?.userAdminId);
  const sellerPlan = sellerPlanDetails;
  const [category, setCategory] = useState();
  const [title, setTitle] = useState();
  const [gender, setGender] = useState();
  const [description, setDescription] = useState();
  const [fact, setFact] = useState();
  const [address, setAddress] = useState();
  const [country, setCountry] = useState();
  const [errors, setErrors] = useState({});
  const [tab, setTab] = useState("basic");
  const [videoAllowed, setVideoAllowed] = useState(false);
  const [categoryList, setCategoryList] = useState();
  const [addressList, setAddressList] = useState();
  const [loading, setLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [vegan, setVegan] = useState(false);
  const [handmade, setHandmade] = useState(false);
  const [makeInIndia, setMakeInIndia] = useState(false);
  const [productGST, setProductGST] = useState('');
  const toastRef = React.useRef();
  const [productData, setProductData] = useState({
    category,
    title,
    gender,
    description,
    fact,
    address,
    country,
    makeInIndia,
    vegan,
    handmade,
    productGST,
    sellerId,
    delivery: {
      cashOnDelivery: "NA",
      returnable: "NA",
      refundable: "NA",
    },
  });


  function isAddVideoAllowed(sellerPlanDetails) {
    //console.log(sellerPlanDetails)
    const elements = sellerPlanDetails?.toString().split(".");
    // //console.log(elements[8])
    if (elements?.length >= 12) {
      const videoElement = parseFloat(elements[8]);
      if(videoElement>0){
        return true;
      }    }
    return false;
  }


  function checkVideoAllowed() {
    if (isAddVideoAllowed(sellerPlanDetails)) {
      setVideoAllowed(true)
    }
    else {
      setVideoAllowed(false)
    }
  }

  useEffect(() => {
    checkVideoAllowed()
  }, [sellerPlanDetails])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setTab("specific");
      window.scrollTo(0, 0);

      ////console.log("Form submitted");
    }
  };

  const fetchCategories = async () => {
    try {
      let response = await fetch(
        `${API_BASE_URL}/productgen/api/v1/Category/All`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      let data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        errorAlert('', 'Your Session has expired');
        logout();
        return;
      }
      if (data?.status === 401) {
        errorAlert('','Session timed out. Please Log in');
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate('/')
      }
      setCategoryList(data.result);
    } catch (error) {
      //console.error(error);
    }
  };

  const fetchAddress = async () => {
    if(!sellerData?.result?.userID){
      return;
    }
    try {
      let response = await fetch(
        `${API_BASE_URL}/address/api/v1/getAll?userId=${sellerData?.result?.typeOfUser==='SLADM'?sellerData?.result?.userID:sellerData?.result?.userAdminId}`,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );


      let data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        errorAlert('', 'Your Session has expired');
        logout();
        return;
      }
      setAddressList(data.result);
    }
    catch (error) {
      //console.error(error);
    }
  };
  useEffect(() => {
    fetchCategories();
    fetchAddress();
  }, [sellerData]);

  const validate = () => {
    let tempErrors = {};
    if (!category) {
      tempErrors.category = "Please choose a category";
    }
    if (
      !title ||
      !/^(?=.*\S).{1,250}$/
        .test(
          title
        )
    ) {
      tempErrors.title = "Please provide a valid Product Name";
    }
    if (!gender) {
      tempErrors.gender = "Please select applicable genders";
    }
    if (!productGST) {
      tempErrors.productGST = "Please Enter a valid GST Amount";
    } else {
      const gstAmount = Number(productGST);
    
      if (isNaN(gstAmount) || gstAmount >= 100 || gstAmount < 0) {
        tempErrors.productGST = "GST Amount should be a number less than 100";
      }
    }
    if (!description || !/^[\s\S]{1,2000}$/.test(description)|| !description?.trim().length>=1) {
      tempErrors.description = "Please provide a description";
    }
    if (!address) {
      tempErrors.address = "Please select an Address";
    }
    if (fact?.length > 0 && !/^[\s\S]{1,1000}$/.test(fact)) {
      tempErrors.fact = "Provide a fact within 1000 characters";
    }
    if (!country) {
      tempErrors.country = "Please select a Country";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const countryList = Object.values(countries);

  const sortedCountries = countryList.sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const filteredCountries = sortedCountries.filter((country) =>
    country.name.toLowerCase().includes("a")
  );

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    setProductData((prevData) => ({
      ...prevData,
      category: event.target.value,
    }));
    setErrors((prevErrors) => {
      const { category: nameError, ...rest } = prevErrors;
      return { ...rest };
    });
  };
  const handleAddressChange = (event) => {
    setAddress(event.target.value);
    setProductData((prevData) => ({
      ...prevData,
      address: event.target.value,
    }));
    setErrors((prevErrors) => {
      const { address: nameError, ...rest } = prevErrors;
      return { ...rest };
    });
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
    setProductData((prevData) => ({
      ...prevData,
      gender: event.target.value,
    }));
    setErrors((prevErrors) => {
      const { gender: nameError, ...rest } = prevErrors;
      return { ...rest };
    });
  };

  const handleProductNameChange = (event) => {
    setTitle(event.target.value);
    setProductData((prevData) => ({
      ...prevData,
      title: event.target.value,
    }));
    setErrors((prevErrors) => {
      const { title: nameError, ...rest } = prevErrors;
      return { ...rest };
    });
  };

  const handleProductDescriptionChange = (event) => {
    setDescription(event.target.value);
    setProductData((prevData) => ({
      ...prevData,
      description: event.target.value,
    }));
    setErrors((prevErrors) => {
      const { description: nameError, ...rest } = prevErrors;
      return { ...rest };
    });
  };
  const handleProductFactChange = (event) => {
    setFact(event.target.value);
    setProductData((prevData) => ({
      ...prevData,
      fact: event.target.value,
    }));
    setErrors((prevErrors) => {
      const { fact: nameError, ...rest } = prevErrors;
      return { ...rest };
    });
  };
  
  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setCountry(selectedCountry);
    setProductData((prevData) => ({
      ...prevData,
      country: selectedCountry.name,
    }));
    setErrors((prevErrors) => {
      const { country: nameError, ...rest } = prevErrors;
      return { ...rest };
    });
  };

  const handleVeganChange = (event) =>{
    
    setProductData((prevData) => ({
      ...prevData,
      vegan: event.target.value,
    }));
   
  }
  const handleMakeInIndiaChange = (event) =>{
    
    setProductData((prevData) => ({
      ...prevData,
      makeInIndia: event.target.value,
    }));
   
  }
  const handleHandmadeChange = (event) =>{
    
    setProductData((prevData) => ({
      ...prevData,
      handmade: event.target.value,
    }));
  
  }
  const handleProductGSTChange = (event) =>{
    setProductGST(event.target.value);
    setProductData((prevData) => ({
      ...prevData,
      productGST: event.target.value,
    }));
    setErrors((prevErrors) => {
      const { productGST: nameError, ...rest } = prevErrors;
      return { ...rest };
    });
    console.log(productData);
  
  }

 
  

  useEffect(() => {
    ////console.log(productData);
  }, [productData]);

  const [formData, setFormData] = useState({});
  ////console.log(formData);


  async function SubmitProductDetails() {
    if (!productData.fact) {
      productData.fact = "";
    }
    setLoading(true);

    if (productData?.skUs?.length <= 0) {
      errorAlert("", "Add Atleast one variant");
      setLoading(false);

      return;
    }
    ////console.log(`Product Data: ${JSON.stringify(productData)}`);
    try {
      const modifiedProductData = { ...productData }; 
      if (modifiedProductData.skUs) {
        modifiedProductData.skUs = modifiedProductData.skUs.map((sku) => {
          if (sku.imagesUriList) delete sku.imagesUriList;
          if (sku.videosUriList) delete sku.videosUriList;
          ////console.log(sku)
          return sku;
        });
      }

      let result = await fetch(
        `${API_BASE_URL}/productgen/api/v1/create`,
        {
          method: "post",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "text/plain ",
          },
          body: JSON.stringify(modifiedProductData),
        }
      );
      ////console.log(result);
      const response = await result.json();
      const handleAuthError = handleAuthExpiry(result);
      if (handleAuthError === true) {
        errorAlert('', 'Your Session has expired');
        logout();
        return;
      }
      if (response?.result === true) {
        getSellerPlan();
        successAlert('', 'Product Created Successfully')
        navigate('/sellerCategoriesListingPage')
        setLoading(false);

      }
      else {
        // errorAlert('', 'Something went Wrong. Please check the data and try again')
        errorAlert('',
          "Something went Wrong. Please check the data and try again",
        );
        setLoading(false);

      }
      ////console.log(response);
    } catch (e) {
      ////console.log(e);
      setLoading(false);

    }
  }

  return (
    <SellerLayoutAdjustment>
      {/* <SellerNavbar2 /> */}
      <Heading text=" Add Product " />
      <div className="page-content">

        {tab === "basic" &&
          !productLoading && <div>
            {/* <Link to='/sellerCategoriesListingPage' style={{ color: 'black', textDecoration: 'underline' }}>Back to Products</Link> */}
            <div onClick={() => { navigate('/sellerCategoriesListingPage') }} style={{cursor:'pointer', display:'flex', alignItems:'center'}}>
              <CaretLeft /> Back</div>
            <form className="add-product-by-seller-form" >
              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">Category *</div>
                <div className="add-product-by-seller-field-textfield">
                  <TextField
                    select
                    value={category}
                    onChange={handleCategoryChange}
                    fullWidth
                    outlined
                    error={Boolean(errors.category)}
                    helperText={errors.category}
                  >
                    {categoryList?.map((category) => (
                      <MenuItem value={category.categoryId}>{category.categoryName}</MenuItem>
                    ))}
                  </TextField>
                </div>
              
              </div>

              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">
                  Product Name *
                </div>
                <div className="add-product-by-seller-field-textfield">
                  <TextField
                    fullWidth
                    outlined
                    value={title}
                    onChange={handleProductNameChange}
                    error={Boolean(errors.title)}
                    helperText={errors.title}
                  />
                </div>
              </div>

              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">Gender *</div>
                <div className="add-product-by-seller-field-textfield">
                  <TextField
                    select
                    value={gender}
                    onChange={handleGenderChange}
                    fullWidth
                    outlined
                    error={Boolean(errors.gender)}
                    helperText={errors.gender}
                  >
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                    <MenuItem value={"Unisex"}>Unisex</MenuItem>
                    <MenuItem value={"Not Applicable"}>Not Applicable</MenuItem>
                  </TextField>
                </div>
                {/* {errors.gender && (
              <div className="add-product-by-seller-field-error-text">
                Please Select applicable gender
              </div>
            )} */}
              </div>

              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">Description *</div>
                <div className="add-product-by-seller-field-textfield">
                  {/* <TextField fullWidth outlined/> */}
                  <textarea
                    className={`add-product-by-seller-field-text-area ${errors.description ? "add-product-by-seller-field-error" : ""
                      }`}
                    value={description}
                    onChange={handleProductDescriptionChange}
                    error={Boolean(errors.description)}
                    helperText={errors.description}
                  />
                </div>
                {errors.description && (
                  <div className="add-product-by-seller-field-error-text-desc">
                    Please add a valid description
                  </div>
                )}
              </div>
              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">Fact</div>
                <div className="add-product-by-seller-field-textfield">
                  {/* <TextField fullWidth outlined/> */}
                  <textarea
                    className={`add-product-by-seller-field-text-area ${errors.fact ? "add-product-by-seller-field-error" : ""
                      }`}
                    value={fact}
                    onChange={handleProductFactChange}
                    error={Boolean(errors.fact)}
                    helperText={errors.fact}
                  />
                </div>
                {errors.fact && (
                  <div className="add-product-by-seller-field-error-text-desc">
                    Fact should be less than 300 characters.
                  </div>
                )}
              </div>
              



              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">Address *</div>
                <div className="add-product-by-seller-field-textfield">

                  <TextField
                    select
                    value={address}
                    onChange={handleAddressChange}
                    fullWidth
                    outlined
                    error={Boolean(errors.address)}
                    helperText={errors.address}
                  >
                    {addressList?.map((address) => (
                      <MenuItem value={address.id}>{address.addressLine1 + ' ' + address.state + ' ' + address.city + ' ' + address.pincode}</MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>

              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">Country*</div>
                <div className="add-product-by-seller-field-textfield">
                  <TextField
                    select
                    value={country}
                    onChange={handleCountryChange}
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors.country)}
                    helperText={errors.country}
                  >
                    <MenuItem value="">Select a country</MenuItem>
                    {filteredCountries.map((country) => (
                      <MenuItem value={country}>{country.name}</MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>

              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">Product GST</div>
                <div className="add-product-by-seller-field-textfield">
                  {/* <TextField fullWidth outlined/> */}
                  <TextField
                    fullWidth
                    value={productGST}
                    onChange={handleProductGSTChange}
                    error={Boolean(errors.productGST)}
                    helperText={errors.productGST}
                  />
                </div>
              </div>

              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">Is your product Vegan*</div>
                <div className="add-product-by-seller-field-textfield"  style={{display:'flex', gap:'50px'}}>
                <label>
        <input
          type="radio"
          name="vegan"
          value={true}
          checked={vegan===true}
          onChange={(e) => {setVegan(true); handleVeganChange(e);}}
        />{' '}
        Yes
      </label>
      <br />
      <label>
        <input
          type="radio"
          name="vegan"
          value={false}
          checked={vegan===false}
          onChange={(e) => {setVegan(false); handleVeganChange(e);}}
        />{' '}
        No
      </label>
                </div>
              </div>

              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name"> Is your product Handmade*</div>
                <div className="add-product-by-seller-field-textfield" style={{display:'flex', gap:'50px'}}>
                <label>
        <input
          type="radio"
          name="handmade"
          style={{color:'pink', background:'pink'}}
          value={true}
          checked={handmade===true}
          onChange={(e) => {setHandmade(true); handleHandmadeChange(e)}}
        />{' '}
        Yes
      </label>
      <br />
      <label>
        <input
          type="radio"
          name="handmade"
          value={false}
          checked={handmade===false}
          onChange={(e) => {setHandmade(false); handleHandmadeChange(e)}}
        />{' '}
        No
      </label>
                </div>
              </div>

              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">Is your product Made In India*</div>
                <div className="add-product-by-seller-field-textfield"  style={{display:'flex', gap:'50px'}}>
                <label>
        <input
          type="radio"
          name="madeInIndia"
          value={true}
          checked={makeInIndia===true}
          onChange={(e) => {setMakeInIndia(true); handleMakeInIndiaChange(e);}}
        />{' '}
        Yes
      </label>
      <br />
      <label>
        <input
          type="radio"
          name="madeInIndia"
          value={false}
          checked={makeInIndia===false}
          onChange={(e) => {setMakeInIndia(false); handleMakeInIndiaChange(e)}}
        />{' '}
        No
      </label>
                </div>
              </div>

              <div className="add-product-by-seller-field-button">
                <button className="common-btn" type="button" onClick={handleSubmit}>
                  Submit and Proceed
                </button>
              </div>
            </form>
          </div>}
        {tab === "basic" && productLoading && <CircularProgress style={{ color: "pink" }} />}
        {tab === "specific" && (
          <div>
            <Form
              setFormData={setFormData}
              // isChecked={isChecked}
              // isCommonVideo={isCommonVideo}
              videoAllowed={videoAllowed}
              productData={productData}
              setProductData={setProductData}
              SubmitProductDetails={SubmitProductDetails}
              // commonVideos={commonVideos}
              // commonImages={commonImages}
              sellerData={sellerData}
              loading={loading}
              productLoading={productLoading}
              accessToken={accessToken}
              logout={logout}
              handleAuthExpiry={handleAuthExpiry}
              tab={tab}
              setTab={setTab}
            />
          </div>
        )}
        {/* //<ToastContaner theme="coloured" className="toast-message" /> */}
      </div>
    </SellerLayoutAdjustment>
  );
}

export default (AddProductBySeller2);

function Form({
  setFormData,
  formData,
  isChecked,
  isCommonVideo,
  videoAllowed,
  commonVideos,
  commonImages,
  productData,
  setProductData,
  SubmitProductDetails,
  sellerData,
  loading,
  productLoading,
  accessToken, logout, handleAuthExpiry, tab, setTab
}) {
  const [forms, setForms] = useState([
    {
      imageList: isChecked ? commonImages : [],
      imagesUriList: [],
      videosUriList: [],
      videoList: isCommonVideo ? commonVideos : [],
      productAttributeList: [
        { a_Key: "length", a_Value: "" },
        { a_Key: "width", a_Value: "" },
        { a_Key: "height", a_Value: "" },
        { a_Key: "weight", a_Value: "" },
      ], sku: generateSkuId(sellerData?.result?.userID)
    },
  ]);

  // const sellerId=1;

  function generateSkuId(sellerId) {
    const datetime = new Date().getTime().toString();
    const skuData = sellerId + datetime;
    const sku = md5(skuData).toString();
    ////console.log(sku);
    return sku;
  }

  const validationSchema = yup.object({
    color: yup.string().matches(/^[a-zA-Z,&]{0,100}$/),
    fabric: yup.string().matches(/^[a-zA-Z0-9,&]{0,100}$/),
    size: yup.string(),
    price: yup
      .string()
      .matches(/^[1-9]\d*$/)
      .required(),
    sellingPrice: yup
      .string()
      .matches(/^[1-9]\d*$/)
      .required(),
    // .test("is-less-than-price", "Selling price must be less than price", function (value) {
    //   const price = this.resolve(yup.ref("price"));
    //   return !value || !price || parseInt(value, 10) <= parseInt(price, 10);
    // }),
    quantity: yup
      .string()
      .required()
      .matches(/^[1-9]\d*$/),
  });


  const formik = useFormik({
    initialValues: {
      color: "",
      fabric: "",
      size: "",
      price: "",
      sellingPrice: "",
      quantity: "",
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      // setFormError(() => [...formError, false]);
      ////console.log(formik.touched.color); // Check the value of touched.color
      ////console.log(formik.errors.color);
      handleAddForm();
      formik.setFieldValue("color", "");
      formik.setFieldValue("fabric", "");
      formik.setFieldValue("size", "");
      formik.setFieldValue("price", "");
      formik.setFieldValue("sellingPrice", "");
      formik.setFieldValue("quantity", "");
    },
  });

  const addNewForm = () => {
    if (forms.length === 0) {
      handleAddForm();
      return;
    }
    const latestForm = forms[forms.length - 1];
    const errorReasons = [];

    if (!/^[a-zA-Z,& ]{0,100}$/.test(latestForm?.color)) {
      errorReasons.push("Invalid color");
    }

    if (!/^[a-zA-Z,& ]{0,100}$/.test(latestForm?.fabric)) {
      errorReasons.push("Invalid Material");
    }

    if (!/^[1-9]\d*$/.test(latestForm?.price)) {
      errorReasons.push("Invalid price");
    }

    if (!/^[1-9]\d*$/.test(latestForm?.sellingPrice)) {
      errorReasons.push("Invalid selling price");
    }

    if (parseInt(latestForm?.sellingPrice) > parseInt(latestForm?.price)) {
      errorReasons.push("Selling price should be lesser than price");
    }

    if (!/^[1-9]\d*$/.test(latestForm?.quantity)) {
      errorReasons.push("Invalid quantity");
    }
    if (!latestForm?.imageList?.length > 0) {
      errorReasons.push(`At least one image is required.`);
    }
    if (!(latestForm?.productAttributeList[0].a_Value)) {
      errorReasons.push(
        `Integer value is required for Length property .`
      );
    }
    if (!(latestForm?.productAttributeList[1].a_Value)) {
      errorReasons.push(
        `Integer value is required for Width property .`
      );
    }
    if (!(latestForm?.productAttributeList[2].a_Value)) {
      errorReasons.push(
        `Integer value is required for Height property .`
      );
    }
    if (!(latestForm?.productAttributeList[3].a_Value)) {
      errorReasons.push(
        `Integer value is required for Weight property .`
      );
    }

    if (errorReasons.length > 0) {
      const errorMessage = `The latest form has the following errors:\n${errorReasons.join(
        "\n"
      )}`;
      errorAlert('', errorMessage, '', '', 30000, true);

    } else {
      handleAddForm();
    }
  };

  useEffect(() => {
    ////console.log(forms);
    setFormData(forms);
    // eslint-disable-next-line
  }, [forms]);

  useEffect(() => {
    const newData = { ...productData, skUs: forms };
    setProductData(newData);
    ////console.log(productData);
    // eslint-disable-next-line
  }, [forms]);

  const handleAddField = (formIndex) => {
    const newForms = [...forms];
    newForms[formIndex].productAttributeList.push({ a_Key: "", a_Value: "" });
    setForms(newForms);
  };
  const [prevImages, setPrevImages] = useState([]);
  const [prevVideos, setPrevVideos] = useState([]);
  // const [prevAttributes, setPrevAttributes] = useState([]);

  const handlePrevImagesChange = (event, formIndex) => {
    // const form = forms?.[formIndex];
    ////console.log(form);
    const updatedPrevImages = [...prevImages];
    updatedPrevImages[formIndex] = event.target.checked;

    if (event.target.checked && formIndex > 0) {
      const prevFormIndex = formIndex - 1;
      const prevImageList = forms[prevFormIndex]?.imageList;
      const prevImagesUriList = forms[prevFormIndex]?.imagesUriList;
      ////console.log(forms[prevFormIndex]?.imagesUriList);
      ////console.log(prevImageList);
      ////console.log(prevImagesUriList);
      setForms((prevForm) => {
        const updatedForm = [...prevForm];
        updatedForm[formIndex] = {
          ...prevForm[formIndex],
          imageList: prevImageList,
          imagesUriList: prevImagesUriList,
        };
        return updatedForm;
      });
    } else {
      setForms((prevForm) => {
        const updatedForm = [...prevForm];
        updatedForm[formIndex] = {
          ...updatedForm[formIndex],
          imageList: [],
          imagesUriList: [],
        };
        return updatedForm;
      });
    }

    setPrevImages(updatedPrevImages);
  };

  const handlePrevVideoChange = (event, formIndex) => {
    const form = forms?.[formIndex];
    const updatedPrevVideos = [...prevVideos];
    updatedPrevVideos[formIndex] = event.target.checked;

    if (event.target.checked && formIndex > 0) {
      const prevFormIndex = formIndex - 1;
      const prevVideoList = forms[prevFormIndex]?.videoList;
      const prevVideosUriList = forms[prevFormIndex]?.videosUriList;

      setForms((prevForm) => {
        const updatedForm = [...prevForm];
        updatedForm[formIndex] = {
          ...prevForm[formIndex],
          videoList: prevVideoList,
          videosUriList: prevVideosUriList,
        };
        return updatedForm;
      });
    } else {
      setForms((prevForm) => {
        const updatedForm = [...prevForm];
        updatedForm[formIndex] = {
          ...updatedForm[formIndex],
          videoList: [],
          videosUriList: [],
        };
        return updatedForm;
      });
    }

    setPrevVideos(updatedPrevVideos);
  };

  const handleAddForm = () => {

    setForms((prevForms) => {
      // const lastForm = prevForms[prevForms.length - 1];
      const newForm = {
        size: "",
        price: "",
        sellingPrice: "",
        quantity: "",
        sku: generateSkuId(sellerData?.result?.userID),
        productAttributeList: [
          { a_Key: "length", a_Value: "" },
          { a_Key: "width", a_Value: "" },
          { a_Key: "height", a_Value: "" },
          { a_Key: "weight", a_Value: "" },
        ],
        imageList: [],
        videoList: [],
        imagesUriList: [],
        videosUriList: []
      };
      return [...prevForms, newForm];
    });
  };

  useEffect(() => {
    if (prevImages) {
      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        updatedForms[prevForms.length - 1] = {
          ...updatedForms[prevForms.length - 1],
          imageList: updatedForms[prevForms.length - 1]?.imageList || [],
          imagesUriList: updatedForms[prevForms.length - 1]?.imagesUriList || [],
          isNewfiles: true,

        };
        return updatedForms;
      });
    }
    if (!prevImages) {
      ////console.log("thissssss")
      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        updatedForms[prevForms.length - 1] = {
          ...updatedForms[prevForms.length - 1],
          imageList: [],
          imagesUriList: []
        };
        return updatedForms;
      });
    }
  }, [prevImages]);

  useEffect(() => {
    if (prevVideos) {
      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        updatedForms[prevForms.length - 1] = {
          ...updatedForms[prevForms.length - 1],
          videoList: updatedForms[prevForms.length - 1]?.videoList || [],
          videosUriList: updatedForms[prevForms.length - 1]?.videosUriList || [],
          isNewfiles: true,
        };
        return updatedForms;
      });
    }
    if (!prevVideos) {
      setForms((prevForms) => {
        const updatedForms = [...prevForms];
        updatedForms[prevForms.length - 1] = {
          ...updatedForms[prevForms.length - 1],
          videoList: [],
          videosUriList: [],
        };
        return updatedForms;
      });
    }
  }, [prevVideos]);



  const [uploading, setUploading] = useState(false);
  const [videoUploading, setVideoUploading] = useState(false);
  const toastRef = React.useRef();
  // const [thumbnailUrls, setThumbnailUrls] = useState([]);
  // const [thumbnailVideoUrls, setThumbnailVideoUrls] = useState([]);

  const handleImageDrop = async (acceptedFiles, formIndex) => {
    if (acceptedFiles.length > 1) {
      errorAlert('', "Only one file can be uploaded at a time.");
      return;
    }
    if (!isChecked) {
      const updatedForms = [...forms];
      const updatedImages = updatedForms[formIndex].imageList
        ? [...updatedForms[formIndex].imageList]
        : [];
      const updatedImagesUriList = updatedForms[formIndex].imagesUriList
        ? [...updatedForms[formIndex].imagesUriList]
        : [];

      acceptedFiles.forEach(async (file) => {
        const readFileAsArrayBuffer = (file) => {
          return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = () => {
              const arrayBuffer = fileReader.result;
              resolve(new Uint8Array(arrayBuffer));
            };

            fileReader.onerror = (error) => {
              reject(error);
            };

            fileReader.readAsArrayBuffer(file);
          });
        };

        if (updatedImages?.length === 10) {
          errorAlert('', "Only ten images can be uploaded.");
          return;
        }
        try {
          const uint8Array = await readFileAsArrayBuffer(file);

          // Check if the magic number corresponds to jpg/jpeg/png
          if (
            uint8Array.length >= 2 &&
            uint8Array[0] === 0xFF &&
            uint8Array[1] === 0xD8
          ) {
            // JPG file
          } else if (
            uint8Array.length >= 4 &&
            uint8Array[0] === 0x89 &&
            uint8Array[1] === 0x50 &&
            uint8Array[2] === 0x4E &&
            uint8Array[3] === 0x47
          ) {
            // PNG file
          } else if (
            uint8Array.length >= 2 &&
            uint8Array[0] === 0xFF &&
            uint8Array[1] === 0xD9
          ) {
            // JPEG file (ending with EOI marker)
          } else {
            // Invalid file type
            errorAlert('',
              "Invalid file type. Please choose a valid jpg, jpeg, or png file."
            );
            return;
          }

          // Continue with the rest of your code for size and extension validation
          if (
            !["image/jpeg", "image/png", "image/jpg"].includes(file.type)
          ) {
            errorAlert('',"File type must be jpg, jpeg, or png.");
            return;
          }

          if (file.size > 5 * 1024 * 1024) {
            errorAlert('',"File size should be less than 5MB");
            return;
          }

          const formData = new FormData();
          const extension = file.name.split(".").pop().toLowerCase();
          const fileName = uuidv4() + "." + extension;

          formData.append("Attachment", file);
          formData.append("FileNameWithExtension", fileName);
          formData.append("FileCategory", "products");

          setUploading(true);

          fetch(`${API_BASE_URL}/blob/api/v1/uploadmedia`, {
            method: "POST",
            headers: {
              Accept: "text/plain",
              Authorization: `Bearer ${accessToken}`,
            },
            body: formData,
          })
            .then((response) => {
              if (!response.ok) {
                if (response.status === 401) {
                  //console.log("Unauthorized access. Redirecting to login page.");
                  logout();
                } else {
                  console.error(`Error: ${response.status} - ${response.statusText}`);
                }
                throw new Error("Network response was not ok");
              }
              return response.text();
            })
            .then((result) => {
              const imageThumbnail = URL.createObjectURL(file);
              const newImage = fileName;
              updatedImages.push(newImage);
              updatedImagesUriList.push({ thumbnailURI: imageThumbnail });
              updatedForms[formIndex].imageList = updatedImages;
              updatedForms[formIndex].imagesUriList = updatedImagesUriList;
              updatedForms[formIndex].isNewfiles = true;
              setForms(updatedForms);

              setUploading(false);
            })
            .catch((error) => {
              console.error("Error uploading file:", error);
              setUploading(false);
            });
        } catch (error) {
          console.error("Error reading file:", error);
        }
      });
    }
  };

  const handleImageCancel = (index, formIndex) => {
    ////console.log(index);
    setForms((prevForms) => {
      const updatedForms = [...prevForms];
      const form = { ...updatedForms[formIndex] };
      const updatedImages = form.imageList.filter((_, i) => i !== index);
      const updatedImagesThumbnails = form.imagesUriList.filter((_, i) => i !== index);
      form.imageList = updatedImages;
      form.imagesUriList = updatedImagesThumbnails;
      updatedForms[formIndex] = form;

      return updatedForms;
    });
  };
  const handleVideoDrop = (acceptedFiles, formIndex) => {
    if (acceptedFiles.length > 1) {
      errorAlert('', "Only one file can be uploaded at a time.");
      return;
    }
    if (!isCommonVideo) {
      const updatedForms = [...forms];
      const updatedVideos = updatedForms[formIndex].videoList
        ? [...updatedForms[formIndex].videoList]
        : [];
      const updatedVideosUriList = updatedForms[formIndex].videosUriList
        ? [...updatedForms[formIndex].videosUriList]
        : [];

      acceptedFiles.forEach(async (file) => {
        const readFileAsArrayBuffer = (file) => {
          return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = () => {
              const arrayBuffer = fileReader.result;
              resolve(new Uint8Array(arrayBuffer));
            };

            fileReader.onerror = (error) => {
              reject(error);
            };

            fileReader.readAsArrayBuffer(file.slice(0, 4)); // Read only the first 4 bytes
          });
        };
        if (updatedVideos?.length === 1) {
          errorAlert('', "Only one video can be uploaded.");
          return;
        }

        try {
          const uint8Array = await readFileAsArrayBuffer(file);

          // Check if the magic number corresponds to mp4/quicktime/3gpp
          if (
            uint8Array.length >= 4 &&
            uint8Array[0] === 0x00 &&
            uint8Array[1] === 0x00 &&
            uint8Array[2] === 0x00 &&
            (uint8Array[3] === 0x18 || uint8Array[3] === 0x20)
          ) {
            // MP4 file
          } else if (
            uint8Array.length >= 8 &&
            uint8Array[0] === 0x6D &&
            uint8Array[1] === 0x6F &&
            uint8Array[2] === 0x6F &&
            uint8Array[3] === 0x76 &&
            uint8Array[4] === 0x00 &&
            uint8Array[5] === 0x00 &&
            uint8Array[6] === 0x00 &&
            uint8Array[7] === 0x6D
          ) {
            // Quicktime file
          } else if (
            uint8Array.length >= 4 &&
            uint8Array[0] === 0x66 &&
            uint8Array[1] === 0x74 &&
            uint8Array[2] === 0x79 &&
            uint8Array[3] === 0x70
          ) {
          } else if (
            uint8Array.length >= 4 &&
            uint8Array[0] === 0x00 &&
            uint8Array[1] === 0x00 &&
            uint8Array[2] === 0x00 &&
            uint8Array[3] === 0x24
          ) {
          } else if (
            uint8Array.length >= 4 &&
            uint8Array[0] === 0x00 &&
            uint8Array[1] === 0x00 &&
            uint8Array[2] === 0x00 &&
            uint8Array[3] === 0x20
          ) {
          } else if (
            uint8Array.length >= 4 &&
            uint8Array[0] === 0x00 &&
            uint8Array[1] === 0x00 &&
            uint8Array[2] === 0x00 &&
            uint8Array[3] === 0x32
          ) {
            // 3GPP file
          }
          else {
            // Invalid file type
            errorAlert('',
              "Invalid file type. Please choose a valid mp4 file."
            );
            return;
          }

          // Continue with the rest of your code for size and extension validation
          if (
            !["video/mp4", "video/quicktime", "video/3gpp"].includes(file.type)
          ) {
            errorAlert('',"File type must be mp4");
            return;
          }

          if (file.size > 40 * 1024 * 1024) {
            errorAlert('',"File size should be less than 40MB");
            return;
          }

          const formData = new FormData();
          const extension = file.name.split(".").pop().toLowerCase();
          const fileName = uuidv4() + "." + extension;

          formData.append("Attachment", file);
          formData.append("FileNameWithExtension", fileName);
          formData.append("FileCategory", "products");

          setVideoUploading(true);

          fetch(`${API_BASE_URL}/blob/api/v1/uploadmedia`, {
            method: "POST",
            headers: {
              Accept: "text/plain",
              Authorization: `Bearer ${accessToken}`,
            },
            body: formData,
          })
            .then((response) => {
              if (!response.ok) {
                if (response.status === 401) {
                  //console.log("Unauthorized access. Redirecting to login page.");
                  logout();
                } else {
                  console.error(`Error: ${response.status} - ${response.statusText}`);
                }
                throw new Error("Network response was not ok");
              }
              return response.text();
            })
            .then((result) => {
              const videoThumbnail = URL.createObjectURL(file);
              const newVideo = fileName;
              updatedVideos.push(newVideo);
              updatedVideosUriList.push({ thumbnailURI: videoThumbnail });
              updatedForms[formIndex].videoList = updatedVideos;
              updatedForms[formIndex].videosUriList = updatedVideosUriList;
              updatedForms[formIndex].isNewfiles = true;
              setForms(updatedForms);

              setVideoUploading(false);
            })
            .catch((error) => {
              console.error("Error uploading file:", error);
              setVideoUploading(false);
            });
        } catch (error) {
          console.error("Error reading file:", error);
        }
      });
    }
  };

  const handleVideoCancel = (index, formIndex) => {
    setForms((prevForms) => {
      const updatedForms = [...prevForms];
      const form = { ...updatedForms[formIndex] };
      const updatedVideos = form.videoList.filter((_, i) => i !== index);
      const updatedVideosThumbnails = form.videosUriList.filter((_, i) => i !== index);

      form.videoList = updatedVideos;
      form.videosUriList = updatedVideosThumbnails;
      updatedForms[formIndex] = form;

      return updatedForms;
    });
  };

  const handleChange = (e, formIndex, fieldIndex) => {
    const { name, value } = e.target;
    const newForms = [...forms];

    if (
      name === "color" ||
      name === "fabric" ||
      name === "size" ||
      name === "price" ||
      name === "sellingPrice" ||
      name === "quantity" ||
      name === "sku"
    ) {
      newForms[formIndex][name] = value;
    } else {
      const form = newForms[formIndex];
      const newFields = [...form.productAttributeList];
      newFields[fieldIndex][name] = value;
      newForms[formIndex].productAttributeList = newFields;
    }

    setForms(newForms);
  };

  const deleteSku = (formIndex) => {
    confirmAlert('', 'Are you sure you want to delete this SKU?', () => { deleteSkuHandler(formIndex) });
  }
  const deleteSkuHandler = (formIndex) => {
    ////console.log("Before filter:", forms);
    const updatedForms = forms.filter((_, index) => index !== formIndex);



    setForms(updatedForms);
    window.scrollTo(0, 0);
  };



  return (
    <div>
      <ToastComponent ref={toastRef} timeout={4000} />

      <form onSubmit={formik.handleSubmit}>
        {/* <div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => setTab('basic')}>Back to Basic Details</div> */}
        <div  onClick={() => setTab('basic')} style={{cursor:'pointer', display:'flex', alignItems:'center'}}>
              <CaretLeft /> Back to basic details</div>
        {forms?.map((form, formIndex) => (
          <div key={formIndex} className="add-product-by-seller-form">
            <div className="seller-product-sku-delete-icon-container"><DeleteOutline onClick={() => { deleteSku(formIndex) }} className="seller-product-sku-delete-icon" style={{ width: "2rem", height: "2rem" }} /></div>

            <div className="add-product-by-seller-field">
              <div className="add-product-by-seller-field-name">Color</div>
              <div className="add-product-by-seller-field-textfield">
                <TextField
                  fullWidth
                  type="text"
                  name="color"
                  placeholder="Color"
                  value={form.color}
                  onChange={(e) => {
                    handleChange(e, formIndex);
                    // formik.setFieldValue("color", e.target.value);
                  }}
                  error={!/^[a-zA-Z,& ]{0,100}$/.test(form.color)}
                />
              </div>
            </div>
            <div className="add-product-by-seller-field">
              <div className="add-product-by-seller-field-name">Material</div>
              <div className="add-product-by-seller-field-textfield">
                <TextField
                  fullWidth
                  type="text"
                  name="fabric"
                  placeholder="Fabric"
                  value={form.fabric}
                  onChange={(e) => {
                    handleChange(e, formIndex);
                    // formik.setFieldValue("fabric", e.target.value);
                  }}
                  error={!/^[a-zA-Z,& ]{0,100}$/.test(form.fabric)}
                />
              </div>
            </div>
            <div className="add-product-by-seller-field-single-line-inputs">
              <div className="add-product-by-seller-field-single-line-input-field">
                <div className="add-product-by-seller-field-name">Size</div>
                <div className="add-product-by-seller-field-single-line-input-field-textfield">
                  <TextField
                    // fullWidth
                    outlined
                    type="text"
                    name="size"
                    value={form.size}
                    onChange={(e) => handleChange(e, formIndex)}
                  />
                </div>
              </div>
              <div className="add-product-by-seller-field-single-line-input-field">
                <div className="add-product-by-seller-field-name">Price (₹) *</div>
                <div className="add-product-by-seller-field-single-line-input-field-textfield">
                  <TextField
                    // fullWidth
                    outlined
                    type="text"
                    name="price"
                    value={form.price}
                    onChange={(e) => {
                      handleChange(e, formIndex);
                      // formik.setFieldValue("price", e.target.value);
                    }}
                    error={!/^[1-9]\d*$/.test(form.price)}
                  // error={Boolean(priceError)}
                  />
                </div>
              </div>
              <div className="add-product-by-seller-field-single-line-input-field">
                <div className="add-product-by-seller-field-name">
                  Selling Price  (₹)*
                </div>
                <div className="add-product-by-seller-field-single-line-input-field-textfield">
                  <TextField
                    // fullWidth
                    outlined
                    type="text"
                    name="sellingPrice"
                    value={form.sellingPrice}
                    // error={Boolean(quantityError)}
                    onChange={(e) => {
                      handleChange(e, formIndex);
                      // formik.setFieldValue("sellingPrice", e.target.value);
                    }}
                    error={!/^[1-9]\d*$/.test(form.sellingPrice)}
                  />
                </div>
              </div>
              <div className="add-product-by-seller-field-single-line-input-field">
                <div className="add-product-by-seller-field-name">Quantity*</div>
                <div className="add-product-by-seller-field-single-line-input-field-textfield">
                  <TextField
                    // fullWidth
                    outlined
                    type="text"
                    name="quantity"
                    value={form.quantity}
                    // error={Boolean(quantityError)}
                    onChange={(e) => {
                      handleChange(e, formIndex);
                      // formik.setFieldValue("quantity", e.target.value);
                    }}
                    error={!/^[1-9]\d*$/.test(form.quantity)}
                  />
                </div>
              </div>
            </div>

            <div className="add-product-by-seller-field-single-line-inputs">
              <div className="add-product-by-seller-field-single-line-input-field">
                <div className="add-product-by-seller-field-name">Discount</div>
                <div className="add-product-by-seller-field-single-line-input-field-textfield">
                  <TextField
                    // fullWidth
                    outlined
                    type="text"
                    name="discount"
                    value={Math.floor(
                      ((form.price - form.sellingPrice) / form.price) * 100
                    )}
                    onChange={(e) => handleChange(e, formIndex)}
                    disabled
                  />
                </div>
              </div>

              <div className="add-product-by-seller-field">
                <div className="add-product-by-seller-field-name">SKU</div>
                <div className="add-product-by-seller-field-textfield">
                  <TextField
                    fullWidth
                    outlined
                    type="text"
                    name="sku"
                    value={form.sku}
                    onChange={(e) => handleChange(e, formIndex)}
                    // error={Boolean(quantityError)}
                    disabled
                  />
                </div>
              </div>
            </div>

            {formIndex > 0 && (
              <div className="add-product-by-seller-field-add-img-checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={prevImages[formIndex]}
                    onChange={(event) => handlePrevImagesChange(event, formIndex)}
                    style={{ width: "1.4rem", height: "1.4rem" }}
                  />
                  Import images from the previous variant
                </label>
              </div>
            )}
            {formIndex === 0 && (
              <div className="add-product-image-field">
                <div className="add-product-by-seller-image-field">
                  <Dropzone
                    className="seller-drag-image-field"
                    onDrop={(acceptedFiles) =>
                      handleImageDrop(acceptedFiles, formIndex)
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input
                          className="seller-drag-image-field"
                          {...getInputProps({
                            accept: "image/png, image/jpeg, image/jpg",
                          })}
                        />
                        <p className="add-product-image-add-text">
                          <img loading="lazy" src={ImageUploderImage} alt="" />
                          <p>Drop your images here or, select click to Browse</p>
                          {!uploading && <button
                            className="common-btn"
                            style={{ position: "relative", top: "50%" }}
                          >
                            Upload Images
                          </button>}

                          {uploading ? (
                            <CircularProgress animation="border" role="status" style={{ color: 'pink' }}>
                              <span className="sr-only">Loading...</span>
                            </CircularProgress>
                          ) : (
                            <p></p>
                          )}
                        </p>
                      </div>
                    )}
                  </Dropzone>
                  <ul className="seller-products-mediaList">
                    {!prevImages[formIndex] &&
                      form?.imageList?.map((image, index) => (
                        <li key={index}>
                          <img
                            src={form?.imagesUriList?.[index]?.thumbnailURI}
                            alt="thumb"
                            style={{ maxWidth: "100px" }}
                          />
                          <button
                            className="cancel-button"
                            type="button"
                            onClick={() => handleImageCancel(index, formIndex)}
                          >
                            Cancel
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="add-product-by-seller-common-img-rules">
                  <div>
                    NOTE: You have to add one image at the least so that your product can be displayed.
                    You can add up to  10 images.
                    Image size cannot exceed 5MB.
                  </div>
                </div>
              </div>
            )}
            {formIndex !== 0 && (
              <div className="add-product-image-field">
                <div className="add-product-by-seller-image-field">
                  {!prevImages?.[formIndex] && (
                    <Dropzone
                      className="seller-drag-image-field"
                      onDrop={(acceptedFiles) =>
                        handleImageDrop(acceptedFiles, formIndex)
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input
                            className="seller-drag-image-field"
                            {...getInputProps({
                              accept: "image/png, image/jpeg, image/jpg",
                            })}
                          />
                          <p className="add-product-image-add-text">
                            {!uploading && <button
                              className="common-btn"
                              style={{ position: "relative", top: "50%" }}
                            >
                              Uplod Images
                            </button>}

                            {uploading ? (
                              <CircularProgress animation="border" role="status" style={{ color: 'pink' }}>
                                <span className="sr-only">Loading...</span>
                              </CircularProgress>
                            ) : (
                              <p></p>
                            )}
                          </p>
                        </div>
                      )}
                    </Dropzone>
                  )}
                  <ul className="seller-products-mediaList">
                    {
                      form?.imageList?.map((image, index) => (
                        <li key={index}>
                          <img
                            src={form?.imagesUriList?.[index]?.thumbnailURI}
                            alt="thumb"
                            style={{ maxWidth: "100px" }}
                          />
                          <button
                            className="cancel-button"
                            type="button"
                            onClick={() => handleImageCancel(index, formIndex)}
                          >
                            Cancel
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="add-product-by-seller-common-img-rules">
                  <div>
                    NOTE: You have to add one image at the least so that your product can be displayed.
                    You can add up to  10 images.
                    Image size cannot exceed 5MB.
                  </div>
                </div>
              </div>
            )}

            {formIndex > 0 && videoAllowed && (
              <div className="add-product-by-seller-field-add-img-checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={prevVideos[formIndex]}
                    onChange={(event) => handlePrevVideoChange(event, formIndex)}
                    style={{ width: "1.4rem", height: "1.4rem" }}
                  />
                  Import videos from the previous variant
                </label>
              </div>
            )}
            {formIndex === 0 && videoAllowed && (
              <div className="add-product-image-field">
                <div className="add-product-by-seller-image-field">
                  <Dropzone
                    className="seller-drag-image-field"
                    onDrop={(acceptedFiles) => handleVideoDrop(acceptedFiles, formIndex)}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input
                          className="seller-drag-image-field"
                          {...getInputProps({
                            accept: "video/mp4, video/quicktime, video/3gpp",
                          })}
                        />
                        <p className="add-product-image-add-text">
                          <img loading="lazy" src={ImageUploderImage} alt="" />
                          <p>Drop your videos here or click to Browse</p>
                          {!videoUploading && <button
                            className="common-btn"
                            style={{ position: "relative", top: "50%" }}
                          >
                            Upload Videos
                          </button>}

                          {videoUploading ? (
                            <CircularProgress animation="border" role="status" style={{ color: 'pink' }}>
                              <span className="sr-only">Loading...</span>
                           </CircularProgress>
                          ) : (
                            <p></p>
                          )}
                        </p>
                      </div>
                    )}
                  </Dropzone>
                  <ul className="seller-products-mediaList">
                    {!prevVideos[formIndex] &&
                      form?.videoList?.map((video, index) => (
                        <li key={index}>
                          <video controls style={{ maxWidth: "100px" }}>
                            <source src={form?.videosUriList?.[index]?.thumbnailURI} type="video/mp4" />
                          </video>
                          <button
                            className="cancel-button"
                            type="button"
                            onClick={() => handleVideoCancel(index, formIndex)}
                          >
                            Cancel
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="add-product-by-seller-common-img-rules">
                  <div>
                    NOTE: You have to add one video at least so that your product can be displayed.
                    You can add only one video.
                    Video size cannot exceed 40MB.
                  </div>
                </div>
              </div>
            )}

            {formIndex !== 0 && videoAllowed && (
              <div className="add-product-image-field">
                <div className="add-product-by-seller-image-field">
                  {!prevVideos?.[formIndex] && (
                    <Dropzone
                      className="seller-drag-image-field"
                      onDrop={(acceptedFiles) =>
                        handleVideoDrop(acceptedFiles, formIndex)
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input
                            className="seller-drag-image-field"
                            {...getInputProps({
                              accept: "video/mp4, video/quicktime, video/3gpp",
                            })}
                          />
                          <p className="add-product-image-add-text">
                            {!videoUploading && <button
                              className="common-btn"
                              style={{ position: "relative", top: "50%" }}
                            >
                              Upload Videos
                            </button>}

                            {videoUploading ? (
                              <CircularProgress animation="border" role="status" style={{ color: 'pink' }}>
                                <span className="sr-only">Loading...</span>
                              </CircularProgress>
                            ) : (
                              <p></p>
                            )}
                          </p>
                        </div>
                      )}
                    </Dropzone>
                  )}
                  <ul className="seller-products-mediaList">
                    {form?.videoList?.map((video, index) => (
                      <li key={index}>
                        <video controls style={{ maxWidth: "100px" }}>
                          <source
                            src={form?.videosUriList?.[index]?.thumbnailURI}
                            type="video/mp4"
                          />
                        </video>
                        <button
                          className="cancel-button"
                          type="button"
                          onClick={() => handleVideoCancel(index, formIndex)}
                        >
                          Cancel
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="add-product-by-seller-common-img-rules">
                  <div>
                    NOTE: You have to add one video at least so that your product can be displayed.
                    You can add only one video.
                    Video size cannot exceed 40MB.
                  </div>
                </div>
              </div>
            )}

            <p className="add-product-by-seller-field">
              You can add multiple values and properties
            </p>
            {form?.productAttributeList?.map((field, fieldIndex) => (
              <div key={fieldIndex}>
                <div className="add-product-key-value-field">
                  <div className="add-product-by-seller-field-textfield">
                    {fieldIndex>3&&<TextField
                      fullWidth
                      type="text"
                      name="a_Key"
                      // onFocus={(event)=>fieldIndex<4&& event.target.blur()}
                      placeholder={
                        field.a_Key === "length" ? 'Length in Centimeters ' :
                          field.a_Key === "width" ? 'Width in Centimeters' :
                            field.a_Key === "height" ? 'Height in Centimeters' :
                              field.a_Key === "weight" ? 'Weight in KiloGrams' : field.a_Key
                      }
                      // onFocus={document.activeElement.blur()}
                      // disabled={field.a_Key === "length" || field.a_Key === "width" || field.a_Key === "height" || field.a_Key === "weight"}
                      value={fieldIndex < 4 ? null : field.a_Key}
                      onChange={(e) => {
                        if (!(field.a_Key === "length" || field.a_Key === "width" || field.a_Key === "height" || field.a_Key === "weight")) {
                          handleChange(e, formIndex, fieldIndex);
                        } else {
                          e.target.value = '';

                        }
                      }}
                    />}
                     {fieldIndex<=3&&<div style={{ width:'220px',  padding:'12px', border:'1px solid #aaa'}}
                  >{
                    field.a_Key === "length" ? 'Length in Centimeters ' :
                      field.a_Key === "width" ? 'Width in Centimeters' :
                        field.a_Key === "height" ? 'Height in Centimeters' :
                          field.a_Key === "weight" ? 'Weight in KiloGrams' : field.a_Key
                  }</div>}
                  </div>
                  <div className="add-product-by-seller-field-textfield">
                    <TextField
                      fullWidth
                      type={`${fieldIndex > 3 ? 'text' : 'number'}`}
                      name="a_Value"
                      placeholder={
                        field.a_Key === "length" ? 'Length Value ' :
                          field.a_Key === "width" ? 'Width Value' :
                            field.a_Key === "height" ? 'Height Value' :
                              field.a_Key === "weight" ? 'Weight Value' : field.a_Key
                      }                      value={field.a_Value}
                      inputProps={{
                        min: 0,
                        onWheel: (e) => e.target.blur(),
                        onInput: (event) => {
                          if (parseFloat(event.target.value) < 0) {
                            event.target.value = 0;
                          }
                          if(event.target.value.startsWith('.')){
                            event.target.value = `0${event.target.value}`
                          }
                        },
                      }}
                      onChange={(e) => handleChange(e, formIndex, fieldIndex)}
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className="add-product-by-seller-field">
              <button
                onClick={() => handleAddField(formIndex)}
                className="common-button"
              >
                Add new field
              </button>
            </div>

          </div>
        ))}
        <div className="products-page-button-container">
          <button className="common-btn" type="button" onClick={addNewForm}>
            Add new Variant
          </button>
          {!loading && <button
            type="button"
            className="common-btn"
            onClick={() => {
              let hasError = false;
              let errorIndex = -1;
              const errorReasons = [];

              for (let i = 0; i < forms?.length; i++) {
                const form = forms[i];

                if (!/^[a-zA-Z,& ]{0,100}$/.test(form?.color)) {
                  errorReasons.push(`Invalid color. 
                
                `);
                }

                if (!/^[a-zA-Z,& ]{0,100}$/.test(form?.fabric)) {
                  errorReasons.push(`Invalid Material .
                
                `);
                }

                if (!/^[1-9]\d*$/.test(form?.price)) {
                  errorReasons.push(`Invalid price .
                `);
                }

                if (!/^[1-9]\d*$/.test(form?.sellingPrice)) {
                  errorReasons.push(`Invalid selling price
                `);
                }

                if (parseInt(form?.sellingPrice) > parseInt(form?.price)) {
                  errorReasons.push(
                    `Selling price should be lesser than price 
                  `
                  );
                }

                if (!/^[1-9]\d*$/.test(form?.quantity)) {
                  errorReasons.push(`Invalid quantity  
                `);
                }

                if (!form?.imageList?.length > 0) {
                  errorReasons.push(
                    `At least one image is required 
                  `
                  );
                }

                if (!(form?.productAttributeList[0].a_Value)) {
                  errorReasons.push(
                    `Integer value is required for Length property 
                  `
                  );
                }
                if (!(form?.productAttributeList[1].a_Value)) {
                  errorReasons.push(
                    `Integer value is required for Width property\
                  `
                  );
                }
                if (!(form?.productAttributeList[2].a_Value)) {
                  errorReasons.push(
                    `Integer value is required for Height property
                  `
                  );
                }
                if (!(form?.productAttributeList[3].a_Value)) {
                  errorReasons.push(
                    `Integer value is required for Weight property 
                  `
                  );
                }

                if (errorReasons.length > 0) {
                  hasError = true;
                  errorIndex = i;
                  break; // Terminate the loop when an error is found
                }
              }

              if (hasError) {
                const errorMessage = `Form at position ${errorIndex + 1} has the following errors:\n${errorReasons.map(reason => `• ${reason}`).join('\n')}`;

                errorAlert('', errorMessage, '', '', 10000, true);
              } else {
                SubmitProductDetails();
              }
            }}
          >
            Add Product
          </button>}
          {loading && <CircularProgress style={{ color: "pink" }} />}
        </div>
      </form>
    </div>
  );
}
