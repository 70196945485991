import React from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Subnav from './Subnav'
import "../styles/Header.css"
import SubsNavbar from './SubsNavbar'

export default function Header() {
  return (
    <div className="header-container">
        <Navbar />
        {/* <SubsNavbar /> */}
        
        <div className="header-filter">
            {/* <Sidebar /> */}
            <Subnav />
        </div>
    </div>
  )
}

