import React from 'react';

function ShippingPolicy() {
  return (
    <div className='terms-condition-content'>
      <h1>Shipping Policy - Myhraki (Seller)</h1>

      <p>
        Thank you for choosing to shop with Myhraki! As a seller, we are committed to providing a seamless and enjoyable shopping experience for our customers on our e-commerce platform.
        This Shipping Policy outlines important information regarding the shipping process and policies for your orders. We kindly ask you to review this policy carefully before placing your order.
      </p>

      <h2>Order Processing:</h2>

      <p>
        As a seller, we strive to process orders promptly to ensure timely delivery to our customers.
        Order processing typically takes 1 to 3 business days. During peak seasons or unforeseen circumstances, processing times may be slightly longer.
        We appreciate your understanding and patience.
        Please note that orders are processed on business days, excluding weekends and public holidays.
      </p>

      <h2>Shipping Methods and Estimated Delivery Times:</h2>

      <p>
        To cater to our customers' needs, we offer various shipping methods. The available shipping methods will be displayed during the checkout process.
        Estimated delivery times are provided based on the selected shipping method and the customer's location. It is important to note that these estimates are approximate, and actual delivery times may vary due to factors beyond our control, such as weather conditions or shipping carrier delays.
        As a seller, we cannot be held responsible for any delays caused by shipping carriers or unforeseen circumstances.
      </p>

      <h2>Shipping Costs:</h2>

      <p>
        Shipping costs are calculated based on the total weight of the order, shipping destination, and the chosen shipping method.
        The applicable shipping costs will be presented to the customer during the checkout process, allowing them to review and confirm their order.
      </p>

      {/* ... Other sections ... */}

      <h2>Order Status and Communication:</h2>

      <p>
        We will provide regular updates to our customers regarding their order's status through email or their Myhraki account on our platform.
        If customers have any questions or concerns regarding their order or shipping, please inform them to reach out to our dedicated customer support team. We are here to assist them.
        By placing an order on our platform, customers acknowledge and agree to comply with the terms and conditions stated in this Shipping Policy.
      </p>
    </div>
  );
}

export default ShippingPolicy;
