import "../styles/SellerPersonalData.css";
import "../styles/Cart.css";

import React, { useState, useEffect, useRef } from "react";
// import VerifiedIcon from "@mui/icons-material/Verified";
import { useFormik } from "formik";
import * as yup from "yup";
import MyhrakiLogo from "../Images/Myhraki_Logo0213.png";
import { Box, CircularProgress, Modal, TextField } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { LinearProgress, MenuItem } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ChevronLeft } from "@mui/icons-material";
import OtpInput from "react-otp-input";
import SellerSubscriptionSelection from "../components/SellerSubscriptionSelection";
// import SellerLayout from "../components/SellerLayout";
import Heading from "../components/Heading";
import { SellerContext } from "../context/SellerContext";
import { useContext } from "react";
// import { useNavigate } from "react-router-dom";
import withSellerAuth from "../context/withSellerAuth";
import verifiedIcon from "../Images/verifiedIcon.png";
import { errorAlert, okAlert } from "../components/Alert";
import SellerLayoutAdjustment from "../components/SellerLayoutAdjustment";
import MyhrakiTextfield from "../components/MyhrakiTextfield";
import { API_BASE_URL } from "../App";
import ToastComponent from "../components/ToastComponent";
import { useNavigate } from "react-router-dom";
import RazorPay from "../Images/razorpay.png";
import PhonePe from "../Images/phonepe.png";
import { Radio } from "@mui/icons-material";
function SellerPersonalData() {
  // const navigate = useNavigate();

  const { sellerData, accessToken, handleAuthExpiry, logout } =
    useContext(SellerContext);
  // ////console.log("SellerData", sellerData);

  const [sellerCurrentData, setSellerCurrentData] = useState();
  const [rejected, setRejected] = useState(false);
  // if (!sellerData) {

  //   ////console.log(sellerData);
  //   navigate("/");
  // };

  const toastRef = useRef();

  const [uploadedImageMSME, setUploadedImageMSME] = useState(null);
  const [uploadedImageCIN, setUploadedImageCIN] = useState(null);
  const [existingImage, setExistingImage] = useState();
  const [aadharLoading, setAadharLoading] = useState(false);
  const [sellerSubscriptionData, setSellerSubscriptionData] = useState();
  // const [aadharResendLoading, setAadharResendLoading] = useState(false);

  function compareArrays(array1, array2) {
    if (array1.length !== array2.length) {
      return false;
    }

    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }

    return true;
  }
  const [completed, setCompleted] = useState(0);
  const [tab, setTab] = useState("aadhar");
  const [fullWidthTabs, setFullWidthTabs] = useState(false);

  const getCurrentImage = async (image) => {
    try {
      const result = await fetch(`${API_BASE_URL}/blob/api/v1/getfile`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-type": "application/json-patch+json",
        },
        method: "POST",
        body: JSON.stringify({
          fileNameWithExtension: [`${image}`],
          fileCategory: "Msme",
        }),
      });
      const response = await result.json();
      ////console.log(response);
      const handleAuthError = handleAuthExpiry(result);
      if (handleAuthError === true) {
        errorAlert("", "Your Session has expired");
        logout();
        return;
      }

      setExistingImage(response?.result[0]?.uri);

      return;
    } catch {
      errorAlert("", "Something went wrong");
    }
  };

  useEffect(() => {
    const getSellerData = async () => {
      try {
        const sellerStatus = await fetch(
          `${API_BASE_URL}/seller/api/v1/GetSellerById&GetAllSeller?SellerId=${sellerData?.result?.userID}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const status = await sellerStatus.json();
        const handleAuthError = handleAuthExpiry(sellerStatus);
        if (handleAuthError === true) {
          errorAlert("", "Your Session has expired");
          logout();
          return;
        }
        ////console.log("PersonalData", status);
        setSellerCurrentData(status);
        if (status?.result?.status) {
          setRejected(true);
        }
      } catch (error) {
        console.error("Error fetching seller data:", error);
      }
    };
    const fetchSubscriptionData = async () => {
      const response = await fetch(
        `${API_BASE_URL}/subscription/api/v1/getall`,
        {
          // mode: "no-cors",
          headers: {
            // 'Accept': 'text/plain',
            // 'Content-Type': 'text/plain',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      setSellerSubscriptionData(data);
      ////console.log(data);
    };
    fetchSubscriptionData();
    getSellerData();
    fetchSellerAddressData();
  }, [sellerData]);

  useEffect(() => {
    if (sellerCurrentData?.result?.referenceNumber) {
      getCurrentImage(sellerCurrentData?.result?.referenceNumber);
    }
  }, [sellerCurrentData]);

  const aadharRegExp =
    /(^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$)|(^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$)|(^[2-9]{1}[0-9]{3}-[0-9]{4}-[0-9]{4}$)/;

  const panRegExp = /^[A-Za-z]{5}\d{4}[A-Za-z]$/;
  const gstRegExp =
    /^([0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[zZ]{1}[A-Za-z0-9]{1})$/;
  const bankAccountNumberRegExp = /^[0-9]{9,18}$/;
  const bankIfscRegExp = /([A-Za-z]{4}0[A-Za-z0-9]{6}$)/;

  const fullNameRegExp =
    /^(?!.*\\s{2})(?!.*[.'-]{2})[A-Za-z][A-Za-z' ]{0,48}[A-Za-z]$/;
  const companyNameRegExp = /^[A-Za-z0-9\s.&()\/\-']{1,200}$/;
  const companyAddressRegExp = /^[A-Za-z0-9\s.,&()\/#-' ]{1,200}$/;
  const cityRegExp = /^[A-Za-z\s.'-]{1,50}$/;
  const stateRegExp = /^[A-Za-z\s.'-]{1,50}$/;
  const pincodeRegExp = /^\d{6}$/;
  const companyBuildingRegExp = /^\d+[A-Za-z]?\s?[/|-]?\d*[A-Za-z]?$/;

  const validationSchemaAadhar = yup.object({
    aadhar: yup
      .string("Enter your Aadhar number")
      .trim()
      .matches(aadharRegExp, "Aadhar number is not valid")
      .min(12, "Aadhar Number should be of minimum 12 characters")
      .required("Aadhar Number is required"),
  });

  const validationSchemaForPanGST = yup.object({
    pan: yup
      .string("Enter your Pan number")
      .trim()
      .matches(panRegExp, "Pan number is not valid")
      .min(10, "PAN should be of minimum 10 characters")
      .required("PAN Number is required"),

    gst: yup
      .string("Enter your GST number")
      .trim()
      .matches(gstRegExp, "Gst number is not valid")
      .test("valid-gst", "GST number is not valid", (value) => {
        if (!value) {
          return true; // allow empty value
        }
        return gstRegExp.test(value);
      }),

    companyName: yup
      .string("Enter Company Name")
      .trim()
      .matches(companyNameRegExp, "Enter valid company name")
      .required("Company's name is required")
      .min(1, "Company's Name should be of minimum 1 characters")
      .max(200, "Company's Name should not exceed 200 characters"),
    companyBuildingNo: yup
      .string("Enter Company Building no")
      .trim()
      .matches(companyBuildingRegExp, "Enter valid Building number")
      .required("Company Building no is required"),
    // .min(2, "Company's Name should be of minimum 2 characters"),
    companyAddress: yup
      .string("Enter Company Address")
      .matches(companyAddressRegExp, "Enter valid company name")
      .required("Company Address is required")
      .max(500, "Company's Address should not exceed 500 characters"),
    city: yup
      .string("Enter Company City")
      .trim()
      .matches(cityRegExp, "Enter valid company city")
      .required("Company's City is required"),
    // .min(2, "Company's State should be of minimum 2 characters"),
    state: yup
      .string("Enter Company State")
      .trim()
      .matches(stateRegExp, "Enter valid company State")
      .required("Company's State is required"),
    // .min(2, "Company's State should be of minimum 2 characters"),
    pincode: yup
      .string("Enter Company Pincode")
      .trim()
      .matches(pincodeRegExp, "Enter valid Company Pincode")
      .required("Company's Pincode is required"),
    // .min(2, "Company's pincode should be of minimum 6 digits"),
  });

  const validationSchemaBusiness = yup.object({
    accountNumber: yup
      .string("Enter your Account number")
      .trim()
      .matches(bankAccountNumberRegExp, "Invalid Account number")
      //   .min(12, "Aadhar Number should be of minimum 12 characters length")
      .required("Account Number is required"),
    ifsc: yup
      .string("Enter your ISFC Code")
      .trim()
      .matches(bankIfscRegExp, "IFSC code is not valid")
      .max(11, "Enter Valid IFSC")
      .required("Ifsc code is required"),
    name: yup
      .string("Enter Your Name")
      .trim()
      .matches(fullNameRegExp, "Invalid name.")
      .required("Account Holder's name is required")
      .min(1, "Account Holder's Name should be minimum 2 characters"),
    branchName: yup
      .string("Enter Branch Name")
      .trim()
      .matches(fullNameRegExp, "Invalid Branch name.")
      .required("Branch name is required")
      .min(1, "Branch Name should be minimum 2 characters")
      .max(200, "Branch Name should not exceed 200 characters"),

    accountType: yup.string("Please select a Bank Account type"),
  });

  // const validationSchemaMsme = yup.object().shape({
  //   image: yup.mixed().required("File is required"),
  // });

  // const validationSchemaSellerSubscriptionPlan=yup.object({
  //   plan:yup.required("Select a plan to proceed")
  // })

  const formik = useFormik({
    initialValues: {
      aadhar: sellerCurrentData?.result?.aadharNumber
        ? sellerCurrentData?.result?.aadharNumber
        : "",
    },
    validationSchema: validationSchemaAadhar,
    onSubmit: async () => {
      const exists = await postAadharForOtp();
      if (exists === true) {
        showOtpField();
      }
      // verifyOtp();
    },
  });

  const [aadharPredefined, setAadharPredefined] = useState();

  useEffect(() => {
    if (sellerCurrentData?.result?.aadharNumber) {
      formik.setValues({
        aadhar: sellerCurrentData.result.aadharNumber,
      });

      setAadharPredefined(sellerCurrentData.result.aadharNumber);
      setAadharVerified(true);

      formikPanGst.setValues({
        pan: sellerCurrentData.result.panNumber?.toUpperCase(),
        gst: sellerCurrentData.result.gstNumber?.toUpperCase(),
        companyName: sellerCurrentData.result.companyName,
        ...formikPanGst.values,
      });
      formikBusiness.setValues({
        accountNumber: sellerCurrentData?.result?.accountNumber,
        ifsc: sellerCurrentData?.result?.bankIFSC,
        name: sellerCurrentData?.result?.accountHolderName,
        branchName: sellerCurrentData?.result?.branchCode,
        accountType: sellerCurrentData?.result?.typeOfAccount,
      });
      formikMsme.setValues({
        image: sellerCurrentData?.result?.referenceNumber,
      });
      setAadharVerified(true);
    }
  }, [sellerCurrentData]);

  const [error, setError] = useState(false);
  const [code, setCode] = useState("");
  const [invalidCode, setInvalidCode] = useState(false);
  const handleOtpChange = (code) => setCode(code);

  const [openPayModal, setOpenPayModal] = useState(false);
  const handleOpenPayModal = () => {
    setOpenPayModal(true);
    setPaymentLoading(false);
  };
  const handleClosePayModal = () => {
    setOpenPayModal(false);
    setPaymentLoading(true);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "white",
    // border: "2px solid #000"/,
    boxShadow: 24,
    p: 4,
  };

  const verifyOtp = async () => {
    if (code.length !== 6) {
      ////console.log(code.length);
      setError(true);
    } else {
      const result = await requestOtpVerifyAadhar(code);
      ////console.log(result);
      if (result?.result?.success) {
        setTab("pan");
        setCompleted(20);
        //document.getElementById("step-1-dot").classList.add("completed_step");
        //document.getElementById("step-1-dot").innerText = "✔";
        //document.getElementById("step-2-dot").style.backgroundColor =
        // "rgba(255, 208, 216, 1)";

        //document.getElementById("step-1-line").classList.add("completed_step");
        ////console.log("abc");
        setInvalidCode(false);
        setOtpField(false);
        setAadharVerified(true);
        setAadharPredefined(formik.values.aadhar);
        goToPanDetails();
      } else {
        setInvalidCode(true);
      }
    }
  };
  const [validCode, setValidCode] = useState(false);
  useEffect(() => {
    if (code.length === 6) {
      setValidCode(true);
    } else {
      setValidCode(false);
    }
  }, [code]);

  const showOtpField = () => {
    setCode("");
    setOtpField(true);
  };

  useEffect(() => {
    ////console.log(aadharPredefined=== formik.values.aadhar)

    if (aadharPredefined === formik.values.aadhar) {
      setAadharVerified(true);
      setOtpField(false);
    } else {
      setAadharVerified(false);
    }
  }, [formik.values.aadhar, sellerCurrentData?.result?.aadharNumber]);

  const resendOtp = () => {
    setCode("");
    postAadharForOtp();
    ////console.log("resend");
  };
  const [aadharVerified, setAadharVerified] = useState(false);
  const [clientId, setClientId] = useState();

  async function postAadharForOtp() {
    setAadharLoading(true);
    try {
      let result = await fetch(
        `${API_BASE_URL}/seller/api/v1/AadharNumbervalidation`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            selllerId: sellerData?.result?.userID,
            aadharNumber: formik.values.aadhar.trim(),
          }),
        }
      );
      setAadharLoading(false);

      const response = await result.json();
      const handleAuthError = handleAuthExpiry(result);
      if (handleAuthError === true) {
        errorAlert("", "Your Session has expired");
        logout();
        return;
      }
      if (response?.status === 400) {
        toastRef.current.showToast(
          "Something went wrong, please try again.",
          "warning"
        );
        return;
      }
      if (response?.result?.success === true) {
        // successAlert('', 'OTP has been sent.')
        toastRef.current.showToast("OTP has been sent.", "success");
        setClientId(JSON.parse(response?.result?.result)?.data?.client_id);
        return true;
      } else {
        toastRef.current.showToast(
          "Could not Send OTP. Please Try Again.",
          "error"
        );
      }
    } catch (e) {
      setAadharLoading(false);
      // errorAlert('', 'Could not Send OTP. Please Try Again.')
      toastRef.current.showToast(
        "Could not Send OTP. Please Try Again.",
        "error"
      );
      ////console.log(e);
    }
  }

  async function requestOtpVerifyAadhar(code) {
    setAadharLoading(true);

    try {
      let result = await fetch(
        `${API_BASE_URL}/seller/api/v1/AadharNumbervalidation`,
        {
          method: "post",
          headers: {
            accept: "text/plain",
            "Content-type": "application/json-patch+json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            selllerId: sellerData?.result?.userID,
            otp: code,
            aadharNumber: formik.values.aadhar.trim(),
            client_id: clientId,
          }),
        }
      );
      setAadharLoading(false);

      const response = await result.json();
      const handleAuthError = handleAuthExpiry(result);
      if (handleAuthError === true) {
        errorAlert("", "Your Session has expired");
        logout();
        return;
      }
      ////console.log(response);
      return response;
    } catch (e) {
      ////console.log(e);
      setAadharLoading(false);
    }
  }

  async function postPanGst() {
    try {
      let result = await fetch(`${API_BASE_URL}/seller/api/v1/GST/PAN`, {
        method: "post",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          sellerId: sellerData?.result?.userID,
          gstNumber: formikPanGst?.values.gst
            ? formikPanGst.values.gst.toUpperCase().trim()
            : "",
          panNumber: formikPanGst?.values.pan
            ? formikPanGst.values.pan.toUpperCase().trim()
            : "",
        }),
      });

      const response = await result.json();
      const handleAuthError = handleAuthExpiry(result);
      if (handleAuthError === true) {
        errorAlert("", "Your Session has expired");
        logout();
        return;
      }
      ////console.log(response);
    } catch (e) {
      ////console.log(e);
    }
  }

  const [sellerAddressData, setSellerAddressData] = useState();
  const [currentAddressId, setCurrentAddressId] = useState();

  const fetchSellerAddressData = async () => {
    const response = await fetch(
      `${API_BASE_URL}/address/api/v1/getAll?userId=${sellerData?.result?.userID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = await response.json();
    const handleAuthError = handleAuthExpiry(response);
    if (handleAuthError === true) {
      errorAlert("", "Your Session has expired");
      logout();
      return;
    }
    setSellerAddressData(data);
    setCurrentAddressId(data?.result?.[0]?.id);

    ////console.log("Seller Address Data:", data?.result);
  };

  async function clearAddresses() {
    console.log("Called");
    try {
      await sellerAddressData?.result?.forEach((item) =>
        handleDeleteAddress(item.id)
      );
      //    fetchCartData();
    } catch {
      errorAlert("", "Could not clear cart");
    }
  }

  const handleDeleteAddress = async (id) => {
    // setLoading(true)
    try {
      const response = await fetch(
        `${API_BASE_URL}/address/api/v1/${id}?userId=${sellerData?.result?.userID}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      //  setLoading(false);
      if (response.ok) {
        //  toastRef.current.showToast("Address deleted Successfully", "success");
        //  props.fetchAddressData();
      } else {
        //  setLoading(false)
        //  toastRef.current.showToast("Could not delete address", "error");
      }
    } catch (error) {
      //  setLoading(false)
      //  toastRef.current.showToast("Could not delete address", "error");
    }
  };

  const postAddress = async () => {
    if (sellerAddressData?.result?.[0]?.id) {
      await clearAddresses();
    }

    try {
      const requestBody = {
        userID: sellerCurrentData?.result?.sellerId,
        email: sellerData?.result?.email,
        contactName: sellerCurrentData?.result?.sellerName,
        phoneNumber:
          sellerCurrentData?.result?.sellerMobileNumber?.substring(3),
        addressLine1: formikPanGst.values.companyBuildingNo,
        addressLine2: formikPanGst.values.companyAddress,
        city: formikPanGst.values.city,
        pincode: formikPanGst.values.pincode,
        state: formikPanGst.values.state,
        country: "India",
        addressType: "Office",
        countryCode: "+91",
        isDefault: true,
        IsSellerAddress: true,
      };

      const response = await fetch(`${API_BASE_URL}/address/api/v1/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        errorAlert("", "Your Session has expired");
        logout();
        return;
      }
      if (response.ok) {
        // successAlert("Address added sucessfully");
        // props.fetchAddressData();
        fetchSellerAddressData();

        // handleModalClose();
      } else {
        console.error("Failed to add address.");
      }
    } catch (error) {
      console.error("Error adding address:", error);
    }
  };

  useEffect(() => {
    if (sellerAddressData?.result?.[0]?.id)
      formikPanGst.setValues({
        companyBuildingNo: sellerAddressData.result[0].addressLine1,
        companyAddress: sellerAddressData.result[0].addressLine2,
        state: sellerAddressData.result[0].state,
        city: sellerAddressData.result[0].city,
        pincode: sellerAddressData.result[0].pincode,
        gst: formikPanGst.values.gst
          ? formikPanGst.values.gst?.toUpperCase()
          : sellerCurrentData?.result?.gstNumber
          ? sellerCurrentData?.result?.gstNumber.toUpperCase()
          : "",
        pan: formikPanGst.values.pan
          ? formikPanGst.values.pan?.toUpperCase()
          : sellerCurrentData?.result?.panNumber
          ? sellerCurrentData?.result?.panNumber.toUpperCase()
          : "",
        companyName: formikPanGst.values.companyName
          ? formikPanGst.values.companyName
          : sellerCurrentData?.result?.companyName
          ? sellerCurrentData?.result?.companyName
          : "",
      });
  }, [sellerAddressData, sellerCurrentData]);

  // const token = localStorage.getItem("token");
  useEffect(() => {
    ////console.log("test");
    if (sellerCurrentData) {
      // ////console.log(sellerCurrentData)
      if (!sellerCurrentData?.result?.aadharNumber) {
        setTab("aadhar");
      } else if (
        !sellerCurrentData?.result?.panNumber ||
        !sellerCurrentData?.result?.companyName ||
        !sellerCurrentData?.result?.companyAddress ||
        !sellerCurrentData?.result?.pan_HolderName
      ) {
        setTab("pan");
        setCompleted(20);
        //document.getElementById("step-1-dot").classList.add("completed_step");
        //document.getElementById("step-1-dot").innerText = "✔";
        //document.getElementById("step-2-dot").style.backgroundColor =
        // "rgba(255, 208, 216, 1)";
        //document.getElementById("step-1-line").classList.add("completed_step");
        setAadharVerified(true);
      } else if (
        !sellerCurrentData?.result?.accountNumber ||
        !sellerCurrentData?.result?.accountHolderName ||
        !sellerCurrentData?.result?.bankIFSC ||
        !sellerCurrentData?.result?.branchCode ||
        !sellerCurrentData?.result?.typeOfAccount
      ) {
        setTab("business");
        //document.getElementById("step-1-dot").classList.add("completed_step");
        //document.getElementById("step-1-dot").innerText = "✔";
        //document.getElementById("step-2-dot").style.backgroundColor =
        // "rgba(255, 208, 216, 1)";

        //document.getElementById("step-1-line").classList.add("completed_step");
        //document.getElementById("step-2-dot").classList.add("completed_step");
        //document.getElementById("step-2-line").classList.add("completed_step");
        //document.getElementById("step-2-dot").innerText = "✔";
        //document.getElementById("step-3-dot").style.backgroundColor =
        // "rgba(255, 208, 216, 1)";
        setCompleted(40);
        setAadharVerified(true);
      } else if (
        !sellerCurrentData?.result?.referenceNumber &&
        !sellerCurrentData?.result?.sellerPlanName
      ) {
        setTab("certificates");
        //document.getElementById("step-1-dot").classList.add("completed_step");
        //document.getElementById("step-1-dot").innerText = "✔";
        //document.getElementById("step-2-dot").style.backgroundColor =
        // "rgba(255, 208, 216, 1)";

        //document.getElementById("step-1-line").classList.add("completed_step");
        //document.getElementById("step-2-dot").classList.add("completed_step");
        //document.getElementById("step-2-line").classList.add("completed_step");
        //document.getElementById("step-2-dot").innerText = "✔";
        //document.getElementById("step-3-dot").style.backgroundColor =
        // "rgba(255, 208, 216, 1)";
        //document.getElementById("step-3-dot").classList.add("completed_step");
        //document.getElementById("step-3-line").classList.add("completed_step");
        //document.getElementById("step-3-dot").innerText = "✔";
        //document.getElementById("step-4-dot").style.backgroundColor =
        // "rgba(255, 208, 216, 1)";
        setCompleted(60);
        setAadharVerified(true);
      } else if (!sellerCurrentData?.result?.sellerPlanName) {
        setTab("confirmDetails");
        //document.getElementById("step-1-dot").classList.add("completed_step");
        //document.getElementById("step-1-dot").innerText = "✔";
        //document.getElementById("step-2-dot").style.backgroundColor =
        // "rgba(255, 208, 216, 1)";

        //document.getElementById("step-1-line").classList.add("completed_step");
        //document.getElementById("step-2-dot").classList.add("completed_step");
        //document.getElementById("step-2-line").classList.add("completed_step");
        //document.getElementById("step-2-dot").innerText = "✔";
        //document.getElementById("step-3-dot").style.backgroundColor =
        // "rgba(255, 208, 216, 1)";
        //document.getElementById("step-3-dot").classList.add("completed_step");
        //document.getElementById("step-3-line").classList.add("completed_step");
        //document.getElementById("step-3-dot").innerText = "✔";
        //document.getElementById("step-4-dot").style.backgroundColor =
        // "rgba(255, 208, 216, 1)";
        //document.getElementById("step-4-dot").classList.add("completed_step");
        //document.getElementById("step-4-line").classList.add("completed_step");
        //document.getElementById("step-4-dot").innerText = "✔";
        //document.getElementById("step-5-dot").style.backgroundColor =
        // "rgba(255, 208, 216, 1)";
        setFullWidthTabs(true);
        setCompleted(80);
        setAadharVerified(true);
      } else {
        setAadharVerified(true);
        setTab("pending");
      }
    }
  }, [sellerCurrentData]);

  async function postSellerPersonalData() {
    ////console.log(sellerData);

    if (sellerCurrentData?.result?.status === "P") {
      try {
        // ////console.log(state);
        const data = await fetch(
          `${API_BASE_URL}/seller/api/v1/SellerPersonalDetails`,
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              // id: state.guid,
              // "aadharNumber": formik.values.aadhar ? formik.values.aadhar :sellerCurrentData?.result?.aadhar?sellerCurrentData?.result?.aadhar: "",
              panNumber: formikPanGst.values.pan
                ? formikPanGst.values.pan.toUpperCase().trim()
                : sellerCurrentData?.result?.panNumber
                ? sellerCurrentData?.result?.panNumber.toUpperCase()
                : "",
              gstNumber: formikPanGst.values.gst
                ? formikPanGst.values.gst.toUpperCase().trim()
                : sellerCurrentData?.result?.gstNumber
                ? sellerCurrentData?.result?.gstNumber.toUpperCase()
                : "",
              companyName: formikPanGst.values.companyName
                ? formikPanGst.values.companyName
                : "",
              companyAddress: currentAddressId
                ? currentAddressId
                : sellerAddressData?.result[0]?.id
                ? sellerAddressData?.result[0]?.id
                : "",
              accountNumber: formikBusiness.values.accountNumber
                ? formikBusiness.values.accountNumber.trim()
                : "",
              bankIFSC: formikBusiness.values.ifsc
                ? formikBusiness.values.ifsc.trim()
                : "",
              accountHolderName: formikBusiness.values.name
                ? formikBusiness.values.name.trim()
                : "",
              branchCode: formikBusiness.values.branchName
                ? formikBusiness.values.branchName.trim()
                : "",
              typeOfAccount: formikBusiness.values.accountType
                ? formikBusiness.values.accountType === "Current Account"
                  ? "Current Account"
                  : "Savings Bank Account"
                : "",
              refrenceNumber: formikMsme.values.image
                ? formikMsme.values.image
                : "",
              sellerId: sellerData?.result?.userID,
            }),
          }
        );
        ////console.log(data);
        const response = await data.json();
        const handleAuthError = handleAuthExpiry(data);
        if (handleAuthError === true) {
          errorAlert("", "Your Session has expired");
          logout();
          return;
        }
        ////console.log(response);
      } catch (e) {
        ////console.log(e);
      }
    } else {
      try {
        // ////console.log(state);
        const data = await fetch(
          `${API_BASE_URL}/seller/api/v1/ModifyDetails`,
          {
            method: "PUT",
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              // id: state.guid,
              aadharNumber: formik.values.aadhar
                ? formik.values.aadhar
                : sellerCurrentData?.result?.aadhar
                ? sellerCurrentData?.result?.aadhar
                : "",
              panNumber: formikPanGst.values.pan
                ? formikPanGst.values.pan.toUpperCase().trim()
                : sellerCurrentData?.result?.panNumber
                ? sellerCurrentData?.result?.panNumber?.toUpperCase()
                : "",
              gstNumber: formikPanGst.values.gst
                ? formikPanGst.values.gst.toUpperCase().trim()
                : sellerCurrentData?.result?.gstNumber
                ? sellerCurrentData?.result?.gstNumber?.toUpperCase()
                : "",
              companyName: formikPanGst.values.companyName
                ? formikPanGst.values.companyName?.trim()
                : sellerCurrentData?.result?.companyName
                ? sellerCurrentData?.result?.companyName?.trim()
                : "",
              companyAddress: currentAddressId
                ? currentAddressId
                : sellerAddressData?.result[0]?.id
                ? sellerAddressData?.result[0]?.id
                : "",
              accountNumber: formikBusiness.values.accountNumber
                ? formikBusiness.values.accountNumber?.trim()
                : "",
              bankIFSC: formikBusiness.values.ifsc
                ? formikBusiness.values.ifsc.toUpperCase().trim()
                : "",
              accountHolderName: formikBusiness.values.name
                ? formikBusiness.values.name?.trim()
                : "",
              branchCode: formikBusiness.values.branchName
                ? formikBusiness.values.branchName?.trim()
                : "",
              typeOfAccount: formikBusiness.values.accountType
                ? formikBusiness.values.accountType === "Current Account"
                  ? "Current Account"
                  : "Savings Bank Account"
                : "",
              msmeReferenceNumber: formikMsme.values.image
                ? formikMsme.values.image
                : "",
              sellerPlanName: sellerCurrentData?.result?.sellerPlanName
                ? sellerCurrentData?.result?.sellerPlanName
                : "",
              sellerId: sellerData?.result?.userID,
            }),
          }
        );
        ////console.log(data);
        const response = await data.json();
        const handleAuthError = handleAuthExpiry(data);
        if (handleAuthError === true) {
          errorAlert("", "Your Session has expired");
          logout();
          return;
        }
        ////console.log(response);
      } catch (e) {
        ////console.log(e);
      }
    }
  }

  async function postDraftData() {
    try {
      // ////console.log(state);
      const response = await fetch(
        `${API_BASE_URL}/seller/api/v1/SaveInDraft`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${accessToken}`,

            "Content-type": "application/json",
          },
          body: JSON.stringify({
            // id: state.guid,
            aadharNumber: formik.values.aadhar ? formik.values.aadhar : "",
            panNumber: formikPanGst.values.pan
              ? formikPanGst.values.pan.toUpperCase().trim()
              : "",
            gstNumber: formikPanGst.values.gst
              ? formikPanGst.values.gst.toUpperCase().trim()
              : "",
            companyName: formikPanGst.values.companyName
              ? formikPanGst.values.companyName
              : "",
            companyAddress: currentAddressId
              ? currentAddressId
              : sellerAddressData?.result[0]?.id
              ? sellerAddressData?.result[0]?.id
              : "",
            accountNumber: formikBusiness.values.accountNumber
              ? formikBusiness.values.accountNumber
              : "",
            bankIFSC: formikBusiness.values.ifsc
              ? formikBusiness.values.ifsc
              : "",
            accountHolderName: formikBusiness.values.name
              ? formikBusiness.values.name
              : "",
            branchCode: formikBusiness.values.branchName
              ? formikBusiness.values.branchName
              : "",
            typeOfAccount: formikBusiness.values.accountType
              ? formikBusiness.values.accountType === "Current Account"
                ? "Current Account"
                : "Savings Bank Account"
              : "",
            referenceNumber: formikMsme.values.image
              ? formikMsme.values.image
              : "",
            sellerId: sellerData?.result?.userID,
            sellerPlanName: "",
          }),
        }
      );
      const data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        errorAlert("", "Your Session has expired");
        logout();
        return;
      }
      ////console.log("Drafted Data", data);
    } catch (e) {
      errorAlert("", "Your Session has expired");
      ////console.log(e);
    }
  }

  const formikPanGst = useFormik({
    initialValues: {
      pan: sellerCurrentData?.result?.panNumber
        ? sellerCurrentData?.result?.panNumber?.toUpperCase().trim()
        : "",
      gst: sellerCurrentData?.result?.gstNumber
        ? sellerCurrentData?.result?.gstNumber?.toUpperCase().trim()
        : "",
      companyName: sellerCurrentData?.result?.companyName
        ? sellerCurrentData?.result?.companyName
        : "",
      // companyAddress: sellerAddressData?.result[0]?sellerAddressData?.result[0]?.addressLine1:'',
      // city:sellerAddressData?sellerAddressData?.city:'',
      // state:sellerAddressData?sellerAddressData?.state: '',
      // pincode:sellerAddressData?sellerAddressData?.pincode: ''
      companyBuildingNo: sellerAddressData?.result?.[0]?.addressLine1
        ? sellerAddressData?.result[0]?.addressLine1
        : "",
      companyAddress: sellerAddressData?.result?.[0]?.addressLine2
        ? sellerAddressData?.result[0]?.addressLine2
        : "",
      state: sellerAddressData?.result?.[0]?.state
        ? sellerAddressData?.result[0]?.state
        : "",
      city: sellerAddressData?.result?.[0]?.city
        ? sellerAddressData?.result[0]?.city
        : "",
      pincode: sellerAddressData?.result?.[0]?.pincode
        ? sellerAddressData?.result[0]?.pincode
        : "",
    },
    validationSchema: validationSchemaForPanGST,
    onSubmit: async () => {
      // ////console.log(formikPanGst.initialValues)
      // ////console.log(formikPanGst.values.companyAddress)
      // ////console.log('Checkkkkkkkkk')
      // ////console.log(sellerCurrentData?.result?.pan_HolderName, formikPanGst.values.pan, sellerCurrentData?.result?.panNumber, sellerCurrentData?.result?.gstNumber, formikPanGst.values.gst)
      if (
        !sellerCurrentData?.result?.pan_HolderName ||
        !sellerCurrentData?.result.panNumber ||
        sellerCurrentData?.result.panNumber !== formikPanGst.values.pan ||
        sellerCurrentData?.result?.gstNumber !== formikPanGst.values.gst
      ) {
        // ////console.log("Fetched Pan Holed",Boolean(!sellerCurrentData?.result?.pan_HolderName))
        // ////console.log("Fetched panNumber",Boolean((sellerCurrentData?.result.panNumber !== formikPanGst.values.pan)))
        // ////console.log ("Current Pan number",  Boolean(sellerCurrentData?.result?.gstNumber !== formik.values.gst))
        // ////console.log("fetched GST", Boolean(!sellerCurrentData?.result?.gstNumber))

        postPanGst();
      }
      // ////console.log("BPPPPPPPPPPPPPPPPPPPPPP")
      !sellerAddressData?.result[0]?.id ? postAddress() : postAddress();
      setCompleted(40);
      setTab("business");
      if (rejected) {
        return;
      }

      //document.getElementById("step-2-dot").classList.add("completed_step");
      //document.getElementById("step-2-line").classList.add("completed_step");
      //document.getElementById("step-2-dot").innerText = "✔";
      //document.getElementById("step-3-dot").style.backgroundColor =
      // "rgba(255, 208, 216, 1)";
    },
  });

  useEffect(() => {
    ////console.log(formikPanGst?.values.gst)
  }, [formikPanGst?.values.gst]);

  const formikBusiness = useFormik({
    initialValues: {
      accountNumber: sellerCurrentData?.result?.accountNumber
        ? sellerCurrentData?.result?.accountNumber
        : "",
      ifsc: sellerCurrentData?.result?.bankIFSC
        ? sellerCurrentData?.result?.bankIFSC?.toUpperCase()
        : "",
      name: sellerCurrentData?.result?.accountHolderName
        ? sellerCurrentData?.result?.accountHolderName
        : "",
      branchName: sellerCurrentData?.result?.branchCode
        ? sellerCurrentData?.result?.branchCode
        : "",
      accountType: sellerCurrentData?.result?.typeOfAccount
        ? sellerCurrentData?.result?.typeOfAccount
        : "Current Account",
    },
    validationSchema: validationSchemaBusiness,
    onSubmit: () => {
      setTab("certificates");
      setCompleted(60);
      if (rejected) {
        return;
      }
      //document.getElementById("step-3-dot").classList.add("completed_step");
      //document.getElementById("step-3-line").classList.add("completed_step");
      //document.getElementById("step-3-dot").innerText = "✔";
      //document.getElementById("step-4-dot").style.backgroundColor =
      // "rgba(255, 208, 216, 1)";
    },
  });

  const formikMsme = useFormik({
    initialValues: {
      image: sellerCurrentData?.result?.referenceNumber
        ? sellerCurrentData?.result?.referenceNumber
        : null,
    },
    // validationSchema: validationSchemaMsme,
    onSubmit: () => {
      // if (formikMsme.values.image != null) {
      //   ////console.log(formikMsme.values.image);
      // }
      setCompleted(80);

      setTab("confirmDetails");
      setFullWidthTabs(true);
      if (rejected) {
        return;
      }
      //document.getElementById("step-4-dot").classList.add("completed_step");
      //document.getElementById("step-4-line").classList.add("completed_step");
      //document.getElementById("step-4-dot").innerText = "✔";
      //document.getElementById("step-5-dot").style.backgroundColor =
      // "rgba(255, 208, 216, 1)";
    },
  });

  // const handleMsmeFileUpload = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);

  //   reader.onload = () => {
  //     setImage(reader.result);
  //     // Send image data to server for storage
  //   };
  // };

  // eslint-disable-next-line
  // const [image, setImage] = useState(null);
  const [msmeChoose, setMsmeChoose] = useState(false);
  // const [msmeCapture, setMsmeCapture] = useState(false);
  // const [cinCapture, setCinCapture] = useState(false);
  const [cinChoose, setCinChoose] = useState(false);

  async function confirmDetails() {
    await postSellerPersonalData();
    setCompleted(100);
    setFullWidthTabs(true);
    setTab("sellerSubscription");

    if (rejected) {
      return;
    }
    //document.getElementById("step-5-dot").classList.add("completed_step");
    //document.getElementById("step-5-dot").innerText = "✔";
    ////console.log(accessToken);

    ////console.log("xyz");
    // ////console.log(formikDetails.values);
    // setEditable(false);
  }

  const [otpField, setOtpField] = useState(false);

  function saveAsDraft() {
    postDraftData();
  }

  function goBackToBankDetailsScreen() {
    setTab("business");
  }
  function goBackToAadharScreen() {
    setTab("aadhar");
  }
  function goBackToPanScreen() {
    setTab("pan");
  }
  function goBackToMsmeScreen() {
    setFullWidthTabs(false);

    setTab("certificates");
  }
  function goBackToConfirmScreen() {
    setTab("confirmDetails");
  }

  function goToPanDetails() {
    setTab("pan");
    setCompleted(20);
    setAadharVerified(true);

    if (rejected) {
      return;
    }
    ////console.log(aadharVerified);

    //document.getElementById("step-1-dot").classList.add("completed_step");
    //document.getElementById("step-1-dot").innerText = "✔";
    //document.getElementById("step-2-dot").style.backgroundColor =
    // "rgba(255, 208, 216, 1)";
    //document.getElementById("step-1-line").classList.add("completed_step");
  }
  const [subsPlan, setSubsPlan] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [annualPlanPayment, setAnnualPlanPayment] = useState(true);

  const postBasicPlan = async () => {
    try {
      // ////console.log(state);
      const response = await fetch(
        `${API_BASE_URL}/seller/api/v1/SaveInDraft`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${accessToken}`,

            "Content-type": "application/json",
          },
          body: JSON.stringify({
            // id: state.guid,
            aadharNumber: formik.values.aadhar ? formik.values.aadhar : "",
            panNumber: formikPanGst.values.pan
              ? formikPanGst.values.pan.toUpperCase()
              : "",
            gstNumber: formikPanGst.values.gst
              ? formikPanGst.values.gst.toUpperCase()
              : "",
            companyName: formikPanGst.values.companyName
              ? formikPanGst.values.companyName
              : "",
            companyAddress: currentAddressId
              ? currentAddressId
              : sellerAddressData?.result[0]?.id
              ? sellerAddressData?.result[0]?.id
              : "",
            accountNumber: formikBusiness.values.accountNumber
              ? formikBusiness.values.accountNumber
              : "",
            bankIFSC: formikBusiness.values.ifsc
              ? formikBusiness.values.ifsc
              : "",
            accountHolderName: formikBusiness.values.name
              ? formikBusiness.values.name
              : "",
            branchCode: formikBusiness.values.branchName
              ? formikBusiness.values.branchName
              : "",
            typeOfAccount: formikBusiness.values.accountType
              ? formikBusiness.values.accountType === "Current Account"
                ? "Current Account"
                : "Savings Bank Account"
              : "",
            referenceNumber: formikMsme.values.image
              ? formikMsme.values.image
              : "",
            sellerId: sellerData?.result?.userID,
            sellerPlanName: "Basic",
          }),
        }
      );

      const data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        errorAlert("", "Your Session has expired");
        logout();
        return;
      }
      ////console.log(data);
    } catch (e) {
      ////console.log(e);
    }
  };

  const mapBasicPlan = async () => {
    const check = await checkPlan();

    // const resultPlan= await check.json();

    if (check) {
      ////console.log("plan already exists.")

      setTab("pending");
    } else {
      const response = await fetch(
        `${API_BASE_URL}/subscription/api/v1/mapping/create`,
        {
          method: "POST",

          headers: {
            Authorization: `Bearer ${accessToken}`,

            "Content-Type": "application/json-patch+json",
          },

          body: JSON.stringify({
            sellerId: sellerData?.result?.userID,

            sellerEmailid: sellerData?.result?.email,

            subscriptionId: 1,

            isBillingAnnual: true,

            paymentStatus: true,

            transactionId: "Test1234",
          }),
        }
      );

      const result = await response.json();

      ////console.log(result);
    }
  };

  const checkPlan = async () => {
    const response = await fetch(
      `${API_BASE_URL}/subscription/api/v1/mapping/get/sellerId=${sellerData?.result?.userID}/true/false`,
      {
        method: "get",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json-patch+json",
        },
      }
    );
    const data = await response.json();
    const handleAuthError = handleAuthExpiry(response);
    if (handleAuthError === true) {
      errorAlert("", "Your Session has expired");
      logout();
      return;
    }
    ////console.log(data);
    if (data?.result?.[0]?.Title) {
      return true;
    } else {
      return false;
    }
  };

  const selectPlan = async () => {
    setAadharLoading(true);
    if (subsPlan === "Basic") {
      await postBasicPlan();
      await mapBasicPlan();
      setTab("pending");
      setAadharLoading(false);
    } else {
      purchaseSubscription(selectedPlan);
      // setAadharLoading(true)
      // setPaymentLoading(false);
      // handleOpenPayModal(selectedPlan);
    }
  };

  const existingPlan = () => {
    if (sellerCurrentData?.result?.sellerPlanName) {
      setTab("pending");
      return true;
    }
    // errorAlert('', 'You do not have a Subscription Plan. Please choose one of the above');
    toastRef.current.showToast(
      "You do not have a Subscription Plan. Please choose one of the above.",
      "error"
    );
    return false;
  };

  const [paymentLoading, setPaymentLoading] = useState(false);
  const [phonePeLoading, setPhonePeLoading] = useState(false);
  const [razorPayLoading, setRazorPayLoading] = useState(false);

  const purchaseSubscription = async (selectedPlan) => {
    setAadharLoading(true);
    // okAlert(``,'<h2>Apologies for the inconvenience.</h2><p>Currently, only the <strong>Basic</strong> plan is active. Please select the <strong>Basic</strong> plan for now. The other subscriptions will be available shortly.</p> <p><strong>Thank you for your patience.</strong></p>',()=>{return})
    // return;
    // ////console.log(cartData.cartId)
    setPaymentLoading(false);
    handleOpenPayModal(selectedPlan);
  };

  const phonePePayment = async () => {
    alert("HERE");
    const requestData = {
      userId: sellerData?.result?.userID,
      Name: sellerData?.result?.fullName,
      Email: sellerData?.result?.email,
      PhoneNumber: sellerData?.result?.mobileNumber?.substring(3),
      DeliveryAddress: "string",
      DeliveryPlace: "string",
      DeliveryState: "string",
      DeliveryPincode: "string",
      BillingAddress:
        sellerAddressData?.result[0]?.addressLine1 +
        "" +
        sellerAddressData?.result[0]?.addressLine2,
      BillingPlace: sellerAddressData?.result[0]?.city,
      BillingState: sellerAddressData?.result[0]?.state,
      BillingPincode: sellerAddressData?.result[0]?.pincode,
      PaymentMode: "Online",
      Amount: annualPlanPayment
        ? selectedPlan.annualValue + selectedPlan.annualValue * 0.18
        : selectedPlan.value + selectedPlan.value * 0.18,
      cartId: 0,
      planID: selectedPlan.id,
      shipcost: 0,
      couponID: "string",
      planMonthsCount: annualPlanPayment ? 12 : 1,
    };
    setPhonePeLoading(true);
    const response = await fetch(
      `${API_BASE_URL}/payments/api/v1/PhonepeRequestPayment`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      }
    );
    // ////console.log("Anand", cartData)
    ////console.log("SUMAN", requestData)

    const responseData = await response.json();
    const handleAuthError = handleAuthExpiry(response);
    if (handleAuthError === true) {
      errorAlert("", "Your Session has expired");
      logout();
      setAadharLoading(false);

      return;
    }
    ////console.log(responseData);
    if (responseData && responseData.result) {
      try {
        const resultData = JSON.parse(responseData.result);

        // PHONE PE
        if (
          resultData.data &&
          resultData.data.instrumentResponse &&
          resultData.data.instrumentResponse.redirectInfo
        ) {
          //STRIPE PAYMENT if (resultData && resultData?.url) {
          const redirectURL =
            resultData.data.instrumentResponse.redirectInfo.url;
          ////console.log(redirectURL);
          // Redirect the user to the provided URL
          window.location.href = redirectURL;
          setPhonePeLoading(false);
          setAadharLoading(false);
        } else {
          console.error("Invalid response format or missing redirect URL.");
          // Handle the case where the response does not contain a valid redirect URL.
          setAadharLoading(false);
          setPhonePeLoading(false);
        }
      } catch (error) {
        console.error("Error parsing JSON from result field:", error);
        // Handle any JSON parsing errors that may occur.
        setAadharLoading(false);
        setPhonePeLoading(false);
      }
    } else {
      console.error("Invalid response format or missing result field.");
      // Handle the case where the response does not contain a valid result field.
      setAadharLoading(false);
      setPhonePeLoading(false);
    }
  };
  const navigate = useNavigate();
  const razorPayPayment = async () => {
    const requestData = {
      UserId: sellerData?.result?.userID,
      Name: sellerData?.result?.fullName,
      Email: sellerData?.result?.email,
      PhoneNumber: sellerData?.result?.mobileNumber,
      DeliveryAddress: "string",
      DeliveryPlace: "string",
      DeliveryState: "string",
      DeliveryPincode: "string",
      BillingAddress:
        sellerAddressData?.result[0]?.addressLine1 +
        "" +
        sellerAddressData?.result[0]?.addressLine2,
      BillingPlace: sellerAddressData?.result[0]?.city,
      BillingState: sellerAddressData?.result[0]?.state,
      BillingPincode: sellerAddressData?.result[0]?.pincode,
      PaymentMode: "Online",
      Amount: annualPlanPayment
        ? selectedPlan.annualValue + selectedPlan.annualValue * 0.18
        : selectedPlan.value + selectedPlan.value * 0.18,
      cartId: 0,
      planID: selectedPlan.id,
      shipcost: 0,
      couponID: "string",
      planMonthsCount: annualPlanPayment ? 12 : 1,
      giftCardAmount: 0,
    };

    setRazorPayLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/payments/api/v1/ProcessRequestOrder`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      const responseData = await response.json();
      //console.log(responseData);
      setRazorPayLoading(false);
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        logout();
        return;
      }
      console.log(responseData);
      if (responseData?.result?.orderId) {
        const options = {
          amount: annualPlanPayment
            ? selectedPlan.annualValue + selectedPlan.annualValue * 0.18
            : selectedPlan.value + selectedPlan.value * 0.18,
          currency: "INR",
          name: "Myhraki",
          description: "ordertype",
          image: MyhrakiLogo,
          order_id: responseData?.result?.orderId,
          handler: function (response) {
            console.log(response.razorpay_payment_id);
            console.log(response.razorpay_order_id);
            console.log(response.razorpay_signature);
            // console.log(amt);
            // console.log(Cartid);
            console.log("Payment successful");
            // alert("Congratulations");
            toastRef.current.showToast("Successful Payment", "seccess");
            navigate("/SubscriptionPlans");
          },
          prefill: {
            name: sellerData?.result?.firstName,
            email: sellerData?.result?.email,
            contact: sellerData?.result?.phone,
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#528FF0",
          },
        };

        const rzr = new window.Razorpay(options);
        rzr.on("payment.failed", function (response) {
          //console.log(response.error.code);
          //console.log(response.error.description);
          //console.log(response.error.source);
          //console.log(response.error.step);
          //console.log(response.error.reason);
          //console.log(response.error.metadata.order_id);
          //console.log(response.error.metadata.payment_id);
          alert("Oops! Payment Failed");
        });

        rzr.open();
      }
      // if (responseData && responseData.result) {
      //   try {
      //     const resultData = JSON.parse(responseData.result);

      //     // PHONE PE
      //     if (
      //       resultData.data &&
      //       resultData.data.instrumentResponse &&
      //       resultData.data.instrumentResponse.redirectInfo
      //     ) {
      //       //STRIPE PAYMENT if (resultData && resultData?.url) {
      //       const redirectURL =
      //         resultData.data.instrumentResponse.redirectInfo.url;
      //       // //console.log(redirectURL);
      //       // Redirect the user to the provided URL
      //       setRazorPayLoading(false);

      //       window.location.href = redirectURL;
      //     } else {
      //       setRazorPayLoading(false);

      //       console.error("Invalid response format or missing redirect URL.");
      //       // Handle the case where the response does not contain a valid redirect URL.
      //     }
      //   } catch (error) {
      //     setRazorPayLoading(false);

      //     console.error("Error parsing JSON from result field:", error);
      //     // Handle any JSON parsing errors that may occur.
      //   }

      // }
      // else {
      //   setRazorPayLoading(false);

      //   console.error("Invalid response format or missing result field.");
      // }
    } catch (e) {
      setPhonePeLoading(false);

      //console.log(e)
    }
  };

  const [msmeLoading, setMsmeLoading] = useState();
  const [cinLoading, setCinLoading] = useState();

  const [selectedValue, setSelectedValue] = React.useState("a");

  const handlePayChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <SellerLayoutAdjustment>
      <ToastComponent ref={toastRef} timeout={4000} />
      <>
        <div className="page-content helper-page-content">
          <div className="seller-personal-data-page-content">
            <div className="seller-personal-tabs">
              <div
                className="seller-details-tab"
                style={{
                  width: fullWidthTabs ? "100%" : "80%",
                  // border: "1px solid rgba(112, 112, 112, 0.2)",
                  padding: "1rem auto",
                  // paddingLeft:"2rem",
                  margin: "0rem auto",
                  // borderLeft:fullWidthTabs?"none":"1px solid rgba(0, 0, 0, 0.2)"
                }}
              >
                {tab === "aadhar" && (
                  <div className="seller-aadhar-tab">
                    <form onSubmit={formik.handleSubmit}>
                      <Heading text="Aadhar Details" />
                      <div className="seller-personal-data-form-field">
                        <div className="seller-personal-data-field-name">
                          Aadhar Card Number*:
                        </div>
                        <div style={{ display: "flex", width: "100%" }}>
                          <MyhrakiTextfield
                            autoFocus
                            variant="outlined"
                            autoComplete="off"
                            fullWidth
                            type="text"
                            id="seller-aadhar"
                            name="aadhar"
                            // className="seller-form-field"
                            placeholder="Aadhar Number "
                            value={formik.values.aadhar}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.aadhar &&
                              Boolean(formik.errors.aadhar)
                            }
                            helperText={
                              formik.touched.aadhar && formik.errors.aadhar
                            }
                          />
                        </div>
                        {!otpField && !aadharVerified && !aadharLoading && (
                          <input
                            type="submit"
                            className="common-button"
                            value="Verify"
                          />
                        )}
                      </div>
                      {!otpField && !aadharVerified && aadharLoading && (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress style={{ color: "pink" }} />
                        </div>
                      )}

                      {aadharVerified && (
                        <div className="aadhar-verified-text">
                          <div>You have already Verified Your Aadhar</div>
                          <div>
                            <button
                              className="common-button"
                              onClick={goToPanDetails}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      )}
                    </form>
                    {otpField && (
                      <div className="seller-personal-data-form-field-new-Otp">
                        <div className="seller-personal-data-field-name">
                          {" "}
                          Enter One Time password to continue{" "}
                        </div>
                        <div
                          className="aadhar-otp-numbers"
                          // style={{ position: "relative", right: "0.75rem" }}
                        >
                          {/* <div className="Otp-Head-Aadhar"
                            style={{
                              fontSize: "1.2rem",
                              textAlign: "center",
                              display: "flex",
                              justifyContent: "center",
                              color: rgba(255, 97, 85, 0.1);
                              color: "#FF7891",
                              margin: "auto",
                            }}
                          >
                           
                          </div> */}
                          <OtpInput
                            value={code}
                            onChange={handleOtpChange}
                            numInputs={6}
                            separator={<span style={{ width: "8px" }}></span>}
                            isInputNum={true}
                            shouldAutoFocus={true}
                            className="otp-verify-otp-verify"
                            inputStyle={{
                              border: "1px solid black",
                              // borderRight: "none",
                              // borderLeft: "none",
                              // borderTop: "none",
                              width: "2.5rem",
                              height: "3rem",
                              fontSize: "16px",
                              color: " rgba(112, 112, 112, 0.72)",
                              borderRadius: "8px",
                              //   fontWeight: "400",
                              // caretColor: "blue"
                            }}
                            focusStyle={{
                              border: "1px solid black",
                              borderRadius: "8px",
                              // borderRight: "none",
                              // borderLeft: "none",
                              // borderTop: "none",
                              outline: "none",
                            }}
                          />
                        </div>
                        {error && (
                          <div className="otp-verify-error-aadhar">
                            Please enter a valid OTP
                          </div>
                        )}
                        {invalidCode && (
                          <div className="otp-verify-error-aadhar">
                            Invalid OTP.
                          </div>
                        )}
                      </div>
                    )}

                    {otpField && (
                      <div>
                        {validCode && !aadharLoading && (
                          <input
                            type="button"
                            className="common-button"
                            value="Confirm OTP and Proceed"
                            onClick={verifyOtp}
                          />
                        )}

                        {validCode && aadharLoading && (
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <CircularProgress style={{ color: "pink" }} />
                          </div>
                        )}

                        <div>
                          {!validCode && (
                            <div>
                              <input
                                type="button"
                                className="disabled-common-button"
                                value="Confirm OTP and Proceed"
                                // onClick={verifyOtp}
                              />
                            </div>
                          )}

                          <div>
                            {!aadharLoading && !validCode && (
                              <input
                                type="button"
                                className="common-button"
                                value="Resend OTP"
                                onClick={resendOtp}
                              />
                            )}
                            {aadharLoading && !validCode && (
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <CircularProgress style={{ color: "pink" }} />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {!aadharVerified && (
                      <div>
                        NOTE: OTP from Aadhar portal typically takes around 30
                        seconds to 2 minutes.
                      </div>
                    )}
                  </div>
                )}
                {tab === "pan" && (
                  <div className="seller-aadhar-tab">
                    <form onSubmit={formikPanGst.handleSubmit}>
                      <Heading text="Pan and GST Details" />
                      <div className="seller-personal-data-note">
                        Note: Enter the Permanent Account Number or GST Number
                        if your Annual Turn Over is less than 2,00,000/-.
                      </div>
                      <div className="seller-personal-data-form-field">
                        <div className="seller-personal-data-field-name">
                          GST Number
                        </div>
                        <MyhrakiTextfield
                          autoFocus
                          variant="outlined"
                          autoComplete="off"
                          fullWidth
                          type="text"
                          // id="seller-gst"
                          name="gst"
                          // className="seller-form-field"
                          placeHolder="GST Number "
                          value={formikPanGst.values.gst?.toUpperCase()}
                          onChange={formikPanGst.handleChange}
                          error={
                            formikPanGst.touched.gst &&
                            Boolean(formikPanGst.errors.gst)
                          }
                          helperText={
                            formikPanGst.touched.gst && formikPanGst.errors.gst
                          }
                        />
                      </div>
                      <div className="seller-personal-data-form-field">
                        <div className="seller-personal-data-field-name">
                          Permanent Account Number*
                        </div>
                        <MyhrakiTextfield
                          variant="outlined"
                          autoComplete="off"
                          fullWidth
                          type="text"
                          //  id="seller-pan"
                          name="pan"
                          // className="seller-form-field"
                          placeHolder="PAN Number "
                          value={formikPanGst.values.pan?.toUpperCase()}
                          onChange={formikPanGst.handleChange}
                          error={
                            formikPanGst.touched.pan &&
                            Boolean(formikPanGst.errors.pan)
                          }
                          helperText={
                            formikPanGst.touched.pan && formikPanGst.errors.pan
                          }
                        />
                      </div>
                      <div className="seller-personal-data-form-field">
                        <div className="seller-personal-data-field-name">
                          Company Name*
                        </div>
                        <MyhrakiTextfield
                          variant="outlined"
                          autoComplete="off"
                          fullWidth
                          type="text"
                          // id="companyName"
                          name="companyName"
                          // className="seller-form-field"
                          placeHolder="Company Name "
                          value={formikPanGst.values.companyName}
                          onChange={formikPanGst.handleChange}
                          error={
                            formikPanGst.touched.companyName &&
                            Boolean(formikPanGst.errors.companyName)
                          }
                          helperText={
                            formikPanGst.touched.companyName &&
                            formikPanGst.errors.companyName
                          }
                        />
                      </div>
                      <div className="seller-personal-data-form-field">
                        <div className="seller-personal-data-field-name">
                          Building Number*
                        </div>
                        <MyhrakiTextfield
                          variant="outlined"
                          autoComplete="off"
                          fullWidth
                          type="text"
                          // id="companyBuildingNo"
                          name="companyBuildingNo"
                          // className="seller-form-field"
                          placeHolder="Company Building Number "
                          value={formikPanGst.values.companyBuildingNo}
                          onChange={formikPanGst.handleChange}
                          error={
                            formikPanGst.touched.companyBuildingNo &&
                            Boolean(formikPanGst.errors.companyBuildingNo)
                          }
                          helperText={
                            formikPanGst.touched.companyBuildingNo &&
                            formikPanGst.errors.companyBuildingNo
                          }
                        />
                      </div>
                      <div className="seller-personal-data-form-field">
                        <div className="seller-personal-data-field-name">
                          Address Line*
                        </div>
                        <MyhrakiTextfield
                          variant="outlined"
                          autoComplete="off"
                          fullWidth
                          type="text"
                          //  id="companyAddress"
                          name="companyAddress"
                          // className="seller-form-field"
                          placeHolder="Company Address "
                          value={formikPanGst.values.companyAddress}
                          onChange={formikPanGst.handleChange}
                          error={
                            formikPanGst.touched.companyAddress &&
                            Boolean(formikPanGst.errors.companyAddress)
                          }
                          helperText={
                            formikPanGst.touched.companyAddress &&
                            formikPanGst.errors.companyAddress
                          }
                        />
                      </div>
                      <div className="seller-personal-data-form-field">
                        <div className="seller-personal-data-field-name">
                          City*
                        </div>
                        <MyhrakiTextfield
                          variant="outlined"
                          autoComplete="off"
                          fullWidth
                          type="text"
                          //  id="companyAddress"
                          name="city"
                          // className="seller-form-field"
                          placeHolder="Company City "
                          value={formikPanGst.values.city}
                          onChange={formikPanGst.handleChange}
                          error={
                            formikPanGst.touched.city &&
                            Boolean(formikPanGst.errors.city)
                          }
                          helperText={
                            formikPanGst.touched.city &&
                            formikPanGst.errors.city
                          }
                        />
                      </div>
                      <div className="seller-personal-data-form-field">
                        <div className="seller-personal-data-field-name">
                          State*
                        </div>
                        <MyhrakiTextfield
                          variant="outlined"
                          autoComplete="off"
                          fullWidth
                          type="text"
                          // id="companyAddress"
                          name="state"
                          // className="seller-form-field"
                          placeHolder="Company State "
                          value={formikPanGst.values.state}
                          onChange={formikPanGst.handleChange}
                          error={
                            formikPanGst.touched.state &&
                            Boolean(formikPanGst.errors.state)
                          }
                          helperText={
                            formikPanGst.touched.state &&
                            formikPanGst.errors.state
                          }
                        />
                      </div>
                      <div className="seller-personal-data-form-field">
                        <div className="seller-personal-data-field-name">
                          Pincode*
                        </div>
                        <MyhrakiTextfield
                          variant="outlined"
                          autoComplete="off"
                          fullWidth
                          type="text"
                          // id="companyAddress"
                          name="pincode"
                          // className="seller-form-field"
                          placeHolder="Company Pincode "
                          value={formikPanGst.values.pincode}
                          onChange={formikPanGst.handleChange}
                          error={
                            formikPanGst.touched.pincode &&
                            Boolean(formikPanGst.errors.pincode)
                          }
                          helperText={
                            formikPanGst.touched.pincode &&
                            formikPanGst.errors.pincode
                          }
                        />
                      </div>
                      <div className="seller-personal-tabs-nav">
                        <div
                          className="back-to-button"
                          onClick={goBackToAadharScreen}
                        >
                          <ChevronLeft /> Back
                        </div>
                        <div className="seller-personal-data-buttons">
                          {!sellerCurrentData?.result?.sellerPlanName && (
                            <button
                              type="button"
                              className="common-btn"
                              onClick={saveAsDraft}
                            >
                              Save as Draft
                            </button>
                          )}
                          <input
                            type="submit"
                            className="common-btn"
                            value="NEXT"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                )}
                {tab === "business" && (
                  <div className="seller-aadhar-tab">
                    <form onSubmit={formikBusiness.handleSubmit}>
                      <Heading text="Business Details" />

                      <div className="seller-personal-data-form-field">
                        <div className="seller-personal-data-field-name">
                          Bank Account Number*{" "}
                        </div>
                        <MyhrakiTextfield
                          autoFocus
                          variant="outlined"
                          autoComplete="off"
                          fullWidth
                          type="text"
                          // id="seller-account-number"
                          name="accountNumber"
                          // className="seller-form-field"
                          placeHolder="Enter Your Bank Account Number"
                          value={formikBusiness.values.accountNumber}
                          onChange={formikBusiness.handleChange}
                          error={
                            formikBusiness.touched.accountNumber &&
                            Boolean(formikBusiness.errors.accountNumber)
                          }
                          helperText={
                            formikBusiness.touched.accountNumber &&
                            formikBusiness.errors.accountNumber
                          }
                        />
                      </div>
                      <div className="seller-personal-data-form-field">
                        <div className="seller-personal-data-field-name">
                          IFSC Number*
                        </div>
                        <MyhrakiTextfield
                          variant="outlined"
                          autoComplete="off"
                          fullWidth
                          type="text"
                          // id="seller-ifsc"
                          name="ifsc"
                          // className="seller-form-field "
                          placeHolder="Enter Your IFSC Code"
                          value={formikBusiness?.values?.ifsc?.toUpperCase()}
                          onChange={formikBusiness.handleChange}
                          error={
                            formikBusiness.touched.ifsc &&
                            Boolean(formikBusiness.errors.ifsc)
                          }
                          helperText={
                            formikBusiness.touched.ifsc &&
                            formikBusiness.errors.ifsc
                          }
                        />
                      </div>
                      <div className="seller-personal-data-form-field">
                        <div className="seller-personal-data-field-name">
                          Account Holder's Name*
                        </div>
                        <MyhrakiTextfield
                          variant="outlined"
                          autoComplete="off"
                          fullWidth
                          type="text"
                          // id="seller-name"
                          name="name"
                          // className="seller-form-field"
                          placeHolder="Enter Account Holder's Name"
                          value={formikBusiness.values.name}
                          onChange={formikBusiness.handleChange}
                          error={
                            formikBusiness.touched.name &&
                            Boolean(formikBusiness.errors.name)
                          }
                          helperText={
                            formikBusiness.touched.name &&
                            formikBusiness.errors.name
                          }
                        />
                      </div>
                      <div className="seller-personal-data-form-field">
                        <div className="seller-personal-data-field-name">
                          Branch Name*
                        </div>
                        <MyhrakiTextfield
                          variant="outlined"
                          autoComplete="off"
                          fullWidth
                          type="text"
                          // id="seller-branch-name"
                          name="branchName"
                          // className="seller-form-field"
                          placeHolder="Enter Branch Name"
                          value={formikBusiness.values.branchName}
                          onChange={formikBusiness.handleChange}
                          error={
                            formikBusiness.touched.branchName &&
                            Boolean(formikBusiness.errors.branchName)
                          }
                          helperText={
                            formikBusiness.touched.branchName &&
                            formikBusiness.errors.branchName
                          }
                        />
                      </div>
                      <div className="seller-personal-data-form-field-dropdown">
                        <div className="seller-personal-data-field-name">
                          Type of Bank Account*
                        </div>

                        <TextField
                          select
                          value={formikBusiness.values.accountType}
                          name="accountType"
                          // id="seller-accountType"
                          // className="seller-account-type"
                          className="seller-form-field-dropdown"
                          onChange={formikBusiness.handleChange}
                          // displayEmpty
                          fullWidth
                          // sx={{ width: "80%", fontFamily: "Barlow" }}
                        >
                          <MenuItem value="Current Account">
                            Current Account
                          </MenuItem>
                          <MenuItem value="Savings Bank Account">
                            Savings Bank Account
                          </MenuItem>
                        </TextField>
                        {/* </div> */}
                      </div>
                      <div className="seller-personal-tabs-nav">
                        <div
                          className="back-to-button"
                          onClick={goBackToPanScreen}
                        >
                          <ChevronLeft /> Back
                        </div>
                        <div className="seller-personal-data-buttons">
                          {!sellerCurrentData?.result?.sellerPlanName && (
                            <button
                              type="button"
                              className="common-btn"
                              onClick={saveAsDraft}
                            >
                              Save as Draft
                            </button>
                          )}
                          <input
                            type="submit"
                            className="common-btn"
                            value="NEXT"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                )}
                {tab === "certificates" && (
                  <div className="seller-aadhar-tab">
                    {!existingImage && (
                      <form onSubmit={formikMsme.handleSubmit}>
                        <Heading text="Certificates" />

                        <div className="seller-personal-data-note">
                          Note: Upload CIN Certificate if your Annual Turn Over
                          is less than 50,00,000/-.
                        </div>

                        <div className="seller-personal-data-cert-msme-section">
                          <div className="seller-personal-data-cert-msme-section-text">
                            <div className="seller-personal-data-cert-msme-section-heading">
                              Upload MSME Certificate{" "}
                            </div>
                            <div>
                              <div className="seller-personal-data-msme-cert-info">
                                <InfoIcon />
                                <div className="seller-personal-data-note">
                                  File with PNG, JPG or JPEG format is only
                                  allowed.
                                </div>
                              </div>
                              <div className="seller-personal-data-msme-cert-info">
                                <InfoIcon />
                                <div className="seller-personal-data-note">
                                  Maximum File size for upload is 5 MB.
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="seller-personal-data-cert-msme-section-buttons">
                            <div>
                              <label className="choose-file-btn">
                                <input
                                  type="file"
                                  // accept=".jpg,.jpeg,.png"
                                  onChange={async (event) => {
                                    setMsmeLoading(true);

                                    const uploadedImage = event.target.files[0];

                                    if (!uploadedImage) {
                                      setMsmeLoading(false);
                                      return;
                                    }

                                    const maxSizeInBytes = 5 * 1024 * 1024;
                                    if (uploadedImage.size > maxSizeInBytes) {
                                      errorAlert(
                                        "",
                                        "File size exceeds 5 MB limit."
                                      );
                                      setMsmeLoading(false);
                                      return;
                                    }

                                    // Function to read file as array buffer
                                    const readFileAsArrayBuffer = (file) => {
                                      return new Promise((resolve, reject) => {
                                        const fileReader = new FileReader();

                                        fileReader.onload = () => {
                                          const arrayBuffer = fileReader.result;
                                          resolve(new Uint8Array(arrayBuffer));
                                        };

                                        fileReader.onerror = (error) => {
                                          reject(error);
                                        };

                                        fileReader.readAsArrayBuffer(file);
                                      });
                                    };

                                    try {
                                      const uint8Array =
                                        await readFileAsArrayBuffer(
                                          uploadedImage
                                        );

                                      // Check if the magic number corresponds to jpg/jpeg/png
                                      if (
                                        uint8Array.length >= 2 &&
                                        uint8Array[0] === 0xff &&
                                        uint8Array[1] === 0xd8
                                      ) {
                                        // JPG file
                                      } else if (
                                        uint8Array.length >= 4 &&
                                        uint8Array[0] === 0x89 &&
                                        uint8Array[1] === 0x50 &&
                                        uint8Array[2] === 0x4e &&
                                        uint8Array[3] === 0x47
                                      ) {
                                        // PNG file
                                      } else if (
                                        uint8Array.length >= 2 &&
                                        uint8Array[0] === 0xff &&
                                        uint8Array[1] === 0xd9
                                      ) {
                                        // JPEG file (ending with EOI marker)
                                      } else {
                                        // Invalid file type
                                        errorAlert(
                                          "",
                                          "Invalid file type. Please choose a valid jpg, jpeg, or png file."
                                        );
                                        setMsmeLoading(false);
                                        return;
                                      }

                                      // Continue with the rest of your code for size and extension validation
                                      const validTypes = [
                                        "image/jpeg",
                                        "image/png",
                                        "image/jpg",
                                      ];
                                      if (
                                        !validTypes.includes(uploadedImage.type)
                                      ) {
                                        errorAlert(
                                          "",
                                          "File type must be jpg, jpeg, or png."
                                        );
                                        setMsmeLoading(false);
                                        return;
                                      }

                                      // Continue with the rest of your code for successful upload
                                      const fileExtension = uploadedImage.name
                                        .split(".")
                                        .pop();
                                      const imageName = `${uuidv4()}.${fileExtension}`;

                                      const formData = new FormData();
                                      formData.append(
                                        "ContainerName",
                                        "ecomdata"
                                      );
                                      formData.append(
                                        "FileNameWithExtension",
                                        imageName
                                      );
                                      formData.append("FileCategory", "Msme");
                                      formData.append(
                                        "Attachment",
                                        uploadedImage,
                                        uploadedImage.name
                                      );

                                      const response = await fetch(
                                        `${API_BASE_URL}/blob/api/v1/uploadmediaWithContainer`,
                                        {
                                          method: "POST",
                                          headers: {
                                            Authorization: `Bearer ${accessToken}`,
                                          },
                                          body: formData,
                                        }
                                      );

                                      const data = await response?.json();
                                      const handleAuthError =
                                        handleAuthExpiry(response);
                                      if (handleAuthError === true) {
                                        errorAlert(
                                          "",
                                          "Your Session has expired"
                                        );
                                        logout();
                                        return;
                                      }

                                      if (!response.ok) {
                                        throw new Error(
                                          `HTTP error! status: ${response.status}`
                                        );
                                      }

                                      // Set image variable as the uploaded image name
                                      formikMsme.setFieldValue(
                                        "image",
                                        imageName
                                      );

                                      // You can also perform other actions after successful upload
                                      setCinChoose(false);
                                      setMsmeChoose(true);
                                      setMsmeLoading(false);
                                      setUploadedImageCIN(null);
                                      setUploadedImageMSME(
                                        URL.createObjectURL(uploadedImage)
                                      );
                                    } catch (error) {
                                      // Handle error if upload fails
                                      console.error(
                                        "Error uploading file:",
                                        error
                                      );
                                      setMsmeLoading(false);
                                    }
                                  }}
                                  name="image"
                                />
                                Choose File
                              </label>
                              {uploadedImageMSME && (
                                <img
                                  loading="lazy"
                                  src={uploadedImageMSME}
                                  style={{ width: "200px", height: "200px" }}
                                  alt="Uploaded Image"
                                />
                              )}

                              {msmeLoading && (
                                <CircularProgress className="file-submitted" />
                              )}

                              {msmeChoose && (
                                <CheckCircleIcon className="file-submitted" />
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            marginBottom: "-36px",
                          }}
                        >
                          <hr
                            style={{
                              width: "45%",
                              height: " 0px",
                              alignSelf: "center",
                            }}
                          />
                          <p
                            style={{
                              fontSize: "1.8rem",
                              alignSelf: "center",
                              color: "rgba(0, 0, 0, 0.5)",
                            }}
                          >
                            or
                          </p>
                          <hr
                            style={{
                              width: "45%",
                              height: " 0px",
                              alignSelf: "center",
                            }}
                          />
                        </div>
                        <div className="seller-personal-data-cert-msme-section">
                          <div className="seller-personal-data-cert-msme-section-text">
                            <div className="seller-personal-data-cert-msme-section-heading">
                              Upload CIN Certificate{" "}
                            </div>
                            <div>
                              <div className="seller-personal-data-msme-cert-info">
                                <InfoIcon />
                                <div className="seller-personal-data-note">
                                  File with PNG, JPG or JPEG format is only
                                  allowed.
                                </div>
                              </div>
                              <div className="seller-personal-data-msme-cert-info">
                                <InfoIcon />
                                <div className="seller-personal-data-note">
                                  Maximum File size for upload is 5 MB.
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="seller-personal-data-cert-msme-section-buttons">
                            <div>
                              <label className="choose-file-btn">
                                <input
                                  type="file"
                                  // accept=".jpg,.jpeg,.png"
                                  onChange={async (event) => {
                                    setCinLoading(true);

                                    const uploadedImage = event.target.files[0];

                                    if (!uploadedImage) {
                                      // No file was selected, handle this case gracefully
                                      setCinLoading(false);
                                      return;
                                    }

                                    const maxSizeInBytes = 5 * 1024 * 1024;
                                    if (uploadedImage.size > maxSizeInBytes) {
                                      errorAlert(
                                        "",
                                        "File size exceeds 5 MB limit."
                                      );
                                      setCinLoading(false);
                                      return;
                                    }

                                    // Function to read file as array buffer
                                    const readFileAsArrayBuffer = (file) => {
                                      return new Promise((resolve, reject) => {
                                        const fileReader = new FileReader();

                                        fileReader.onload = () => {
                                          const arrayBuffer = fileReader.result;
                                          resolve(new Uint8Array(arrayBuffer));
                                        };

                                        fileReader.onerror = (error) => {
                                          reject(error);
                                        };

                                        fileReader.readAsArrayBuffer(file);
                                      });
                                    };

                                    try {
                                      const uint8Array =
                                        await readFileAsArrayBuffer(
                                          uploadedImage
                                        );

                                      // Check if the magic number corresponds to jpg/jpeg/png
                                      if (
                                        uint8Array.length >= 2 &&
                                        uint8Array[0] === 0xff &&
                                        uint8Array[1] === 0xd8
                                      ) {
                                        // JPG file
                                      } else if (
                                        uint8Array.length >= 4 &&
                                        uint8Array[0] === 0x89 &&
                                        uint8Array[1] === 0x50 &&
                                        uint8Array[2] === 0x4e &&
                                        uint8Array[3] === 0x47
                                      ) {
                                        // PNG file
                                      } else if (
                                        uint8Array.length >= 2 &&
                                        uint8Array[0] === 0xff &&
                                        uint8Array[1] === 0xd9
                                      ) {
                                        // JPEG file (ending with EOI marker)
                                      } else {
                                        // Invalid file type
                                        errorAlert(
                                          "",
                                          "Invalid file type. Please choose a valid jpg, jpeg, or png file."
                                        );
                                        setCinLoading(false);
                                        return;
                                      }

                                      // Continue with the rest of your code for size and extension validation
                                      const validTypes = [
                                        "image/jpeg",
                                        "image/png",
                                        "image/jpg",
                                      ];
                                      if (
                                        !validTypes.includes(uploadedImage.type)
                                      ) {
                                        errorAlert(
                                          "",
                                          "File type must be jpg, jpeg, or png."
                                        );
                                        setCinLoading(false);
                                        return;
                                      }

                                      // Continue with the rest of your code for successful upload
                                      const fileExtension = uploadedImage.name
                                        .split(".")
                                        .pop();
                                      const imageName = `${uuidv4()}.${fileExtension}`;

                                      const formData = new FormData();
                                      formData.append(
                                        "ContainerName",
                                        "ecomdata"
                                      );
                                      formData.append(
                                        "FileNameWithExtension",
                                        imageName
                                      );
                                      formData.append("FileCategory", "Msme");
                                      formData.append(
                                        "Attachment",
                                        uploadedImage,
                                        uploadedImage.name
                                      );

                                      const response = await fetch(
                                        `${API_BASE_URL}/blob/api/v1/uploadmediaWithContainer`,
                                        {
                                          method: "POST",
                                          headers: {
                                            Authorization: `Bearer ${accessToken}`,
                                          },
                                          body: formData,
                                        }
                                      );

                                      const data = await response?.json();
                                      const handleAuthError =
                                        handleAuthExpiry(response);
                                      if (handleAuthError === true) {
                                        errorAlert(
                                          "",
                                          "Your Session has expired"
                                        );
                                        logout();
                                        return;
                                      }

                                      if (!response.ok) {
                                        throw new Error(
                                          `HTTP error! status: ${response.status}`
                                        );
                                      }

                                      // Set image variable as the uploaded image name
                                      formikMsme.setFieldValue(
                                        "image",
                                        imageName
                                      );

                                      // You can also perform other actions after successful upload
                                      setCinChoose(true);
                                      setMsmeChoose(false);
                                      setCinLoading(false);
                                      setUploadedImageMSME(null);
                                      setUploadedImageCIN(
                                        URL.createObjectURL(uploadedImage)
                                      );
                                    } catch (error) {
                                      // Handle error if upload fails
                                      console.error(
                                        "Error uploading file:",
                                        error
                                      );
                                      setCinLoading(false);
                                    }
                                  }}
                                  name="image"
                                />
                                Choose File
                              </label>
                              {uploadedImageCIN && (
                                <img
                                  loading="lazy"
                                  src={uploadedImageCIN}
                                  style={{ width: "200px", height: "200px" }}
                                  alt="Uploaded Image"
                                />
                              )}
                              {cinLoading && (
                                <CircularProgress className="file-submitted" />
                              )}
                              {cinChoose && (
                                <CheckCircleIcon className="file-submitted" />
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="seller-personal-tabs-nav">
                          <div
                            className="back-to-button"
                            onClick={goBackToBankDetailsScreen}
                          >
                            <ChevronLeft />
                            Back
                          </div>
                          <div className="seller-personal-data-buttons">
                            {!sellerCurrentData?.result?.sellerPlanName && (
                              <button
                                type="button"
                                className="common-btn"
                                onClick={saveAsDraft}
                              >
                                Save as Draft
                              </button>
                            )}
                            <input
                              type="submit"
                              className="common-btn"
                              value="Finish"
                            />
                          </div>
                        </div>
                      </form>
                    )}
                    {existingImage && (
                      <>
                        <Heading text="You have already uploaded a Certificate" />
                        <div className="Existing-image-container">
                          <img
                            loading="lazy"
                            src={existingImage}
                            alt="existing Image"
                            className="existingImage-field"
                          />
                          <div className="Existing-image-container-buttons">
                            <div
                              className="back-to-button"
                              onClick={goBackToBankDetailsScreen}
                            >
                              <ChevronLeft />
                              Back
                            </div>
                            <div>
                              <button
                                className="common-button-primary"
                                onClick={() => {
                                  setCompleted(80);

                                  setTab("confirmDetails");
                                  setFullWidthTabs(true);
                                }}
                              >
                                Continue
                              </button>
                              <button
                                className="common-btn-secondary"
                                onClick={() => {
                                  setExistingImage(null);
                                }}
                              >
                                Edit{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
                {tab === "confirmDetails" && (
                  <div className="seller-aadhar-tab">
                    <Heading text="Review Personal Details " />
                    <div className="seller-page-form">
                      <div className="">
                        <div className="seller-form-field">
                          <div className="seller-my-account-field-name">
                            Aadhar Card Number*{" "}
                          </div>
                          <TextField
                            autoComplete="off"
                            variant="outlined"
                            fullWidth
                            type="text"
                            id="invite_sellerName"
                            name="aadhar"
                            disabled
                            className="seller-form-field-disabled"
                            // placeholder=" "
                            value={formik.values.aadhar.trim()}
                            // onChange={formikDetails.handleChange}
                            // error={
                            //   formikDetails.touched.aadhar &&
                            //   Boolean(formikDetails.errors.aadhar)
                            // }
                            // disabled={editable ? false : true}
                            // helperText={
                            //   formikDetails.touched.aadhar &&
                            //   formikDetails.errors.aadhar
                            // }
                          />
                        </div>
                        <div className="seller-form-field">
                          <div className="seller-my-account-field-name">
                            GST Number
                          </div>
                          <TextField
                            autoComplete="off"
                            variant="outlined"
                            fullWidth
                            disabled
                            type="text"
                            id="invite_sellerName"
                            name="gst"
                            className="seller-form-field-disabled"
                            // placeholder=" "
                            value={formikPanGst?.values?.gst}
                            // onChange={formikDetails.handleChange}
                            // error={
                            //   formikDetails.touched.gst &&
                            //   Boolean(formikDetails.errors.gst)
                            // }
                            // disabled={editable ? false : true}
                            // helperText={
                            //   formikDetails.touched.gst &&
                            //   formikDetails.errors.gst
                            // }
                          />
                        </div>
                        <div className="seller-form-field">
                          <div className="seller-my-account-field-name">
                            Pan Card Number(PAN)*{" "}
                          </div>
                          <TextField
                            autoComplete="off"
                            variant="outlined"
                            fullWidth
                            disabled
                            type="text"
                            id="invite_sellerName"
                            name="pan"
                            className="seller-form-field-disabled"
                            // placeholder=" "
                            value={formikPanGst.values.pan}
                            // onChange={formikDetails.handleChange}
                            // error={
                            //   formikDetails.touched.pan &&
                            //   Boolean(formikDetails.errors.pan)
                            // }
                            // disabled={editable ? false : true}
                            // helperText={
                            //   formikDetails.touched.pan &&
                            //   formikDetails.errors.pan
                            // }
                          />
                        </div>
                        <div className="seller-form-field">
                          <div className="seller-my-account-field-name">
                            Company Name*
                          </div>
                          <TextField
                            autoComplete="off"
                            variant="outlined"
                            fullWidth
                            disabled
                            type="text"
                            id="invite_sellerName"
                            name="companyName"
                            className="seller-form-field-disabled"
                            // placeholder=" "
                            value={formikPanGst.values.companyName}
                            // onChange={formikDetails.handleChange}
                            // error={
                            //   formikDetails.touched.pan &&
                            //   Boolean(formikDetails.errors.pan)
                            // }
                            // disabled={editable ? false : true}
                            // helperText={
                            //   formikDetails.touched.pan &&
                            //   formikDetails.errors.pan
                            // }
                          />
                        </div>
                        <div className="seller-form-field">
                          <div className="seller-my-account-field-name">
                            Company Building Number*
                          </div>
                          <TextField
                            autoComplete="off"
                            variant="outlined"
                            fullWidth
                            disabled
                            type="text"
                            id="invite_sellerName"
                            name="companyBuildingNo"
                            className="seller-form-field-disabled"
                            // placeholder=" "
                            value={formikPanGst.values.companyBuildingNo?.trim()}
                            // onChange={formikDetails.handleChange}
                            // error={
                            //   formikDetails.touched.pan &&
                            //   Boolean(formikDetails.errors.pan)
                            // }
                            // disabled={editable ? false : true}
                            // helperText={
                            //   formikDetails.touched.pan &&
                            //   formikDetails.errors.pan
                            // }
                          />
                        </div>
                        <div className="seller-form-field">
                          <div className="seller-my-account-field-name">
                            Company Address*
                          </div>
                          <TextField
                            autoComplete="off"
                            variant="outlined"
                            fullWidth
                            disabled
                            type="text"
                            id="invite_sellerName"
                            name="companyAddress"
                            className="seller-form-field-disabled"
                            // placeholder=" "
                            value={formikPanGst.values.companyAddress?.trim()}
                            // onChange={formikDetails.handleChange}
                            // error={
                            //   formikDetails.touched.pan &&
                            //   Boolean(formikDetails.errors.pan)
                            // }
                            // disabled={editable ? false : true}
                            // helperText={
                            //   formikDetails.touched.pan &&
                            //   formikDetails.errors.pan
                            // }
                          />
                        </div>
                        <div className="seller-form-field">
                          <div className="seller-my-account-field-name">
                            City*
                          </div>
                          <TextField
                            autoComplete="off"
                            variant="outlined"
                            fullWidth
                            type="text"
                            id="invite_sellerName"
                            name="city"
                            disabled
                            className="seller-form-field-disabled"
                            // placeholder=" "
                            value={formikPanGst.values.city?.trim()}
                            // onChange={formikDetails.handleChange}
                            // error={
                            //   formikDetails.touched.pan &&
                            //   Boolean(formikDetails.errors.pan)
                            // }
                            // disabled={editable ? false : true}
                            // helperText={
                            //   formikDetails.touched.pan &&
                            //   formikDetails.errors.pan
                            // }
                          />
                        </div>
                        <div className="seller-form-field">
                          <div className="seller-my-account-field-name">
                            State*
                          </div>
                          <TextField
                            autoComplete="off"
                            variant="outlined"
                            fullWidth
                            disabled
                            type="text"
                            id="invite_sellerName"
                            name="state"
                            className="seller-form-field-disabled"
                            // placeholder=" "
                            value={formikPanGst.values.state?.trim()}
                            // onChange={formikDetails.handleChange}
                            // error={
                            //   formikDetails.touched.pan &&
                            //   Boolean(formikDetails.errors.pan)
                            // }
                            // disabled={editable ? false : true}
                            // helperText={
                            //   formikDetails.touched.pan &&
                            //   formikDetails.errors.pan
                            // }
                          />
                        </div>
                        <div className="seller-form-field">
                          <div className="seller-my-account-field-name">
                            Pincode*
                          </div>
                          <TextField
                            autoComplete="off"
                            variant="outlined"
                            fullWidth
                            disabled
                            type="text"
                            id="invite_sellerName"
                            name="companyAddress"
                            className="seller-form-field-disabled"
                            // placeholder=" "
                            value={formikPanGst.values.pincode?.trim()}
                            // onChange={formikDetails.handleChange}
                            // error={
                            //   formikDetails.touched.pan &&
                            //   Boolean(formikDetails.errors.pan)
                            // }
                            // disabled={editable ? false : true}
                            // helperText={
                            //   formikDetails.touched.pan &&
                            //   formikDetails.errors.pan
                            // }
                          />
                        </div>
                        <div className="seller-form-field">
                          <div className="seller-my-account-field-name">
                            Bank Account Number*{" "}
                          </div>
                          <TextField
                            autoComplete="off"
                            variant="outlined"
                            fullWidth
                            disabled
                            type="text"
                            id="invite_sellerName"
                            name="accountNumber"
                            className="seller-form-field-disabled"
                            // placeholder=" "
                            value={formikBusiness.values.accountNumber}
                            // onChange={formikDetails.handleChange}
                            // error={
                            //   formikDetails.touched.accountNumber &&
                            //   Boolean(formikDetails.errors.accountNumber)
                            // }
                            // disabled={editable ? false : true}
                            // helperText={
                            //   formikDetails.touched.accountNumber &&
                            //   formikDetails.errors.accountNumber
                            // }
                          />
                        </div>
                        <div className="seller-form-field">
                          <div className="seller-my-account-field-name">
                            Account Holder's Name*{" "}
                          </div>
                          <TextField
                            autoComplete="off"
                            variant="outlined"
                            fullWidth
                            disabled
                            type="text"
                            id="invite_sellerName"
                            name="name"
                            className="seller-form-field-disabled"
                            // placeholder=" "
                            value={formikBusiness?.values?.name}
                            // onChange={formikDetails.handleChange}
                            // error={
                            //   formikDetails.touched.name &&
                            //   Boolean(formikDetails.errors.name)
                            // }
                            // disabled={editable ? false : true}
                            // helperText={
                            //   formikDetails.touched.name &&
                            //   formikDetails.errors.name
                            // }
                          />
                        </div>
                        <div className="seller-form-field">
                          <div className="seller-my-account-field-name">
                            Bank IFSC Number*{" "}
                          </div>
                          <TextField
                            autoComplete="off"
                            variant="outlined"
                            fullWidth
                            disabled
                            type="text"
                            id="invite_sellerName"
                            name="ifsc"
                            className="seller-form-field-disabled"
                            // placeholder=" "
                            value={formikBusiness.values.ifsc}
                            // onChange={formikDetails.handleChange}
                            // error={
                            //   formikDetails.touched.ifsc &&
                            //   Boolean(formikDetails.errors.ifsc)
                            // }
                            // disabled={editable ? false : true}
                            // helperText={
                            //   formikDetails.touched.ifsc &&
                            //   formikDetails.errors.ifsc
                            // }
                          />
                        </div>
                        <div className="seller-form-field">
                          <div className="seller-my-account-field-name">
                            Branch Name*{" "}
                          </div>
                          <TextField
                            autoComplete="off"
                            variant="outlined"
                            fullWidth
                            disabled
                            type="text"
                            id="invite_sellerName"
                            name="branchName"
                            className="seller-form-field-disabled"
                            // placeholder=" "
                            value={formikBusiness.values.branchName}
                            // onChange={formikDetails.handleChange}
                            // error={
                            //   formikDetails.touched.branchName &&
                            //   Boolean(formikDetails.errors.branchName)
                            // }
                            // disabled={editable ? false : true}
                            // helperText={
                            //   formikDetails.touched.branchName &&
                            //   formikDetails.errors.branchName
                            // }
                          />
                        </div>
                        <div className="seller-form-field">
                          <div className="seller-my-account-field-name">
                            Type of Account*{" "}
                          </div>

                          <div className="seller-my-account-non-texfield">
                            {formikBusiness.values.accountType !==
                            "Current Account"
                              ? "Savings Bank Account"
                              : "Current Account"}
                          </div>
                        </div>
                        <div className="seller-form-field">
                          <div className="seller-my-account-field-name">
                            Upload Certificate
                          </div>

                          <div className="seller-my-account-non-texfield">
                            {formikMsme?.values?.image}
                          </div>
                        </div>

                        <div className="seller-personal-tabs-nav">
                          <div
                            className="back-to-button"
                            onClick={goBackToMsmeScreen}
                          >
                            <ChevronLeft /> Back
                          </div>
                          <div className="seller-personal-data-buttons">
                            {/* <button type="button" className="common-btn" onClick={saveAsDraft}>
                          Save as Draft
                        </button> */}
                            <button
                              type="button"
                              className="common-btn"
                              onClick={confirmDetails}
                            >
                              Confirm Detail
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {tab === "sellerSubscription" && (
                  <div className="seller-aadhar-tab">
                    <div className="submit-models-container">
                      <div className="subs-models-header">
                        Subscription Plans
                      </div>
                      <div className="subs-list-note  ">
                        Make the wise decision for your business
                      </div>
                      <SellerSubscriptionSelection
                        subsPlan={subsPlan}
                        setSubsPlan={setSubsPlan}
                        selectedPlan={selectedPlan}
                        setSelectedPlan={setSelectedPlan}
                        setSellerSubscriptionData={setSellerSubscriptionData}
                        sellerSubscriptionData={sellerSubscriptionData}
                        // planName={sellerPlanName}
                      />

                      {selectedPlan && (
                        <div className="seller-subscription-checkbox">
                          {selectedPlan.annualDiscount > 0 && (
                            <input
                              type="checkbox"
                              style={{ width: "24px", transform: "scale(1.8)" }}
                              checked={annualPlanPayment}
                              onChange={() => {
                                setAnnualPlanPayment(!annualPlanPayment);
                              }}
                            />
                          )}
                          {selectedPlan.annualDiscount > 0 && (
                            <label>Choose to pay annually</label>
                          )}
                          {selectedPlan.annualDiscount > 0 && (
                            <label>
                              {" "}
                              {""} and get {selectedPlan.annualDiscount} %
                              discount (
                              {annualPlanPayment
                                ? selectedPlan.annualValue
                                : selectedPlan?.value}{" "}
                              + GST)
                            </label>
                          )}
                        </div>
                      )}

                      <div className="seller-personal-tabs-nav">
                        <div
                          className="back-to-button"
                          onClick={goBackToConfirmScreen}
                        >
                          <ChevronLeft /> Back
                        </div>
                        <div className="seller-personal-data-buttons">
                          <button
                            type="button"
                            className="common-btn-secondary"
                            onClick={existingPlan}
                          >
                            Already Have a Plan
                          </button>

                          {!aadharLoading &&
                            selectedPlan?.annualValue === 0 && (
                              <button
                                type="button"
                                className="common-btn"
                                onClick={selectPlan}
                                style={{ marginBottom: "60px", float: "right" }}
                              >
                                Continue with Free Plan
                              </button>
                            )}
                          {selectedPlan?.annualValue > 0 && !openPayModal && (
                            <button
                              type="button"
                              className="common-btn"
                              onClick={selectPlan}
                              style={{ marginBottom: "60px", float: "right" }}
                            >
                              Pay ₹{" "}
                              {annualPlanPayment
                                ? selectedPlan.annualValue
                                : selectedPlan?.value}{" "}
                              +GST/ {annualPlanPayment ? "Year" : "Month"}
                            </button>
                          )}
                          {openPayModal && (
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}
                            >
                              <CircularProgress style={{ color: "pink" }} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {tab === "pending" &&
                  sellerCurrentData?.result?.status === "P" && (
                    <div className="seller-aadhar-tab">
                      <div className="seller-submission-success">
                        <img
                          loading="lazy"
                          src={verifiedIcon}
                          alt=" "
                          className="verified-icon"
                        />

                        <div className="seller-submission-success-title">
                          THANK YOU
                        </div>
                        <div className="seller-submission-success-message">
                          Your application has been submitted and is under
                          review
                          <br />
                        </div>
                        <div className="seller-submission-success-note">
                          You will be notified within 2 business days regarding
                          the status of your account<br></br>
                          If there are any issues with your applications's
                          approval, we will reach out to you immediately.
                        </div>
                      </div>
                    </div>
                  )}
                {tab === "pending" &&
                  sellerCurrentData?.result?.status === "R" && (
                    <div className="seller-aadhar-tab">
                      <div className="seller-submission-success">
                        <img
                          loading="lazy"
                          src={verifiedIcon}
                          alt=" "
                          className="verified-icon"
                        />
                        <div className="seller-submission-success-title">
                          OOPS
                        </div>
                        <div className="seller-submission-success-message">
                          Your application was rejected by the admin for some
                          reason.
                          <br />
                        </div>
                        <div className="seller-submission-success-note">
                          We have sent an Email to you regarding the details of
                          the errors due to which the application was rejected.
                          Please check the mail you have received on your
                          registered Email and edit the details accordingly.{" "}
                          <br></br>
                          If you have already edited the details, Please wait,
                          The admin will get back to you shortly.
                        </div>
                        <button
                          className="common-button"
                          onClick={() => {
                            setTab("aadhar");
                          }}
                        >
                          Edit Details
                        </button>
                      </div>
                    </div>
                  )}
                {tab === "paymentError" && (
                  <div className="seller-aadhar-tab">
                    <div className="payment-error cart-card-layout-border">
                      <div className="payment-error-message">
                        Oops ! Payment Failed
                      </div>
                      <div className="payment-error-order-id">
                        Payment for Order ID 12345678 could not be proceed.
                        <br /> Try Again.
                      </div>
                      <div className="payment-form-total error-payment-field">
                        <div className="payment-field-heading form-total error-payment">
                          TOTAL AMOUNT
                        </div>
                        <div className="payment-field-heading form-total error-payment">
                          ₹ 10,000
                        </div>
                      </div>
                      <input
                        type="submit"
                        className="full-width-button"
                        value="Pay ₹ 10,000"
                        onClick={selectPlan}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={openPayModal}
          onClose={handleClosePayModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                margin: "0 auto",
                width: "100%",
                flexDirection: "column",
                gap: "5%",
              }}
            >
              {phonePeLoading ? (
                <CircularProgress style={{ color: "purple" }} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    paddingLeft: "40px",
                    borderRadius: "4px",
                  }}
                  onClick={phonePePayment}
                >
                  <Radio
                    checked={selectedValue === "b"}
                    onChange={handlePayChange}
                    value="b"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "B" }}
                  />
                  <img src={PhonePe} />
                </div>
              )}
              {razorPayLoading ? (
                <CircularProgress style={{ color: "blue" }} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItem: "center",
                    gap: "5%",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    paddingLeft: "40px",
                    borderRadius: "4px",
                  }}
                  onClick={razorPayPayment}
                >
                  <Radio
                    checked={selectedValue === "b"}
                    onChange={handlePayChange}
                    value="b"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "B" }}
                  />
                  <img src={RazorPay} />
                </div>
              )}
            </div>
          </Box>
        </Modal>
      </>
    </SellerLayoutAdjustment>
  );
}

const TrackCheckout = () => {
  const [current] = useState(0);

  return (
    <div class="order-track">
      <div class="order-track-step">
        <div class="order-track-status">
          {current <= 0 && (
            <span
              class="order-track-status-dot"
              id="step-1-dot"
              style={{ backgroundColor: "rgba(255, 208, 216, 1)" }}
            >
              1
            </span>
          )}
          {current > 0 && (
            <span class="order-track-status-dot" id="step-1-dot">
              D
            </span>
          )}
          <span class="order-track-status-line" id="step-1-line"></span>
        </div>
        <div class="order-track-text">
          <p class="order-track-text-stat">Aadhar</p>
        </div>
      </div>
      <div class="order-track-step">
        <div class="order-track-status">
          {current <= 1 && (
            <span class="order-track-status-dot" id="step-2-dot">
              2
            </span>
          )}
          {current > 1 && (
            <span class="order-track-status-dot" id="step-2-dot">
              D
            </span>
          )}{" "}
          <span class="order-track-status-line" id="step-2-line"></span>
        </div>
        <div class="order-track-text">
          <p class="order-track-text-stat">PAN and GST</p>
        </div>
      </div>
      <div class="order-track-step">
        <div class="order-track-status">
          {current <= 2 && (
            <span class="order-track-status-dot" id="step-3-dot">
              3
            </span>
          )}
          {current > 2 && (
            <span class="order-track-status-dot" id="step-3-dot">
              D
            </span>
          )}{" "}
          <span class="order-track-status-line" id="step-3-line"></span>
        </div>
        <div class="order-track-text">
          <p class="order-track-text-stat">Business Information</p>
        </div>
      </div>
      <div class="order-track-step">
        <div class="order-track-status">
          {current <= 2 && (
            <span class="order-track-status-dot" id="step-4-dot">
              4
            </span>
          )}
          {current > 2 && (
            <span class="order-track-status-dot" id="step-4-dot">
              D
            </span>
          )}{" "}
          <span class="order-track-status-line" id="step-4-line"></span>
        </div>
        <div class="order-track-text">
          <p class="order-track-text-stat">Certificates</p>
        </div>
      </div>
      <div class="order-track-step">
        <div class="order-track-status">
          {current <= 3 && (
            <span class="order-track-status-dot" id="step-5-dot">
              5
            </span>
          )}
          {current > 3 && (
            <span class="order-track-status-dot" id="step-5-dot">
              D
            </span>
          )}{" "}
        </div>
        <div class="order-track-text">
          <p class="order-track-text-stat">Confirm Details</p>
        </div>
      </div>
    </div>
  );
};

export default withSellerAuth(SellerPersonalData);
