import React from 'react'
import "../styles/Blog.css"
import { useNavigate } from "react-router-dom";

function BlogSideBarComponent({ title, description, date ,img ,id}) {
  const navigate = useNavigate();

  return (
    
    <div className='bloag-side-card-container' >
      
        <div className='blog-side-card-content'>
        <img loading="lazy" src={img} alt="" className='blog-side-card-img'/>
        <div className='blog-side-card-Title'>{title}</div>
        <div className='blog-side-card-description'>{description}</div>
      <p className='para-side-bar-blog'>{date}</p>
      </div>
      </div>

      
  )
}

export default BlogSideBarComponent