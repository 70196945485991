import React,{useState} from "react";
// import AccountCard from "./AccountCard";
import CategoryIcon from "@mui/icons-material/Category";
import { useNavigate } from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FavoriteIcon from '@mui/icons-material/Favorite';
import GradeIcon from '@mui/icons-material/Grade';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
// import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import HistoryIcon from '@mui/icons-material/History';
import { Modal } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
// import logo from "../logo.png";
import "../styles/Common.css";
// import GoogleIcon from "@mui/icons-material/Google";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
// import FacebookIcon from "@mui/icons-material/Facebook";
import { Alert } from "@mui/lab";
// import MyOrders from "../pages/MyOrders";
import { TextField } from '@mui/material';
import BcHeading from "../buyerComponents/BcHeading";
import { SettingsInputComponent } from "@mui/icons-material";


export default function HelperMyAccount(props) {
    const navigate = useNavigate();
    const [error]= useState(false);
    const [page, setPage]=useState('');
    const [login]= useState(props.log);
    const [openModal, setOpenModal] = useState(false);
  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);

  const passwordRegexp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{6,20}$)");

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .matches(passwordRegexp,"Password is not valid")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });
  
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      // response()
      // if(success){
        navigate(`/${page}`);
        // }
        // .then(() =>{navigate("/")});
    },
  });

    function goToOrders(){
        setPage('myOrders');
        if(login===false){
            handleModalOpen();
            // setOpenModal(true);
        }
        else{
        navigate(`/myOrders`);
        }
                // navigate('/myOrders');

    }

    function goToAddresses(){
        
        setPage('account/addresses');
        ////console.log(page)
        if(login===false){
            handleModalOpen();
            // setOpenModal(true);
        }
        else{
        navigate(`/account/addresses`);
        }
    }

    function goToWishlist(){
        setPage('wishlist');
        if(login===false){
            handleModalOpen();
            // setOpenModal(true);
        }
        else{ 
        navigate(`/wishlist`);
    }
    }
    function goToReviews(){
        setPage('BuyerReviewsRatings');
        if(login===false){
            handleModalOpen();
            // setOpenModal(true);
        }
        else{
        navigate(`/BuyerReviewsRatings`);
    }
}

    function goToContacts(){
        setPage('account/contact');
        if(login===false){
            handleModalOpen();
            // setOpenModal(true);
        }
        else{
        navigate(`/manageProfile`);
        }
    }

    function goToHistory(){
        setPage('account/history');
        if(login===false){
            handleModalOpen();
            // setOpenModal(true);
        }
        else{
        navigate(`/account/history`);
    }
    }
  return (
    <div className="helper-my-account-container">
      <div className="my-account-action-card" onClick={goToOrders}>
        <div>
          <CategoryIcon sx={{ color: "rgba(248, 152, 128, 1)", fontSize: "2rem" }} />
        </div>

        <BcHeading text='Orders' />
      </div>
      <div className="my-account-action-card" onClick={goToAddresses}>
        <div>
          <LocationOnIcon sx={{ color: "rgba(248, 152, 128, 1)", fontSize: "2rem" }} />
        </div>
        <BcHeading text='Addresses' />
      </div>
      <div className="my-account-action-card" onClick={goToWishlist}>
        <div>
          <FavoriteIcon sx={{ color: "rgba(248, 152, 128, 1)", fontSize: "2rem" }} />
        </div>
        <BcHeading text='Wishlist' />
      </div>
      <div className="my-account-action-card" onClick={goToReviews}>
        <div>
          <GradeIcon sx={{ color: "rgba(248, 152, 128, 1)", fontSize: "2rem" }} />
        </div>
        <BcHeading text='Reviews and Ratings' />
      </div>
      {/* <div className="my-account-action-card" onClick={goToHistory}>
        <div>
          <HistoryIcon sx={{ color: "rgba(248, 152, 128, 1)", fontSize: "2rem" }} />
        </div>
        <BcHeading text='Browsing History' />
      </div> */}
      <div className="my-account-action-card" onClick={goToContacts}>
        <div>
          <ContactSupportIcon sx={{ color: "rgba(248, 152, 128, 1)", fontSize: "2rem" }} />
        </div>
        <BcHeading text='Contact Us' />
      </div>
      <div className="my-account-action-card" onClick={goToContacts}>
        <div>
          <SettingsInputComponent sx={{ color: "rgba(248, 152, 128, 1)", fontSize: "2rem" }} />
        </div>
        <BcHeading text='Settings' />
      </div>

      
      <Modal open={openModal} onClose={handleModalClose}>
        <div className="login-modal">
        <div className="login-page-card" style={{width:"100%"}}>
        
        {error&& <Alert severity="error" className="login-page-error-toast">Invalid Email or Password</Alert>}

      <div className="login-page-card-login">LOGIN</div>
      <form className="login-page-form" onSubmit={formik.handleSubmit}>
        <div className="login-page-email-field">
          <TextField
            autoFocus
            variant="outlined"
            fullWidth
            type="text"
            id="login-page-email"
            name="email"
            className="login-email"
            placeholder="Enter Email Address * "
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <MailOutlineIcon
            className="login-email-icon"
            fontSize="medium "
          />
        </div>

       
        <div className="login-page-password-field">
          <TextField
            variant="outlined"
            type="text"
            id="login-page-password"
            name="password"
            fullWidth
            className="login-password"
            placeholder="Enter Your Password * "
            value={formik.values.password}
            onChange={formik.handleChange}
            error={
              formik.touched.password && Boolean(formik.errors.password)
            }
            helperText={formik.touched.password && formik.errors.password}
          />
          <LockOpenIcon
            className="login-password-icon"
            fontSize="medium "
          />
        </div>
     

        <label className="login-page-checkbox">
          <input
            type="checkbox"
            style={{ fontSize: "5em", marginRight: "10px",transform:"scale(1.5)"}}
            name="checkbox"
            value="value"
          />
          Remember me?
        </label>

        <input type="submit" className="submit-button sign-in-button" value="Sign In" />
      </form>
      <div className="login-page-forgot">
        <span
          onClick={() => {
            navigate("/reset");
          }}
        >
          Forget Password?
        </span>
      </div>
      <div style={{ display: "flex", flexDirection: "row", width: "60%" }}>
        <hr style={{ width: "45%", height: " 0px", alignSelf: "center" }} />
        <p style={{ fontSize: "1.8rem", alignSelf: "center" }}>or</p>
        <hr style={{ width: "45%", height: " 0px", alignSelf: "center" }} />
      </div>
      <div className="login-page-social">
        {/* <GoogleIcon className="login-page-google" fontSize="large" /> */}
        <PhoneAndroidIcon
          className="login-page-otp"
          fontSize="large"
          onClick={() => {
            navigate("/otp");
          }}
        />
        {/* <FacebookIcon className="login-page-facebook" fontSize="large" /> */}
      </div>
      <div className="login-page-signUp">
        New User?{" "}
        <span
          onClick={() => {
            navigate("/signup");
          }}
        >
          Sign Up
        </span>
      </div>
      <div className="login-page-terms">
        By continuing, you agree to MyhraKi's
        <span>Terms and conditions</span> and <span>privacy policy</span>
      </div>
    </div>
    </div>
      </Modal>
    </div>
  );
}
