import React, { useContext, useEffect, useState } from 'react'
import LogisticAdminLayoutAdjustment from '../components/LogisticAdminLayoutAdjustment'
import '../styles/LogisticsAdmin.css'
import { useNavigate } from 'react-router-dom/dist';
import { LogisticsAdminContext } from '../context/LogisticsAdminContext';
import { API_BASE_URL } from '../App';

function LogisticAdminCancelOrder() {
    const [orderData, setOrderData] = useState([ ]);
    const { accessToken } = useContext(LogisticsAdminContext);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(`${API_BASE_URL}/logistic/api/v1/GetAllOrder`, {
              method: 'GET',
              headers: {
                // 'Accept': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
            });
    
            if (response.ok) {
              const data = await response.json();
              setOrderData(data); 
            } else {
              console.error('Failed to fetch data');
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, []);

    const navigate = useNavigate();
  return (
    <LogisticAdminLayoutAdjustment>

    <div className='logistic-orders'>
    <div className='logistic-order-details'>

        <div className='logistic-order-table-item'>Order Id</div>
        <div className='logistic-order-table-item'>Courier AWB</div>
        <div className='logistic-order-table-item'>Courier Provide</div>
        <div className='logistic-order-table-item'>Status</div>
    </div>

    {orderData?.result?.map((order)=>{
                return(
                <div className='logistic-order-details-tr'key={order.orderId}  onClick={()=>{navigate(`/cancel/${order.orderId}`)}}>

        <div className='logistic-order-table-item'>Order # {order.orderID}</div>
        <div className='logistic-order-table-item'>{order.courierAWB}</div>
        <div className='logistic-order-table-item'>{order.courierProviderName}</div>
        <div className='logistic-order-table-item'> <div className='status-button'>{order.status}</div></div>
        
    </div>)
                }
                )}
</div> 
</LogisticAdminLayoutAdjustment>
  )
}

export default LogisticAdminCancelOrder