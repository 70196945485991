import React from "react";
import "../styles/AdminOtpLogin.css";
// import "../styles/AdminOtpLoginPhone.css"
import "../styles/Common.css";
// import "../styles/SellerOtpLogin.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { API_BASE_URL } from '../App'

import {CircularProgress, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import adminLeftLogo from "../Images/adminforgot.png";
import CallIcon from "@mui/icons-material/Call";
// import { Context } from "../Context/Store";
import { useContext } from "react";
import { errorAlert, successAlert } from "../components/Alert";
import { AdminContext } from "../context/AdminContext";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";

const phoneRegExp = /^(?!\s)([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}(?<!\s)$/;
// const phoneRegExp = /^(?!\s)([6789]{1})([\d]{3})([\s]?[\d]{3}){2}$/;

const validationSchema = yup.object({
  number: yup
    .string()
    .trim()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required*")
    .min(10, "Phone number must consist of 10 digits")
    .max(10, "Phone number must consist of 10 digits"),
});



export default function AdminOtpLogin() {
  const navigate = useNavigate();
  const [loading, setLoading ] = useState(false);
  // const [loginInProgress, setLoginInProgress] = useState(false)
  const {setPhoneNumberForOtp} = useContext(AdminContext)

  const formik = useFormik({
    initialValues: {
      number: "",
    },
    validationSchema: validationSchema,
    onSubmit:async () => {
      // navigate("/adminOtpVerify");
      await postForOtp();
      setPhoneNumberForOtp(formik.values.number);
      // successAlert('','OTP sent Successfully')
    },
  });

  async function postForOtp() {
    setLoading(true)
    try {
      let response = await fetch(
        `${API_BASE_URL}/users/api/v1/otp?mobileNumber=${formik.values.number.trim()}&userType=${1}`,
        {
          headers: {
            // Accept: "application.json",
            // "Content-type": "application.json",
          },
         
        }
      );
      const result = await response.json()
      //console.log("Message", result.message);
      if(result?.result>0){
        successAlert('','OTP sent Successfully')
 
        navigate("/AdminOtpVerify");
        setPhoneNumberForOtp(formik.values.number.trim())
        setLoading(false)
      }
      else if(result?.message === 'Maximum attempts! try next day'){

        errorAlert('','Maximum attempts reached. Try after 24 hours');
        setLoading(false);
        return;
      }     
      else if(result?.message ==="Unsuccessful login - User not found.")
      {
         errorAlert('','Failed to send OTP. Try again.')
         setLoading(false)
         return;
       }
       else if(JSON.parse(result?.message)?.return){
         successAlert('','OTP sent Successfully')
 
         navigate("/AdminOtpVerify");
         setPhoneNumberForOtp(formik.values.number.trim())
         setLoading(false)

       }
      // //console.log(response);
      ////console.log(result);
      // return response;
    } catch (e) {
      errorAlert('','Failed to send OTP. Try again.')

      ////console.log(e);
      setLoading(false)

    }}

   

    const gotoLoginPage=()=>{
      navigate("/");
    }


    

  return (
    <div className="admin-otp-Container">
    {/* <div className="admin-otp-top-left-rect"></div> */}
    {/* <div className="admin-otp-top-right-rect"></div> */}
    {/* <div className="admin-otp-circle"></div> */}
    {/* <div className="admin-otp-btm-left"></div> */}
    {/* <div className="admin-otp-btm-left-bottom"></div> */}
    {/* <div className="admin-otp-btm-left-top"></div> */}

    <div className="admin-otp-left">
      <div className="admin-otp-left-content">
     <img loading="lazy" className="admin-left-img" src={adminLeftLogo} alt=" "  style={{width:"60%"}}/>
     </div>
  </div>
  <div className='admin-otp-right'>
    <div className="admin-otp-right-content">
  
      <div className='admin-otp-login-title'>Sign In With One Time Password</div>
      
      <form className="admin-otp-page-form" onSubmit={formik.handleSubmit}>
        <div className="admin-otp-page-phone">
          <TextField
            variant="outlined"
            fullWidth
            //   autoFocus
            // disabled
            type="text"
            id="number"
            name="number"
            className="admin-login-email"
            placeholder="Enter your Phone Number"
            //   value={state.phone}
            value={formik.values.number.trim()}
            onChange={formik.handleChange}
            error={formik.touched.number && Boolean(formik.errors.number)}
            helperText={formik.touched.number && formik.errors.number}
          />
          <CallIcon className="otp-phone-icon" fontSize="medium " />
        </div>
        {!loading && <input
          type="submit"
          className="common-button"
          value="Send OTP"
        />}
           {loading && <div style={{display:"flex", width:"100%",alignItems:"center",justifyContent:"center"}}><CircularProgress style={{color:"pink" }} /></div>}

      </form>
      <div className="admin-otp-content-desc">
        We need to send verification code to Authenticate your number.
      </div>
      <div className="seller-reset-login">
          <span onClick={gotoLoginPage}>Return to Login</span>
        </div>
      {/* <div className="admin-password-signin"> <span onClick={gotoSignInPage}>Request Admin To change phone number</span></div> */}
      {/* <div className="seller-otp-number-change">Request Admin to change Phone Number</div> */}
      <div className="seller-reset-page-terms">
            By continuing, you agree to MyhraKi's
            <span> Terms and conditions</span> and <span>privacy policy</span>
          </div>
      {/* <div className="admin-otp-auth-text seller-color">
        We will send a verification code to the below number.
      </div> */}
    </div>
    </div>
    {/* //<ToastContaner theme="coloured" className="toast-message" /> */}

  </div>
  );
}
