import React from 'react'
import "../styles/Navbar.css";
import logo from '../Images/MainLogo.png';
import { Avatar, Menu,} from '@mui/material';
import { MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import cart from "../Images/Cart.png";
import account from "../Images/accountIcon.png"
import heart from "../Images/favourites.png";

export default function Navbar() {


  const navigate = useNavigate();

  function gotoAccount(){
    // ////console.log("BBB")
    navigate('/account');
   
  }
  function gotoHomePage(){
    ////console.log("BBB")
    navigate('/');

  }
  function gotoOrders(){
    // ////console.log("BBB")
    navigate('/myOrders');

  }
  function gotoWishlist(){
    // ////console.log("BBB")
    navigate('/wishlist');

  }
  function gotoProfile(){
    // ////console.log("BBB")
    navigate('/home');

  }
  function gotoCart(){
    // ////console.log("BBB")
    navigate('/cart');

  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="navbar-container">
    <div className="navbar-left">
      <img loading="lazy" className="navbar-logo" src={logo} alt="logo" onClick={gotoHomePage}/>
      <div className="navbar-logo-name"></div>
      <div className="navbar-search-bar">
          <SearchOutlinedIcon className="navbar-search-icon" icon="@Icons.Filled.Search" fontSize='medium'/>
          {/* <img loading="lazy" src={SearchIcon} alt='' className="navbar-search-icon" fontSize='medium'/> */}
        <input className="navbar-search" type="string" placeholder="search" />
      </div>
    </div>
    <div className="navbar-right">
      <div className="navbar-icons">
        <img loading="lazy" src={heart} alt='' onClick={gotoWishlist} className="navbar-wishlist" fontSize='large' />
        {/* <FavoriteBorderOutlinedIcon icon="@Icons.Filled.FavoriteBorder" className="navbar-wishlist" fontSize='large' onClick={gotoWishlist}/> */}
        <img loading="lazy" src={cart} alt='' className="navbar-cart" onClick={gotoCart}  fontSize="large"/>
        {/* <AccountCircleIcon icon="@Icons.Outlined.AccountCircle" className="navbar-profile"  /> */}
        <div title="Account settings"
            style={{display:'inline-block'}}
            onClick={handleClick}
            size="medium"
            // sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
                  {/* <AccountCircleOutlinedIcon icon="@Icons.Outlined.AccountCircle" className="navbar-profile" fontSize='large' /> */}
                  <img loading="lazy" src={account} alt='' className="navbar-profile" fontSize='large' />
          
        </div>
        <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 5.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: -20,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-80%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem className="menu-item" onClick={gotoProfile}>
          <Avatar /> Profile  
        </MenuItem>
        <MenuItem className="menu-item"onClick={gotoAccount}>
          <Avatar /> My account
        </MenuItem>
        <MenuItem className="menu-item"onClick={gotoOrders}>
          <Avatar /> My Orders
        </MenuItem>
        </Menu>
      </div>
    </div>
  </div>
  )
}
