import React, { useEffect, useState } from 'react';
import "../styles/BuyersNavbar.css"
import logo from "../Images/logo.png"
import { FavoriteBorder, Menu, Person2Outlined, ShoppingCartCheckout } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../App'

export default function BuyersNavbar() {

  const navigate = useNavigate();
    // const data=1;
    const [searchValue, setSearchValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  let debounceTimer = null;

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/productgen/api/v1/search?Text=${searchValue}&PageNumber=1&PageSize=10`);
        const data = await response.json();
    
        const filteredData = data.result.filter((item) => {
          const hasMatchingSKU = item.productSKUs.some((sku) =>
            sku.color.toLowerCase().includes(searchValue.toLowerCase())
          );
          return item.title.toLowerCase().includes(searchValue.toLowerCase()) || hasMatchingSKU;
        });
    
        setSuggestions(filteredData);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    };

    clearTimeout(debounceTimer);

    if (searchValue) {
      debounceTimer = setTimeout(() => {
        fetchSuggestions();
      }, 500);
    } else {
      setSuggestions([]);
    }

    return () => clearTimeout(debounceTimer);
  }, [searchValue]);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
  };



  return (
    <div className="buyers-navbar">
        <img loading="lazy" src={logo} />
        <div className="categories-button"> <Menu /> Categories</div>
        <div className="main-categories">
        <div className="main-categories-item" onClick = {()=>{navigate('/productFilter')}}>Home</div>
        <div className="main-categories-item">Men</div>
        <div className="main-categories-item">Women</div>
        <div className="main-categories-item">Kids</div>
        <div className="main-categories-item">Others</div>

        </div>
        <div className="buyers-searchbar">
      <TextField
        className="buyers-search-field"
        value={searchValue}
        onChange={handleSearchChange}
        // label="Search"
        placeholder='Search'
        variant="outlined"
      />
      {suggestions.length > 0 && (
        <ul className="buyers-search-suggestion-list">
          {suggestions.map((suggestion, index) => (
            <li className="buyers-search-suggestion" key={index} onClick={()=>{navigate(`/singleProduct/${suggestion?.productId}`)}}>
              {searchValue} in {suggestion.title}
            </li>
          ))}
        </ul>
      )}   
    </div>
        <div className="buyers-nav-icons">
            <FavoriteBorder onClick ={()=>{navigate('/wishlist')}}/>
            <ShoppingCartCheckout  onClick ={()=>{navigate('/cart')}}/>
            <Person2Outlined onClick ={()=>{navigate('/account')}}/>
        </div>
    </div>
  )
} 
