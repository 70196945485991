import React, { useEffect, useState } from "react";
import "../styles/BuyersMyAccount.css";
import "../styles/Cart.css";
import "../styles/AddressCard.css"
import Card from "../components/Card";
// import data from "../Data";
import { useFormik } from "formik";
import ClearIcon from "@mui/icons-material/Clear";
// import * as yup from "yup";
// import valid from "card-validator";
import VerifiedIcon from "@mui/icons-material/Verified";
import {  useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../App";

import { Divider, TextField } from "@mui/material";
import ShoppingCartCheckoutRoundedIcon from "@mui/icons-material/ShoppingCartCheckoutRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
// import AddressData from "../AddressData";
import AddressCard from "../components/AddressCard";
import PrdouctFilterCard from "../components/PrdouctFilterCard";
import productData from "../ProductData";
import Heading from "../components/Heading";
import BuyersLayout from "../components/BuyersLayout";
import { useContext } from "react";
import { CustomerContext } from "../context/CustomerContext";

// const fullNameRegExp = /^[A-Z][a-zA-Z]{3,}(?: [A-Z][a-zA-Z]*){0,2}$/;

// const validationSchema = yup.object({
//   // email: yup
//   //   .string("Enter your email")
//   //   .email("Enter a valid email")
//   //   .required("Email is required"),
//   // password: yup
//   //   .string("Enter your password")
//   //   .min(8, "Password should be of minimum 8 characters length")
//   //   .required("Password is required"),
//   // fullname: yup
//   //   .string("Enter fullname")
//   //   .required("Full Name is Required")
//   //   .min(3, "Full Name should be 3 chars atleast")
//   //   .matches(fullNameRegExp, "Invalid Full Name"),
//   cardNumber: yup
//     .string()
//     .test(
//       "test-number", // this is used internally by yup
//       "Invalid Card number", //validation message
//       (value) => valid.number(value).isValid
//     ) // return true false based on validation
//     .required("Card Number is Required"),
//   cardHolder: yup
//     .string()
//     .test(
//       "test-text", // this is used internally by yup
//       "Invalid Name", //validation message
//       (value) => valid.cardholderName(value).isValid
//     ) // return true false based on validation
//     .required(),
//   cardExpiration: yup
//     .string()
//     .test(
//       "test-number", // this is used internally by yup
//       "Invalid Expiry Date", //validation message
//       (value) => valid.expirationDate(value).isValid
//     ) // return true false based on validation
//     .required(),
//   cardCVV: yup
//     .string()
//     .test(
//       "test-number", // this is used internally by yup
//       "Invalid CVV", //validation message
//       (value) => valid.cvv(value).isValid
//     ) // return true false based on validation
//     .required(),
//   firstName: yup
//     .string("Enter Your First Name")
//     .required("First Name is required*"),
//   lastName: yup
//     .string("Enter Your last Name")
//     .required("Last Name is required*"),
//   street: yup
//     .string("Enter Street Address")
//     .required("Street Address is required"),
//   landmark: yup.string("Enter Landmark").required("Landmark is required"),
//   pincode: yup
//     .number("Enter Pin Code")
//     .required("Pincode is required")
//     .min(6, "Minimum 6 digits required"),
// });
export default function Cart() {
  const [token] = useState(true);
  const [cartLayout, setCartLayout] = useState("items");
  const [selectedAddress, setSelectedAddress] = useState();
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [success] = useState(true);
  const [cartData, setCartData] = useState( [
      {
        "cartId": 7,
        "itemId": 22,
        "imageFilename": "https://picsum.photos/id/1015/250/150/",
        "title": "Miss Chase Women Apparel",
        "description": "aaaaaaaaa",
        "sku": "1",
        "qty": 1,
        "price":200,
        "sellingPrice":150,
      },
      {
        "cartId": 7,
        "itemId": 23,
        "imageFilename": "https://picsum.photos/id/1015/250/150/",
        "title": "Miss Chase Womens Apparel",
        "description": "aaaaaaaaa",
        "sku": "0",
        "qty": 1,
  "price":200,
        "sellingPrice":150,
      },
    ]
  );
  // const [orderDetails, setOrderDetails] = useState()
  const {customerData, accessToken} = useContext(CustomerContext);
  const [deliveryAddressData, setDeliveryAddressData] = useState([]);
  // const [billingAddressData, setBillingAddressData] = useState({});
  // const [amount, setAmount] = useState();
  // const [cartId, setCartId] = useState();





  async function getCartData(){
    // //console.log(buyerData)
    try{
      const response = await fetch(
        `${API_BASE_URL}/cart/api/v1/getbymail/${customerData?.result?.email}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      const data = await response.json();
      console.log(data);
      // setCartData(data?.result)
      // return data;
    }
    catch(e){
      //console.log(e);
    }
  }

  const fetchAddressData = async () => {
    const response = await fetch( `${API_BASE_URL}/address/api/v1/getall?userId=${customerData?.result?.userID}`, {
      headers: {
          Authorization:`Bearer ${accessToken}`
      },
    });
    const data = await response.json();
    setDeliveryAddressData(data?.result);
  };

  useEffect(() => {
    fetchAddressData();
  }, []);

  const calculateTotalAmount = (cartData) => {
    let totalAmount = 0;
    let totalDiscount = 0;
  
    cartData.forEach((item) => {
      const itemTotal = item.unitCost * item.qty;
      const discountTotal = item?.oldUnitCost * item.qty;
      totalAmount += itemTotal;
      totalDiscount += discountTotal;
    });
  
    return { totalAmount, totalDiscount };
  };
  
  // Example usage:
  const { totalAmount, totalDiscount } = calculateTotalAmount(cartData);
  // //console.log('Total Amount:', totalAmount);
  // //console.log('Total Discount:', totalDiscount);

  useEffect(()=>{
    getCartData()
},[]);

  // eslint-disable-next-line
  async function getPincode(code) {
    await fetch(`https://api.postalpincode.in/pincode/${code}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        // ////console.log(res);
        // ////console.log(res[0].PostOffice[0].Country);
        // ////console.log(res[0].PostOffice[0].State);
        // ////console.log(res[0].PostOffice[0].District);
        setCountry(res[0].PostOffice[0].Country);
        setState(res[0].PostOffice[0].State);
        setCity(res[0].PostOffice[0].District);
        ////console.log(country);
        ////console.log(state);
        ////console.log(city);
      });
  }

  const selectAddress = (x) => {
    document
      .querySelector(`.cartAddress-${x}`)
      .setAttribute("class", "selected-address-card");
    setSelectedAddress();
    setSelectedAddress(x);
    // ////console.log(x);
  };
  const proceedToShipping = () => {
    document.getElementById("step-1").classList.add("completed");
    setCartLayout("address");
  };
  const proceedToPayment = () => {
    document.getElementById("step-2").classList.add("completed");

    setCartLayout("payment");
  };
  const goBackToShipping = () => {
    document.getElementById("step-2").classList.remove("completed");
    document.getElementById("step-3").classList.remove("completed");

    setCartLayout("address");
  };
  const  goBackToCart = () => {
    document.getElementById("step-1").classList.remove("completed");
    document.getElementById("step-2").classList.remove("completed");

    setCartLayout("cartAddress");
  };
  
  const navigate = useNavigate();
  const  backToHomePage=()=>{
    navigate("/");
  }
 
  // const proceedToOrderConfirmation = () => {
  //   document.getElementById("step-3").classList.add("completed");

  //   setCartLayout("orderConfirmation");
  // };
  useEffect(() => {
    if (cartLayout === "orderConfirmation" && success) {
      document.getElementById("step-4").classList.add("completed");
    }
  }, [cartLayout, success]);
  const formik = useFormik({
    initialValues: {
      // email: "",
      // password: "",
      cardNumber: "",
      cardHolder: "",
      cardExpiration: "",
      cardCVV: "",
      landmark: "",
      street: "",
      firstName: "",
      lastName: "",
      pincode: "",
    },
    // validationSchema: validationSchema,
    onSubmit: () => {
      document.getElementById("step-3").classList.add("completed");

      setCartLayout("orderConfirmation");
      if (cartLayout === "orderConfirmation") {
        document.getElementById("step-4").classList.add("completed");
      }
    },
  });

  useEffect(() => {
    if (formik.values.pincode.length === 6) {
      getPincode(formik.values.pincode);
    }
  }, [formik.values.pincode,getPincode]);


  const [isModalOpen, setIsModalOpen] = useState(false);

  function closeModal() {
    setIsModalOpen(false);
  }
  
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  
  function handleClick(event) {
    event.currentTarget.classList.toggle('highlighted');
    setIsModalOpen(!isModalOpen); // Toggle the isModalOpen state
  }
  
  return (
    <BuyersLayout>
    <div className="buyers-my-account ">
      {/* <Header /> */}
      <div className="page-content">
        <TrackCheckout />
        {cartLayout === "items" && 
         <div className="my-cart-head"><Heading text="My Cart"></Heading>
         </div>
         }
        {cartLayout === "address" && (
          <div className="my-cart-head"><Heading text="Shipping"></Heading>
      

          </div>
        )}
        {cartLayout === "payment" && (
          <div className="my-cart-head"><Heading text="Payments"></Heading>
         
          </div>
        )}
        {cartLayout === "orderConfirmation" && !success && (
          <div className="my-cart-head">Payment Error </div>
        )}
        {cartLayout === "orderConfirmation" && success && (
          <div className="my-cart-head"> <Heading text="Order Confirmation"></Heading></div>
        )}

        {!token && (
          <div className="cart-redirect-login">
            <WarningAmberRoundedIcon className="warning-icon-red" />
            <div>Please Login Now to continue your shopping</div>
            <div className="login-link">LOGIN</div>
          </div>
        )}
        <div className="cart-layout">
          {cartLayout === "items" && (
            <div className="cart-card-layout">
              {cartData?.map((cardProps) => {
                  return <Card cartCard {...cardProps} />;
               
              })}
            </div>
          )}
          {cartLayout === "address" && (
            <div>
             <div className="return-to-shipping" onClick={goBackToCart}>
             {" "}
             &lt; Return to Cart{" "}
           </div>
            <div className="cart-card-layout cart-card-layout-border">
           
              <div className="cart-email-field">
                <div className="cart-email-heading">Shipping Information</div>
                <TextField
                  autoFocus
                  variant="outlined"
                  sx={{ width: "25rem" }}
type="text"                  id="email"
                  name="email"
                  className="login-email-cart"
                  placeholder="Enter Email Here "
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <div className="cart-address-heading">Delivery Address</div>
              </div>
              <div className="cart-address-cards">
                {deliveryAddressData.map((cardProps) => {
                  return (
                    <div
                      className={`cartAddress-${cardProps.id} `}
                      onClick={() => {
                        selectAddress(cardProps.id);
                      }}
                    >
                      <div className="card-address-cards">
                        <AddressCard {...cardProps} />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="cart-address-button-field">
              <button className="byr-common-button" onClick={handleClick}>
          Add Address</button> 
          </div>
          <div className="cart-address-heading">Billing Address</div>
          
          <label className="admin-login-page-checkbox seller-login-page-checkbox">
              <input
                type="checkbox"
                style={{
                  fontSize: "5em",
                  marginRight: "10px",
                  transform: "scale(1.5)",
                }}
                name="checkbox"
                value="value"
              />
             Same as Delivery Address
            </label>
          <div className="cart-address-cards">
                {deliveryAddressData?.map((cardProps) => {
                  return (
                    <div
                      className={`cartAddress-${cardProps.id} `}
                      onClick={() => {
                        selectAddress(cardProps.id);
                      }}
                    >
                      <div className="card-address-cards">
                        <AddressCard {...cardProps} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          )}
        {isModalOpen && (
      <div className="add-address-shipping">
  <div className="coupon-modal" id="couponModal">
     <ClearIcon className="address-close-button" onClick={handleModalClose} />
    <div className="modal-address-content">
      <h2>Add Address </h2>
      <form className="fact-modal-form">
       <div className="address-form-field">
          <div  className="field-name">FullName:</div>
         <div className="textfield-add-address-shipping"><TextField
            id="fullName"
            name="fullName"
            variant="outlined"
            fullWidth
            placeholder="Full Name"
            margin="normal"
          />
          </div> 
        </div>
         {/* <div className="address-form-field">
  <div  className="field-name">Country:</div>
  <div className="offer-type-dropdown">
    <Select
      name="country"
      variant="outlined"
      fullWidth
      displayEmpty
      defaultValue=""
      className="select-field"
    >
      <MenuItem value="" disabled>
        Select Country
      </MenuItem>
      <MenuItem value="country1">Country 1</MenuItem>
      <MenuItem value="country2">Country 2</MenuItem>
      <MenuItem value="country3">Country 3</MenuItem>
    </Select>
  </div>
</div>
<div className="address-form-field">
  <div  className="field-name">State:</div>
  <div className="offer-type-dropdown">
    <Select
      name="state"
      variant="outlined"
      fullWidth
      displayEmpty
      defaultValue=""
      className="select-field"
    >
      <MenuItem value="" disabled>
        Select State
      </MenuItem>
      <MenuItem value="state1">State 1</MenuItem>
      <MenuItem value="state2">State 2</MenuItem>
      <MenuItem value="state3">State 3</MenuItem>
    </Select>
  </div>
</div>
  <div className="address-form-field">

  <div  className="field-name">City:</div>
  <div className="offer-type-dropdown">
    <Select
      name="city"
      variant="outlined"
      fullWidth
      displayEmpty
      defaultValue=""
      className="select-field"
    >
      <MenuItem value="" disabled>
        Select City
      </MenuItem>
      <MenuItem value="city1">City 1</MenuItem>
      <MenuItem value="city2">City 2</MenuItem>
      <MenuItem value="city3">City 3</MenuItem>
    </Select>
  </div>
</div> */}
        
         <div className="address-form-field">
          <div  className="field-name">Street Address:</div>
         <div  className="textfield-add-address-shipping"> <TextField
          name="streetAddress"
          label="Street Address"
          variant="outlined"
          fullWidth
          placeholder="Street Address"
          margin="normal"
        />
          </div> 
        </div>
         <div className="address-form-field">
          <div  className="field-name">Landmark:</div>
         <div  className="textfield-add-address-shipping">     <TextField
          name="landmark"
          label="Landmark"
          variant="outlined"
          fullWidth
          placeholder="Landmark"
          margin="normal"
        />
          </div> 
        </div>
        
         <div className="address-form-field">
          <div  className="field-name">PIN:</div>
         <div  className="textfield-add-address-shipping">
         <TextField
          name="pin"
          label="PIN"
          variant="outlined"
          fullWidth
          placeholder="PIN"
          margin="normal"
        />
          </div> 
        </div>
         <div className="address-form-field">
          <div  className="field-name">Contact:</div>
         <div  className="textfield-add-address-shipping">
           
        <TextField
          name="contact"
          label="Contact"
          variant="outlined"
          fullWidth
          placeholder="Contact"
          margin="normal"
        />
          </div> 
        </div>
       
<div className="add-addres-button-fields">
        <button type="submit" className="byr-common-button">Add Address</button>  
        <button type="submit" className="byr-common-button">Cancel</button>
        </div>
      </form>
    </div>
  </div>
  </div>
)}
          {cartLayout === "payment" && (
            <div>
             <div className="return-to-shipping" onClick={goBackToShipping}>
             {" "}
             &lt; Return to Shipping{" "}
           </div>
            <div className="cart-card-layout cart-card-layout-border">
              <form
                className="payment-page-form"
                onSubmit={formik.handleSubmit}
              >
                <div className="payment-field-heading">Card Number *</div>
                <div className="payment-page-form-field">
                  <TextField
                    autoFocus
                    variant="outlined"
                    fullWidth
  type="text"                    id="payment-form-card-number"
                    name="cardNumber"
                    className="payment-form-field-row"
                    placeholder="XXXX-XXXX-XXXX-XXXX"
                    value={formik.values.cardNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.cardNumber &&
                      Boolean(formik.errors.cardNumber)
                    }
                    helperText={
                      formik.touched.cardNumber && formik.errors.cardNumber
                    }
                  />
                </div>
                <div className="payment-field-heading">
                  Name of Card Holder *
                </div>

                <div className="payment-page-form-field">
                  <TextField
                    variant="outlined"
                    fullWidth
  type="text"                    id="payment-form-card-holder-name"
                    name="cardHolder"
                    className="payment-form-field-row"
                    placeholder="NAME "
                    value={formik.values.cardHolder}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.cardHolder &&
                      Boolean(formik.errors.cardHolder)
                    }
                    helperText={
                      formik.touched.cardHolder && formik.errors.cardHolder
                    }
                  />
                </div>
                <div className="payment-expiry-cvv">
                  <div className="payment-expiry">
                    <div className="payment-field-heading">Expiry Date *</div>

                    <TextField
                      variant="outlined"
                      fullWidth
    type="text"                      id="payment-form-expiry-date"
                      name="cardExpiration"
                      className="payment-form-field-row-expiry"
                      placeholder="MM/YY "
                      value={formik.values.cardExpiration
                        .replace(/^(\d\d)(\d)$/g, "$1/$2")
                        .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
                        .replace(/[^\d/]/g, "")}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.cardExpiration &&
                        Boolean(formik.errors.cardExpiration)
                      }
                      helperText={
                        formik.touched.cardExpiration &&
                        formik.errors.cardExpiration
                      }
                    />
                  </div>
                  <div className="payment-cvv">
                    <div className="payment-field-heading">CVV *</div>

                    <TextField
                      variant="outlined"
                      fullWidth
    type="text"                      id="payment-form-cvv"
                      name="cardCVV"
                      className="payment-form-field-row-expiry"
                      placeholder="XXX"
                      value={formik.values.cardCVV}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.cardCVV && Boolean(formik.errors.cardCVV)
                      }
                      helperText={
                        formik.touched.cardCVV && formik.errors.cardCVV
                      }
                    />
                  </div>
                </div>
                <div className="payment-field-heading">Billing Information</div>
                <div className="payment-field-dual">
                  <div className="payment-page-form-field">
                    <TextField
                      variant="outlined"
                      fullWidth
    type="text"                      id="payment-form-firstName"
                      name="firstName"
                      className="payment-form-field-row"
                      placeholder="First Name "
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </div>
                  <div className="payment-page-form-field">
                    <TextField
                      variant="outlined"
                      fullWidth
    type="text"                      id="payment-form-lastName"
                      name="lastName"
                      className="payment-form-field-row"
                      placeholder="Last Name "
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </div>
                </div>
                <div className="payment-page-form-field field-spacing">
                  <TextField
                    variant="outlined"
                    fullWidth
  type="text"                    id="payment-form-street"
                    name="street"
                    className="payment-form-field-row"
                    placeholder="Street Address"
                    value={formik.values.street}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.street && Boolean(formik.errors.street)
                    }
                    helperText={formik.touched.street && formik.errors.street}
                  />
                </div>
                <div className="payment-page-form-field field-spacing">
                  <TextField
                    variant="outlined"
                    fullWidth
  type="text"                    id="payment-form-landmark"
                    name="landmark"
                    className="payment-form-field-row"
                    placeholder="Apt Residencial, landmark etc.."
                    value={formik.values.landmark}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.landmark && Boolean(formik.errors.landmark)
                    }
                    helperText={
                      formik.touched.landmark && formik.errors.landmark
                    }
                  />
                </div>
                <div className="payment-zipcode">
                  <div className="payment-zip">
                    <TextField
                      variant="outlined"
                      fullWidth
    type="text"                      id="parent-form-city"
                      name="city"
                      className="payment-form-field-row-expiry"
                      placeholder="City"
                      value={city}
                      disabled
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="payment-zip">
                    <TextField
                      variant="outlined"
                      fullWidth
    type="text"                      id="parent-form-state"
                      name="state"
                      className="payment-form-field-row-expiry"
                      placeholder="State"
                      value={state}
                      disabled
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="payment-zip pincode">
                    <TextField
                      variant="outlined"
                      fullWidth
    type="text"                      id="payment-form-pincode"
                      name="pincode"
                      className="payment-form-field-row-expiry"
                      placeholder="ZipCode"
                      value={formik.values.pincode}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.pincode && Boolean(formik.errors.pincode)
                      }
                      helperText={
                        formik.touched.pincode && formik.errors.pincode
                      }
                    />
                  </div>
                </div>
                <div className="payment-form-total">
                  <div className="payment-field-heading form-total">
                    TOTAL AMOUNT
                  </div>
                  <div className="payment-field-heading form-total">
                    ₹ 10,000
                  </div>
                </div>
                <input
                  type="submit"
                  className="byr-common-button full-width-button-byr"
                  value="Pay ₹ 10,000"
                  // onClick={proceedToOrderConfirmation}
                />
              </form>
             
              <div className="mandatory">*All fields are mandatory</div>
            </div>
            </div>
          )}
          {cartLayout === "orderConfirmation" && !success && (
            <div className="cart-card-layout cart-card-layout-border">
              <div className="payment-error">
                <div className="payment-error-message">
                  Oops ! Payment Failed
                </div>
                <div className="payment-error-order-id">
                  Payment for Order ID 12345678 could not be proceed.
                  <br /> Try Again.
                </div>
                <div className="payment-form-total error-payment-field">
                  <div className="payment-field-heading form-total error-payment">
                    TOTAL AMOUNT
                  </div>
                  <div className="payment-field-heading form-total error-payment">
                    ₹ 10,000
                  </div>
                </div>
                <input
                  type="submit"
                  className="submit-button full-width-button"
                  value="Pay ₹ 10,000"
                  // onClick={proceedToOrderConfirmation}
                />
              </div>
              <div className="return-to-shipping" onClick={goBackToShipping}>
                &lt; Return to Shipping
              </div>
            </div>
          )}
          {cartLayout === "orderConfirmation" && success && (
            <div>
             <div className="return-to-shipping" onClick={backToHomePage}> &lt; Back to home page</div>
            <div
              className="cart-card-layout cart-card-layout-border"
              style={{ width: "80%" }}
            >
              <div className="payment-success">
                <VerifiedIcon className="verified-icon" />
                <div className="payment-success-title">
                  THANK YOU FOR THE ORDER
                </div>
                <div className="payment-success-message">
                  Your order has been placed successfully and is being
                  processed.
                  <br />
                  You will receive an email with the order details.
                </div>
               
              </div>
            </div>
            </div>
          )}

          {cartLayout !== "orderConfirmation" && success && (
            <div className="cart-checkout-layout">
              <div className="cart-order-summary-text">
                Order Summary <span className="items-count">(4 Items)</span>
              </div>
              <div className="cart-checkout-row">
                <div className="cart-checkout-amount-text">Subtotal</div>
                <div className="cart-checkout-amount">₹ {totalAmount}</div>
              </div>
              <div className="cart-checkout-row">
                <div className="cart-checkout-amount-text">Total Discount</div>
                <div className="cart-checkout-amount">₹ {totalDiscount}</div>
              </div>
              <div className="cart-checkout-row">
                <div className="cart-checkout-amount-text">
                  Estimated Delivery
                </div>
                <div className="cart-checkout-amount">Tomorrow</div>
              </div>
              <Divider className="divider" />
              <div className="cart-checkout-row-dark">
                <div className="cart-checkout-amount-text">Total</div>
                <div className="cart-checkout-amount">₹ {totalAmount-totalDiscount}</div>
              </div>
              {token &&
                (cartLayout === "items" || cartLayout === "address") && (
                  <div className="checkout-button">
                    <ShoppingCartCheckoutRoundedIcon />
                    {cartLayout === "items" && (
                      <div onClick={proceedToShipping}>Proceed to CheckOut</div>
                    )}

                    {!selectedAddress && cartLayout === "address" && (
                      <div>Proceed to CheckOut</div>
                    )}
                    {selectedAddress && cartLayout === "address" && (
                      <div onClick={proceedToPayment}>Proceed to CheckOut</div>
                    )}
                    {cartLayout === "payment" && selectedAddress && (
                      <div onClick={proceedToPayment}>Proceed to CheckOut</div>
                    )}
                  </div>
                )}
              {!token && (
                <div className="checkout-button-disabled">
                  <ShoppingCartCheckoutRoundedIcon />
                  <div onClick={proceedToShipping}>Proceed to CheckOut</div> 
                </div>
              )}
              {/* {!selectedAddress&&} */}
            </div>
          )}
        </div>
        {cartLayout === "items" && (
          <div>
            {/* <div className="related-styling">
              <div className="related-styling-text">RELATED ITEMS</div>
              <div className="related-styling-underline"> </div>
            </div> */}
            <div className="product-image-cards">
              {productData.slice(0, 4).map((cardProps) => {
                return <PrdouctFilterCard id={`${cardProps.productId}`} />;
              })}
            </div>
          </div>
        )}
         {cartLayout === "address" && (
          <div>
            <div className="related-styling">
              <div className="related-styling-text">RELATED ITEMS</div>
              <div className="related-styling-underline"> </div>
            </div>
            <div className="product-image-cards">
              {productData.slice(0, 4).map((cardProps) => {
                return <PrdouctFilterCard id={`${cardProps.productId}`} />;
              })}
            </div>
          </div>
        )}
         {cartLayout === "payment" && (
          <div style={{marginTop:"9rem"}}>
            <div className="related-styling">
              <div className="related-styling-text">RELATED ITEMS</div>
              <div className="related-styling-underline"> </div>
            </div>
            <div className="product-image-cards">
              {productData.slice(0, 4).map((cardProps) => {
                return <PrdouctFilterCard id={`${cardProps.productId}`} />;
              })}
            </div>
          </div>
        )}
                 {cartLayout === "orderConfirmation" && (
          <div>
            <div className="related-styling">
              <div className="related-styling-text">RELATED ITEMS</div>
              <div className="related-styling-underline"> </div>
            </div>
            <div className="product-image-cards">
              {productData.slice(0, 4).map((cardProps) => {
                return <PrdouctFilterCard id={`${cardProps.productId}`} />;
              })}
            </div>
          </div>
        )}
      </div>
    </div>
    </BuyersLayout>
  );
}

const TrackCheckout = () => {
  return (
    <div class="stepper-wrapper">
      <div id="step-1" class="stepper-item">
        <div class="step-counter default"></div>
        <div class="step-name">Cart</div>
      </div>
      <div id="step-2" class="stepper-item ">
        <div class="step-counter"></div>
        <div class="step-name">Shipping</div>
      </div>
      <div id="step-3" class="stepper-item">
        <div class="step-counter"></div>
        <div class="step-name">Payment</div>
      </div>
      <div id="step-4" class="stepper-item">
        <div class="step-counter"></div>
        <div class="step-name">Order Confirmation</div>
      </div>
    </div>
  );
};
