import { Verified } from '@mui/icons-material';
import React, { useState, useEffect, useContext } from 'react';
import "../styles/SingleProductReviews.css"
import {  useNavigate, useParams } from "react-router-dom";
import { CustomerContext } from '../context/CustomerContext';
// import Star from "@mui/icons-material/Star";
import { UserCircle, SealCheck, Star} from '@phosphor-icons/react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { API_BASE_URL } from '../App'
// import { logDOM } from '@testing-library/react';
import CustomerProductDetail from './CustomerProductDetail';



function SingleProductCustomerReviews(...props) {
  const [reviews, setReviews] = useState([]);
  const {accessToken} = useContext(CustomerContext);
  const params = useParams();
  const id = params.productId;
  
  async function fetchReviews( accessToken ) {

    const requestOptions = {
      method: "GET",
      headers: {
        "Accept": "text/plain",
        "Authorization": `Bearer ${accessToken}`,
      },
    };
  
    try {
      const response = await fetch(
        // `${API_BASE_URL}/feedback/api/v1/Get?Id=141&Approval=${approval}`,
        `${API_BASE_URL}/feedback/api/v1/Get?ProductId=${id}&Approval=${true}`,

        requestOptions
      );
  
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
  
      const data = await response.json();
      console.log(data);
    
      if(data?.result.length>0){
        console.log("yESESE");
      return data;}
      else{
        console.log("NOO");
        
      }
    } catch (error) {
      throw error;
    }

  }
  useEffect(() => {
    async function fetchData() {
      // const productId = props?.productId;
      const userId = null;
      const approval = true;

      try {
        const data = await fetchReviews( userId, approval, props?.accessToken);
        setReviews(data);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    }

    fetchData();
  }, [accessToken]);
  
  function generateRatingStars(rating) {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(<Star key={i}  color="#029F4A" size={14} weight="fill"/>);
    }
    return stars;
  }

  return (
    <div>
     {reviews?.result?.length > 0 ? (
            reviews.result.map((review, index) => (
                <div key={index} className='Customer-reviews-container'>
                    <div className='User-name'>
                        <div><UserCircle className='account-icon'/></div>
                        <div className='Verified-user'>Verified User <SealCheck size={15} color="#04ABE0" weight="fill" /></div>
                    </div>
                    <div className='review-start-count'>
                        {generateRatingStars(review.rating)}
                    </div>
                    <div className='review-description'>
                        {review.content}
                    </div>
                    <div>
                        <img loading="lazy" src={review?.files[0]?.fileURL} alt='' className='product-detail-review-image' />
                    </div>
                </div>
            ))
        ) : (
            <div className='Customer-reviews-container'>No review</div>
        )}

          {/* {review.files && review.files.length > 0 && ( 
  <div className='review-images'>
    {review?.files?.map((file, imageIndex) => (
      <div key={imageIndex}>
        {file.fileURL == 'string' && (
          <img loading="lazy" src={review?.files[0]?.fileURL} alt={`Review Image ${imageIndex}`} />
        )}
      </div>
    ))} 
  </div>
)} */}
        </div>
      
    
  );
}

export default SingleProductCustomerReviews;
