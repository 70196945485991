import React from 'react'
import '../styles/BcBuyerLayout.css'

function BcAccountPagesContainer(props) {
    return (
        <div className='bc-buyer-layout-container'>
        {props.children}
        </div>
    )
}

export default BcAccountPagesContainer