import React from 'react'
import AdminLayoutAdjustment from '../components/AdminLayoutAdjustment'
import { useNavigate } from 'react-router-dom'
import { TextField } from '@mui/material';
import { useState } from 'react';
import Heading from '../components/Heading';

const AdminProductSearch = () => {
    const navigate= useNavigate();
    const [productId, setProductId] = useState('');
  return (
    <AdminLayoutAdjustment>
         <Heading text="Admin Product Management" />
      <div className="layout-container">
        <TextField value={productId} onChange={(e)=>{setProductId(e.target.value)}} fullWidth style={{maxWidth:'800px', display:'block', margin:'1rem auto'}} placeholder='Enter Product Link Here'/>
        <div>

        <button className='byr-common-button' onClick={()=>{productId?.length>32&&navigate(`/adminPreview/${productId?.split('/').pop()}`)}}>Check Buyer Preview</button>
        <button className='byr-common-button' onClick={()=>{productId?.length>32&&navigate(`/admineditpage/${productId?.split('/').pop()}`)}}>Edit Product</button>
        </div>
        </div>
    </AdminLayoutAdjustment>
  )
}

export default AdminProductSearch