import React, { useState } from 'react';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import TextField from '@mui/material/TextField';
import "../styles/FreightCalculator.css";

const FreightCalculator = (props) => {
  const {
    pickupPincode,
    setPickupPincode,
    setDeliveryPincode, deliveryPincode,
    dimensions,
    setDimensions,
    weight,
    setWeight,
    onCheckButtonClick,
  } = props;
  const clearDimension = (dimension) => {
    setDimensions({ ...dimensions, [dimension]: '' });
  };
  return (
    <div className='freightCalculator'>
      <div className='freightCalculator-head'>FREIGHT CALCULATOR</div>
      <div className='freightCalculator-content'>
        <div className='freightCalculator-content-left'>
          <div className='lv-form-field'>
            <div className='lv-field-name'>Pick-up Area Pincode*: </div>
            <TextField
              autoFocus
              autoComplete='off'
              variant='outlined'
              fullWidth
              type='text'
              placeholder='Enter 6 digit pickup area pincode'
              className="seller-form-field"
              value={pickupPincode}
              onChange={(e) => setPickupPincode(e.target.value)}
            />
          </div>
          <div className='lv-field-name'>Dimensions</div>
          <div className='dimensions-container'>
            <TextField
              autoFocus
              autoComplete='off'
              variant='outlined'
              fullWidth
              type='text'
              placeholder='Length'
              className='dimension-field'
              value={dimensions.length}
              onChange={(e) => setDimensions({ ...dimensions, length: e.target.value })}
            />
           
            <TextField
              autoComplete='off'
              variant='outlined'
              fullWidth
              type='text'
              placeholder='Width'
              className='dimension-field'
              value={dimensions.width}
              onChange={(e) => setDimensions({ ...dimensions, width: e.target.value })}
            />
           
            <TextField
              autoComplete='off'
              variant='outlined'
              fullWidth
              type='text'
              placeholder='Height'
              className='dimension-field'
              value={dimensions.height}
              onChange={(e) => setDimensions({ ...dimensions, height: e.target.value })}
            />
         
          </div>
          <div>
            <button className='Logistic-check-btn' onClick={onCheckButtonClick}>
              Check
            </button>
          </div>
        </div>
        <div className='freightCalculator-content-right'>
          <div className='lv-field-name'>Delivery Area Pincode*</div>
          <TextField
            autoFocus
            autoComplete='off'
            variant='outlined'
            fullWidth
            type='text'
            placeholder='Enter 6 digit pickup area pincode'
            className="seller-form-field"
            value={deliveryPincode}
              onChange={(e) => setDeliveryPincode(e.target.value)}
          />
          <div className='lv-field-name'>Weight (Kg)</div>
          <TextField
            autoFocus
            autoComplete='off'
            variant='outlined'
            type='text'
            placeholder='Kg'
            className='seller-form-field'
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default FreightCalculator;
