import React, { useState, useEffect } from "react";
import "../styles/AdminPlanList.css";
import { API_BASE_URL } from '../App'

import { useNavigate } from "react-router-dom";
import AdminLayoutAdjustment from "../components/AdminLayoutAdjustment";
import Heading from "../components/Heading";
import withAdminAuth from "../context/withAdminAuth";
import { errorAlert } from "../components/Alert";

 function AdminPlanList() {
  const [mySubsPlans, setMySubsPlans] = useState([]);

  // // return fetch("https://api.myhraki.com/api/v1/Favorites?buyeremail=kharavela.jain%40outlook.com", {
const addPlan=()=>{
  navigate('/adminAddPlan');
}
  const fetchMySubsPlansData = async () => {
    const response = await fetch(`${API_BASE_URL}/subscription/api/v1/getall`, {
      // mode: "no-cors",
      method: "GET",

      headers: {
        // 'Accept': 'text/plain',
        // 'Content-Type': 'text/plain',
      },
    });
    const data = await response.json();
    if(data?.status===401){
      errorAlert('Session timed out. Please Log in');
      await new Promise((resolve) => setTimeout(resolve, 1000));
      navigate('/')
    }
    ////console.log(data)
    setMySubsPlans(data.result);
  };

  useEffect(() => {
    fetchMySubsPlansData();
  }, []);
  const navigate = useNavigate();
  function goToSubsDetailsPage(x) {
    ////console.log(x);
    navigate(`/adminSubscriptionPlanDetails/${x}`)
  }

  ///////////
  return (
    <AdminLayoutAdjustment>
      <Heading text="Manage Subscription Plans" />
      <div className="layout-container"> 
      
      <button className="common-btn" onClick={addPlan}>Add Plan</button>
      
      <table
        //   className={classes.table}
        class="admin-planlist-table"
        //   size="small"
        //   aria-label="a dense table"
      >
        <tr className="table-head-admin-plan-list">
          {/* <th>Feature Order</th> */}
          <th>Plan Name</th>
          <th>Plan Price</th>
          <th>Billed Yearly</th>
          <th>Discount Offered</th>
        </tr>
        {mySubsPlans &&
          mySubsPlans?.map((row, i) => {
            return (
              <tr         onClick={()=>{goToSubsDetailsPage(row.id)}}
              >
                <>
                  <td  data-th="Plan Name">{row.title}</td>
                  <td  data-th="Plan Value">{row.value}</td>
                  <td  data-th="Frequency">{row.isAnnual===true?"Yes":"No"}</td>
                  <td  data-th="Discount">{row.annualDiscount}</td>
                </>
              </tr>
            );
          })}
      </table>
      </div>
    </AdminLayoutAdjustment>
  );
}

export default withAdminAuth(AdminPlanList);