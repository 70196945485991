import React from "react";
import "../styles/SignupPage.css";
import "../styles/Common.css";

import { useFormik } from "formik";
import * as yup from "yup";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import logo from "../Images/logo.png";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { Checkbox } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import CallIcon from "@mui/icons-material/Call";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const phoneRegExp = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;
// const nameRegExp=/^[A-Z][a-zA-Z]{3,}\S*$/;

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Invalid Password")
    .required("Password is required"),
  number: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required*")
    .min(10, "Phone number must consist of 10 digits")
    .max(10, "Phone number must consist of 10 digits"),
  firstName: yup
    .string("Enter Your First Name")
    .required("First Name is required*"),
  lastName: yup
    .string("Enter Your last Name")
    .required("Last Name is required*"),
  termsAndConditions: yup
    .bool("Enter terms and conditions")
    .oneOf([true], "You need to accept the terms and conditions"),
});

export default function SignUpPage() {
  // const [errorCheckboxText, setErrorCheckboxText]=useState(true)

  const navigate = useNavigate();
  // document.getElementById('dt').max = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0];
  const gotoLoginPage = () => {
    navigate("/login");
  };
  const [radioValue, setRadioValue] = React.useState("");
  const [radioError, setRadioError] = React.useState(false);
  const [radioHelperText, setRadioHelperText] = React.useState("");
  let day = new Date().getDate();
  //  ////console.log(day);
  let month = new Date().getMonth() + 1;
  //  ////console.log(month);

  const year = new Date().getFullYear();
  //  ////console.log(year);

  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  const maxDate = `${year}-${month}-${day}`;
  //  ////console.log(maxDate);

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    setRadioHelperText(" ");
    setRadioError(false);
  };

  // document.getElementById("checkBox").checked=checked;

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      number: "",
      firstName: "",
      lastName: "",
      termsAndConditions: false,
      date: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      // setErrorCheckboxText(true);
      // ////console.log(formik.values)
      if (radioValue === "male") {
        setRadioHelperText("");
      } else if (radioValue === "female") {
        setRadioHelperText("");
      } else if (radioValue === "other") {
        setRadioHelperText("");
      } else {
        setRadioHelperText("Please select an option.");
        setRadioError(true);
      }

      if (radioValue) {
        // navigate('/home');
        postData();
      }
    },
  });

  async function postData() {
    try {
      let result = await fetch(
        "https://webhook.site/b2622e3f-5a2f-4b98-8759-7b721b73e9bc",
        {
          method: "post",
          mode: "no-cors",
          headers: {
            Accept: "application.json",
            "Content-type": "application.json",
          },
          body: JSON.stringify({
            email: formik.values.email,
            password: formik.values.password,
            number: formik.values.number,
            firstName: formik.values.firstName.trim(),
            lastName: formik.values.lastName.trim(),
            termsAndConditions: formik.values.termsAndConditions,
            date: formik.values.date,
          }),
        }
      );
      ////console.log(result);
    } catch (e) {
      ////console.log(e);
    }
  }

  return (
    <div className="signup-container">
      <div className="signup-top-left-circle"></div>
      <div className="signup-top-right-rect"></div>
      <div className="signup-bottom-center-circle"></div>
      <div className="signup-bottom-right-shape"></div>
      <div className="signup-center-shape"></div>
      <div className="signup-card-container">
        <div className="signup-card">
          <div className="signup-head">SignUp</div>
          <form className="signup-page-form" onSubmit={formik.handleSubmit}>
            <div className="signup-row">
              <div className="signup-first">
                <TextField
                  variant="outlined"
                  fullWidth
                  autoFocus
type="text"                  id="signup-firstName"
                  name="firstName"
                  //   className="login-page-email"
                  className="signup-firstName"
                  placeholder="Enter Your First Name * "
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
                <PersonIcon
                  className="signup-firstName-icon"
                  fontSize="medium "
                />
              </div>
              <div className="signup-last">
                <TextField
                  variant="outlined"
                  fullWidth
type="text"                  id="signup-lastName"
                  name="lastName"
                  className="signup-lastName"
                  placeholder="Enter Your Last Name * "
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
                <PersonIcon className="signup-email-icon" fontSize="medium " />
              </div>
            </div>
            <div className="signup-row">
              <div className="signup-email-field">
                <TextField
                  variant="outlined"
                  fullWidth
type="text"                  id="signup-email"
                  name="email"
                  //   className="login-page-email"
                  className="signup-email"
                  placeholder="Enter Email Address * "
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <MailOutlineIcon
                  className="signup-email-icon"
                  fontSize="medium "
                />
              </div>
              <div className="signup-number-field">
                <TextField
                  variant="outlined"
                  fullWidth
type="text"                  id="signup-number"
                  name="number"
                  //   className="login-page-email"
                  className="signup-number"
                  placeholder="Enter Your Phone Number * "
                  value={formik.values.number}
                  onChange={formik.handleChange}
                  error={formik.touched.number && Boolean(formik.errors.number)}
                  helperText={formik.touched.number && formik.errors.number}
                />
                <CallIcon className="signup-number-icon" fontSize="medium " />
              </div>
            </div>
            <div className="signup-row">
              <div className="signup-password-field">
                <TextField
                  variant="outlined"
                  fullWidth
type="text"                  id="signup-password"
                  name="password"
                  //   className="login-page-email"
                  className="signup-password"
                  placeholder="Enter a desired Password * "
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
                <LockOpenIcon
                  className="signup-password-icon"
                  fontSize="medium "
                />
              </div>
              <div className="signup-calendar-field">
                <input
                  className="signup-calendar"
                  placeholder="Enter date"
                  // value={formik.values.date}
                  onChange={formik.handleChange}
                  // min='1899-01-01'
                  max={maxDate}
                  id="dt"
                  // type="text"
                  type="date"

                  // value="DateTime.Today.ToString("yyyy-MM-dd")
                />
                {/* <input placeholder="Date"  type="text" onfocus="(this.type='date')" id="date"></input> */}
                <CalendarMonthIcon
                  className="signup-date-icon"
                  fontSize="medium "
                />
                {/* <MudIcon className="signup-calendar-icon" Icon="@Icons.Outlined.CalendarToday" /> */}
              </div>
            </div>

            <FormControl
              className="signup-gender"
              error={radioError}
              variant="standard"
            >
              {/* <FormLabel id="demo-error-radios">Gender</FormLabel> */}
              <div className="signup-gender">Gender</div>
              <RadioGroup
                // aria-labelledby="demo-error-radios"
                name="gender"
                value={radioValue}
                onChange={handleRadioChange}
                className="signup-gender-radio"
                row
              >
                <FormControlLabel
                  fontSize="medium "
                  style={{ marginRight: "5rem" }}
                  value="male"
                  className="signup-male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  fontSize="medium "
                  style={{ marginRight: "5rem" }}
                  value="female"
                  className="signup-female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  fontSize="medium "
                  style={{ marginRight: "5rem" }}
                  value="other"
                  className="signup-other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
              <FormHelperText
                style={{
                  fontSize: "1rem",
                  marginTop: "1rem",
                  left: "8%",
                  bottom: "-45%",
                }}
              >
                {radioHelperText}
              </FormHelperText>
            </FormControl>

            <div className="signup-terms">
              {/* Terms and conditions */}
              <Checkbox
                type="checkbox"
                name="termsAndConditions"
                id="signup-terms"
                className="signup-terms"
                // placeholder="Enter a desired Password"
                value={formik.values.termsAndConditions}
                onChange={formik.handleChange}
                error={
                  formik.touched.termsAndConditions &&
                  Boolean(formik.errors.termsAndConditions)
                }
                helperText={
                  formik.touched.termsAndConditions &&
                  formik.errors.termsAndConditions
                }
              />
              <div className="signup-terms-text">
                By continuing, you agree to MyhraKi'sTerms and conditions and
                privacy policy
              </div>
            </div>
            {/* {////console.log(errorCheckboxText)} */}
            {/* <div className="signup-checkbox-error"> Please agree to our terms and conditions to continue</div>  */}

            {/* {////console.log(formik.values.termsAndConditions)} */}
            <input
              type="submit"
              className="submit-button signup-button"
              value="Sign Up"
            />
          </form>
          <div className="signup-signin">
            Already a User? <span onClick={gotoLoginPage}>Sign In </span>
          </div>
        </div>
      </div>
      <div className="signup-welcome">
        <div className="signup-welcome-head">Welcome to the world of</div>
        <div className="signup-welcome-name">MyhraKi</div>
        <img loading="lazy" className="signup-logo" src={logo} alt=" " />
        <div className="signup-explore">[ This is a Placeholder text]</div>
        <img loading="lazy" className="signup-shop" alt=" " src="images/shop.png" />
      </div>
    </div>
  );
}
