import React, { useContext, useEffect, useState } from "react";
import "../styles/SellerProductList.css";
import "../styles/SellerCategoryList.css";
import "../styles/Common.css";
import { useNavigate } from "react-router-dom";
import Heading from "../components/Heading";
import { SellerContext } from "../context/SellerContext";
import { errorAlert } from "../components/Alert";
import Loading from "../components/Loading";
import withSellerAuth from "../context/withSellerAuth";
import SellerLayoutAdjustment from "../components/SellerLayoutAdjustment";
// import SellerCategoryCard from "../components/SellerCategoryCard";
import { useRef } from "react";
import ToastComponent from "../components/ToastComponent";
import { API_BASE_URL } from "../App";
import { CircularProgress } from "@mui/material";

function SellerCategoriesListingPage() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const { getSellerPlan, accessToken, sellerData, setSelectedListItemIndex } =
    useContext(SellerContext);
    const [addLoading, setAddLoading] = useState(false)

  const [categories, setCategories] = useState();
  //console.log(sellerPlanDetails);

  function isAddProductAllowed(sellerPlanDetails) {
    ////console.log(sellerPlanDetails)
    const elements = sellerPlanDetails?.toString()?.split(".");
    if (elements?.length >= 12) {
      const videoElement = parseFloat(elements[1]);
      if(videoElement>0){
        return true;
      } else{
        return 'expired'
      }   
     }
    return false;
  }


  async function addNewProduct() {
    setAddLoading(true);
    const sellerPlanDetailsres = await getSellerPlan();
    const sellerPlanDetails= await sellerPlanDetailsres?.result
    if(isAddProductAllowed(sellerPlanDetails)===true){
      navigate("/addProductBySeller2");
      setAddLoading(false);

    }
    else if(isAddProductAllowed(sellerPlanDetails)==='expired'){
      setAddLoading(false);

      errorAlert('','Cannot add more products in your existing subscription plan.')
    }
    else{
      setAddLoading(false);

      errorAlert('','Something went wrong. Try again.')

    }
  }

  const fetchCategories = async () => {
    try {
      let response = await fetch(
        `${API_BASE_URL}/productgen/api/v1/category/All`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      let data = await response.json();
      if (data?.status === 401) {
        errorAlert('','Session timed out. Please Log in');
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate('/')
      }
      setCategories(data?.result);
      //console.log(data);
    } catch (error) {
      //console.error(error);
      if (error.title === "Unauthorized") {
        navigate("/");
      }
    }
  };

  useEffect(() => {
    setSelectedListItemIndex(0)
    fetchCategories();

  }, []);
 

  // const [sellerProductListData, setSellerProductListData] = useState([]);
  const [sellerCategoryListData, setSellerCategoryListData] = useState([]);

  // const fetchSellerProductListData = async () => {
  //   const response = await fetch(
  //     `${API_BASE_URL}/productgen/api/v1/get?sellerId=${sellerData?.result.userID}`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     }
  //   );
  //   const data = await response.json();
  //   if(data?.status===401){
  //     errorAlert('Session timed out. Please Log in');
  //     await new Promise((resolve) => setTimeout(resolve, 1000));
  //     navigate('/')
  //   }
  //   setSellerProductListData(data.result);
  //   setLoading(false);
  //   ////console.log(data);
  // };

  const fetchSellerCategoryListData = async () => {
    try {


      setLoading(true);
      const response = await fetch(
    
        // `${API_BASE_URL}/productgen/api/v1/category/${sellerData?.result?.typeOfUser==='SLADM'?sellerData?.result?.userID:sellerData?.result?.userAdminId}`,
        `${API_BASE_URL}/productgen/api/v1/sellerId?sellerId=${sellerData?.result?.typeOfUser==='SLADM'?sellerData?.result?.userID:sellerData?.result?.userAdminId}`,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      if (data?.status === 401) {
        errorAlert('','Session timed out. Please Log in');
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate('/')
      }
      setSellerCategoryListData(data?.result?.result);
      setLoading(false);
      ////console.log(data);
    } catch (e) {
      setLoading(false);
      toastRef.current.showToast('Something went wrong, Please try again', 'error')
      //console.log(e);
    }
  };


  useEffect(() => {
    if (!sellerData?.result?.userID) {
      navigate('/')
      return;
    }
    fetchSellerCategoryListData();
  }, [sellerData]);

  // const sellerPlan= getSellerPlan();
  // ////console.log(sellerPlanDetails);
  //


  const toastRef = useRef();



  useEffect(() => {
    //console.log(categories);
    //console.log(sellerCategoryListData)
  }, [categories, sellerCategoryListData])


  const [matchingCategories, setMatchingCategories] = useState([]);


  useEffect(() => {
    // Update matching categories when categories or sellerCategoryListData change
    const matching = categories?.filter(category =>
      sellerCategoryListData?.some(item => item?.category === String(category?.categoryId))
    );
    setMatchingCategories(matching);
  }, [categories, sellerCategoryListData]);


  return (
    <div>
      <ToastComponent ref={toastRef} timeout={4000} />

      <SellerLayoutAdjustment>
        <div className="page-content">
        <Heading text="Categories" />
          <div className="seller-products-list-navbar">
            {/* <div className="seller-products-list-add-products">
                <button className="common-button" onClick={addNewProduct}>+ Add New Products</button>
            </div> */}
            <div className="seller-products-list-search-and-filter">
            {!addLoading&&<button className="common-button" onClick={addNewProduct}>
                + Add New Products
              </button>}
              {addLoading&&<div style={{width:'200px'}}><CircularProgress style={{color:'pink', margin:'0 auto 0 50px'}} /></div>}
              {/* 
              <div className="seller-products-list-navbar-search-bar">
                <SearchIcon
                  className="seller-products-list-navbar-search-icon"
                  icon="@Icons.Filled.Search"
                  fontSize="medium"
                />
                <input
                  className="seller-products-list-navbar-search"
                  // type="string"
                  placeholder="search"
                  value={searchQuery}
                  onChange={handleSearchQueryChange}
                />
              </div> */}
            </div>
          </div>
          {loading && <div className="loading-container" style={{ minHeight: "300px", width:'90%', maxWidth:'400px', margin:'0 auto' }}>
            <Loading />

          </div>}


          {!loading&&<div className="seller-category-cards">
            {matchingCategories?.map(category => (
              <div className="seller-category-card" key={category?.categoryId} onClick={() => { navigate(`/sellerProductsPage/${category?.categoryId}`) }}>
                <img loading="lazy" className="seller-category-card-img" src={category?.blobImageURL} alt={category?.categoryName} />
                <div className="seller-category-card-name">{category?.categoryName}</div>
              </div>
            ))}
          </div>}



        </div>
        {/* <SellerFooter /> */}
      </SellerLayoutAdjustment>
    </div>
  );
}


export default withSellerAuth(SellerCategoriesListingPage);