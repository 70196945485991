import React from 'react'
import '../styles/ReturnRefundPolicy.css';

function RefundPolicySeller() {
  return (
    <div className="return-refund-policy">
         <div>
      <h1>Return and Refund Policy for Myhraki Sellers</h1>

      <h2>Introduction</h2>
      <p>
        This Return Policy outlines the procedures for customers to return products purchased from Myhraki sellers ("Sellers"). It is the responsibility of Sellers to adhere to and implement this policy.
      </p>

      <h2>Return Eligibility</h2>
      <p>
        Customers may return a product purchased from a Seller on Myhraki within 5 days of receiving the product. To be eligible for a return, the product must be unused, undamaged, and in its original packaging. Customized or made-to-order products are not eligible for returns.
      </p>

      <h2>Return Process</h2>
      <p>
        Once the product is received and inspected by the Seller, a refund must be issued to the customer within 1 day. The refund should encompass the full purchase price of the product, minus any applicable shipping or handling fees charged to the customer.
      </p>

      <h2>Defective or Damaged Products</h2>
      <p>
        In the case of a customer receiving a defective or damaged product from a Seller on Myhraki, the customer should promptly contact the Seller through Myhraki to request a return or exchange. The Seller should furnish the customer with instructions for returning the product, including any necessary shipping labels or packaging.
      </p>

      <p>
        Upon receiving and inspecting the product, the Seller must issue a refund or replacement to the customer within 1 working day. The refund or replacement should include the full purchase price of the product, as well as any shipping or handling fees charged to the customer.
      </p>

      <h2>Seller Responsibilities</h2>
      <p>
        Sellers are accountable for complying with this Return Policy and ensuring customer satisfaction. Timely and professional responses to return requests, as well as issuing refunds or replacements promptly upon receipt of returned products, are crucial responsibilities for Sellers.
      </p>

      <h2>Dispute Resolution</h2>
      <p>
        If a customer and Seller cannot resolve a return or exchange issue, Myhraki may assist in resolving the dispute. Working with both parties, Myhraki aims to find a mutually acceptable solution.
      </p>
      <h2>Refunds</h2>
      <p>
      Once the returned product is received and inspected, the customer will be notified of the refund status. If approved, the refund will be initiated to the original payment method. Please be aware that the refund amount may be subject to deductions for return shipping costs or restocking fees, if applicable.       </p>

      <h2>Faulty or Damaged Products </h2>
      <p>
      In case of receiving a faulty or damaged product, customers should contact Myhraki's customer support team within 5 days of delivery, providing necessary details and evidence. Eligible cases will be assessed, and replacements or refunds arranged accordingly.       </p>

      <h2>Lost Packages </h2>
      <p>
      For lost packages during transit, customers should contact the customer support team. Investigations will be conducted with the shipping carrier, and if confirmed lost, a replacement or refund will be provided.       </p>
      <h2>Refund Processing </h2>
      <p>
      Refunds will be processed back to the original payment method. The time for the refund to reflect in the account may vary depending on the payment provider.       </p>
      
      <h2>Contact Us</h2>
      <p>
      For questions or concerns regarding the Return and Refund Policy, please contact us at <a href="https://mail.google.com/mail/?view=cm&to=hello@myhraki.com" >Hello@myhraki.com</a>. Our customer support team is ready to assist and provide further clarification.
      </p>

      <h2>Policy Amendments</h2>
      <p>
        This Return Policy is subject to change without prior notice. It is the responsibility of users to review the policy periodically for any updates or modifications.
      </p>

      <h2>Amendments</h2>
      <p>
      Myhraki may amend this Return Policy by posting updated terms on its website. Sellers are responsible for complying with the most recent version of this policy. By using the Myhraki platform, Sellers agree to this Return Policy and any additional policies or guidelines published by Myhraki from time to time. 
      </p>    </div>
    </div>
  )
}

export default RefundPolicySeller