
import React, { useContext } from "react";
import AdminLayoutAdjustment from "../components/AdminLayoutAdjustment";
import Heading from "../components/Heading";
import { DeleteOutline } from "@mui/icons-material";
import "../styles/AdminFacts.css";
import FormHelperText from '@mui/material/FormHelperText';
import { useState } from "react";
// import SearchBar from "../components/SearchBarComponent";
import { useEffect } from "react";
// import { sellerFactsData } from "../SellerFactsData";
import "../styles/sellerfactsadd.css";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import InputLabel from '@mui/material/InputLabel';
import Select from "@mui/material/Select";
// import Modal from "@mui/joy/Modal";
// import ModalClose from "@mui/joy/ModalClose";
// import SearchIcon from "@mui/icons-material/Search";
// import Sheet from "@mui/joy/Sheet";
import { CircularProgress, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Textarea } from "@mui/joy";
// import TextareaAutosize from '@mui/material/TextareaAutosize';
import withAdminAuth from "../context/withAdminAuth";
// import { confirmAlert, successAlert } from "../components/Alert";
// import { errorAlert } from "../components/Alert";
import { v4 as uuidv4 } from "uuid";
import { CheckCircle } from "@mui/icons-material";
import { AdminContext } from "../context/AdminContext";
// import ClearIcon from "@mui/icons-material/Clear";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ToastComponent from "../components/ToastComponent";
import { API_BASE_URL } from "../App";
 
function AdminFacts() {
  // const [status, setStatus] = useState(10);
  // const [imageError, setImageError] = useState(null);
  const [imageLoading1, setImageLoading1] = useState(false);
  const [imageLoading2, setImageLoading2] = useState(false);
  // const [open, setOpen] = useState(false);
  const [tab, setTab] = useState("facts");
  const [facts, setFacts] = useState([]);
  const [selectedFact, setSelectedFact] = useState(null);
  const [factsData, setFactsData] = useState([]);
  const { accessToken, sellerData } = useContext(AdminContext);
  const [openModal, setOpenModal] = useState(false);
  // const handleModalOpen = () => setOpenModal(true);
  const [loading, setLoading] = useState(false);
  const [factNameforimage1, setfactNameforimage1] = useState("");
  const [factNameforimage2, setfactNameforimage2] = useState("");
  const [factDescription, setfactDescription] = useState("");
  const [imageNameWithExtension1, setimageNameWithExtension1] = useState("");
  const [imageNameWithExtension2, setimageNameWithExtension2] = useState("");
  const [blogName, setblogName] = useState("");
  const [blogArticle, setblogArticle] = useState("");
  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();
  const [category, setcategory] = useState("Art");
  const toastRef = React.useRef();
  const [factAddLayout, setFactAddLayout] = useState(false);
 
  const validationSchema = yup.object().shape({
    imageNameWithExtension1: yup
      .string()
      .trim()
      .required('Image Name is required')
      .max(50, 'Image Name should not exceed 50 characters'),
    imageNameWithExtension2: yup
      .string()
      .trim()
      .required('Image Name is required')
      .max(50, 'Image Name should not exceed 50 characters'),
    blogName: yup
      .string()
      .trim()
      .required('Blog title is required')
      .max(50, 'Blog title should not exceed 50 characters'),
    category: yup
      .string()
      .nullable()
      .required('Category Name is required'),
    blogArticle: yup
      .string()
      .trim()
      .required('Blog Article is required')
      .max(30000000000000000000000000000000000000),
    image1: yup.string().nullable().required("Image is required"),
    image2: yup.string().nullable().required("image is required"),
    startDateTime: yup.string().required("startDateTime is required")
  });
  const initialValues = {
    factId: "",
    factDescription: "",
    factNameforimage1: "",
    factNameforimage2: "",
    startDateTime: "",
    imageNameWithExtension1: "",
    imageNameWithExtension2: "",
    blogName: "",
    blogArticle: "",
    category: '',
    imageUrl: "",
    image1: '', // Set a default value as an empty string
    image2: '', // Set a default value as an empty string
    status: ""
  };
 
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      handleAddFactSubmit(formik.values);
    },
  });
 
  async function fetchFacts() {
    try {
      const response = await fetch(`${API_BASE_URL}/facts/api/v1/GetAllCFacts`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
 
      if (!response.ok) {
        throw new Error("Failed to fetch facts");
      }
 
      const data = await response.json();
      setFacts(data);
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
 
  useEffect(() => {
    const fetchData = async () => {
      const facts = await fetchFacts();
      if (facts) {
        setFacts(facts);
      }
    };
 
    fetchData();
  }, []);
 
 
  async function DeleteFact(factId) {
    const shouldDelete = window.confirm("Are you sure you want to delete this fact?");
    if (!shouldDelete) {
      return;
    }
 
    try {
      await fetch(`${API_BASE_URL}/facts/api/v1/DeleteFacts?FactId=${factId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
 
      setFacts((prevFacts) => {
        if (!Array.isArray(prevFacts)) {
          return [];
        }
        return prevFacts.filter((fact) => fact.factId !== factId);
      });
 
      fetchFacts();
    } catch (error) {
      console.error("Failed to delete fact:", error);
    }
  }
 
  const handleAddFactSubmit = async () => {
    //console.log(formik.values)
    try {
      const formData = new FormData();
      formData.append("factId", uuidv4());
      formData.append("factNameforimage1", 'a');
      formData.append("factNameforimage2", 'a');
      formData.append("factDescription", 'a');
      formData.append("imageNameWithExtension1", formik.values.imageNameWithExtension1.trim());
      formData.append("imageNameWithExtension2", formik.values.imageNameWithExtension2.trim());
      formData.append("blogName", formik.values.blogName.trim());
      formData.append("blogArticle", formik.values.blogArticle.trim());
      formData.append("category", formik.values.category);
      formData.append("image1", image1);
      formData.append("image2", image2);
      // formData.append("sellerid", sellerData?.result.userID);
      //console.log(formData)
 
      const response = await fetch(`${API_BASE_URL}/facts/api/v1/CreateFacts`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${accessToken}`,
 
        },
        body: formData,
      });
 
      //console.log(formData)
 
      const newFact = await response.json();
      //console.log("API Response:", response);
      //console.log("New Fact:", newFact);
      if (response.ok && newFact?.result === true) {
        setFactsData([...factsData, newFact]);
        setfactNameforimage1("");
        setfactNameforimage2("");
        setfactDescription("");
        setimageNameWithExtension1("");
        setimageNameWithExtension2("");
        setcategory("");
        setblogArticle("");
        setblogName("");
        fetchFacts();
        setLoading(false);
        formik.resetForm({ values: initialValues });
        toastRef.current.showToast("Fact Added Successfully", "success");
      } else {
        setLoading(false);
        toastRef.current.showToast("An Error occurred. Please try again", "error");
      }
    } catch (error) {
      // setOpen(false);
      // setOpenModal(false);
      setLoading(false);
      // handleModalClose();
      toastRef.current.showToast("Could not add fact. Please try again", "error");
    }
  };
 
  // async function AddNewFact() {
  //   setfactNameforimage1("");
  //   setfactNameforimage2("");
  //   setfactDescription("");
  //   setimageNameWithExtension1("");
  //   setimageNameWithExtension2("");
  //   setcategory("");
  //   setblogName("");
  //   setblogArticle("");
  //   await fetchFacts();
  //   setOpen(false);
  // }
 
  // function addNewFact() {
  //   //console.log("Add");
  //   handleModalOpen();
  // }
  const [factsForApproval, setFactsForApproval] = useState([]);
 
  const fetchFactsForApproval = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/facts/api/v1/GetAllCFacts`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
 
      if (!response.ok) {
        throw new Error("Failed to fetch facts");
      }
 
      const data = await response.json();
      setFacts(data);
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };
 
  const ApproveFact = async (factId) => {
    try {
      const formData = new FormData();
      formData.append("factId", uuidv4());
      await fetch(`${API_BASE_URL}/facts/api/v1/ApproveFacts?FactId=${factId}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          // 'Content-type': 'application/json',
        },
        body: formData,
      });
      fetchFacts();
      fetchFactsForApproval();
      alert("Fact approved successfully");
    } catch (error) {
      console.error(error);
    }
  };
 
  const RejectFact = async (factId) => {
    try {
      await fetch(`${API_BASE_URL}/facts/api/v1/RejectFacts?FactId=${factId}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      fetchFactsForApproval();
      setFactsForApproval(factsForApproval.filter((fact) => fact.FactId !== factId));
    } catch (error) {
      console.error(error);
    }
  };
 
  const [editedFact, setEditedFact] = useState({});
  // const [selectedFact, setSelectedFact] = useState(null);
  const handleRowClick = (fact) => {
    setOpenModal(true);
    setSelectedFact(fact);
    setEditedFact({
      factId: uuidv4(),
      blogName: formik.values.blogName,
      category: formik.values.category,
      blogArticle: formik.values.blogArticle,
      ImageNameWithExtension1: formik.values.image1,
      ImageNameWithExtension2: formik.values.image2,
      Image1: formik.values.image1,
      Image2: formik.values.image2,
    }
    );
  };
  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedFact(null);
  };
  // const handleFormSubmit = async () => {
  //   try {
  //     const formData = new FormData();
  //     formData.append("factId", uuidv4());
  //     formData.append("factNameforimage1", 'a');
  //     formData.append("factNameforimage2", 'a');
  //     formData.append("factDescription", 'a');
  //     formData.append("imageNameWithExtension1", formik.values.imageNameWithExtension1.trim());
  //     formData.append("imageNameWithExtension2", formik.values.imageNameWithExtension2.trim());
  //     formData.append("blogName", formik.values.blogName.trim());
  //     formData.append("blogArticle", formik.values.blogArticle.trim());
  //     formData.append("category", formik.values.category);
  //     formData.append("image1", image1);
  //     formData.append("image2", image2);
 
  //     const response = await fetch(
  //       {
  //         method: "PUT",
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //         body: formData,
  //       }
  //     );
 
  //     const updatedFact = await response.json();
  //     //console.log(updatedFact);
  //     if (updatedFact?.result === true) {
  //       fetchFacts();
  //       handleModalClose();
 
  //     } else {
  //       throw new Error("Failed to update fact");
  //     }
  //   } catch (error) {
  //     console.error("Error updating fact:", error);
  //   }
  // };
 
  const textareaStyle = {
    '--Textarea-focusedHighlight': 'var(--_Textarea-focusedHighlight, var(--joy-palette-focusVisible, var(--joy-palette-primary-500, #FF5569)))',
  };
 
  return (
    <AdminLayoutAdjustment>
       <ToastComponent ref={toastRef} timeout={4000} />
      <Heading text="Facts" />
      <div className="layout-container">
        {tab === "facts" && (
          <div>
            <div className="Facts-Button-field">
              <button
                className="common-btn"
                onClick={() => {
                  setTab("approve");
                }}
              >
                Approve Facts
              </button>
 
              <button
                className="common-btn"
                onClick={() => {
                  setFactAddLayout(!factAddLayout);
                }}
              >
                + Add New Fact
              </button>
            </div>
 
            {factAddLayout && (
              <div
                style={{
                  minWidth: '300px', width: '90%',
                  margin: '1rem auto', minHeight: '300px',
                  borderRadius: "20px",
                  boxShadow: '2px 2px 5px rgba(0.6, 0, 0, 0.6)'
                }}>
 
                <div className="add-fact-form">
                  <form className="fact-modal-form" onSubmit={formik.handleSubmit}>
                   
                  <div className="fact-modal-form-field">
                    <div className="fact-modal-form-field-name">Blog Category</div>
                    <Box
                      sx={{
                        width: "60%",
                      }}
                    >
                      <FormControl fullWidth style={{ background: "white" }}>
 
                        <Select
                          style={{ background: "white" }}
 
                          name="category"
                          value={formik.values.category}
                          onChange={formik.handleChange}
 
                        >
                          <MenuItem value={"Select a Category"} disabled>Select a category</MenuItem>
                          <MenuItem value={"Art"}>Indian Art</MenuItem>
                          <MenuItem value={"Artisan"}>Indian Artist</MenuItem>
                        </Select>
                        <FormHelperText style={{ fontSize: "11px", color: "#D32F2F" }}>{formik.touched.category && formik.errors.category}</FormHelperText>
                      </FormControl>
                    </Box>
                  </div>
 
                   <div className="fact-modal-form-field">
                    <div className="fact-modal-form-field-name">Blog Title</div>
                    <TextField
                      name="blogName"
                      value={formik.values.blogName}
                      onChange={formik.handleChange}
                      error={formik.touched.blogName && Boolean(formik.errors.blogName)}
                      helperText={formik.touched.blogName && formik.errors.blogName}
                      sx={{
                        width: "60%",
                        "&:focused": {
                          borderColor: "orange",
                          borderWidth: "2px",
                        },
                      }}
                    />
                  </div>
 
                  <div className="fact-modal-form-field">
                    <div className="fact-modal-form-field-name">Blog Article</div>
                    <Textarea
                      style={{ resize: "none", padding: "10px", border: "1px solid grey", background: "white", ...textareaStyle  }}
                      fullWidth
                      name="blogArticle"
                      value={formik.values.blogArticle}
                      onChange={formik.handleChange}
                      // error={formik.touched.blogArticle && Boolean(formik.errors.blogArticle)}
                      sx={{
                        width: "60%",
                        minHeight: "200px",
                      }}
                    />
                  </div>
                  <FormHelperText style={{color:"#D32F2F",textAlign:"center"}}>{formik.touched.blogArticle && formik.errors.blogArticle}</FormHelperText>
       
                  <div className="fact-modal-form-field">
                    <div className="fact-modal-form-field-name" ><p>Image 1</p>
                      <p className="note" style={{ fontSize: "13px" }}>Showcase the beauty of the Artwork</p></div>
                    <div className="add-image" style={{ margin: "0 auto", padding: "0px",}}>
                      <label className="choose-file-btn">
                        <input
                          type="file"
                          name="image"
                          value={formik.values.image1}
 
                          onChange={async (event) => {
                            setImageLoading1(true);
                            const uploadedImage = event.target.files[0];
                            const maxSizeInBytes = 5 * 1024 * 1024;
                            if (uploadedImage.size > maxSizeInBytes) {
                              // errorAlert("File size exceeds 1 MB limit.");
                              toastRef.current.showToast("File size exceeds 5 MB limit", "error");
                              setImageLoading1(false);
                              return;
                            }
                            const validTypes = ["image/jpeg", "image/png", "image/jpg"];
                            if (!validTypes.includes(uploadedImage.type)) {
                              // errorAlert("File type must be jpg, jpeg, or png.");
                              toastRef.current.showToast("File type must be jpg, jpeg, or png.", "error");
                              setImageLoading1(false);
 
                              return;
                            }
                            const fileExtension = uploadedImage.name
                              .split(".")
                              .pop();
                            const imageNameWithExtension1 = `${uuidv4()}.${fileExtension}`;
 
                            const formData = new FormData();
                            formData.append("ContainerName", "ecomdata");
                            formData.append(
                              "FileNameWithExtension",
                              imageNameWithExtension1
                            );
                            formData.append("FileCategory", "Facts");
                            formData.append(
                              "Attachment",
                              uploadedImage,
                              uploadedImage.name
                            );
 
                            try {
                              const response = await fetch(
                               `${API_BASE_URL}/blob/api/v1/uploadmediaWithContainer`,
                                {
                                  method: "POST",
                                  headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                  },
                                  body: formData,
                                }
                              );
 
                              if (!response.ok) {
                                // errorAlert('', 'Something went WrongLocation. Could not upload Image');
                              }
                              formik.setFieldValue("imageNameWithExtension1", imageNameWithExtension1)
                              setImage1(uploadedImage)
                              setimageNameWithExtension1(imageNameWithExtension1)
                              setImageLoading1(false);
 
                            } catch (error) {
                              //console.log(error);
                              setImageLoading1(false);
                            }
                           
                          }}
                        />
                       {/* <img loading="lazy" src={imageSeller} alt="" /> */}
                       Choose File *
                      </label>
 
                      {imageLoading1 && (
                        <CircularProgress className="file-submitted" />
                      )}
                      {!imageLoading1 && imageNameWithExtension1 !== '' && <CheckCircle className="file-submitted" />}
                      {formik.touched.image1 && formik.errors.image1 && !image1 && (
    <div className="error-message-facts-field" style={{ textAlign: 'center' }}>
      {formik.errors.image1}
    </div>
  )}
                    </div>
                  </div>
 
                  <div className="fact-modal-form-field">
                    <div className="fact-modal-form-field-name"><p>Image 2</p>
                      <p className="note" style={{ fontSize: "13px" }}>Glimpse of the talented Artisan behind the creation</p></div>
                    <div className="add-image" style={{ margin: "0 auto", padding: "0px", }}>
                      <label className="choose-file-btn">
                        <input
                          type="file"
                          name="image"
                          value={formik.values.image2}
 
                          onChange={async (event) => {
                            setImageLoading2(true);
                            const uploadedImage = event.target.files[0];
                            const maxSizeInBytes = 5 * 1024 * 1024;
                            if (uploadedImage.size > maxSizeInBytes) {
                              // errorAlert("File size exceeds 5 MB limit.");
                              toastRef.current.showToast("File size exceeds 5 MB limit", "error");
                              setImageLoading2(false);
 
                              return;
                            }
                            const validTypes = ["image/jpeg", "image/png", "image/jpg"];
                            if (!validTypes.includes(uploadedImage.type)) {
                              // errorAlert("File type must be jpg, jpeg, or png.");
                              toastRef.current.showToast("File type must be jpg, jpeg, or png.", "error");
                              setImageLoading2(false);
 
                              return;
                            }
                            const fileExtension = uploadedImage.name
                              .split(".")
                              .pop();
                            const imageNameWithExtension2 = `${uuidv4()}.${fileExtension}`;
 
                            const formData = new FormData();
                            formData.append("ContainerName", "ecomdata");
                            formData.append(
                              "FileNameWithExtension",
                              imageNameWithExtension2
                            );
                            formData.append("FileCategory", "Facts");
                            formData.append(
                              "Attachment",
                              uploadedImage,
                              uploadedImage.name
                            );
 
                            try {
                              const response = await fetch(
                                `${API_BASE_URL}/blob/api/v1/uploadmediaWithContainer`,
                                {
                                  method: "POST",
                                  headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                  },
                                  body: formData,
                                }
                              );
 
                              if (!response.ok) {
                                // errorAlert('', 'Something went WrongLocation. Could not upload Image');
                              }
                              formik.setFieldValue("imageNameWithExtension2", imageNameWithExtension2)
                              setImage2(uploadedImage)
                              setimageNameWithExtension2(imageNameWithExtension2)
                              setImageLoading2(false);
 
                            } catch (error) {
                              //console.log(error);
                              setImageLoading2(false);
                            }
 
                            if (!formik.touched.image2 && formik.errors.image2) {
                              formik.setFieldError("image2", formik.errors.image2);
                            }
 
                          }}
                        />
                       {/* <img loading="lazy" src={imageSeller} alt="" /> */}
                       Choose File *
                      </label>
 
                      {imageLoading2 && (
                        <CircularProgress className="file-submitted" />
                      )}
                      {!imageLoading2 && imageNameWithExtension2 !== '' && <CheckCircle className="file-submitted" />}
                      {formik.touched.image2 && formik.errors.image2 && !image2 && (
    <div className="error-message-facts-field" style={{ textAlign: 'center' }}>
      {formik.errors.image1}
    </div>
  )}
                    </div>
                  </div>
                   
                    <div className="facts-modal-button">
                    {!loading && (<button type="submit"
                    className="common-btn" onClick={handleAddFactSubmit}>
                      Add Fact</button>)}
                      {loading && (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}>
                          <CircularProgress style={{ color: "pink" }} />
                      </div>
                    )}
                    </div>
                  </form>
                </div>
              </div>
            )}
 
            <div className="modal-trigger">
              <table className="admin-categories-table">
                <tbody>
                  <tr>
                    <th>Blog Title </th>
                    <th>Blog Article</th>
                    <th>Status</th>
                    <th> Actions</th>
                  </tr>
 
                  {facts?.result?.filter((fact) => fact.status === "Active").map((fact) => (
                    <tr key={fact.factId} onClick={() => handleRowClick(fact)}>
                      {/* <FactModal fact={selectedFact} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} /> */}
                      <td data-th="Fact Name" className="Admin-facts-description-field">{fact.blogName}</td>
                      <td
                        data-th="Fact Description"
                        className="Admin-facts-description-field"
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          maxWidth: "200px",
                        }}
                      >
                        {fact.blogArticle}
                      </td>
 
                      <td data-th="Status">
                        <div className="seller-discount-status">
                          {fact.status === "Scheduled" && (
                            <div className="status-indicator-pending"> </div>
                          )}
                          {fact.status === "Active" && (
                            <div className="status-indicator-active"> </div>
                          )}
                          {fact.status === "Expired" && (
                            <div className="status-indicator-expired"> </div>
                          )}
                          {fact.status}
                        </div>
                      </td>
 
                      <td data-th="Actions">
                        <DeleteOutline onClick={(e) => {
                          e.stopPropagation();
                          DeleteFact(fact.factId);
                        }} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
 
        {tab === "approve" && (
          <div>
            <div className="facts-for-approval-tab">
              <button className="common-btn" onClick={() => setTab("facts")}>
                ◀ Go Back to Facts
              </button>
              {facts?.result?.filter(fact => fact.status !== "Active").map((fact) => (
                <div className="single-fact" key={fact.FactId}>
                  <div className="single-fact-field">
                    <div className="seller-single-fact-head">Blog Category</div>
                    <div className="Admin-facts-description-field">{fact.category}</div>
                  </div>
                  <div className="single-fact-field">
                    <div className="seller-single-fact-head">Blog Title</div>
                    <div className="Admin-facts-description-field">{fact.blogName}</div>
                  </div>
                  <div className="single-fact-field">
                    <div className="seller-single-fact-head">Blog Description</div>
                    <div className="Admin-facts-description-field" >{fact.blogArticle}</div>
                  </div>
                  <div className="single-fact-field">
                    <div className="seller-single-fact-head">Image 1</div>
                    <img loading="lazy" className="single-fact-image" src={fact.imageUrl1} alt="fact" onClick={() => {
                window.open(fact.imageUrl1);
              }}/>
                  </div>
                  <div className="single-fact-field">
                    <div className="seller-single-fact-head">Image 2</div>
                    <img loading="lazy" className="single-fact-image" src={fact.imageUrl2} alt="fact" onClick={() => {
                window.open(fact.imageUrl2);
              }}/>
                  </div>
                  <div className="fact-approve-buttons">
                    <button className="common-btn" onClick={() => ApproveFact(fact.factId)}>
                      Approve
                    </button>
                    <button className="Reject-button-facts" onClick={() => RejectFact(fact.factId)}>Decline</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
 
      </div>
    </AdminLayoutAdjustment>
  );
}
export default withAdminAuth(AdminFacts);