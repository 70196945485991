import React from "react";
import "../styles/Common.css";
import headingIcon from "../Images/HedingIcon.png"

function Heading(props) {
  return (
    <div className="heading-container">
    <img loading="lazy" src={headingIcon} alt="" className="heading-image" />
      <h2 className="heading-text">{props.text}</h2>
    </div>
  );
}

export default Heading;