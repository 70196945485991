import React, { useContext, useState } from "react";
import "../styles/BcOrderCard.css";
import { useEffect } from "react";
import { CustomerContext } from "../context/CustomerContext";

export default function BcOrderCard(props) {
  //console.log(props);
  const { selectedOrder, setSelectedOrder} = useContext(CustomerContext);
  const { selectedTab, setSelectedTab,} = props;
  const [ind, setInd] = useState();
  // const [addressModalOpen, setAddressModalOpen] = useState(false);
  // const [tabCount, setTabCount] = useState(0)
  const buttonStyle = {
    background: ' linear-gradient(90deg, #FD2A80 -5.66%, #FE575C 103.62%)',
    color: 'white',
    padding: '10px 8px',
    borderRadius: '8px',
    cursor: 'pointer',
    color:"#FFFF",
fontFamily: "Poppins",
fontSize: "14px",
fontStyle: "normal",
fontWeight: "500",
lineHeight: "14px",
letterSpacing: "-0.14px",
textTransform: "capitalize",
  };



  useEffect(() => {
    if (selectedOrder===props?.itemDetails?.[ind]?.orderitemNumber && selectedTab === 'track') {
        setOpenModal(true);
    }
}, [selectedOrder, selectedTab]);
  useEffect(() => {
    if (selectedOrder===props?.itemDetails?.[ind]?.orderitemNumber && selectedTab === 'cancel') {
        setOpenModal(true);
    }
}, [selectedOrder, selectedTab]);

  // const handleTrackClick = async (index) => {
  //   setSelectedOrder(props?.itemDetails[index]?.orderitemNumber);
  //   setSelectedTab('track');
  //   setOpenModal(true);

  // };

  // const handleCancelClick = async (index) => {
  //   setSelectedOrder(props?.itemDetails[index]?.orderitemNumber);
  //   // const data=await getCurrentOrderDetail(selectedOrder);
  //    setSelectedTab('cancel');
  //    setOpenModal(true);
  // }


  const [openModal, setOpenModal] = useState(false);

  function formatDate(inputDate) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const date = new Date(inputDate);
    return date.toLocaleDateString('en-US', options);
  }

  return (
    <div className="card-container">
      {/* <CustomerOrderModal selectedOrder={(props?.itemDetails[0]?.orderitemNumber)} itemDetails={props.itemDetails} refId={props?.itemDetails[0]?.orderitemNumber} currentOrderDetail={currentOrderDetail} myOrdersData={myOrdersData} selectedTab={selectedTab} isOpen={openModal} onClose={() => setOpenModal(false)} /> */}
      <div className="card-container-header">
        <div className="card-container-head-fields">
          <div className="card-container-head-fields-title"> Order placed</div>
          <div className="card-container-head-fields-title">{props?.itemDetails?.[0]?.date}</div>
        </div>
        <div className="card-container-head-fields-details">
          <div className="card-container-head-fields-title">Total</div>
          <div className="card-container-head-fields-title">₹{props?.itemDetails?.[0]?.totalCost+props?.itemDetails?.[0]?.shippingCost}</div>
        </div>
        <div className="card-container-head-fields-details">
          <div className="card-container-head-fields-title">Ship to</div>
          <div className="card-container-head-fields-title">{props?.itemDetails?.[0]?.name}</div>
        </div>
        <div className="card-container-header-fields">
          <div className="card-container-head-fields-title">{props?.itemDetails?.[0]?.order_RefrenceId}</div>
          <div className="card-links">
            <div className="card-view-details-orders" style={buttonStyle} onClick={() => {
              window.scrollTo(0,0);
              props.setOrderDetailData(props?.itemDetails)
              props.setTab('details')
            }} >
              View order details
            </div>
          </div>
        </div>
      </div>

      {props?.itemDetails?.map((item,index) => {
        // //console.log(item);
        return (
          <div key={item[index]?.orderitemNumber} className="card-container-content-field">
            {/* <div>Arriving on: {formatDate(item[index]?.etaDate.split('T')[0])}</div> */}
            {/* <div className="shipping-today-active">Shipping : {item[index]?.shipmentStatus}</div> */}
            <div className="order-card-content-field">
            <div>
               <img loading="lazy" src={item?.image} alt=''  className="order-container-image"/>
              </div>
              <div className="order-card-product-detail-fields">
              <div className="cust-order-details-product-name">{item?.title}</div>
              <div style={{fontSize:"14px"}}>  Price : Rs. {item?.itemCost}</div>
             </div>
             
                {/* <div>{item?.orderitemNumber}</div> */}
               
                {/* <div className="order-detail-info-buttons"> */}
          
          
                
                {/* <div onClick={() => {handleTrackClick();} } className="track-package-link">Track package</div> */}

                {/* <button
                  className="Customer-common-btn" onClick={() => {setInd(index);handleTrackClick(index)} }
                >
                  Track Order
                </button>
                <button
className="CustomerPlain-common-btn"
                  onClick={ async() => {
                    setInd(index);
                     handleCancelClick(index);
                  }}
                >
                  Cancel Order
                </button> */}
              {/* </div> */}
            
            </div>

          </div>)
      })}
    </div>
  );
}