import DeleteTwoTone from "@mui/icons-material/DeleteTwoTone";
import React, { useState } from "react";
import "../styles/Card.css";
import Star from "@mui/icons-material/Star";
import pricedown from "../pricedown.png";
import Edit from "@mui/icons-material/Edit";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import SizeDropdown from "./SizeDropdown";
import Favorite from "@mui/icons-material/Favorite";
import Checkbox from "@mui/material/Checkbox";
import Quantity from "./Quantity";
import ViewOrder from "../pages/ViewOrder";
import { Link, useNavigate } from "react-router-dom";

export default function Card(props) {
  const navigate = useNavigate();
  const goToDefautView=()=>{
    setDetails(false);

  }
  const [details, setDetails] = useState(false);
  function viewDetails() {
    // setDetails(true);
    navigate("/viewOrder");
  }
  return (
    <div className="card-container" id={`${props.card_id}`}>
      {!details && !props.historyCard && (
        <div className="card-header">
          {props.ordercard && (
            <div className="card-order-no">Order Id: {props.id}</div>
          )}
          {props.ordercard && (
            <div className="card-order-status">{props.card_orderStatus}</div>
          )}
          {props.ordercard && (
            <div className="card-links">
              <Link to={'/viewOrder'} className="card-view-details">
                View order details
              </Link>
              <div className="card-links-divider">|</div>
              <Link to={'/'} > Get Invoice</Link>
            </div>
          )}

          {(props.wishlistCard  ) && (
            <div className="card-details-and-delete">
              <div className="card-details">
                {/* {props.wishlistCard && <Link>More Details</Link>} */}
                {props.reviewCard && <Edit style={{ marginLeft: "50rem" }} />}
              </div>
              <div className="card-delete">
                <DeleteTwoTone />
              </div>
            </div>
          )}
           {(props.reviewsRatingCard  ) && (
            <div className="card-details-and-delete">
              <div className="card-details">
                {/* {props.wishlistCard && <Link>More Details</Link>} */}
                {props.reviewCard && <Edit style={{ marginLeft: "50rem" }} />}
              </div>
              <div className="card-delete">
                <DeleteTwoTone />
              </div>
            </div>
          )}
        </div>
      )}
      {!details && (
        <div className="card-content">
            {!props.cartCard&&!props.reviewCard&&<img loading="lazy" alt=" "  className="card-product-image" src={`https://picsum.photos/id/${props.id}/200`} />}
            {(props.cartCard||props.reviewCard)&&<img loading="lazy" alt=" "  className="card-product-image-big" src={`https://picsum.photos/id/${props.card_id}/200`} />}
           

          {props.ordercard && (
            <div className="card-product-details">
              <div className="card-product-name">{props.name}</div>
              <div className="card-product-total">
                Order Total: {props.card_orderPrice}
              </div>
              <div className="card-product-dates">
                <div className="card-order-placed-date">
                  Order Placed: {props.card_orderDate}
                </div>
                {props.order_returned && (
                  <div className="card-order-returned-date">
                    Order Returned: {props.card_returnDate}
                  </div>
                )}
              </div>
            </div>
          )}
          {props.wishlistCard && (
            <div className="card-product-details">
              <div className="card-product-name">
                <div>{props.name}</div>
                {props.cardPriceDown && <img loading="lazy" alt=" "  src={pricedown} />}
              </div>
              <div className="card-product-rating">
                <div>{props.id}</div>
                <Star sx={{fontSize:"1rem"}}/>
              </div>
              <div className="card-prices">
                <div className="card-price">Rs.{props.card_SellingPrice}</div>
                <div className="card-actual-price">
                  Rs.{props.card_ActualPrice}
                </div>
                <div className="card-discount">{props.card_Discount}</div>
              </div>
              <div className="card-quantity">
                Quantity: {props.id}
              </div>
            </div>
          )}
           {props.reviewsRatingCard && (
            <div className="card-product-details">
              <div className="card-product-name">
                <div>{props.name}</div>
                {props.cardPriceDown && <img loading="lazy" alt=" "  src={pricedown} />}
              </div>
              <div className="card-product-rating">
                <div>{props.id}</div>
                <Star sx={{fontSize:"1rem"}}/>
              </div>
              <div className="card-prices">
                <div className="card-price">Rs.{props.card_SellingPrice}</div>
                <div className="card-actual-price">
                  Rs.{props.card_ActualPrice}
                </div>
                <div className="card-discount">{props.card_Discount}</div>
              </div>
              <div className="card-quantity">
                Quantity: {props.id}
              </div>
            </div>
          )}
          {props.reviewCard && (
            <div className="card-product-details">
              <div className="card-product-name">
                <div>{props.card_product_name}</div>
              </div>
              <div className="card-product-stars">
                <div className="card-product-star-indicator">
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                </div>
                <div className="card-product-star-number"> (5/5)</div>
                <div className="card-product-review-count">(104 Reviews)</div>
              </div>
              <div className="card-review-text">
                It is a very good product. The Quality is also awesome and I
                would suggest to buy the product
              </div>
            </div>
          )}
          {props.historyCard && (
            <div className="card-product-details">
              <div className="card-product-name-history">
                <div>{props.card_product_name}</div>
              </div>
            </div>
          )}
          {props.ordercard && (
            <button className="card-button">Order Again</button>
          )}
          {props.wishlistCard && (
            <button className="card-button">Move To Cart</button>
          )}
          
          {props.reviewCard && <div></div>}
          {props.historyCard && (
            <button className="card-button">Details</button>
          )}
          {props.cartCard && (
            <div
              className="card-product-details"
              style={{ marginLeft: "0rem" }}
            >
              <div className="card-product-name">
                <div>{props.title}</div>
              </div>
              <div className="card-product-color">
                <div>Color: Black</div>
              </div>
              <div className="card-product-size">
                {/* Size: <SizeDropdown /> */}
              </div>

              <div className="card-quantity">
                Quantity: <Quantity />
              </div>
              <div className="card-prices">
                <div className="card-price">Rs.{props.unitCost}</div>
                <div className="card-actual-price">
                  Rs.{props.oldUnitCost}
                </div>
                <div className="card-discount">{props.card_Discount}</div>
              </div>
              <div className="card-cart-wishlist">
                <Checkbox
                  icon={<FavoriteBorder />}
                  checkedIcon={<Favorite />}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                />

                <div className="card-cart-text">Move to Wishlist</div>
              </div>
            </div>
          )}
        </div>
      )}
      {details && (
        <div>

          <ViewOrder goToDefaultView={goToDefautView}/>
        </div>
      )}
    </div>
  );
}
