import React, { useContext, useRef } from "react";
import "../styles/AdminOtpVerify.css";
import "../styles/AdminForgotPasswordOtpVerify.css";
import OtpInput from "react-otp-input";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../Images/MainLogo.png";
import { API_BASE_URL } from '../App'

import AdminOtpImg from "../Images/thankYouScreenImg.png";
import "../styles/Common.css";
import { AdminContext } from "../context/AdminContext";
import { useFormik } from "formik";
import * as yup from "yup";
import LockIcon from "@mui/icons-material/LockOpen";
import { CircularProgress, TextField } from "@mui/material";
import { errorAlert, successAlert } from "../components/Alert";

import 'react-toastify/dist/ReactToastify.css';
export default function AdminForgotPasswordOtpVerify() {
    const passwordRegexp = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{6,20}$)"
      );
      const validationSchema = yup.object({
        password: yup
          .string("Enter your password")
          .matches(passwordRegexp, "Password is not valid")
          .min(8, "Password should contain 8 characters ")
          .required("Password is required"),
        rePassword: yup
          .string("Enter Your Password again")
          .oneOf([yup.ref("password"), null], "Passwords must match")
          .min(8, "Password should contain 8 characters")
          .required("Password is required"),
      });

      const navigate = useNavigate();
      const {loginWithOtpForForgetPassword, fetchDataStore} = useContext(AdminContext);
      
  const [resendOtpMessage, setResendOtpMessage] = useState(false);
  const [invalidOTP, setInvalidOTP] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const toastRef = useRef();

      const formik = useFormik({
        initialValues: {
          password: "",
          rePassword: "",
        },
        validationSchema: validationSchema,
        onSubmit: async () => {
          // postResetData();
          
            // setInvalidOTP(false);
            setLoading(true);
            const response = await loginWithOtpForForgetPassword(phoneNumberForOtp?.trim(), code, 1);
            // setResendOtpMessage(false);
            if (response?.result?.accessToken) {
              setInvalidOTP(false);
              setCode();
              setLoading(false);
              // toastRef.current.showToast("Login successful", "success");
              await postResetData(response?.result?.accessToken);
              // navigate('/SellerCategoriesListingPage')
            } else if (response?.message) {
              setInvalidOTP(true);
              // toastRef.current.showToast("Invalid OTP", "error");
              setLoading(false);
            } else {
              setLoading(false);
              setInvalidOTP(false);
        
              toastRef.current.showToast("Something went wrong. Please try later", "error");
            }
          },
        
      });

      async function postResetData(token) {
        const userId = await fetchDataStore(token);
        try {
          //console.log(sellerData)
          const response = await fetch(`${API_BASE_URL}/users/api/v1/update`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization:`Bearer ${token}`
            },
            body: JSON.stringify({
              userID:userId?.result?.userID,
              password: formik.values.password,
            }),
          });
          const data = await response.json();
          console.log(data);
          if (data.result) {
            // toastRef.current.showToast("Password Update successful", "success");
            navigate('/AdminFacts');
            return true;
          } else {
            // toastRef.current.showToast("Password Could not be updated", "error");
            return false;
          }
        } catch (error) {
          alert('500 error. Something went wrong')
          // toastRef.current.showToast("Something went wrong. Please try later", "error");
          return false;
        }
      
      }

  // const {sellerRegistered} = useContext(AppContext)
  const { phoneNumberForOtp } = useContext(AdminContext);
  const [loading, setLoading ] = useState(false);
  // const [loginInProgress, setLoginInProgress] = useState(false)
  // const userType = 1;
  const [timeLeft, setTimeLeft] = useState(180);
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    setEnabled(false);
    const timer = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    if (timeLeft === 0) {
      clearTimeout(timer);
      setEnabled(true);
    }
    return () => clearTimeout(timer);
  }, [timeLeft]);


  // async function postResetData() {
  //   setLoading(true);

  //   try {
  //     const response = await fetch(`${API_BASE_URL}/users/api/v1/resetPassword`, {
  //       method: 'POST',
  //       headers: {
  //         'accept': 'text/plain',
  //         'Content-Type': 'application/json-patch+json'
  //       },
  //       body: JSON.stringify({
  //         "mobileNumber": phoneNumberForOtp,
  //         "otp": code,
  //         "newPassword": formik.values.password,
  //         "userType": "1"
  //       })
  //     });
  //     //console.log(response);
  //     if(response.ok){
  //       setLoading(false);
  //       successAlert('','Password reset successful');
  //       navigate('/')
  //     }
  //     else{
  //       setLoading(false);

  //       errorAlert('','Password Reset Failed');
  //     }
  //     return response;
  //   } catch (error) {
  //     setLoading(false);

  //     errorAlert('', 'Password Reset Failed')
  //     // console.error(error);
  //   }
  // }
  // async function verifyOtp() {
  //  const response= await postResetData();
  //  //console.log(response)
  //   if(response.result===true){
  //     //notify('Password Successfully Reset')
  //     navigate('/');
  //   }
  //   else{
  //     //notify('Password Reset Failed')

  //   }
  // }

  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  // const [resend, setResend] = useState(false);
  const resendOtp = async () => {
    try {
        let result = await fetch(
          `${API_BASE_URL}/users/api/v1/otp?mobileNumber=${phoneNumberForOtp}&userType=${1}`,
          {
            headers: {
            
            },
          
          }
        );
        ////console.log(result);
        //console.log(result);
        if(result?.ok){
          successAlert('','OTP has been resent.')
        }
        else{
          errorAlert('','Error Resending OTP.')
          return;
        }
      } catch (e) {
        //console.log(e);
      }
      setTimeLeft(180);
    };

  const handleChange = (code) => setCode(code);

  return (
    <div className="admin-otp-Container">
      <div className="admin-otp-verify-left">
      <form className="SellerOtpVerification-container-right-content" onSubmit={formik.handleSubmit}>
          <div className="OtpVerification-Myhraki-logo" >
            <img loading="lazy" src={logo} alt="title" className="Myhraki-logo" />
          </div>
          <div className="OtpVerification-title">OTP Verification</div>
          <div className="otp-numbers">
            <OtpInput
              value={code}
              onChange={handleChange}
              numInputs={4}
              separator={<span style={{ width: "8px" }}></span>}
              isInputNum={true}
              shouldAutoFocus={true}
              className="otp-verify-otp-verify"
              inputStyle={{
                border: "2px solid black",
                borderRight: "none",
                borderLeft: "none",
                borderTop: "none",
                width: "2rem",
                height: "2rem",
                fontSize: "1.5rem",
                color: "#000",

                //   fontWeight: "400",
                // caretColor: "blue"
              }}
              focusStyle={{
                // border: "1px solid #CFD3DB",
                borderRight: "none",
                borderLeft: "none",
                borderTop: "none",
                outline: "none",
              }}
            />
          </div>
          {error && (
            <div className="otp-verify-error">Please enter a valid OTP</div>
          )}
           <div className="admin-reset-page-phone-field">
            <TextField
              variant="outlined"
              fullWidth
              autoFocus
              autoComplete="off"
              type="text"
              id="password"
              name="password"
              className="login-email"
              placeholder="Enter new Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <LockIcon className="admin-reset-phone-icon" fontSize="medium " />
          </div>
          <div className="admin-reset-page-phone-field">
            <TextField
              variant="outlined"
              fullWidth
              //   autoFocus

              autoComplete="off"
              type="text"
              id="rePassword"
              name="rePassword"
              className="login-email"
              placeholder="Re-Enter  new Password"
              value={formik.values.rePassword}
              onChange={formik.handleChange}
              error={
                formik.touched.rePassword && Boolean(formik.errors.rePassword)
              }
              helperText={formik.touched.rePassword && formik.errors.rePassword}
            />
            <LockIcon className="admin-reset-phone-icon" fontSize="medium " />
          </div>
          <div className="otp-verify-resend-otp">
            <div>
              <span>Didn't receive the OTP?</span>
            </div>
            {!enabled && (
              <div
                className="otp-verify-resend-otp-button"
                style={{ color: " #FF5569;" }}
              >
                Resend
              </div>
            )}
            {enabled && (
              <div className="otp-verify-resend-otp-button" onClick={resendOtp}>
                Resend OTP
              </div>
            )}
             <div>
            
            {timeLeft !== 0 && <div>{timeLeft}</div>}
          </div>
</div>
           
            
          {!loading && <button className="common-button" onClick={formik.handleSubmit}>
            Verify
          </button>}
          {loading &&
            <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "pink" }} /></div>}

          </form>
          {/* <div className="otp-verify-signUp">New User? <span onClick={gotoSignupPage}>Sign Up</span></div> */}
          <div className="admin-page-terms">
            By continuing, you agree to MyhraKi's
            <span className="admin-span"> Terms and conditions</span> and <span className="admin-span">privacy policy</span>
          </div>
        </div>
      
      <div className="admin-otp-verify-right">
        <div className="OtpVerification-container-left-content">
          <div className="OtpVerification-container-left-img">
            <img loading="lazy" src={AdminOtpImg} alt=" " />
          </div>
        </div>
      </div>
      {/* //<ToastContaner theme="coloured" className="toast-message" /> */}

    </div>
 
  );
}
