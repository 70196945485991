import React, { useEffect, useState } from 'react'
import "../styles/AdminDashboard.css"
import "../styles/Seller.css";
// import DashboardData from "../DashboardData";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import { Bar, Pie } from "react-chartjs-2";
// import profilePic from '../Images/personIcon.png';
import dashboardWelcome from "../Images/dashboardWelcome.png";
import ellipse from "../Images/Ellipse .png";
// import SellerLayout from '../components/SellerLayout';
import AdminLayoutAdjustment from '../components/AdminLayoutAdjustment';
import Heading from '../components/Heading';
import withAdminAuth from '../context/withAdminAuth';
import { errorAlert } from '../components/Alert';
import { useNavigate } from 'react-router-dom';
 function AdminDashboard() {
  const navigate = useNavigate();

  const data = [
    // { profilePic: <img loading="lazy" src={profilePic} alt="profile pic" />, name: "Rahul", value: 0 },
    // { profilePic: <img loading="lazy" src={profilePic} alt="profile pic" />, name: "John", value: 0 },
    // { profilePic: <img loading="lazy" src={profilePic} alt="profile pic" />, name: "Sarah", value: 0 },
    
  ];
  const ratings = [
    { name: "Excelent", count: 0, stars: "⭐⭐⭐⭐⭐", barClass: "bar-5" },
    { name: "Good", count: 0, stars: "⭐⭐⭐⭐", barClass: "bar-4" },
    { name: "Average", count: 0, stars: "⭐⭐⭐", barClass: "bar-3" },
    { name: "Below Average", count: 0, stars: "⭐⭐", barClass: "bar-2" }
  ];
    
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            // display: true,
            // text: 'Chart.js Bar Chart',
          },
        },
      };
      
    const [sellerDashboardData, setSellerDashboardData] = useState([]);

    const fetchDashboardData = async () => {
      const response = await fetch("https://jsonplaceholder.typicode.com/users", {
        // mode: "no-cors",
        headers: {
          // 'Accept': 'text/plain',
          // 'Content-Type': 'text/plain',
          "Access-Control-Allow-Origin": "*",
        },
      });
      const data = await response.json();
      if(data?.status===401){
        errorAlert('Session timed out. Please Log in');
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate('/')
      }
      setSellerDashboardData(data);
      ////console.log(data);
    };
  
    useEffect(() => {
      fetchDashboardData();
    }, []);
  
    const visitorData = 0
  // sellerDashboardData.slice(0,4).map((i)=>{
  //  return i.name.length;
  // })
  
  const newOrdersData = 0
  // sellerDashboardData.slice(3,7).map((i)=>{
  //  return i.name.length;
  // })
  
  const readyToShipData = 0
  // sellerDashboardData.slice(5,9).map((i)=>{
  //  return i.name.length;
  // })
  
  const inDeliveryData = 0
  // sellerDashboardData.slice(2,7).map((i)=>{
  //  return i.name.length;
  // })
  
  const valuesForPieChartData = 0
  // sellerDashboardData.slice(0,5).map((i)=>{
  //  return i.name.length;
  // })
    
  
    ////console.log(visitorData);
  
    const barChartData = {
      labels: ["Nov 22", "Dec 22", "Jan 23", "Feb 23"],
      datasets: [
        {
          label: "Visitors",
          data: visitorData,
          backgroundColor: ["rgba(255, 253, 216, 1)"],
          borderColor: ["rgba(255, 253, 216, 1)"],
          borderWidth: 1,
        },
        {
          label: "New Orders",
          data: newOrdersData,
          backgroundColor: ["rgba(212, 240, 240, 1)"],
          borderColor: ["rgba(212, 240, 240, 0.3)"],
          borderWidth: 1,
        },
        {
          label: "Ready to ship",
          data: readyToShipData,
          backgroundColor: ["rgba(255, 239, 243, 1)"],
          borderColor: ["rgba(255, 239, 243, 1)"],
          borderWidth: 1,
        },
        {
          label: "In Delivery",
          data: inDeliveryData,
          backgroundColor: ["rgba(239, 255, 233, 1)"],
          borderColor: ["rgba(239, 255, 233, 1)"],
          borderWidth: 1,
        },
      ],
    };
    const pieChartData = {
      labels: [
        "Clothing",
        "Kitchenware",
        "Accessories",
        "Cosmetics",
        "Home Decore",
      ],
      datasets: [
        {
          label: "Visitors",
          data: valuesForPieChartData,
          backgroundColor: [
            " #3BDDF3",
            "#AAEFF9",
            "#31A5CA",
            "#D0F9FF",
            "#7DF7C3",
          ],
          borderColor: [" #3BDDF3", "#AAEFF9", "#31A5CA", "#D0F9FF", "#7DF7C3"],
          borderWidth: 1,
        },
      ],
    };
    
  return (
    <AdminLayoutAdjustment>
      <Heading text="Dashboard" />
    <div className="page-content">
      <div className='admin-dashboard-welcome'>
    <div className='admin-dashboard-status'>
    <img loading="lazy" src={dashboardWelcome} alt="" className='welcome-img' />
    <div className='welcome-desc'>
    <div className="dashboard-section-head">Welcome to MyhraKi!</div>
    <div className="dashboard-section-para">
      Activities you need to monitor to maintain buyer satisfaction
    </div>
    </div>
    </div>
    <div className='admin-dashboard-status-help'>
      <div className='admin-dashboard-status-help-img'> <img loading="lazy" src={ellipse} alt=""  className='ellipse-img'/></div>
      <div className='admin-dashboard-status-help-title'>Need Help?</div>
      <div className='admin-dashboard-status-help-desc'>Customer support is here to solve all your doubts reach out on 9876543210</div>
    </div>
    </div>
    <div className="admin-activities-cards">
    <div className="dashboard-section-head">Important Today</div>
    <div className="dashboard-section-para">
      Activities you need to monitor to maintain buyer satisfaction
    </div>
    <div className='admin-dashboard-activities-icon'>
    <div className="seller-activities-card visitors-card">
      <VisibilityIcon className="seller-activities-icon" />
      <div className="seller-activities-card-data">
        <div className="seller-activities-card-data-field">{0}</div>
        <div className="seller-activities-card-data-field small-text">
          New Visitors
        </div>
      </div>
    </div>

   
    <div className="seller-activities-card new-orders-card">
      <ShoppingBagIcon className="seller-activities-icon" />
      <div className="seller-activities-card-data">
        <div className="seller-activities-card-data-field">{0}</div>
        <div className="seller-activities-card-data-field small-text">
          New Orders
        </div>
      </div>
    </div>
   
   
    <div className="seller-activities-card ready-to-ship-card">
      <LocalShippingIcon className="seller-activities-icon" />
      <div className="seller-activities-card-data">
        <div className="seller-activities-card-data-field">{0}</div>
        <div className="seller-activities-card-data-field small-text">
          Ready to ship
        </div>
      </div>
    </div>
    
   
    <div className="seller-activities-card in-delivery-card">
      <DeliveryDiningIcon className="seller-activities-icon" />
      <div className="seller-activities-card-data">
      <div className="seller-activities-card-data-field">{0}</div>
        <div className="seller-activities-card-data-field small-text">
          In Delivery
        </div>
      </div>
    </div>
    </div>
  </div>
      <div className="admin-dashboard-card-layout-chart">
      <div className="dashboard-chart-layout">
      <div className="chart-admin">
        <div className="barChart-admin">
          <Bar options={options} data={barChartData} height="125"/>
        </div>
        <div className="pieChart-admin">
          <Pie options={options} data={pieChartData} />
        </div>
      </div>
    </div>
      </div>
      <div className='dashboard-top-seller-container '> 
      
      <div className='dashboard-top-sellers'>
        <div className='admin-dashboard-top-sellers-title'><h2> Top Sellers</h2></div>
        <div className='admin-dashboard-top-sellers-table'>
        <table>
          <thead>
            <tr className='dashboard-head'>
              <th className='profile'>Profile</th>
              <th className='product'>Product</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td className='table-data'>
                  {item.profile}
                  <div>{item.profilePic}</div>
                  <div>{item.name}</div>
                </td>
                <td>{item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      </div>
      <div className='admin-dashboard-reviews'>
        <div className='admin-dashboard-reviews-title'><h2>Coustomer Reviews</h2></div>
        <div className='review-star-content'>
<span>⭐⭐⭐⭐ </span>
<span>0 out of 5</span>
</div>
<div className="row" style={{padding:"1rem 1rem 1rem 1rem"}}>
{ratings.map((rating, index) => (
  <div key={index} className="row-excelent">
    <div className="side">
      <div>{rating.name}</div>
    </div>
    <div className="middle">
      <div className="bar-container">
        <div className={rating.barClass}></div>
      </div>
    </div>
    <div className="side right">
      <div>{rating.count}</div>
    </div>
  </div>
))}
</div>
      </div>

    </div>
    </div>
    </AdminLayoutAdjustment>
  )
}


export default withAdminAuth(AdminDashboard);