import { TextField } from "@mui/material";
import React from "react";
// import AdminSidebar from "../components/AdminSidebar";
import "../styles/SellerRegistration.css";
import { useFormik } from "formik";
import * as yup from "yup";
import AdminFooter from "../components/AdminFooter";
import AdminNavbar from "../components/AdminNavbar";
import { Divider } from '@mui/material';

export default function AddNewUsers() {
  ///////////
  const phoneRegExp = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;
  const fullNameRegExp = /^[A-Z][a-zA-Z]{3,}(?: [A-Z][a-zA-Z]*){0,2}$/;

  const validationSchema = yup.object({
      sellerName: yup
  .string("Enter fullname")
  .required("Full Name is Required")
  .min(3, "Full Name should be 3 chars atleast")
  .matches(fullNameRegExp, "Invalid Full Name"),
      sellerEmail: yup
        .string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),
        sellerPhoneNumber: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Phone number is required*")
        .min(10, "Phone number must consist of 10 digits")
        .max(10, "Phone number must consist of 10 digits"),
    });

    const formik = useFormik({
      initialValues: {
        sellerEmail: "",
          sellerName: "",
          sellerPhoneNumber:"",
      },
      validationSchema: validationSchema,
      onSubmit: () => {
        ////console.log(formik.values)
      },
    });
 
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <AdminNavbar />
      <Divider style={{ marginLeft: "250px" }} />

      <div className="seller-registration-container">
        <div> 
          {/* <AdminSidebar /> */}
        </div>

          <div className="page-content-admin">
          <div style={{ textAlign: "center", width: "30%", margin: "3rem auto" }}>
            <div className="seller-registration-head">
              <div className="seller-registration-head-text">
                Add New User
              </div>
              <div className="seller-registration-head-underline"> </div>
            </div>
          </div> 
          <form onSubmit={formik.handleSubmit} className="seller-page-form">
          <div className="seller-registration-card">
           
          <div className="seller-form-field">
              <div className="field-name">User Name: </div>
              <TextField
                autoFocus
                autoComplete="off"
                variant="outlined"
                fullWidth
                type="text"
                id="sellerName"
                name="sellerName"
                className="seller-field"
                placeholder=" "
                value={formik.values.sellerName}
                onChange={formik.handleChange}
                error={
                  formik.touched.sellerName && Boolean(formik.errors.sellerName)
                }
                helperText={formik.touched.sellerName && formik.errors.sellerName}
              />
              </div>
          <div className="seller-form-field">
              <div className="field-name">User Email ID: </div>
              <TextField
                // autoFocus
                autoComplete="off"
                variant="outlined"
                fullWidth
                type="text"
                id="sellerEmail"
                name="sellerEmail"
                className="seller-field"
                placeholder=" "
                value={formik.values.sellerEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.sellerEmail && Boolean(formik.errors.sellerEmail)
                }
                helperText={formik.touched.sellerEmail && formik.errors.sellerEmail}
              />
              </div>
          <div className="seller-form-field">
              <div className="field-name">User Phone Number: </div>
              <TextField
                // autoFocus
                autoComplete="off"
                variant="outlined"
                fullWidth
                type="text"
                id="sellerPhoneNumber"
                name="sellerPhoneNumber"
                className="seller-field"
                placeholder=" "
                value={formik.values.sellerPhoneNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.sellerPhoneNumber && Boolean(formik.errors.sellerPhoneNumber)
                }
                helperText={formik.touched.sellerPhoneNumber && formik.errors.sellerPhoneNumber}
              />
            </div>

           
         
          </div>
         
          <input type="submit" className="submit-button register-button" value="Save" style={{float:"right"}}/>
          </form>
        </div>
      </div>
      <AdminFooter />
    </div>
  );
}
