import React from 'react';
import "../styles/ProductFilterCard.css";
import "../styles/Common.css";
// import logo from "../logo.png";
import { useNavigate } from "react-router-dom";
// import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import { Checkbox } from "@mui/material";
import Favorite from "@mui/icons-material/Favorite";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Star from "@mui/icons-material/Star";
// import Quantity from "./Quantity";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// import { API_BASE_URL } from '../App';

export default function PrdouctFilterCard(props) {
  // const [active, setActive] = useState(false);
// const [added, setAdded]= useState(false);
  // const {addToCart} = useContext(CartContext);
  // const{addToWishlist}=useContext(WishlistContext);
  const id = props.id;
  const navigate = useNavigate();
  function goToSingleProductView() {
    navigate(`/singleProduct/${props.id}`);
    window.scrollTo(0, 0);
  }

 

  // function addToCart(){
  //   if(!added){
  //   //console.log("Added to cart")
  //   successAlert("Added to cart")
  //   setAdded(true);
  //   }else{
  //     navigate('/cart')  
  //   }
  // } 
  //console.log('Props', props);
  // const handleAddToWishlist = async () => {
  //   const url = `${API_BASE_URL}/favorites/api/v1/add`; 

  //   const requestBody = {
  //     BuyerEmail: 'anandhoni183@gmail.com',
  //     Products: [
  //       {
  //         ItemId: props.productId,
  //         ImageFilename: props.productImage,
  //         Title: props.productName,
  //         Description: props.productDescription.join(' '),
  //         SKU: props.productId.toString(),
  //         Qty: 1,
  //         UnitCost: parseInt(props.productSellingPrice),
  //       },
  //     ],
  //   };

  //   try {
  //     const response = await fetch(url, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json-patch+json',
  //       },
  //       body: JSON.stringify(requestBody),
  //     });

  //     if (response.ok) {
  //       // Request succeeded
  //       //console.log('Item added to Wishlist successfully!');
  //     } else {
  //       // Request failed
  //       console.error('Failed to add item to Wishlist.');
  //     }
  //   } catch (error) {
  //     console.error('Error adding item to Wishlist:', error);
  //   }
  // };
  return (
    <div className="product-filter-card">
      <div className="product-filter-card-container">
        <LazyLoadImage
          className="product-filter-card-image"
          src={
          props.productSKUs[0].imagesUriList[0].thumbnailURI
          }
          // PlaceholderSrc={logo}
          width="100%"
          height="20rem"
          delayTime={100000}

        />

        {/* <buttton className="product-filter-add-to-cart">Add To Cart</buttton>
        <Checkbox
          className="product-filter-wishlist"
          icon={<FavoriteBorder />}
          checkedIcon={<Favorite />}
          sx={{ "& .MuiSvgIcon-root": { fontSize: 35, color:'rgba(248, 152, 128, 1)' } }}
        /> */}

        <div
          className="product-filter-card-name"
          onClick={goToSingleProductView}
        >
          {props.title}
        </div>
        <div className="product-filter-card-prices">
          <div className="product-filter-card-price">{`Rs ${props.productSKUs[0].sellingPrice}`}</div>
          <div className="product-filter-card-actual-price">{`Rs ${props.productSKUs[0].price}`}</div>
          <div className="product-filter-card-discount">{`${props.productSKUs[0].quantity} % OFF`}</div>
        </div>

        <div className="product-filter-product-rating">
          <div>
            {4}
          </div>

          <Star sx={{fontSize:"1.2rem"}}/>
        </div>
        <div className="product-filter-review-count">{`(${4} Reviews)`}</div>
        <div className="product-filter-quantity">
          {/* <div className="product-filter-quantity-text">Add: </div> */}
          {/* <Quantity className="product-filter-quantity-field"/> */}
          {/* <button className="buyer-save-button" onClick={handleAddToCart} >Add to Cart</button> */}
          {/* {added&&<button className="buyer-save-button" onClick={addToCart} >Go to cart</button>} */}
          {/* <buttton className="product-filter-add-to-cart">Add To Cart</buttton> */}
        <Checkbox
          className=""
          icon={<FavoriteBorder />}
          checkedIcon={<Favorite />}
          sx={{ "& .MuiSvgIcon-root": { fontSize: 35, color:'rgba(248, 152, 128, 1)' } }}
        />
        </div>
      </div>
    </div>
  );
}
