import React, { useContext, useState } from "react";
import "../styles/Seller.css";
import FormHelperText from '@mui/material/FormHelperText';
import "../styles/sellerfactsadd.css"
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextField, CircularProgress } from "@mui/material";
import SellerLayoutAdjustment from "../components/SellerLayoutAdjustment";
import Heading from "../components/Heading";
import "../styles/SellerFacts.css"
import "../styles/AdminFacts.css"
import { SellerContext } from "../context/SellerContext";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { CheckCircle } from "@mui/icons-material";
import imageSeller from "../Images/ChooseFileImage.png"
import ToastComponent from "../components/ToastComponent";
import { API_BASE_URL } from "../App";
import Loading from "../components/Loading";

export default function SellerFacts() {
  const [status, setStatus] = useState(10);
  const [loading, setLoading] = useState(false);
  const [imageLoading1, setImageLoading1] = useState(false);
  const [imageLoading2, setImageLoading2] = useState(false);
  const [factsData, setFactsData] = useState([]);
  const [selectedFact, setSelectedFact] = useState(null);
  const { accessToken, sellerData , handleAuthExpiry, logout} = useContext(SellerContext);
  const [openModal, setOpenModal] = useState(false);
  const handleModalOpen = () => setOpenModal(true);
  const [factNameforimage1, setfactNameforimage1] = useState("");
  const [factNameforimage2, setfactNameforimage2] = useState("");
  const [factDescription, setfactDescription] = useState("");
  const [imageNameWithExtension1, setimageNameWithExtension1] = useState("");
  const [imageNameWithExtension2, setimageNameWithExtension2] = useState("");
  const [blogName, setblogName] = useState("");
  const [blogArticle, setblogArticle] = useState("");
  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();
  const [category, setcategory] = useState("Art");
  const toastRef = React.useRef();
  const [factAddLayout, setFactAddLayout] = useState(false);

  // const handleModalClose = () => {
  //   setOpenModal(false);
  //   //console.log("Closing modal");
  //   setSelectedFact(null);
  // };
  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const validationSchema = yup.object().shape({
    imageNameWithExtension1: yup
      .string()
      .trim()
      .required('Image Name is required')
      .max(50, 'Image Name should not exceed 50 characters'),
    imageNameWithExtension2: yup
      .string()
      .trim()
      .required('Image Name is required')
      .max(50, 'Image Name should not exceed 50 characters'),
    blogName: yup
      .string()
      .trim()
      .required('Blog title is required')
      .max(45, 'Blog title should not exceed 45 characters'),
    category: yup
      .string()
      .nullable()
      .required('Category Name is required'),
    blogArticle: yup
      .string()
      .trim()
      .required('Blog Article is required')
      .max(30000000000000000000000000000000000000),
    // image1: yup.string().nullable().required("Image is required"),
    // image2: yup.string().nullable().required("image is required"),
    // startDateTime: yup.string().required("startDateTime is required")
  });
  const initialValues = {
    factId: "",
    factDescription: "",
    factNameforimage1: "",
    factNameforimage2: "",
    startDateTime: "",
    imageNameWithExtension1: "",
    imageNameWithExtension2: "",
    blogName: "",
    blogArticle: "",
    category: "",
    imageUrl: "",
    image1: '', // Set a default value as an empty string
    image2: '', // Set a default value as an empty string
    status: ""
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      handleAddFactSubmit(formik.values);
    },
  });


  const [sellerFactsData, setSellerFactsData] = useState([]);



  async function fetchFacts() {
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/facts/api/v1/GetFactbysellerId?sellerid=${sellerData?.result?.typeOfUser==='SLADM'?sellerData?.result?.userID:sellerData?.result?.userAdminId}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      });
      const facts = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        // errorAlert('', 'Your Session has expired');
        toastRef.current.showToast("Your Session has expired.", "error");
        setLoading(false);
        logout();
        return;
      }
      if (response.ok) {

        // console.log(facts);
        setSellerFactsData(facts);
        setLoading(false);
      } else {
        console.error("Unable to fetch facts:", response.status);
        setLoading(false);
      }


    } catch (e) {
      console.error("Error:", e);
    }
  }
  useEffect(() => {
    fetchFacts();
  }, [sellerData]);

  const handleAddFactSubmit = async () => {
    // event.preventDefault();
    //console.log(formik.values)
    setLoading(true);
    
    try {
      const formData = new FormData();
      formData.append("factId", uuidv4());
      formData.append("factNameforimage1", 'a');
      formData.append("factNameforimage2", 'a');
      formData.append("factDescription", 'a');
      formData.append("imageNameWithExtension1", formik.values.imageNameWithExtension1.trim());
      formData.append("imageNameWithExtension2", formik.values.imageNameWithExtension2.trim());
      formData.append("blogName", formik.values.blogName.trim());
      formData.append("blogArticle", formik.values.blogArticle.trim());
      formData.append("category", formik.values.category);
      formData.append("image1", image1);
      formData.append("image2", image2);
      formData.append("sellerid", sellerData?.result?.typeOfUser==='SLADM'?sellerData?.result?.userID:sellerData?.result?.userAdminId);
      //console.log(formData)

      const response = await fetch(`${API_BASE_URL}/facts/api/v1/CreateFacts`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${accessToken}`,

        },
        body: formData,
      });
      
      const newFact = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        // errorAlert('', 'Your Session has expired');
        toastRef.current.showToast("Your Session has expired.", "error");
        logout();
        return;
      }
      //console.log("API Response:", response);
      //console.log("New Fact:", newFact);
      
      if (response.ok && newFact?.result === true) {
        setFactsData([...factsData, newFact]);
        setfactNameforimage1("");
        setfactNameforimage2("");
        setfactDescription("");
        setimageNameWithExtension1(null);
        setimageNameWithExtension2(null);
        setcategory("");
        setblogArticle("");
        setblogName("");
        fetchFacts();
        setLoading(false);
        setImage1(null);
        setImage2(null);
        formik.setValues({ ...formik.values, blogName: '', blogArticle: '', imageNameWithExtension1:'', imageNameWithExtension2:'',category:'' });
        formik.setTouched(Object.keys(formik.values).reduce((acc, key) => ({ ...acc, [key]: false }), {}));

        // formik.resetForm({ values: initialValues });
        toastRef.current.showToast("Fact Added Successfully", "success");
      } else {
        setLoading(false);
        toastRef.current.showToast("An Error occurred. Please try again", "error");
      }
    } catch (error) {
      // setOpen(false);
      // setOpenModal(false);
      setLoading(false);
      // handleModalClose();
      toastRef.current.showToast("Could not add fact. Please try again", "error");
    }

  };
  // async function AddNewFact() {
  //   setfactNameforimage1("");
  //   setfactNameforimage2("");
  //   setfactDescription("");
  //   setimageNameWithExtension1("");
  //   setimageNameWithExtension2("");
  //   setcategory("");
  //   setblogName("");
  //   setblogArticle("");
  //   await fetchFacts();
  //   setOpen(false);
  // }

  return (
    <div>

      <SellerLayoutAdjustment >
        <ToastComponent ref={toastRef} timeout={4000} />

        <div className="page-content">
        <Heading text="Facts" />
          <div>
            <div className="seller-products-list-navbar">
              <div className="seller-products-list-search-and-filter">
                <button className="common-button" onClick={() => { setFactAddLayout(!factAddLayout) }}>+ Add New Fact</button>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ fontSize: "16px", marginRight: "8px", width: "50%" }}>Status : </div>


                  <div className="seller-products-list-category">
                    <Box sx={{ minWidth: 200 }}>
                      <FormControl fullWidth style={{ background: "white" }}>
                        {/* <InputLabel id="demo-simple-select-label">Category</InputLabel>   */}
                        <Select
                          style={{ background: "white" }}
                          //   labelId="demo-simple-select-label"
                          //   id="demo-simple-select"
                          value={status}
                          //   label="Age"
                          displayEmpty
                          onChange={handleChange}
                        >
                          <MenuItem value={10}>All </MenuItem>
                          <MenuItem value={20}>Pending</MenuItem>
                          <MenuItem value={30}>Active</MenuItem>
                          {/* <MenuItem value={40}>Expired</MenuItem> */}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="seller-products-list-table-head"> */}
            {/* <div className="seller-products-list-table-product-item-small">
          </div> */}

            {factAddLayout && <div style={{ minWidth: '300px', width: '90%', margin: '1rem',padding:'12px 6px', minHeight: '300px', borderRadius: "5px", boxShadow: `rgba(0, 0, 0, 0.25) 1px 1px 3px` }}>
              <div className="add-fact-form">
                <form className="fact-modal-form" onSubmit={formik.handleSubmit}>

                  <div className="fact-modal-form-field">
                    <div className="fact-modal-form-field-name">Blog Category</div>
                    <Box
                      sx={{
                        width: "60%",
                        minWidth:'320px',
                      }}
                    >
                      <FormControl fullWidth style={{ background: "white" }}>

                        <Select
                          style={{ background: "white" }}

                          name="category"
                          value={formik.values.category}
                          onChange={formik.handleChange}

                        >
                          <MenuItem value={"Select a Category"} disabled>Select a category</MenuItem>
                          <MenuItem value={"Art"}>Indian Art</MenuItem>
                          <MenuItem value={"Artisan"}>Indian Artist</MenuItem>
                        </Select>
                        <FormHelperText style={{ fontSize: "11px", color: "#D32F2F" }}>{formik.touched.category && formik.errors.category}</FormHelperText>
                      </FormControl>
                    </Box>
                  </div>

                  <div className="fact-modal-form-field">
                    <div className="fact-modal-form-field-name">Blog Title</div>
                    <TextField
                      name="blogName"
                      value={formik.values.blogName}
                      onChange={formik.handleChange}
                      error={formik.touched.blogName && Boolean(formik.errors.blogName)}
                      helperText={formik.touched.blogName && formik.errors.blogName}
                      sx={{
                        width: "60%",
                        minWidth:'320px',
                        "&:focused": {
                          borderColor: "orange",
                          borderWidth: "2px",
                        },
                      }}
                    />
                  </div>

                  <div className="fact-modal-form-field">
                    <div className="fact-modal-form-field-name">Blog Article</div>
                    <textarea
                      style={{ resize: "none", padding: "10px", border: "1px solid #80808073", background: "white",minWidth:"300px", width:'60%' ,minHeight:"160px" ,borderRadius:"4px" }}
                      fullWidth
                      name="blogArticle"
                      className="seller-field-text-area-facts"
                      value={formik.values.blogArticle}
                      onChange={formik.handleChange}
                      // error={formik.touched.blogArticle && Boolean(formik.errors.blogArticle)}
                      sx={{
                        width: "60%",

                        minHeight: "200px",
                      }}
                    />
                  </div>
                  <FormHelperText style={{color:"#D32F2F",textAlign:"center"}}>{formik.touched.blogArticle && formik.errors.blogArticle}</FormHelperText>



                  <div className="fact-modal-form-field">
                    <div className="fact-modal-form-field-name" ><p>Image 1</p>
                      <p className="note" style={{ fontSize: "13px" }}>Showcase the beauty of the Artwork</p></div>
                    <div className="add-image" style={{ margin: "0 auto", padding: "0px",}}>
                    <label className="choose-file-btn" style={{background:'white'}}>
  <input
    type="file"
    name="image"
    value={formik.values.image1}
    onChange={async (event) => {
      setImageLoading1(true);
      const uploadedImage = event.target.files[0];

      const maxSizeInBytes = 5 * 1024 * 1024;
      if (uploadedImage.size > maxSizeInBytes) {
        toastRef.current.showToast("File size exceeds 5 MB limit", "error");
        setImageLoading1(false);
        return;
      }

      // Use FileReader to read the magic number
      const fileReader = new FileReader();

      fileReader.onloadend = async () => {
        const arrayBuffer = fileReader.result;
        const uint8Array = new Uint8Array(arrayBuffer);

        // Check if the magic number corresponds to jpg/jpeg/png
        if (
          uint8Array.length >= 2 &&
          uint8Array[0] === 0xFF &&
          uint8Array[1] === 0xD8
        ) {
          // JPG file
        } else if (
          uint8Array.length >= 4 &&
          uint8Array[0] === 0x89 &&
          uint8Array[1] === 0x50 &&
          uint8Array[2] === 0x4E &&
          uint8Array[3] === 0x47
        ) {
          // PNG file
        } else if (
          uint8Array.length >= 2 &&
          uint8Array[0] === 0xFF &&
          uint8Array[1] === 0xD9
        ) {
          // JPEG file (ending with EOI marker)
        } else {
          // Invalid file type
          toastRef.current.showToast("Invalid file type. Please choose a valid jpg, jpeg, or png file.", "error");
          setImageLoading1(false);
          return;
        }

        // Continue with the rest of your code
        const validTypes = ["image/jpeg", "image/png", "image/jpg"];
        if (!validTypes.includes(uploadedImage.type)) {
          toastRef.current.showToast("File type must be jpg, jpeg, or png.", "error");
          setImageLoading1(false);
          return;
        }

        const fileExtension = uploadedImage.name.split(".").pop();
        const imageNameWithExtension1 = `${uuidv4()}.${fileExtension}`;

        const formData = new FormData();
        formData.append("ContainerName", "ecomdata");
        formData.append("FileNameWithExtension", imageNameWithExtension1);
        formData.append("FileCategory", "Facts");
        formData.append("Attachment", uploadedImage, uploadedImage.name);

        try {
          const response = await fetch(
            `${API_BASE_URL}/blob/api/v1/uploadmediaWithContainer`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              body: formData,
            }
          );
            const result=await response.json();
            const handleAuthError = handleAuthExpiry(response);
            if (handleAuthError === true) {
              // errorAlert('', 'Your Session has expired');
              toastRef.current.showToast("Your Session has expired", "error");
              logout();
              return;
            }
          

          formik.setFieldValue("imageNameWithExtension1", imageNameWithExtension1);
          setImage1(uploadedImage);
          setimageNameWithExtension1(imageNameWithExtension1);
          setImageLoading1(false);
        } catch (error) {
          console.error(error);
          setImageLoading1(false);
        }
      };

      fileReader.readAsArrayBuffer(uploadedImage);
    }}
  />
  <img loading="lazy" src={imageSeller} alt="" />
</label>

                      {imageLoading1 && (
                        <CircularProgress className="file-submitted" />
                      )}
                      {!imageLoading1 && formik.values.imageNameWithExtension1 !== '' && <CheckCircle className="file-submitted" />}
                      {formik.touched.image1 && formik.errors.image1 && !image1 && (
    <div className="error-message-facts-field" style={{ textAlign: 'center' }}>
      {formik.errors.image1}
    </div>
  )}
  
                    </div>
                  </div>


                  <div className="fact-modal-form-field">
                    <div className="fact-modal-form-field-name"><p>Image 2</p>
                      <p className="note" style={{ fontSize: "13px" }}>Glimpse of the talented Artisan behind the creation</p></div>
                    <div className="add-image" style={{ margin: "0 auto", padding: "0px", }}>
                      <label className="choose-file-btn" style={{background:'white'}}>
                      <input
    type="file"
    name="image"
    value={formik.values.image2}
    onChange={async (event) => {
      setImageLoading2(true);
      const uploadedImage = event.target.files[0];

      const maxSizeInBytes = 5 * 1024 * 1024;
      if (uploadedImage.size > maxSizeInBytes) {
        // errorAlert("File size exceeds 5 MB limit.");
          toastRef.current.showToast("File size exceeds 5 MB limit.", "error");
        setImageLoading2(false);
        return;
      }

      // Use FileReader to read the magic number
      const fileReader = new FileReader();

      fileReader.onloadend = async () => {
        const arrayBuffer = fileReader.result;
        const uint8Array = new Uint8Array(arrayBuffer);

        // Check if the magic number corresponds to jpg/jpeg/png
        if (
          uint8Array.length >= 2 &&
          uint8Array[0] === 0xFF &&
          uint8Array[1] === 0xD8
        ) {
          // JPG file
        } else if (
          uint8Array.length >= 4 &&
          uint8Array[0] === 0x89 &&
          uint8Array[1] === 0x50 &&
          uint8Array[2] === 0x4E &&
          uint8Array[3] === 0x47 
        ) {
          // PNG file
        } else if (
          uint8Array.length >= 2 &&
          uint8Array[0] === 0xFF &&
          uint8Array[1] === 0xD9
        ) {
          // JPEG file (ending with EOI marker)
        } else {
          // Invalid file type
          // errorAlert("Invalid file type. Please choose a valid jpg, jpeg, or png file.");
          toastRef.current.showToast("Invalid file type. Please choose a valid jpg, jpeg, or png file.", "error");
          setImageLoading2(false);
          return;
        }

        // Continue with the rest of your code
        const validTypes = ["image/jpeg", "image/png", "image/jpg"];
        if (!validTypes.includes(uploadedImage.type)) {
          // errorAlert("File type must be jpg, jpeg, or png.");
          toastRef.current.showToast("File type must be jpg, jpeg, or png.", "error");
          setImageLoading2(false);
          return;
        }

        const fileExtension = uploadedImage.name.split(".").pop();
        const imageNameWithExtension2 = `${uuidv4()}.${fileExtension}`;

        const formData = new FormData();
        formData.append("ContainerName", "ecomdata");
        formData.append("FileNameWithExtension", imageNameWithExtension2);
        formData.append("FileCategory", "Facts");
        formData.append("Attachment", uploadedImage, uploadedImage.name);

        try {
          const response = await fetch(
            `${API_BASE_URL}/blob/api/v1/uploadmediaWithContainer`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              body: formData,
            }
          );

          const result=await response.json();
          const handleAuthError = handleAuthExpiry(response);
          if (handleAuthError === true) {
            // errorAlert('', 'Your Session has expired');
                                toastRef.current.showToast("Your Session has expired", "error");
            logout();
            return;
          }

          if (!response.ok) {
            // Handle error if upload fails
          }

          formik.setFieldValue("imageNameWithExtension2", imageNameWithExtension2);
          setImage2(uploadedImage);
          setimageNameWithExtension2(imageNameWithExtension2);
          setImageLoading2(false);
        } catch (error) {
          console.error(error);
          setImageLoading2(false);
        }

        if (!formik.touched.image2 && formik.errors.image2) {
          formik.setFieldError("image2", formik.errors.image2);
        }
      };

      fileReader.readAsArrayBuffer(uploadedImage);
    }}
  />
  <img loading="lazy" src={imageSeller} alt="" />
</label>

                      {imageLoading2 && (
                        <CircularProgress className="file-submitted" />
                      )}
                      {!imageLoading2 && formik.values.imageNameWithExtension2 !== '' && <CheckCircle className="file-submitted" />}
                      {formik.touched.image2 && formik.errors.image2 && !image2 && (
    <div className="error-message-facts-field" style={{ textAlign: 'center' }}>
      {formik.errors.image1}
    </div>
  )}
                    </div>
                  </div>

                  <div className="facts-modal-button">
                    {!loading && (<input type="submit" className="common-btn" value={'Add Fact'} />)}
                    {loading && (
                      <div
                        style={{
                          display: "flex",
                          width: "25%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress style={{ color: "pink" }} />
                      </div>
                    )}
                  </div>
                </form>
              </div>
              {/* <button className="common-button" onClick={addNewFact}>+ Add  Fact</button> */}
            </div>}

           {!loading&& <table className="SellerDiscount-table">
              <thead className="seller-discount-thead">
                <tr>
                  <th>Blog Title</th>
                  <th>Blog Article</th>
                  {/* <th>Start Date</th>
      <th>End Date</th> */}
                  <th>Status</th>
                </tr>
              </thead>

           
              <tbody>
            
                {sellerFactsData?.result?.map((cardProps) => {
                  if (status === 10 && cardProps.status === "All Coupons") {
                    return null;
                  }
                  if (status === 20 && cardProps.status !== "Pending") {
                    return null;
                  }
                  if (status === 30 && cardProps.status !== "Active") {
                    return null;
                  }
                  if (status === 40 && cardProps.status !== "Expired") {
                    return null;
                  }
                  return (
                    <tr key={cardProps.factId}>
                      <td data-th="Name" >{cardProps.blogName}</td>
                      <td className="description" data-th="Description"
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          maxWidth: "200px"
                        }} >
                        {cardProps.blogArticle}
                      </td>
                      {/* <td>{cardProps.startDateTime}</td>
          <td>{cardProps.expiryDateTime}</td> */}
                      <td data-th="Status">
                        <div className="seller-discount-status">
                          {cardProps.status === "Pending" && (
                            <div className="status-indicator-pending"> </div>
                          )}
                          {cardProps.status === "Active" && (
                            <div className="status-indicator-active"> </div>
                          )}
                          {cardProps.status === "Expired" && (
                            <div className="status-indicator-expired"> </div>
                          )}
                          {cardProps.status}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>}
            {loading && <div className="loading-container" style={{ minHeight: "300px", width:'90%', maxWidth:'400px', margin:'0 auto' }}>
            <Loading />

          </div>}
          </div>
        </div>
      </SellerLayoutAdjustment>
    </div>
  )
              }