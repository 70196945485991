import React from "react";
import "../styles/Common.css";
import "../styles/SellerRegistration.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { API_BASE_URL } from '../App'

import { CircularProgress, IconButton, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/SellerRegistrationScreen.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
// import { Checkbox } from "@mui/material";
// import PersonIcon from "@mui/icons-material/Person";
// import CallIcon from "@mui/icons-material/Call";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import { useContext } from "react";
// import byrRegisterImg from "../Images/buyerRegisterImg.png";
// import MailOutlineIcon from "@mui/icons-material/MailOutline";
// import LockOpenIcon from "@mui/icons-material/LockOpen";
// import "../styles/SignupPage.css";
import { useState } from "react";
import { Link } from 'react-router-dom';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { errorAlert, successAlert } from "./Alert";
import BuyerCard from "./BuyerCard";
import "../styles/BuyerRegister.css"

const phoneRegExp = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;
const nameRegExp=/^[A-Z][a-zA-Z]{3,}\S*$/;
const passwordRegexp = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{6,20}$)"
);

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Invalid Password")
    .matches(passwordRegexp, "Password is not valid")
    .required("Password is required"),
  number: yup
    .string().trim()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required*")
    .min(10, "Phone number must consist of 10 digits")
    .max(10, "Phone number must consist of 10 digits"),
  firstName: yup
    .string("Enter Your First Name")
    .required("First Name is required"),
  lastName: yup
    .string("Enter Your last Name")
    .required("Last Name is required"),
  termsAndConditions: yup
    .bool("Enter terms and conditions")
    .oneOf([true], "You need to accept the terms and conditions"),
  rePassword: yup
    .string("Enter Your Password again")
    .oneOf([yup.ref("password"), null], "Passwords must match")
    // .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export default function BuyerRegister(props) {
  
  const navigate = useNavigate();
  const location = useLocation();
  const gotoLogin = () => {
    navigate("/login");
  };

  const [loading, setLoading] = useState(false);

  const [radioValue, setRadioValue] = React.useState("");
  const [radioError, setRadioError] = React.useState(false);
  const [radioHelperText, setRadioHelperText] = React.useState("");
  let day = new Date().getDate();
  //  ////console.log(day);
  let month = new Date().getMonth() + 1;
  //  ////console.log(month);

  const year = new Date().getFullYear();
  //  ////console.log(year);

  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  const maxDate = `${year}-${month}-${day}`;
  //  ////console.log(maxDate);
  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPasswordEntered(value !== "");
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [passwordEntered, setPasswordEntered] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    setRadioHelperText(" ");
    setRadioError(false);
  };

  // document.getElementById("checkBox").checked=checked;

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rePassword: "",
      number: "",
      firstName: "",
      lastName: "",
      termsAndConditions: false,
      date: "",
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
    //   setLoading(true)
      // setErrorCheckboxText(true);
      // ////console.log(formik.values)
      if (radioValue === "male") {
        setRadioHelperText("");
      } else if (radioValue === "female") {
        setRadioHelperText("");
      } else if (radioValue === "other") {
        setRadioHelperText("");
      } else {
        setRadioHelperText("Please select an option.");
        setRadioError(true);
        setLoading(false);
        return;
      }

      if (radioValue) {
        setLoading(true);
        const result = await postData();
        ////console.log("After posting Data", result);

        // const response = await redirectLogin(sellerData?.result?.email, formik.values.password);
        // const data = await result.json();
        // //console.log(data);  
        if (result?.result) {
          successAlert('', 'Registration Successful');
          navigate("/login");
          setLoading(false);

        }
        else {
          errorAlert('', 'Something went wrong. Please try again.');
          setLoading(false);

        }
      }
    },
  });
  //////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////////////////////////

  async function postData() {
    try {

      const response = await fetch(`${API_BASE_URL}/users/api/v1/registration`, {
        method: "post",
        // mode: "no-cors",
        headers: {
          //   Accept: "application.json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          email: formik.values.email,
          // salt: "string",
          password: formik.values.password,
          mobileNumber: formik.values.number,
          firstName: formik.values.firstName,
          lastName: "",
          typeOfUser: "BYR",
          dateOfBirth: formik.values.date,
        }),
      });
      const data = await response.json();
      // ////console.log(data);
      ////console.log("After posting Data", data);

      return data;
    } catch (e) {

      ////console.log(e);
    }
  }

  return (
    <BuyerCard
    >

      {/* <div className="seller-register-container"> */}
      {/* <div className="seller-register-welcome-layout">
    <div className="seller-register-welcome-layout-heading">
        Welcome to the World of Myhraki
    </div>
    <div className="seller-register-welcome-layout-desc">
        Let’s dive into MyhraKi and explore its world to grow your business
    </div>
    </div> */}
      <div className="buyer-login-right">
        <div className="byr-signup-card-container">
          {/* <div className="register-page-main-logo">
        <img loading="lazy"  className='register-page-logo' src={Logo} alt="" />
    </div> */}
          <div className="byr-signup-head">Register</div>
          <form className="byr-signup-page-form" onSubmit={formik.handleSubmit}>
            <div className="signup-row-byr">
              <div className="sigup-row-fields-byr">
            <div className="signup-first-title">
            Your Name
              </div>
              <div className="signup-first-byr">
                <TextField
                  variant="outlined"
                  fullWidth
                //   disabled
                  // autoFocus
                  type="text"
                  id="byr-signup-firstName"
                  name="firstName"
                  className="byr-signup-firstName seller-outline"
                  // placeholder="Enter Your First Name"
                  value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={
                  formik.touched.firstName && formik.errors.firstName
                }
                />
                {/* <PersonIcon
                  className="signup-firstName-icon"
                  fontSize="medium "
                /> */}
              </div>
              </div>
            
              <div className="sigup-row-fields-byr">
              <div className="signup-first-title">
              Mobile Number
              </div>
              <div className="signup-number-field-byr">
                <TextField
                  variant="outlined"
                  fullWidth
                //   disabled
                  type="text"
                  id="signup-number-byr"
                  name="number"
                  className="signup-number-byr seller-outline"
                  // placeholder="Enter Your Phone Number * "
                  value={formik.values.number}
                onChange={formik.handleChange}
                error={formik.touched.number && Boolean(formik.errors.number)}
                helperText={formik.touched.number && formik.errors.number}
                />
                {/* <CallIcon className="signup-number-icon" fontSize="medium " /> */}
              </div>
              </div>
            </div>
            
            <div className="signup-row-byr">
            <div className="sigup-row-fields-byr">
            <div className="signup-first-title">
            Your Email
              </div>
              <div className="signup-email-field-byr ">
                <TextField
                  variant="outlined"
                  fullWidth
                //   disabled
                  type="text"
                  id="signup-email-byr"
                  name="email"
                  className="signup-email-byr seller-outline"
                  // placeholder="Enter Email Address * "
                  value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                />
                {/* <MailOutlineIcon
                  className="signup-email-icon"
                  fontSize="medium "
                /> */}
              </div>
              {/* <div className="signup-calendar-field">
                <input
                  variant="outlined"
                  fullWidth
                  type="date"
                  className="signup-calendar seller-outline"
                  placeholder="Enter date"
                  name="date"
                  value={formik.values.date}
                  onChange={formik.handleChange}
                  min="1919-01-01"
                  max={maxDate}
                  id="dt"
                // value="DateTime.Today.ToString("yyyy-MM-dd")
                />
                <CalendarMonthIcon
                  className="signup-date-icon"
                  fontSize="medium "
                />
              </div> */}
              </div>
            </div>
            <div className="signup-row-byr">
            <div className="sigup-row-fields-byr">
            <div className="signup-first-title">
             Password
              </div>
              <div className="signup-password-field-byr">
                <TextField
                  variant="outlined"
                  fullWidth
                  // autoFocus
                  type={showPassword ? "text" : "password"}
                  id="enter-password-byr"
                  name="password"
                  //   className="login-page-email"
                  className="signup-email-byr seller-outline"
                  // placeholder="Enter a desired Password * "
                  value={formik.values.password}
                  onChange={(event) => {
                    formik.handleChange(event);
                    handlePasswordChange(event);
                  }}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: passwordEntered && (
                      <IconButton onClick={handleTogglePassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
                {/* <LockOpenIcon
                  className="seller-password-icon"
                  fontSize="medium "
                /> */}
              </div>
              {/* <div className="signup-password-field">
                <TextField
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  type={showRePassword ? 'text' : 'password'}
                  id="resetPassword"
                  name="rePassword"
                  className="signup-email seller-outline"
                  placeholder="Re-Enter your Password"
                  value={formik.values.rePassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.rePassword &&
                    Boolean(formik.errors.rePassword)
                  }
                  helperText={
                    formik.touched.rePassword && formik.errors.rePassword
                  }
                  InputProps={{
                    endAdornment: passwordEntered && (
                      <IconButton onClick={() => setShowRePassword(!showRePassword)}>
                        {showRePassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}

                />
                <LockOpenIcon
                  className="seller-reset-phone-icon"
                  fontSize="medium "
                />
              </div> */}
              </div>
            </div>
            <FormControl
              className="byr-registration-gender"
              error={radioError}
              variant="standard"
            >
              {/* <FormLabel id="demo-error-radios">Gender</FormLabel> */}
              {/* <div className="byr-registration-gender">Gender</div> */}
              <RadioGroup
                name="gender"
                value={radioValue}
                onChange={handleRadioChange}
                className="signup-gender-radio-byr"
                row
                style={{
                  display: "flex",
                  justifycontent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <FormControlLabel
                  fontSize="medium "
                  style={{ marginRight: "3rem" }}
                  value="male"
                  className="signup-male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  fontSize="medium "
                  style={{ marginRight: "3rem" }}
                  value="female"
                  className="signup-female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  fontSize="medium "
                  style={{ marginRight: "3rem" }}
                  value="other"
                  className="signup-other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
              <FormHelperText
                style={{
                  fontSize: "1rem",
                  marginTop: "1rem",
                  left: "8%",
                  bottom: "-25%",
                }}
              >
                {radioHelperText}
              </FormHelperText>
            </FormControl>
            
            <div className="sigup-button-fields-byr">
            {!loading && <input
              type="submit"
              className="byr-common-button"
              value="Sign Up"
            />}
            </div>
            <div className="sigup-button-fields-byr">
            {loading && <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "pink" }} /></div>}
</div>
          </form>
          <div className="register-footer-content-byr">
          {/* <div className="registration-terms-text-byr">
              <Checkbox
                type="checkbox"
                name="termsAndConditions"
                id="signup-terms"
                className="signup-terms"
                // placeholder="Enter a desired Password"
                value={formik.values.termsAndConditions}
                onChange={formik.handleChange}
                error={
                  formik.touched.termsAndConditions &&
                  Boolean(formik.errors.termsAndConditions)
                }
                helperText={
                  formik.touched.termsAndConditions &&
                  formik.errors.termsAndConditions
                }
              />
              <div className="buyer-register-terms-text">
                I agree to the  <Link to="/termsAndCondition" className='register-privacy-link'>Terms and Conditions</Link> and  <Link to="/privacyPolicy" className='register-privacy-link'>Privacy Policy</Link> of MyhraKi.
              </div>
            </div> */}
              
          <div className="signup-register-byr">
            Already a User? ,{" "}
            <span style={{  color: "#FF6155" }} onClick={gotoLogin}>
              Sign In{" "}
            </span>
          </div>
          <div className="byr-terms-condition">
              By continuing, you agree to MyhraKi's
              <Link to="/termsAndCondition" className='register-privacy-link'>Terms and Conditions</Link> and <Link to="/privacyPolicy" className='register-privacy-link'>Privacy Policy</Link> 
            </div>
          </div>
        </div>
      </div>
    </BuyerCard>
  );
}
