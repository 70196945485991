import React from "react";
import "../styles/SingleProduct.css";
// import CustomerLoginModal from "./CustomerLoginModal";

export default function Quantity(props) {
  const { selectedQuantity, setSelectedQuantity } = props
  // const [loginModalOpen, setLoginModalOpen] = React.useState(false);
  // const { customerData, accessToken } = useContext(CustomerContext);


  //  useEffect(() => {
  //       
  //     }, [selectedQuantity]);
  function incrementCount() {
 

      setSelectedQuantity(selectedQuantity + 1);
    
  }
  function decrementCount() {
    if (selectedQuantity > 0) {
   

        setSelectedQuantity(selectedQuantity - 1);
      }
    

  }

  return (
    <div className="quantity">
        <button className="operation-button" onClick={decrementCount}>
        -
      </button>
     

      <div
        className="selected-number"
        style={{ display: "inline-block", lineHeight: "1rem" }}
      >
        {selectedQuantity}
      </div>
      <button className="operation-button" onClick={incrementCount}>
        +
      </button>
   
    </div>
  )
}
