import { Divider, TextField } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { API_BASE_URL } from "../App";
import { CustomerContext } from "../context/CustomerContext";
import ToastComponent from "../components/ToastComponent";
// import "../styles/Commomn.css";
import "../styles/CustomerWallet.css";
import { WarningCircle } from "@phosphor-icons/react/dist/ssr";

const CustomerWallet = (props) => {
  const [code, setCode] = useState("");
  const [balance, setBalance] = useState(0);
  const [submitClick, setSubmitClick] = useState(false);
  const toastRef = useRef();
  const [giftCardAmount, setGiftCardAmount] = useState(0);

  const { customerData, accessToken } = useContext(CustomerContext);
  // `${API_BASE_URL}/coupons/api/v1/GetVoucherStatus?PurchaseUserid=${customerData?.result?.userID}`,
  const fetchGiftCardBalance = async () => {
    const response = await fetch(
      `${API_BASE_URL}/coupons/api/v1/GiftCardLedger?UserId=${customerData?.result?.userID}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    console.log(response);
    const data = await response.json();
    console.log(data);
    console.log(data?.result);
    const sortedData = data?.result?.sort((a, b) => b.id - a.id);

    // if (data?.result) {
    //   setGiftCardAmount(
    //     data?.result?.reduce(
    //       (acc, total) => acc + parseFloat(total?.totalAmount)-parseFloat(total?.usedAmount),
    //       0
    //     )
    //   );}
    // console.log("Sorted gift card ledger data:", giftCardAmount);

    // Update the balance with the value from the most recent entry
    const mostRecentEntry = sortedData[0];
    // console.log("Most recent entry:", mostRecentEntry);
    // setBalance(parseFloat(mostRecentEntry?.totalAmount || 0));
    setBalance(parseFloat((mostRecentEntry?.totalAmount || 0).toFixed(2)));
    // const balanceValue1 = data?.result.reduce(
    //   (sum, item) => sum + parseFloat(item?.totalAmount || 0),
    //   0

    // );
    // console.log(balanceValue1);
    // const balanceValue1 = data?.result?.result?.reduce(
    //   (sum, item) => sum + parseFloat(item?.balancedAmount || 0),
    //   0
    // );
    // console.log(balanceValue);
    // setBalance(balanceValue);
  };
  console.log(customerData?.result?.userID);
  const activateCode = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/coupons/api/v1/ActivateVoucher`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application / json - patch + json",
          },
          body: JSON.stringify({
            voucherCode: code,
            receptanceId: customerData?.result?.userID,
          }),
        }
      );
      const data = await response.json();
      console.log(data);
      if (data?.result === "UpdateStatus") {
        toastRef.current.showToast("Amount added to Wallet", "success");
      } else {
        toastRef.current.showToast("Unsuccefull Update", "error");
      }
      setCode("");
    } catch {}
  };

  const addCode = async () => {
    if (code.length > 10) {
      await activateCode();
      fetchGiftCardBalance();
    } else if (code.length <= 0) {
      setSubmitClick(true);
    } else {
      toastRef.current.showToast("Invalid Voucher Code", "error");
    }
  };

  useEffect(() => {
    fetchGiftCardBalance();
  }, []);

  return (
    <div className="wallet-main">
      <ToastComponent ref={toastRef} timeout={4000} />

      <div className="wallet-gc">Gift Card</div>
      <div className="wallet-balance">
        Gift Card balance: <span style={{ color: "green" }}>₹{balance}</span>
      </div>
      <Divider />
      <div className="wallet-gcadd">Add Gift Card to balance</div>
      <TextField
        value={code}
        onChange={(e) => setCode(e.target.value)}
        placeholder="Enter gift card code"
        className="wallet-voucher"
      />
      {submitClick && code.length === 0 && (
        <p className="wallet-error-msg"> * Please enter a valid voucher code</p>
      )}
      <p className="wallet-eg">
        e.g <u>8U95-Y3E8CQ-39MPQ</u>
      </p>

      <button className="wallet-common-btn" onClick={addCode}>
        Add Gift card to Balance
      </button>

      <span className="wallet-warnoptn">
        <WarningCircle
          size={16}
          weight="light"
          color="rgba(119, 119, 119, 1)"
        />
        <span className="wallet-option">
          For optimal utilization, balance expiring the earliest will be
          redeemed first.
        </span>
      </span>
    </div>
  );
};

export default CustomerWallet;
