import React from 'react'
import '../styles/CustSingleProductBanner.css'
import JuttiClip from '../Images/JuttiClip.png'
import Jutti from '../Images/Jutti.png'


function CustSingleProductBanner() {
  return (
    <div className='product-banner-main'>
       <div className='product-banner1'>
            <div><img src={JuttiClip} className='banner-clip'/></div>
            <div className='banner-prdct-detail1'>
                <div className='banner-prdct-desc1'>
                    <span className='banner-prdct-name'>Jhuttis</span>
                    <div className='banner-prdct-origin'>From Rajasthan</div>
                    
                </div>
                <div className='banner-prdct-desc2'><span 
                style={{color:'#000',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight:'140%'}}>
                Rajasthani juttis and the mojari</span> are interchangeable terms. The traditional Indian footwear popularized in North India, especially around Rajasthan, Punjab, and Haryana.  </div>
            </div>
       </div>
       <div className='product-banner2'>
            <div><img src={Jutti} className='banner-image'/></div>
            <div className='banner-prdct-detail2'>
                <div className='banner-prdct-desc1'>
                    <span className='banner-prdct-name'>Jhuttis</span>
                    <div className='banner-prdct-origin'>From Rajasthan</div>
                </div>
                <div className='banner-prdct-desc2'>Accordingly, Rajasthani juttis are made of bright vibrant colors that are ergonomically very comfortable to use. Initially, the process of jutti making involved the use of leather and extensive embroidery.The use of juttis on a daily basis or for special occasions depends on their type. With the wedding season around the corner, here are 4 go-to brands to consider while shopping Rajasthani juttis.  </div>
            </div>
       </div>
       
    </div>
  )
}

export default CustSingleProductBanner
