import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import SellerFooter from "../components/SellerFooter";
import SubsNavbar from "../components/SubsNavbar";
import "../styles/Seller.css";
import * as yup from "yup";
import { useFormik } from "formik";
import Heading from "../components/Heading";

export default function SellerMyAccount() {
  const [sellerMyAccountData, setSellerMyAccountData] = useState([]);

  const fetchSellerMyAccountData = async () => {
    const response = await fetch(
      "https://jsonplaceholder.typicode.com/users/1",
      {
        // mode: "no-cors",
        headers: {
          // 'Accept': 'text/plain',
          // 'Content-Type': 'text/plain',
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    const data = await response.json();
    setSellerMyAccountData(data);
    ////console.log(data);
  };

  useEffect(() => {
    fetchSellerMyAccountData();
  }, []);

  const [editable, setEditable] = useState(false);

  const editForm = () => {
    window.scrollTo(0, 0);

    setEditable(true);
  };
  const aadharRegExp =
    /(^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$)|(^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$)|(^[2-9]{1}[0-9]{3}-[0-9]{4}-[0-9]{4}$)/;

  const panRegExp = /([A-Z]|[a-z]{5}[0-9]{4}[A-Z]|[a-z]{1}$)/;
  const gstRegExp =
    /([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$)/;
  const bankAccountNumberRegExp = /[0-9]{9,18}$/;
  const bankIfscRegExp = /([A-Z]{4}0[A-Z0-9]{6}$)/;

  const validationSchemaSellerMyAccount = yup.object({
    aadhar: yup
      .string("Enter your Aadhar number")
      .matches(aadharRegExp, "Aadhar number is not valid")
      .min(12, "Aadhar Number should be of minimum 12 characters length")
      .required("Aadhar Number is required"),
    pan: yup
      .string("Enter your Pan number")
      .matches(panRegExp, "Pan number is not valid")
      .min(10, "PAN should be of minimum 10 characters length")
      .required("PAN Number is required"),
    gst: yup
      .string("Enter your GST number")
      .matches(gstRegExp, "GST number is not valid")
      //   .min(12, " Number should be of minimum 12 characters length")
      .required("GST Number is required"),
    accountNumber: yup
      .string("Enter your Account number")
      .matches(bankAccountNumberRegExp, "Account number is not valid")
      //   .min(12, "Aadhar Number should be of minimum 12 characters length")
      .required("Account Number is required"),
    ifsc: yup
      .string("Enter your ISFC Code")
      .matches(bankIfscRegExp, "IFSC code is not valid")
      .required("Ifsc code is required"),
    name: yup
      .string("Enter Your Name")
      .required("Account Holder's name is required")
      .min(2, "Account Holder's Name should be of minimum 2 characters"),
    branchName: yup
      .string("Enter BAnk Name")
      .required("Bank's name is required")
      .min(2, "Bank's Name should be of minimum 2 characters"),
    accountType: yup
      .number("Please select a Bank Account type")
      .test((value) => value > 0),
  });

  const formikDetails = useFormik({
    initialValues: {
      aadhar: sellerMyAccountData.name,
      pan: sellerMyAccountData.id,
      gst: sellerMyAccountData.username,
      accountNumber: sellerMyAccountData.email,
      ifsc: sellerMyAccountData.phone,
      name: sellerMyAccountData.id,
      branchName: sellerMyAccountData.website,
      accountType: 0,
      paymentStatus: "Success",
      MSME: "MSME.png",
      subsPlan: 0,
    },
    validationSchema: validationSchemaSellerMyAccount,
    onSubmit: () => {
      // setCardDetails(formikDetails.values.fullname);
      //   cardDetails.push(formikDetails.values);
      // ////console.log(cardDetails);
      ////console.log(formikDetails.values);
      setEditable(false);

      // setCity("")
    },
  });

  // const [openModal, setOpenModal] = useState(false);
  // const handleModalOpen = () => setOpenModal(true);
  // const handleModalClose = () => setOpenModal(false);

  // const handleClick = () => {
  //     // props.functionChangeState();
  //     handleModalOpen();
  //   };

  async function postData() {
    try {
      let result = await fetch(
        "https://webhook.site/cc1cd566-2d01-4931-96af-1e7d036a27cb",
        {
          method: "post",
          mode: "no-cors",
          withCredentials: true,
          headers: {
            Accept: "application.json",
            "Content-type": "application.json",
          },
          body: JSON.stringify({
            aadhar: formikDetails.values.aadhar,
            pan: formikDetails.values.pan,
            gst: formikDetails.values.gst,
            accountNumber: formikDetails.values.accountNumber,
            ifsc: formikDetails.values.ifsc,
            name: formikDetails.values.name,
            branchName: formikDetails.values.branchName,
            accountType: formikDetails.values.accountType,
            paymentStatus: "Success",
            MSME: formikDetails.values.MSME,
            subsPlan: age,
          }),
        }
      );
      ////console.log(result);
    } catch (e) {
      // setToken(false)
      ////console.log(e);
    }
  }

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };



  return (
    <div>
      <SubsNavbar />
      {/* <SubsSubnav /> */}
      <div className="page-content">
        <Heading text='My Account' />
        <div className="sellerMyAccountForm">
          <form className="seller-page-form">
            <div className="">
              <div className="seller-form-field">
                <div className="seller-my-account-field-name">Aadhar Card Number*: </div>
                <TextField
                  autoFocus
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  type="text" id="invite_sellerName"
                  name="aadhar"
                  className={
                    editable
                      ? "seller-form-field"
                      : "seller-form-field-disabled"
                  }
                  // placeholder=" "
                  value={
                    editable ? formikDetails.values.aadhar : sellerMyAccountData.name
                  }
                  onChange={formikDetails.handleChange}
                  error={
                    formikDetails.touched.aadhar &&
                    Boolean(formikDetails.errors.aadhar)
                  }
                  disabled={editable ? false : true}
                  helperText={
                    formikDetails.touched.aadhar &&
                    formikDetails.errors.aadhar
                  }
                />
              </div>
              <div className="seller-form-field">
                <div className="seller-my-account-field-name">GST Number*: </div>
                <TextField
                  autoFocus
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  type="text" id="invite_sellerName"
                  name="gst"
                  className={
                    editable
                      ? "seller-form-field"
                      : "seller-form-field-disabled"
                  }
                  // placeholder=" "
                  value={editable ? formikDetails.values.gst : sellerMyAccountData.id}
                  onChange={formikDetails.handleChange}
                  error={
                    formikDetails.touched.gst &&
                    Boolean(formikDetails.errors.gst)
                  }
                  disabled={editable ? false : true}
                  helperText={
                    formikDetails.touched.gst &&
                    formikDetails.errors.gst
                  }
                />
              </div>
              <div className="seller-form-field">
                <div className="seller-my-account-field-name">Pan Card Number*: </div>
                <TextField
                  autoFocus
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  type="text" id="invite_sellerName"
                  name="pan"
                  className={
                    editable
                      ? "seller-form-field"
                      : "seller-form-field-disabled"
                  }
                  // placeholder=" "
                  value={
                    editable ? formikDetails.values.pan : sellerMyAccountData.username
                  }
                  onChange={formikDetails.handleChange}
                  error={
                    formikDetails.touched.pan &&
                    Boolean(formikDetails.errors.pan)
                  }
                  disabled={editable ? false : true}
                  helperText={
                    formikDetails.touched.pan &&
                    formikDetails.errors.pan
                  }
                />
              </div>
              <div className="seller-form-field">
                <div className="seller-my-account-field-name">Bank Account No*: </div>
                <TextField
                  autoFocus
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  type="text" id="invite_sellerName"
                  name="accountNumber"
                  className={
                    editable
                      ? "seller-form-field"
                      : "seller-form-field-disabled"
                  }
                  // placeholder=" "
                  value={
                    editable
                      ? formikDetails.values.accountNumber
                      : sellerMyAccountData.email
                  }
                  onChange={formikDetails.handleChange}
                  error={
                    formikDetails.touched.accountNumber &&
                    Boolean(formikDetails.errors.accountNumber)
                  }
                  disabled={editable ? false : true}
                  helperText={
                    formikDetails.touched.accountNumber &&
                    formikDetails.errors.accountNumber
                  }
                />
              </div>
              <div className="seller-form-field">
                <div className="seller-my-account-field-name">Bank Name*: </div>
                <TextField
                  autoFocus
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  type="text" id="invite_sellerName"
                  name="name"
                  className={
                    editable
                      ? "seller-form-field"
                      : "seller-form-field-disabled"
                  }
                  // placeholder=" "
                  value={
                    editable ? formikDetails.values.name : sellerMyAccountData.name
                  }
                  onChange={formikDetails.handleChange}
                  error={
                    formikDetails.touched.name &&
                    Boolean(formikDetails.errors.name)
                  }
                  disabled={editable ? false : true}
                  helperText={
                    formikDetails.touched.name &&
                    formikDetails.errors.name
                  }
                />
              </div>
              <div className="seller-form-field">
                <div className="seller-my-account-field-name">Bank IFSC Number*: </div>
                <TextField
                  autoFocus
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  type="text" id="invite_sellerName"
                  name="ifsc"
                  className={
                    editable
                      ? "seller-form-field"
                      : "seller-form-field-disabled"
                  }
                  // placeholder=" "
                  value={editable ? formikDetails.values.ifsc : sellerMyAccountData.id}
                  onChange={formikDetails.handleChange}
                  error={
                    formikDetails.touched.ifsc &&
                    Boolean(formikDetails.errors.ifsc)
                  }
                  disabled={editable ? false : true}
                  helperText={
                    formikDetails.touched.ifsc &&
                    formikDetails.errors.ifsc
                  }
                />
              </div>
              <div className="seller-form-field">
                <div className="seller-my-account-field-name">Branch Code*: </div>
                <TextField
                  autoFocus
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  type="text" id="invite_sellerName"
                  name="branchName"
                  className={
                    editable
                      ? "seller-form-field"
                      : "seller-form-field-disabled"
                  }
                  // placeholder=" "
                  value={
                    editable
                      ? formikDetails.values.branchName
                      : sellerMyAccountData.website
                  }
                  onChange={formikDetails.handleChange}
                  error={
                    formikDetails.touched.branchName &&
                    Boolean(formikDetails.errors.branchName)
                  }
                  disabled={editable ? false : true}
                  helperText={
                    formikDetails.touched.branchName &&
                    formikDetails.errors.branchName
                  }
                />
              </div>
              <div className="seller-form-field">
                <div className="seller-my-account-field-name">Type of Account*: </div>
                {!editable && (
                  <div className="seller-my-account-non-texfield">
                    Savings Bank Account
                  </div>
                )}
                {editable && (
                  <div>
                    <FormControl sx={{ ml: -2, minWidth: 480 }}>
                      <Select
                        value={formikDetails.values.accountType}
                        onChange={formikDetails.handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        name="accountType"
                      >
                        <MenuItem value={10}>Savings Bank Account</MenuItem>
                        <MenuItem value={20}>Current Account</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
              <div className="seller-form-field">
                <div className="seller-my-account-field-name">Upload MSME Certificate*: </div>
                {/* <TextField
                  autoFocus
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
type="text"                  id="invite_sellerName"
                  name="invite_sellerName"
                  className={
                    editable
                      ? "seller-form-field"
                      : "seller-form-field-disabled"
                  }
                  // placeholder=" "
                  value={
                    editable ? formikDetails.values.aadhar : sellerMyAccountData.name
                  }
                  onChange={formikDetails.handleChange}
                  // error={
                  //   formikDetails.touched.invite_sellerName &&
                  //   Boolean(formikDetails.errors.invite_sellerName)
                  // }
                  disabled={editable ? false : true}
                  // helperText={
                  //   formikDetails.touched.invite_sellerName &&
                  //   formikDetails.errors.invite_sellerName
                  // }
                /> */}
                {!editable && (
                  <div className="seller-my-account-non-texfield">{formikDetails.values.MSME}</div>
                )}
                {editable && (
                  <div className="seller-my-account-non-texfield">
                    <div>{formikDetails.values.MSME}</div>
                    <input
                      type="file"
                      className="submit-upload-files"
                      name="MSME"
                      onChange={formikDetails.handleChange}
                      //   value={formikDetails.values.MSME}
                      accept="image/png, image/jpeg"
                    />
                  </div>
                )}
              </div>
              <div className="seller-form-field">
                <div className="seller-my-account-field-name">Subscription Plan*: </div>
                {/* <TextField
                  autoFocus
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
type="text"                  id="invite_sellerName"
                  name="invite_sellerName"
                  className={
                    editable
                      ? "seller-form-field"
                      : "seller-form-field-disabled"
                  }
                  // placeholder=" "
                  value={
                    editable ? formikDetails.values.aadhar : sellerMyAccountData.name
                  }
                  onChange={formikDetails.handleChange}
                  // error={
                  //   formikDetails.touched.invite_sellerName &&
                  //   Boolean(formikDetails.errors.invite_sellerName)
                  // }
                  disabled={editable ? false : true}
                  // helperText={
                  //   formikDetails.touched.invite_sellerName &&
                  //   formikDetails.errors.invite_sellerName
                  // }
                /> */}
                {!editable && (
                  <div className="seller-my-account-non-texfield">Basic</div>
                )}
                {editable && (
                  <div>
                    <FormControl sx={{ ml: 2, minWidth: 240 }}>
                      <Select
                        value={age}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value={10}>Basic</MenuItem>
                        <MenuItem value={20}>Standard</MenuItem>
                        <MenuItem value={30}>Premium</MenuItem>
                        <MenuItem value={40}>Enterprise</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
              <div className="seller-form-field">
                <div className="seller-my-account-field-name">Payment Status*: </div>
                {/* <TextField
                  autoFocus
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
type="text"                  id="invite_sellerName"
                  name="invite_sellerName"
                  className={
                    editable
                      ? "seller-form-field"
                      : "seller-form-field-disabled"
                  }
                  // placeholder=" "
                  value={
                    editable ? formikDetails.values.aadhar : sellerMyAccountData.name
                  }
                  onChange={formikDetails.handleChange}
                  // error={
                  //   formikDetails.touched.invite_sellerName &&
                  //   Boolean(formikDetails.errors.invite_sellerName)
                  // }
                  disabled={editable ? false : true}
                  // helperText={
                  //   formikDetails.touched.invite_sellerName &&
                  //   formikDetails.errors.invite_sellerName
                  // }
                /> */}
                {!editable && (
                  <div className="seller-my-account-non-texfield">
                    {formikDetails.values.paymentStatus}
                  </div>
                )}
                {editable && (
                  <div className="seller-my-account-non-texfield">
                    {formikDetails.values.paymentStatus}
                  </div>
                )}
              </div>
              {/* <button type="button" className="common-button" style={{float:"right"}}>Add New</button> */}
              {!editable && (
                <input
                  type="button"
                  className="submit-button"
                  style={{ float: "right", fontSize: "0.8rem" }}
                  onClick={editForm}
                  value="Edit Details"
                />
              )}
              {editable && (
                <input
                  type="button"
                  className="submit-button"
                  style={{ float: "right", fontSize: "0.8rem" }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setEditable(false);
                    postData();

                    // formikDetails.handleSubmit();
                  }}
                  value="Update Details"
                />
              )}
            </div>
          </form>
        </div>
      </div>
      <SellerFooter />
    </div>
  );
}
