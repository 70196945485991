import React, { useContext, useState } from 'react'
import { useParams } from "react-router-dom";
// import productData from "../ProductData";
import imageUploader from "../Images/imgFrame.png";
import shoe from "../Images/Jutha2.png"
import "../styles/RatingCardComponent.css"
// import CustomerReviewCard from './CustomerReviewCard';
import { CircularProgress, Rating, TextField } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { v4 as uuidv4 } from "uuid";
import { errorAlert } from './Alert';
import { CustomerContext } from '../context/CustomerContext';
import { API_BASE_URL } from '../App';

function RatingCardComponent() {
  const params = useParams();
  const id = params.productId;
  const [isReviewModalOpen, setReviewModalOpen] = useState(false);
  const [reviewText, setReviewText] = useState("");
  const [value, setValue] = useState(0);

  const handleRatingChange = (event, newValue) => {
    setValue(newValue);
  };

  // const openReviewModal = () => {
  //   setReviewModalOpen(true);
  // };

  // const closeReviewModal = () => {
  //   setReviewModalOpen(false);
  // };
  const { accessToken , customerData} = useContext(CustomerContext);
  const [title, setTitle] = useState('');
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const [imageLoading, setImageLoading] = useState(false);
  const [imageName, setImageName] = useState('')
  const handleSubmitReview = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/feedback/api/v1/Add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          "userId":customerData?.result?.userID,
          "productId":"", 
          "title":title,
          "rating": value,
          "review": reviewText,
          "fileNameWithExtension": imageName

        }),
      });
  
      if (response.ok) {
      
        //console.log("Review added successfully");
      } else {
        
        console.error("Failed to add review:", response.status);
      }
    } catch (error) {
      console.error("Error adding review:", error);
    }
  };

  return (
    <div className="card-container-reviews" >
              <div className='review-head'>Write a Review</div>
            
            <div className="card-product-details-review">
            
              <div className="rating-product-name ">
                  <img loading="lazy" alt="" src={shoe} />
              </div>
              <div className="single-prod-name">
              <div>Miss Chase Women Apparel</div>
            </div>
            </div>
              <div className="card-product-properties">
             
             
                
                  <div className='rating-fields-set'>
    
            <div className="rating-fields-set">
         <div className='rating-fields-title'>Ratings</div> 
           <div className="start-field">
           <Rating
    name="custom-rating"
    value={value}
    onChange={handleRatingChange}
    precision={0.5} 
    max={5} 
  />
           </div>
           
           <div className='rating-fields-title'>Add Heading</div> 
           <div><TextField 
           className='rating-title'
            value={title}
            onChange={handleTitleChange}>
              </TextField></div>

           </div>
          
                <div className="rating-fields-set">
    <h2 className="rating-fields-title">Add Photo or Video</h2>
    <label className="choose-file-btn">
        <img loading="lazy" src={imageUploader} alt=""/>
        <input
              type="file"
              // accept=".jpg,.jpeg,.png"
              onChange={async (event) => {
                setImageLoading(true);

                const uploadedImage = event.target.files[0];
                const maxSizeInBytes = 10 * 1024 * 1024;
                if (uploadedImage.size > maxSizeInBytes) {
                  errorAlert('',"File size exceeds 1 MB limit.");
                  setImageLoading(false);

                  return;
                }

                // Check if file type is jpg/jpeg/png
                const validTypes = ["image/jpeg", "image/png", "image/jpg"];
                if (!validTypes.includes(uploadedImage.type)) {
                  errorAlert('',"File type must be jpg, jpeg, or png.");
                  setImageLoading(false);

                  return;
                }
                const fileExtension = uploadedImage.name
                  .split(".")
                  .pop(); // Extract file extension
                const imageName = `${uuidv4()}.${fileExtension}`; // Generate image name with uuid and file extension

                const formData = new FormData();
                formData.append("ContainerName", "ecomdata");
                formData.append(
                  "FileNameWithExtension",
                  imageName
                );
                formData.append("FileCategory", "Category");
                formData.append(
                  "Attachment",
                  uploadedImage,
                  uploadedImage.name
                );

                try {
                  const response = await fetch(
                    `${API_BASE_URL}/blob/api/v1/uploadmediaWithContainer`,
                    {
                      method: "POST",
                      headers: {
                        Authorization: `Bearer ${accessToken}`,
                      },
                      body: formData,
                    }
                  );

                  if (!response.ok) {
                    errorAlert('', 'Something went WrongLocation. Could not upload Image');
                  }

                  // Set image variable as the uploaded image name
                  setImageName(imageName)
                  // You can also perform other actions after successful upload
                  setImageLoading(false);

                } catch (error) {
                  
                  setImageLoading(false);

                }
              }}
              name="image"
            />
            Choose file
      </label>
      {imageLoading && (
            <CircularProgress className="file-submitted" />
          )}
          {!imageLoading && imageName !== '' && <CheckCircle className="file-submitted" />}
                 </div>
        
         
           <div className="rating-fields-set">
           <h2 className="rating-fields-title">Add a written review</h2>
            <textarea
             style={{maxWidth:"500px",height:"193px", minWidth:"370px"}}
             value={reviewText}
             className="textarea-style"
            onChange={(e) => setReviewText(e.target.value)}>
              Would you like to add anything about this product?</textarea>
           </div>
        
          </div>
            </div>
   
            <button className="byr-submit-btn" onClick={handleSubmitReview}>Submit </button>
           </div>
           
  )
}

export default RatingCardComponent