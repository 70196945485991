import React, {  useMemo, useState } from "react";
import  "../styles/Common.css"
import  "../styles/Logistics.css"
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import AdminFooter from "./AdminFooter";
import mainlogo from "../Images/MainLogo.png";

import logo from "../Images/MainLogo.png"
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import VerifiedIcon from '@mui/icons-material/Verified';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CategoryIcon from '@mui/icons-material/Category';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { Avatar } from "@mui/material";
import {
  Apps,
  Menu,
  ContactMail,
  AssignmentInd,
  Home
  
} from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import Footer from "./Footer";
import { green } from "@mui/material/colors";
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { useContext } from "react";
import { SellerContext } from "../context/SellerContext";
import { useEffect } from "react";
import { LocalVendorContext } from "../context/LocalVendorContext";
import { LogisticsAdminContext } from "../context/LogisticsAdminContext";
// import Home from "../Images/DashboardIcon.png"
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: "#F2F2F2",
// color: "rgba(0, 0, 0, 0.6)",
color:green,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  backgroundColor: "White",

  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
 


  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // marginLeft: drawerWidth,
    width: `calc(100% - ${0}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const listItems = [
  {
    listIcon: <ShoppingBagOutlinedIcon />,
    listText: "ORDERS",
    listNav: "/orders",
  },

  {
    listIcon: <DashboardIcon />,
    listText: "RETURN AND REPLACE",
    listNav: "/return",
  },
  {
    listIcon: <DoNotDisturbAltIcon />,
    listText: "CANCEL ORDERS",
    listNav: "/cancel",
  },
];
export default function LogisticsAdminLayout2({ children }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
 


  const { selectedListItemIndex, setSelectedListItemIndex, logout } =
    useContext(LogisticsAdminContext);
// useEffect(()=>{
//   //console.log(selectedListItemIndex)
// },[selectedListItemIndex])

    const handleListItemClick = (index) => {
      setSelectedListItemIndex(index);
      //console.log(listItems[index].listNav);
      navigate(listItems[index].listNav);
    };

  return (
    <Box sx={{ display: "flex" }} className="logistic-navbar-sidebar">
      <CssBaseline />
     
      <AppBar position="fixed" open={open} elevation={0} style={{background:"red !important"}}>
        <Toolbar style={{background:"red !important"}}> 
     
          <IconButton
            color="black"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 0,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            color="black"
            aria-label="open drawer"
            onClick={handleDrawerClose}
                        edge="start"
            sx={{
              marginRight: 0,
              ...(!open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <img loading="lazy" src={mainlogo} alt="" className="Myhraki-logo-nav-bar"/> 

        </Toolbar>
        
       <Avatar style={{position:"absolute", right:"1rem", color:"black", top:"1.3rem",width:"30px",height:"30px"}} />
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} color="red">
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              open&&<ChevronLeftIcon 
            />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
       
        <List>
     
          {listItems.map((listItem, index) => (
            <ListItem onClick={() => {handleListItemClick(index) }} key={index} disablePadding sx={{ display: "block", backgroundColor:selectedListItemIndex===index?"#ff7891":"#f2f2f2", borderRadius:"0px", minHeight:"3rem" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {listItem.listIcon}
                </ListItemIcon>
                <ListItemText primary={listItem.listText} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <div  style={{position:"absolute", bottom:"2rem" ,width:"99%" ,fontSize:"1.2rem",textAlign:"center", minHeight:"30px", padding:"1rem 0"}} onMouseOver={(e) => {
          e.target.style.backgroundColor = '#FD2A80';
          e.target.style.color ='#FFFFFF'; 
          e.target.style.cursor="pointer"
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = '#f2f2f2';
          e.target.style.color ='rgba(0,0,0,0.7)'; 
        }}onClick={logout}>
          Logout
        </div>
        
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, }}>
        <DrawerHeader />
        <div className="logistics-layout">{children}</div>
        {/* <Footer color="seller-footer-color" /> */}
      </Box>
    </Box>
  );
}
