import React, { useContext } from "react";
import "../styles/BuyersMyAccount.css";
// import Footer from "./Footer";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "@mui/material";
import "../styles/reviews.css";
import imageUploader from "../Images/fileUploader.png";
import { useParams } from "react-router-dom";
// import SingleProductReview from "../components/SingleProductReview";
import RatingCardComponent from "../components/RatingCardComponent";
// import BuyersLayout from "../components/BuyersLayout";
import { useState } from "react";
import CustomerNavbar from "../components/CustomerNavbar";
import CustomerFooter from "../components/CustomerFooter";
import CustomerCategoryBar from "../components/CustomerCategoryBar";
import { CustomerContext } from "../context/CustomerContext";
import { API_BASE_URL } from "../App";



export default function ReviewsAndRatings() {
  const params = useParams();
  const productId = params.productId;
  const [reviewText, setReviewText] = useState("");
  const { accessToken , customerData} = useContext(CustomerContext);

  const handleSubmitReview = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/feedback/api/v1/Add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          userId:"",
          productId: "", 
          rating: 5, 
          review: reviewText,
      

        }),
      });
  
      if (response.ok) {
      
        //console.log("Review added successfully");
      } else {
        
        console.error("Failed to add review:", response.status);
      }
    } catch (error) {
      console.error("Error adding review:", error);
    }
  };
  
  return (
    <>
    <CustomerNavbar />
    <CustomerCategoryBar />
    <div className="buyers-my-account ">
      {/* <Header /> */}
      <div className="buyers-my-account-container">
        <div className="page-content">
        {/* <div className="my-account-head">My Browsing History</div> */}
        <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              className="breadcrumbs"
              aria-label="breadcrumb"
              sx={{ marginTop: "2rem", marginBottom: "2rem", }}
            >
              <Link
                underline="hover"
                color="inherit"
                style={{ fontSize: "1.8rem", fontWeight: "600", color: "#000" }}
                href="/account"

              >
               
              </Link>

              <Link
                underline="hover"
                color="inherit"
                style={{ fontSize: "1.5rem", fontWeight: "400" }}
              >
                My Reviews and Ratings
              </Link>
             </Breadcrumbs>
             <div >
            <RatingCardComponent/>
           <div className="rating-fields">
           <div className="start-rating-text-field">
            <div className="rating-fields-set">
           <h2 className="rating-fields-title">Rating Product</h2>
           <div className="start-field">
            <h3 style={{display:"flex",justifyContent:"flexStart",alignItems:"flex-start"}}>Star Rating</h3>

           </div>
           </div>
           <div className="review-text-area">
           <div className="rating-fields-set">
           <h2 className="rating-fields-title">Write a Review</h2>
           <div className="start-field">
            <textarea
             style={{maxWidth:"420px",height:"193px", minWidth:"370px"}}
             value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}>
              Would you like to add anything about this product?</textarea>

           </div>
           </div>
           </div>
           </div>
           <div className="review-image-area">
  <div className="rating-fields-set">
    <h2 className="rating-fields-title">Add Photo or Video</h2>
    <div className="start-field">
      <label htmlFor="upload-input" className="upload-label">
        <img loading="lazy" src={imageUploader} alt=""/>
        Click Here to Upload
        <input
          id="upload-input"
          type="file"
          accept="image/*, video/*"
          className="file-input"
        />
      </label>
    </div>
  </div>
</div>
           </div>
           <div className="button-field-reviews">
           <button className="byr-submit-btn" onClick={handleSubmitReview}>Submit </button>
           </div>
           </div>
      </div>
      </div>
    </div>
   <CustomerFooter />
   </>
  );
}